import { Dispatch } from 'redux';

import SendEmailClient from '../lib/sendEmailClient';
import * as types from '../types';

export function updateContactFormAction(field: string, value: string): any {
  return (dispatch: any): void => {
    dispatch({
      type: types.UPDATE_CONTACT_FORM,
      data: { field, value },
    });
  };
}

export function resetContactFormAction(): any {
  return (dispatch: any): void => {
    dispatch({
      type: types.RESET_CONTACT_FORM,
    });
  };
}

export function sendEmailAction(returnAddress: string, subject: string, message: string): any {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: types.SEND_EMAIL_REQUEST });

    let hasError = false;
    let result = null;
    try {
      const sendEmailClient = new SendEmailClient();
      result = await sendEmailClient.sendEmail(returnAddress, subject, message);

      if (result.success) {
        dispatch({ type: types.SEND_EMAIL_SUCCESS });
      }
    } catch (error) {
      console.error('There was a problem sending email.', error);
      hasError = true;
    }

    if (hasError || (result && !result.success)) {
      dispatch({ type: types.SEND_EMAIL_FAILURE });
    }
  };
}
