import { DictContentType, GenericMap } from './dataType';

export const detectContentType = (input: string): number => {
  const inputLocal = input.trim();

  // Strong's number
  const regex = /([a-zA-Z]{1}\d+)/g;
  const result = inputLocal.match(regex);
  if (result) {
    return DictContentType.strongs;
  }

  // Manuscript
  const code = inputLocal.charCodeAt(0);
  if (code >= 0x0590 && code <= 0x05ff) {
    return DictContentType.hebrew;
  }

  if ((code >= 0x0370 && code <= 0x03ff) || (code >= 0x1f00 && code <= 0x1fff)) {
    return DictContentType.greek;
  }

  return DictContentType.translation;
};

export const strongsXIsHebrew = (input: string): boolean => {
  const trimmedInput = input.trim();
  return trimmedInput[0].toLowerCase() === 'h';
};

const greekMap: GenericMap<number> = {
  884: 187,
  894: 835,
  903: 720,
  8049: 7109,
  8051: 7110,
  8053: 7111,
  8055: 7112,
  8057: 7085,
  8059: 7086,
  8061: 7087,
  8123: 7221,
  8126: 7173,
  8137: 7233,
  8139: 7234,
  8147: 7235,
  8155: 7249,
  8163: 7219,
  8171: 7261,
  8174: 7273,
  8175: 8079,
  8185: 7277,
  8187: 7276,
  8189: 8009,
};

const calcsGreekValue = (code: number): number => code - greekMap[code];

export const purification = (input: string): string => {
  if (detectContentType(input) === DictContentType.hebrew) {
    return input
      .split('')
      .filter((char: string) => char.charCodeAt(0) > 1487 && char.charCodeAt(0) < 1525)
      .join('');
  }

  if (detectContentType(input) === DictContentType.greek) {
    return input
      .split('')
      .map((char: string) => {
        const code = char.charCodeAt(0);
        if (code in greekMap) {
          return String.fromCharCode(calcsGreekValue(code));
        }
        return char;
      })
      .join('');
  }

  return input;
};

export const digitization = (term: string): string => {
  const input = term.trim();
  if (detectContentType(input) === DictContentType.greek) {
    return input
      .split('')
      .map((char: string): string => {
        const code = char.charCodeAt(0);
        if ((code >= 0x0370 && code <= 0x03ff) || (code >= 0x1f00 && code <= 0x1fff)) {
          if (code in greekMap) {
            return `${calcsGreekValue(code)}`.padStart(4, '0');
          }
          return `${code}`.padStart(4, '0');
        }

        return '';
      })
      .join('');
  }

  if (detectContentType(input) === DictContentType.hebrew) {
    return input
      .split('')
      .map((char: string): string => {
        const code = char.charCodeAt(0);
        if (code >= 0x0590 && code <= 0x05ff) {
          return `${code}`.padStart(4, '0');
        }

        return '';
      })
      .join('');
  }

  return input
    .split('')
    .map((char: string): string => {
      const code = char.charCodeAt(0);
      return `${code}`.padStart(4, '0');
    })
    .join('');
};
