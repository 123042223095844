import React, { ReactElement } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { AppState } from '../../reducers';
import CustomDropdownToggle from '../navigator/customDropdownToggle';
import { ProfileRTLstate } from '../../types';
import { VerseIdParser } from '../../shared/verseIdParser';
import { updateUserProfileBooleanAction } from '../../actions';

export const SelectVerseDirectionComp = (profile: ProfileRTLstate): ReactElement => {
  const dispatch = useDispatch();
  const { isNTrtl, isOTrtl, isNTltr, isOTltr, isRTL, textId, uid } = profile;

  // are we in OT or NT?
  const verseIdParser = new VerseIdParser();
  const testament = verseIdParser.isNT(textId) ? 'nt' : 'ot';
  let rtlClass = 'rtl';
  let dirClass = '';
  let dirValue = true;

  if (isRTL) {
    dirValue = true;
    dirClass = 'fas fa-align-left';
    // right to left direction 'fa-align-left' (reverse)
    if (testament === 'ot' && isOTrtl === true) {
      dirClass = 'fas fa-align-right';
      dirValue = false;
    } else if (testament === 'nt' && isNTrtl === true) {
      dirClass = 'fas fa-align-right';
      dirValue = false;
    }
  } else {
    dirValue = true;
    rtlClass = 'ltr';
    dirClass = 'fas fa-align-right';
    // left to right direction 'fa-align-right' (reverse)
    if (testament === 'ot' && isOTltr === true) {
      dirClass = 'fas fa-align-left';
      dirValue = false;
    } else if (testament === 'nt' && isNTltr === true) {
      dirClass = 'fas fa-align-left';
      dirValue = false;
    }
  }

  return (
    <Dropdown
      key="switch-direction"
      className="switch-direction"
      onClick={(): void => {
        dispatch(
          updateUserProfileBooleanAction(uid, `is${testament.toUpperCase()}${rtlClass}`, dirValue),
        );
      }}
    >
      <Dropdown.Toggle id="interlinear-dropdown-custom-components" as={CustomDropdownToggle}>
        <OverlayTrigger
          key="switch-direction"
          trigger={['hover', 'focus']}
          placement="left"
          // prettier-ignore
          overlay={(
            <Tooltip id="tooltip-select-interlinear">
              <FormattedMessage id="switchDirection" />
            </Tooltip>
        )}
        >
          <i className={dirClass} />
        </OverlayTrigger>
      </Dropdown.Toggle>
    </Dropdown>
  );
};

export const mapStateToProps = (state: AppState): any => {
  return {
    isNTrtl: state.profile?.isNTrtl ? state.profile.isNTrtl : false,
    isOTrtl: state.profile?.isOTrtl ? state.profile.isOTrtl : false,
    isNTltr: state.profile?.isNTltr ? state.profile.isNTltr : false,
    isOTltr: state.profile?.isOTltr ? state.profile.isOTltr : false,
    uid: state.auth?.user ? state.auth.user.uid : '',
  };
};

export const mapDispatchToProps = (): any => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SelectVerseDirectionComp);
