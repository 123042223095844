/* eslint-disable no-case-declarations */
import { REHYDRATE } from 'redux-persist';
import moment from 'moment';
import { config } from '../config';
import noop from '../lib/noop';

import {
  ProjectState,
  CreateProjectActionTypes,
  CHANGE_DATA,
  CHANGE_RTL_TOGGLE,
  CANCEL_FORM,
  FORM_ERROR,
  PROJECT_LOADED,
  CREATE_PROJECT_REQUEST,
  CREATE_PROJECT_FAILURE,
  CREATE_PROJECT_SUCCESS,
  CLEAR_ERROR_MSG,
  SHOW_PROJECT_LIST,
  SHOW_PROJECT_CREATION,
  FETCH_PROJECTS_REQUEST,
  FETCH_PROJECTS_FAILURE,
  FETCH_PROJECTS_SUCCESS,
  SHOW_LIGHTBOX_REMOVING_PROJECT,
  CLOSE_LIGHTBOX_REMOVING_PROJECT,
  DELETE_PROJECT_REQUEST,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAILURE,
  SHOW_LIGHTBOX_EDITING_PROJECT,
  CLOSE_LIGHTBOX_EDITING_PROJECT,
  EDIT_PROJECT_REQUEST,
  EDIT_PROJECT_SUCCESS,
  EDIT_PROJECT_FAILURE,
  FETCH_PROJECT_REQUEST,
  FETCH_PROJECT_FAILURE,
  FETCH_PROJECT_SUCCESS,
  SHOW_LIGHTBOX_MANAGE_PROJECT_USERS,
  CLOSE_LIGHTBOX_MANAGE_PROJECT_USERS,
  SHOW_VIEW_OF_ADDING_PROJECT_USER,
  CANCEL_ADDING_PROJECT_USER,
  SEARCH_PROJECT_USER_EMAIL,
  HANDLE_USER_EMAIL_CHANGE,
  HANDLE_USER_PERMISSION_CHANGE,
  SAVE_PROJECT_USER_REQUEST,
  SAVE_PROJECT_USER_SUCCESS,
  SAVE_PROJECT_USER_FAILURE,
  DELETE_PROJECT_USER_REQUEST,
  DELETE_PROJECT_USER_SUCCESS,
  DELETE_PROJECT_USER_FAILURE,
  OPEN_LIGHTBOX_EXPORT_DATA,
  CLOSE_LIGHTBOX_EXPORT_DATA,
  EXPORT_DATA_REQUEST,
  EXPORT_DATA_SUCCESS,
  EXPORT_DATA_FAILURE,
  CHANGE_EXPORT_TYPE,
  CHANGE_BOOK_GROUP,
  NavigatorActionTypes,
  FETCH_BOOK_NAMES_REQUEST,
  FETCH_BOOK_NAMES_FAILURE,
  FETCH_BOOK_NAMES_SUCCESS,
  SELECT_BOOK,
  CHAPTER_SELECTS_BOOK,
  CHAPTER_SET_RANGE_FROM,
  CHAPTER_SET_RANGE_TO,
  SELECT_CHAPTER,
  VERSE_SET_RANGE_MIN,
  VERSE_SET_RANGE_MAX,
  CHANGE_STEP,
  VERSE_SELECTS_BOOK,
  DATA_EXPORT_ERROR,
} from '../types';

import { BibleChapters, getVerseNumberByBookAndChapter } from '../shared/verseIdParser';

const today = moment(new Date()).format(config.dateFormat);

const INIT_STAT: ProjectState = {
  loading: true,
  view: 'project',
  isFetching: false,
  redirectToProjectList: false,
  error: null,
  projectName: '',
  beginningDate: today,
  targetCompletionDate: '',
  description: '',
  showLightboxRemovingProjectVar: false,
  removeProjectId: undefined,
  deletingProject: false,
  isFetchingProject: false,
  projectNameEditing: '',
  beginningDateEditing: '',
  targetCompletionDateEditing: '',
  descriptionEditing: '',
  showLightboxManageUsersVar: false,
  isRTL: false,
  isReadOnly: false,

  displayLightboxOfExportData: false,
  isCreatingData: false,
  projectIdOfExportData: undefined,
  projectNameOfExportData: undefined,
  openLightboxOfExportDataFunc: noop,
  closeLightboxOfExportDataFunc: noop,
  dataExportsFunc: noop,
  dataExportErrorFunc: noop,
};

const ProjectApp = (
  state = INIT_STAT,
  action: CreateProjectActionTypes | NavigatorActionTypes,
): any => {
  const stateCopy = { ...state };
  const { selectedBook4Chapter, selectedBook4Verse, selectedChapterInVersesExport } = state;

  switch (action.type) {
    case CHANGE_RTL_TOGGLE:
      if (action.key === 'isRTL') {
        stateCopy.isRTL = action.value;
      }
      return { ...stateCopy };
    case CHANGE_DATA:
      if (action.key === 'projectName') {
        stateCopy.projectName = action.value;
      } else if (action.key === 'description') {
        stateCopy.description = action.value;
      } else if (action.key === 'beginningDate') {
        stateCopy.beginningDate = action.value;
      } else if (action.key === 'targetCompletionDate') {
        stateCopy.targetCompletionDate = action.value;
      } else if (action.key === 'projectNameEditing') {
        stateCopy.projectNameEditing = action.value;
      } else if (action.key === 'descriptionEditing') {
        stateCopy.descriptionEditing = action.value;
      } else if (action.key === 'beginningDateEditing') {
        stateCopy.beginningDateEditing = action.value;
      } else if (action.key === 'targetCompletionDateEditing') {
        stateCopy.targetCompletionDateEditing = action.value;
      }

      return { ...stateCopy };

    case CANCEL_FORM:
      return {
        ...state,
        projectName: '',
        beginningDate: '',
        targetCompletionDate: '',
        description: '',
        error: '',
        isFetching: false,
      };

    case FORM_ERROR:
      return { ...state, error: action.error, message: action.message };

    case CLEAR_ERROR_MSG:
      return { ...state, error: '', message: '', errorProjectUser: '', messageProjectUser: '' };

    case PROJECT_LOADED:
      return { ...state, loading: false };
    case CREATE_PROJECT_REQUEST:
      return { ...state, isFetching: true };

    case CREATE_PROJECT_FAILURE:
      return { ...state, error: action.error, isFetching: false, message: '' };

    case CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        error: '',
        isFetching: false,
        message: action.message,
        redirectToProjectList: true,
      };

    case REHYDRATE:
      if (Object.prototype.hasOwnProperty.call(action, 'payload')) {
        const { payload } = action;

        if (payload !== undefined && Object.prototype.hasOwnProperty.call(payload, 'project')) {
          const { project } = payload;
          if (Object.prototype.hasOwnProperty.call(project, 'beginningDate')) {
            const { beginningDate } = project;
            if (beginningDate === '') {
              return {
                ...payload.project,
                beginningDate: moment(new Date()).format(config.dateFormat),
              };
            }
          }

          return {
            ...payload.project,
            beginningDate: moment(new Date()).format(config.dateFormat),
          };
        }
      }

      return state;

    case SHOW_PROJECT_LIST:
      return { ...state, view: SHOW_PROJECT_LIST, loading: true, error: '' };

    case SHOW_PROJECT_CREATION:
      if (state.beginningDate === '') {
        return {
          ...state,
          view: SHOW_PROJECT_CREATION,
          loading: true,
          error: '',
          beginningDate: today,
        };
      }

      return { ...state, view: SHOW_PROJECT_CREATION, loading: true, error: '' };

    case FETCH_PROJECTS_REQUEST:
      return { ...state, isFetchingList: true };

    case FETCH_PROJECTS_FAILURE:
      return { ...state, isFetchingList: false, error: action.error };

    case FETCH_PROJECTS_SUCCESS:
      return {
        ...state,
        isFetchingList: false,
        projectList: action.projectList,
        currentUid: action.currentUid,
      };

    case SHOW_LIGHTBOX_REMOVING_PROJECT:
      return { ...state, showLightboxRemovingProjectVar: true, removeProjectId: action.projectId };

    case CLOSE_LIGHTBOX_REMOVING_PROJECT:
      return { ...state, showLightboxRemovingProjectVar: false };

    case DELETE_PROJECT_REQUEST:
      return { ...state, deletingProject: true };

    case DELETE_PROJECT_SUCCESS:
      return { ...state, deletingProject: false, removeProjectId: undefined };

    case DELETE_PROJECT_FAILURE:
      return { ...state, deletingProject: false, deletingError: action.error };

    case SHOW_LIGHTBOX_EDITING_PROJECT:
      return { ...state, showLightboxEditingProjectVar: true, editProjectId: action.projectId };

    case CLOSE_LIGHTBOX_EDITING_PROJECT:
      return { ...state, showLightboxEditingProjectVar: false, editProjectId: undefined };

    case EDIT_PROJECT_REQUEST:
      return { ...state, editingProject: true };

    case EDIT_PROJECT_SUCCESS:
      return {
        ...state,
        editingProject: false,
        showLightboxEditingProjectVar: false,
        editProjectId: undefined,
      };

    case EDIT_PROJECT_FAILURE:
      return { ...state, editingProject: false, editingError: action.error };

    case FETCH_PROJECT_REQUEST:
      return { ...state, isFetchingProject: true };

    case FETCH_PROJECT_FAILURE:
      return { ...state, isFetchingProject: false, error: action.error };

    case FETCH_PROJECT_SUCCESS:
      return {
        ...state,
        isFetchingProject: false,
        projectNameEditing: action.projectData.projectName,
        beginningDateEditing: action.projectData.beginningDate,
        targetCompletionDateEditing: action.projectData.targetCompletionDate,
        descriptionEditing: action.projectData.description,
        projectUsers: action.projectData.users,
        error: null,
        message: null,
        userIsProjectMember: action.userIsProjectMember,
        isReadOnly: action.projectData.isReadOnly,
        isRTL: action.projectData.isRTL,
      };

    // manage project users - begin
    case SHOW_LIGHTBOX_MANAGE_PROJECT_USERS:
      return { ...state, showLightboxManageUsersVar: true, projectIdManageUsers: action.projectId };

    case CLOSE_LIGHTBOX_MANAGE_PROJECT_USERS:
      return {
        ...state,
        showLightboxManageUsersVar: false,
        projectIdManageUsers: undefined,
        errorProjectUser: '',
        messageProjectUser: '',
      };

    case SHOW_VIEW_OF_ADDING_PROJECT_USER:
      return { ...state, appState: 'ADD_USER' };

    case CANCEL_ADDING_PROJECT_USER:
      return { ...state, appState: 'LIST_USER', errorProjectUser: '', messageProjectUser: '' };

    case SEARCH_PROJECT_USER_EMAIL:
      return { ...state, appState: 'SEARCH_USER' };

    case HANDLE_USER_EMAIL_CHANGE:
      return { ...state, userEmail: action.email || '' };

    case HANDLE_USER_PERMISSION_CHANGE:
      return { ...state, userPermission: action.permission };

    case SAVE_PROJECT_USER_REQUEST:
      return { ...state, isFetchingProject: true };

    case SAVE_PROJECT_USER_FAILURE:
      return { ...state, isFetchingProject: false, errorProjectUser: action.error };

    case SAVE_PROJECT_USER_SUCCESS:
      return {
        ...state,
        isFetchingProject: false,
        appState: 'LIST_USER',
        message: 'project.message.user-added',
        error: null,
        userEmail: '',
        userPermission: null,
      };

    case DELETE_PROJECT_USER_REQUEST:
      return { ...state, isFetchingProject: true };

    case DELETE_PROJECT_USER_FAILURE:
      return { ...state, isFetchingProject: false, errorProjectUser: action.error };

    case DELETE_PROJECT_USER_SUCCESS:
      return {
        ...state,
        isFetchingProject: false,
        appState: 'LIST_USER',
        message: 'project.message.user-added',
        error: null,
      };

    case OPEN_LIGHTBOX_EXPORT_DATA:
      return {
        ...state,
        displayLightboxOfExportData: true,
        projectIdOfExportData: action.projectId,
        projectNameOfExportData: action.projectName,
        selectedBookGroup: undefined,
        selectedBooks: {},
      };

    case CLOSE_LIGHTBOX_EXPORT_DATA:
      return {
        ...state,
        displayLightboxOfExportData: false,
        projectIdOfExportData: undefined,
        isCreatingData: false,
        fileUrl: undefined,
        rtfError: undefined,
        statistic: undefined,
        dataExportError: '',

        exportType: 1,
        selectedBookGroup: undefined,
        loadingTranslatedBookNames: false,
        translatedBookNameMap: {},
        selectedBooks: {},
        selectedBook4Chapter: undefined,
        chapterFrom: undefined,
        chapterTo: undefined,
        selectedBook4Verse: undefined,
        selectedChapterInVersesExport: undefined,
        verseRangeMin: undefined,
        verseRangeMax: undefined,
        currentStep: 1,
        selectedBooksString: undefined,
      };

    case EXPORT_DATA_REQUEST:
      return { ...state, isCreatingData: true, currentStep: 2 };

    case EXPORT_DATA_SUCCESS:
      return {
        ...state,
        isCreatingData: false,
        fileUrls: action.fileUrls,
        statistic: action.statistic,
      };

    case EXPORT_DATA_FAILURE:
      return { ...state, isCreatingData: false, rtfError: action.rtfError };

    case CHANGE_EXPORT_TYPE:
      return { ...state, exportType: action.exportType };

    case CHANGE_BOOK_GROUP:
      return { ...state, selectedBookGroup: action.bookGroupKey };

    case FETCH_BOOK_NAMES_REQUEST:
      return { ...state, loadingTranslatedBookNames: true };

    case FETCH_BOOK_NAMES_FAILURE:
      return { ...state, loadingTranslatedBookNames: false, rtfError: action.error };

    case FETCH_BOOK_NAMES_SUCCESS:
      return {
        ...state,
        loadingTranslatedBookNames: false,
        translatedBookNameMap: action.translatedBookNameMap,
      };

    case SELECT_BOOK:
      return {
        ...state,
        selectedBooks: action.selectedBooks,
        selectedBooksString: Object.keys(action.selectedBooks).join('|'),
      };

    case CHAPTER_SELECTS_BOOK:
      // eslint-disable-next-line no-case-declarations
      const chapterMaxChapter = BibleChapters[action.bookId as keyof typeof BibleChapters];

      return {
        ...state,
        selectedBook4Chapter: action.bookId,
        chapterFrom: 1,
        chapterTo: chapterMaxChapter,
      };

    case CHAPTER_SET_RANGE_FROM:
      if (selectedBook4Chapter) {
        const chapterMaxChapterTemp =
          BibleChapters[selectedBook4Chapter as keyof typeof BibleChapters];
        const chapterFrom =
          action.rangeFrom <= chapterMaxChapterTemp ? action.rangeFrom : chapterMaxChapterTemp;

        return {
          ...state,
          chapterFrom,
        };
      }
      return {
        ...state,
        chapterFrom: action.rangeFrom,
      };

    case CHAPTER_SET_RANGE_TO:
      if (selectedBook4Chapter) {
        const chapterMaxChapterTemp =
          BibleChapters[selectedBook4Chapter as keyof typeof BibleChapters];
        const chapterTo =
          action.rangeTo <= chapterMaxChapterTemp ? action.rangeTo : chapterMaxChapterTemp;

        return {
          ...state,
          chapterTo,
        };
      }
      return {
        ...state,
        chapterTo: action.rangeTo,
      };

    case VERSE_SELECTS_BOOK:
      if (selectedChapterInVersesExport) {
        return {
          ...state,
          selectedBook4Verse: action.bookId,
          selectedChapterInVersesExport: 1,
          verseRangeMin: '-1',
          verseRangeMax: getVerseNumberByBookAndChapter(
            action.bookId,
            selectedChapterInVersesExport,
          ),
        };
      }

      return {
        ...state,
        selectedBook4Verse: action.bookId,
        selectedChapterInVersesExport: 1,
        verseRangeMin: '-1',
        verseRangeMax: getVerseNumberByBookAndChapter(action.bookId, 1),
      };

    case SELECT_CHAPTER:
      if (selectedBook4Verse) {
        return {
          ...state,
          selectedChapterInVersesExport: action.selectedChapter,
          verseRangeMin: '-1',
          verseRangeMax: getVerseNumberByBookAndChapter(selectedBook4Verse, action.selectedChapter),
        };
      }
      return {
        ...state,
        selectedChapterInVersesExport: action.selectedChapter,
        verseRangeMin: '-1',
        verseRangeMax: '-1',
      };
    case VERSE_SET_RANGE_MIN:
      return {
        ...state,
        verseRangeMin: action.rangeValue,
      };

    case VERSE_SET_RANGE_MAX:
      return {
        ...state,
        verseRangeMax: action.rangeValue,
      };

    case CHANGE_STEP:
      if (action.step === 1) {
        return {
          ...state,
          currentStep: action.step,

          isCreatingData: false,
          fileUrl: undefined,
          rtfError: undefined,
          statistic: undefined,
          dataExportError: '',

          loadingTranslatedBookNames: false,
        };
      }
      return {
        ...state,
        currentStep: action.step,
      };

    case DATA_EXPORT_ERROR:
      return {
        ...state,
        dataExportError: action.errorMsg,
      };

    default:
      return state;
  }
};

export default ProjectApp;
