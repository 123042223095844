import React, { ReactElement, useEffect } from 'react';
// react-markdown is required to transform the markdown in order to:
// (1) generate correct image links
// (2) add appropriate css classes to <img> elements
import ReactMarkdown from 'react-markdown';
// markdown-it is used to generate a linked TOC from the markdown.
// It is therefore required to render the TOC and the body.
import MarkdownIt from 'markdown-it';
import MarkdownItAnchor from 'markdown-it-anchor';
import MarkdownItTOC from 'markdown-it-table-of-contents';
import Fetch, { FetchResult } from 'react-fetch-component';

const HelpContentLocation = '/help/index.md';

const transformImageUri = (origUri: string): string => {
  return `/help/${origUri}`;
};

const imageRenderer = (props: { alt: string; src: string; title: string }): ReactElement => {
  const { alt, src, title } = props;
  return <img alt={alt} src={src} title={title} className="img-fluid" />;
};

const MARKDOWN_TOC_SYMBOL = '[[toc]]';

const Help = (): React.ReactElement => {
  useEffect(() => {
    setTimeout((): void => {
      window.location.hash = window.decodeURIComponent(window.location.hash);
      const scrollToAnchor = (): void => {
        const hashParts = window.location.hash.split('#');
        if (hashParts.length >= 2) {
          const hash = hashParts.slice(-1)[0];
          document
            .querySelector(`#${hash}`)
            ?.scrollIntoView({ block: 'start', inline: 'start', behavior: 'auto' });
        }
      };
      scrollToAnchor();
      window.onhashchange = scrollToAnchor;
    }, 500);
  }, []);
  return (
    <div className="container-fluid help-html">
      <div className="row">
        <div className="d-flex help-left">
          <div className="help-title">
            <Fetch url={HelpContentLocation}>
              {(props: FetchResult<any>): React.ReactElement => {
                if (props.data) {
                  const stringContent = String.fromCharCode.apply(
                    null,
                    Array.from(new Int8Array(props.data)),
                  );
                  const md = new MarkdownIt({ html: true });
                  md.use(MarkdownItAnchor);
                  md.use(MarkdownItTOC, { includeLevel: [1, 2, 3] });
                  const contentWithToc = `${MARKDOWN_TOC_SYMBOL}\n${stringContent}`;
                  const renderedContentWithToc = md.render(contentWithToc);
                  const extractedTocHtmlString = renderedContentWithToc.substring(
                    3,
                    renderedContentWithToc.indexOf('</p>'),
                  );
                  return <div dangerouslySetInnerHTML={{ __html: extractedTocHtmlString }} />;
                }
                return <></>;
              }}
            </Fetch>
          </div>
        </div>

        <main className="d-flex help-right">
          <div className="help-doc">
            <Fetch url={HelpContentLocation}>
              {(props: FetchResult<any>): React.ReactElement => {
                if (props.data) {
                  const stringContent = String.fromCharCode.apply(
                    null,
                    Array.from(new Int8Array(props.data)),
                  );
                  const md = new MarkdownIt({ html: true });
                  md.use(MarkdownItAnchor);
                  const preProcessedContent = md.render(stringContent);
                  return (
                    <ReactMarkdown
                      escapeHtml={false}
                      transformImageUri={transformImageUri}
                      source={preProcessedContent}
                      renderers={{ image: imageRenderer }}
                    />
                  );
                }
                return <></>;
              }}
            </Fetch>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Help;
