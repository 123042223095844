import { Panel } from './grid';
import { UserProfile } from '../shared/structs';

export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';
export const TOGGLE_MANUSCRIPT = 'TOGGLE_MANUSCRIPT';

export interface ProfileState {
  [index: string]: any;
  isNTrtl: boolean;
  isOTrtl: boolean;
  isNTltr: boolean;
  isOTltr: boolean;
  isLeftPanelOpen: boolean;
  isRightPanelOpen: boolean;
  isBottomPanelOpen: boolean;
  language: string;
  selectedInterlinearNT: string;
  selectedInterlinearOT: string;
  uid?: string;
}

export interface ProfileRTLstate {
  isRTL?: boolean;
  isNTrtl: boolean;
  isOTrtl: boolean;
  isNTltr: boolean;
  isOTltr: boolean;
  textId: string;
  uid: string;
}

export interface ProfileRTLAlignstate {
  isRTL?: boolean;
  isNTrtlAlignSource: boolean;
  isOTrtlAlignSource: boolean;
  isNTltrAlignSource: boolean;
  isOTltrAlignSource: boolean;
  isNTrtlAlignTarget: boolean;
  isOTrtlAlignTarget: boolean;
  isNTltrAlignTarget: boolean;
  isOTltrAlignTarget: boolean;
  verseCode: string;
  uid: string;
  iconPosition: string;
  reverseAlignmentDisplay: boolean;
  clearLinkSelectionsFunc: Function;
}

export interface ProfileProps {
  language: string;
  uid: string;
  profile: UserProfile;
  updateLanguageFunc?: any;
  updateProfileFunc?: any;
}

interface UpdateProfileAction {
  type: typeof UPDATE_PROFILE;
  profile: ProfileState;
}

interface UpdateLanguageAction {
  type: typeof UPDATE_LANGUAGE;
  language: string;
}

interface ToggleManuscriptAction {
  type: typeof TOGGLE_MANUSCRIPT;
  panel: Panel;
}

interface UndefinedAction {
  type: typeof undefined;
}

export type ProfileActionTypes =
  | UpdateProfileAction
  | UpdateLanguageAction
  | ToggleManuscriptAction
  | UndefinedAction;
