/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useIntl } from 'react-intl';
import { Dropdown } from 'react-bootstrap';
import { confirmUnsavedVerse } from '../../shared/frontend';

interface Props {
  index: number;
  eventKey: string;
  verseModifiedMap: any;
  changeChapterFunc: any;
}

export const I18nDropdown: React.FC<Props> = (props) => {
  // eslint-disable-next-line react/prop-types
  const { index, verseModifiedMap, changeChapterFunc } = props;
  const { formatMessage } = useIntl();
  const promptMessage = formatMessage({ id: 'translation.prompt' });

  return (
    <Dropdown.Item
      eventKey={index.toString()}
      key={`dropdown-item-chapter-${index}`}
      onClick={(): void => {
        if (confirmUnsavedVerse(promptMessage, verseModifiedMap)) {
          changeChapterFunc(index);
        }
      }}
    >
      {index}
    </Dropdown.Item>
  );
};
