const languageObject: Record<string, Record<string, string>> = {
  en: {
    'languages.en': 'English',
    'languages.am': 'Amharic',
    'languages.bn': 'Bengali Language',
    'languages.ceb': 'Cebuano Language',
    'languages.de': 'German Language',
    'languages.es-419': 'Spanish Language',
    'languages.fr': 'French Language',
    'languages.gu': 'Gujarati',
    'languages.hi': 'Hindi',
    'languages.hr': 'Croatian Language',
    'languages.hu': 'Hungarian Language',
    'languages.id': 'Indonesian Language',
    'languages.km': 'Khmer',
    'languages.kn': 'Kannada',
    'languages.ko': 'Korean Language',
    'languages.lo': 'Laotian Language',
    'languages.ml': 'Malayalam',
    'languages.mr': 'Marathi',
    'languages.ms': 'Malaysian',
    'languages.my': 'Burmese Language',
    'languages.ne': 'Nepali Language',
    'languages.plt': 'Malagasy Language',
    'languages.pt-br': 'Portuguese (Brazil) Language',
    'languages.ru': 'Russian Language',
    'languages.sw': 'Swahili',
    'languages.ta': 'Tamil Language',
    'languages.th': 'Thai Language',
    'languages.tl': 'Tagalog',
    'languages.te': 'Telegu Language',
    'languages.vi': 'Vietnamese Language',
    'languages.zh-hans': 'Chinese Language (Simplified)',
    'languages.zh-hant': 'Chinese Language (Traditional)',
    'header.home': 'Home',
    'header.help': 'Help',
    'header.project': 'Project',
    'header.contact': 'Contact',
    'help.contactSupport': 'Please email us at help@youtranslate.bible for assistance.',
    'home.ytbProvides': 'YouTranslate.Bible freely provides:',
    'home.easyToUseTool': 'An easy-to-use online translation tool',
    'home.originalTextHelp': 'Help to understand the text of the Bible in its original languages',
    'home.referencesAndResources': 'Reference and training resources',
    'home.computerAssistance': 'State-of-the-art computer technology to assist translators',
    'profile.userProfile': 'User Profile',
    'profile.language': 'Language',
    switchDirection: 'Switch Text Direction',
    'profile.update': 'Update',
    'profile.languageWarning':
      'Google Translate was used to translate the user interface into languages other than English temporarily. YTB has no control over the accuracy or reliability of the translation. Anyone relying on the translated labels and explanations obtained from Google Translate does so at his or her own risk. If anyone is interested in volunteering to provide more accurate and reliable translation of the user interface into languages other than English, please click on the Contact option to send an email to GBI.',
    'usernav.profile': 'Profile',
    'usernav.logout': 'Logout',
    'usernav.logout.modalTitle': 'Logout of YouTranslate.Bible',
    'usernav.logout.modalText': 'Are you sure you want to logout? All unsaved work will be lost.',
    cancel: 'Cancel',
    delete: 'Delete',
    create: 'Create',
    update: 'Update',
    edit: 'Edit',
    save: 'Save',
    add: 'Add',
    translate: 'Translate',
    'more.options': 'More options',
    'project.create.title': 'Bible Translation Project',
    'project.create.list': 'Project list',
    'project.list.deleteProject': 'Delete Project?',
    'project.list.areYouSure': 'Are you sure you want to delete this project?',
    'project.list.workWillBeDeleted': 'The project and any work associated will be deleted.',
    'project.list.completion': 'Completion:',
    'project.list.createdDate': 'Created Date:',
    'project.list.beginningDate': 'Beginning Date:',
    'project.list.targetCompletionDate': 'Target Completion Date:',
    'project.list.description': 'Description:',
    'project.edit': 'Edit Project',
    'project.list.translateBible': 'Translate Bible',
    'project.list.empty': 'Your translation project list is empty.',
    'project.list.myProjects': 'My Bible Translation Projects',
    'project.list.newProject': 'New Project',
    'project.create.createNew': 'Create a new Project',
    'project.namePlaceholder': 'Enter project name',
    'project.descriptionPlaceholder': 'Enter project description',
    'project.name': 'Project name',
    'project.beginningDate': 'Beginning Date',
    'project.targetCompletionDate': 'Target Completion Date',
    'project.description': 'Description',
    'project.isRTL': 'Translation is right-to-left',
    'project.manage-users': 'Manage Translators',
    'project.new-user': 'New translator',
    'project.add-user': 'Add translator',
    'project.users': 'Project translators',
    'project.read-only': 'Read only',
    'project.read&write': 'Read & Write',
    'project.owner': 'Owner',
    'project.translator': 'Translator',
    'project.message.no-such-user': 'No such translator exists',
    'project.message.user-added': 'Translator added successfully',
    'project.message.user-deleted': 'Translator removed.',
    'project.message.only-owner-can-manage-users': 'Only project owner can manage users',
    'project.shared': 'Collaborative project',
    'general.delete-user': 'Delete translator',
    'general.edit-user': 'Edit user',
    'general.name': 'Name',
    'general.email': 'E-mail',
    'general.permissions': 'Permissions',
    'general.role': 'Role',
    signin: 'Sign in',
    'signin.emailPlaceholder': 'Email address',
    'signin.passwordPlaceholder': 'Password',
    'signin.forgotPassword': 'Forgot Your Password?',
    'signin.facebook': 'Sign in with Facebook',
    'signin.google': 'Sign in with Google',
    'signin.dontHaveAccount': "Don't have an account?",
    'signin.createOne': 'Create one',
    'signup.join': 'Join YouTranslate.Bible',
    'signup.createAccountPrompt': 'Create your free account to begin.',
    'signup.createAccount': 'Create Account',
    'signup.emailPlaceholder': 'Email address',
    'signup.passwordPlaceholder': 'Password',
    'passwordReset.title': 'Password Reset',
    'passwordReset.prompt': 'Request an email with instructions to reset your password.',
    'passwordReset.emailPlaceholder': 'Email Address',
    'passwordReset.requestButtonLabel': 'Request Email',
    'passwordReset.success': 'Email has been sent.',
    'passwordReset.error': 'An error has occurred.',
    'translation.project': 'PROJECT:',
    'translation.backToProjectList': 'Back to project list',
    'translation.book': 'Book:',
    'translation.chapter': 'Chapter:',
    'translation.prompt':
      'Are you sure you want to leave this chapter? All unsaved work will be lost.',
    'translation.prev': 'Prev',
    'translation.next': 'Next',
    'translation.previousChapter': 'Previous chapter',
    'translation.nextChapter': 'Next chapter',
    'navigator.book': 'Book:',
    'navigator.chapter': 'Chapter:',
    'navigator.oldTestament': 'Old Testament',
    'navigator.newTestament': 'New Testament',
    'navigator.selectBook': 'Select a book',
    'navigator.search': 'Search',
    'navigator.changeBook': 'Change book',
    'navigator.changeChapter': 'Change chapter',
    Genesis: 'Genesis',
    Exodus: 'Exodus',
    Leviticus: 'Leviticus',
    Numbers: 'Numbers',
    Deuteronomy: 'Deuteronomy',
    Joshua: 'Joshua',
    Judges: 'Judges',
    Ruth: 'Ruth',
    '1 Samuel': '1 Samuel',
    '2 Samuel': '2 Samuel',
    '1 Kings': '1 Kings',
    '2 Kings': '2 Kings',
    '1 Chronicles': '1 Chronicles',
    '2 Chronicles': '2 Chronicles',
    Ezra: 'Ezra',
    Nehemiah: 'Nehemiah',
    Esther: 'Esther',
    Job: 'Job',
    Psalms: 'Psalms',
    Proverbs: 'Proverbs',
    Ecclesiastes: 'Ecclesiastes',
    'Song of Songs': 'Song of Songs',
    Isaiah: 'Isaiah',
    Jeremiah: 'Jeremiah',
    Lamentations: 'Lamentations',
    Ezekiel: 'Ezekiel',
    Daniel: 'Daniel',
    Hosea: 'Hosea',
    Joel: 'Joel',
    Amos: 'Amos',
    Obadiah: 'Obadiah',
    Jonah: 'Jonah',
    Micah: 'Micah',
    Nahum: 'Nahum',
    Habakkuk: 'Habakkuk',
    Zephaniah: 'Zephaniah',
    Haggai: 'Haggai',
    Zechariah: 'Zechariah',
    Malachi: 'Malachi',
    Matthew: 'Matthew',
    Mark: 'Mark',
    Luke: 'Luke',
    John: 'John',
    Acts: 'Acts',
    Romans: 'Romans',
    '1 Corinthians': '1 Corinthians',
    '2 Corinthians': '2 Corinthians',
    Galatians: 'Galatians',
    Ephesians: 'Ephesians',
    Philippians: 'Philippians',
    Colossians: 'Colossians',
    '1 Thessalonians': '1 Thessalonians',
    '2 Thessalonians': '2 Thessalonians',
    '1 Timothy': '1 Timothy',
    '2 Timothy': '2 Timothy',
    Titus: 'Titus',
    Philemon: 'Philemon',
    Hebrews: 'Hebrews',
    James: 'James',
    '1 Peter': '1 Peter',
    '2 Peter': '2 Peter',
    '1 John': '1 John',
    '2 John': '2 John',
    '3 John': '3 John',
    Jude: 'Jude',
    Revelation: 'Revelation',
    'resources.prompt':
      'Add Biblical resources for additional insight into the passage you are translating.',
    'resources.add': 'Add a Biblical resource',
    Manuscript: 'Manuscript',
    Morphology: 'Morphology',
    Lexicon: 'Lexicon',
    'Syntactic Tree': 'Syntactic Tree',
    'Go to project list': 'Go to project list',
    'Project not found': 'Project not found or insufficient permissions.',
    'error.referenceDoesNotExistForResource':
      'This Bible translation does not contain content for the selected passage.',
    'references.addAnother': 'Add another translation',
    'references.referencePrompt': 'Add other Bible translations for additional insight.',
    'references.add': 'Add a Bible translation',
    'references.licensing': 'Licensing Information',
    'references.licensingEmpty': 'No licensing information was found for this item.',
    'translation.complete': 'Translation complete',
    Alignment: 'Alignment',
    Share: 'Share',
    'Your Translation': 'Your Translation',
    'Enter your translation': 'Enter your translation',
    OK: 'OK',
    'error.verseTranslationEmpty': 'You cannot leave the translation content empty.',
    'translation.hide.completed': 'Hide completed',
    alignment: 'Alignment',
    'alignment.verify': 'Verify and close.',
    'alignment.close': 'Close without Verifying',
    'alignment.backToEditor': 'Back to Editor',
    'alignment.error':
      'Something went wrong. If the problem persists, please contact the YouTranslate.Bible team.',
    'alignment.add': 'Add Link',
    'alignment.delete': 'Remove Link',
    'alignment.clear': 'Clear Selection',
    'alignment.reverse': 'Reverse Display',
    'alignment.removeOrClear': 'Remove the selected link or clear selection.',
    'alignment.addOrRemove': 'Create a new link or delete a previous one.',
    'alignment.selectAndAdd': 'Select corresponding segments to link.',
    'error.mustBeSignedToMakeChanges': 'You must be signed in to make changes.',
    'error.mustBeSignedToTranslateBible': 'You must be signed in to translate the Bible.',
    checking: 'Checking',
    'verseEditor.unexpectedError': 'An unexpected error has occurred.',
    verses: 'By Verse',
    words: 'By Word',
    'leftPanel.name': 'Original Text of the Bible',
    'rightPanel.name': 'Translations of the Bible',
    'bottomPanel.name': 'Resources for the Bible',
    revisionHistory: 'Revision History',
    'syntax.adv': 'Adverbial Modifier',
    'syntax.adjp': 'Adjectival Phrase',
    'syntax.advp': 'Adverb Phrase',
    'syntax.cl': 'Clause',
    'syntax.conj': 'Conjunction',
    'syntax.cjp': 'Conjunction',
    'syntax.det': 'Determiner',
    'syntax.ijp': 'Interjection',
    'syntax.nump': 'Numerical Phrase',
    'syntax.np': 'Noun Phrase',
    'syntax.o': 'Direct Object',
    'syntax.o2': 'Second Object',
    'syntax.oc': 'Object Complement',
    'syntax.om': 'Object Marker',
    'syntax.io': 'Indirect Object',
    'syntax.p': 'Non-Verbal Predicate',
    'syntax.pp': 'Prepositional Phrase',
    'syntax.prep': 'Preposition',
    'syntax.ptcl': 'Particle',
    'syntax.rel': 'Relative Particle',
    'syntax.s': 'Subject',
    'syntax.v': 'Verb',
    'syntax.vc': 'Linking Verb',
    'syntax.adjp.description': 'Phrase that modifies a noun or pronoun',
    'syntax.adv.description':
      'Modifies the verb, typically further defining manner, time, place, frequency, degree, etc...',
    'syntax.advp.description':
      'Phrase modifying the verb, typically further defining manner, time, place, frequency, degree, etc.',
    'syntax.cl.description': 'A group of words that makes an assertion about the subject.',
    'syntax.conj.description': 'Connects words, phrases, and clauses.',
    'syntax.cjp.description': 'Connects words, phrases, and clauses.',
    'syntax.det.description': 'Specifies, identifies, or quantifies a noun phrase.',
    'syntax.ijp.description': 'Exclamation or sudden expression of emotion.',
    'syntax.nump.description': 'Phrase that describes numerical quantity',
    'syntax.np.description': 'A phrase that has a noun as its head or that functions as a noun.',
    'syntax.o.description':
      'That which is directly affected, caused, or produced by the action of a verb.',
    'syntax.o2.description':
      'That which is directly affected, caused, or produced by the action of a verb.',
    'syntax.oc.description': 'Makes an assertion about the Direct Object.',
    'syntax.om.description': 'Particle that introduces a direct object.',
    'syntax.io.description':
      'Object that is indirectly affected by an action, often the recipient of the action of a verb.',
    'syntax.p.description':
      'Identifies or describes the subject, often (but not always) occurs together with a linking verb.',
    'syntax.pp.description': 'Modifying phrase consisting of a preposition and its object.',
    'syntax.prep.description':
      'Indicates relationship of the object of the preposition with other words, often indicating where or when.',
    'syntax.ptcl.description':
      'One member from the diverse group of function words that do not belong to any of the inflected grammatical word classes',
    'syntax.rel.description': 'Particle that introduces a relative clause.',
    'syntax.s.description':
      'The part of the clause that the rest of the clause makes an assertion about.',
    'syntax.v.description':
      'States something about the subject of the clause and may express action, event, or condition.',
    'syntax.vc.description': 'Links the subject of a clause to a subject complement.',
    'general.close': 'Close',
    'general.action': 'Action',
    'general.select': 'Select...',
    'general.back': 'Back',
    'revisionHistory.noData': 'There is no revision history for this verse.',
    'revisionHistory.translation': 'Translation',
    'revisionHistory.lastChanged': 'Last Changed',
    'sourceText.view': 'Click to view source text',
    'sourceText.viewStructure': 'Click to view source text structure',
    'error.projectIdIsRequired': 'Project ID is required.',
    'error.searchTermIsRequired': 'Search term cannot empty.',
    'error.bookIdIsRequired': 'Book ID is required to store book name.',
    'error.bookNameCannotBeEmpty': 'Book name cannot be empty.',
    'error.signInIsRequiredToAccessProject': 'You must be signed in to access your project.',
    error: 'Error',
    'error.doNotHavePermissionAccessProject': 'You do not have permission to access this project.',
    'error.unauthorized': 'Unauthorized access or invalid access token.',
    'error.tokenExpired': 'Your token has expired. Please re-login.',
    'error.invalidApiUrl': 'Invalid API url.',
    'error.badRequestInvalidJson': 'Bad request. Invalid JSON.',
    'error.windowIsNotDefined': 'Window is not defined.',
    'error.unexpectedError':
      'An unexpected error has occurred. Please close this window and try again.',
    'error.invalidDataType': 'Invalid data type.',
    'export.data': 'Export Data',
    more: 'More',
    'export.to.rtf': 'Export to RTF',
    'export.to.usfm': 'Export to USFM',
    'export.titlePreparingData': 'We are preparing your Bible translation data.',
    'export.doNotClose':
      'This process may take a while, please do not close this window and you will be notified when it is ready to download.',
    'export.desc': 'You can export and download your translation data by clicking buttons below.',
    'export.titleOfReady2Download': 'Your Bible translation data is ready.',
    'export.textOfReady2Download':
      'Please click the download button below to download your Bible translation.',
    'export.statistics': 'Statistics',
    'export.book': 'Book name',
    'export.translatedVerses': 'Translated verses',
    'export.exportedVerses': 'Exported verses',
    'export.howDoUWant2Export': 'How do you want to export data?',
    'export.whichBooks': 'Which book(s) do you want to export?',
    'export.byBooks': 'By books',
    'export.byChapters': 'By chapters',
    'export.byVerses': 'By verses',
    'export.booksInOt': 'Old Testament',
    'export.booksInNt': 'New Testament',
    'export.booksInBible': 'Whole Bible',
    'export.booksInNone': 'None',
    'export.selectRangeOfChapters': 'Range of chapters',
    'export.selectRangeOfVerses': 'Range of verses',
    'export.selectChapter': 'Select a chapter',
    'export.exportTypeIsRequired':
      'You have not yet selected about how do you want to export data?',
    'export.haveNotYetSelectedBooks': 'You have not yet selected any book.',
    'error.selectABook': 'You must select a book.',
    'error.invalidBookId': 'Invalid book ID.',
    'error.selectAChapter': 'You must select a chapter.',
    'error.invalidChapterRange': 'You must select a valid chapter range.',
    'error.invalidVerseRange': 'You must select a valid verse range.',
    'error.invalidExportType': 'Invalid export type.',
    startover: 'Start over',
    download: 'Download',
    'download.all': 'Download all files',
    'contact.header': 'Contact Us',
    'contact.returnAddress': 'Return Address',
    'contact.returnAddressPlaceholder': 'Where can we reach you?',
    'contact.subject': 'Subject',
    'contact.subjectPlaceholder': 'What do you need help with?',
    'contact.message': 'Message',
    'contact.messagePlaceholder': 'How can we help?',
    'contact.sendMessage': 'Send Message',
    'contact.success': 'Message was sent successfully.',
    'contact.failure':
      'There was a problem sending this message. Please contact help@globalbibleinitiative.org.',
    selectInterlinear: 'Click to change interlinear text',
    'warning.screenTooSmall':
      'Please switch to a higher screen resolution (minimum 1024px wide) or please widen your browser window.',
    'label.dictionary': 'Concordance Search',
    'dict.search.placeholder': 'Enter word(s)',
    'tooltip.manuscript': 'Manuscript',
    'tooltip.strongsx': "Strong's Number",
    'tooltip.translation': 'Translation',
    'tooltip.english': 'English',
    'title.howToSearch': 'How to use concordance search',
    'desc.howToSearch':
      'Enter word(s) from the Greek or Hebrew, word(s) from your translation, or Strong Number(s). Or click on the Greek or Hebrew words in the panel of "Your translation" or Alignment views for search options.',
    'dict.noDataToDisplay': 'There are no data that match your search criteria.',
    'title.searchTermTooMany': 'You have entered too many keywords',
    'desc.searchTermTooMany': 'You can search up to 8 keywords each time.',
    'title.searchDictError': 'An unexpected error has occurred.',
    'desc.searchDictError': 'Please try again later or contact customer support',
    'alignment.saveAndEditAlignment': 'Save and Align New Text',
    'alignment.reviewAlignment': 'Review Text Alignment',
    'dict.searchLemma': 'Click to search original word',
    'dict.searchTranslation': 'Click to search translation word',
    'dict.searchStrongsX': "Click to search Strong's number",
    'dict.showMenu': 'Click to show full text of occurrences in context.',
    'dict.hideMenu': 'Click to hide full text of occurrences in context.',
  },
  am: {
    'languages.en': 'እንግሊዝኛ',
    'languages.am': 'አማርኛ',
    'languages.bn': 'ቤንጋሊ ቋንቋ',
    'languages.ceb': 'ሴብዋኖ ቋንቋ',
    'languages.de': 'የጀርመን ቋንቋ',
    'languages.es-419': 'ስፓኒሽ ቋንቋ',
    'languages.fr': 'ፈረንሳይኛ ቋንቋ',
    'languages.gu': 'ጉጅራቲ',
    'languages.hi': 'ሂንዲ',
    'languages.hr': 'ክሮኤሽያኛ ቋንቋ',
    'languages.hu': 'ሀንጋሪኛ ቋንቋ',
    'languages.id': 'የኢንዶኔዥያ ቋንቋ',
    'languages.km': 'ክመርኛ',
    'languages.kn': 'ካናዳ',
    'languages.ko': 'የኮሪያ ቋንቋ',
    'languages.lo': 'የላኦስ ቋንቋ',
    'languages.ml': 'ማላያላም',
    'languages.mr': 'ማራቲ',
    'languages.ms': 'የማሌዥያ',
    'languages.my': 'በበርማ ቋንቋ',
    'languages.ne': 'ኔፓሊኛ ቋንቋ',
    'languages.plt': 'የማደጋስካር ቋንቋ',
    'languages.pt-br': 'ፖርቱጋልኛ (ብራዚል) ቋንቋ',
    'languages.ru': 'የሩስያ ቋንቋ',
    'languages.sw': 'ስዋሕሊ',
    'languages.ta': 'የታሚል ቋንቋ',
    'languages.th': 'የታይ ቋንቋ',
    'languages.tl': 'ታንጋሎግ',
    'languages.te': 'Telegu ቋንቋ',
    'languages.vi': 'ቪትናምኛ ቋንቋ',
    'languages.zh-hans': 'ቻይንኛ ቋንቋ (ቀለል ያለ)',
    'languages.zh-hant': 'ቻይንኛ ቋንቋ (ባህላዊ)',
    'header.home': 'መኖሪያ ቤት',
    'header.help': 'እርዳታ',
    'header.project': 'ፕሮጀክት',
    'header.contact': 'ያግኙን',
    'help.contactSupport': 'እርዳታ ለማግኘት help@youtranslate.bible ላይ ኢሜይል ይላኩልን.',
    'home.ytbProvides': 'YouTranslate.Bible በነፃ ይሰጣል:',
    'home.easyToUseTool': 'አንድ ቀላል-ወደ-ለመጠቀም መስመር ላይ የትርጉም መሣሪያ',
    'home.originalTextHelp': 'እገዛ መጀመሪያ በተጻፈባቸው ቋንቋዎች ውስጥ የመጽሐፍ ቅዱስ ጽሑፍ ለመረዳት',
    'home.referencesAndResources': 'ማጣቀሻ እና ስልጠና ሀብቶች',
    'home.computerAssistance': 'መንግስት-ኦቭ-ዘ-አርት የኮምፒውተር ቴክኖሎጂ ተርጓሚዎችን ለመርዳት',
    'profile.userProfile': 'የተጠቃሚ መገለጫ',
    'profile.language': 'ቋንቋ',
    switchDirection: 'ቀይር የጽሑፍ አቅጣጫ',
    'profile.update': 'አዘምን',
    'profile.languageWarning':
      'የ Google ለጊዜው ከእንግሊዝኛ ባሻገር በሌላ ቋንቋዎች የተጠቃሚ በይነገጽ ለመተርጎም ውሏል ተርጉም. YTB ​​ትርጉሙ ትክክለኛነት ወይም አስተማማኝነት ላይ ምንም ዓይነት ቁጥጥር የለውም. ከ Google ማግኘት የተተረጎመው ስያሜዎች እና ማብራሪያ ላይ ተመርኩዘው ማንኛውም ሰው መተርጎም እሱ ወይም እሷ በራሱ አደጋ ላይ እንዲሁ ያደርጋል. ማንም ከእንግሊዝኛ ባሻገር በሌላ ቋንቋ ወደ የተጠቃሚ በይነገጽ ይበልጥ ትክክለኛ እና አስተማማኝ ትርጉም ማቅረብ በፈቃደኝነት የመሥራት ፍላጎት ከሆነ, GBI ኢሜይል ለመላክ ያግኙን አማራጭ ላይ ጠቅ ያድርጉ.',
    'usernav.profile': 'መገለጫ',
    'usernav.logout': 'ውጣ',
    'usernav.logout.modalTitle': 'YouTranslate.Bible መካከል አስወጣ',
    'usernav.logout.modalText': 'እርስዎ ለመውጣት እንደሚፈልጉ እርግጠኛ ነዎት? ሁሉም ያልዳነው ሥራ ይጠፋል.',
    cancel: 'ሰርዝ',
    delete: 'ሰርዝ',
    create: 'ፈጠረ',
    update: 'አዘምን',
    edit: 'አርትዕ',
    save: 'አስቀምጥ',
    add: 'ያክሉ',
    translate: 'ተርጉም',
    'more.options': 'ተጨማሪ አማራጮች',
    'project.create.title': 'የመጽሐፍ ቅዱስ ትርጉም ፕሮጀክት',
    'project.create.list': 'የፕሮጀክት ዝርዝር',
    'project.list.deleteProject': 'ፕሮጀክት ሰርዝ?',
    'project.list.areYouSure': 'ይህን ፕሮጀክት መሰረዝ ይፈልጋሉ እርግጠኛ ነዎት?',
    'project.list.workWillBeDeleted': 'ፕሮጀክቱ እና የተጎዳኙ ማንኛውም ሥራ ይሰረዛል.',
    'project.list.completion': 'መጠናቀቅ:',
    'project.list.createdDate': 'የተፈጠረ ቀን:',
    'project.list.beginningDate': 'ቀን ጀምሮ:',
    'project.list.targetCompletionDate': 'የዒላማ ማጠናቀቂያ ቀን:',
    'project.list.description': 'መግለጫ:',
    'project.edit': 'ፕሮጀክት አርትዕ',
    'project.list.translateBible': 'የመጽሐፍ ቅዱስ መተርጎም',
    'project.list.empty': 'የእርስዎ የትርጉም ፕሮጀክት ዝርዝር ባዶ ነው.',
    'project.list.myProjects': 'የእኔ የመጽሐፍ ቅዱስ ትርጉም ፕሮጀክቶች',
    'project.list.newProject': 'አዲስ ፕሮጀክት',
    'project.create.createNew': 'አዲስ ፕሮጀክት ፍጠር',
    'project.namePlaceholder': 'የፕሮጀክት ስም ያስገቡ',
    'project.descriptionPlaceholder': 'የፕሮጀክት መግለጫ አስገባ',
    'project.name': 'የፕሮጀክት ስም',
    'project.beginningDate': 'ጀምሮ ቀን',
    'project.targetCompletionDate': 'ዒላማ ማጠናቀቂያ ቀን',
    'project.description': 'መግለጫ',
    'project.isRTL': 'ትርጉም ከቀኝ-ወደ-ግራ ነው',
    'project.manage-users': 'ተርጓሚዎች ያቀናብሩ',
    'project.new-user': 'አዲስ ተርጓሚ',
    'project.add-user': 'ተጨማሪው ተርጓሚ',
    'project.users': 'የፕሮጀክት ተርጓሚዎች',
    'project.read-only': 'ለማንበብ ብቻ የተፈቀደ',
    'project.read&write': 'አንብብ እና ፃፍ',
    'project.owner': 'ባለቤት',
    'project.translator': 'አስተርጓሚ',
    'project.message.no-such-user': 'ምንም እንደዚህ ተርጓሚ አለ',
    'project.message.user-added': 'ተርጓሚ በተሳካ ሁኔታ ታክሏል',
    'project.message.user-deleted': 'ተርጓሚ ተወግዷል.',
    'project.message.only-owner-can-manage-users': 'ተጠቃሚዎችን ማስተዳደር የሚችለው ብቻ የፕሮጀክቱ ባለቤት',
    'project.shared': 'የትብብር ፕሮጄክት',
    'general.delete-user': 'ሰርዝ ተርጓሚ',
    'general.edit-user': 'ተጠቃሚን ያርትዑ',
    'general.name': 'ስም',
    'general.email': 'ኢ-ሜል',
    'general.permissions': 'ፍቃዶች',
    'general.role': 'ሚና',
    signin: 'ስግን እን',
    'signin.emailPlaceholder': 'የ ኢሜል አድራሻ',
    'signin.passwordPlaceholder': 'የይለፍ ቃል',
    'signin.forgotPassword': 'የሚስጥራዊውን ቁጥር ረሳህው?',
    'signin.facebook': 'ፌስቡክ ጋር ይግቡ',
    'signin.google': 'በ Google ይግቡ',
    'signin.dontHaveAccount': 'መለያ የለህም?',
    'signin.createOne': 'አንድ ፍጠር',
    'signup.join': 'YouTranslate.Bible ይቀላቀሉ',
    'signup.createAccountPrompt': 'ለመጀመር የ ነጻ መለያ ፍጠር.',
    'signup.createAccount': 'መለያ ፍጠር',
    'signup.emailPlaceholder': 'የ ኢሜል አድራሻ',
    'signup.passwordPlaceholder': 'የይለፍ ቃል',
    'passwordReset.title': 'የይለፍ ቃል ዳግም አስጀምር',
    'passwordReset.prompt': 'የይለፍ ቃልዎን ዳግም ለማስጀመር መመሪያዎች ጋር አንድ ኢሜይል ይጠይቁ.',
    'passwordReset.emailPlaceholder': 'የ ኢሜል አድራሻ',
    'passwordReset.requestButtonLabel': 'ጥያቄ ኢሜይል',
    'passwordReset.success': 'ኢሜይል ተልኳል.',
    'passwordReset.error': 'ስህተት ተፈጥሯል.',
    'translation.project': 'ፕሮጀክት:',
    'translation.backToProjectList': 'ፕሮጀክት ዝርዝር ተመለስ',
    'translation.book': 'መጽሐፍ:',
    'translation.chapter': 'ምዕራፍ:',
    'translation.prompt': 'ይህን ምዕራፍ መተው ይፈልጋሉ እርግጠኛ ነዎት? ሁሉም ያልዳነው ሥራ ይጠፋል.',
    'translation.prev': 'የቀድሞው',
    'translation.next': 'ቀጣይ',
    'translation.previousChapter': 'ቀዳሚ ምዕራፍ',
    'translation.nextChapter': 'በሚቀጥለው ምዕራፍ',
    'navigator.book': 'መጽሐፍ:',
    'navigator.chapter': 'ምዕራፍ:',
    'navigator.oldTestament': 'ብሉይ ኪዳን',
    'navigator.newTestament': 'አዲስ ኪዳን',
    'navigator.selectBook': 'አንድ መጽሐፍ ይምረጡ',
    'navigator.search': 'ፈልግ',
    'navigator.changeBook': 'ለውጥ መጽሐፍ',
    'navigator.changeChapter': 'ለውጥ ምዕራፍ',
    Genesis: 'ዘፍጥረት',
    Exodus: 'ዘፀአት',
    Leviticus: 'ዘሌዋውያን',
    Numbers: 'ቁጥሮች',
    Deuteronomy: 'ዘዳግም',
    Joshua: 'ኢያሱ',
    Judges: 'ዳኞች',
    Ruth: 'ሩት',
    '1 Samuel': '1 ሳሙኤል',
    '2 Samuel': '2 ሳሙኤል',
    '1 Kings': '1 ነገሥት',
    '2 Kings': '2 ነገሥት',
    '1 Chronicles': '1 ዜና መዋዕል',
    '2 Chronicles': '2 ዜና መዋዕል',
    Ezra: 'ዕዝራ',
    Nehemiah: 'ነህምያ',
    Esther: 'አስቴር',
    Job: 'ሥራ',
    Psalms: 'መዝሙረ',
    Proverbs: 'ምሳሌ',
    Ecclesiastes: 'መክብብ',
    'Song of Songs': 'መኃልየ መኃልይ',
    Isaiah: 'ኢሳይያስ',
    Jeremiah: 'ኤርምያስ',
    Lamentations: 'ሰቆቃወ',
    Ezekiel: 'ሕዝቅኤል',
    Daniel: 'ዳንኤል',
    Hosea: 'ሆሴዕ',
    Joel: 'ኢዩኤል',
    Amos: 'አሞጽ',
    Obadiah: 'አብድዩ',
    Jonah: 'ዮናስ',
    Micah: 'ሚክያስ',
    Nahum: 'ናሆም',
    Habakkuk: 'ዕንባቆም',
    Zephaniah: 'ሶፎንያስ',
    Haggai: 'ሐጌ',
    Zechariah: 'ዘካርያስ',
    Malachi: 'ሚልክያስ',
    Matthew: 'በማቴዎስ',
    Mark: 'ምልክት',
    Luke: 'ሉቃስ',
    John: 'ዮሐንስ',
    Acts: 'የሐዋርያት ሥራ',
    Romans: 'ወደ ሮሜ ሰዎች',
    '1 Corinthians': '1 ቆሮንቶስ',
    '2 Corinthians': '2 ቆሮንቶስ',
    Galatians: 'ገላትያ',
    Ephesians: 'ወደ ኤፌሶን ሰዎች',
    Philippians: 'ወደ ፊልጵስዩስ ሰዎች',
    Colossians: 'ቆላስይስ',
    '1 Thessalonians': '1 ተሰሎንቄ',
    '2 Thessalonians': '2 ተሰሎንቄ',
    '1 Timothy': '1 ጢሞቴዎስ',
    '2 Timothy': '2 ጢሞቴዎስ',
    Titus: 'ቲቶ',
    Philemon: 'ፊልሞና',
    Hebrews: 'ዕብራውያን',
    James: 'ያዕቆብ',
    '1 Peter': '1 የጴጥሮስ',
    '2 Peter': '2 የጴጥሮስ',
    '1 John': '1 ዮሐንስ',
    '2 John': '2 ዮሐንስ',
    '3 John': '3 ዮሐንስ',
    Jude: 'ይሁዳ',
    Revelation: 'የዮሐንስ ራእይ',
    'resources.prompt': 'የሚተረጉሙትን ምንባብ ተጨማሪ ማስተዋል ለማግኘት መጽሐፍ ቅዱሳዊ ምንጮች ያክሉ.',
    'resources.add': 'አንድ የመጽሐፍ ቅዱስ ሀብት ያክሉ',
    Manuscript: 'የእጅ ጽሑፍ',
    Morphology: 'ሞርፎሎጂ',
    Lexicon: 'ሌክሲከን',
    'Syntactic Tree': 'Syntactic ዛፍ',
    'Go to project list': 'ፕሮጀክት ዝርዝር ይሂዱ',
    'Project not found': 'ፕሮጀክት አልተገኘም ወይም በቂ ፍቃዶች አይደለም.',
    'error.referenceDoesNotExistForResource': 'ይህ የመጽሐፍ ቅዱስ ትርጉም ለተመረጠው ምንባብ የሚሆን ይዘት የለውም.',
    'references.addAnother': 'ሌላ ትርጉም አክል',
    'references.referencePrompt': 'ተጨማሪ ማስተዋል ለማግኘት ሌሎች የመጽሐፍ ቅዱስ ትርጉሞችን ያክሉ.',
    'references.add': 'አንድ የመጽሐፍ ቅዱስ ትርጉም አክል',
    'references.licensing': 'የፈቃድ መረጃ',
    'references.licensingEmpty': 'ምንም የፈቃድ መረጃዎች ለዚህ ንጥል አልተገኘም.',
    'translation.complete': 'ትርጉም ተጠናቅቋል',
    Alignment: 'ስለፋ',
    Share: 'አጋራ',
    'Your Translation': 'የእርስዎ ትርጉም',
    'Enter your translation': 'የእርስዎ ትርጉም ያስገቡ',
    OK: 'እሺ',
    'error.verseTranslationEmpty': 'አንተ የትርጉም ይዘት ባዶ መተው አይችሉም.',
    'translation.hide.completed': 'ደብቅ ተጠናቋል',
    alignment: 'ስለፋ',
    'alignment.verify': 'አረጋግጥ እና ዝጋ.',
    'alignment.close': 'ማረጋገጫ ያለ ዝጋ',
    'alignment.backToEditor': 'አርታኢ ተመለስ',
    'alignment.error': 'የሆነ ስህተት ተከስቷል. ችግሩ ከቀጠለ, YouTranslate.Bible ቡድን ያነጋግሩ.',
    'alignment.add': 'አገናኝ አክል',
    'alignment.delete': 'አገናኝ አስወግድ',
    'alignment.clear': 'ምርጫ አጽዳ',
    'alignment.reverse': 'ግልብጥ ማሳያ',
    'alignment.removeOrClear': 'የተመረጠው አገናኝ ወይም ግልጽ ምርጫ አስወግድ.',
    'alignment.addOrRemove': 'አዲስ አገናኝ ይፍጠሩ ወይም ቀዳሚ አንድ ሰርዝ.',
    'alignment.selectAndAdd': 'አገናኝ ወደ አንጓዎች ተጓዳኝ ይምረጡ.',
    'error.mustBeSignedToMakeChanges': 'እንድታደርገው ለውጦች ውስጥ መግባት አለበት.',
    'error.mustBeSignedToTranslateBible': 'መጽሐፍ ቅዱስ ለመተርጎም ውስጥ መግባት አለብዎት.',
    checking: 'በማረጋገጥ ላይ',
    'verseEditor.unexpectedError': 'አንድ ያልተጠበቀ ስህተት ተከስቷል.',
    verses: 'ቁጥር በ',
    words: 'ቃል',
    'leftPanel.name': 'የመጽሐፍ ቅዱስ የመጀመሪያው ጽሑፍ',
    'rightPanel.name': 'መጽሐፍ ቅዱስ ትርጉሞች',
    'bottomPanel.name': 'መጽሐፍ ቅዱስ ስለ መርጃዎች',
    revisionHistory: 'የክለሳ ታሪክ',
    'syntax.adv': 'Adverbial መቀየሪያ',
    'syntax.adjp': 'ገላጭ ሐረግ',
    'syntax.advp': 'ተውሳከ ሐረግ',
    'syntax.cl': 'አንቀጽ',
    'syntax.conj': 'መስተፃምር',
    'syntax.cjp': 'መስተፃምር',
    'syntax.det': 'Determiner',
    'syntax.ijp': 'ተጠራጣሪው',
    'syntax.nump': 'የቁጥር ሐረግ',
    'syntax.np': 'ስም ሐረግ',
    'syntax.o': 'ቀጥታ የነገር',
    'syntax.o2': 'ሁለተኛ የነገር',
    'syntax.oc': 'የነገር ማሟያ',
    'syntax.om': 'የነገር ምልክት ማድረጊያ',
    'syntax.io': 'በተዘዋዋሪ የነገር',
    'syntax.p': 'ያልሆነ-ቃላዊ ተሳቢው',
    'syntax.pp': 'Prepositional ሐረግ',
    'syntax.prep': 'መስተዋድድ',
    'syntax.ptcl': 'ፓርቲክል',
    'syntax.rel': 'አንጻራዊ ፓርቲክል',
    'syntax.s': 'ትምህርት',
    'syntax.v': 'ግሥ',
    'syntax.vc': 'ግሥ, በሐረጉ',
    'syntax.adjp.description': 'አንድ ስም ወይም ተውላጠ ስም ያሻሽላል መሆኑን ሐረግ',
    'syntax.adv.description': 'ይቀይረዋል ግስ, በተለምዶ ተጨማሪ ተዘግቦ መንገድ, ጊዜ, ቦታ, ድግግሞሽ, ዲግሪ, ወዘተ ...',
    'syntax.advp.description': 'ሐረግ በተለምዶ ተጨማሪ, ግስ መቀየር ተዘግቦ መንገድ, ጊዜ, ቦታ, ድግግሞሽ, ዲግሪ, ወዘተ',
    'syntax.cl.description': 'ርዕሰ ስለ የተጨመረ ነው የሚያደርገው ቃላት አንድ ቡድን.',
    'syntax.conj.description': 'ቃላት, ሐረጎች, እና ሐረጎች ያገናኛል.',
    'syntax.cjp.description': 'ቃላት, ሐረጎች, እና ሐረጎች ያገናኛል.',
    'syntax.det.description': 'ይገልጻል, ይለያል, ወይም quantifies አንድ ስም ሐረግ.',
    'syntax.ijp.description': 'አጋኖ ወይም ስሜት የተነሳ ድንገተኛ መግለጫ.',
    'syntax.nump.description': 'የቁጥር ብዛት የሚገልጽ ሐረግ',
    'syntax.np.description': 'በውስጡ ራስ እንደ አንድ ስም ወይም ስም እንደ ተግባራት ያለው ሐረግ.',
    'syntax.o.description': 'ይህ ይህም በቀጥታ, ተጽዕኖ ምክንያት, ወይም ግስ ድርጊት በ ምርት ነው.',
    'syntax.o2.description': 'ይህ ይህም በቀጥታ, ተጽዕኖ ምክንያት, ወይም ግስ ድርጊት በ ምርት ነው.',
    'syntax.oc.description': 'ቀጥተኛ ተሳቢ ስለ የተጨመረ ነው ያደርገዋል.',
    'syntax.om.description': 'በቀጥታ ነገር ያስተዋውቃል መሆኑን ፓርቲክል.',
    'syntax.io.description': 'በተዘዋዋሪ እርምጃ ተጽዕኖ ነው ነገር, አንድ ግስ ያለውን እርምጃ በአብዛኛው ተቀባይ.',
    'syntax.p.description':
      'ይለያል ወይም ርዕሰ ጉዳይ, ይገልጻል ብዙውን ጊዜ (ግን ሁልጊዜ አይደለም) አንድ የሚያገናኙ ግስ ጋር አብሮ የሚከሰተው.',
    'syntax.pp.description': 'አንድ መስተዋድድ እና ነገር ባካተተ በመቀየር ሐረግ.',
    'syntax.prep.description':
      'ብዙውን ጊዜ የት ወይም መቼ የሚያመላክት, ከሌሎች ቃላት ጋር መስተዋድድ ያለውን ዕቃ ግንኙነት ያመለክታል.',
    'syntax.ptcl.description':
      'የ ረብተው ሰዋሰዋዊ ቃል ክፍሎች ማንኛውም ወደ የእሱ ያልሆነውን ተግባር ቃላት የተለያዩ ቡድን አንድ አባል',
    'syntax.rel.description': 'አንጻራዊ አንቀጽ ያስተዋውቃል መሆኑን ፓርቲክል.',
    'syntax.s.description': 'ወደ ሐረግ በቀሪው ስለ የተጨመረ ነው ያደርጋል የሚል ሐረግ ክፍል.',
    'syntax.v.description': 'ወደ ሐረግ ያለውን ጉዳይ በተመለከተ አንድ ነገር ስቴትስ እና እርምጃ, ክስተት ወይም ሁኔታ መግለጽ ይችላል.',
    'syntax.vc.description': 'በአንድ ርዕሰ ጉዳይ ማሟያ አንድ ሐረግ ርዕሰ ጉዳይ ያገናኛል.',
    'general.close': 'ገጠመ',
    'general.action': 'አክሽን',
    'general.select': 'ምረጥ ...',
    'general.back': 'ወደኋላ',
    'revisionHistory.noData': 'ይህ ጥቅስ ምንም የለውጥ ታሪክ የለም.',
    'revisionHistory.translation': 'ትርጉም',
    'revisionHistory.lastChanged': 'ተቀይሯል ለመጨረሻ',
    'sourceText.view': 'ምንጭ ጽሑፍ ለማየት ጠቅ አድርግ',
    'sourceText.viewStructure': 'ምንጭ ጽሑፍ መዋቅር ለማየት ጠቅ ያድርጉ',
    'error.projectIdIsRequired': 'የፕሮጀክት መታወቂያ ያስፈልጋል.',
    'error.searchTermIsRequired': 'የፍለጋ ቃል ባዶ አይችልም.',
    'error.bookIdIsRequired': 'መጽሐፍ መታወቂያ መጽሐፍ ስም ማከማቸት ያስፈልጋል.',
    'error.bookNameCannotBeEmpty': 'መጽሐፍ ስም ባዶ ሊሆን አይችልም.',
    'error.signInIsRequiredToAccessProject': 'የእርስዎ ፕሮጀክት ለመድረስ ውስጥ መግባት አለብዎት.',
    error: 'ስሕተት',
    'error.doNotHavePermissionAccessProject': 'ይህን ፕሮጀክት ለመድረስ ፈቃድ የልዎትም.',
    'error.unauthorized': 'ያልተፈቀደ መዳረሻ ወይም ልክ ያልሆነ መዳረሻ ማስመሰያ.',
    'error.tokenExpired': 'ማስመሰያህ ጊዜ አልፎበታል. ዳግም-መግቢያ እባክህ.',
    'error.invalidApiUrl': 'ልክ ያልሆነ ኤ ፒ አር ኤል.',
    'error.badRequestInvalidJson': 'ወድቅ ጥያቄ. ልክ ያልሆነ JSON.',
    'error.windowIsNotDefined': 'መስኮት የተገለጸ አይደለም.',
    'error.unexpectedError': 'አንድ ያልተጠበቀ ስህተት ተከስቷል. ይህን መስኮት ይዝጉ እና እንደገና ይሞክሩ.',
    'error.invalidDataType': 'ልክ ያልሆነ የውሂብ አይነት.',
    'export.data': 'ውሂብ ወደ ውጪ ላክ',
    more: 'ይበልጥ',
    'export.to.rtf': 'RTF ላክ',
    'export.to.usfm': 'USFM ላክ',
    'export.titlePreparingData': 'የእርስዎን የመጽሐፍ ቅዱስ ትርጉም ውሂብ ማዘጋጀት ነው.',
    'export.doNotClose':
      'ይህ ሂደት አንድ ጊዜ ሊወስድ ይችላል, አይደለም ዝጋ ይህን መስኮት ማድረግ እና ለማውረድ ዝግጁ ሲሆን ማሳወቂያ ይደረጋል እባክህ.',
    'export.desc': 'አንተ መላክ እና በታች አዝራሮች ጠቅ በማድረግ የእርስዎን የትርጉም ውሂብ ማውረድ ይችላሉ.',
    'export.titleOfReady2Download': 'የእርስዎ የመጽሐፍ ቅዱስ ትርጉም ውሂብ ዝግጁ ነው.',
    'export.textOfReady2Download': 'የ የመጽሐፍ ቅዱስ ትርጉም ለማውረድ ከታች ያለውን አውርድ አዝራር ጠቅ ያድርጉ.',
    'export.statistics': 'ስታቲስቲክስ',
    'export.book': 'መጽሐፍ ስም',
    'export.translatedVerses': 'የተተረጎሙ ጥቅሶች',
    'export.exportedVerses': 'ወደ ውጭ ጥቅሶች',
    'export.howDoUWant2Export': 'እንዴት ውሂብ ወደ ውጪ ለመላክ ይፈልጋሉ?',
    'export.whichBooks': 'እርስዎ ወደ ውጪ መላክ የሚፈልጉት የትኛው መጽሐፍ (ዎች)?',
    'export.byBooks': 'መጽሐፍት በ',
    'export.byChapters': 'ምዕራፎች በ',
    'export.byVerses': 'ጥቅሶች',
    'export.booksInOt': 'ብሉይ ኪዳን',
    'export.booksInNt': 'አዲስ ኪዳን',
    'export.booksInBible': 'መላው መጽሐፍ ቅዱስ',
    'export.booksInNone': 'አንድም',
    'export.selectRangeOfChapters': 'ምዕራፎች ክልል',
    'export.selectRangeOfVerses': 'ጥቅሶች ክልል',
    'export.selectChapter': 'አንድ ምዕራፍ ምረጥ',
    'export.exportTypeIsRequired': 'አንተ የኤክስፖርት ውሂብ ይፈልጋሉ እንዴት እስካሁን ስለ አልመረጡም?',
    'export.haveNotYetSelectedBooks': 'ገና ምንም መጽሐፍ አልመረጡም.',
    'error.selectABook': 'አንድ መጽሐፍ መምረጥ አለብዎት.',
    'error.invalidBookId': 'ልክ ያልሆነ መጽሐፍ መታወቂያ.',
    'error.selectAChapter': 'አንድ ምዕራፍ መምረጥ አለብዎት.',
    'error.invalidChapterRange': 'የሚሰራ ምዕራፍ ክልል መምረጥ አለብዎት.',
    'error.invalidVerseRange': 'ልክ የሆነ ቁጥር ክልል መምረጥ አለብዎት.',
    'error.invalidExportType': 'ልክ ያልሆነ የኤክስፖርት አይነት.',
    startover: 'እንደገና ጀምር',
    download: 'አውርድ',
    'download.all': 'ሁሉንም ፋይሎችን አውርድ',
    'contact.header': 'አግኙን',
    'contact.returnAddress': 'ተመለስ አድራሻ',
    'contact.returnAddressPlaceholder': 'የት እኛ መድረስ እንችላለን?',
    'contact.subject': 'ትምህርት',
    'contact.subjectPlaceholder': 'ምን እርዳታ ያስፈልገኛል?',
    'contact.message': 'መልእክት',
    'contact.messagePlaceholder': 'እኛ ምን ሊረዳህ ይችላል?',
    'contact.sendMessage': 'መልዕክት ላክ',
    'contact.success': 'መልዕክት በተሳካ ሁኔታ ተልኳል.',
    'contact.failure': 'ይህን መልዕክት በመላክ ላይ ችግር ነበር. help@globalbibleinitiative.org ያነጋግሩ.',
    selectInterlinear: 'ኢንተርሊኒየር ጽሑፍ ለመቀየር ጠቅ አድርግ',
    'warning.screenTooSmall': 'ከፍተኛ ማያ ጥራት (ሰፊ ቢያንስ 1024px) ይቀይሩ ወይም የአሳሽ መስኮት ለማስፋት እባክህ.',
    'label.dictionary': 'ኮንኮርዳንስ ፍለጋ',
    'dict.search.placeholder': 'ቃል (ዎች) ያስገቡ',
    'tooltip.manuscript': 'የእጅ ጽሑፍ',
    'tooltip.strongsx': 'ጠንካራ ቁጥር',
    'tooltip.translation': 'ትርጉም',
    'tooltip.english': 'እንግሊዝኛ',
    'title.howToSearch': 'ኮንኮርዳንስ ፍለጋ መጠቀም እንደሚቻል',
    'desc.howToSearch':
      'የግሪክ ወይም የዕብራይስጥ, ቃል (ዎች) የትርጉም ጀምሮ, ወይም ጠንካራ ቁጥር (ዎች) ከ ቃል (ዎች) ያስገቡ. ወይም "የእርስዎ ትርጉም" ወይም የፍለጋ አማራጮች አሰላለፍ እይታዎች ፓነል ውስጥ የግሪክ ወይም የዕብራይስጥ ቃላት ላይ ጠቅ ያድርጉ.',
    'dict.noDataToDisplay': 'የእርስዎን የፍለጋ መስፈርት የሚመሳሰሉ ምንም ውሂብ የለም.',
    'title.searchTermTooMany': 'በጣም ብዙ ቁልፍ ቃላት አስገብተዋል',
    'desc.searchTermTooMany': 'የ 8 ቁልፍ ቃላት በእያንዳንዱ ጊዜ ድረስ መፈለግ ይችላሉ.',
    'title.searchDictError': 'አንድ ያልተጠበቀ ስህተት ተከስቷል.',
    'desc.searchDictError': 'ቆይተው እንደገና ይሞክሩ ወይም የደንበኞች ድጋፍ እባክዎ',
    'alignment.saveAndEditAlignment': 'አስቀምጥ እና አሰልፍ አዲስ ጽሑፍ',
    'alignment.reviewAlignment': 'የግምገማ ጽሑፍ አሰላለፍ',
    'dict.searchLemma': 'የመጀመሪያው ቃል ለመፈለግ ጠቅ ያድርጉ',
    'dict.searchTranslation': 'የትርጉም ቃል ለመፈለግ ጠቅ ያድርጉ',
    'dict.searchStrongsX': 'ጠንካራ ቁጥር ለመፈለግ ጠቅ ያድርጉ',
    'dict.showMenu': 'አውድ ውስጥ ክስተቶች ሙሉ ጽሁፍ ለማሳየት ጠቅ ያድርጉ.',
    'dict.hideMenu': 'አውድ ውስጥ ክስተቶች ሙሉ ጽሑፍ ለመደበቅ ጠቅ ያድርጉ.',
  },
  bn: {
    'languages.en': 'ইংরেজি',
    'languages.am': 'আমহারিক',
    'languages.bn': 'বাংলা ভাষা',
    'languages.ceb': 'চেবুয়ানো ভাষা',
    'languages.de': 'জার্মান ভাষা',
    'languages.es-419': 'স্প্যানিশ ভাষা',
    'languages.fr': 'ফ্রঞ্চ ভাষা',
    'languages.gu': 'গুজরাটি',
    'languages.hi': 'হিন্দি',
    'languages.hr': 'ক্রোয়েশীয় ভাষা',
    'languages.hu': 'হাঙ্গেরীয় ভাষা',
    'languages.id': 'ভাষা ইন্দোনেশীয়',
    'languages.km': 'খেমের',
    'languages.kn': 'কন্নড',
    'languages.ko': 'কোরিয়ান ভাষা',
    'languages.lo': 'লাওসের ভাষা',
    'languages.ml': 'মালায়ালম',
    'languages.mr': 'মারাঠি',
    'languages.ms': 'মালয়েশিয়ার',
    'languages.my': 'বর্মি ভাষা',
    'languages.ne': 'নেপালি ভাষা',
    'languages.plt': 'মালাগাসি ভাষা',
    'languages.pt-br': 'পর্তুগিজ (ব্রাজিল) ভাষা',
    'languages.ru': 'রুশ ভাষা',
    'languages.sw': 'সোয়াহিলি',
    'languages.ta': 'তামিল ভাষা',
    'languages.th': 'থাই ভাষা',
    'languages.tl': 'তাগালোগ',
    'languages.te': 'তেলেগু ভাষা',
    'languages.vi': 'ভাষা ভিয়েতনামী',
    'languages.zh-hans': 'চীনা ভাষা (সরলীকৃত)',
    'languages.zh-hant': 'চীনা ভাষা (ঐতিহ্যবাহী)',
    'header.home': 'বাড়ি',
    'header.help': 'সাহায্য',
    'header.project': 'প্রকল্প',
    'header.contact': 'যোগাযোগ',
    'help.contactSupport': 'দয়া করে আমাদের সহায়তার জন্য help@youtranslate.bible এ ইমেইল করুন।',
    'home.ytbProvides': 'YouTranslate.Bible অবাধে প্রদান করে:',
    'home.easyToUseTool': 'একটি সহজ-থেকে-ব্যবহার অনলাইন অনুবাদ সরঞ্জাম',
    'home.originalTextHelp': 'তার মূল ভাষায় বাইবেল টেক্সট বুঝতে সাহায্য',
    'home.referencesAndResources': 'রেফারেন্স ও প্রশিক্ষণ সম্পদ',
    'home.computerAssistance': 'রাজ্য-অত্যাধুনিক কম্পিউটার প্রযুক্তি অনুবাদক সহায়তা করার',
    'profile.userProfile': 'ব্যাবহারকারীর বিস্তারিত',
    'profile.language': 'ভাষা',
    switchDirection: 'সুইচ পাঠ্যের দিকবিন্যাস',
    'profile.update': 'হালনাগাদ',
    'profile.languageWarning':
      'Google Translate ইংরেজি সাময়িকভাবে ছাড়া অন্য ভাষায় ইউজার ইন্টারফেস অনুবাদ করতে ব্যবহৃত হয়। YTB ​​নির্ভুলতা বা অনুবাদ নির্ভরযোগ্যতা উপর কোন নিয়ন্ত্রণ আছে। যে কেউ অনূদিত লেবেল ও ব্যাখ্যা Google থেকে প্রাপ্ত উপর নির্ভর অনুবাদ তার নিজের ঝুঁকিতে তাই আছে। যে কেউ ইংরেজি ছাড়া অন্য ভাষায় ইউজার ইন্টারফেস আরো সঠিক এবং নির্ভরযোগ্য অনুবাদ প্রদান স্বেচ্ছাসেবী আগ্রহী হলে যোগাযোগ করুন বিকল্প ক্লিক করুন GBI একটি ইমেল পাঠান।',
    'usernav.profile': 'প্রোফাইল',
    'usernav.logout': 'প্রস্থান',
    'usernav.logout.modalTitle': 'YouTranslate.Bible এর লগআউট',
    'usernav.logout.modalText': 'আপনি লগ আউট করতে চান? সমস্ত অসংরক্ষিত কাজ হারিয়ে যাবে।',
    cancel: 'বাতিল',
    delete: 'মুছে ফেলা',
    create: 'সৃষ্টি',
    update: 'হালনাগাদ',
    edit: 'সম্পাদন করা',
    save: 'সংরক্ষণ',
    add: 'যোগ',
    translate: 'অনুবাদ করা',
    'more.options': 'আরও বিকল্প',
    'project.create.title': 'বাইবেল অনুবাদ প্রকল্প',
    'project.create.list': 'প্রকল্পের তালিকা',
    'project.list.deleteProject': 'প্রকল্প মুছে ফেলতে চান?',
    'project.list.areYouSure': 'আপনি এই প্রকল্পের মুছে ফেলতে চান আপনি কি নিশ্চিত?',
    'project.list.workWillBeDeleted': 'প্রোজেক্ট এবং সম্পর্কিত কোন কাজ মুছে ফেলা হবে।',
    'project.list.completion': 'সম্পূর্ণকরণ:',
    'project.list.createdDate': 'তৈরীর তারিখ:',
    'project.list.beginningDate': 'শুরুতে তারিখ:',
    'project.list.targetCompletionDate': 'উদ্দিষ্ট সম্পূর্ণতা তারিখ:',
    'project.list.description': 'বর্ণনা:',
    'project.edit': 'প্রকল্প সম্পাদনা',
    'project.list.translateBible': 'অনুবাদ বাইবেল',
    'project.list.empty': 'আপনার অনুবাদ প্রকল্প তালিকা খালি।',
    'project.list.myProjects': 'আমার বাইবেল অনুবাদ প্রকল্প',
    'project.list.newProject': 'নতুন প্রকল্প',
    'project.create.createNew': 'একটি নতুন প্রকল্প তৈরি',
    'project.namePlaceholder': 'প্রকল্পের নাম লিখুন',
    'project.descriptionPlaceholder': 'প্রকল্পের বিবরণ লিখুন',
    'project.name': 'প্রকল্পের নাম',
    'project.beginningDate': 'শুরুতে তারিখ',
    'project.targetCompletionDate': 'উদ্দিষ্ট সমাপ্তির তারিখ',
    'project.description': 'বিবরণ',
    'project.isRTL': 'অনুবাদ ডান-থেকে-বাম',
    'project.manage-users': 'অনুবাদক পরিচালনা',
    'project.new-user': 'নিউ অনুবাদক',
    'project.add-user': 'অনুবাদক যোগ',
    'project.users': 'প্রকল্পের অনুবাদকদের',
    'project.read-only': 'শুধুমাত্র পাঠযোগ্য',
    'project.read&write': 'পড়া & লেখা',
    'project.owner': 'মালিক',
    'project.translator': 'অনুবাদক',
    'project.message.no-such-user': 'জাতীয় কোনও অনুবাদক বিদ্যমান',
    'project.message.user-added': 'অনুবাদক সফলভাবে যোগ করা',
    'project.message.user-deleted': 'অনুবাদক সরানো হয়েছে।',
    'project.message.only-owner-can-manage-users':
      'শুধু প্রকল্প মালিক ব্যবহারকারীদের পরিচালনা করতে পারেন',
    'project.shared': 'সহযোগিতামূলক প্রকল্প',
    'general.delete-user': 'মুছে ফেলুন অনুবাদক',
    'general.edit-user': 'ব্যবহারকারী সম্পাদনা করুন',
    'general.name': 'নাম',
    'general.email': 'ই-মেইল',
    'general.permissions': 'অনুমতিসমূহ',
    'general.role': 'ভূমিকা',
    signin: 'সাইন ইন করুন',
    'signin.emailPlaceholder': 'ই-মেইল ঠিকানা',
    'signin.passwordPlaceholder': 'পাসওয়ার্ড',
    'signin.forgotPassword': 'আপনি কি পাসওয়ার্ড ভুলে গেছেন?',
    'signin.facebook': 'ফেসবুকে সাইন - ইন করুন',
    'signin.google': 'Google দিয়ে সাইন ইন',
    'signin.dontHaveAccount': 'একটি অ্যাকাউন্ট নেই?',
    'signin.createOne': 'একটি তৈরী কর',
    'signup.join': 'YouTranslate.Bible যোগদান',
    'signup.createAccountPrompt': 'আপনার বিনামূল্যে একাউন্ট শুরু করার তৈরি করুন।',
    'signup.createAccount': 'হিসাব তৈরি কর',
    'signup.emailPlaceholder': 'ই-মেইল ঠিকানা',
    'signup.passwordPlaceholder': 'পাসওয়ার্ড',
    'passwordReset.title': 'পাসওয়ার্ড রিসেট',
    'passwordReset.prompt': 'আপনার পাসওয়ার্ড রিসেট করতে নির্দেশাবলী সহ একটি ইমেল অনুরোধ করুন।',
    'passwordReset.emailPlaceholder': 'ইমেল ঠিকানা',
    'passwordReset.requestButtonLabel': 'অনুরোধ ইমেইল',
    'passwordReset.success': 'ইমেল পাঠানো হয়েছে.',
    'passwordReset.error': 'একটি ত্রুটি উৎপন্ন হয়েছে.',
    'translation.project': 'প্রকল্প:',
    'translation.backToProjectList': 'প্রকল্পের তালিকা ফিরে যান',
    'translation.book': 'বই:',
    'translation.chapter': 'অধ্যায়:',
    'translation.prompt':
      'আপনি এই অধ্যায়ের ছেড়ে দিতে চান আপনি কি নিশ্চিত? সমস্ত অসংরক্ষিত কাজ হারিয়ে যাবে।',
    'translation.prev': 'পূর্ববর্তী',
    'translation.next': 'পরবর্তী',
    'translation.previousChapter': 'পূর্ববর্তী অধ্যায়ে',
    'translation.nextChapter': 'পরের অধ্যায়',
    'navigator.book': 'বই:',
    'navigator.chapter': 'অধ্যায়:',
    'navigator.oldTestament': 'পুরনো উইল',
    'navigator.newTestament': 'নববিধান',
    'navigator.selectBook': 'একটি বই নির্বাচন করুন',
    'navigator.search': 'অনুসন্ধান করুন',
    'navigator.changeBook': 'বই পরিবর্তন',
    'navigator.changeChapter': 'অধ্যায় পরিবর্তন',
    Genesis: 'জনন',
    Exodus: 'প্রস্থান',
    Leviticus: 'লেবীয় পুস্তক',
    Numbers: 'নাম্বার',
    Deuteronomy: 'দ্বিতীয় বিবরণ',
    Joshua: 'জশুয়া',
    Judges: 'বিচারকদের',
    Ruth: 'করুণা',
    '1 Samuel': '1 শমূয়েল',
    '2 Samuel': '2 স্যামুয়েল',
    '1 Kings': '1 রাজাবলি',
    '2 Kings': '2 কিংস',
    '1 Chronicles': 'বংশাবলি 1',
    '2 Chronicles': '2 ক্রনিকলস',
    Ezra: 'ইষ্রা',
    Nehemiah: 'নেহেমিয়া',
    Esther: 'ইষ্টের',
    Job: 'কাজ',
    Psalms: 'সাম',
    Proverbs: 'বাগধারা',
    Ecclesiastes: 'উপদেশক',
    'Song of Songs': 'গান গানের',
    Isaiah: 'ইশাইয়ার',
    Jeremiah: 'যিরমিয়',
    Lamentations: 'মাতম',
    Ezekiel: 'এজেকিয়েল',
    Daniel: 'ড্যানিয়েল',
    Hosea: 'হোসেয়া',
    Joel: 'জোএল',
    Amos: 'আমোস',
    Obadiah: 'ওবদিয়',
    Jonah: 'দুর্ভাগ্যআনয়নকারী',
    Micah: 'মীখা',
    Nahum: 'নহূম',
    Habakkuk: 'হবক্কূক',
    Zephaniah: 'সফনিয়',
    Haggai: 'হগয়',
    Zechariah: 'সখরিয়',
    Malachi: 'মালাখি',
    Matthew: 'ম্যাথু',
    Mark: 'ছাপ',
    Luke: 'লুক',
    John: 'জন',
    Acts: 'প্রেরিত',
    Romans: 'রোমানরা',
    '1 Corinthians': '1 করিন্থীয়',
    '2 Corinthians': '2 করিন্থিয়ান্স',
    Galatians: 'গালাতীয়',
    Ephesians: 'ইফিষীয়',
    Philippians: 'ফিলিপীয়',
    Colossians: 'কলসীয়',
    '1 Thessalonians': '1 থিষলনীকীয়',
    '2 Thessalonians': '2 থিষলনীকীয়',
    '1 Timothy': '1 তীমথিয়',
    '2 Timothy': '2 টিমোথি',
    Titus: 'তিতাস',
    Philemon: 'ফিলীমন',
    Hebrews: 'হিব্রুদের কাছে পত্র',
    James: 'জেমস',
    '1 Peter': 'পিতরের 1 ম পত্র',
    '2 Peter': '2 পিটার',
    '1 John': '1 যোহন',
    '2 John': '2 জন',
    '3 John': '3 যোহন',
    Jude: 'যিহূদা',
    Revelation: 'উদ্ঘাটন',
    'resources.prompt':
      'উত্তরণ আপনি অনুবাদ করছেন অতিরিক্ত অন্তর্দৃষ্টি জন্য বাইবেলের সম্পদ যুক্ত করো।',
    'resources.add': 'একটি বাইবেলের রিসোর্স যোগ করুন',
    Manuscript: 'পাণ্ডুলিপি',
    Morphology: 'অঙ্গসংস্থানবিদ্যা',
    Lexicon: 'শব্দকোষ',
    'Syntactic Tree': 'অন্বিত বৃক্ষ',
    'Go to project list': 'প্রকল্পের তালিকাতে যান',
    'Project not found': 'প্রকল্প খুঁজে পাওয়া যায়নি অথবা অপর্যাপ্ত অনুমতির।',
    'error.referenceDoesNotExistForResource':
      'এই বাইবেল অনুবাদ নির্বাচিত উত্তরণ জন্য সামগ্রী ধারণ করে না।',
    'references.addAnother': 'অন্য অনুবাদ যোগ করুন',
    'references.referencePrompt': 'অতিরিক্ত অন্তর্দৃষ্টি জন্য বাইবেল অন্যান্য অনুবাদের যুক্ত করো।',
    'references.add': 'একটি বাইবেল অনুবাদ যোগ করুন',
    'references.licensing': 'লাইসেন্সিং তথ্য',
    'references.licensingEmpty': 'কোন লাইসেন্সিং তথ্য এই আইটেমটি জন্য পাওয়া যায়নি।',
    'translation.complete': 'অনুবাদ সম্পুর্ণ',
    Alignment: 'শ্রেণীবিন্যাস',
    Share: 'ভাগ',
    'Your Translation': 'আপনার অনুবাদ',
    'Enter your translation': 'আপনার অনুবাদ লিখুন',
    OK: 'ঠিক আছে',
    'error.verseTranslationEmpty': 'আপনি অনুবাদ বিষয়বস্তু খালি রাখুন করতে পারবে না।',
    'translation.hide.completed': 'সম্পন্ন লুকান',
    alignment: 'শ্রেণীবিন্যাস',
    'alignment.verify': 'যাচাই করুন এবং বন্ধ করুন।',
    'alignment.close': 'যাচাই না করেই বন্ধ',
    'alignment.backToEditor': 'সম্পাদনায় ফিরুন',
    'alignment.error':
      'কিছু ভুল হয়েছে. সমস্যা থেকে গেলে, YouTranslate.Bible দলের সাথে যোগাযোগ করুন।',
    'alignment.add': 'লিংক যোগ করুন',
    'alignment.delete': 'লিঙ্ক সরান',
    'alignment.clear': 'সাফ নির্বাচন',
    'alignment.reverse': 'বিপরীত প্রদর্শন',
    'alignment.removeOrClear': 'নির্বাচিত লিঙ্ক অথবা স্পষ্ট নির্বাচন সরান।',
    'alignment.addOrRemove': 'একটি নতুন লিঙ্ক তৈরি করুন বা একটি পূর্ববর্তী মুছে ফেলুন।',
    'alignment.selectAndAdd': 'লিঙ্কে অংশ সংশ্লিষ্ট নির্বাচন করুন।',
    'error.mustBeSignedToMakeChanges': 'আপনার করা পরিবর্তনগুলি এ সাইন ইন করতে হবে।',
    'error.mustBeSignedToTranslateBible': 'আপনি বাইবেল অনুবাদ করতে সাইন ইন করতে হবে।',
    checking: 'পরীক্ষা করা হচ্ছে',
    'verseEditor.unexpectedError': 'একটি অপ্রত্যাশিত ত্রুটি ঘটেছে।',
    verses: 'দ্বারা আয়াত',
    words: 'শব্দ অনুসারে',
    'leftPanel.name': 'বাইবেলের মূল পাঠ্য',
    'rightPanel.name': 'বাইবেল অনুবাদ',
    'bottomPanel.name': 'বাইবেল জন্য সম্পদগুলি',
    revisionHistory: 'পরিবর্ধন ও পরিবর্তন তালিকা',
    'syntax.adv': 'ক্রিয়া-বিশেষণ জাতীয় সংশোধক',
    'syntax.adjp': 'বিশেষণ শব্দবন্ধ',
    'syntax.advp': 'ক্রিয়া বিশেষণ শব্দবন্ধ',
    'syntax.cl': 'দফা',
    'syntax.conj': 'সংযোগ',
    'syntax.cjp': 'সংযোগ',
    'syntax.det': 'নিয়ন্তা',
    'syntax.ijp': 'মধ্যে নিক্ষেপ',
    'syntax.nump': 'সংখ্যাসূচক শব্দবন্ধ',
    'syntax.np': 'বিশেষ্য ফ্রেজ',
    'syntax.o': 'সরাসরি বস্তুর',
    'syntax.o2': 'দ্বিতীয়ত অবজেক্ট',
    'syntax.oc': 'অবজেক্ট পরিপূর্ণ',
    'syntax.om': 'অবজেক্ট মার্কার',
    'syntax.io': 'পরোক্ষ বস্তু',
    'syntax.p': 'অ মৌখিক সম্পৃক্ত',
    'syntax.pp': 'পদান্বয়ী অব্যয় ফ্রেজ',
    'syntax.prep': 'পদান্বয়ী অব্যয়',
    'syntax.ptcl': 'কণা',
    'syntax.rel': 'আপেক্ষিক কণা',
    'syntax.s': 'বিষয়',
    'syntax.v': 'ক্রিয়া',
    'syntax.vc': 'লিঙ্ক ক্রিয়া',
    'syntax.adjp.description': 'শব্দবন্ধ যে একটি বিশেষ্য বা সর্বনাম পরিবর্তন',
    'syntax.adv.description':
      'পরিবর্তন ক্রিয়া, সাধারণত আরও সংজ্ঞায়িত যে উপায়ে, সময়, স্থান, পৌনঃপুনিকতা, ডিগ্রী, ইত্যাদি ...',
    'syntax.advp.description':
      'শব্দবন্ধ ক্রিয়া পরিবর্তন, সাধারণত আরও সংজ্ঞায়িত যে উপায়ে, সময়, স্থান, পৌনঃপুনিকতা, ডিগ্রী, ইত্যাদি',
    'syntax.cl.description': 'শব্দের একদল যে বিষয় সম্পর্কে একটি বিবৃতি তোলে।',
    'syntax.conj.description': 'শব্দ, বাক্যাংশ, এবং ক্লজ সংযোগ করে।',
    'syntax.cjp.description': 'শব্দ, বাক্যাংশ, এবং ক্লজ সংযোগ করে।',
    'syntax.det.description': 'নির্দিষ্ট করে, শনাক্ত করে, বা এবং quantifies একটি বিশেষ্য ফ্রেজ।',
    'syntax.ijp.description': 'বিস্ময়বোধক বা আবেগ আকস্মিক অভিব্যক্তি।',
    'syntax.nump.description': 'শব্দবন্ধ যে সংখ্যাসূচক পরিমাণ বর্ণনা',
    'syntax.np.description':
      'একজন ফ্রেজ তার প্রধান হিসেবে একটি বিশেষ্য বা বিশেষ্য হিসাবে যে ফাংশন রয়েছে।',
    'syntax.o.description': 'যা সরাসরি প্রভাবিত সৃষ্ট, অথবা একটি ক্রিয়া কর্ম দ্বারা উত্পাদিত হয়।',
    'syntax.o2.description':
      'যা সরাসরি প্রভাবিত সৃষ্ট, অথবা একটি ক্রিয়া কর্ম দ্বারা উত্পাদিত হয়।',
    'syntax.oc.description': 'সরাসরি বস্তুর সম্পর্কে একটি বিবৃতি তৈরি করে।',
    'syntax.om.description': 'কণা যে একটি সরাসরি বস্তুর প্রবর্তন করে।',
    'syntax.io.description':
      'অবজেক্ট যে পরোক্ষভাবে একটি কর্ম দ্বারা প্রভাবিত, প্রায়ই একটি ক্রিয়াপদের কর্মের প্রাপক।',
    'syntax.p.description':
      'সনাক্ত বা বিষয় বর্ণনা প্রায়ই (তবে সব সময় নয়) একসঙ্গে একটি লিঙ্ক ক্রিয়া ক্ষেত্রেও একই ঘটনা ঘটে।',
    'syntax.pp.description': 'পরিবর্তন একটি অব্যয় এবং অবজেক্ট গঠিত ফ্রেজ।',
    'syntax.prep.description':
      'অন্য কথায় সঙ্গে অব্যয় বস্তুর সম্পর্ক চিহ্নিত, যা নির্দেশ প্রায়ই যেখানে অথবা যখন।',
    'syntax.ptcl.description':
      'ফাংশন শব্দের বিচিত্র গ্রুপ থেকে এক সদস্য যে রুপান্তরিত ব্যাকরণগত শব্দ ক্লাস কোন অন্তর্গত না',
    'syntax.rel.description': 'কণা যে একটি আপেক্ষিক দফা প্রবর্তন করে।',
    'syntax.s.description': 'দফা যে ধারা বাকি সম্পর্কে একটি বিবৃতি তোলে অংশ।',
    'syntax.v.description':
      'দফা বিষয় সম্পর্কে কিছু যুক্তরাষ্ট্র এবং কর্ম, ঘটনা, অথবা শর্ত ব্যক্ত করিতে পারিবেন।',
    'syntax.vc.description': 'একটি বিষয় সম্পূরক করার জন্য একটি দফা বিষয় সংযোগ করে।',
    'general.close': 'ঘনিষ্ঠ',
    'general.action': 'কর্ম',
    'general.select': 'নির্বাচন করুন ...',
    'general.back': 'পেছনে',
    'revisionHistory.noData': 'এই শ্লোক জন্য কোন সংস্করণ ইতিহাস।',
    'revisionHistory.translation': 'অনুবাদ',
    'revisionHistory.lastChanged': 'সর্বশেষ পরিবর্তন',
    'sourceText.view': 'উৎস পাঠ্য দেখতে ক্লিক করুন',
    'sourceText.viewStructure': 'উৎস পাঠ্য গঠন দেখতে ক্লিক করুন',
    'error.projectIdIsRequired': 'প্রকল্প ID প্রয়োজন হয়।',
    'error.searchTermIsRequired': 'অনুসন্ধানের শব্দ খালি করতে পারবে না।',
    'error.bookIdIsRequired': 'বইয়ের আইডি পুস্তক নাম সঞ্চয় করতে প্রয়োজন হয়।',
    'error.bookNameCannotBeEmpty': 'বইয়ের নাম ফাঁকা হতে পারে না।',
    'error.signInIsRequiredToAccessProject':
      'তুমি তোমার প্রকল্পের অ্যাক্সেস করতে সাইন ইন করতে হবে।',
    error: 'ত্রুটি',
    'error.doNotHavePermissionAccessProject': 'আপনি এই প্রকল্পের অ্যাক্সেস করার অনুমতি নেই।',
    'error.unauthorized': 'অননুমোদিত অ্যাক্সেস বা অবৈধ অ্যাক্সেস টোকেন।',
    'error.tokenExpired': 'তোমার টোকেন মেয়াদ শেষ হয়েছে। দয়া করে পুনরায় লগইন করুন।',
    'error.invalidApiUrl': 'অবৈধ API URL।',
    'error.badRequestInvalidJson': 'খারাপ অনুরোধ. অবৈধ JSON।',
    'error.windowIsNotDefined': 'উইন্ডো সংজ্ঞায়িত করা হয় না।',
    'error.unexpectedError':
      'একটি অপ্রত্যাশিত ত্রুটি ঘটেছে। এই উইন্ডো বন্ধ করুন এবং আবার চেষ্টা করুন।',
    'error.invalidDataType': 'অবৈধ ডেটা প্রকার।',
    'export.data': 'রপ্তানি তথ্য',
    more: 'অধিক',
    'export.to.rtf': 'RTF পরস্পরের রপ্তানি করুন',
    'export.to.usfm': 'রপ্তানি USFM করতে',
    'export.titlePreparingData': 'আমরা আপনার বাইবেল অনুবাদ তথ্য প্রস্তুত করা হয়।',
    'export.doNotClose':
      'এই প্রক্রিয়া কিছু সময় নিতে পারে, বন্ধ না এই উইন্ডোটি না দয়া করে এবং যখন এটি ডাউনলোড করার জন্য প্রস্তুত আপনাকে সূচিত করা হবে।',
    'export.desc': 'রফতানির এবং নীচে বাটন ক্লিক করে আপনার অনুবাদ ডেটা ডাউনলোড করতে পারেন।',
    'export.titleOfReady2Download': 'আপনার বাইবেল অনুবাদ ডেটা প্রস্তুত।',
    'export.textOfReady2Download':
      'নিচে আপনার বাইবেল অনুবাদ ডাউনলোড করতে ডাউনলোড বাটনে ক্লিক করুন।',
    'export.statistics': 'পরিসংখ্যান',
    'export.book': 'বইয়ের নাম',
    'export.translatedVerses': 'অনুবাদিত আয়াত',
    'export.exportedVerses': 'রপ্তানী আয়াত',
    'export.howDoUWant2Export': 'আপনি কিভাবে ডেটা রপ্তানি করতে চান?',
    'export.whichBooks': 'কোন বই (গুলি) আপনার রপ্তানি করতে চান?',
    'export.byBooks': 'লেখা বই',
    'export.byChapters': 'দ্বারা অধ্যায়গুলির',
    'export.byVerses': 'দ্বারা আয়াত',
    'export.booksInOt': 'পুরনো উইল',
    'export.booksInNt': 'নববিধান',
    'export.booksInBible': 'সমগ্র বাইবেল',
    'export.booksInNone': 'না',
    'export.selectRangeOfChapters': 'অধ্যায়গুলির বিন্যাস',
    'export.selectRangeOfVerses': 'আয়াত বিন্যাস',
    'export.selectChapter': 'একটি অধ্যায় নির্বাচন করুন',
    'export.exportTypeIsRequired':
      'আপনি এখনও সম্পর্কে নির্বাচন করেন নি কিভাবে তুমি রপ্তানি ডেটা করতে চান?',
    'export.haveNotYetSelectedBooks': 'আপনি এখনও পর্যন্ত কোনো বই নির্বাচন করেন নি।',
    'error.selectABook': 'আপনি একটি বই নির্বাচন করতে হবে।',
    'error.invalidBookId': 'অবৈধ বই আইডি।',
    'error.selectAChapter': 'আপনি একটি অধ্যায় নির্বাচন করতে হবে।',
    'error.invalidChapterRange': 'আপনাকে অবশ্যই একটি বৈধ অধ্যায় পরিসর নির্বাচন করতে হবে।',
    'error.invalidVerseRange': 'আপনাকে অবশ্যই একটি বৈধ শ্লোক পরিসর নির্বাচন করতে হবে।',
    'error.invalidExportType': 'অবৈধ রপ্তানি প্রকার।',
    startover: 'নতুন করে শুরু কর',
    download: 'ডাউনলোড',
    'download.all': 'ডাউনলোড সমস্ত ফাইল',
    'contact.header': 'যোগাযোগ করুন',
    'contact.returnAddress': 'ফেরত ঠিকানা',
    'contact.returnAddressPlaceholder': 'আমরা কোথায় যোগাযোগ করবে?',
    'contact.subject': 'বিষয়',
    'contact.subjectPlaceholder': 'তোমার কোন ধরনের সাহায্য দরকার?',
    'contact.message': 'বার্তা',
    'contact.messagePlaceholder': 'আমরা কিভাবে সাহায্য করতে পারি?',
    'contact.sendMessage': 'বার্তা পাঠান',
    'contact.success': 'বার্তা সফলভাবে পাঠানো হয়েছে।',
    'contact.failure':
      'একটি সমস্যা এই বার্তা পাঠানোর সময়। help@globalbibleinitiative.org সাথে যোগাযোগ করুন।',
    selectInterlinear: 'ইন্টারলিনিয়ার টেক্সট পরিবর্তন করতে ক্লিক করুন',
    'warning.screenTooSmall':
      'একটি উচ্চ পর্দার রেজল্যুশন (ন্যূনতম 1024px চওড়া) স্যুইচ করুন অথবা আপনার ব্রাউজার উইন্ডোতে বিস্তীর্ণ দয়া করুন।',
    'label.dictionary': 'নির্ঘণ্ট অনুসন্ধান',
    'dict.search.placeholder': 'শব্দ (গুলি) লিখুন',
    'tooltip.manuscript': 'পাণ্ডুলিপি',
    'tooltip.strongsx': 'এর স্ট্রং নম্বর',
    'tooltip.translation': 'অনুবাদ',
    'tooltip.english': 'ইংরেজি',
    'title.howToSearch': 'নির্ঘণ্ট অনুসন্ধান কীভাবে ব্যবহার করতে হয়',
    'desc.howToSearch':
      'গ্রিক বা হিব্রু, শব্দ (গুলি) আপনার অনুবাদ থেকে, অথবা স্ট্রং নম্বর (গুলি) থেকে শব্দ (গুলি) সন্নিবেশ করান। কিংবা "আপনার অনুবাদ" বা অনুসন্ধান বিকল্পের জন্য সারিবদ্ধতা মতামত প্যানেলে গ্রিক বা হিব্রু শব্দের উপর ক্লিক করুন।',
    'dict.noDataToDisplay': 'কোন তথ্য যে আপনার অনুসন্ধানের সাথে মেলে আছে।',
    'title.searchTermTooMany': 'আপনি অনেকগুলি কীওয়ার্ড লিখে ফেলেছেন',
    'desc.searchTermTooMany': 'আপনি 8 কীওয়ার্ড প্রতিটি সময় পর্যন্ত অনুসন্ধান করতে পারেন।',
    'title.searchDictError': 'একটি অপ্রত্যাশিত ত্রুটি ঘটেছে।',
    'desc.searchDictError': 'পরে আবার চেষ্টা বা কাস্টমার সহায়তাতে যোগাযোগের দয়া করে',
    'alignment.saveAndEditAlignment': 'সংরক্ষণ করুন এবং সারিবদ্ধ নতুন পাঠ্য',
    'alignment.reviewAlignment': 'পর্যালোচনা পাঠ্যে সারিবদ্ধতা',
    'dict.searchLemma': 'মূল শব্দ অনুসন্ধান করতে ক্লিক করুন',
    'dict.searchTranslation': 'অনুবাদ শব্দ অনুসন্ধান করতে ক্লিক করুন',
    'dict.searchStrongsX': 'স্ট্রং নম্বর অনুসন্ধান করতে ক্লিক করুন',
    'dict.showMenu': 'প্রেক্ষাপটে ঘটনার সম্পূর্ণ টেক্সট দেখাতে ক্লিক করুন।',
    'dict.hideMenu': 'প্রেক্ষাপটে ঘটনার সম্পূর্ণ টেক্সট আড়াল করতে ক্লিক করুন।',
  },
  ceb: {
    'languages.en': 'Iningles',
    'languages.am': 'Amharic',
    'languages.bn': 'Bangla Pinulongan',
    'languages.ceb': 'Cebuano',
    'languages.de': 'German nga Pinulongan',
    'languages.es-419': 'Espanyol Pinulongan',
    'languages.fr': 'Pranses Pinulongan',
    'languages.gu': 'Gujarati',
    'languages.hi': 'Hindi',
    'languages.hr': 'nga Croatian Pinulongan',
    'languages.hu': 'Hinungriyanon Pinulongan',
    'languages.id': 'Indonesian nga Pinulongan',
    'languages.km': 'Khmer',
    'languages.kn': 'kannada',
    'languages.ko': 'Korean Language',
    'languages.lo': 'Laotian Pinulongan',
    'languages.ml': 'Malayalam',
    'languages.mr': 'Marathi',
    'languages.ms': 'Malaysia',
    'languages.my': 'Burmese Pinulongan',
    'languages.ne': 'Minepalhon Pinulongan',
    'languages.plt': 'Malagasy Pinulongan',
    'languages.pt-br': 'Portuges (Brazil) Pinulongan',
    'languages.ru': 'Russian nga Pinulongan',
    'languages.sw': 'sa Swahili',
    'languages.ta': 'Tamil nga Pinulongan',
    'languages.th': 'Thailand Pinulongan',
    'languages.tl': 'Tinagalog',
    'languages.te': 'Telegu Pinulongan',
    'languages.vi': 'Vietnamese Pinulongan',
    'languages.zh-hans': 'Sa China Language (Simplified)',
    'languages.zh-hant': 'Sa China Language (Tradisyonal)',
    'header.home': 'Panimalay',
    'header.help': 'Tabang',
    'header.project': 'Project',
    'header.contact': 'Contact',
    'help.contactSupport': 'Palihug email kanato sa help@youtranslate.bible alang sa tabang.',
    'home.ytbProvides': 'YouTranslate.Bible sa walay bayad naghatag:',
    'home.easyToUseTool': 'Usa ka sayon-sa-paggamit sa online nga himan sa paghubad',
    'home.originalTextHelp':
      'Tabang sa pagsabut sa teksto sa Bibliya diha sa iyang orihinal nga mga pinulongan',
    'home.referencesAndResources': 'Reference ug pagbansay mga kapanguhaan',
    'home.computerAssistance':
      'State-of-the-art nga computer teknolohiya sa pagtabang sa mga maghuhubad',
    'profile.userProfile': 'profile user',
    'profile.language': 'pinulongan',
    switchDirection: 'Switch Text Direction',
    'profile.update': 'update',
    'profile.languageWarning':
      'Google Paghubad gigamit sa paghubad sa user interface ngadto sa mga pinulongan gawas sa Iningles temporaryong. YTB ​​walay kontrol sa tukma o kasaligan sa paghubad. Bisan kinsa nga pagsalig sa gihubad label ug mga pagpatin-aw nga nakuha gikan sa Google Paghubad ang mao nga sa iyang o sa iyang kaugalingong risgo. Kon ang bisan kinsa nga interesado sa pagboluntaryo sa paghatag og mas tukma ug kasaligan nga hubad sa user interface ngadto sa mga pinulongan gawas sa Iningles, palihug i-klik ang kapilian Contact sa pagpadala sa usa ka email ngadto sa GBI.',
    'usernav.profile': 'profile',
    'usernav.logout': 'Logout',
    'usernav.logout.modalTitle': 'Logout sa YouTranslate.Bible',
    'usernav.logout.modalText':
      'Sigurado nga gusto mo sa logout? Ang tanan nga dili-luwas nga buhat mawala.',
    cancel: 'Cancel',
    delete: 'pagwagtang',
    create: 'Paghimo',
    update: 'update',
    edit: 'edit',
    save: 'Luwasa',
    add: 'Idugang',
    translate: 'paghubad',
    'more.options': 'Dugang nga mga kapilian',
    'project.create.title': 'Paghubad sa Bibliya Project',
    'project.create.list': 'listahan Project',
    'project.list.deleteProject': 'Panas Project?',
    'project.list.areYouSure': 'Ikaw ba sigurado nga imong gusto sa panas niini nga proyekto?',
    'project.list.workWillBeDeleted':
      'Ang proyekto ug sa bisan unsa nga buhat nga nalangkit nga thread.',
    'project.list.completion': 'pagkompleto:',
    'project.list.createdDate': 'Gilalang Date:',
    'project.list.beginningDate': 'Sugod Date:',
    'project.list.targetCompletionDate': 'Target mahuman Date:',
    'project.list.description': 'Description:',
    'project.edit': 'edit Project',
    'project.list.translateBible': 'paghubad sa Bibliya',
    'project.list.empty': 'Ang imong listahan sa paghubad nga proyekto mao ang walay sulod.',
    'project.list.myProjects': 'Ang akong Bibliya Translation proyekto',
    'project.list.newProject': 'Bag-ong Project',
    'project.create.createNew': 'Create sa usa ka bag-o nga Project',
    'project.namePlaceholder': 'Pagsulod sa ngalan nga proyekto',
    'project.descriptionPlaceholder': 'Pagsulod sa proyekto nga paghulagway',
    'project.name': 'ngalan Project',
    'project.beginningDate': 'sugod Petsa',
    'project.targetCompletionDate': 'Target mahuman Petsa',
    'project.description': 'Description',
    'project.isRTL': 'Hubad matarung-sa-sa wala',
    'project.manage-users': 'pagdumala sa mga maghuhubad',
    'project.new-user': 'Bag-ong maghuhubad',
    'project.add-user': 'add maghuhubad',
    'project.users': 'maghuhubad Project',
    'project.read-only': 'Basaha lamang',
    'project.read&write': 'Basaha & Isulat',
    'project.owner': 'Tag-iya',
    'project.translator': 'Maghuhubad',
    'project.message.no-such-user': 'Walay ingon nga maghuhubad anaa',
    'project.message.user-added': 'Maghuhubad malampuson dugang pa',
    'project.message.user-deleted': 'gikuha Maghuhubad.',
    'project.message.only-owner-can-manage-users':
      'Lamang nga tag-iya nga proyekto makahimo sa pagdumala sa tiggamit',
    'project.shared': 'collaborative proyekto',
    'general.delete-user': 'pagwagtang maghuhubad',
    'general.edit-user': 'edit user',
    'general.name': 'Ngalan',
    'general.email': 'E-mail',
    'general.permissions': 'Permissions',
    'general.role': 'papel',
    signin: 'Sign sa',
    'signin.emailPlaceholder': 'email address',
    'signin.passwordPlaceholder': 'password',
    'signin.forgotPassword': 'Nakalimot sa imong Password?',
    'signin.facebook': 'Sign sa uban Facebook',
    'signin.google': 'Sign sa uban Google',
    'signin.dontHaveAccount': 'Ayaw adunay usa ka asoy?',
    'signin.createOne': 'Paghimo sa usa ka',
    'signup.join': 'Apil sa YouTranslate.Bible',
    'signup.createAccountPrompt': 'Paghimo sa inyong free account sa pagsugod.',
    'signup.createAccount': 'Paghimo Account',
    'signup.emailPlaceholder': 'email address',
    'signup.passwordPlaceholder': 'password',
    'passwordReset.title': 'password Reset',
    'passwordReset.prompt':
      'Hangyo sa usa ka email uban sa mga panudlo aron sa Reset sa imong password.',
    'passwordReset.emailPlaceholder': 'email Address',
    'passwordReset.requestButtonLabel': 'hangyo Email',
    'passwordReset.success': 'Email nga gipadala.',
    'passwordReset.error': 'Usa ka sayop nga nahitabo.',
    'translation.project': 'PROYEKTO:',
    'translation.backToProjectList': 'Balik sa listahan proyekto',
    'translation.book': 'basahon:',
    'translation.chapter': 'kapitulo:',
    'translation.prompt':
      'Ikaw ba sigurado nga imong gusto nga mobiya sa niini nga kapitulo? Ang tanan nga dili-luwas nga buhat mawala.',
    'translation.prev': 'Prev',
    'translation.next': 'sunod',
    'translation.previousChapter': 'miaging kapitulo',
    'translation.nextChapter': 'sunod nga kapitulo',
    'navigator.book': 'basahon:',
    'navigator.chapter': 'kapitulo:',
    'navigator.oldTestament': 'Daang Tugon',
    'navigator.newTestament': 'Bag-ong Tugon',
    'navigator.selectBook': 'Pagpili og usa ka basahon',
    'navigator.search': 'Pangitaa',
    'navigator.changeBook': 'Change nga basahon',
    'navigator.changeChapter': 'Change kapitulo',
    Genesis: 'Genesis',
    Exodus: 'Exodo',
    Leviticus: 'Levitico',
    Numbers: 'Numeros',
    Deuteronomy: 'Deuteronomio',
    Joshua: 'Josue',
    Judges: 'Maghuhukom',
    Ruth: 'Ruth',
    '1 Samuel': '1 Samuel',
    '2 Samuel': '2 Samuel',
    '1 Kings': '1 Kings',
    '2 Kings': '2 Hari',
    '1 Chronicles': '1 Cronicas',
    '2 Chronicles': '2 Cronicas',
    Ezra: 'Esdras',
    Nehemiah: 'Nehemias',
    Esther: 'Ester',
    Job: 'Job',
    Psalms: 'Salmo',
    Proverbs: 'Proverbio',
    Ecclesiastes: 'Ecclesiastes',
    'Song of Songs': 'Awit sa mga Awit',
    Isaiah: 'Isaias',
    Jeremiah: 'Jeremias',
    Lamentations: 'Lamentaciones',
    Ezekiel: 'Ezequiel',
    Daniel: 'Daniel',
    Hosea: 'Oseas',
    Joel: 'Joel',
    Amos: 'Amos',
    Obadiah: 'Abdias',
    Jonah: 'Jonas',
    Micah: 'Miqueas',
    Nahum: 'Nahum',
    Habakkuk: 'Habacuc',
    Zephaniah: 'Sofonias',
    Haggai: 'Haggeo',
    Zechariah: 'Zacarias',
    Malachi: 'Malaquias',
    Matthew: 'Mateo',
    Mark: 'Marcos',
    Luke: 'Lucas',
    John: 'Juan',
    Acts: 'Buhat',
    Romans: 'Taga-Roma',
    '1 Corinthians': '1 Mga Taga-Corinto',
    '2 Corinthians': '2 Corinto',
    Galatians: 'Galacia',
    Ephesians: 'Taga-Efeso',
    Philippians: 'Filipos',
    Colossians: 'Colosas',
    '1 Thessalonians': '1 Tesalonica',
    '2 Thessalonians': '2 Tesalonica',
    '1 Timothy': '1 Timoteo',
    '2 Timothy': '2 Timoteo',
    Titus: 'Tito',
    Philemon: 'Filemon',
    Hebrews: 'Hebreohanon',
    James: 'Santiago',
    '1 Peter': '1 Pedro',
    '2 Peter': '2 Pedro',
    '1 John': '1 Juan',
    '2 John': '2 Juan',
    '3 John': '3 Juan',
    Jude: 'Judas',
    Revelation: 'Pinadayag',
    'resources.prompt':
      'Idugang mga kapanguhaan sa Bibliya alang sa dugang pagsabot sa tudling nga imong paghubad.',
    'resources.add': 'Add usa ka Biblikanhong kapanguhaan',
    Manuscript: 'Manuscript',
    Morphology: 'morpolohiya',
    Lexicon: 'Lexicon',
    'Syntactic Tree': 'Syntactic Kahoy',
    'Go to project list': 'Lakaw ngadto sa listahan sa proyekto',
    'Project not found': 'Project dili makaplagan o insufficient Permissions.',
    'error.referenceDoesNotExistForResource':
      'Kini nga hubad sa Bibliya wala sulod alang sa pinili nga tudling.',
    'references.addAnother': 'Idugang sa laing hubad',
    'references.referencePrompt':
      'Idugang sa ubang mga hubad sa Bibliya alang sa dugang nga panabut.',
    'references.add': 'Add usa ka hubad sa Bibliya',
    'references.licensing': 'Licensing Impormasyon',
    'references.licensingEmpty':
      'Walay sa lisensya sa impormasyon nga nakita alang butang niini nga.',
    'translation.complete': 'Hubad bug-os nga',
    Alignment: 'paglaray, pagtalay',
    Share: 'Share',
    'Your Translation': 'Ang imong Hubad',
    'Enter your translation': 'Pagsulod sa imong hubad',
    OK: 'OK',
    'error.verseTranslationEmpty': 'Ikaw dili mobiya sa sulod sa paghubad biyaan.',
    'translation.hide.completed': 'panit nahuman',
    alignment: 'paglaray, pagtalay',
    'alignment.verify': 'Panghimatuud ug sa pagsarado.',
    'alignment.close': 'Close nga walay mosusi',
    'alignment.backToEditor': 'Balik sa Editor',
    'alignment.error':
      'Usa ka butang nga miadto sayop. Kon ang nagapadayon nga problema, palihog kontaka ang YouTranslate.Bible team.',
    'alignment.add': 'Add Link',
    'alignment.delete': 'Kuhaa Link',
    'alignment.clear': 'Tin-aw Pagpili',
    'alignment.reverse': 'reverse Ipakita',
    'alignment.removeOrClear': 'Kuhaa ang mga pinili nga link o tin-aw nga pagpili.',
    'alignment.addOrRemove': 'Create sa usa ka bag-o nga link o panas usa ka miaging usa.',
    'alignment.selectAndAdd': 'Pagpili katugbang bahin sa link.',
    'error.mustBeSignedToMakeChanges':
      'Kamo kinahanglan gayud nga nga gipirmahan sa sa sa paghimo sa mga kausaban.',
    'error.mustBeSignedToTranslateBible':
      'Kamo kinahanglan gayud nga nga gipirmahan sa sa paghubad sa Bibliya.',
    checking: 'pagsusi',
    'verseEditor.unexpectedError': 'Usa ka wala damha nga kasaypanan nahitabo.',
    verses: 'pinaagi sa Bersikulo',
    words: 'pinaagi sa Pulong sa',
    'leftPanel.name': 'Orihinal nga teksto sa Bibliya',
    'rightPanel.name': 'Hubad sa Bibliya',
    'bottomPanel.name': 'Mga kapanguhaan alang sa mga Bibliya',
    revisionHistory: 'Kasaysayan rebisyon',
    'syntax.adv': 'sa diwa nga adberbo modifier',
    'syntax.adjp': 'adjectival Hugpong sa Pulong',
    'syntax.advp': 'adverbio nga Hugpong sa Pulong',
    'syntax.cl': 'clause',
    'syntax.conj': 'inubanan sa',
    'syntax.cjp': 'inubanan sa',
    'syntax.det': 'Determiner',
    'syntax.ijp': 'interjection',
    'syntax.nump': 'sa gidaghanon nga Hugpong sa Pulong',
    'syntax.np': 'noun Hugpong sa Pulong',
    'syntax.o': 'Direct Object',
    'syntax.o2': 'Ikaduhang Object',
    'syntax.oc': 'butang katimbang',
    'syntax.om': 'butang puntos',
    'syntax.io': 'dili direkta nga Object',
    'syntax.p': 'Non-berbal predicate',
    'syntax.pp': 'Prepositional Hugpong sa Pulong',
    'syntax.prep': 'Preposisyon',
    'syntax.ptcl': 'tipik',
    'syntax.rel': 'paryente tipik',
    'syntax.s': 'subject',
    'syntax.v': 'berbo',
    'syntax.vc': 'Nagdugtong sa berbo',
    'syntax.adjp.description': 'Hugpong sa mga pulong nga modifies usa ka noun o pronombre',
    'syntax.adv.description':
      'Modifies sa berbo, kasagaran sa dugang aw nga paagi, sa panahon, dapit, frequency, degree, etc ...',
    'syntax.advp.description':
      'Hugpong sa mga pulong pag-usab sa berbo, kasagaran sa dugang aw nga paagi, sa panahon, dapit, frequency, degree, ug uban pa',
    'syntax.cl.description':
      'Usa ka grupo sa mga pulong nga naghimo sa usa ka pangangkon mahitungod sa hilisgutan.',
    'syntax.conj.description': 'Nagsumpay sa mga pulong, hugpong sa mga pulong, ug mga pulong.',
    'syntax.cjp.description': 'Nagsumpay sa mga pulong, hugpong sa mga pulong, ug mga pulong.',
    'syntax.det.description': 'Hingalan, nagpaila, o quantifies usa ka noun hugpong sa mga pulong.',
    'syntax.ijp.description': 'Pagtuaw o kalit nga pagpahayag sa emosyon.',
    'syntax.nump.description': 'Hugpong sa mga pulong nga naghulagway sa gidaghanon gidaghanon',
    'syntax.np.description':
      'Ang usa ka hugpong sa mga pulong nga adunay usa ka noun ingon nga sa iyang ulo o nga gimbuhaton sama sa sa usa ka noun.',
    'syntax.o.description':
      'Kana nga direktang apektado, hinungdan, o gipatungha pinaagi sa buhat sa usa ka berbo.',
    'syntax.o2.description':
      'Kana nga direktang apektado, hinungdan, o gipatungha pinaagi sa buhat sa usa ka berbo.',
    'syntax.oc.description': 'Naghimo sa usa ka pangangkon mahitungod sa Direct Object.',
    'syntax.om.description': 'Tipik nga nagpaila sa usa ka direkta nga butang.',
    'syntax.io.description':
      'Butang nga dili direkta nga apektado sa usa ka aksyon, sa kasagaran sa mga nakadawat sa aksyon sa usa ka berbo.',
    'syntax.p.description':
      'Nagpaila o naghulagway sa hilisgutan, nga sagad (apan dili sa kanunay) mahitabo uban sa usa ka nga nagsumpay berbo.',
    'syntax.pp.description':
      'Pag-usab hugpong sa mga pulong nga naglangkob sa usa ka preposisyon ug sa iyang butang.',
    'syntax.prep.description':
      'Nagpakita nga relasyon sa tumong sa mga preposisyon uban sa lain nga mga pulong, nga sagad nagpakita diin o sa diha nga.',
    'syntax.ptcl.description':
      'Usa ka miyembro gikan sa nagkalain-laing grupo sa mga function nga mga pulong nga dili iya sa bisan unsa sa mga mausab ang porma sa gramatika klase pulong',
    'syntax.rel.description': 'Tipik nga nagpaila sa usa ka paryente clause.',
    'syntax.s.description':
      'Ang bahin sa clause nga ang uban nga mga clause naghimo sa usa ka pangangkon mahitungod sa.',
    'syntax.v.description':
      'Nag-ingon sa usa ka butang mahitungod sa hilisgutan sa clause ug mahimo nga pagpahayag sa aksyon, hitabo, o kahimtang.',
    'syntax.vc.description':
      'Naglangkit sa hilisgutan sa usa ka clause sa usa ka hilisgutan katimbang.',
    'general.close': 'Suod nga',
    'general.action': 'Action',
    'general.select': 'Pagpili ...',
    'general.back': 'balik',
    'revisionHistory.noData': 'Walay rebisyon kasaysayan alang sa niini nga bersikulo.',
    'revisionHistory.translation': 'Hubad',
    'revisionHistory.lastChanged': 'Last Nausab',
    'sourceText.view': 'Click sa pagtan-aw sa tinubdan nga teksto',
    'sourceText.viewStructure': 'Click sa pagtan-aw sa tinubdan nga teksto nga gambalay',
    'error.projectIdIsRequired': 'Project ID ang gikinahanglan.',
    'error.searchTermIsRequired': 'Search termino dili biyaan.',
    'error.bookIdIsRequired': 'Basahon ID ang gikinahanglan sa tindahan sa ngalan nga basahon.',
    'error.bookNameCannotBeEmpty': 'ang ngalan sa Basahon dili mahimong walay sulod.',
    'error.signInIsRequiredToAccessProject':
      'Kamo kinahanglan gayud nga nga gipirmahan sa sa pag-access sa imong proyekto.',
    error: 'sayop',
    'error.doNotHavePermissionAccessProject':
      'nga kamo wala pagtugot sa pag-access niini nga proyekto.',
    'error.unauthorized': 'Dili awtorisado nga access o imbalido access gitunol.',
    'error.tokenExpired': 'Ang imong timaan nga expire. Palihug pag-login.',
    'error.invalidApiUrl': 'Imbalido url API.',
    'error.badRequestInvalidJson': 'Bad hangyo. Imbalido JSON.',
    'error.windowIsNotDefined': 'Bintana dili gihubit.',
    'error.unexpectedError':
      'Usa ka wala damha nga kasaypanan nahitabo. Palihug sa pagsarado niini nga bintana ug mosulay pag-usab.',
    'error.invalidDataType': 'Imbalido matang data.',
    'export.data': 'export Data',
    more: 'Dugang',
    'export.to.rtf': 'Export sa RTF',
    'export.to.usfm': 'Export sa USFM',
    'export.titlePreparingData': 'Kita andam sa imong data paghubad sa Bibliya.',
    'export.doNotClose':
      'Kini nga proseso aron sa pagkuha sa usa ka samtang, palihug sa pagbuhat sa dili suod kini nga bintana ug kamo gipahibalo sa diha nga kini mao ang andam na sa pag-download.',
    'export.desc':
      'Ikaw mahimo export ug download sa imong data sa paghubad pinaagi sa pagklik buton ubos.',
    'export.titleOfReady2Download': 'Ang imong paghubad sa Bibliya data mao ang andam na.',
    'export.textOfReady2Download':
      'Palihug i-klik ang download button sa ubos sa pag-download sa imong paghubad sa Bibliya.',
    'export.statistics': 'Statistics',
    'export.book': 'ngalan sa basahon',
    'export.translatedVerses': 'gihubad nga mga bersikulo',
    'export.exportedVerses': 'eksport nga mga bersikulo',
    'export.howDoUWant2Export': 'Unsa nga paagi nga kamo gusto nga export data?',
    'export.whichBooks': 'Nga basahon (s) nga imong gusto sa eksport?',
    'export.byBooks': 'pinaagi sa mga basahon',
    'export.byChapters': 'pinaagi sa mga kapitulo',
    'export.byVerses': 'pinaagi sa mga bersikulo',
    'export.booksInOt': 'Daang Tugon',
    'export.booksInNt': 'Bag-ong Tugon',
    'export.booksInBible': 'tibook Biblia',
    'export.booksInNone': 'Walay',
    'export.selectRangeOfChapters': 'Range sa mga kapitulo',
    'export.selectRangeOfVerses': 'Range sa mga bersikulo',
    'export.selectChapter': 'Pagpili og usa ka kapitulo',
    'export.exportTypeIsRequired':
      'Ikaw wala pa pinili nga kon sa unsang paagi nga kamo gusto nga export data?',
    'export.haveNotYetSelectedBooks': 'Ikaw wala pa pinili nga sa bisan unsa nga basahon.',
    'error.selectABook': 'Kamo kinahanglan gayud nga pagpili sa usa ka basahon.',
    'error.invalidBookId': 'Imbalido nga basahon ID.',
    'error.selectAChapter': 'Kamo kinahanglan gayud nga pagpili sa usa ka kapitulo.',
    'error.invalidChapterRange':
      'Kamo kinahanglan gayud nga pagpili sa usa ka balido nga range kapitulo.',
    'error.invalidVerseRange':
      'Kamo kinahanglan gayud nga pagpili sa usa ka balido nga range bersikulo.',
    'error.invalidExportType': 'Imbalido matang export.',
    startover: 'Sugdi sa',
    download: 'download',
    'download.all': 'Download sa tanan nga mga files',
    'contact.header': 'Contact Us',
    'contact.returnAddress': 'Bumalik Address',
    'contact.returnAddressPlaceholder': 'Diin nato sa pagkab-ot kaninyo?',
    'contact.subject': 'subject',
    'contact.subjectPlaceholder': 'Unsay kinahanglan kamo sa pagtabang sa uban sa?',
    'contact.message': 'nga mensahe',
    'contact.messagePlaceholder': 'Unsa nga paagi kita makatabang?',
    'contact.sendMessage': 'ipadala Mensahe',
    'contact.success': 'Message gipadala malampuson.',
    'contact.failure':
      'Adunay usa ka problema sa pagpadala niini nga mensahe. Palihog kontaka help@globalbibleinitiative.org.',
    selectInterlinear: 'Click sa pag-usab interlinear nga teksto',
    'warning.screenTooSmall':
      'Palihug switch sa usa ka mas taas nga resolusyon screen (minimum 1024px gilapdon) o palihug magpasangkad sa imong bintana browser.',
    'label.dictionary': 'Concordance Search',
    'dict.search.placeholder': 'Pagsulod pulong (s)',
    'tooltip.manuscript': 'Manuscript',
    'tooltip.strongsx': 'Numero ni Lig-on',
    'tooltip.translation': 'Hubad',
    'tooltip.english': 'Iningles',
    'title.howToSearch': 'Sa unsa nga paagi sa paggamit sa concordance search',
    'desc.howToSearch':
      'Pagsulod (s) nga pulong gikan sa Griyego o Hebreohanon, pulong (s) gikan sa imong hubad, o sa Lig-on Number (s). O i-klik sa Grego o Hebreohanon nga mga pulong diha sa panel sa "Ang imong hubad" o paglaray, pagtalay panglantaw alang sa mga kapilian search.',
    'dict.noDataToDisplay': 'Walay mga data nga motakdo sa imong mga criteria search.',
    'title.searchTermTooMany': 'Ikaw misulod usab sa daghan nga keywords',
    'desc.searchTermTooMany': 'Ikaw mahimo sa pagpangita sa sa 8 keywords sa matag panahon.',
    'title.searchDictError': 'Usa ka wala damha nga kasaypanan nahitabo.',
    'desc.searchDictError': 'Palihug sulayi pag-usab sa ulahi o contact customer nga suporta',
    'alignment.saveAndEditAlignment': 'Save ug abin sa Bag-ong Text',
    'alignment.reviewAlignment': 'Review Text paglaray, pagtalay',
    'dict.searchLemma': 'Click sa pagpangita orihinal nga pulong',
    'dict.searchTranslation': 'Click sa pagpangita sa paghubad sa pulong',
    'dict.searchStrongsX': 'Click sa pagpangita Lig-on ni gidaghanon',
    'dict.showMenu': 'Click sa pagpakita sa bug-os nga teksto sa mga panghitabo sa konteksto.',
    'dict.hideMenu': 'Click sa pagtago sa bug-os nga teksto sa mga panghitabo sa konteksto.',
  },
  de: {
    'languages.en': 'Englisch',
    'languages.am': 'Amharisch',
    'languages.bn': 'Bengali Sprache',
    'languages.ceb': 'Cebuano',
    'languages.de': 'Deutsche Sprache',
    'languages.es-419': 'Spanische Sprache',
    'languages.fr': 'Französisch Sprache',
    'languages.gu': 'Gujarati',
    'languages.hi': 'Hindi',
    'languages.hr': 'Kroatische Sprache',
    'languages.hu': 'Ungarische Sprache',
    'languages.id': 'Indonesische Sprache',
    'languages.km': 'Khmer',
    'languages.kn': 'Kannada',
    'languages.ko': 'Koreanische Sprache',
    'languages.lo': 'laotische Sprache',
    'languages.ml': 'Malayalam',
    'languages.mr': 'Marathi',
    'languages.ms': 'malaysisch',
    'languages.my': 'burmesischen Sprache',
    'languages.ne': 'Nepali Sprache',
    'languages.plt': 'Malagasy Sprache',
    'languages.pt-br': 'Portugiesisch (Brasilien) Sprache',
    'languages.ru': 'Russische Sprache',
    'languages.sw': 'Swahili',
    'languages.ta': 'Tamilische Sprache',
    'languages.th': 'Thai Sprache',
    'languages.tl': 'Tagalog',
    'languages.te': 'Telegu Sprache',
    'languages.vi': 'Vietnamesische Sprache',
    'languages.zh-hans': 'Chinesische Sprache (vereinfacht)',
    'languages.zh-hant': 'Chinesische Sprache (traditionell)',
    'header.home': 'Zuhause',
    'header.help': 'Hilfe',
    'header.project': 'Projekt',
    'header.contact': 'Kontakt',
    'help.contactSupport': 'Bitte mailen Sie an help@youtranslate.bible für Unterstützung.',
    'home.ytbProvides': 'YouTranslate.Bible bietet frei:',
    'home.easyToUseTool': 'Ein einfach zu bedienende Online-Übersetzungs-Tool',
    'home.originalTextHelp':
      'Helfen Sie den Text der Bibel in ihren ursprünglichen Sprachen zu verstehen',
    'home.referencesAndResources': 'Referenz und Schulungsressourcen',
    'home.computerAssistance': 'State-of-the-art Computer-Technologie Übersetzer zu unterstützen,',
    'profile.userProfile': 'Benutzerprofil',
    'profile.language': 'Sprache',
    switchDirection: 'Schalter Textrichtung',
    'profile.update': 'Aktualisieren',
    'profile.languageWarning':
      'Google Translate verwendet wurde vorübergehend die Benutzeroberfläche in anderen Sprachen als Englisch zu übersetzen. YTB ​​hat keine Kontrolle über die Genauigkeit und Zuverlässigkeit der Übersetzung. Wer sich auf die übersetzten Beschriftungen und Erklärungen von Google erhalten Übersetzen, tut dies auf eigene Gefahr. Wenn jemand in freiwilligem Engagement interessiert ist, genauer und zuverlässige Übersetzung der Benutzeroberfläche in anderen Sprachen als Englisch zur Verfügung zu stellen, klicken Sie bitte auf der Option Kontakt eine E-Mail an GBI zu senden.',
    'usernav.profile': 'Profil',
    'usernav.logout': 'Ausloggen',
    'usernav.logout.modalTitle': 'Logout von YouTranslate.Bible',
    'usernav.logout.modalText':
      'Sind Sie sicher, dass Sie sich ausloggen? Alle nicht gespeicherten Daten gehen verloren.',
    cancel: 'Stornieren',
    delete: 'Löschen',
    create: 'Erstellen',
    update: 'Aktualisieren',
    edit: 'Bearbeiten',
    save: 'sparen',
    add: 'Hinzufügen',
    translate: 'Übersetzen',
    'more.options': 'Mehr Optionen',
    'project.create.title': 'Bibel Übersetzungsprojekt',
    'project.create.list': 'Projektliste',
    'project.list.deleteProject': 'Projekt löschen?',
    'project.list.areYouSure': 'Sind Sie sicher, dass Sie dieses Projekt löschen?',
    'project.list.workWillBeDeleted':
      'Das Projekt und jede Arbeit verbunden sind, werden gelöscht.',
    'project.list.completion': 'Fertigstellung:',
    'project.list.createdDate': 'Erstellt am:',
    'project.list.beginningDate': 'Anfangsdatum:',
    'project.list.targetCompletionDate': 'Ziel Fertigstellung:',
    'project.list.description': 'Beschreibung:',
    'project.edit': 'Projekt bearbeiten',
    'project.list.translateBible': 'Übersetzen Bibel',
    'project.list.empty': 'Ihr Übersetzungsprojekt Liste ist leer.',
    'project.list.myProjects': 'Meine Bibel Übersetzungsprojekte',
    'project.list.newProject': 'Neues Projekt',
    'project.create.createNew': 'Erstellen Sie ein neues Projekt',
    'project.namePlaceholder': 'Geben Sie Projektnamen',
    'project.descriptionPlaceholder': 'Geben Sie Projektbeschreibung',
    'project.name': 'Projektname',
    'project.beginningDate': 'Anfangsdatum',
    'project.targetCompletionDate': 'Ziel Fertigstellung',
    'project.description': 'Beschreibung',
    'project.isRTL': 'Die Übersetzung ist von rechts nach links',
    'project.manage-users': 'verwalten Übersetzer',
    'project.new-user': 'New Übersetzer',
    'project.add-user': 'In Übersetzer',
    'project.users': 'Projekt Übersetzer',
    'project.read-only': 'Schreibgeschützt',
    'project.read&write': 'Lesen Schreiben',
    'project.owner': 'Inhaber',
    'project.translator': 'Übersetzer',
    'project.message.no-such-user': 'Eine solche Übersetzer vorhanden',
    'project.message.user-added': 'Übersetzer erfolgreich hinzugefügt',
    'project.message.user-deleted': 'Übersetzer entfernt.',
    'project.message.only-owner-can-manage-users': 'Nur Projektinhaber können Benutzer verwalten',
    'project.shared': 'Verbundprojekt',
    'general.delete-user': 'Delete Übersetzer',
    'general.edit-user': 'Benutzer bearbeiten',
    'general.name': 'Name',
    'general.email': 'Email',
    'general.permissions': 'Berechtigungen',
    'general.role': 'Rolle',
    signin: 'Einloggen',
    'signin.emailPlaceholder': 'E-Mail-Addresse',
    'signin.passwordPlaceholder': 'Passwort',
    'signin.forgotPassword': 'Passwort vergessen?',
    'signin.facebook': 'Mit Facebook anmelden',
    'signin.google': 'Anmeldung mit Google',
    'signin.dontHaveAccount': 'Sie haben noch kein Konto?',
    'signin.createOne': 'Erstelle einen',
    'signup.join': 'Registriert YouTranslate.Bible',
    'signup.createAccountPrompt': 'Legen Sie Ihr gratis Konto zu beginnen.',
    'signup.createAccount': 'Konto erstellen',
    'signup.emailPlaceholder': 'E-Mail-Addresse',
    'signup.passwordPlaceholder': 'Passwort',
    'passwordReset.title': 'Passwort zurücksetzen',
    'passwordReset.prompt':
      'Fordern Sie eine E-Mail mit Anweisungen zum Zurücksetzen des Passworts.',
    'passwordReset.emailPlaceholder': 'E-Mail-Addresse',
    'passwordReset.requestButtonLabel': 'Anfrage per E-Mail',
    'passwordReset.success': 'Die Email wurde verschickt.',
    'passwordReset.error': 'Ein Fehler ist aufgetreten.',
    'translation.project': 'PROJEKT:',
    'translation.backToProjectList': 'Zurück zur Projektliste',
    'translation.book': 'Buch:',
    'translation.chapter': 'Kapitel:',
    'translation.prompt':
      'Sind Sie sicher, dass Sie dieses Kapitel verlassen? Alle nicht gespeicherten Daten gehen verloren.',
    'translation.prev': 'Zurück',
    'translation.next': 'Nächster',
    'translation.previousChapter': 'Vorheriges Kapitel',
    'translation.nextChapter': 'Nächstes Kapitel',
    'navigator.book': 'Buch:',
    'navigator.chapter': 'Kapitel:',
    'navigator.oldTestament': 'Altes Testament',
    'navigator.newTestament': 'Neues Testament',
    'navigator.selectBook': 'Wählen Sie ein Buch',
    'navigator.search': 'Suche',
    'navigator.changeBook': 'ändern Buch',
    'navigator.changeChapter': 'ändern Kapitel',
    Genesis: 'Genesis',
    Exodus: 'Exodus',
    Leviticus: 'Leviticus',
    Numbers: 'Zahlen',
    Deuteronomy: 'Deuteronomium',
    Joshua: 'Joshua',
    Judges: 'Jury',
    Ruth: 'Ruth',
    '1 Samuel': '1 Samuel',
    '2 Samuel': '2 Samuel',
    '1 Kings': '1 Kings',
    '2 Kings': '2 King',
    '1 Chronicles': '1 Chronicles',
    '2 Chronicles': '2 Chronicles',
    Ezra: 'Ezra',
    Nehemiah: 'Nehemiah',
    Esther: 'Esther',
    Job: 'Job',
    Psalms: 'Psalter',
    Proverbs: 'Sprüche',
    Ecclesiastes: 'Kohelet',
    'Song of Songs': 'Lied',
    Isaiah: 'Isaiah',
    Jeremiah: 'Jeremiah',
    Lamentations: 'Lamentations',
    Ezekiel: 'Ezekiel',
    Daniel: 'Daniel',
    Hosea: 'Hosea',
    Joel: 'Joel',
    Amos: 'Amos',
    Obadiah: 'Obadiah',
    Jonah: 'Jona',
    Micah: 'Micah',
    Nahum: 'Nahum',
    Habakkuk: 'Habakuk',
    Zephaniah: 'Zephaniah',
    Haggai: 'Haggai',
    Zechariah: 'Sacharja',
    Malachi: 'Malachi',
    Matthew: 'Matthew',
    Mark: 'Kennzeichen',
    Luke: 'Luke',
    John: 'John',
    Acts: 'Acts',
    Romans: 'Römer',
    '1 Corinthians': '1.Korinther',
    '2 Corinthians': '2 Corinthians',
    Galatians: 'Gal',
    Ephesians: 'Epheser',
    Philippians: 'Philipper',
    Colossians: 'Kolosser',
    '1 Thessalonians': '1. Thessalonicher',
    '2 Thessalonians': '2.Thessalonicher',
    '1 Timothy': '1 Timothy',
    '2 Timothy': '2 Timothy',
    Titus: 'Titus',
    Philemon: 'Philemon',
    Hebrews: 'Hebräer',
    James: 'James',
    '1 Peter': '1 Peter',
    '2 Peter': '2 Peter',
    '1 John': '1 John',
    '2 John': '2 John',
    '3 John': '3 John',
    Jude: 'Jude',
    Revelation: 'Offenbarung',
    'resources.prompt':
      'In biblischen Ressourcen für zusätzliche Einblicke in die Passage Sie übersetzen.',
    'resources.add': 'Fügen Sie eine biblische Ressource',
    Manuscript: 'Manuskript',
    Morphology: 'Morphologie',
    Lexicon: 'Lexikon',
    'Syntactic Tree': 'syntaktischer Baum',
    'Go to project list': 'Zum Projektliste',
    'Project not found': 'Projekt nicht oder nicht genügend Berechtigungen gefunden.',
    'error.referenceDoesNotExistForResource':
      'Diese Bibelübersetzung enthält keinen Inhalt für die ausgewählte Passage.',
    'references.addAnother': 'Fügen Sie eine weitere Übersetzung',
    'references.referencePrompt': 'In anderen Bibelübersetzungen für zusätzliche Einblicke.',
    'references.add': 'Fügen Sie eine Bibelübersetzung',
    'references.licensing': 'Lizenzinformationen',
    'references.licensingEmpty': 'Keine Lizenzinformationen wurden für diesen Artikel gefunden.',
    'translation.complete': 'Übersetzung komplett',
    Alignment: 'Ausrichtung',
    Share: 'Aktie',
    'Your Translation': 'Ihre Übersetzung',
    'Enter your translation': 'Geben Sie Ihre Übersetzung',
    OK: 'OK',
    'error.verseTranslationEmpty': 'Sie können die Übersetzung Inhalt nicht leer lassen.',
    'translation.hide.completed': 'ausblenden abgeschlossen',
    alignment: 'Ausrichtung',
    'alignment.verify': 'Stellen Sie sicher, und schließen.',
    'alignment.close': 'Schließen ohne Verifizieren',
    'alignment.backToEditor': 'Zurück zum Editor',
    'alignment.error':
      'Etwas ist schief gelaufen. Wenn das Problem weiterhin besteht, kontaktieren Sie bitte das YouTranslate.Bible Team.',
    'alignment.add': 'Link hinzufügen',
    'alignment.delete': 'Link entfernen',
    'alignment.clear': 'Auswahl löschen',
    'alignment.reverse': 'Reverse-Anzeige',
    'alignment.removeOrClear':
      'Entfernen Sie die ausgewählte Verbindung oder übersichtliche Auswahl.',
    'alignment.addOrRemove': 'Erstellen Sie einen neuen Link oder einen vorherigen löschen.',
    'alignment.selectAndAdd': 'Wählen Segmente Verbindung entspricht.',
    'error.mustBeSignedToMakeChanges': 'Sie müssen, um Änderungen zu unterzeichnen.',
    'error.mustBeSignedToTranslateBible': 'Sie müssen zu übersetzen, die Bibel zu unterzeichnen.',
    checking: 'Überprüfung',
    'verseEditor.unexpectedError': 'Ein unerwarteter Fehler ist aufgetreten.',
    verses: 'durch Verse',
    words: 'Mit dem Wort',
    'leftPanel.name': 'Originaltext der Bibel',
    'rightPanel.name': 'Übersetzungen der Bibel',
    'bottomPanel.name': 'Ressourcen für die Bibel',
    revisionHistory: 'Versionsgeschichte',
    'syntax.adv': 'Adverbiales Attribut',
    'syntax.adjp': 'adjektivische Phrase',
    'syntax.advp': 'adverb Phrase',
    'syntax.cl': 'Klausel',
    'syntax.conj': 'Verbindung',
    'syntax.cjp': 'Verbindung',
    'syntax.det': 'Bestimmer',
    'syntax.ijp': 'Zwischenruf',
    'syntax.nump': 'numerische Phrase',
    'syntax.np': 'Nominalphrase',
    'syntax.o': 'Direktes Objekt',
    'syntax.o2': 'Zweites Objekt',
    'syntax.oc': 'Objekt Complement',
    'syntax.om': 'Object Marker',
    'syntax.io': 'Indirektes Objekt',
    'syntax.p': 'Nonverbale Prädikat',
    'syntax.pp': 'Präpositionalphrase',
    'syntax.prep': 'Präposition',
    'syntax.ptcl': 'Partikel',
    'syntax.rel': 'Relative Particle',
    'syntax.s': 'Gegenstand',
    'syntax.v': 'Verb',
    'syntax.vc': 'Linking Verb',
    'syntax.adjp.description': 'Phrase, die ein Substantiv oder Pronomen modifiziert',
    'syntax.adv.description':
      'Modifiziert das Verb, typischerweise weiter definieren Weise, Zeit, Ort, Frequenz, Grad, etc ...',
    'syntax.advp.description':
      'Phrase Modifizieren des Verbs, typischerweise weiter definieren Weise, Zeit, Ort, Frequenz, Grad usw.',
    'syntax.cl.description': 'Eine Gruppe von Wörtern, die eine Aussage über das Thema macht.',
    'syntax.conj.description': 'Verbindet Wörter, Phrasen und Klauseln.',
    'syntax.cjp.description': 'Verbindet Wörter, Phrasen und Klauseln.',
    'syntax.det.description': 'Gibt an, identifiziert oder quantifiziert eine Nominalphrase.',
    'syntax.ijp.description': 'Exclamation oder plötzlicher Ausdruck von Emotionen.',
    'syntax.nump.description': 'Phrase, die numerische Größe beschreibt',
    'syntax.np.description': 'Ein Satz, der ein Substantiv als Kopf oder, das als Substantiv hat.',
    'syntax.o.description':
      'Dass die direkt betroffen ist, verursacht wird, oder durch die Einwirkung eines Verbs hergestellt.',
    'syntax.o2.description':
      'Dass die direkt betroffen ist, verursacht wird, oder durch die Einwirkung eines Verbs hergestellt.',
    'syntax.oc.description': 'Macht eine Aussage über das direkte Objekt.',
    'syntax.om.description': 'Teilchen, das ein direktes Objekt führt.',
    'syntax.io.description':
      'Objekt, das indirekt durch eine Aktion betroffen ist, oft der Empfänger der Aktion eines Verbs.',
    'syntax.p.description':
      'Identifiziert oder beschreibt das Thema, oft (aber nicht immer) tritt zusammen mit einem linking verb.',
    'syntax.pp.description': 'Ändern Satz bestehend aus einer Präposition und ihre Aufgabe.',
    'syntax.prep.description':
      'Gibt Beziehung des Objekts der Präposition mit anderen Worten, oft anzeigt, wo oder wann.',
    'syntax.ptcl.description':
      'Ein Mitglied aus der vielfältigen Gruppe von Funktionsworten, die auf einen der gebeugten grammatischen Wortklassen gehören nicht',
    'syntax.rel.description': 'Teilchen, die eine Relativsatz führt.',
    'syntax.s.description':
      'Der Teil der Klausel, dass der Rest der Klausel über eine Behauptung macht.',
    'syntax.v.description':
      'Besagt etwas über das Subjekt des Satzes und kann eine Aktion, ein Ereignis oder Zustand auszudrücken.',
    'syntax.vc.description': 'Verknüpft den Gegenstand einer Klausel zu einem Thema ergänzen.',
    'general.close': 'Schließen',
    'general.action': 'Aktion',
    'general.select': 'Wählen...',
    'general.back': 'Zurück',
    'revisionHistory.noData': 'Es gibt keine Revisionsgeschichte für diesen Vers.',
    'revisionHistory.translation': 'Übersetzung',
    'revisionHistory.lastChanged': 'Zuletzt geändert',
    'sourceText.view': 'Klicken Sie auf Quelltext anzuzeigen',
    'sourceText.viewStructure': 'Klicken Sie auf Quelltext Struktur anzeigen',
    'error.projectIdIsRequired': 'Projekt-ID erforderlich.',
    'error.searchTermIsRequired': 'Suchbegriff darf nicht leer.',
    'error.bookIdIsRequired': 'Buch-ID erforderlich Buch Namen zu speichern.',
    'error.bookNameCannotBeEmpty': 'Buch-Name kann nicht leer sein.',
    'error.signInIsRequiredToAccessProject':
      'Sie müssen für den Zugriff auf Ihr Projekt zu unterzeichnen.',
    error: 'Error',
    'error.doNotHavePermissionAccessProject':
      'Sie haben keine Berechtigung dieses Projekt zugreifen.',
    'error.unauthorized': 'Unberechtigter Zugriff oder ungültige Zugriffstoken.',
    'error.tokenExpired': 'Ihr Token ist abgelaufen. Bitte neu einloggen.',
    'error.invalidApiUrl': 'Ungültige API URL.',
    'error.badRequestInvalidJson': 'Ungültige Anforderung. Ungültige JSON.',
    'error.windowIsNotDefined': 'Fenster sind nicht definiert.',
    'error.unexpectedError':
      'Ein unerwarteter Fehler ist aufgetreten. Bitte schließen Sie dieses Fenster und versuchen Sie es erneut.',
    'error.invalidDataType': 'Ungültige Datentyp.',
    'export.data': 'Daten exportieren',
    more: 'Mehr',
    'export.to.rtf': 'Export in RTF',
    'export.to.usfm': 'Export nach USFM',
    'export.titlePreparingData': 'Wir bereiten Ihre Übersetzungsdaten Bibel.',
    'export.doNotClose':
      'Dieser Vorgang kann eine Weile dauern, bitte nicht schließen Sie dieses Fenster und Sie werden benachrichtigt, wenn es zum Download bereit ist.',
    'export.desc':
      'Sie können Ihre Übersetzungsdaten durch Klicken auf Schaltflächen unten exportieren und herunterladen.',
    'export.titleOfReady2Download': 'Ihre Bibelübersetzung Daten bereit.',
    'export.textOfReady2Download':
      'Bitte klicken Sie auf den Download-Button unten Ihre Bibelübersetzung zum Download bereit.',
    'export.statistics': 'Statistiken',
    'export.book': 'Buchname',
    'export.translatedVerses': 'übersetzt Verse',
    'export.exportedVerses': 'exportierte Verse',
    'export.howDoUWant2Export': 'Wie wollen Sie Daten exportieren?',
    'export.whichBooks': 'Welches Buch (en) möchten Sie exportieren?',
    'export.byBooks': 'durch Bücher',
    'export.byChapters': 'von Kapiteln',
    'export.byVerses': 'durch Verse',
    'export.booksInOt': 'Altes Testament',
    'export.booksInNt': 'Neues Testament',
    'export.booksInBible': 'ganz Bibel',
    'export.booksInNone': 'Keiner',
    'export.selectRangeOfChapters': 'Bereich von Kapiteln',
    'export.selectRangeOfVerses': 'Bereich von Versen',
    'export.selectChapter': 'Wählen Sie ein Kapitel',
    'export.exportTypeIsRequired':
      'Sie haben noch nicht über ausgewählt, wie Sie Daten exportieren wollen?',
    'export.haveNotYetSelectedBooks': 'Sie haben noch kein Buch ausgewählt.',
    'error.selectABook': 'Sie müssen ein Buch auswählen.',
    'error.invalidBookId': 'Ungültige Buch ID.',
    'error.selectAChapter': 'Sie müssen ein Kapitel auswählen.',
    'error.invalidChapterRange': 'Sie müssen einen gültigen Kapitel Bereich auswählen.',
    'error.invalidVerseRange': 'Sie müssen einen gültigen Vers Bereich auswählen.',
    'error.invalidExportType': 'Ungültige Exporttyp.',
    startover: 'Von vorn anfangen',
    download: 'Herunterladen',
    'download.all': 'Alle Dateien herunterladen',
    'contact.header': 'Kontaktiere uns',
    'contact.returnAddress': 'Absender',
    'contact.returnAddressPlaceholder': 'Wo können wir Sie erreichen?',
    'contact.subject': 'Gegenstand',
    'contact.subjectPlaceholder': 'Was brauchen Sie Hilfe?',
    'contact.message': 'Botschaft',
    'contact.messagePlaceholder': 'Wie können wir helfen?',
    'contact.sendMessage': 'Nachricht senden',
    'contact.success': 'Nachricht wurde erfolgreich versendet.',
    'contact.failure':
      'Es gab ein Problem Diese Nachricht zu senden. Bitte kontaktieren Sie help@globalbibleinitiative.org.',
    selectInterlinear: 'Klicken Sie interlinear Text zu ändern',
    'warning.screenTooSmall':
      'Bitte schalten Sie auf eine höhere Bildschirmauflösung (mindestens 1024px breit) oder geben Sie bitte Ihre Browser-Fenster erweitern.',
    'label.dictionary': 'Konkordanzsuche',
    'dict.search.placeholder': 'Geben Sie (e)',
    'tooltip.manuscript': 'Manuskript',
    'tooltip.strongsx': 'Strongs Nummer',
    'tooltip.translation': 'Übersetzung',
    'tooltip.english': 'Englisch',
    'title.howToSearch': 'Wie Konkordanzsuche verwenden,',
    'desc.howToSearch':
      'Geben Sie (e) aus dem Griechischen oder Hebräischen Wort (e) von Ihrer Übersetzung oder starken Nummer (n). Oder klicken Sie auf den griechischen oder hebräischen Worte in der Platte von „Ihrer Übersetzung“ oder Ausrichtung Ansichten für Suchoptionen.',
    'dict.noDataToDisplay': 'Es gibt keine Daten, die Ihre Suchkriterien entsprechen.',
    'title.searchTermTooMany': 'Sie haben zu viele Schlüsselwörter eingegeben',
    'desc.searchTermTooMany': 'Sie können bis zu 8 keywords jedes Mal suchen.',
    'title.searchDictError': 'Ein unerwarteter Fehler ist aufgetreten.',
    'desc.searchDictError':
      'Bitte versuchen Sie es später noch einmal oder kontaktieren Kunden-Support',
    'alignment.saveAndEditAlignment': 'Speichern und Align Neuer Text',
    'alignment.reviewAlignment': 'Bewertung Textausrichtung',
    'dict.searchLemma': 'Klicken Sie auf Original-Wort suchen',
    'dict.searchTranslation': 'Klicken Übersetzung Wort suchen',
    'dict.searchStrongsX': 'Klicken Sie Strongs Nummer suchen',
    'dict.showMenu': 'Klicken Sie volle Wortlaut der Ereignisse in Zusammenhang zu zeigen.',
    'dict.hideMenu':
      'Klicken Sie zum vollständigen Wortlaut der Ereignisse in Zusammenhang zu verbergen.',
  },
  'es-419': {
    'languages.en': 'Inglés',
    'languages.am': 'amárico',
    'languages.bn': 'bengalí',
    'languages.ceb': 'Cebuano Language',
    'languages.de': 'Idioma aleman',
    'languages.es-419': 'Lengua española',
    'languages.fr': 'Idioma francés',
    'languages.gu': 'gujarati',
    'languages.hi': 'hindi',
    'languages.hr': 'idioma croata',
    'languages.hu': 'Idioma húngaro',
    'languages.id': 'Idioma indonesio',
    'languages.km': 'Khmer',
    'languages.kn': 'kannada',
    'languages.ko': 'Lenguaje Koreano',
    'languages.lo': 'Lengua de Laos',
    'languages.ml': 'malayalam',
    'languages.mr': 'marathi',
    'languages.ms': 'malasio',
    'languages.my': 'birmano idioma',
    'languages.ne': 'Nepali Language',
    'languages.plt': 'Madagascar idioma',
    'languages.pt-br': 'Portugués (Brasil) Idioma',
    'languages.ru': 'Idioma ruso',
    'languages.sw': 'swahili',
    'languages.ta': 'Lenguaje tamil',
    'languages.th': 'Idioma tailandés',
    'languages.tl': 'Tagalo',
    'languages.te': 'telegu idioma',
    'languages.vi': 'Idioma vietnamita',
    'languages.zh-hans': 'Idioma Chino (simplificado)',
    'languages.zh-hant': 'Idioma Chino (tradicional)',
    'header.home': 'Hogar',
    'header.help': 'Ayuda',
    'header.project': 'Proyecto',
    'header.contact': 'Contacto',
    'help.contactSupport':
      'Por favor, envíenos un email a help@youtranslate.bible para obtener ayuda.',
    'home.ytbProvides': 'YouTranslate.Bible ofrece libremente:',
    'home.easyToUseTool': 'Una herramienta de traducción en línea fácil de usar',
    'home.originalTextHelp': 'Ayuda a entender el texto de la Biblia en sus idiomas originales',
    'home.referencesAndResources': 'recursos de referencia y formación',
    'home.computerAssistance':
      'Estado actual de la técnica de la tecnología informática para ayudar a los traductores',
    'profile.userProfile': 'Perfil del usuario',
    'profile.language': 'Idioma',
    switchDirection: 'Interruptor de dirección del texto',
    'profile.update': 'Actualizar',
    'profile.languageWarning':
      'Traductor Google se utiliza para traducir la interfaz de usuario a idiomas distintos del Inglés temporalmente. YTB ​​no tiene control sobre la exactitud o fiabilidad de la traducción. Cualquiera que depender de las etiquetas y explicaciones traducidas obtenidos de Google Traducir lo hace bajo su propio riesgo. Si alguien está interesado en ser voluntario para proporcionar una traducción más precisa y fiable de la interfaz de usuario a idiomas distintos del Inglés, por favor haga clic en la opción de contacto para enviar un correo electrónico a GBI.',
    'usernav.profile': 'Perfil',
    'usernav.logout': 'Cerrar sesión',
    'usernav.logout.modalTitle': 'Salir de YouTranslate.Bible',
    'usernav.logout.modalText':
      '¿Seguro que quieres salir? se perderá todo el trabajo no guardado.',
    cancel: 'Cancelar',
    delete: 'Eliminar',
    create: 'Crear',
    update: 'Actualizar',
    edit: 'Editar',
    save: 'Salvar',
    add: 'Añadir',
    translate: 'Traducir',
    'more.options': 'Mas opciones',
    'project.create.title': 'Bible Translation Project',
    'project.create.list': 'Lista de proyectos',
    'project.list.deleteProject': 'Eliminar Proyecto?',
    'project.list.areYouSure': '¿Está seguro de que quiere eliminar este proyecto?',
    'project.list.workWillBeDeleted': 'Se eliminarán del proyecto y cualquier trabajo asociado.',
    'project.list.completion': 'Terminación:',
    'project.list.createdDate': 'Fecha de creación:',
    'project.list.beginningDate': 'Fecha de comienzo:',
    'project.list.targetCompletionDate': 'Fecha límite de finalización:',
    'project.list.description': 'Descripción:',
    'project.edit': 'Editar Proyecto',
    'project.list.translateBible': 'traducir la Biblia',
    'project.list.empty': 'Su lista de proyectos de traducción está vacío.',
    'project.list.myProjects': 'Mi Biblia Proyectos de traducción',
    'project.list.newProject': 'Nuevo proyecto',
    'project.create.createNew': 'Crear un nuevo proyecto',
    'project.namePlaceholder': 'Introduzca el nombre del proyecto',
    'project.descriptionPlaceholder': 'Introduzca la descripción del proyecto',
    'project.name': 'Nombre del proyecto',
    'project.beginningDate': 'Fecha de comienzo',
    'project.targetCompletionDate': 'Fecha límite de finalización',
    'project.description': 'Descripción',
    'project.isRTL': 'La traducción es de derecha a izquierda',
    'project.manage-users': 'Manejo de Traductores',
    'project.new-user': 'nueva Traductor',
    'project.add-user': 'Agregar Traductor',
    'project.users': 'traductores del proyecto',
    'project.read-only': 'Solo lectura',
    'project.read&write': 'Leer escribir',
    'project.owner': 'Propietario',
    'project.translator': 'Traductor',
    'project.message.no-such-user': 'No existe tal Traductor',
    'project.message.user-added': 'Traductor añadido correctamente',
    'project.message.user-deleted': 'Traductor eliminado.',
    'project.message.only-owner-can-manage-users':
      'propietario del proyecto sólo puede administrar usuarios',
    'project.shared': 'Proyecto colaborativo',
    'general.delete-user': 'Traductor de eliminación',
    'general.edit-user': 'Editar usuario',
    'general.name': 'Nombre',
    'general.email': 'Email',
    'general.permissions': 'permisos',
    'general.role': 'Papel',
    signin: 'Registrarse',
    'signin.emailPlaceholder': 'Dirección de correo electrónico',
    'signin.passwordPlaceholder': 'Contraseña',
    'signin.forgotPassword': '¿Olvidaste tu contraseña?',
    'signin.facebook': 'Iniciar sesión usando Facebook',
    'signin.google': 'Inicia sesión con Google',
    'signin.dontHaveAccount': '¿No tienes una cuenta?',
    'signin.createOne': 'Crea uno',
    'signup.join': 'Únete YouTranslate.Bible',
    'signup.createAccountPrompt': 'Crea tu cuenta gratis para empezar.',
    'signup.createAccount': 'Crear una cuenta',
    'signup.emailPlaceholder': 'Dirección de correo electrónico',
    'signup.passwordPlaceholder': 'Contraseña',
    'passwordReset.title': 'Restablecimiento de contraseña',
    'passwordReset.prompt':
      'Solicitar un correo electrónico con instrucciones para restablecer la contraseña.',
    'passwordReset.emailPlaceholder': 'Dirección de correo electrónico',
    'passwordReset.requestButtonLabel': 'solicitud de correo electrónico',
    'passwordReset.success': 'E-mail ha sido enviado.',
    'passwordReset.error': 'Se ha producido un error.',
    'translation.project': 'PROYECTO:',
    'translation.backToProjectList': 'Volver a la lista de proyectos',
    'translation.book': 'Libro:',
    'translation.chapter': 'Capítulo:',
    'translation.prompt':
      '¿Seguro que quieres dejar este capítulo? se perderá todo el trabajo no guardado.',
    'translation.prev': 'Anterior',
    'translation.next': 'próximo',
    'translation.previousChapter': 'Capítulo previo',
    'translation.nextChapter': 'Siguiente capítulo',
    'navigator.book': 'Libro:',
    'navigator.chapter': 'Capítulo:',
    'navigator.oldTestament': 'Viejo Testamento',
    'navigator.newTestament': 'Nuevo Testamento',
    'navigator.selectBook': 'Seleccione un libro',
    'navigator.search': 'Buscar',
    'navigator.changeBook': 'cambio de libros',
    'navigator.changeChapter': 'cambio capítulo',
    Genesis: 'Génesis',
    Exodus: 'éxodo',
    Leviticus: 'Levíticio',
    Numbers: 'Números',
    Deuteronomy: 'Deuteronomio',
    Joshua: 'Joshua',
    Judges: 'jueces',
    Ruth: 'Piedad',
    '1 Samuel': '1 Samuel',
    '2 Samuel': '2 Samuel',
    '1 Kings': '1 Reyes',
    '2 Kings': '2 Reyes',
    '1 Chronicles': '1 Crónicas',
    '2 Chronicles': '2 Crónicas',
    Ezra: 'Esdras',
    Nehemiah: 'Nehemías',
    Esther: 'Esther',
    Job: 'Trabajo',
    Psalms: 'Salmos',
    Proverbs: 'Proverbios',
    Ecclesiastes: 'Eclesiastés',
    'Song of Songs': 'Canción de canciones',
    Isaiah: 'Isaías',
    Jeremiah: 'Jeremías',
    Lamentations: 'Lamentaciones',
    Ezekiel: 'Ezequiel',
    Daniel: 'Daniel',
    Hosea: 'Oseas',
    Joel: 'Joel',
    Amos: 'Amos',
    Obadiah: 'Abdías',
    Jonah: 'Jonás',
    Micah: 'Miqueas',
    Nahum: 'Nahum',
    Habakkuk: 'Habacuc',
    Zephaniah: 'Sofonías',
    Haggai: 'Ageo',
    Zechariah: 'Zacarías',
    Malachi: 'Malaquías',
    Matthew: 'Mateo',
    Mark: 'marca',
    Luke: 'Lucas',
    John: 'Juan',
    Acts: 'Hechos',
    Romans: 'Romanos',
    '1 Corinthians': '1 Corintios',
    '2 Corinthians': '2 Corintios',
    Galatians: 'Gálatas',
    Ephesians: 'Efesios',
    Philippians: 'filipenses',
    Colossians: 'Colosenses',
    '1 Thessalonians': '1 Tesalonicenses',
    '2 Thessalonians': '2 Tesalonicenses',
    '1 Timothy': '1 Timoteo',
    '2 Timothy': '2 Timoteo',
    Titus: 'Tito',
    Philemon: 'Filemón',
    Hebrews: 'Hebreos',
    James: 'James',
    '1 Peter': '1 Pedro',
    '2 Peter': '2 Pedro',
    '1 John': '1 Juan',
    '2 John': '2 Juan',
    '3 John': '3 Juan',
    Jude: 'Jude',
    Revelation: 'Revelación',
    'resources.prompt':
      'Añadir recursos bíblicos para la penetración adicional en el paso que se está traduciendo.',
    'resources.add': 'Añadir un recurso bíblico',
    Manuscript: 'Manuscrito',
    Morphology: 'Morfología',
    Lexicon: 'Léxico',
    'Syntactic Tree': 'árbol sintáctico',
    'Go to project list': 'Ir a la lista de proyectos',
    'Project not found': 'Proyecto no encontrado o permisos insuficientes.',
    'error.referenceDoesNotExistForResource':
      'Esta traducción de la Biblia no contiene contenido para el pasaje seleccionado.',
    'references.addAnother': 'Añadir otra traducción',
    'references.referencePrompt':
      'Añadir otras traducciones de la Biblia para la penetración adicional.',
    'references.add': 'Añadir una traducción de la Biblia',
    'references.licensing': 'Información sobre licencias',
    'references.licensingEmpty': 'No hay información de licencia fue encontrado por este concepto.',
    'translation.complete': 'La traducción completa',
    Alignment: 'Alineación',
    Share: 'Compartir',
    'Your Translation': 'su traducción',
    'Enter your translation': 'Introduzca su traducción',
    OK: 'Okay',
    'error.verseTranslationEmpty': 'No se puede dejar vacía el contenido de la traducción.',
    'translation.hide.completed': 'Ocultar completado',
    alignment: 'Alineación',
    'alignment.verify': 'Verificar y cerrar.',
    'alignment.close': 'Cerrar sin verificar',
    'alignment.backToEditor': 'Volver al Editor',
    'alignment.error':
      'Algo salió mal. Si persiste el problema, póngase en contacto con el equipo YouTranslate.Bible.',
    'alignment.add': 'Añadir enlace',
    'alignment.delete': 'Remover enlace',
    'alignment.clear': 'Selección clara',
    'alignment.reverse': 'Visualización inversa',
    'alignment.removeOrClear': 'Retire el enlace seleccionado o una selección clara.',
    'alignment.addOrRemove': 'Crear un nuevo enlace o eliminar una anterior.',
    'alignment.selectAndAdd': 'Seleccionar segmentos correspondientes a enlace.',
    'error.mustBeSignedToMakeChanges': 'Debe haber iniciado sesión en Realizar cambios.',
    'error.mustBeSignedToTranslateBible': 'Debe haber iniciado sesión en traducir la Biblia.',
    checking: 'Comprobación',
    'verseEditor.unexpectedError': 'Ha ocurrido un error inesperado.',
    verses: 'por Verso',
    words: 'por Palabra',
    'leftPanel.name': 'El texto original de la Biblia',
    'rightPanel.name': 'Las traducciones de la Biblia',
    'bottomPanel.name': 'Los recursos para la Biblia',
    revisionHistory: 'Revisión histórica',
    'syntax.adv': 'Modificador adverbial',
    'syntax.adjp': 'Frase adjetiva',
    'syntax.advp': 'Frase adverbio',
    'syntax.cl': 'Cláusula',
    'syntax.conj': 'Conjunción',
    'syntax.cjp': 'Conjunción',
    'syntax.det': 'determinador',
    'syntax.ijp': 'Interjección',
    'syntax.nump': 'Frase numérica',
    'syntax.np': 'sintagma nominal',
    'syntax.o': 'Objeto directo',
    'syntax.o2': 'segundo objeto',
    'syntax.oc': 'Complemento de objeto',
    'syntax.om': 'marcador de objetos',
    'syntax.io': 'Objeto indirecto',
    'syntax.p': 'No verbal predicado',
    'syntax.pp': 'Frase preposicional',
    'syntax.prep': 'Preposición',
    'syntax.ptcl': 'Partícula',
    'syntax.rel': 'partículas relativa',
    'syntax.s': 'Tema',
    'syntax.v': 'Verbo',
    'syntax.vc': 'Verbo que une',
    'syntax.adjp.description': 'Frase que modifica un sustantivo o un pronombre',
    'syntax.adv.description':
      'Modifica el verbo, que define típicamente más modo, tiempo, lugar, frecuencia, grado, etc ...',
    'syntax.advp.description':
      'Frase modificar el verbo, típicamente aún más la definición de modo, tiempo, lugar, frecuencia, grado, etc.',
    'syntax.cl.description': 'Un grupo de palabras que hace una afirmación sobre el tema.',
    'syntax.conj.description': 'Se conecta las palabras, frases y oraciones.',
    'syntax.cjp.description': 'Se conecta las palabras, frases y oraciones.',
    'syntax.det.description': 'Especifica, identifica, cuantifica o un sintagma nominal.',
    'syntax.ijp.description': 'Exclamación o repentina expresión de la emoción.',
    'syntax.nump.description': 'Frase que describe cantidad numérica',
    'syntax.np.description':
      'Una frase que tiene un nombre como su cabeza o que funciona como un sustantivo.',
    'syntax.o.description':
      'Lo que es directamente afectada, causado, o producidos por la acción de un verbo.',
    'syntax.o2.description':
      'Lo que es directamente afectada, causado, o producidos por la acción de un verbo.',
    'syntax.oc.description': 'Hace una afirmación sobre el objeto directo.',
    'syntax.om.description': 'Partícula que introduce un objeto directo.',
    'syntax.io.description':
      'Objeto que se ve afectada indirectamente por una acción, a menudo el destinatario de la acción de un verbo.',
    'syntax.p.description':
      'Identifica o describe el tema, a menudo (pero no siempre) se produce junto con un verbo de enlace.',
    'syntax.pp.description':
      'La modificación de la frase que consta de una preposición y su objeto.',
    'syntax.prep.description':
      'Indica la relación del objeto de la preposición con otras palabras, a menudo indicando donde o cuando.',
    'syntax.ptcl.description':
      'Uno de los miembros del grupo diverso de las palabras de función que no pertenece a ninguna de las clases de palabras gramaticales inflexiones',
    'syntax.rel.description': 'Partícula que introduce una cláusula relativa.',
    'syntax.s.description':
      'La parte de la cláusula que el resto de la cláusula hace una afirmación acerca.',
    'syntax.v.description':
      'Afirma algo sobre el tema de la cláusula y puede expresar la acción, evento o condición.',
    'syntax.vc.description': 'Enlaces objeto de una cláusula de complemento tema.',
    'general.close': 'Cerca',
    'general.action': 'Acción',
    'general.select': 'Seleccione...',
    'general.back': 'Espalda',
    'revisionHistory.noData': 'No hay una historia de revisiones de este verso.',
    'revisionHistory.translation': 'Traducción',
    'revisionHistory.lastChanged': 'Último cambio',
    'sourceText.view': 'Haga clic para ver el texto fuente',
    'sourceText.viewStructure': 'Haga clic para ver la estructura del texto fuente',
    'error.projectIdIsRequired': 'Identificación del proyecto se requiere.',
    'error.searchTermIsRequired': 'El término de búsqueda no puede vaciar.',
    'error.bookIdIsRequired':
      'Identificación libro es necesario para almacenar el nombre del libro.',
    'error.bookNameCannotBeEmpty': 'Nombre del libro no puede estar vacío.',
    'error.signInIsRequiredToAccessProject': 'Debes iniciar sesión para acceder a su proyecto.',
    error: 'Error',
    'error.doNotHavePermissionAccessProject':
      'Usted no tiene permiso para acceder a este proyecto.',
    'error.unauthorized': 'El acceso no autorizado o el acceso no válido token.',
    'error.tokenExpired': 'El token ha caducado. Por favor re-inicio de sesión.',
    'error.invalidApiUrl': 'URL de la API no válida.',
    'error.badRequestInvalidJson': 'Solicitud incorrecta. Inválida JSON.',
    'error.windowIsNotDefined': 'Ventana no está definida.',
    'error.unexpectedError':
      'Ha ocurrido un error inesperado. Por favor, cierre esta ventana y vuelve a intentarlo.',
    'error.invalidDataType': 'tipo de datos no válido.',
    'export.data': 'Exportar datos',
    more: 'Más',
    'export.to.rtf': 'Exportar a RTF',
    'export.to.usfm': 'Exportar a USFM',
    'export.titlePreparingData': 'Estamos preparando sus datos de traducción de la Biblia.',
    'export.doNotClose':
      'Este proceso puede llevar un tiempo, por favor, no cierre esta ventana y se le notificará cuando está listo para descargar.',
    'export.desc':
      'Puede exportar y descargar los datos de traducción haciendo clic en los botones de abajo.',
    'export.titleOfReady2Download': 'Sus datos de traducción de la Biblia está listo.',
    'export.textOfReady2Download':
      'Por favor, haga clic en el botón de descarga para descargar su traducción de la Biblia.',
    'export.statistics': 'Estadísticas',
    'export.book': 'Nombre del libro',
    'export.translatedVerses': 'versos traducidos',
    'export.exportedVerses': 'versos exportados',
    'export.howDoUWant2Export': '¿Cómo se desea exportar los datos?',
    'export.whichBooks': '¿Qué libro (s) que desea exportar?',
    'export.byBooks': 'por los libros',
    'export.byChapters': 'por capítulos',
    'export.byVerses': 'por versos',
    'export.booksInOt': 'Viejo Testamento',
    'export.booksInNt': 'Nuevo Testamento',
    'export.booksInBible': 'toda la Biblia',
    'export.booksInNone': 'Ninguna',
    'export.selectRangeOfChapters': 'Gama de capítulos',
    'export.selectRangeOfVerses': 'Gama de versos',
    'export.selectChapter': 'Seleccionar un capítulo',
    'export.exportTypeIsRequired':
      'Usted todavía no ha seleccionado acerca de cómo quiere usted a los datos de exportación?',
    'export.haveNotYetSelectedBooks': 'Usted todavía no ha seleccionado ningún libro.',
    'error.selectABook': 'Debe seleccionar un libro.',
    'error.invalidBookId': 'Identificación libro válido.',
    'error.selectAChapter': 'Debe seleccionar un capítulo.',
    'error.invalidChapterRange': 'Debe seleccionar un rango capítulo válido.',
    'error.invalidVerseRange': 'Debe seleccionar un rango verso válida.',
    'error.invalidExportType': 'tipo de exportación válido.',
    startover: 'Comenzar de nuevo',
    download: 'Descargar',
    'download.all': 'Descargar todos los archivos',
    'contact.header': 'Contáctenos',
    'contact.returnAddress': 'Dirección del remitente',
    'contact.returnAddressPlaceholder': '¿Dónde podemos llegar a usted?',
    'contact.subject': 'Tema',
    'contact.subjectPlaceholder': 'Con qué necesitas ayuda?',
    'contact.message': 'Mensaje',
    'contact.messagePlaceholder': '¿Cómo podemos ayudar?',
    'contact.sendMessage': 'Enviar mensaje',
    'contact.success': 'Mensaje fue enviado con éxito.',
    'contact.failure':
      'Hubo un problema al enviar este mensaje. Por favor, póngase en contacto con help@globalbibleinitiative.org.',
    selectInterlinear: 'Haga clic para cambiar el texto interlineal',
    'warning.screenTooSmall':
      'Por favor, cambiar a una resolución de pantalla más alta (mínimo de 1024px de ancho) o póngase en ampliar la ventana de su navegador.',
    'label.dictionary': 'concordancia Buscar',
    'dict.search.placeholder': 'Introduzca la palabra (s)',
    'tooltip.manuscript': 'Manuscrito',
    'tooltip.strongsx': 'Número de Strong',
    'tooltip.translation': 'Traducción',
    'tooltip.english': 'Inglés',
    'title.howToSearch': 'Cómo utilizar la búsqueda de concordancia',
    'desc.howToSearch':
      'Introduzca la palabra (s) del griego o hebreo, la palabra (s) de su traducción, o el número (s) fuerte. O haga clic en las palabras griegas o hebreas en el panel de "su traducción" o puntos de vista de alineación para las opciones de búsqueda.',
    'dict.noDataToDisplay': 'No existen datos que coinciden con su criterio de búsqueda.',
    'title.searchTermTooMany': 'Ha introducido demasiadas palabras clave',
    'desc.searchTermTooMany': 'Puede buscar hasta 8 palabras clave cada vez.',
    'title.searchDictError': 'Ha ocurrido un error inesperado.',
    'desc.searchDictError':
      'Por favor, intente de nuevo más tarde o soporte de contacto del cliente',
    'alignment.saveAndEditAlignment': 'Guardar y alinear texto nuevo',
    'alignment.reviewAlignment': 'Revisión Alineación de texto',
    'dict.searchLemma': 'Haga clic para buscar palabra original',
    'dict.searchTranslation': 'Haga clic para buscar la palabra traducción',
    'dict.searchStrongsX': 'Haga clic para buscar el número de Strong',
    'dict.showMenu': 'Haga clic para mostrar el texto completo de los sucesos en su contexto.',
    'dict.hideMenu': 'Haga clic para ocultar texto completo de las apariciones en su contexto.',
  },
  fr: {
    'languages.en': 'Anglais',
    'languages.am': 'amharique',
    'languages.bn': 'Bengali',
    'languages.ceb': 'Cebuano Language',
    'languages.de': 'Langue allemande',
    'languages.es-419': "L'Espagnol",
    'languages.fr': 'Langue française',
    'languages.gu': 'gujarati',
    'languages.hi': 'hindi',
    'languages.hr': 'Langue croate',
    'languages.hu': 'Langue hongroise',
    'languages.id': 'Langue indonésienne',
    'languages.km': 'khmer',
    'languages.kn': 'kannada',
    'languages.ko': 'La langue coréenne',
    'languages.lo': 'Langue laotien',
    'languages.ml': 'malayalam',
    'languages.mr': 'Marathi',
    'languages.ms': 'Malaisie',
    'languages.my': 'Langue birmane',
    'languages.ne': 'népalais',
    'languages.plt': 'Langue malgache',
    'languages.pt-br': 'Portugais (Brésil) Langue',
    'languages.ru': 'Langue russe',
    'languages.sw': 'swahili',
    'languages.ta': 'Langue Tamil',
    'languages.th': 'Langue thai',
    'languages.tl': 'Tagalog',
    'languages.te': 'télougou Langue',
    'languages.vi': 'Langue vietnamienne',
    'languages.zh-hans': 'Langue Chinois (simplifié)',
    'languages.zh-hant': 'Langue Chinois (traditionnel)',
    'header.home': 'Accueil',
    'header.help': 'Aidez-moi',
    'header.project': 'Projet',
    'header.contact': 'Contact',
    'help.contactSupport':
      "S'il vous plaît écrivez-nous à help@youtranslate.bible pour l'assistance.",
    'home.ytbProvides': 'YouTranslate.Bible fournit librement:',
    'home.easyToUseTool': 'Un outil de traduction en ligne facile à utiliser',
    'home.originalTextHelp': 'Aide à comprendre le texte de la Bible dans ses langues originales',
    'home.referencesAndResources': 'ressources de référence et de formation',
    'home.computerAssistance':
      "L'état de la technologie informatique de pointe pour aider les traducteurs",
    'profile.userProfile': "Profil de l'utilisateur",
    'profile.language': 'Langue',
    switchDirection: 'Commutateur Direction du texte',
    'profile.update': 'Mettre à jour',
    'profile.languageWarning':
      "Google Translate a été utilisé pour traduire l'interface utilisateur dans les langues autres que l'anglais temporairement. YTB ​​n'a aucun contrôle sur l'exactitude ou la fiabilité de la traduction. Tout le monde se fondant sur les étiquettes et les explications traduites obtenues à partir de Google Translate fait à son propre risque. Si quelqu'un est intéressé à faire du bénévolat pour fournir une traduction plus précise et fiable de l'interface utilisateur dans les langues autres que l'anglais, s'il vous plaît cliquer sur l'option de contact pour envoyer un email à GBI.",
    'usernav.profile': 'Profil',
    'usernav.logout': 'Se déconnecter',
    'usernav.logout.modalTitle': 'Déconnexion de YouTranslate.Bible',
    'usernav.logout.modalText':
      'Êtes-vous sûr de vouloir vous déconnecter? Tous les travaux non enregistré sera perdu.',
    cancel: 'Annuler',
    delete: 'Supprimer',
    create: 'Créer',
    update: 'Mettre à jour',
    edit: 'Éditer',
    save: 'sauver',
    add: 'Ajouter',
    translate: 'Traduire',
    'more.options': "Plus d'options",
    'project.create.title': 'Bible Projet de traduction',
    'project.create.list': 'liste des projets',
    'project.list.deleteProject': 'Supprimer le projet?',
    'project.list.areYouSure': 'Etes-vous sûr de vouloir supprimer ce projet?',
    'project.list.workWillBeDeleted': 'Le projet et les travaux associés seront supprimés.',
    'project.list.completion': 'Achèvement:',
    'project.list.createdDate': 'Date de création:',
    'project.list.beginningDate': 'Date de début:',
    'project.list.targetCompletionDate': "Date d'achèvement cible:",
    'project.list.description': 'La description:',
    'project.edit': 'Modifier le projet',
    'project.list.translateBible': 'Traduire la Bible',
    'project.list.empty': 'Votre liste de projets de traduction est vide.',
    'project.list.myProjects': 'Ma Bible Projets de traduction',
    'project.list.newProject': 'Nouveau projet',
    'project.create.createNew': 'Créer un nouveau projet',
    'project.namePlaceholder': 'Entrez le nom du projet',
    'project.descriptionPlaceholder': 'Entrez description du projet',
    'project.name': 'Nom du projet',
    'project.beginningDate': 'Date de début',
    'project.targetCompletionDate': "Date d'achèvement cible",
    'project.description': 'La description',
    'project.isRTL': 'La traduction est de droite à gauche',
    'project.manage-users': 'gérer les traducteurs',
    'project.new-user': 'nouveau traducteur',
    'project.add-user': 'Ajouter Traducteur',
    'project.users': 'traducteurs projet',
    'project.read-only': 'Lecture seulement',
    'project.read&write': 'Lire écrire',
    'project.owner': 'Propriétaire',
    'project.translator': 'Traducteur',
    'project.message.no-such-user': 'Pas de traducteur existe',
    'project.message.user-added': 'Traducteur ajouté avec succès',
    'project.message.user-deleted': 'Traducteur supprimé.',
    'project.message.only-owner-can-manage-users':
      'Seul le propriétaire du projet peut gérer les utilisateurs',
    'project.shared': 'projet de collaboration',
    'general.delete-user': 'Traducteur Supprimer',
    'general.edit-user': "Modifier l'utilisateur",
    'general.name': 'Nom',
    'general.email': 'Email',
    'general.permissions': 'autorisations',
    'general.role': 'Rôle',
    signin: 'se connecter',
    'signin.emailPlaceholder': 'Adresse e-mail',
    'signin.passwordPlaceholder': 'Mot de passe',
    'signin.forgotPassword': 'Mot de passe oublié?',
    'signin.facebook': 'Connectez-vous avec Facebook',
    'signin.google': 'Connectez-vous avec Google',
    'signin.dontHaveAccount': 'Ne pas avoir un compte?',
    'signin.createOne': 'Créer une',
    'signup.join': 'Inscrivez-vous YouTranslate.Bible',
    'signup.createAccountPrompt': 'Créez votre compte gratuit pour commencer.',
    'signup.createAccount': 'Créer un compte',
    'signup.emailPlaceholder': 'Adresse e-mail',
    'signup.passwordPlaceholder': 'Mot de passe',
    'passwordReset.title': 'Réinitialisation du mot de passe',
    'passwordReset.prompt':
      'Demander un e-mail avec des instructions pour réinitialiser votre mot de passe.',
    'passwordReset.emailPlaceholder': 'Adresse électronique',
    'passwordReset.requestButtonLabel': 'demande Email',
    'passwordReset.success': "L'email a été envoyé.",
    'passwordReset.error': 'Une erreur est survenue.',
    'translation.project': 'PROJET:',
    'translation.backToProjectList': 'Retour à la liste des projets',
    'translation.book': 'Livre:',
    'translation.chapter': 'Chapitre:',
    'translation.prompt':
      'Etes-vous sûr de vouloir quitter ce chapitre? Tous les travaux non enregistré sera perdu.',
    'translation.prev': 'Précédent',
    'translation.next': 'Prochain',
    'translation.previousChapter': 'chapitre précédent',
    'translation.nextChapter': 'Chapitre suivant',
    'navigator.book': 'Livre:',
    'navigator.chapter': 'Chapitre:',
    'navigator.oldTestament': "L'Ancien Testament",
    'navigator.newTestament': 'Nouveau Testament',
    'navigator.selectBook': 'Sélectionnez un livre',
    'navigator.search': 'Chercher',
    'navigator.changeBook': 'Changer Réservez',
    'navigator.changeChapter': 'Modifier le chapitre',
    Genesis: 'Genèse',
    Exodus: 'Exode',
    Leviticus: 'Lévitique',
    Numbers: 'Nombres',
    Deuteronomy: 'Deutéronome',
    Joshua: 'Joshua',
    Judges: 'Les juges',
    Ruth: 'Ruth',
    '1 Samuel': '1 Samuel',
    '2 Samuel': '2 Samuel',
    '1 Kings': '1 Kings',
    '2 Kings': '2 Rois',
    '1 Chronicles': '1 Chronicles',
    '2 Chronicles': '2 Chroniques',
    Ezra: 'Ezra',
    Nehemiah: 'Néhémie',
    Esther: 'Esther',
    Job: 'Emploi',
    Psalms: 'Psaumes',
    Proverbs: 'les proverbes',
    Ecclesiastes: "livre de l'Ecclésiaste",
    'Song of Songs': 'Cantique des cantique',
    Isaiah: 'Isaïe',
    Jeremiah: 'Jeremiah',
    Lamentations: 'Lamentations',
    Ezekiel: 'Ezekiel',
    Daniel: 'Daniel',
    Hosea: 'Osée',
    Joel: 'Joel',
    Amos: 'Amos',
    Obadiah: 'Abdias',
    Jonah: 'Jonas',
    Micah: 'Micah',
    Nahum: 'Nahum',
    Habakkuk: 'Habacuc',
    Zephaniah: 'Sophonie',
    Haggai: 'Haggai',
    Zechariah: 'Zacharie',
    Malachi: 'Malachie',
    Matthew: 'Matthieu',
    Mark: 'marque',
    Luke: 'Luke',
    John: 'John',
    Acts: 'Actes',
    Romans: 'Romains',
    '1 Corinthians': '1 Co',
    '2 Corinthians': '2 Corinthiens',
    Galatians: 'Galates',
    Ephesians: 'Ephésiens',
    Philippians: 'Philippiens',
    Colossians: 'Colossiens',
    '1 Thessalonians': '1 Thessalonians',
    '2 Thessalonians': '2 Thessaloniciens',
    '1 Timothy': '1 Timothy',
    '2 Timothy': '2 Timothée',
    Titus: 'Titus',
    Philemon: 'Philemon',
    Hebrews: 'Hébreux',
    James: 'James',
    '1 Peter': '1 Pierre',
    '2 Peter': '2 Pierre',
    '1 John': '1 John',
    '2 John': '2 John',
    '3 John': '3 John',
    Jude: 'Jude',
    Revelation: 'Révélation',
    'resources.prompt':
      'Ajouter des ressources bibliques pour des informations supplémentaires sur le passage que vous traduisez.',
    'resources.add': 'Ajouter une ressource biblique',
    Manuscript: 'Manuscrit',
    Morphology: 'Morphologie',
    Lexicon: 'Lexique',
    'Syntactic Tree': 'Arbre Syntactic',
    'Go to project list': 'Aller à la liste des projets',
    'Project not found': 'Projet introuvable ou autorisations insuffisantes.',
    'error.referenceDoesNotExistForResource':
      'Cette traduction de la Bible ne contient pas de contenu pour le passage sélectionné.',
    'references.addAnother': 'Ajouter une autre traduction',
    'references.referencePrompt':
      "Ajouter d'autres traductions de la Bible pour un aperçu supplémentaire.",
    'references.add': 'Ajouter une traduction de la Bible',
    'references.licensing': 'Informations sur les licences',
    'references.licensingEmpty':
      "Aucune information sur la licence n'a été trouvée pour cet article.",
    'translation.complete': 'complète de traduction',
    Alignment: 'Alignement',
    Share: 'Partager',
    'Your Translation': 'votre traduction',
    'Enter your translation': 'Entrez votre traduction',
    OK: "D'accord",
    'error.verseTranslationEmpty': 'Vous ne pouvez pas laisser le contenu de la traduction vide.',
    'translation.hide.completed': 'Masquer terminé',
    alignment: 'Alignement',
    'alignment.verify': 'Vérifier et fermer.',
    'alignment.close': 'Fermer sans vérification',
    'alignment.backToEditor': "Retour à l'éditeur",
    'alignment.error':
      "Quelque chose a mal tourné. Si le problème persiste à, s'il vous plaît contacter l'équipe YouTranslate.Bible.",
    'alignment.add': 'Ajouter un lien',
    'alignment.delete': 'Supprimer le lien',
    'alignment.clear': 'Effacer la sélection',
    'alignment.reverse': 'Affichage inverse',
    'alignment.removeOrClear': "Retirez le lien sélectionné ou d'une sélection claire.",
    'alignment.addOrRemove': 'Créer un nouveau lien ou supprimer un précédent.',
    'alignment.selectAndAdd': 'Sélectionnez segments correspondant à la liaison.',
    'error.mustBeSignedToMakeChanges': 'Vous devez être connecté pour effectuer des modifications.',
    'error.mustBeSignedToTranslateBible': 'Vous devez être connecté pour traduire la Bible.',
    checking: 'Vérification',
    'verseEditor.unexpectedError': "Une erreur imprévue s'est produite.",
    verses: 'par Verse',
    words: 'par Word',
    'leftPanel.name': 'Texte original de la Bible',
    'rightPanel.name': 'Les traductions de la Bible',
    'bottomPanel.name': 'Ressources pour la Bible',
    revisionHistory: 'Historique des révisions',
    'syntax.adv': 'adverbiale Modificateur',
    'syntax.adjp': 'adjectivale Phrase',
    'syntax.advp': 'adverbe Phrase',
    'syntax.cl': 'Clause',
    'syntax.conj': 'Conjonction',
    'syntax.cjp': 'Conjonction',
    'syntax.det': 'déterminant',
    'syntax.ijp': 'Interjection',
    'syntax.nump': 'Phrase numérique',
    'syntax.np': 'Phrase nominale',
    'syntax.o': 'Objet direct',
    'syntax.o2': 'deuxième objet',
    'syntax.oc': "Complément d'objet",
    'syntax.om': 'Marker Object',
    'syntax.io': 'Objet indirect',
    'syntax.p': 'Non verbale prédicats',
    'syntax.pp': 'Préposition',
    'syntax.prep': 'Préposition',
    'syntax.ptcl': 'Particule',
    'syntax.rel': 'relative Particle',
    'syntax.s': 'Matière',
    'syntax.v': 'Verbe',
    'syntax.vc': 'Linking Verbe',
    'syntax.adjp.description': 'Phrase qui modifie un nom ou un pronom',
    'syntax.adv.description':
      'Modifie le verbe, typiquement en outre la définition manière, le temps, le lieu, la fréquence, le degré, etc ...',
    'syntax.advp.description':
      'Phrase modifier le verbe, typiquement en outre la définition manière, le temps, le lieu, la fréquence, le degré, etc.',
    'syntax.cl.description': 'Un groupe de mots qui fait une affirmation sur le sujet.',
    'syntax.conj.description': 'Relie mots, des phrases et des clauses.',
    'syntax.cjp.description': 'Relie mots, des phrases et des clauses.',
    'syntax.det.description': 'Indique, identifie ou quantifie une phrase de nom.',
    'syntax.ijp.description': "Exclamation ou expression soudaine d'émotion.",
    'syntax.nump.description': 'Phrase qui décrit la quantité numérique',
    'syntax.np.description': 'Une phrase qui a un nom comme tête ou qui fonctionne comme un nom.',
    'syntax.o.description':
      "Ce qui est directement touché, causé ou produit par l'action d'un verbe.",
    'syntax.o2.description':
      "Ce qui est directement touché, causé ou produit par l'action d'un verbe.",
    'syntax.oc.description': "Fait une assertion sur l'objet direct.",
    'syntax.om.description': 'Particules qui introduit un objet direct.',
    'syntax.io.description':
      "Objet qui est indirectement affectée par une action, souvent le destinataire de l'action d'un verbe.",
    'syntax.p.description':
      'Cernent ou décrit le sujet, souvent (mais pas toujours) se produit en même temps avec un verbe liant.',
    'syntax.pp.description': "Modification de phrase composée d'une préposition et son objet.",
    'syntax.prep.description':
      "Indique la relation de l'objet de la préposition avec d'autres mots, ce qui indique souvent où et quand.",
    'syntax.ptcl.description':
      "Un membre du groupe diversifié de mots de fonction qui ne font pas partie à l'une des classes de mots grammaticaux fléchies",
    'syntax.rel.description': 'Particules qui introduit une clause relative.',
    'syntax.s.description':
      'La partie de la clause que le reste de la clause fait une affirmation au sujet.',
    'syntax.v.description':
      'États quelque chose sur le sujet de la clause et peut exprimer une action, un événement ou condition.',
    'syntax.vc.description': "Liens l'objet d'une clause à un complément de sujet.",
    'general.close': 'Fermer',
    'general.action': 'action',
    'general.select': 'Sélectionner...',
    'general.back': 'Arrière',
    'revisionHistory.noData': "Il n'y a pas d'antécédents de révision de ce verset.",
    'revisionHistory.translation': 'Traduction',
    'revisionHistory.lastChanged': 'dernière modification',
    'sourceText.view': 'Cliquer pour voir texte source',
    'sourceText.viewStructure': 'Cliquez pour voir la structure du texte source',
    'error.projectIdIsRequired': 'ID projet est nécessaire.',
    'error.searchTermIsRequired': 'Le terme de recherche ne peut pas vider.',
    'error.bookIdIsRequired': 'ID livre est requis pour stocker le nom du livre.',
    'error.bookNameCannotBeEmpty': 'Nom du livre ne peut pas être vide.',
    'error.signInIsRequiredToAccessProject':
      'Vous devez être connecté pour accéder à votre projet.',
    error: 'Erreur',
    'error.doNotHavePermissionAccessProject': "Vous n'êtes pas autorisé à accéder à ce projet.",
    'error.unauthorized': "jeton d'accès non autorisé ou l'accès non valide.",
    'error.tokenExpired': "Votre jeton a expiré. S'il vous plaît reconnectez-vous.",
    'error.invalidApiUrl': 'url API non valide.',
    'error.badRequestInvalidJson': 'Mauvaise Demande. Non valide JSON.',
    'error.windowIsNotDefined': "Fenêtre n'est pas défini.",
    'error.unexpectedError':
      "Une erreur imprévue s'est produite. S'il vous plaît fermer cette fenêtre et essayez à nouveau.",
    'error.invalidDataType': 'Type de données non valide.',
    'export.data': 'Exporter des données',
    more: 'Plus',
    'export.to.rtf': 'Exporter au format RTF',
    'export.to.usfm': 'Exporter vers USFM',
    'export.titlePreparingData': 'Nous préparons vos données de traduction de la Bible.',
    'export.doNotClose':
      "Ce processus peut prendre un certain temps, s'il vous plaît ne pas fermer cette fenêtre et vous serez averti quand il est prêt à télécharger.",
    'export.desc':
      'Vous pouvez exporter et télécharger vos données de traduction en cliquant sur les boutons ci-dessous.',
    'export.titleOfReady2Download': 'Vos données de traduction de la Bible est prêt.',
    'export.textOfReady2Download':
      "S'il vous plaît cliquer sur le bouton ci-dessous pour télécharger télécharger la traduction de la Bible.",
    'export.statistics': 'Statistiques',
    'export.book': 'nom du livre',
    'export.translatedVerses': 'vers Translated',
    'export.exportedVerses': 'vers exporté',
    'export.howDoUWant2Export': "Comment voulez-vous d'exporter des données?",
    'export.whichBooks': 'Quel livre (s) que vous voulez exporter?',
    'export.byBooks': 'par livres',
    'export.byChapters': 'par chapitres',
    'export.byVerses': 'par versets',
    'export.booksInOt': "L'Ancien Testament",
    'export.booksInNt': 'Nouveau Testament',
    'export.booksInBible': 'toute la Bible',
    'export.booksInNone': 'Aucun',
    'export.selectRangeOfChapters': 'Gamme de chapitres',
    'export.selectRangeOfVerses': 'De versets',
    'export.selectChapter': 'Sélectionnez un chapitre',
    'export.exportTypeIsRequired':
      "Vous ne l'avez pas encore sélectionné sur la façon dont ce que vous voulez exporter des données?",
    'export.haveNotYetSelectedBooks': "Vous n'êtes pas encore sélectionné aucun livre.",
    'error.selectABook': 'Vous devez choisir un livre.',
    'error.invalidBookId': 'ID non valide du livre.',
    'error.selectAChapter': 'Vous devez sélectionner un chapitre.',
    'error.invalidChapterRange': 'Vous devez sélectionner une plage de chapitre valide.',
    'error.invalidVerseRange': 'Vous devez sélectionner une plage de vers valide.',
    'error.invalidExportType': "Type non valide à l'exportation.",
    startover: 'Recommencer',
    download: 'Télécharger',
    'download.all': 'Télécharger tous les fichiers',
    'contact.header': 'Nous contacter',
    'contact.returnAddress': 'Adresse de retour',
    'contact.returnAddressPlaceholder': 'Où peut-on vous joindre?',
    'contact.subject': 'Matière',
    'contact.subjectPlaceholder': "Avec quoi as tu besoin d'aide?",
    'contact.message': 'Message',
    'contact.messagePlaceholder': 'Comment pouvons nous aider?',
    'contact.sendMessage': 'Envoyer le message',
    'contact.success': 'Le message a été envoyé avec succès.',
    'contact.failure':
      "Il y avait un problème pour envoyer ce message. S'il vous plaît contacter help@globalbibleinitiative.org.",
    selectInterlinear: 'Cliquez pour modifier le texte interlinéaire',
    'warning.screenTooSmall':
      "S'il vous plaît passer à une résolution d'écran supérieure (large minimum 1024px) ou s'il vous plaît élargir la fenêtre de votre navigateur.",
    'label.dictionary': 'Recherche Concordance',
    'dict.search.placeholder': 'Entrez mot (s)',
    'tooltip.manuscript': 'Manuscrit',
    'tooltip.strongsx': 'Nombre de Strong',
    'tooltip.translation': 'Traduction',
    'tooltip.english': 'Anglais',
    'title.howToSearch': 'Comment utiliser la recherche de concordance',
    'desc.howToSearch':
      "Entrez mot (s) du grec ou l'hébreu, mot (s) de votre traduction, ou numéro Strong (s). Ou cliquez sur les mots grecs ou hébreux dans le panneau de « Votre traduction » ou sur l'alignement pour les options de recherche.",
    'dict.noDataToDisplay': "Il n'y a pas de données correspondant à vos critères de recherche.",
    'title.searchTermTooMany': 'Vous avez entré trop de mots clés',
    'desc.searchTermTooMany': "Vous pouvez rechercher jusqu'à 8 mots-clés à chaque fois.",
    'title.searchDictError': "Une erreur imprévue s'est produite.",
    'desc.searchDictError':
      "S'il vous plaît réessayer plus tard ou le soutien à la clientèle de contact",
    'alignment.saveAndEditAlignment': 'Enregistrer et Aligner Nouveau texte',
    'alignment.reviewAlignment': "Examen d'alignement du texte",
    'dict.searchLemma': "Cliquez pour rechercher mot d'origine",
    'dict.searchTranslation': 'Cliquez pour rechercher mot de traduction',
    'dict.searchStrongsX': 'Cliquez pour rechercher le numéro de Strong',
    'dict.showMenu': 'Cliquez pour afficher le texte complet des événements dans leur contexte.',
    'dict.hideMenu': 'Cliquer pour cacher le texte complet des événements dans leur contexte.',
  },
  gu: {
    'languages.en': 'ઇંગલિશ',
    'languages.am': 'એમ્હારિક',
    'languages.bn': 'બંગાળી ભાષા',
    'languages.ceb': 'સિબુઆનો ભાષા',
    'languages.de': 'જર્મન ભાષા',
    'languages.es-419': 'સ્પેનીશ ભાષા',
    'languages.fr': 'ફ્રેન્ચ ભાષા',
    'languages.gu': 'ગુજરાતી',
    'languages.hi': 'હિન્દી',
    'languages.hr': 'ક્રોએશિયન ભાષા',
    'languages.hu': 'હંગેરીયન ભાષા',
    'languages.id': 'ઇન્ડોનેશિયન ભાષા',
    'languages.km': 'ખ્મેર',
    'languages.kn': 'કન્નડા',
    'languages.ko': 'કોરિયન ભાષા',
    'languages.lo': 'લાઓશિયન ભાષા',
    'languages.ml': 'મલયાલમ',
    'languages.mr': 'મરાઠી',
    'languages.ms': 'મલેશિયન',
    'languages.my': 'બર્મીઝ ભાષા',
    'languages.ne': 'નેપાળી ભાષા',
    'languages.plt': 'મલાગસી ભાષા',
    'languages.pt-br': 'પોર્ટુગીઝ (બ્રાઝીલ) ભાષા',
    'languages.ru': 'રશિયન ભાષા',
    'languages.sw': 'સ્વાહિલી',
    'languages.ta': 'તમિલ ભાષા',
    'languages.th': 'થાઇ ભાષા',
    'languages.tl': 'ટાગાલોગ',
    'languages.te': 'Telegu ભાષા',
    'languages.vi': 'વિયેટનામી ભાષા',
    'languages.zh-hans': 'ચિની ભાષા (સરળ)',
    'languages.zh-hant': 'ચિની ભાષા (પરંપરાગત)',
    'header.home': 'ઘર',
    'header.help': 'સહાય',
    'header.project': 'પ્રોજેક્ટ',
    'header.contact': 'સંપર્ક',
    'help.contactSupport': 'કૃપા કરીને અમને સહાય માટે help@youtranslate.bible ખાતે ઇમેઇલ કરો.',
    'home.ytbProvides': 'YouTranslate.Bible મુક્તપણે પૂરા પાડે છે:',
    'home.easyToUseTool': 'એક સરળ-થી-ઉપયોગ ઓનલાઇન અનુવાદ સાધન',
    'home.originalTextHelp': 'તેના મૂળ ભાષાઓમાં બાઇબલ લખાણ સમજવા માટે મદદ',
    'home.referencesAndResources': 'સંદર્ભ અને તાલીમ સાધનો',
    'home.computerAssistance': 'સ્ટેટ-ઓફ-ધી-આર્ટ કોમ્પ્યુટર ટેકનોલોજી અનુવાદકો સહાય કરવા',
    'profile.userProfile': 'વપરાશકર્તા પ્રોફાઇલ',
    'profile.language': 'ભાષાની',
    switchDirection: 'સ્વિચ કરો ટેક્સ્ટની દિશા',
    'profile.update': 'અપડેટ',
    'profile.languageWarning':
      'Google Translate ઇંગલિશ કામચલાઉ કરતાં અન્ય ભાષાઓમાં યુઝર ઇન્ટરફેસ અનુવાદ કરવા ઉપયોગ કરાયો હતો. YTB ​​ચોકસાઈ અથવા અનુવાદ વિશ્વસનીયતા પર કોઈ નિયંત્રણ ધરાવે છે. કોઈપણ અનુવાદ લેબલ્સ અને સ્પષ્ટતા Google માંથી મેળવી પર આધાર ભાષાંતર તેના અથવા તેણીના પોતાના જોખમે આવું કરે. કોઈને ઇંગલિશ કરતાં અન્ય ભાષાઓમાં યુઝર ઇન્ટરફેસ વધુ સચોટ અને વિશ્વસનીય અનુવાદ પૂરી પાડવા માટે સ્વયંસેવી રસ હોય, તો સંપર્ક વિકલ્પ પર ક્લિક કરો GBI માટે એક ઇમેઇલ મોકલો.',
    'usernav.profile': 'પ્રોફાઇલ',
    'usernav.logout': 'લૉગ આઉટ',
    'usernav.logout.modalTitle': 'YouTranslate.Bible ના લૉગઆઉટ',
    'usernav.logout.modalText':
      'જો તમને ખાતરી છે તમે લૉગઆઉટ કરવા માંગો છો? તમામ વણસાચવેલ કાર્ય ગુમ થશે.',
    cancel: 'રદ કરો',
    delete: 'કાઢી નાંખો',
    create: 'બનાવો',
    update: 'અપડેટ',
    edit: 'સંપાદિત કરો',
    save: 'સેવ',
    add: 'ઉમેરો',
    translate: 'ભાષાંતર',
    'more.options': 'વધુ વિકલ્પ',
    'project.create.title': 'બાઇબલ અનુવાદ પ્રોજેક્ટ',
    'project.create.list': 'પ્રોજેક્ટ યાદી',
    'project.list.deleteProject': 'પ્રોજેક્ટ કાઢી નાખીએ?',
    'project.list.areYouSure': 'જો તમે આ પ્રોજેક્ટ કાઢી નાખવા માંગો છો?',
    'project.list.workWillBeDeleted': 'પ્રોજેક્ટ અને સંકળાયેલ કોઈપણ કામ કાઢી નાખવામાં આવશે.',
    'project.list.completion': 'સમાપ્તિ:',
    'project.list.createdDate': 'તારીખ તારીખ:',
    'project.list.beginningDate': 'શરુ તારીખ:',
    'project.list.targetCompletionDate': 'લક્ષ્યાંક સમાપ્તિ તારીખ:',
    'project.list.description': 'વર્ણન:',
    'project.edit': 'પ્રોજેક્ટ સંપાદિત કરો',
    'project.list.translateBible': 'ભાષાંતર બાઇબલ',
    'project.list.empty': 'તમારી અનુવાદ પ્રોજેક્ટ સૂચિ ખાલી છે.',
    'project.list.myProjects': 'મારા બાઇબલ અનુવાદ પ્રોજેક્ટ્સ',
    'project.list.newProject': 'ન્યૂ પ્રોજેક્ટ',
    'project.create.createNew': 'એક નવો પ્રોજેક્ટ બનાવો',
    'project.namePlaceholder': 'પ્રોજેક્ટ નામ દાખલ કરો',
    'project.descriptionPlaceholder': 'પ્રોજેક્ટ વર્ણન દાખલ કરો',
    'project.name': 'પ્રોજેક્ટનું નામ',
    'project.beginningDate': 'શરુ તારીખ',
    'project.targetCompletionDate': 'લક્ષ્યાંક સમાપ્તિ તારીખ',
    'project.description': 'વર્ણન',
    'project.isRTL': 'અનુવાદ જમણે-થી-ડાબે છે',
    'project.manage-users': 'અનુવાદકો વ્યવસ્થા',
    'project.new-user': 'ન્યૂ અનુવાદક',
    'project.add-user': 'અનુવાદક ઉમેરો',
    'project.users': 'પ્રોજેક્ટ અનુવાદકો',
    'project.read-only': 'ફક્ત વાંચી',
    'project.read&write': 'વાંચો લખો',
    'project.owner': 'માલિકનું',
    'project.translator': 'અનુવાદક',
    'project.message.no-such-user': 'આવી કોઈ અનુવાદક અસ્તિત્વમાં',
    'project.message.user-added': 'અનુવાદક સફળતાપૂર્વક ઉમેરાયું',
    'project.message.user-deleted': 'અનુવાદક દૂર કર્યું.',
    'project.message.only-owner-can-manage-users':
      'માત્ર પ્રોજેક્ટ માલિક વપરાશકર્તાને સંચાલિત કરી શકે',
    'project.shared': 'સહયોગી પ્રોજેક્ટ',
    'general.delete-user': 'કાઢી નાંખો અનુવાદક',
    'general.edit-user': 'વપરાશકર્તા સંપાદિત કરો',
    'general.name': 'નામ',
    'general.email': 'ઇ-મેઇલ',
    'general.permissions': 'પરવાનગીઓ',
    'general.role': 'રોલ',
    signin: 'સાઇન ઇન કરો',
    'signin.emailPlaceholder': 'ઈ - મેઈલ સરનામું',
    'signin.passwordPlaceholder': 'પાસવર્ડ',
    'signin.forgotPassword': 'તમારો પાસવર્ડ ભૂલી ગયા છો?',
    'signin.facebook': 'ફેસબુક સાથે સાઇન ઇન કરો',
    'signin.google': 'Google સાથે સાઇન ઇન',
    'signin.dontHaveAccount': 'એક એકાઉન્ટ નથી?',
    'signin.createOne': 'એક બનાવો',
    'signup.join': 'YouTranslate.Bible જોડાઓ',
    'signup.createAccountPrompt': 'તમારા મફત એકાઉન્ટ શરૂ કરવા માટે બનાવો.',
    'signup.createAccount': 'ખાતું બનાવો',
    'signup.emailPlaceholder': 'ઈ - મેઈલ સરનામું',
    'signup.passwordPlaceholder': 'પાસવર્ડ',
    'passwordReset.title': 'પાસવૉર્ડ રીસેટ',
    'passwordReset.prompt': 'તમારો પાસવર્ડ રીસેટ કરવા સૂચનાઓ સાથે એક ઇમેઇલ વિનંતી કરો.',
    'passwordReset.emailPlaceholder': 'ઈ - મેઈલ સરનામું',
    'passwordReset.requestButtonLabel': 'વિનંતી ઇમેઇલ',
    'passwordReset.success': 'ઈ - મેઈલ મોકલી દીધો છે.',
    'passwordReset.error': 'એક ભૂલ આવી છે.',
    'translation.project': 'પ્રોજેક્ટ:',
    'translation.backToProjectList': 'પ્રોજેક્ટ સૂચિ પર પાછા',
    'translation.book': 'પુસ્તક:',
    'translation.chapter': 'પ્રકરણ:',
    'translation.prompt': 'તમે આ પ્રકરણમાં છોડવા માંગો છો? તમામ વણસાચવેલ કાર્ય ગુમ થશે.',
    'translation.prev': 'પૂર્વ',
    'translation.next': 'આગળ',
    'translation.previousChapter': 'ગત પ્રકરણ',
    'translation.nextChapter': 'આવતા પ્રકરણમાં',
    'navigator.book': 'પુસ્તક:',
    'navigator.chapter': 'પ્રકરણ:',
    'navigator.oldTestament': 'ઓલ્ડ ટેસ્ટામેન્ટ',
    'navigator.newTestament': 'ધ ન્યૂ ટેસ્ટામેન્ટ',
    'navigator.selectBook': 'એક પુસ્તક પસંદ',
    'navigator.search': 'શોધો',
    'navigator.changeBook': 'પુસ્તક બદલો',
    'navigator.changeChapter': 'પ્રકરણ બદલો',
    Genesis: 'ઉત્પત્તિ',
    Exodus: 'હિજરત',
    Leviticus: 'લેવિટિકસ',
    Numbers: 'નંબર્સ',
    Deuteronomy: 'પુનર્નિયમ',
    Joshua: 'જોશુઆ',
    Judges: 'ન્યાયાધીશો',
    Ruth: 'રુથ',
    '1 Samuel': '1 સેમ્યુઅલ',
    '2 Samuel': '2 સેમ્યુઅલ',
    '1 Kings': '1 કિંગ્સ',
    '2 Kings': '2 કિંગ્સ',
    '1 Chronicles': '1 ક્રોનિકલ્સ',
    '2 Chronicles': '2 ક્રોનિકલ્સ',
    Ezra: 'એઝરા',
    Nehemiah: 'નહેમ્યા',
    Esther: 'એસ્થર',
    Job: 'જોબ',
    Psalms: 'સેલ્મસ',
    Proverbs: 'ઉકિતઓ',
    Ecclesiastes: 'સભાશિક્ષક',
    'Song of Songs': 'ગીતો ગીત',
    Isaiah: 'ઇસાઇઆહ',
    Jeremiah: 'Jeremiah',
    Lamentations: 'આક્રંદથી',
    Ezekiel: 'એઝેકીલ',
    Daniel: 'ડેનિયલ',
    Hosea: 'હોસિયા',
    Joel: 'જોએલ',
    Amos: 'આમોસ',
    Obadiah: 'ઓબાદ્યા',
    Jonah: 'જોનાહ',
    Micah: 'મીખાહ',
    Nahum: 'નાહૂમ',
    Habakkuk: 'હબાક્કૂક',
    Zephaniah: 'સફાન્યાહ',
    Haggai: 'હાગ્ગાય',
    Zechariah: 'ઝખાર્યાહ',
    Malachi: 'માલાચી',
    Matthew: 'મેથ્યુ',
    Mark: 'ચિહ્ન',
    Luke: 'લ્યુક',
    John: 'જ્હોન',
    Acts: 'કાયદાઓ',
    Romans: 'રોમનો',
    '1 Corinthians': '1 કોરીન્થિયન્સ',
    '2 Corinthians': '2 કોરીન્થિયન્સ',
    Galatians: 'ગલાતી',
    Ephesians: 'એફેસિઅન્સ',
    Philippians: 'ફિલિપી',
    Colossians: 'કોલોસી',
    '1 Thessalonians': '1 થેસ્સાલોનીકી',
    '2 Thessalonians': '2 થેસ્સાલોનીકી',
    '1 Timothy': '1 તિમોથી',
    '2 Timothy': '2 તિમોથી',
    Titus: 'ટાઇટસ',
    Philemon: 'ફિલેમોન',
    Hebrews: 'હર્બુઝ',
    James: 'જેમ્સ',
    '1 Peter': '1 પીટર',
    '2 Peter': '2 પીટર',
    '1 John': '1 યોહાન',
    '2 John': '2 યોહાન',
    '3 John': '3 યોહાન',
    Jude: 'જ્યુડ',
    Revelation: 'રેવિલેશન',
    'resources.prompt': 'માર્ગ તમે જે અનુવાદ વધારાના સૂઝ માટે બિબ્લીકલ સાધનો ઉમેરો.',
    'resources.add': 'એક બિબ્લીકલ સાધન ઉમેરો',
    Manuscript: 'હસ્તપ્રત',
    Morphology: 'મોર્ફોલોજી',
    Lexicon: 'લેક્સિકોન',
    'Syntactic Tree': 'વાક્યરચના વૃક્ષ',
    'Go to project list': 'પ્રોજેક્ટ સૂચિ પર જાઓ',
    'Project not found': 'પ્રોજેક્ટ મળ્યો નથી અથવા અપર્યાપ્ત પરવાનગીઓ.',
    'error.referenceDoesNotExistForResource':
      'આ બાઇબલ અનુવાદ પસંદગી પસાર કરવા માટેનો સામગ્રી શામેલ નથી.',
    'references.addAnother': 'અન્ય અનુવાદ ઉમેરો',
    'references.referencePrompt': 'વધારાના સૂઝ માટે બાઇબલ અન્ય અનુવાદ ઉમેરો.',
    'references.add': 'બાઇબલ અનુવાદ ઉમેરો',
    'references.licensing': 'લાઇસેન્સિંગ માહિતી',
    'references.licensingEmpty': 'કોઈ લાઈસન્સીંગ માહિતી આ આઇટમ માટે મળી હતી.',
    'translation.complete': 'અનુવાદ પૂર્ણ',
    Alignment: 'ગોઠવણી',
    Share: 'શેર',
    'Your Translation': 'તમારી ભાષાંતર',
    'Enter your translation': 'તમારી અનુવાદ દાખલ',
    OK: 'બરાબર',
    'error.verseTranslationEmpty': 'તમે અનુવાદ સામગ્રી ખાલી છોડી શકતા નથી.',
    'translation.hide.completed': 'પૂર્ણ છુપાવો',
    alignment: 'ગોઠવણી',
    'alignment.verify': 'ચકાસો અને બંધ કરો.',
    'alignment.close': 'ચકાસ્યાં વિના બંધ',
    'alignment.backToEditor': 'સંપાદક પર પાછા',
    'alignment.error': 'કંઈક ખોટું થયું. જો સમસ્યા ચાલુ રહે છે, YouTranslate.Bible ટીમ સંપર્ક કરો.',
    'alignment.add': 'લિંક ઉમેરો',
    'alignment.delete': 'લિંક દૂર',
    'alignment.clear': 'પસંદગી સાફ કરો',
    'alignment.reverse': 'રિવર્સ પ્રદર્શન',
    'alignment.removeOrClear': 'પસંદ કરેલ લિંક અથવા સ્પષ્ટ પસંદગી દૂર કરો.',
    'alignment.addOrRemove': 'એક નવી લિંક બનાવો અથવા પહેલાંના એક કાઢી નાખો.',
    'alignment.selectAndAdd': 'લિંક સેગમેન્ટમાં અનુરૂપ પસંદ કરો.',
    'error.mustBeSignedToMakeChanges': 'તમે ફેરફારો કરવા માટે સાઇન ઇન કરવું આવશ્યક છે.',
    'error.mustBeSignedToTranslateBible': 'તમે બાઇબલ ભાષાંતર કરવા માટે સાઇન ઇન કરવું આવશ્યક છે.',
    checking: 'તપાસવું',
    'verseEditor.unexpectedError': 'એક અનપેક્ષિત ભૂલ આવી છે.',
    verses: 'દ્વારા શ્લોક',
    words: 'દ્વારા વર્ડ',
    'leftPanel.name': 'બાઇબલના મૂળ લખાણ',
    'rightPanel.name': 'બાઇબલના ભાષાંતરો',
    'bottomPanel.name': 'બાઇબલ માટે સંસાધનો',
    revisionHistory: 'પુનરાવર્તન ઇતિહાસ',
    'syntax.adv': 'ક્રિયાવિશેષક સંશોધક',
    'syntax.adjp': 'વિશેષણયુક્ત શબ્દસમૂહ',
    'syntax.advp': 'ક્રિયાવિશેષણ શબ્દસમૂહ',
    'syntax.cl': 'કલમ',
    'syntax.conj': 'જોડાણમાં',
    'syntax.cjp': 'જોડાણમાં',
    'syntax.det': 'નિયામક',
    'syntax.ijp': 'ઉદ્ગારવાચક અવ્યય',
    'syntax.nump': 'સંખ્યાત્મક શબ્દસમૂહ',
    'syntax.np': 'સંજ્ઞા શબ્દસમૂહના',
    'syntax.o': 'સીધા પદાર્થ',
    'syntax.o2': 'બીજું ઓબ્જેક્ટ',
    'syntax.oc': 'ઑબ્જેક્ટ પૂરક',
    'syntax.om': 'ઑબ્જેક્ટ માર્કર',
    'syntax.io': 'અપ્રત્યક્ષ',
    'syntax.p': 'બિન-મૌખિક વિશેષણો',
    'syntax.pp': 'નામયોગી મહાવરો',
    'syntax.prep': 'નામયોગી અવ્યય',
    'syntax.ptcl': 'કણ',
    'syntax.rel': 'સંબંધિત કણ',
    'syntax.s': 'વિષય',
    'syntax.v': 'ક્રિયાપદ',
    'syntax.vc': 'લિંક ક્રિયાપદ',
    'syntax.adjp.description': 'શબ્દસમૂહનો સંજ્ઞા અથવા સર્વનામ સુધારે',
    'syntax.adv.description':
      'સુધારે ક્રિયાપદ, ખાસ કરીને વધુ વ્યાખ્યાયિત રીતે સમય, સ્થળ, આવૃત્તિ, ડિગ્રી, વગેરે ...',
    'syntax.advp.description':
      'શબ્દસમૂહ ક્રિયાપદ ફેરફાર, ખાસ કરીને વધુ વ્યાખ્યાયિત રીતે સમય, સ્થળ, આવૃત્તિ, ડિગ્રી, વગેરે',
    'syntax.cl.description': 'શબ્દો એક જૂથ છે કે જે વિષય અંગે દાવા બનાવે છે.',
    'syntax.conj.description': 'શબ્દો, શબ્દસમૂહો અને કલમો સાથે જોડે છે.',
    'syntax.cjp.description': 'શબ્દો, શબ્દસમૂહો અને કલમો સાથે જોડે છે.',
    'syntax.det.description': 'ઉલ્લેખ ઓળખ કરવી, અથવા quantifies સંજ્ઞા શબ્દસમૂહના.',
    'syntax.ijp.description': 'ઉદ્ગારવાચક અથવા લાગણી અચાનક અભિવ્યક્તિ.',
    'syntax.nump.description': 'શબ્દસમૂહનો સંખ્યાત્મક જથ્થો વર્ણવે',
    'syntax.np.description': 'શબ્દસમૂહ તેના વડા તરીકે સંજ્ઞા અથવા સંજ્ઞા કે કાર્યો ધરાવે છે.',
    'syntax.o.description': 'જે સીધી અસર કારણે, અથવા એક ક્રિયાપદ ક્રિયા દ્વારા પેદા થાય છે.',
    'syntax.o2.description': 'જે સીધી અસર કારણે, અથવા એક ક્રિયાપદ ક્રિયા દ્વારા પેદા થાય છે.',
    'syntax.oc.description': 'ડાયરેક્ટ ઓબ્જેક્ટ વિશે એક દાવા બનાવે છે.',
    'syntax.om.description': 'કણ કે સીધો પદાર્થ રજૂ કરે છે.',
    'syntax.io.description':
      'પદાર્થ છે કે આડકતરી એક ક્રિયા દ્વારા અસરગ્રસ્ત છે, ઘણી વખત એક ક્રિયાપદ ક્રિયા પ્રાપ્તકર્તા.',
    'syntax.p.description':
      'ઓળખાવે અથવા વિષય, વર્ણવે વારંવાર (પરંતુ હંમેશા નહીં) સાથે મળીને એક લિંક ક્રિયાપદ સાથે જોવા મળે છે.',
    'syntax.pp.description': 'બદલી રહ્યા નામયોગી અવ્યય અને તેના પદાર્થ બનેલી શબ્દસમૂહ.',
    'syntax.prep.description':
      'અન્ય શબ્દો સાથે નામયોગી અવ્યય પદાર્થ સંબંધ સૂચવે છે, જે દર્શાવે છે વારંવાર જ્યાં અથવા જ્યારે.',
    'syntax.ptcl.description':
      'કાર્ય શબ્દો વિવિધ જૂથ માંથી એક સભ્ય કે રૂપાંતરિત વ્યાકરણના શબ્દ વર્ગો કોઇ સંબંધ નથી',
    'syntax.rel.description': 'કણ કે સંબંધિત કલમ રજૂ કરે છે.',
    'syntax.s.description': 'કલમ કે કલમ બાકીના વિશે એક દાવા બનાવે ભાગ છે.',
    'syntax.v.description':
      'વાક્યના વિષયમાં વિશે કંઈક રાજ્યો અને ક્રિયા, ઘટના અથવા શરત વ્યક્ત કરી શકે છે.',
    'syntax.vc.description': 'એક વિષય પૂરક વાક્યના વિષયમાં લિંક્સ.',
    'general.close': 'બંધ',
    'general.action': 'ક્રિયા',
    'general.select': 'પસંદ કરો...',
    'general.back': 'પાછા',
    'revisionHistory.noData': 'આ શ્લોક માટે કોઈ પુનરાવર્તન ઇતિહાસ છે.',
    'revisionHistory.translation': 'અનુવાદ',
    'revisionHistory.lastChanged': 'છેલ્લે બદલાયું',
    'sourceText.view': 'સ્રોત ટેક્સ્ટ જોવા માટે ક્લિક કરો',
    'sourceText.viewStructure': 'સ્રોત ટેક્સ્ટ માળખું જોવા માટે ક્લિક કરો',
    'error.projectIdIsRequired': 'પ્રોજેક્ટ ID જરૂરી છે.',
    'error.searchTermIsRequired': 'શોધ શબ્દ ખાલી કરી શકતા નથી.',
    'error.bookIdIsRequired': 'બુક આઈડી પુસ્તક નામ સંગ્રહ કરવા માટે જરૂરી છે.',
    'error.bookNameCannotBeEmpty': 'પુસ્તકનું નામ ખાલી ન હોઈ શકે.',
    'error.signInIsRequiredToAccessProject':
      'તમે તમારા પ્રોજેક્ટ ઍક્સેસ કરવા માટે સાઇન ઇન હોવું જ જોઈએ.',
    error: 'ભૂલ',
    'error.doNotHavePermissionAccessProject': 'જો તમે આ પ્રોજેક્ટ ઍક્સેસ કરવાની પરવાનગી નથી.',
    'error.unauthorized': 'અનધિકૃત ઍક્સેસ અથવા અમાન્ય ઍક્સેસ ટોકન.',
    'error.tokenExpired': 'તમારું ટોકન સમાપ્ત થયું છે. મહેરબાની કરીને કડીને પુન લોગીન.',
    'error.invalidApiUrl': 'અમાન્ય API URL.',
    'error.badRequestInvalidJson': 'ખરાબ વિનંતી. અમાન્ય JSON.',
    'error.windowIsNotDefined': 'વિન્ડો વ્યાખ્યાયિત નથી.',
    'error.unexpectedError': 'એક અનપેક્ષિત ભૂલ આવી છે. આ વિંડો બંધ કરો અને ફરીથી પ્રયાસ કરો.',
    'error.invalidDataType': 'અમાન્ય ડેટા પ્રકાર.',
    'export.data': 'ડેટા નિકાસ',
    more: 'વધુ',
    'export.to.rtf': 'RTF નિકાસ',
    'export.to.usfm': 'નિકાસ USFM માટે',
    'export.titlePreparingData': 'અમે તમારા બાઇબલ અનુવાદ ડેટા તૈયાર કરવામાં આવે છે.',
    'export.doNotClose':
      'આ પ્રક્રિયા થોડો સમય લાગી શકે છે, બંધ નથી આ વિંડોમાં કરો અને જ્યારે તે ડાઉનલોડ કરવા માટે તૈયાર છે કે તમે જાણ કરવામાં આવશે.',
    'export.desc': 'તમે નિકાસ અને નીચે બટનો પર ક્લિક કરીને તમારા અનુવાદ ડેટા ડાઉનલોડ કરી શકો છો.',
    'export.titleOfReady2Download': 'તમારું બાઇબલ અનુવાદ ડેટા તૈયાર છે.',
    'export.textOfReady2Download': 'નીચે તમારી બાઇબલ અનુવાદ ડાઉનલોડ કરવા ડાઉનલોડ બટન ક્લિક કરો.',
    'export.statistics': 'આંકડા',
    'export.book': 'પુસ્તકનું નામ',
    'export.translatedVerses': 'અનુવાદ છંદો',
    'export.exportedVerses': 'નિકાસ છંદો',
    'export.howDoUWant2Export': 'તમે કેવી રીતે ડેટાનો નિકાસ કરવા માંગો છો?',
    'export.whichBooks': 'કયા પુસ્તક (ઓ) તમે નિકાસ કરવા માંગો છો?',
    'export.byBooks': 'દ્વારા પુસ્તકો',
    'export.byChapters': 'દ્વારા પ્રકરણો',
    'export.byVerses': 'દ્વારા છંદો',
    'export.booksInOt': 'ઓલ્ડ ટેસ્ટામેન્ટ',
    'export.booksInNt': 'ધ ન્યૂ ટેસ્ટામેન્ટ',
    'export.booksInBible': 'આખા બાઇબલમાં',
    'export.booksInNone': 'કંઈ',
    'export.selectRangeOfChapters': 'પ્રકરણોના રેંજ',
    'export.selectRangeOfVerses': 'છંદો રેંજ',
    'export.selectChapter': 'એક પ્રકરણ પસંદ',
    'export.exportTypeIsRequired':
      'તમે હજી સુધી લગભગ પસંદ કર્યા નથી કેવી રીતે કરવું તમે નિકાસ ડેટા કરવા માંગો છો?',
    'export.haveNotYetSelectedBooks': 'તમે હજી સુધી કોઈપણ પુસ્તક પસંદ કર્યા નથી.',
    'error.selectABook': 'તમે એક પુસ્તક પસંદ કરવો પડશે.',
    'error.invalidBookId': 'અમાન્ય પુસ્તક આઈડી.',
    'error.selectAChapter': 'તમે એક પ્રકરણ પસંદ કરવો પડશે.',
    'error.invalidChapterRange': 'તમારે માન્ય પ્રકરણ શ્રેણી પસંદ કરવો પડશે.',
    'error.invalidVerseRange': 'તમારે માન્ય શ્લોક શ્રેણી પસંદ કરવો પડશે.',
    'error.invalidExportType': 'અમાન્ય નિકાસ પ્રકાર.',
    startover: 'પ્રારંભ',
    download: 'ડાઉનલોડ',
    'download.all': 'ડાઉનલોડ બધી ફાઈલો',
    'contact.header': 'અમારો સંપર્ક કરો',
    'contact.returnAddress': 'રીટર્ન સરનામું',
    'contact.returnAddressPlaceholder': 'અમે તમને ક્યાં પહોંચી શકે?',
    'contact.subject': 'વિષય',
    'contact.subjectPlaceholder': 'શું તમે મદદ જરૂર છે?',
    'contact.message': 'સંદેશ',
    'contact.messagePlaceholder': 'અમે કેવી રીતે મદદ કરી શકે?',
    'contact.sendMessage': 'સંદેશ મોકલો',
    'contact.success': 'સંદેશ સફળતાપૂર્વક મોકલવામાં આવ્યો હતો.',
    'contact.failure':
      'એક સમસ્યા એ આ સંદેશ મોકલવાનું આવી હતી. help@globalbibleinitiative.org સંપર્ક કરો.',
    selectInterlinear: 'Interlinear લખાણ બદલવા માટે ક્લિક કરો',
    'warning.screenTooSmall':
      'ઊંચી સ્ક્રીન રિઝોલ્યુશન (લઘુત્તમ 1024px વિશાળ) પર સ્વિચ અથવા તમારા બ્રાઉઝર વિન્ડો વિસ્તૃત કૃપા કરીને કરો.',
    'label.dictionary': 'સુમેળ શોધ',
    'dict.search.placeholder': 'શબ્દ (ઓ) દાખલ',
    'tooltip.manuscript': 'હસ્તપ્રત',
    'tooltip.strongsx': 'મજબૂત સંખ્યા',
    'tooltip.translation': 'અનુવાદ',
    'tooltip.english': 'ઇંગલિશ',
    'title.howToSearch': 'સુમેળ શોધ ઉપયોગ કેવી રીતે કરવો',
    'desc.howToSearch':
      'ગ્રીક માં હિબ્રુ, શબ્દ (ઓ) તમારી ભાષાંતર, અથવા મજબૂત સંખ્યા (ઓ) શબ્દ (ઓ) દાખલ કરો. અથવા "તમારી અનુવાદ" અથવા શોધ વિકલ્પો માટે સંરેખણ જોવાઈ પેનલમાં ગ્રીક માં હીબ્રુ શબ્દો પર ક્લિક કરો.',
    'dict.noDataToDisplay': 'કોઈ ડેટા છે જે તમારા શોધ માપદંડથી મેળ ખાતી હોય છે.',
    'title.searchTermTooMany': 'તમે ઘણી બધી કીવર્ડ દાખલ કરેલ',
    'desc.searchTermTooMany': 'તમે 8 કીવર્ડ દરેક વખતે સુધી શોધી શકો છો.',
    'title.searchDictError': 'એક અનપેક્ષિત ભૂલ આવી છે.',
    'desc.searchDictError':
      'કૃપા કરીને પછીથી ફરી પ્રયાસ કરો અથવા ગ્રાહક સમર્થનનો સંપર્ક કરો કૃપા કરીને',
    'alignment.saveAndEditAlignment': 'સેવ અને સંરેખિત નવો ટેક્સ્ટ',
    'alignment.reviewAlignment': 'સમીક્ષા ટેક્સ્ટ સંરેખણ',
    'dict.searchLemma': 'મૂળ શબ્દ શોધવા માટે ક્લિક કરો',
    'dict.searchTranslation': 'અનુવાદ શબ્દ શોધવા માટે ક્લિક કરો',
    'dict.searchStrongsX': 'સ્ટ્રોંગ નંબર શોધવા માટે ક્લિક કરો',
    'dict.showMenu': 'સંદર્ભમાં ઘટનાઓની સંપૂર્ણ લખાણ બતાવવા માટે ક્લિક કરો.',
    'dict.hideMenu': 'સંદર્ભમાં ઘટનાઓની સંપૂર્ણ લખાણ છુપાવવા માટે ક્લિક કરો.',
  },
  hi: {
    'languages.en': 'अंग्रेज़ी',
    'languages.am': 'अम्हारिक्',
    'languages.bn': 'बंगाली भाषा',
    'languages.ceb': 'सिबुआनो भाषा',
    'languages.de': 'जर्मन भाषा',
    'languages.es-419': 'स्पनिश भाषा',
    'languages.fr': 'फ्रांसीसी भाषा',
    'languages.gu': 'गुजराती',
    'languages.hi': 'हिन्दी',
    'languages.hr': 'क्रोएशियाई भाषा',
    'languages.hu': 'हंगेरियन भाषा',
    'languages.id': 'इंडोनेशियाई भाषा',
    'languages.km': 'खमेर',
    'languages.kn': 'कन्नड़',
    'languages.ko': 'कोरियाई भाषा',
    'languages.lo': 'Laotian भाषा',
    'languages.ml': 'मलयालम',
    'languages.mr': 'मराठी',
    'languages.ms': 'मलेशियाई',
    'languages.my': 'बर्मी भाषा',
    'languages.ne': 'नेपाली भाषा',
    'languages.plt': 'मालागासी भाषा',
    'languages.pt-br': 'पुर्तगाली (ब्राजील) भाषा',
    'languages.ru': 'रूसी भाषा',
    'languages.sw': 'स्वाहिली',
    'languages.ta': 'तमिल भाषा',
    'languages.th': 'थाई भाषा',
    'languages.tl': 'तागालोग',
    'languages.te': 'तेलुगु भाषा',
    'languages.vi': 'वियतनामी भाषा',
    'languages.zh-hans': 'चीनी भाषा (सरलीकृत)',
    'languages.zh-hant': 'चीनी भाषा (पारंपरिक)',
    'header.home': 'घर',
    'header.help': 'मदद',
    'header.project': 'परियोजना',
    'header.contact': 'संपर्क करें',
    'help.contactSupport': 'कृपया हमें सहायता के लिए help@youtranslate.bible पर ईमेल करें।',
    'home.ytbProvides': 'YouTranslate.Bible स्वतंत्र रूप से प्रदान करता है:',
    'home.easyToUseTool': 'एक आसान से उपयोग ऑनलाइन विनिमय टूल',
    'home.originalTextHelp': 'अपने मूल भाषाओं में बाइबिल के पाठ को समझने के लिए सहायता',
    'home.referencesAndResources': 'संदर्भ और प्रशिक्षण संसाधनों',
    'home.computerAssistance':
      'राज्य के अत्याधुनिक कंप्यूटर प्रौद्योगिकी अनुवादकों की सहायता के लिए',
    'profile.userProfile': 'उपयोगकर्ता प्रोफ़ाइल',
    'profile.language': 'भाषा: हिन्दी',
    switchDirection: 'स्विच पाठ दिशा',
    'profile.update': 'अपडेट करें',
    'profile.languageWarning':
      'Google अनुवाद अंग्रेजी अस्थायी रूप से के अलावा अन्य भाषाओं में यूजर इंटरफेस का अनुवाद करने में इस्तेमाल किया गया था। YTB ​​सटीकता या अनुवाद की विश्वसनीयता पर कोई नियंत्रण नहीं है। किसी को भी अनुवाद लेबल और स्पष्टीकरण गूगल से प्राप्त पर निर्भर अनुवाद अपने या अपने स्वयं के जोखिम पर ऐसा नहीं करता है। किसी को भी अंग्रेजी के अलावा अन्य भाषाओं में यूजर इंटरफेस का अधिक सटीक और विश्वसनीय अनुवाद प्रदान करने के लिए स्वयं सेवा में रुचि है, तो संपर्क विकल्प पर क्लिक करें जीबीआई के लिए एक ईमेल भेजने के लिए।',
    'usernav.profile': 'प्रोफ़ाइल',
    'usernav.logout': 'लॉग आउट',
    'usernav.logout.modalTitle': 'YouTranslate.Bible की लॉगआउट',
    'usernav.logout.modalText': 'क्या आप लॉग आउट करना चाहते हैं? सभी बिना सहेजे कार्य खो जाएगा।',
    cancel: 'रद्द करना',
    delete: 'हटाएं',
    create: 'सृजन करना',
    update: 'अपडेट करें',
    edit: 'संपादित करें',
    save: 'सहेजें',
    add: 'जोड़ना',
    translate: 'अनुवाद करना',
    'more.options': 'अधिक विकल्प',
    'project.create.title': 'बाइबिल अनुवाद परियोजना',
    'project.create.list': 'परियोजना सूची',
    'project.list.deleteProject': 'परियोजना हटाना चाहते हैं?',
    'project.list.areYouSure': 'आप इस प्रोजेक्ट को हटाना चाहते आपको यकीन है?',
    'project.list.workWillBeDeleted': 'परियोजना और संबद्ध किसी भी काम से हटा दिया जाएगा।',
    'project.list.completion': 'समापन:',
    'project.list.createdDate': 'रचना तिथि:',
    'project.list.beginningDate': 'प्रारंभिक दिनांक:',
    'project.list.targetCompletionDate': 'लक्ष्य हासिल करने की तारीख:',
    'project.list.description': 'विवरण:',
    'project.edit': 'प्रोजेक्ट संपादित करें',
    'project.list.translateBible': 'अनुवाद बाइबिल',
    'project.list.empty': 'अपना अनुवाद प्रोजेक्ट सूची खाली है।',
    'project.list.myProjects': 'मेरे बाइबिल अनुवाद परियोजनाएं',
    'project.list.newProject': 'नया काम',
    'project.create.createNew': 'कोई नया प्रोजेक्ट बनाएं',
    'project.namePlaceholder': 'इस परियोजना का नाम दर्ज करें',
    'project.descriptionPlaceholder': 'परियोजना विवरण दर्ज करें',
    'project.name': 'परियोजना का नाम',
    'project.beginningDate': 'प्रारंभिक दिनांक',
    'project.targetCompletionDate': 'लक्ष्य हासिल करने की तारीख',
    'project.description': 'विवरण',
    'project.isRTL': 'अनुवाद सही-से-बाएँ है',
    'project.manage-users': 'अनुवादक की व्यवस्था करें',
    'project.new-user': 'न्यू अनुवादक',
    'project.add-user': 'अनुवादक जोड़ें',
    'project.users': 'परियोजना अनुवादकों',
    'project.read-only': 'सिफ़ पढ़िये',
    'project.read&write': 'पढ़ना लिखना',
    'project.owner': 'मालिक',
    'project.translator': 'अनुवादक',
    'project.message.no-such-user': 'ऐसा कोई अनुवादक मौजूद है',
    'project.message.user-added': 'अनुवादक को सफलतापूर्वक जोड़',
    'project.message.user-deleted': 'अनुवादक हटा दिया।',
    'project.message.only-owner-can-manage-users':
      'केवल परियोजना स्वामी उपयोगकर्ताओं को प्रबंधित कर सकते हैं',
    'project.shared': 'सहयोगी परियोजना',
    'general.delete-user': 'हटाएं अनुवादक',
    'general.edit-user': 'यूजर को संपादित करो',
    'general.name': 'नाम',
    'general.email': 'ईमेल',
    'general.permissions': 'अनुमतियां',
    'general.role': 'भूमिका',
    signin: 'साइन इन करें',
    'signin.emailPlaceholder': 'ईमेल पता',
    'signin.passwordPlaceholder': 'कुंजिका',
    'signin.forgotPassword': 'क्या आप पासवर्ड भूल गए?',
    'signin.facebook': 'फ़ेसबुक से साइन इन करें',
    'signin.google': 'गूगल के साथ साइन इन',
    'signin.dontHaveAccount': 'एक खाता नहीं है?',
    'signin.createOne': 'एक बनाए',
    'signup.join': 'YouTranslate.Bible शामिल हों',
    'signup.createAccountPrompt': 'अपने नि: शुल्क खाता शुरू करने के लिए बनाएँ।',
    'signup.createAccount': 'खाता बनाएं',
    'signup.emailPlaceholder': 'ईमेल पता',
    'signup.passwordPlaceholder': 'कुंजिका',
    'passwordReset.title': 'पासवर्ड रीसेट',
    'passwordReset.prompt':
      'अपना पासवर्ड रीसेट करने के लिए निर्देशों के साथ एक ईमेल का अनुरोध करें।',
    'passwordReset.emailPlaceholder': 'ईमेल पता',
    'passwordReset.requestButtonLabel': 'अनुरोध ईमेल',
    'passwordReset.success': 'ईमेल भेजा जा चुका है।',
    'passwordReset.error': 'एक गलती हुई है।',
    'translation.project': 'परियोजना:',
    'translation.backToProjectList': 'परियोजना सूची पर वापस',
    'translation.book': 'पुस्तक:',
    'translation.chapter': 'अध्याय:',
    'translation.prompt':
      'आप इस अध्याय को छोड़ना चाहते हैं आप सुनिश्चित हैं? सभी बिना सहेजे कार्य खो जाएगा।',
    'translation.prev': 'पिछला',
    'translation.next': 'आगे',
    'translation.previousChapter': 'पिछले अध्याय',
    'translation.nextChapter': 'अगला अध्याय',
    'navigator.book': 'पुस्तक:',
    'navigator.chapter': 'अध्याय:',
    'navigator.oldTestament': 'पुराना वसीयतनामा',
    'navigator.newTestament': 'नए करार',
    'navigator.selectBook': 'एक पुस्तक का चयन करें',
    'navigator.search': 'खोज',
    'navigator.changeBook': 'पुस्तक बदलें',
    'navigator.changeChapter': 'अध्याय बदलें',
    Genesis: 'उत्पत्ति',
    Exodus: 'एक्सोदेस',
    Leviticus: 'छिछोरापन',
    Numbers: 'नंबर',
    Deuteronomy: 'व्यवस्था विवरण',
    Joshua: 'यहोशू',
    Judges: 'न्यायाधीशों',
    Ruth: 'दया',
    '1 Samuel': '1 शमूएल',
    '2 Samuel': '2 शमूएल',
    '1 Kings': '1 किंग्स',
    '2 Kings': '2 राजा',
    '1 Chronicles': '1 इतिहास',
    '2 Chronicles': '2 इतिहास',
    Ezra: 'एजरा',
    Nehemiah: 'नहेमायाह',
    Esther: 'एस्थर',
    Job: 'काम',
    Psalms: 'भजन',
    Proverbs: 'कहावत का खेल',
    Ecclesiastes: 'ऐकलेसिस्टास',
    'Song of Songs': 'गीत के गीत',
    Isaiah: 'यशायाह',
    Jeremiah: 'यिर्मयाह',
    Lamentations: 'विलाप',
    Ezekiel: 'ईजेकील',
    Daniel: 'डैनियल',
    Hosea: 'होशे',
    Joel: 'जोएल',
    Amos: 'अमोस',
    Obadiah: 'ओबद्याह',
    Jonah: 'जोनाह',
    Micah: 'मीका',
    Nahum: 'नहूम',
    Habakkuk: 'हबक्कूक',
    Zephaniah: 'सपन्याह',
    Haggai: 'हाग्गै',
    Zechariah: 'जकर्याह',
    Malachi: 'मालाची',
    Matthew: 'मैथ्यू',
    Mark: 'निशान',
    Luke: 'ल्यूक',
    John: 'जॉन',
    Acts: 'अधिनियमों',
    Romans: 'रोमनों',
    '1 Corinthians': '1 कुरिन्थियों',
    '2 Corinthians': '2 कुरिन्थियों',
    Galatians: 'गलाटियन्स',
    Ephesians: 'इफिसियों',
    Philippians: 'फिलिप्पियों',
    Colossians: 'कुलुस्सियों',
    '1 Thessalonians': '1 थिस्सलुनीकियों',
    '2 Thessalonians': '2 थिस्सलुनीकियों',
    '1 Timothy': '1 टिमोथी',
    '2 Timothy': '2 टिमोथी',
    Titus: 'टाइटस',
    Philemon: 'फिलेमोन',
    Hebrews: 'इब्रियों',
    James: 'जेम्स',
    '1 Peter': '1 पीटर',
    '2 Peter': '2 पीटर',
    '1 John': '1 जॉन',
    '2 John': '2 जॉन',
    '3 John': '3 जॉन',
    Jude: 'जूदास',
    Revelation: 'रहस्योद्घाटन',
    'resources.prompt':
      'पारित होने के आप अनुवाद कर रहे बारे में अतिरिक्त जानकारी के लिए बाइबिल संसाधनों को जोड़ें।',
    'resources.add': 'एक बाइबिल संसाधन जोड़ें',
    Manuscript: 'हस्तलिपि',
    Morphology: 'आकृति विज्ञान',
    Lexicon: 'शब्दकोश',
    'Syntactic Tree': 'वाक्यात्मक ट्री',
    'Go to project list': 'परियोजना सूची पर जाएँ',
    'Project not found': 'प्रोजेक्ट नहीं मिला या अपर्याप्त अनुमतियां।',
    'error.referenceDoesNotExistForResource':
      'यह बाइबिल अनुवाद चयनित पारित होने के लिए सामग्री नहीं है।',
    'references.addAnother': 'एक और अनुवाद जोड़ें',
    'references.referencePrompt': 'अतिरिक्त जानकारी के लिए बाइबिल अन्य अनुवाद जोड़ें।',
    'references.add': 'एक बाइबल अनुवाद जोड़ें',
    'references.licensing': 'लाइसेंस जानकारी',
    'references.licensingEmpty': 'कोई लाइसेंस से संबंधित जानकारी इस आइटम के लिए मिला था।',
    'translation.complete': 'अनुवाद पूर्ण',
    Alignment: 'संरेखण',
    Share: 'शेयर',
    'Your Translation': 'आपका अनुवाद',
    'Enter your translation': 'आपके अनुवाद दर्ज',
    OK: 'ठीक है',
    'error.verseTranslationEmpty': 'आप अनुवाद सामग्री खाली नहीं छोड़ सकते।',
    'translation.hide.completed': 'पूरा छिपाएं',
    alignment: 'संरेखण',
    'alignment.verify': 'सत्यापित करें और बंद करें।',
    'alignment.close': 'सत्यापन के बिना बंद',
    'alignment.backToEditor': 'संपादक पर वापस',
    'alignment.error':
      'कुछ गलत हो गया। यदि समस्या बनी रहती, YouTranslate.Bible टीम से संपर्क करें।',
    'alignment.add': 'लिंक जोड़ें',
    'alignment.delete': 'लिंक निकालें',
    'alignment.clear': 'चयन साफ़ करें',
    'alignment.reverse': 'रिवर्स प्रदर्शन',
    'alignment.removeOrClear': 'चयनित लिंक या स्पष्ट चयन निकालें।',
    'alignment.addOrRemove': 'नया लिंक बनाने या पिछले एक को हटा दें।',
    'alignment.selectAndAdd': 'लिंक करने के लिए सेगमेंट इसी का चयन करें।',
    'error.mustBeSignedToMakeChanges': 'किए जाने वाले परिवर्तन के लिए प्रवेश होना आवश्यक है।',
    'error.mustBeSignedToTranslateBible': 'आप बाइबल का अनुवाद करने में प्रवेश करना होगा।',
    checking: 'जाँच हो रही है',
    'verseEditor.unexpectedError': 'एक अनपेक्षित त्रुटि हो गई है।',
    verses: 'द्वारा सुराह',
    words: 'तक पद',
    'leftPanel.name': 'बाइबल की मूल पाठ',
    'rightPanel.name': 'बाइबल की अनुवाद',
    'bottomPanel.name': 'बाइबिल के लिए संसाधन',
    revisionHistory: 'संशोधन इतिहास',
    'syntax.adv': 'विशेषणात्मक संशोधक',
    'syntax.adjp': 'विशेषण वाक्यांश',
    'syntax.advp': 'क्रिया विशेषण वाक्यांश',
    'syntax.cl': 'धारा',
    'syntax.conj': 'संयोजन के रूप',
    'syntax.cjp': 'संयोजन के रूप',
    'syntax.det': 'निर्धारक',
    'syntax.ijp': 'विस्मयादिबोधक',
    'syntax.nump': 'न्यूमेरिकल वाक्यांश',
    'syntax.np': 'संज्ञा वाक्यांश',
    'syntax.o': 'प्रत्यक्ष वस्तु',
    'syntax.o2': 'दूसरी वस्तु',
    'syntax.oc': 'वस्तु पूरक',
    'syntax.om': 'वस्तु मार्कर',
    'syntax.io': 'अप्रत्यक्ष वस्तु',
    'syntax.p': 'नॉन-वर्बल विधेय',
    'syntax.pp': 'पूर्वसर्गीय वाक्यांश',
    'syntax.prep': 'पूर्वसर्ग',
    'syntax.ptcl': 'कण',
    'syntax.rel': 'सापेक्ष कण',
    'syntax.s': 'विषय',
    'syntax.v': 'क्रिया',
    'syntax.vc': 'जोड़ने की क्रिया',
    'syntax.adjp.description': 'मुहावरा है कि एक संज्ञा या सर्वनाम को संशोधित करता है',
    'syntax.adv.description':
      'संशोधित क्रिया, विशिष्ट रूप से परिभाषित करने ढंग, समय, स्थान, आवृत्ति, डिग्री, आदि ...',
    'syntax.advp.description':
      'वाक्यांश क्रिया को संशोधित करने, विशिष्ट रूप से परिभाषित करने ढंग, समय, स्थान, आवृत्ति, डिग्री, आदि',
    'syntax.cl.description': 'शब्द का एक समूह है कि इस विषय के बारे में एक अभिकथन बनाता है।',
    'syntax.conj.description': 'शब्दों, वाक्यांशों, और खंड जोड़ता है।',
    'syntax.cjp.description': 'शब्दों, वाक्यांशों, और खंड जोड़ता है।',
    'syntax.det.description': 'निर्दिष्ट करता है, की पहचान करता है, या quantifies एक संज्ञा पद।',
    'syntax.ijp.description': 'विस्मयादिबोधक या भावना के अचानक अभिव्यक्ति।',
    'syntax.nump.description': 'मुहावरा है कि संख्यात्मक मात्रा का वर्णन करता है',
    'syntax.np.description':
      'एक वाक्यांश उसके सिर के रूप में एक संज्ञा या एक संज्ञा के रूप कि कार्यों है।',
    'syntax.o.description':
      'जो कि सीधे प्रभावित वजह से, या एक क्रिया की कार्रवाई द्वारा निर्मित है।',
    'syntax.o2.description':
      'जो कि सीधे प्रभावित वजह से, या एक क्रिया की कार्रवाई द्वारा निर्मित है।',
    'syntax.oc.description': 'प्रत्यक्ष वस्तु के बारे में एक अभिकथन बनाता है।',
    'syntax.om.description': 'कण है कि एक प्रत्यक्ष वस्तु परिचय देता है।',
    'syntax.io.description':
      'वस्तु है कि परोक्ष रूप से एक कार्रवाई से प्रभावित है, अक्सर एक क्रिया की कार्रवाई के प्राप्तकर्ता।',
    'syntax.p.description':
      'पहचानता या विषय का वर्णन अक्सर (लेकिन हमेशा नहीं) एक साथ एक जोड़ने क्रिया के साथ होता है।',
    'syntax.pp.description': 'संशोधित करना एक पूर्वसर्ग और उसके वस्तु से मिलकर वाक्यांश।',
    'syntax.prep.description':
      'दूसरे शब्दों के साथ पूर्वसर्ग की वस्तु के संबंध को इंगित करता है, यह दर्शाता है अक्सर जहां या जब।',
    'syntax.ptcl.description':
      'समारोह शब्दों के विविध समूह से एक सदस्य है कि विभक्ति व्याकरण शब्द वर्गों में से किसी से संबंधित नहीं है',
    'syntax.rel.description': 'कण कि एक रिश्तेदार खंड परिचय देता है।',
    'syntax.s.description': 'खंड है कि खंड के बाकी के बारे में एक अभिकथन बनाता का हिस्सा है।',
    'syntax.v.description':
      'खंड के विषय के बारे में कुछ कहा गया है और कार्रवाई, घटना, या हालत व्यक्त कर सकते हैं।',
    'syntax.vc.description': 'एक विषय पूरक करने के लिए एक खंड का विषय लिंक।',
    'general.close': 'बंद करे',
    'general.action': 'कार्य',
    'general.select': 'चुनते हैं...',
    'general.back': 'वापस',
    'revisionHistory.noData': 'वहाँ इस कविता के लिए कोई संशोधन इतिहास है।',
    'revisionHistory.translation': 'अनुवाद',
    'revisionHistory.lastChanged': 'अंतिम बदला गया',
    'sourceText.view': 'स्रोत पाठ देखने के लिए क्लिक',
    'sourceText.viewStructure': 'स्रोत पाठ संरचना देखने के लिए क्लिक',
    'error.projectIdIsRequired': 'परियोजना आईडी की आवश्यकता है।',
    'error.searchTermIsRequired': 'खोज शब्द खाली नहीं कर सकता।',
    'error.bookIdIsRequired': 'बुक आईडी पुस्तक का नाम स्टोर करने के लिए आवश्यक है।',
    'error.bookNameCannotBeEmpty': 'पुस्तक का नाम रिक्त नहीं हो सकता है।',
    'error.signInIsRequiredToAccessProject': 'आप अपने प्रोजेक्ट पहुंच के लिए साइन किया जाना चाहिए।',
    error: 'त्रुटि',
    'error.doNotHavePermissionAccessProject': 'आप इस परियोजना का उपयोग करने की अनुमति नहीं है।',
    'error.unauthorized': 'अनधिकृत एक्सेस या अमान्य पहुँच टोकन।',
    'error.tokenExpired': 'आपका टोकन समाप्त हो गया है। कृपया पुन: प्रवेश।',
    'error.invalidApiUrl': 'अमान्य API यूआरएल।',
    'error.badRequestInvalidJson': 'खराब अनुरोध। अमान्य JSON।',
    'error.windowIsNotDefined': 'विंडो परिभाषित नहीं है।',
    'error.unexpectedError':
      'एक अनपेक्षित त्रुटि हो गई है। कृप्या इस विंडो को बंद करें और फिर से कोशिश करें।',
    'error.invalidDataType': 'अमान्य डेटा प्रकार।',
    'export.data': 'निर्यात जानकारी',
    more: 'अधिक',
    'export.to.rtf': 'आरटीएफ में निर्यात करें',
    'export.to.usfm': 'निर्यात USFM को',
    'export.titlePreparingData': 'हम अपने बाइबिल अनुवाद डेटा तैयारी कर रहे हैं।',
    'export.doNotClose':
      'इस प्रक्रिया में कुछ समय लग सकता है, बंद नहीं इस विंडो कृपया और जब यह डाउनलोड करने के लिए तैयार है आपको सूचित किया जाएगा।',
    'export.desc': 'आप निर्यात और नीचे बटन पर क्लिक करके आपके अनुवाद डेटा डाउनलोड कर सकते हैं।',
    'export.titleOfReady2Download': 'आपका बाइबिल अनुवाद डेटा तैयार है।',
    'export.textOfReady2Download':
      'नीचे अपना बाइबिल अनुवाद डाउनलोड करने के लिए डाउनलोड बटन पर क्लिक करें।',
    'export.statistics': 'आंकड़े',
    'export.book': 'पुस्तक का नाम',
    'export.translatedVerses': 'अनूदित छंद',
    'export.exportedVerses': 'निर्यात किए गए छंद',
    'export.howDoUWant2Export': 'आप कैसे डेटा निर्यात करना चाहते हैं?',
    'export.whichBooks': 'कौन सी किताब (रों) आप निर्यात करना चाहते हैं?',
    'export.byBooks': 'द्वारा पुस्तकों',
    'export.byChapters': 'द्वारा अध्यायों',
    'export.byVerses': 'द्वारा छंद',
    'export.booksInOt': 'पुराना वसीयतनामा',
    'export.booksInNt': 'नए करार',
    'export.booksInBible': 'पूरे बाइबल',
    'export.booksInNone': 'कोई नहीं',
    'export.selectRangeOfChapters': 'अध्याय की रेंज',
    'export.selectRangeOfVerses': 'छंद की रेंज',
    'export.selectChapter': 'एक अध्याय का चयन करें',
    'export.exportTypeIsRequired':
      'आपने अभी तक के बारे में चयन नहीं किया है कि कैसे करते हैं आप निर्यात डेटा करना चाहते हैं?',
    'export.haveNotYetSelectedBooks': 'आपने अभी तक कोई पुस्तक नहीं चुना है।',
    'error.selectABook': 'आप एक किताब का चयन करना होगा।',
    'error.invalidBookId': 'अमान्य पुस्तक आईडी।',
    'error.selectAChapter': 'आप एक अध्याय का चयन करना होगा।',
    'error.invalidChapterRange': 'आपको एक मान्य अध्याय श्रेणी का चयन करना होगा।',
    'error.invalidVerseRange': 'आपको एक मान्य कविता श्रेणी का चयन करना होगा।',
    'error.invalidExportType': 'अमान्य निर्यात प्रकार।',
    startover: 'प्रारंभ करें',
    download: 'डाउनलोड',
    'download.all': 'सभी फ़ाइलें डाउनलोड करें',
    'contact.header': 'संपर्क करें',
    'contact.returnAddress': 'वापसी का पता',
    'contact.returnAddressPlaceholder': 'हम आप कहाँ तक पहुँच सकते हैं?',
    'contact.subject': 'विषय',
    'contact.subjectPlaceholder': 'तुम्हें किसमें मदद चाहिए?',
    'contact.message': 'संदेश',
    'contact.messagePlaceholder': 'हम आपकी सहायता किस तरह से कर सकते है?',
    'contact.sendMessage': 'मेसेज भेजें',
    'contact.success': 'संदेश सफलतापूर्वक भेजा गया था।',
    'contact.failure':
      'एक समस्या यह संदेश भेजने में किया गया था। help@globalbibleinitiative.org से संपर्क करें।',
    selectInterlinear: 'इंटरलीनियर पाठ बदलने के लिए क्लिक करें',
    'warning.screenTooSmall':
      'एक उच्च स्क्रीन संकल्प (कम से कम 1024px गुणा चौड़ा) करने के लिए स्विच या अपनी ब्राउज़र विंडो को चौड़ा कृपया करें।',
    'label.dictionary': 'सामंजस्य खोजें',
    'dict.search.placeholder': 'शब्द (शब्दों) दर्ज करें',
    'tooltip.manuscript': 'हस्तलिपि',
    'tooltip.strongsx': 'मजबूत संख्या',
    'tooltip.translation': 'अनुवाद',
    'tooltip.english': 'अंग्रेज़ी',
    'title.howToSearch': 'कॉनकार्डेंस खोज का उपयोग कैसे करें',
    'desc.howToSearch':
      'यूनानी या यहूदी, शब्द (रों) आपके अनुवाद से, या मजबूत संख्या (ओं) से शब्द (शब्दों) दर्ज करें। या की "आपका अनुवाद" या खोज विकल्पों के लिए संरेखण विचारों पैनल में यूनानी या यहूदी शब्द पर क्लिक करें।',
    'dict.noDataToDisplay': 'कोई डेटा है कि आपके खोज मापदंड से मेल खाने रहे हैं।',
    'title.searchTermTooMany': 'आपने बहुत अधिक कीवर्ड में प्रवेश किया है',
    'desc.searchTermTooMany': 'आप 8 कीवर्ड एक समय तक खोज सकते हैं।',
    'title.searchDictError': 'एक अनपेक्षित त्रुटि हो गई है।',
    'desc.searchDictError': 'बाद में फिर से प्रयास करें या ग्राहक सहायता से संपर्क करें',
    'alignment.saveAndEditAlignment': 'सहेजें और संरेखित नया पाठ',
    'alignment.reviewAlignment': 'समीक्षा पाठ संरेखण',
    'dict.searchLemma': 'मूल शब्द खोज करने के लिए क्लिक करें',
    'dict.searchTranslation': 'अनुवाद शब्द खोज करने के लिए क्लिक करें',
    'dict.searchStrongsX': 'मजबूत की नंबर खोज करने के लिए क्लिक करें',
    'dict.showMenu': 'संदर्भ में घटनाओं का पूरा पाठ को दिखाने के लिए क्लिक करें।',
    'dict.hideMenu': 'संदर्भ में घटनाओं का पूरा पाठ को छिपाने के लिए क्लिक करें।',
  },
  hr: {
    'languages.en': 'Engleski',
    'languages.am': 'amharski',
    'languages.bn': 'bengalski jezik',
    'languages.ceb': 'cebuano',
    'languages.de': 'Njemački jezik',
    'languages.es-419': 'Španjolski jezik',
    'languages.fr': 'Francuski jezik',
    'languages.gu': 'gujarati',
    'languages.hi': 'hindski',
    'languages.hr': 'Hrvatski jezik',
    'languages.hu': 'mađarski jezik',
    'languages.id': 'indonezijski jezik',
    'languages.km': 'Crvenih',
    'languages.kn': 'kannada',
    'languages.ko': 'Korejski jezik',
    'languages.lo': 'Laoski Jezik',
    'languages.ml': 'malajalamski',
    'languages.mr': 'marathi',
    'languages.ms': 'Malezijski',
    'languages.my': 'burmanski jezik',
    'languages.ne': 'nepalski jezik',
    'languages.plt': 'Madagaskar Jezik',
    'languages.pt-br': 'Portugalski (Brazil) Jezik',
    'languages.ru': 'Ruski jezik',
    'languages.sw': 'svahili',
    'languages.ta': 'Tamilski jezik',
    'languages.th': 'Thai jezik',
    'languages.tl': 'Tagalog',
    'languages.te': 'telegu jezik Jezik',
    'languages.vi': 'Vijetnamski jezik',
    'languages.zh-hans': 'Kineski jezik (jednostavan)',
    'languages.zh-hant': 'Kineski jezik (tradicionalni)',
    'header.home': 'Dom',
    'header.help': 'Pomozite',
    'header.project': 'Projekt',
    'header.contact': 'Kontakt',
    'help.contactSupport': 'Molimo pošaljite e-poštu na help@youtranslate.bible za pomoć.',
    'home.ytbProvides': 'YouTranslate.Bible slobodno omogućuje:',
    'home.easyToUseTool': 'Jednostavan za korištenje online alat za prevođenje',
    'home.originalTextHelp': 'Pomoć razumjeti tekst Biblije u svojim izvornim jezicima',
    'home.referencesAndResources': 'Referentni i trening resursa',
    'home.computerAssistance':
      'State-of-the-art računalne tehnologije kako bi se pomoglo prevoditelje',
    'profile.userProfile': 'Korisnički profil',
    'profile.language': 'Jezik',
    switchDirection: 'Prekidač Smjer teksta',
    'profile.update': 'ažuriranje',
    'profile.languageWarning':
      'Google Translate je korišten prevesti korisničkog sučelja u osim engleskog privremeno jezika. YTB ​​nema kontrolu nad točnost ili pouzdanost prijevoda. Svatko se oslanja na prevedenih naljepnice i objašnjenja dobivenih od Google Translate čini to na svoju vlastitu odgovornost. Ako bilo tko je zainteresiran za volontiranje pružiti više točne i pouzdane prijevod korisničkog sučelja na drugim jezicima osim engleskog, kliknite na opciju Kontakt poslati e-mail na GBI.',
    'usernav.profile': 'Profil',
    'usernav.logout': 'Odjavite se',
    'usernav.logout.modalTitle': 'Odjava iz YouTranslate.Bible',
    'usernav.logout.modalText':
      'Jeste li sigurni da želite odjaviti? Sve što niste spremili bit će izgubljene.',
    cancel: 'Otkazati',
    delete: 'Izbrisati',
    create: 'Stvoriti',
    update: 'ažuriranje',
    edit: 'Uredi',
    save: 'Uštedjeti',
    add: 'Dodati',
    translate: 'Prevedi',
    'more.options': 'Više mogućnosti',
    'project.create.title': 'Biblija Translation Project',
    'project.create.list': 'popis projekata',
    'project.list.deleteProject': 'Izbriši projekt?',
    'project.list.areYouSure': 'Jeste li sigurni da želite izbrisati ovaj projekt?',
    'project.list.workWillBeDeleted': 'Projekt i bilo koji posao povezan bit će izbrisan.',
    'project.list.completion': 'Završetak:',
    'project.list.createdDate': 'Datum kreiranja:',
    'project.list.beginningDate': 'Početak Date:',
    'project.list.targetCompletionDate': 'Ciljna Datum završetka:',
    'project.list.description': 'Opis:',
    'project.edit': 'Uredi projekta',
    'project.list.translateBible': 'Prevedi Bibliju',
    'project.list.empty': 'Vaš popis prijevod projekt je prazna.',
    'project.list.myProjects': 'Bibliju Prevođenje Projekti',
    'project.list.newProject': 'Novi projekt',
    'project.create.createNew': 'Stvaranje novog projekta',
    'project.namePlaceholder': 'Unesite naziv projekta',
    'project.descriptionPlaceholder': 'Unesite opis projekta',
    'project.name': 'Naziv projekta',
    'project.beginningDate': 'počevši Datum',
    'project.targetCompletionDate': 'Ciljna Datum završetka',
    'project.description': 'Opis',
    'project.isRTL': 'Prijevod je s desna na lijevo',
    'project.manage-users': 'Upravljanje Prevoditelji',
    'project.new-user': 'Novi prevoditelj',
    'project.add-user': 'Dodaj prevoditelj',
    'project.users': 'prevoditelji Projekt',
    'project.read-only': 'Samo za čitanje',
    'project.read&write': 'Čitati i pisati',
    'project.owner': 'Vlasnik',
    'project.translator': 'Prevoditelj',
    'project.message.no-such-user': 'Ne kao prevoditelj postoji',
    'project.message.user-added': 'Prevoditelj uspješno dodan',
    'project.message.user-deleted': 'Prevoditelj uklonjena.',
    'project.message.only-owner-can-manage-users':
      'Samo vlasnik projekt može upravljati korisnicima',
    'project.shared': 'kolaborativni projekt',
    'general.delete-user': 'brisanje prevoditelj',
    'general.edit-user': 'Uređivanje korisnika',
    'general.name': 'Ime',
    'general.email': 'E-mail',
    'general.permissions': 'dozvole',
    'general.role': 'Uloga',
    signin: 'Prijaviti se',
    'signin.emailPlaceholder': 'Email adresa',
    'signin.passwordPlaceholder': 'Zaporka',
    'signin.forgotPassword': 'Zaboravili ste zaporku?',
    'signin.facebook': 'Prijavite se sa Facebook',
    'signin.google': 'Prijavite se s Googleom',
    'signin.dontHaveAccount': 'Nemate korisnički račun?',
    'signin.createOne': 'Stvori jedan',
    'signup.join': 'Pridružite YouTranslate.Bible',
    'signup.createAccountPrompt': 'Napravite svoj besplatni korisnički račun za početak.',
    'signup.createAccount': 'Izradi račun',
    'signup.emailPlaceholder': 'Email adresa',
    'signup.passwordPlaceholder': 'Zaporka',
    'passwordReset.title': 'Password Reset',
    'passwordReset.prompt': 'Zahtjev poslati upute za ponovno postavljanje lozinke.',
    'passwordReset.emailPlaceholder': 'Email adresa',
    'passwordReset.requestButtonLabel': 'Zahtjev e-mail',
    'passwordReset.success': 'E-mail je poslan.',
    'passwordReset.error': 'Došlo je do pogreške.',
    'translation.project': 'PROJEKT:',
    'translation.backToProjectList': 'Povratak na popis projekata',
    'translation.book': 'Knjiga:',
    'translation.chapter': 'Poglavlje:',
    'translation.prompt':
      'Jeste li sigurni da želite napustiti ovo poglavlje? Sve što niste spremili bit će izgubljene.',
    'translation.prev': 'Prethodna',
    'translation.next': 'Sljedeći',
    'translation.previousChapter': 'Prethodno poglavlje',
    'translation.nextChapter': 'Sljedeće poglavlje',
    'navigator.book': 'Knjiga:',
    'navigator.chapter': 'Poglavlje:',
    'navigator.oldTestament': 'Stari zavjet',
    'navigator.newTestament': 'Novi zavjet',
    'navigator.selectBook': 'Odaberite knjigu',
    'navigator.search': 'traži',
    'navigator.changeBook': 'promjena knjiga',
    'navigator.changeChapter': 'promjena poglavlje',
    Genesis: 'Geneza',
    Exodus: 'odlazak',
    Leviticus: 'Levitski zakonik',
    Numbers: 'brojevi',
    Deuteronomy: 'Ponovljeni zakon',
    Joshua: 'Joshua',
    Judges: 'suci',
    Ruth: 'tuga',
    '1 Samuel': '1 Samuel',
    '2 Samuel': '2 Samuel',
    '1 Kings': '1 Kr',
    '2 Kings': '2 Kings',
    '1 Chronicles': '1. Ljetopisa',
    '2 Chronicles': '2 Chronicles',
    Ezra: 'Ezra',
    Nehemiah: 'Nehemija',
    Esther: 'Estera',
    Job: 'Posao',
    Psalms: 'Psalmi',
    Proverbs: 'ljetopis',
    Ecclesiastes: 'Propovjednik',
    'Song of Songs': 'Pjesma nad pjesmama',
    Isaiah: 'Izaija',
    Jeremiah: 'Jeremija',
    Lamentations: 'Tužaljke',
    Ezekiel: 'Ezekiel',
    Daniel: 'Danijel',
    Hosea: 'Hošea',
    Joel: 'Joel',
    Amos: 'Amos',
    Obadiah: 'Obadija',
    Jonah: 'Jona',
    Micah: 'Micah',
    Nahum: 'Nahum',
    Habakkuk: 'Habakuk',
    Zephaniah: 'Sefanija',
    Haggai: 'Hagaj',
    Zechariah: 'Zaharija',
    Malachi: 'Malahija',
    Matthew: 'Matthew',
    Mark: 'Ocjena',
    Luke: 'Luka',
    John: 'Ivan',
    Acts: 'Djela',
    Romans: 'Rimljani',
    '1 Corinthians': '1 Kor',
    '2 Corinthians': '2 Kor',
    Galatians: 'Galaćanima',
    Ephesians: 'Efežanima',
    Philippians: 'Filipljanima',
    Colossians: 'Kološanima',
    '1 Thessalonians': '1 Solunjanima',
    '2 Thessalonians': '2 Thessalonians',
    '1 Timothy': '1 Timothy',
    '2 Timothy': '2 Timothy',
    Titus: 'Titu',
    Philemon: 'Philemon',
    Hebrews: 'Hebrejima',
    James: 'James',
    '1 Peter': '1. Petrova',
    '2 Peter': '2 Peter',
    '1 John': '1 John',
    '2 John': '2. Ivanova',
    '3 John': '3 John',
    Jude: 'Jude',
    Revelation: 'Otkrovenje',
    'resources.prompt': 'Dodaj biblijske sredstva za dodatni uvid u prolazu prevodite.',
    'resources.add': 'Dodaj biblijski resurs',
    Manuscript: 'Rukopis',
    Morphology: 'Morfologija',
    Lexicon: 'Leksikon',
    'Syntactic Tree': 'sintaktička stablo',
    'Go to project list': 'Idi na popis projekata',
    'Project not found': 'Projekt nije pronađena ili je nedovoljno dozvole.',
    'error.referenceDoesNotExistForResource':
      'To prevođenja Biblije ne sadrži sadržaj za odabrani prolaz.',
    'references.addAnother': 'Dodaj još jednu prijevod',
    'references.referencePrompt': 'Dodajte ostale biblijske prijevode za dodatni uvid.',
    'references.add': 'Dodaj biblijski prijevod',
    'references.licensing': 'licenciranje Informacije',
    'references.licensingEmpty': 'Nema podataka licenciranje nađen za tu stavku.',
    'translation.complete': 'Prevođenje potpuna',
    Alignment: 'Poravnanje',
    Share: 'Udio',
    'Your Translation': 'vaš prijevod',
    'Enter your translation': 'Unesite svoj prijevod',
    OK: 'u redu',
    'error.verseTranslationEmpty': 'Ne možete ostaviti sadržaj prijevod prazna.',
    'translation.hide.completed': 'Sakrij završena',
    alignment: 'Poravnanje',
    'alignment.verify': 'Provjerite i zatvoriti.',
    'alignment.close': 'Zatvori bez provjere',
    'alignment.backToEditor': 'Povratak na Editor',
    'alignment.error':
      'Nešto je pošlo po zlu. Ako se problem nastavi, obratite se YouTranslate.Bible tim.',
    'alignment.add': 'Dodaj link',
    'alignment.delete': 'Ukloni vezu',
    'alignment.clear': 'Poništi odabir',
    'alignment.reverse': 'obrnuti prikaz',
    'alignment.removeOrClear': 'Ukloni odabrane vezu ili jasan izbor.',
    'alignment.addOrRemove': 'Izraditi novu vezu ili brisanje prethodnog.',
    'alignment.selectAndAdd': 'Odaberite odgovarajuće segmente na link.',
    'error.mustBeSignedToMakeChanges': 'Morate biti prijavljeni da biste promjene čine.',
    'error.mustBeSignedToTranslateBible': 'Morate biti prijavljeni da biste prevesti Bibliju.',
    checking: 'provjeravanje',
    'verseEditor.unexpectedError': 'Neočekivani pogreška.',
    verses: 'po stih',
    words: 'Do Word',
    'leftPanel.name': 'Izvorni tekst Biblije',
    'rightPanel.name': 'Prijevodi Biblije',
    'bottomPanel.name': 'Resursi za Bibliju',
    revisionHistory: 'revizija Povijest',
    'syntax.adv': 'priloški Modifikacijska',
    'syntax.adjp': 'pridjev fraza',
    'syntax.advp': 'prilog fraza',
    'syntax.cl': 'Klauzula',
    'syntax.conj': 'veznik',
    'syntax.cjp': 'veznik',
    'syntax.det': 'Determiner',
    'syntax.ijp': 'ubacivanje',
    'syntax.nump': 'numerička fraza',
    'syntax.np': 'Imenični izraz',
    'syntax.o': 'Direktni objekt',
    'syntax.o2': 'drugi objekt',
    'syntax.oc': 'Dopuna objekta',
    'syntax.om': 'objekt Marker',
    'syntax.io': 'Indirektni objekt',
    'syntax.p': 'Neverbalnog predikata',
    'syntax.pp': 'prijedložni izraz',
    'syntax.prep': 'Prijedlog',
    'syntax.ptcl': 'Čestica',
    'syntax.rel': 'relativna čestica',
    'syntax.s': 'predmet',
    'syntax.v': 'Glagol',
    'syntax.vc': 'Vezni glagol',
    'syntax.adjp.description': 'Izraz koji modificira imenicu ili zamjenicu',
    'syntax.adv.description':
      'Modificira glagol, obično dalje definira način, vrijeme, mjesto, frekvencija, stupanj, itd ...',
    'syntax.advp.description':
      'Izraz mijenjanja glagol, obično dalje definira način, vrijeme, mjesto, frekvencija, stupanj itd',
    'syntax.cl.description': 'Skupina riječi koje čini tvrdnju o toj temi.',
    'syntax.conj.description': 'Povezuje riječi, fraze i klauzule.',
    'syntax.cjp.description': 'Povezuje riječi, fraze i klauzule.',
    'syntax.det.description': 'Određuje, identificira, ili kvantitativno određuje imenske.',
    'syntax.ijp.description': 'Uskličnika ili iznenadna izražavanje emocija.',
    'syntax.nump.description': 'Izraz koji opisuje numerički količinu',
    'syntax.np.description': 'Fraza koja ima imenicu kao glavi ili koji funkcionira kao imenica.',
    'syntax.o.description':
      'Ono što je izravno utjecalo, prouzročio ili proizvedena djelovanjem glagol.',
    'syntax.o2.description':
      'Ono što je izravno utjecalo, prouzročio ili proizvedena djelovanjem glagol.',
    'syntax.oc.description': 'Čini tvrdnje o izravni objekt.',
    'syntax.om.description': 'Čestica koja uvodi izravni objekt.',
    'syntax.io.description':
      'Objekt koji posredno utječu na djelovanje, često primatelj djelovanjem glagol.',
    'syntax.p.description':
      'Utvrđuje ili opisuje subjekt, često (ali ne uvijek) javlja se zajedno s povezuje glagol.',
    'syntax.pp.description': 'Mijenjanje izraz koji se sastoji od prijedloga i njegova predmeta.',
    'syntax.prep.description':
      'Označava odnos predmeta prijedloga s drugim riječima, često ukazuje gdje i kada.',
    'syntax.ptcl.description':
      'Jedan član iz različitih skupina funkcija riječi koje ne pripadaju bilo kojoj od inflected gramatičke riječi klase',
    'syntax.rel.description': 'Čestica koja uvodi relativnu klauzulu.',
    'syntax.s.description': 'Dio klauzulu da ostatak klauzule čini tvrdnje o tome.',
    'syntax.v.description':
      'Navodi nešto o temi klauzule i mogu izraziti akciju, događaj ili stanje.',
    'syntax.vc.description': 'Linkovi temu klauzulu subjektu dopuna.',
    'general.close': 'Zatvoriti',
    'general.action': 'Akcijski',
    'general.select': 'Odaberi...',
    'general.back': 'leđa',
    'revisionHistory.noData': 'Nema izmjena po satu za ovaj stih.',
    'revisionHistory.translation': 'Prijevod',
    'revisionHistory.lastChanged': 'Zadnja izmjena',
    'sourceText.view': 'Kliknite za prikaz izvornog teksta',
    'sourceText.viewStructure': 'Kliknite da biste vidjeli strukturu izvornog teksta',
    'error.projectIdIsRequired': 'Projekt ID je potrebno.',
    'error.searchTermIsRequired': 'Izraz za pretraživanje ne može isprazniti.',
    'error.bookIdIsRequired': 'Knjiga ID je potrebno za pohranu ime knjige.',
    'error.bookNameCannotBeEmpty': 'Naziv knjige ne može biti prazan.',
    'error.signInIsRequiredToAccessProject':
      'Morate biti prijavljeni da biste pristupili svoj projekt.',
    error: 'greška',
    'error.doNotHavePermissionAccessProject': 'Nemate dozvolu za pristup ovom projektu.',
    'error.unauthorized': 'Neovlaštenog pristupa ili nevažeći pristupni token.',
    'error.tokenExpired': 'Vam je istekao token. Ponovno prijavu.',
    'error.invalidApiUrl': 'Nevažeći URL API-ja.',
    'error.badRequestInvalidJson': 'Loš zahtjev. Pogrešna JSON.',
    'error.windowIsNotDefined': 'Prozor nije definiran.',
    'error.unexpectedError':
      'Neočekivani pogreška. Molimo zatvorite ovaj prozor i pokušajte ponovno.',
    'error.invalidDataType': 'Pogrešna vrsta podataka.',
    'export.data': 'Izvoz podataka',
    more: 'Više',
    'export.to.rtf': 'Izvoz u RTF',
    'export.to.usfm': 'Izvoz u USFM',
    'export.titlePreparingData': 'Pripremamo podatke prevođenja Biblije.',
    'export.doNotClose':
      'Ovaj proces može potrajati, nemojte zatvoriti ovaj prozor, a vi ćete biti obaviješteni kada je spreman za preuzimanje.',
    'export.desc': 'Možete izvoz i preuzimanje podataka prevođenje klikom gumbiće u nastavku.',
    'export.titleOfReady2Download': 'Vaš prevođenja Biblije podataka je spreman.',
    'export.textOfReady2Download': 'Molimo kliknite gumb ispod za download svoju Bibliju prijevod.',
    'export.statistics': 'Statistika',
    'export.book': 'naziv knjige',
    'export.translatedVerses': 'Prevedeni stihovi',
    'export.exportedVerses': 'izvezena stihovi',
    'export.howDoUWant2Export': 'Kako želite izvoziti podatke?',
    'export.whichBooks': 'Koja knjiga (e) želite izvoz?',
    'export.byBooks': 'knjigama',
    'export.byChapters': 'po poglavljima',
    'export.byVerses': 'po recima',
    'export.booksInOt': 'Stari zavjet',
    'export.booksInNt': 'Novi zavjet',
    'export.booksInBible': 'cijela Biblija',
    'export.booksInNone': 'nijedan',
    'export.selectRangeOfChapters': 'Raspon poglavlja',
    'export.selectRangeOfVerses': 'Raspon stihova',
    'export.selectChapter': 'Odaberite poglavlje',
    'export.exportTypeIsRequired': 'Još niste odabrali kako želite izvesti podatke?',
    'export.haveNotYetSelectedBooks': 'Još niste odabrali niti jednu knjigu.',
    'error.selectABook': 'Morate odabrati knjigu.',
    'error.invalidBookId': 'Neispravan ID knjiga.',
    'error.selectAChapter': 'Morate odabrati poglavlje.',
    'error.invalidChapterRange': 'Morate odabrati valjani raspon poglavlja.',
    'error.invalidVerseRange': 'Morate odabrati valjani raspon stih.',
    'error.invalidExportType': 'Pogrešna vrsta izvoza.',
    startover: 'Početi ispočetka',
    download: 'preuzimanje datoteka',
    'download.all': 'Preuzmite sve datoteke',
    'contact.header': 'Kontaktirajte nas',
    'contact.returnAddress': 'Povratna adresa',
    'contact.returnAddressPlaceholder': 'Gdje možemo doći do vas?',
    'contact.subject': 'predmet',
    'contact.subjectPlaceholder': 'Što vam je potrebna pomoć?',
    'contact.message': 'Poruka',
    'contact.messagePlaceholder': 'Kako možemo pomoći?',
    'contact.sendMessage': 'Pošalji poruku',
    'contact.success': 'Poruka je uspješno poslana.',
    'contact.failure':
      'Došlo je do problema prilikom slanja poruke. Obratite help@globalbibleinitiative.org.',
    selectInterlinear: 'Kliknite za promjenu Razdjelnik tekst',
    'warning.screenTooSmall':
      'Prebacite se na višu razlučivost zaslona (minimalno 1024 x širine) ili molimo proširiti prozor preglednika.',
    'label.dictionary': 'podudarnost Traži',
    'dict.search.placeholder': 'Unesite riječ (i)',
    'tooltip.manuscript': 'Rukopis',
    'tooltip.strongsx': 'Jaka je broj',
    'tooltip.translation': 'Prijevod',
    'tooltip.english': 'Engleski',
    'title.howToSearch': 'Kako koristiti pretraživanje podudarnosti',
    'desc.howToSearch':
      'Unesite riječ (i) iz grčkog ili hebrejskom, riječ (i) iz svog prijevoda, ili značajnog broja (s). Ili kliknite na grčke ili hebrejske riječi u panelu „Vaš prijevod” ili centriranje pogleda za mogućnosti pretraživanja.',
    'dict.noDataToDisplay': 'Nema podataka koji odgovaraju Vašim kriterijima pretraživanja.',
    'title.searchTermTooMany': 'Unijeli ste previše ključnih riječi',
    'desc.searchTermTooMany': 'Možete pretraživati ​​do 8 riječi svaki put.',
    'title.searchDictError': 'Neočekivani pogreška.',
    'desc.searchDictError': 'Pokušajte ponovno kasnije ili se obratite korisničkoj podršci',
    'alignment.saveAndEditAlignment': 'Uštedite i poravnajte Novi tekst',
    'alignment.reviewAlignment': 'Pregled Poravnanje teksta',
    'dict.searchLemma': 'Kliknite za pretragu izvornu riječ',
    'dict.searchTranslation': 'Kliknite za pretragu JavaScript',
    'dict.searchStrongsX': 'Kliknite za pretragu Jako je broj',
    'dict.showMenu': 'Kliknite za prikaz puni tekst pojava u kontekstu.',
    'dict.hideMenu': 'Kliknite sakriti cijeli tekst pojava u kontekstu.',
  },
  hu: {
    'languages.en': 'angol',
    'languages.am': 'amhara',
    'languages.bn': 'bengáli nyelv',
    'languages.ceb': 'Cebuano Nyelv',
    'languages.de': 'Német nyelv',
    'languages.es-419': 'Spanyol nyelv',
    'languages.fr': 'Francia nyelv',
    'languages.gu': 'gudzsaráti',
    'languages.hi': 'hindi',
    'languages.hr': 'horvát nyelv',
    'languages.hu': 'magyar nyelv',
    'languages.id': 'Indonéz nyelv',
    'languages.km': 'khmer',
    'languages.kn': 'kannada',
    'languages.ko': 'Koreai nyelv',
    'languages.lo': 'laoszi Nyelv',
    'languages.ml': 'Malayalam',
    'languages.mr': 'marathi',
    'languages.ms': 'malajziai',
    'languages.my': 'burmai nyelv',
    'languages.ne': 'nepáli nyelv',
    'languages.plt': 'madagaszkári nyelv',
    'languages.pt-br': 'Portugál (Brazília) Nyelv',
    'languages.ru': 'Orosz nyelv',
    'languages.sw': 'szuahéli',
    'languages.ta': 'tamil nyelv',
    'languages.th': 'Thai nyelv',
    'languages.tl': 'tagalog',
    'languages.te': 'Telegu Nyelv',
    'languages.vi': 'Vietnámi nyelv',
    'languages.zh-hans': 'Kínai nyelv (egyszerűsített)',
    'languages.zh-hant': 'Kínai nyelv (hagyományos)',
    'header.home': 'itthon',
    'header.help': 'Segítség',
    'header.project': 'program',
    'header.contact': 'Kapcsolatba lépni',
    'help.contactSupport': 'Kérjük, írjon nekünk help@youtranslate.bible segítségért.',
    'home.ytbProvides': 'YouTranslate.Bible szabadon biztosítja:',
    'home.easyToUseTool': 'Egy könnyen használható online fordítási eszköz',
    'home.originalTextHelp': 'Segíts megérteni a szöveg a Biblia eredeti nyelven',
    'home.referencesAndResources': 'Referencia és képzési erőforrások',
    'home.computerAssistance': 'State-of-the-art számítógépes technológia segíti fordítók',
    'profile.userProfile': 'Felhasználói profil',
    'profile.language': 'Nyelv',
    switchDirection: 'Switch Text Direction',
    'profile.update': 'frissítés',
    'profile.languageWarning':
      'Google Translate használunk lefordítani a felhasználói felületet a nem angol nyelvű átmenetileg. YTB ​​nincs ráhatása a pontosság és a megbízhatóság a fordítást. Bárki támaszkodva a lefordított címkék és magyarázatok nyert Google Translate teszi ezt a saját kockázat. Ha valaki érdeklődik az önkéntesek, hogy pontosabb és megbízhatóbb fordítását a felhasználói felületet a nem angol nyelvű, kérjük, kattintson a Kapcsolat lehetőséget, hogy küldjön egy e-mailt a GBI.',
    'usernav.profile': 'Profil',
    'usernav.logout': 'Kijelentkezés',
    'usernav.logout.modalTitle': 'Kilépés a YouTranslate.Bible',
    'usernav.logout.modalText':
      'Biztosan meg szeretné kijelentkezni? Minden nem mentett adatai elvesznek.',
    cancel: 'Megszünteti',
    delete: 'Töröl',
    create: 'Teremt',
    update: 'frissítés',
    edit: 'szerkesztése',
    save: 'Mentés',
    add: 'hozzáad',
    translate: 'fordít',
    'more.options': 'Több lehetőség',
    'project.create.title': 'Biblia fordítási projekt',
    'project.create.list': 'Projekt lista',
    'project.list.deleteProject': 'Projekt törlése?',
    'project.list.areYouSure': 'Biztos, hogy törölni kívánja ezt a projektet?',
    'project.list.workWillBeDeleted': 'A projekt és a kapcsolódó munka törlésre kerül.',
    'project.list.completion': 'Befejezés:',
    'project.list.createdDate': 'Létrehozás dátuma:',
    'project.list.beginningDate': 'Kezdet dátuma:',
    'project.list.targetCompletionDate': 'Terv teljesítés dátuma:',
    'project.list.description': 'Leírás:',
    'project.edit': 'Projekt szerkesztése',
    'project.list.translateBible': 'Fordítás Biblia',
    'project.list.empty': 'A fordítási projekt listája üres.',
    'project.list.myProjects': 'Saját fordítású Projects',
    'project.list.newProject': 'Új projekt',
    'project.create.createNew': 'Hozzon létre egy új projekt',
    'project.namePlaceholder': 'Adja meg a projekt neve',
    'project.descriptionPlaceholder': 'Adja meg a projekt leírása',
    'project.name': 'Projekt neve',
    'project.beginningDate': 'kezdetének',
    'project.targetCompletionDate': 'Terv teljesítés dátuma',
    'project.description': 'Leírás',
    'project.isRTL': 'A fordítás jobb-bal',
    'project.manage-users': 'kezelése fordítók',
    'project.new-user': 'új fordító',
    'project.add-user': 'Add fordító',
    'project.users': 'Project fordítók',
    'project.read-only': 'Csak olvasható',
    'project.read&write': 'Ír olvas',
    'project.owner': 'Tulajdonos',
    'project.translator': 'Fordító',
    'project.message.no-such-user': 'Nincs ilyen fordító létezik',
    'project.message.user-added': 'Fordító sikeresen hozzáadva',
    'project.message.user-deleted': 'Fordító távolítani.',
    'project.message.only-owner-can-manage-users':
      'Csak projekt tulajdonosa kezelheti a felhasználókat',
    'project.shared': 'együttműködési projekt',
    'general.delete-user': 'Törlés fordító',
    'general.edit-user': 'felhasználó szerkesztése',
    'general.name': 'Név',
    'general.email': 'Email',
    'general.permissions': 'Engedélyek',
    'general.role': 'Szerep',
    signin: 'Bejelentkezés',
    'signin.emailPlaceholder': 'Email cím',
    'signin.passwordPlaceholder': 'Jelszó',
    'signin.forgotPassword': 'Elfelejtette a jelszavát?',
    'signin.facebook': 'Jelentkezzen be Facebook',
    'signin.google': 'Jelentkezzen be Google',
    'signin.dontHaveAccount': 'Ne regisztráltál?',
    'signin.createOne': 'Csinálni egyet',
    'signup.join': 'Csatlakozz YouTranslate.Bible',
    'signup.createAccountPrompt': 'Hozza létre ingyenes fiókot, és kezdődik.',
    'signup.createAccount': 'Fiók létrehozása',
    'signup.emailPlaceholder': 'Email cím',
    'signup.passwordPlaceholder': 'Jelszó',
    'passwordReset.title': 'Jelszó visszaállítása',
    'passwordReset.prompt': 'Kérjen e-mailben útmutatót a jelszó visszaállításához.',
    'passwordReset.emailPlaceholder': 'Email cím',
    'passwordReset.requestButtonLabel': 'kérés e-mail',
    'passwordReset.success': 'Az e-mail el lett küldve.',
    'passwordReset.error': 'Hiba történt.',
    'translation.project': 'PROJEKT:',
    'translation.backToProjectList': 'Vissza projekt lista',
    'translation.book': 'Könyv:',
    'translation.chapter': 'Fejezet:',
    'translation.prompt':
      'Biztosan el szeretné hagyni ezt a fejezetet? Minden nem mentett adatai elvesznek.',
    'translation.prev': 'Előző',
    'translation.next': 'Következő',
    'translation.previousChapter': 'Előző fejezet',
    'translation.nextChapter': 'Következő fejezet',
    'navigator.book': 'Könyv:',
    'navigator.chapter': 'Fejezet:',
    'navigator.oldTestament': 'Ótestamentum',
    'navigator.newTestament': 'Újtestamentum',
    'navigator.selectBook': 'Válasszon egy könyvet',
    'navigator.search': 'Keresés',
    'navigator.changeBook': 'Változás könyv',
    'navigator.changeChapter': 'Változás fejezet',
    Genesis: 'Genezis',
    Exodus: 'Kivonulás',
    Leviticus: 'Mózes harmadik könyve',
    Numbers: 'számok',
    Deuteronomy: 'Mózes ötödik könyve',
    Joshua: 'Joshua',
    Judges: 'bírák könyve',
    Ruth: 'Ruth',
    '1 Samuel': '1 Samuel',
    '2 Samuel': '2 Samuel',
    '1 Kings': '1 Kings',
    '2 Kings': '2 Kings',
    '1 Chronicles': '1 Chronicles',
    '2 Chronicles': '2 Chronicles',
    Ezra: 'Ezra',
    Nehemiah: 'Nehémiás',
    Esther: 'Esther',
    Job: 'Munka',
    Psalms: 'Zsoltárok',
    Proverbs: 'Példabeszédek',
    Ecclesiastes: 'prédikátor könyve',
    'Song of Songs': 'Énekek éneke',
    Isaiah: 'Isaiah',
    Jeremiah: 'Jeremiah',
    Lamentations: 'siralmai',
    Ezekiel: 'Ezekiel',
    Daniel: 'Daniel',
    Hosea: 'Hóseás',
    Joel: 'Joel',
    Amos: 'Amos',
    Obadiah: 'Abdiás',
    Jonah: 'Jónás',
    Micah: 'Micah',
    Nahum: 'Nahum',
    Habakkuk: 'Habakuk',
    Zephaniah: 'Sofóniás',
    Haggai: 'Aggeus',
    Zechariah: 'Zakariás',
    Malachi: 'Malachi',
    Matthew: 'Matthew',
    Mark: 'Mark',
    Luke: 'Luke',
    John: 'János',
    Acts: 'ApCsel',
    Romans: 'Romans',
    '1 Corinthians': '1 Corinthians',
    '2 Corinthians': '2 Corinthians',
    Galatians: 'Gal',
    Ephesians: 'Ef',
    Philippians: 'Filippi',
    Colossians: 'Kol',
    '1 Thessalonians': '1Thessz',
    '2 Thessalonians': '2 Thesszalonika',
    '1 Timothy': '1 Timothy',
    '2 Timothy': '2 Timothy',
    Titus: 'Titusz',
    Philemon: 'Philemon',
    Hebrews: 'Zsid',
    James: 'James',
    '1 Peter': '1 Peter',
    '2 Peter': '2 Peter',
    '1 John': '1 John',
    '2 John': '2 János',
    '3 John': '3 János',
    Jude: 'Jude',
    Revelation: 'Kinyilatkoztatás',
    'resources.prompt': 'Add bibliai források további betekintést a folyosón fordítandó.',
    'resources.add': 'Add a bibliai forrás',
    Manuscript: 'Kézirat',
    Morphology: 'Morfológia',
    Lexicon: 'Szókincs',
    'Syntactic Tree': 'A szintaktikai fa',
    'Go to project list': 'Ide projekt lista',
    'Project not found': 'A projekt nem található vagy nem megfelelő jogosultságokat.',
    'error.referenceDoesNotExistForResource':
      'Ez bibliafordítás nem tartalmaz tartalom a kiválasztott szakasz.',
    'references.addAnother': 'Újabb fordítás',
    'references.referencePrompt': 'Add más bibliafordítások további betekintést.',
    'references.add': 'Add a bibliafordítás',
    'references.licensing': 'Licensing Information',
    'references.licensingEmpty': 'Nincs engedélyezési információ találták ezt az elemet.',
    'translation.complete': 'Translation teljes',
    Alignment: 'igazítás',
    Share: 'Ossza meg',
    'Your Translation': 'Az Translation',
    'Enter your translation': 'Adja meg a fordítás',
    OK: 'rendben',
    'error.verseTranslationEmpty': 'Nem hagyhatja a fordítás tartalmát kiüríteni.',
    'translation.hide.completed': 'Hide befejezett',
    alignment: 'igazítás',
    'alignment.verify': 'Ellenőrizze, és zárja be.',
    'alignment.close': 'Bezár ellenőrzése nélkül',
    'alignment.backToEditor': 'Vissza szerkesztő',
    'alignment.error':
      'Valami elromlott. Ha a probléma továbbra is fennáll, forduljon a YouTranslate.Bible csapat.',
    'alignment.add': 'link hozzáadása',
    'alignment.delete': 'link eltávolítása',
    'alignment.clear': 'Kijelölés törlése',
    'alignment.reverse': 'fordított megjelenítése',
    'alignment.removeOrClear': 'Távolítsuk el a kiválasztott link, vagy tiszta választást.',
    'alignment.addOrRemove': 'Hozzon létre egy új kapcsolatot, vagy törölhet egy előzőt.',
    'alignment.selectAndAdd': 'Jelölje megfelelő szegmensei a link.',
    'error.mustBeSignedToMakeChanges': 'Be kell jelentkeznie a módosításokat.',
    'error.mustBeSignedToTranslateBible': 'Be kell jelentkeznie a lefordítani a Bibliát.',
    checking: 'Ellenőrzés',
    'verseEditor.unexpectedError': 'Váratlan hiba történt.',
    verses: 'vers',
    words: 'a Word',
    'leftPanel.name': 'Az eredeti szöveg a Biblia',
    'rightPanel.name': 'Bibliafordítás',
    'bottomPanel.name': 'Források a Biblia',
    revisionHistory: 'Módosítási előzmények',
    'syntax.adv': 'Határozói módosító',
    'syntax.adjp': 'melléknévi kifejezés',
    'syntax.advp': 'határozószó kifejezés',
    'syntax.cl': 'Kikötés',
    'syntax.conj': 'kötőszó',
    'syntax.cjp': 'kötőszó',
    'syntax.det': 'Meghatározó',
    'syntax.ijp': 'Indulatszó',
    'syntax.nump': 'numerikus kifejezés',
    'syntax.np': 'Noun Phrase',
    'syntax.o': 'Közvetlen tárgy',
    'syntax.o2': 'második Object',
    'syntax.oc': 'Object-kiegészítő',
    'syntax.om': 'Object Marker',
    'syntax.io': 'közvetett Object',
    'syntax.p': 'Non-igei állítmány',
    'syntax.pp': 'Elöljárós kifejezés',
    'syntax.prep': 'Elöljáró',
    'syntax.ptcl': 'Részecske',
    'syntax.rel': 'relatív Részecske',
    'syntax.s': 'Tantárgy',
    'syntax.v': 'Ige',
    'syntax.vc': 'Összekötő ige',
    'syntax.adjp.description': 'Kifejezés, amely módosítja a főnév vagy névmás',
    'syntax.adv.description':
      'Módosítja az ige, jellemzően további meghatározó módon, időt, helyet, frekvencia, fokozat, stb ...',
    'syntax.advp.description':
      'Kifejezés módosításával ige, jellemzően további meghatározása módon, idő, hely, gyakorisága, mértéke, stb',
    'syntax.cl.description': 'A szavak csoportja, ami az állítás a témáról.',
    'syntax.conj.description': 'Összeköti szavak, kifejezések és kikötések.',
    'syntax.cjp.description': 'Összeköti szavak, kifejezések és kikötések.',
    'syntax.det.description': 'Meghatározza, azonosítja vagy számszerűsíti a főnév kifejezésre.',
    'syntax.ijp.description': 'Exclamation vagy hirtelen érzelmek kifejezése.',
    'syntax.nump.description': 'Kifejezést, amely leírja numerikus mennyiség',
    'syntax.np.description':
      'Egy kifejezés, amely egy főnév, mint a feje, vagy hogy működik, mint egy főnév.',
    'syntax.o.description': 'Ez közvetlenül érinti, okoztak, vagy hatására képződik egy ige.',
    'syntax.o2.description': 'Ez közvetlenül érinti, okoztak, vagy hatására képződik egy ige.',
    'syntax.oc.description': 'Teszi egy kijelentés, ami a közvetlen tárgy.',
    'syntax.om.description': 'Részecske, amely bevezeti a közvetlen tárgy.',
    'syntax.io.description':
      'Objektum, amelyet közvetve érintett intézkedés gyakran a kedvezményezett a fellépés ige.',
    'syntax.p.description':
      'Azonosítja, vagy leírja a témát, gyakran (de nem mindig) együtt fordul elő egy összekötő ige.',
    'syntax.pp.description': 'Módosítása kifejezés, amely egy prepozíció és célkitűzése.',
    'syntax.prep.description':
      'Jelzi viszony a tárgy a prepozíció más szavakkal, gyakran azt jelzik, hol és mikor.',
    'syntax.ptcl.description':
      'Az egyik tag a változatos csoport funkció szavak, amelyek nem tartoznak sem a ragozott nyelvtani szó osztályok',
    'syntax.rel.description': 'Részecske, amely bemutatja a vonatkozó mellékmondat.',
    'syntax.s.description':
      'Az a része, a záradék, amely a többi záradék értelmében az állítás kb.',
    'syntax.v.description':
      'Kimondja, valamit a témáról a kikötés és kifejtheti akció, esemény vagy feltétel.',
    'syntax.vc.description': 'Linkek a rá vonatkozó kikötés egy alanynak kiegészítője.',
    'general.close': 'Bezárás',
    'general.action': 'Akció',
    'general.select': 'Kiválasztás ...',
    'general.back': 'Vissza',
    'revisionHistory.noData': 'Nincs laptörténete ez a vers.',
    'revisionHistory.translation': 'Fordítás',
    'revisionHistory.lastChanged': 'Utolsó módosítás',
    'sourceText.view': 'Kattintson ide az eredeti szöveg',
    'sourceText.viewStructure': 'Kattintson ide az eredeti szöveg szerkezete',
    'error.projectIdIsRequired': 'Projekt azonosító szükséges.',
    'error.searchTermIsRequired': 'A keresési kifejezés nem üres.',
    'error.bookIdIsRequired': 'Book azonosító szükséges tárolni könyv nevét.',
    'error.bookNameCannotBeEmpty': 'Book neve nem lehet üres.',
    'error.signInIsRequiredToAccessProject': 'Be kell bejelentkezett, hogy elérje a projekt.',
    error: 'Hiba',
    'error.doNotHavePermissionAccessProject': 'Nem jogosultsága hozzáférni ehhez a projekthez.',
    'error.unauthorized': 'Jogosulatlan hozzáférés vagy érvénytelen hozzáférési tokent.',
    'error.tokenExpired': 'A token lejárt. Kérjük, ismételt belépést.',
    'error.invalidApiUrl': 'Érvénytelen API URL-t.',
    'error.badRequestInvalidJson': 'Rossz kérés. Érvénytelen JSON.',
    'error.windowIsNotDefined': 'Ablak nincs definiálva.',
    'error.unexpectedError':
      'Váratlan hiba történt. Kérjük, zárja be ezt az ablakot, és próbálkozzon újra.',
    'error.invalidDataType': 'Érvénytelen adatok típusát.',
    'export.data': 'adatok exportálása',
    more: 'Több',
    'export.to.rtf': 'Export RTF',
    'export.to.usfm': 'Export USFM',
    'export.titlePreparingData': 'Mi készül a bibliafordítás adatokat.',
    'export.doNotClose':
      'Ez a folyamat eltarthat egy darabig, kérjük ne zárja be ezt az ablakot, és akkor be kell jelenteni, ha készen áll a letöltés.',
    'export.desc': 'Akkor exportálása és letöltése fordítási adatok kattintva gombok alatt.',
    'export.titleOfReady2Download': 'A bibliafordítás adatok készen.',
    'export.textOfReady2Download':
      'Kérjük, kattintson a letöltés gombra, hogy töltse le a bibliafordítás.',
    'export.statistics': 'Statisztika',
    'export.book': 'Book neve',
    'export.translatedVerses': 'Fordította versek',
    'export.exportedVerses': 'exportált versek',
    'export.howDoUWant2Export': 'Hogyan szeretne exportálni az adatokat?',
    'export.whichBooks': 'Melyik könyv (ek) akarsz export?',
    'export.byBooks': 'könyvek',
    'export.byChapters': 'fejezetek',
    'export.byVerses': 'by versek',
    'export.booksInOt': 'Ótestamentum',
    'export.booksInNt': 'Újtestamentum',
    'export.booksInBible': 'egész Biblia',
    'export.booksInNone': 'Egyik sem',
    'export.selectRangeOfChapters': 'Több fejezetből',
    'export.selectRangeOfVerses': 'Tartomány versek',
    'export.selectChapter': 'Válasszon ki egy fejezetet',
    'export.exportTypeIsRequired': 'Ön még nem választott, hogyan akarsz kiviteli adatokat?',
    'export.haveNotYetSelectedBooks': 'Még nem választott ki egyetlen könyvet.',
    'error.selectABook': 'Ki kell választani egy könyvet.',
    'error.invalidBookId': 'Érvénytelen könyv azonosítója.',
    'error.selectAChapter': 'Ki kell választani egy fejezetet.',
    'error.invalidChapterRange': 'Ki kell választani egy érvényes fejezetet tartományban.',
    'error.invalidVerseRange': 'Ki kell választani egy érvényes vers tartományban.',
    'error.invalidExportType': 'Érvénytelen export típusát.',
    startover: 'Elölről kezdeni',
    download: 'Letöltés',
    'download.all': 'Az összes fájl letöltése',
    'contact.header': 'Lépjen kapcsolatba velünk',
    'contact.returnAddress': 'Visszaszállítási cím',
    'contact.returnAddressPlaceholder': 'Hol el Önt?',
    'contact.subject': 'Tantárgy',
    'contact.subjectPlaceholder': 'Mivel kapcsolatban van szükséged segítségre?',
    'contact.message': 'Üzenet',
    'contact.messagePlaceholder': 'Hogyan segíthetünk?',
    'contact.sendMessage': 'Üzenet küldése',
    'contact.success': 'Az üzenet sikeresen elküldve.',
    'contact.failure':
      'Volt egy probléma küldő ezt az üzenetet. Kérjük, lépjen kapcsolatba help@globalbibleinitiative.org.',
    selectInterlinear: 'Kattintson változtatni Interlinear',
    'warning.screenTooSmall':
      'Kérjük, váltson át egy nagyobb felbontás (minimum 1024 képpont széles), vagy kérjük, szélesíteni a böngésző ablakot.',
    'label.dictionary': 'konkordanciakeresés',
    'dict.search.placeholder': 'Írja szót (s)',
    'tooltip.manuscript': 'Kézirat',
    'tooltip.strongsx': 'Strong száma',
    'tooltip.translation': 'Fordítás',
    'tooltip.english': 'angol',
    'title.howToSearch': 'Hogyan kell használni konkordanciakeresés',
    'desc.howToSearch':
      'Írja szót (ok) a görög vagy héber szó (ok) a fordítás, vagy a Strong szám (ok). Vagy kattintson a görög vagy héber szó a panel „A fordítás” vagy igazítás nézetek a keresési lehetőségeket.',
    'dict.noDataToDisplay': 'Nincsenek adatok, amelyek megfelelnek a keresési feltételeknek.',
    'title.searchTermTooMany': 'Megadta túl sok kulcsszót',
    'desc.searchTermTooMany': 'Kereshet akár 8 kulcsszavak minden egyes alkalommal.',
    'title.searchDictError': 'Váratlan hiba történt.',
    'desc.searchDictError': 'Kérjük, próbálja újra, vagy lépjen kapcsolatba az ügyfélszolgálattal',
    'alignment.saveAndEditAlignment': 'Mentés és illessze az új szöveg',
    'alignment.reviewAlignment': 'Vélemény Szöveg igazítása',
    'dict.searchLemma': 'Kattintson a kereséshez eredeti szót',
    'dict.searchTranslation': 'Kattintson a keresés fordítás szó',
    'dict.searchStrongsX': 'Kattintson a keresés Strong szám',
    'dict.showMenu': 'Kattintson ide, hogy teljes szövege események összefüggésben.',
    'dict.hideMenu': 'Kattints, hogy elrejtse teljes szövegét események összefüggésben.',
  },
  id: {
    'languages.en': 'Inggris',
    'languages.am': 'Amharic',
    'languages.bn': 'Bahasa Bengali',
    'languages.ceb': 'Cebuano Bahasa',
    'languages.de': 'Bahasa Jerman',
    'languages.es-419': 'Bahasa Spanyol',
    'languages.fr': 'Bahasa Perancis',
    'languages.gu': 'Gujarati',
    'languages.hi': 'Hindi',
    'languages.hr': 'Kroasia Bahasa',
    'languages.hu': 'Hungaria Bahasa',
    'languages.id': 'Bahasa Indonesia',
    'languages.km': 'khmer',
    'languages.kn': 'Kannada',
    'languages.ko': 'Bahasa Korea',
    'languages.lo': 'Bahasa Laos',
    'languages.ml': 'Malayalam',
    'languages.mr': 'Marathi',
    'languages.ms': 'Malaysia',
    'languages.my': 'Burma Bahasa',
    'languages.ne': 'Nepal Bahasa',
    'languages.plt': 'Malagasi Bahasa',
    'languages.pt-br': 'Portugis (Brasil) Bahasa',
    'languages.ru': 'Bahasa Rusia',
    'languages.sw': 'Swahili',
    'languages.ta': 'Bahasa Tamil',
    'languages.th': 'Bahasa Thailand',
    'languages.tl': 'Tagalog',
    'languages.te': 'Telegu Bahasa',
    'languages.vi': 'Bahasa Vietnam',
    'languages.zh-hans': 'Bahasa Cina (Sederhana)',
    'languages.zh-hant': 'Bahasa Cina (Tradisional)',
    'header.home': 'Rumah',
    'header.help': 'Tolong',
    'header.project': 'Proyek',
    'header.contact': 'Kontak',
    'help.contactSupport': 'Silahkan email kami di help@youtranslate.bible untuk bantuan.',
    'home.ytbProvides': 'YouTranslate.Bible bebas memberikan:',
    'home.easyToUseTool': 'Alat terjemahan online mudah digunakan',
    'home.originalTextHelp': 'Bantuan untuk memahami teks Alkitab dalam bahasa aslinya',
    'home.referencesAndResources': 'sumber referensi dan pelatihan',
    'home.computerAssistance': 'State-of-the-art teknologi komputer untuk membantu penerjemah',
    'profile.userProfile': 'Profil pengguna',
    'profile.language': 'Bahasa',
    switchDirection: 'Beralih Arah Teks',
    'profile.update': 'Memperbarui',
    'profile.languageWarning':
      'Google Translate digunakan untuk menerjemahkan antarmuka pengguna ke dalam bahasa lain selain bahasa Inggris sementara. YTB ​​tidak memiliki kontrol atas keakuratan atau keandalan terjemahan. Siapa pun mengandalkan label diterjemahkan dan penjelasan yang diperoleh dari Google Translate melakukannya atas resiko sendiri-sendiri. Jika ada yang tertarik menjadi sukarelawan untuk memberikan terjemahan yang lebih akurat dan dapat diandalkan dari user interface ke dalam bahasa lain selain bahasa Inggris, silakan klik opsi Hubungi untuk mengirim email ke GBI.',
    'usernav.profile': 'Profil',
    'usernav.logout': 'Keluar',
    'usernav.logout.modalTitle': 'Keluar dari YouTranslate.Bible',
    'usernav.logout.modalText':
      'Apakah Anda yakin Anda ingin logout? Semua pekerjaan yang belum disimpan akan hilang.',
    cancel: 'Membatalkan',
    delete: 'Menghapus',
    create: 'Membuat',
    update: 'Memperbarui',
    edit: 'mengedit',
    save: 'Menyimpan',
    add: 'Menambahkan',
    translate: 'Menterjemahkan',
    'more.options': 'Lebih banyak pilihan',
    'project.create.title': 'Proyek Terjemahan Alkitab',
    'project.create.list': 'daftar proyek',
    'project.list.deleteProject': 'Hapus Project?',
    'project.list.areYouSure': 'Apakah Anda yakin ingin menghapus proyek ini?',
    'project.list.workWillBeDeleted': 'Proyek dan setiap pekerjaan yang terkait akan dihapus.',
    'project.list.completion': 'Penyelesaian:',
    'project.list.createdDate': 'Tanggal dibuat:',
    'project.list.beginningDate': 'Mulai Tanggal:',
    'project.list.targetCompletionDate': 'Target Penyelesaian Tanggal:',
    'project.list.description': 'Deskripsi:',
    'project.edit': 'mengedit Proyek',
    'project.list.translateBible': 'menerjemahkan Alkitab',
    'project.list.empty': 'daftar proyek terjemahan Anda kosong.',
    'project.list.myProjects': 'Saya Proyek Terjemahan Alkitab',
    'project.list.newProject': 'Proyek baru',
    'project.create.createNew': 'Buat Proyek baru',
    'project.namePlaceholder': 'Masukkan nama proyek',
    'project.descriptionPlaceholder': 'Masukkan deskripsi proyek',
    'project.name': 'Nama Proyek',
    'project.beginningDate': 'mulai Tanggal',
    'project.targetCompletionDate': 'Target Penyelesaian Tanggal',
    'project.description': 'Deskripsi',
    'project.isRTL': 'Terjemahan kanan-ke-kiri',
    'project.manage-users': 'mengelola Penerjemah',
    'project.new-user': 'penerjemah baru',
    'project.add-user': 'add penerjemah',
    'project.users': 'penerjemah proyek',
    'project.read-only': 'Baca saja',
    'project.read&write': 'Baca tulis',
    'project.owner': 'Pemilik',
    'project.translator': 'Penerjemah',
    'project.message.no-such-user': 'Tidak ada penerjemah seperti ada',
    'project.message.user-added': 'Penerjemah berhasil ditambahkan',
    'project.message.user-deleted': 'Penerjemah dihapus.',
    'project.message.only-owner-can-manage-users': 'Hanya pemilik proyek dapat mengelola pengguna',
    'project.shared': 'proyek kolaborasi',
    'general.delete-user': 'Hapus penerjemah',
    'general.edit-user': 'mengedit pengguna',
    'general.name': 'Nama',
    'general.email': 'Surel',
    'general.permissions': 'perizinan',
    'general.role': 'Wewenang',
    signin: 'Masuk',
    'signin.emailPlaceholder': 'Alamat email',
    'signin.passwordPlaceholder': 'Kata sandi',
    'signin.forgotPassword': 'Lupa kata sandi Anda?',
    'signin.facebook': 'Masuk melalui Facebook',
    'signin.google': 'Masuk dengan Google',
    'signin.dontHaveAccount': 'Tidak memiliki akun?',
    'signin.createOne': 'Buat satu',
    'signup.join': 'bergabung YouTranslate.Bible',
    'signup.createAccountPrompt': 'Buat akun gratis Anda untuk memulai.',
    'signup.createAccount': 'Buat Akun',
    'signup.emailPlaceholder': 'Alamat email',
    'signup.passwordPlaceholder': 'Kata sandi',
    'passwordReset.title': 'password reset',
    'passwordReset.prompt': 'Meminta email dengan petunjuk untuk mereset password Anda.',
    'passwordReset.emailPlaceholder': 'Alamat email',
    'passwordReset.requestButtonLabel': 'Permintaan Email',
    'passwordReset.success': 'Email telah dikirim.',
    'passwordReset.error': 'Sebuah kesalahan telah terjadi.',
    'translation.project': 'PROYEK:',
    'translation.backToProjectList': 'Kembali ke daftar proyek',
    'translation.book': 'Buku:',
    'translation.chapter': 'Bab:',
    'translation.prompt':
      'Apakah Anda yakin ingin meninggalkan bab ini? Semua pekerjaan yang belum disimpan akan hilang.',
    'translation.prev': 'prev',
    'translation.next': 'Lanjut',
    'translation.previousChapter': 'bab sebelumnya',
    'translation.nextChapter': 'Bab selanjutnya',
    'navigator.book': 'Buku:',
    'navigator.chapter': 'Bab:',
    'navigator.oldTestament': 'Perjanjian Lama',
    'navigator.newTestament': 'Perjanjian Baru',
    'navigator.selectBook': 'Pilih buku',
    'navigator.search': 'Cari',
    'navigator.changeBook': 'perubahan buku',
    'navigator.changeChapter': 'perubahan bab',
    Genesis: 'Asal',
    Exodus: 'Keluaran',
    Leviticus: 'Imamat',
    Numbers: 'nomor',
    Deuteronomy: 'Ulangan',
    Joshua: 'joshua',
    Judges: 'hakim',
    Ruth: 'duka',
    '1 Samuel': '1 Samuel',
    '2 Samuel': '2 Samuel',
    '1 Kings': '1 Kings',
    '2 Kings': '2 Kings',
    '1 Chronicles': '1 Tawarikh',
    '2 Chronicles': '2 Tawarikh',
    Ezra: 'Ezra',
    Nehemiah: 'Nehemia',
    Esther: 'Esther',
    Job: 'Pekerjaan',
    Psalms: 'Mazmur',
    Proverbs: 'Peribahasa',
    Ecclesiastes: 'Pengkhotbah',
    'Song of Songs': 'Song of Songs',
    Isaiah: 'Yesaya',
    Jeremiah: 'Yeremia',
    Lamentations: 'Ratapan',
    Ezekiel: 'Yehezkiel',
    Daniel: 'Daniel',
    Hosea: 'Hosea',
    Joel: 'joel',
    Amos: 'Amos',
    Obadiah: 'Obaja',
    Jonah: 'Yunus',
    Micah: 'Micah',
    Nahum: 'Nahum',
    Habakkuk: 'Habakuk',
    Zephaniah: 'Zefanya',
    Haggai: 'Hagai',
    Zechariah: 'Zakharia',
    Malachi: 'Maleakhi',
    Matthew: 'Matthew',
    Mark: 'Menandai',
    Luke: 'Luke',
    John: 'John',
    Acts: 'Tindakan',
    Romans: 'Roma',
    '1 Corinthians': '1 Korintus',
    '2 Corinthians': '2 Korintus',
    Galatians: 'Galatia',
    Ephesians: 'Efesus',
    Philippians: 'Filipi',
    Colossians: 'Kolose',
    '1 Thessalonians': '1 Tesalonika',
    '2 Thessalonians': '2 Tesalonika',
    '1 Timothy': '1 Timotius',
    '2 Timothy': '2 Timotius',
    Titus: 'Titus',
    Philemon: 'Filemon',
    Hebrews: 'Ibrani',
    James: 'James',
    '1 Peter': '1 Petrus',
    '2 Peter': '2 Peter',
    '1 John': '1 John',
    '2 John': '2 John',
    '3 John': '3 John',
    Jude: 'Jude',
    Revelation: 'Wahyu',
    'resources.prompt':
      'Menambahkan sumber Alkitab untuk wawasan tambahan bagian Anda menerjemahkan.',
    'resources.add': 'Tambahkan sumber daya Alkitab',
    Manuscript: 'Naskah',
    Morphology: 'Morfologi',
    Lexicon: 'Kamus',
    'Syntactic Tree': 'sintaksis Pohon',
    'Go to project list': 'Pergi ke daftar proyek',
    'Project not found': 'Proyek tidak ditemukan atau izin tidak memadai.',
    'error.referenceDoesNotExistForResource':
      'penerjemahan Alkitab ini tidak mengandung konten untuk bagian yang dipilih.',
    'references.addAnother': 'Tambah terjemahan lain',
    'references.referencePrompt': 'Menambahkan terjemahan Alkitab lainnya untuk wawasan tambahan.',
    'references.add': 'Tambah terjemahan Alkitab',
    'references.licensing': 'Informasi perizinan',
    'references.licensingEmpty': 'Tidak ada informasi lisensi yang ditemukan untuk item ini.',
    'translation.complete': 'lengkap terjemahan',
    Alignment: 'Penjajaran',
    Share: 'Bagikan',
    'Your Translation': 'Terjemahan Anda',
    'Enter your translation': 'Masukkan terjemahan Anda',
    OK: 'baik',
    'error.verseTranslationEmpty': 'Anda tidak bisa meninggalkan konten terjemahan kosong.',
    'translation.hide.completed': 'Sembunyikan selesai',
    alignment: 'Penjajaran',
    'alignment.verify': 'Verifikasi dan menutup.',
    'alignment.close': 'Tutup tanpa Memverifikasi',
    'alignment.backToEditor': 'Kembali ke Editor',
    'alignment.error':
      'Ada yang salah. Jika tetap terjadi masalah, hubungi tim YouTranslate.Bible.',
    'alignment.add': 'Tambah link',
    'alignment.delete': 'Hapus Tautan',
    'alignment.clear': 'jelas Seleksi',
    'alignment.reverse': 'Tampilan terbalik',
    'alignment.removeOrClear': 'Menghapus link yang dipilih atau seleksi yang jelas.',
    'alignment.addOrRemove': 'Buat link baru atau menghapus sebelumnya.',
    'alignment.selectAndAdd': 'Pilih yang sesuai segmen-link.',
    'error.mustBeSignedToMakeChanges': 'Anda harus masuk ke perubahan make.',
    'error.mustBeSignedToTranslateBible': 'Anda harus masuk untuk menerjemahkan Alkitab.',
    checking: 'memeriksa',
    'verseEditor.unexpectedError': 'Terjadi sebuah kesalahan yang tidak diharapkan.',
    verses: 'dengan Ayat',
    words: 'dengan kata',
    'leftPanel.name': 'Teks asli Alkitab',
    'rightPanel.name': 'Terjemahan Alkitab',
    'bottomPanel.name': 'Sumber daya untuk Alkitab',
    revisionHistory: 'revisi Sejarah',
    'syntax.adv': 'adverbial Modifier',
    'syntax.adjp': 'Frase kata sifat',
    'syntax.advp': 'Frase kata keterangan',
    'syntax.cl': 'Ayat',
    'syntax.conj': 'Konjungsi',
    'syntax.cjp': 'Konjungsi',
    'syntax.det': 'penentu',
    'syntax.ijp': 'Kata seru',
    'syntax.nump': 'Frase numerik',
    'syntax.np': 'Frase nomina',
    'syntax.o': 'Objek langsung',
    'syntax.o2': 'kedua Obyek',
    'syntax.oc': 'objek Pelengkap',
    'syntax.om': 'objek Marker',
    'syntax.io': 'Objek tidak langsung',
    'syntax.p': 'Non-Verbal Predikat',
    'syntax.pp': 'Frase preposisional',
    'syntax.prep': 'Preposisi',
    'syntax.ptcl': 'Partikel',
    'syntax.rel': 'Partikel relatif',
    'syntax.s': 'Subyek',
    'syntax.v': 'Kata kerja',
    'syntax.vc': 'menghubungkan Verb',
    'syntax.adjp.description': 'Frase yang memodifikasi kata benda atau kata ganti',
    'syntax.adv.description':
      'Memodifikasi kata kerja, mendefinisikan biasanya lanjut cara, waktu, tempat, frekuensi, tingkat, dll ...',
    'syntax.advp.description':
      'Frase memodifikasi kata kerja, biasanya lebih mendefinisikan cara, waktu, tempat, frekuensi, tingkat, dll',
    'syntax.cl.description': 'Sekelompok kata-kata yang membuat pernyataan tentang subjek.',
    'syntax.conj.description': 'Menghubungkan kata, frasa, dan klausa.',
    'syntax.cjp.description': 'Menghubungkan kata, frasa, dan klausa.',
    'syntax.det.description':
      'Menentukan, mengidentifikasi, atau mengkuantifikasi frase kata benda.',
    'syntax.ijp.description': 'Seru atau ekspresi tiba-tiba emosi.',
    'syntax.nump.description': 'Kalimat yang menguraikan kuantitas numerik',
    'syntax.np.description':
      'Sebuah frase yang memiliki kata benda sebagai kepala atau yang berfungsi sebagai kata benda.',
    'syntax.o.description':
      'Itu yang terkena dampak langsung, disebabkan, atau diproduksi oleh aksi kata kerja.',
    'syntax.o2.description':
      'Itu yang terkena dampak langsung, disebabkan, atau diproduksi oleh aksi kata kerja.',
    'syntax.oc.description': 'Membuat pernyataan tentang Object Direct.',
    'syntax.om.description': 'Partikel yang memperkenalkan objek langsung.',
    'syntax.io.description':
      'Objek yang secara tidak langsung dipengaruhi oleh tindakan, sering penerima aksi kata kerja.',
    'syntax.p.description':
      'Mengidentifikasi atau menggambarkan subjek, seringkali (tetapi tidak selalu) terjadi bersama-sama dengan kata kerja menghubungkan.',
    'syntax.pp.description': 'Memodifikasi frase yang terdiri dari preposisi dan objeknya.',
    'syntax.prep.description':
      'Menunjukkan hubungan obyek preposisi dengan kata lain, sering menunjukkan di mana atau kapan.',
    'syntax.ptcl.description':
      'Salah satu anggota dari kelompok beragam kata-kata fungsi yang tidak tergabung dalam kelas kata gramatikal yang infleksi',
    'syntax.rel.description': 'Partikel yang memperkenalkan klausa relatif.',
    'syntax.s.description': 'Bagian dari klausul bahwa sisa klausa membuat pernyataan tentang.',
    'syntax.v.description':
      'Menyatakan sesuatu tentang subjek klausa dan dapat mengekspresikan tindakan, peristiwa, atau kondisi.',
    'syntax.vc.description': 'Link subjek klausa untuk pelengkap subjek.',
    'general.close': 'Menutup',
    'general.action': 'Tindakan',
    'general.select': 'Pilih...',
    'general.back': 'Kembali',
    'revisionHistory.noData': 'Tidak ada sejarah revisi untuk ayat ini.',
    'revisionHistory.translation': 'Terjemahan',
    'revisionHistory.lastChanged': 'terakhir Berubah',
    'sourceText.view': 'Klik untuk melihat teks sumber',
    'sourceText.viewStructure': 'Klik untuk melihat struktur teks sumber',
    'error.projectIdIsRequired': 'Proyek ID diperlukan.',
    'error.searchTermIsRequired': 'istilah pencarian tidak dapat mengosongkan.',
    'error.bookIdIsRequired': 'Buku ID diperlukan untuk menyimpan nama buku.',
    'error.bookNameCannotBeEmpty': 'Nama buku tidak boleh kosong.',
    'error.signInIsRequiredToAccessProject': 'Anda harus masuk untuk mengakses proyek Anda.',
    error: 'Kesalahan',
    'error.doNotHavePermissionAccessProject':
      'Anda tidak memiliki izin untuk mengakses proyek ini.',
    'error.unauthorized': 'akses yang tidak sah atau akses Invalid token.',
    'error.tokenExpired': 'token Anda telah kedaluwarsa. Silakan re-login.',
    'error.invalidApiUrl': 'API url tidak valid.',
    'error.badRequestInvalidJson': 'Permintaan yang buruk. Valid JSON.',
    'error.windowIsNotDefined': 'Jendela tidak didefinisikan.',
    'error.unexpectedError':
      'Terjadi sebuah kesalahan yang tidak diharapkan. Silakan tutup jendela ini dan coba lagi.',
    'error.invalidDataType': 'tipe data yang tidak valid.',
    'export.data': 'ekspor data',
    more: 'Lebih',
    'export.to.rtf': 'Ekspor ke RTF',
    'export.to.usfm': 'Ekspor ke USFM',
    'export.titlePreparingData': 'Kami sedang mempersiapkan data yang penerjemahan Alkitab Anda.',
    'export.doNotClose':
      'Proses ini mungkin memakan waktu, mohon tidak menutup jendela ini dan Anda akan diberitahu bila siap untuk men-download.',
    'export.desc':
      'Anda dapat mengekspor dan mengunduh data terjemahan Anda dengan mengklik tombol di bawah.',
    'export.titleOfReady2Download': 'Anda Data penerjemahan Alkitab siap.',
    'export.textOfReady2Download':
      'Silakan klik tombol download di bawah ini untuk men-download penerjemahan Alkitab Anda.',
    'export.statistics': 'statistika',
    'export.book': 'nama buku',
    'export.translatedVerses': 'ayat diterjemahkan',
    'export.exportedVerses': 'ayat diekspor',
    'export.howDoUWant2Export': 'Bagaimana Anda ingin mengekspor data?',
    'export.whichBooks': 'book (s) yang ingin Anda ekspor?',
    'export.byBooks': 'dengan buku',
    'export.byChapters': 'dengan bab',
    'export.byVerses': 'dengan ayat',
    'export.booksInOt': 'Perjanjian Lama',
    'export.booksInNt': 'Perjanjian Baru',
    'export.booksInBible': 'seluruh Alkitab',
    'export.booksInNone': 'tak satupun',
    'export.selectRangeOfChapters': 'Rentang bab',
    'export.selectRangeOfVerses': 'Rentang ayat',
    'export.selectChapter': 'Pilih bab',
    'export.exportTypeIsRequired': 'Anda belum memilih tentang bagaimana Anda ingin ekspor data?',
    'export.haveNotYetSelectedBooks': 'Anda belum memilih buku apa pun.',
    'error.selectABook': 'Anda harus memilih sebuah buku.',
    'error.invalidBookId': 'ID buku valid.',
    'error.selectAChapter': 'Anda harus memilih sebuah bab.',
    'error.invalidChapterRange': 'Anda harus memilih berbagai bab yang valid.',
    'error.invalidVerseRange': 'Anda harus memilih berbagai ayat valid.',
    'error.invalidExportType': 'jenis ekspor tidak valid.',
    startover: 'Mulai lebih',
    download: 'Download',
    'download.all': 'Download semua file',
    'contact.header': 'Hubungi kami',
    'contact.returnAddress': 'Alamat pengembalian',
    'contact.returnAddressPlaceholder': 'Di mana kita dapat mencapai Anda?',
    'contact.subject': 'Subyek',
    'contact.subjectPlaceholder': 'Apa yang Anda perlu bantuan dengan?',
    'contact.message': 'Pesan',
    'contact.messagePlaceholder': 'Bagaimana kami dapat membantu?',
    'contact.sendMessage': 'Mengirim pesan',
    'contact.success': 'Pesan berhasil dikirim.',
    'contact.failure':
      'Ada masalah mengirim pesan ini. Silahkan hubungi help@globalbibleinitiative.org.',
    selectInterlinear: 'Klik untuk mengubah teks interlinear',
    'warning.screenTooSmall':
      'Harap beralih ke resolusi layar lebih tinggi (minimal 1024px lebar) atau silakan memperluas jendela browser Anda.',
    'label.dictionary': 'konkordansi Pencarian',
    'dict.search.placeholder': 'Masukkan kata (s)',
    'tooltip.manuscript': 'Naskah',
    'tooltip.strongsx': 'Strong Nomor',
    'tooltip.translation': 'Terjemahan',
    'tooltip.english': 'Inggris',
    'title.howToSearch': 'Cara menggunakan penelusuran kesesuaian',
    'desc.howToSearch':
      'Masukkan kata (s) dari bahasa Yunani atau Ibrani, kata (s) dari terjemahan Anda, atau Nomor Kuat (s). Atau klik pada kata Yunani atau Ibrani di panel "terjemahan Anda", atau Keselarasan untuk pencarian.',
    'dict.noDataToDisplay': 'Tidak ada data yang sesuai dengan kriteria pencarian Anda.',
    'title.searchTermTooMany': 'Anda telah memasukkan terlalu banyak kata kunci',
    'desc.searchTermTooMany': 'Anda dapat mencari hingga 8 kata kunci setiap kali.',
    'title.searchDictError': 'Terjadi sebuah kesalahan yang tidak diharapkan.',
    'desc.searchDictError': 'Silakan coba lagi nanti atau hubungi dukungan pelanggan',
    'alignment.saveAndEditAlignment': 'Teks Simpan dan Align New',
    'alignment.reviewAlignment': 'Ulasan Teks Keselarasan',
    'dict.searchLemma': 'Klik untuk mencari kata aslinya',
    'dict.searchTranslation': 'Klik untuk mencari kata terjemahan',
    'dict.searchStrongsX': 'Klik untuk mencari nomor Strong',
    'dict.showMenu': 'Klik untuk menampilkan teks lengkap dari kejadian dalam konteks.',
    'dict.hideMenu': 'Klik untuk menyembunyikan teks penuh kejadian dalam konteks.',
  },
  km: {
    'languages.en': 'ភាសាអង់គ្លេស',
    'languages.am': 'អំហារិក',
    'languages.bn': 'ភាសាបង់ក្លាដែភាសា',
    'languages.ceb': 'សឺប៊ូអាណូភាសា',
    'languages.de': 'ភាសាអាឡឺម៉ង់',
    'languages.es-419': 'ភាសា​អេស្ប៉ាញ',
    'languages.fr': 'ភាសា​បារាំង',
    'languages.gu': 'ហ្កុយ៉ារាទី',
    'languages.hi': 'ហិណ្ឌូ',
    'languages.hr': 'ក្រូអាតភាសា',
    'languages.hu': 'ភាសាហុងគ្រីភាសា',
    'languages.id': 'ភាសាឥណ្ឌូនេស៊ី',
    'languages.km': 'ភាសាខ្មែរ',
    'languages.kn': 'កាណាដា',
    'languages.ko': 'ភាសា​កូរ៉េ',
    'languages.lo': 'ភាសាឡាវ',
    'languages.ml': 'ម៉ាឡាយ៉ាឡា',
    'languages.mr': 'ភាសាមារាធិ',
    'languages.ms': 'ម៉ាឡេស៊ី',
    'languages.my': 'ភាសាភូមា',
    'languages.ne': 'ភាសានេប៉ាល់',
    'languages.plt': 'ម៉ាដាហ្កាភាសា',
    'languages.pt-br': 'ព័រទុយហ្គាល់ (ប្រេស៊ីល) ភាសា',
    'languages.ru': 'ភាសារុស្ស៊ី',
    'languages.sw': 'ភាសាស្វាហ៊ីលី',
    'languages.ta': 'ភាសាតាមីល',
    'languages.th': 'ភាសាថៃ',
    'languages.tl': 'Tagalog',
    'languages.te': 'Telegu ភាសា',
    'languages.vi': 'ភាសាវៀតណាម',
    'languages.zh-hans': 'ភាសាចិន (អក្សរកាត់)',
    'languages.zh-hant': 'ភាសាចិន (អក្សរពេញ)',
    'header.home': 'ទំព័រដើម',
    'header.help': 'ជំនួយ',
    'header.project': 'គម្រោង',
    'header.contact': 'ទំនក់ទំនង',
    'help.contactSupport': 'សូមអ៊ីម៉ែលមកយើងនៅ help@youtranslate.bible សម្រាប់ជំនួយ។',
    'home.ytbProvides': 'YouTranslate.Bible ផ្ដល់នូវការដោយសេរី:',
    'home.easyToUseTool': 'ឧបករណ៍បកប្រែអនឡាញមានភាពងាយស្រួលប្រើ',
    'home.originalTextHelp': 'ជំនួយដើម្បីយល់អត្ថបទនៃព្រះគម្ពីរជាភាសាដើមរបស់ខ្លួន',
    'home.referencesAndResources': 'ធនធានឯកសារយោងនិងការបណ្តុះបណ្តាល',
    'home.computerAssistance': 'រដ្ឋនៃសិល្បៈនិងបច្ចេកវិទ្យាកុំព្យូទ័រដើម្បីជួយដល់អ្នកបកប្រែ',
    'profile.userProfile': 'ទម្រង់អ្នកប្រើ',
    'profile.language': 'ភាសា',
    switchDirection: 'ប្ដូរទិសអត្ថបទ',
    'profile.update': 'ធ្វើឱ្យទាន់សម័យ',
    'profile.languageWarning':
      'កម្មវិធីបកប្រែ Google ត្រូវបានប្រើដើម្បីបកប្រែចំណុចប្រទាក់អ្នកប្រើចូលទៅក្នុងភាសាផ្សេងពីភាសាអង់គ្លេសជាបណ្តោះអាសន្ន។ YTB ​​មានការត្រួតពិនិត្យលើភាពត្រឹមត្រូវឬភាពជឿជាក់នៃការបកប្រែនោះទេ។ នរណាម្នាក់ពឹងផ្អែកលើស្លាកបកប្រែនិងការពន្យល់ដែលទទួលបានពីកម្មវិធីបកប្រែ Google ធ្វើដូច្នេះមានហានិភ័យផ្ទាល់ខ្លួនរបស់គាត់ឬនាង។ ប្រសិនបើនរណាម្នាក់ត្រូវបានចាប់អារម្មណ៍ក្នុងការស្ម័គ្រចិត្តដើម្បីផ្តល់នូវការបកប្រែកាន់តែច្រើនមានភាពត្រឹមត្រូវនិងអាចជឿទុកចិត្តនៃចំណុចប្រទាក់អ្នកប្រើចូលទៅក្នុងភាសាផ្សេងពីអង់គ្លេស, សូមចុចលើជម្រើសទំនាក់ទំនងដើម្បីផ្ញើអ៊ីមែលទៅ GBI ។',
    'usernav.profile': 'ទម្រង់',
    'usernav.logout': 'ចាកចេញ',
    'usernav.logout.modalTitle': 'ចេញពី YouTranslate.Bible',
    'usernav.logout.modalText':
      'តើអ្នកប្រាកដថាអ្នកចង់ចេញ? ការងារដែលមិនបានរក្សាទុកទាំងអស់នឹងត្រូវបានបាត់បង់។',
    cancel: 'បោះបង់',
    delete: 'លុប',
    create: 'បង្កើត',
    update: 'ធ្វើឱ្យទាន់សម័យ',
    edit: 'កែសម្រួល',
    save: 'រក្សាទុក',
    add: 'បន្ថែម',
    translate: 'បកប្រែ',
    'more.options': 'មាន​ជម្រើស​ជា​ច្រើន​ទៀត',
    'project.create.title': 'គម្រោងបកប្រែព្រះគម្ពីរ',
    'project.create.list': 'បញ្ជីគម្រោង',
    'project.list.deleteProject': 'លុបគម្រោង?',
    'project.list.areYouSure': 'តើអ្នកប្រាកដជាចង់លុបគម្រោងនេះឬ?',
    'project.list.workWillBeDeleted': 'គម្រោងនេះនិងការងារណាមួយដែលបានភ្ជាប់នឹងត្រូវបានលុប។',
    'project.list.completion': 'បញ្ចប់:',
    'project.list.createdDate': 'កាលបរិច្ឆេទបង្កើត:',
    'project.list.beginningDate': 'ការចាប់ផ្តើមកាលបរិច្ឆេទ:',
    'project.list.targetCompletionDate': 'កាលបរិច្ឆេទបញ្ចប់គោលដៅ:',
    'project.list.description': 'សង្ខេប:',
    'project.edit': 'កែសម្រួលគម្រោង',
    'project.list.translateBible': 'បកប្រែព្រះគម្ពីរ',
    'project.list.empty': 'បញ្ជីគម្រោងការបកប្រែរបស់អ្នកគឺទទេ។',
    'project.list.myProjects': 'គម្រោងបកប្រែព្រះគម្ពីររបស់ខ្ញុំ',
    'project.list.newProject': 'គម្រោង​ថ្មី',
    'project.create.createNew': 'បង្កើតគម្រោងថ្មី',
    'project.namePlaceholder': 'បញ្ចូលឈ្មោះគម្រោង',
    'project.descriptionPlaceholder': 'បញ្ចូលការអធិប្បាយគម្រោង',
    'project.name': 'ឈ្មោះគម្រោង',
    'project.beginningDate': 'កាលបរិច្ឆេទការចាប់ផ្តើម',
    'project.targetCompletionDate': 'កាលបរិច្ឆេទបញ្ចប់គោលដៅ',
    'project.description': 'សង្ខេប',
    'project.isRTL': 'ការបកប្រែគឺជាសិទ្ធិទៅឆ្វេង',
    'project.manage-users': 'គ្រប់គ្រងអ្នកបកប្រែ',
    'project.new-user': 'អ្នកបកប្រែថ្មី',
    'project.add-user': 'បន្ថែមអ្នកបកប្រែ',
    'project.users': 'អ្នកបកប្រែគម្រោង',
    'project.read-only': 'អាច​បាន​តែ​អាន',
    'project.read&write': 'អាន​សរសេរ',
    'project.owner': 'ម្ចាស់',
    'project.translator': 'អ្នកបកប្រែ',
    'project.message.no-such-user': 'មិនមានការបកប្រែដូចមាន',
    'project.message.user-added': 'អ្នកប្រែបន្ថែមដោយជោគជ័យ',
    'project.message.user-deleted': 'អ្នកប្រែយកចេញ។',
    'project.message.only-owner-can-manage-users': 'ម្ចាស់គម្រោងអាចគ្រប់គ្រងអ្នកប្រើតែមួយគត់',
    'project.shared': 'គម្រោងសហការ',
    'general.delete-user': 'អ្នកបកប្រែលុប',
    'general.edit-user': 'កែសម្រួលអ្នកប្រើ',
    'general.name': 'ឈ្មោះ',
    'general.email': 'អ៊ីម៉ែល',
    'general.permissions': 'សិទ្ធិ',
    'general.role': 'តួនាទី',
    signin: 'ចុះឈ្មោះចូល',
    'signin.emailPlaceholder': 'អាស័យ​ដ្ឋាន​អ៊ី​ម៉េ​ល',
    'signin.passwordPlaceholder': 'ការពាក្យសម្ងាត់',
    'signin.forgotPassword': 'ភ្លេចពាក្យសម្ងាត់របស់អ្នក?',
    'signin.facebook': 'ចុះឈ្មោះជាមួយហ្វេសប៊ុក',
    'signin.google': 'ចូលដោយប្រើ Google',
    'signin.dontHaveAccount': 'មិនមានគណនីទេឬ?',
    'signin.createOne': 'បង្កើតមួយ',
    'signup.join': 'ចូលរួមជាមួយ YouTranslate.Bible',
    'signup.createAccountPrompt': 'បង្កើតគណនីដែលឥតគិតថ្លៃរបស់អ្នកដើម្បីចាប់ផ្តើម។',
    'signup.createAccount': 'បង្កើតគណនី',
    'signup.emailPlaceholder': 'អាស័យ​ដ្ឋាន​អ៊ី​ម៉េ​ល',
    'signup.passwordPlaceholder': 'ការពាក្យសម្ងាត់',
    'passwordReset.title': 'កំណត់ពាក្យសម្ងាត់',
    'passwordReset.prompt': 'ស្នើសុំអ៊ីម៉ែលមួយដោយមានការណែនាំដើម្បីកំណត់ពាក្យសម្ងាត់របស់អ្នក។',
    'passwordReset.emailPlaceholder': 'អាស័យ​ដ្ឋាន​អ៊ី​ម៉េ​ល',
    'passwordReset.requestButtonLabel': 'ស្នើសុំអ៊ីមែល',
    'passwordReset.success': 'អ៊ីម៉ែលដែលបានផ្ញើ។',
    'passwordReset.error': 'កំហុសមួយបានកើតឡើង។',
    'translation.project': 'គម្រោង:',
    'translation.backToProjectList': 'ត្រឡប់ទៅក្នុងបញ្ជីគម្រោង',
    'translation.book': 'សៀវភៅ:',
    'translation.chapter': 'ជំពូក:',
    'translation.prompt':
      'តើអ្នកប្រាកដថាអ្នកចង់ចាកចេញពីជំពូកនេះ? ការងារដែលមិនបានរក្សាទុកទាំងអស់នឹងត្រូវបានបាត់បង់។',
    'translation.prev': 'មុន',
    'translation.next': 'បន្ទាប់',
    'translation.previousChapter': 'ជំពូកមុន',
    'translation.nextChapter': 'ជំពូកបន្ទាប់',
    'navigator.book': 'សៀវភៅ:',
    'navigator.chapter': 'ជំពូក:',
    'navigator.oldTestament': 'គម្ពីរសញ្ញាចាស់',
    'navigator.newTestament': 'គម្ពីរសញ្ញាថ្មី',
    'navigator.selectBook': 'ជ្រើសសៀវភៅមួយ',
    'navigator.search': 'ស្វែងរក',
    'navigator.changeBook': 'សៀវភៅការផ្លាស់ប្តូរ',
    'navigator.changeChapter': 'ការផ្លាស់ប្តូរជំពូក',
    Genesis: 'លោកុប្បត្តិ',
    Exodus: 'និក្ខមនំ',
    Leviticus: 'លេវីវិន័យ',
    Numbers: 'លេខ',
    Deuteronomy: 'ទុតិយកថា',
    Joshua: 'លោកយ៉ូស្វេ',
    Judges: 'ចៅក្រម',
    Ruth: 'នាងរស់',
    '1 Samuel': 'សាំយូអែលទី 1',
    '2 Samuel': 'សាំយូអែលទី 2',
    '1 Kings': 'ពង្សាវតារក្សត្រទី 1',
    '2 Kings': 'ពង្សាវតារក្សត្រទី 2',
    '1 Chronicles': 'របាក្សត្រទី 1',
    '2 Chronicles': 'របាក្សត្រទី 2',
    Ezra: 'អែសរ៉ា',
    Nehemiah: 'នេហេមា',
    Esther: 'អេសធើរ',
    Job: 'ការងារ',
    Psalms: 'ទំនុកតម្កើង',
    Proverbs: 'សុភាសិត',
    Ecclesiastes: 'សាស្ដា',
    'Song of Songs': 'បទចម្រៀង',
    Isaiah: 'អេសាយ',
    Jeremiah: 'លោកយេរេមា',
    Lamentations: 'បរិទេវ',
    Ezekiel: 'អេសេគាល',
    Daniel: 'ដានីយ៉ែល',
    Hosea: 'ហូសេ',
    Joel: 'លោក Joel',
    Amos: 'អេម៉ុស',
    Obadiah: 'អូបាឌា',
    Jonah: 'យ៉ូណាស',
    Micah: 'មីកា',
    Nahum: 'ណាហ៊ុម',
    Habakkuk: 'ហាបាគុក',
    Zephaniah: 'សេផានា',
    Haggai: 'ហាកាយ',
    Zechariah: 'សាការី',
    Malachi: 'ម៉ាឡាគី',
    Matthew: 'ម៉ាថាយ',
    Mark: 'លោក Mark',
    Luke: 'លូកា',
    John: 'យ៉ូហាន',
    Acts: 'កិច្ចការ',
    Romans: 'រ៉ូម',
    '1 Corinthians': 'កូរិនថូសទី 1',
    '2 Corinthians': 'កូរិនថូសទី 2',
    Galatians: 'កាឡាទី',
    Ephesians: 'អេភេសូរ',
    Philippians: 'ភីលីព',
    Colossians: 'កូឡុស',
    '1 Thessalonians': 'ថែស្សាឡូនីចទី 1',
    '2 Thessalonians': 'ថែស្សាឡូនីចទី 2',
    '1 Timothy': 'ធីម៉ូថេទី 1',
    '2 Timothy': 'ធីម៉ូថេទី 2',
    Titus: 'ទីតុស',
    Philemon: 'ភីលេម៉ូន',
    Hebrews: 'ហេប្រឺ',
    James: 'លោកយ៉ាកុប',
    '1 Peter': 'ពេត្រុសទី 1',
    '2 Peter': 'ពេត្រុសទី 2',
    '1 John': 'យ៉ូហានទី 1',
    '2 John': 'យ៉ូហានទី 2',
    '3 John': 'យ៉ូហានទី 3',
    Jude: 'យូដាស',
    Revelation: 'វិវរណៈ',
    'resources.prompt':
      'បន្ថែមធនធានព្រះគម្ពីរបន្ថែមការយល់ធ្លុះចូលទៅក្នុងការអនុម័តដែលអ្នកកំពុងបកប្រែ។',
    'resources.add': 'បន្ថែមធនធានព្រះគម្ពីរ',
    Manuscript: 'សាត្រាស្លឹករឹត',
    Morphology: 'morphology',
    Lexicon: 'សទ្ទានុក្រម',
    'Syntactic Tree': 'ជាវាក្យសម្ព័ន្ធមែកធាង',
    'Go to project list': 'ទៅកាន់បញ្ជីគម្រោង',
    'Project not found': 'រកមិនឃើញឬគម្រោងសិទ្ធិមិនគ្រប់គ្រាន់។',
    'error.referenceDoesNotExistForResource':
      'ការបកប្រែព្រះគម្ពីរនេះមិនមានមាតិកាសម្រាប់ការអនុម័តដែលបានជ្រើស។',
    'references.addAnother': 'បន្ថែមការបកប្រែផ្សេងទៀត',
    'references.referencePrompt':
      'បន្ថែមការបកប្រែព្រះគម្ពីរផ្សេងទៀតសម្រាប់ការយល់ដឹងទូលំទូលាយបន្ថែមទៀត។',
    'references.add': 'បន្ថែមបកប្រែព្រះគម្ពីរ',
    'references.licensing': 'អាជ្ញាបណ្ណព',
    'references.licensingEmpty': 'គ្មានត្រូវបានរកឃើញអាជ្ញាប័ណ្ណពសម្រាប់ធាតុនេះ។',
    'translation.complete': 'បានបញ្ចប់ការបកប្រែ',
    Alignment: 'តម្រឹម',
    Share: 'ចែករំលែក',
    'Your Translation': 'ការបកប្រែរបស់អ្នក',
    'Enter your translation': 'បញ្ចូលការបកប្រែរបស់អ្នក',
    OK: 'យល់ព្រម',
    'error.verseTranslationEmpty': 'អ្នកមិនអាចចាកចេញពីមាតិកាបកប្រែលុប។',
    'translation.hide.completed': 'លាក់បានបញ្ចប់',
    alignment: 'តម្រឹម',
    'alignment.verify': 'ផ្ទៀងផ្ទាត់និងបិទ។',
    'alignment.close': 'បិទដោយគ្មានការផ្ទៀងផ្ទាត់',
    'alignment.backToEditor': 'ត្រលប់ទៅកម្មវិធីនិពន្ធ',
    'alignment.error':
      'មាន​អ្វីមួយ​មិន​ប្រក្រតី។ ប្រសិនបើមានការតស៊ូបញ្ហានេះ, សូមទាក់ទងក្រុម YouTranslate.Bible នេះ។',
    'alignment.add': 'បន្ថែមតំណ',
    'alignment.delete': 'យកតំណ',
    'alignment.clear': 'ជម្រះការជ្រើសរើស',
    'alignment.reverse': 'បង្ហាញបញ្ច្រាស',
    'alignment.removeOrClear': 'យកតំណដែលបានជ្រើសឬជ្រើសច្បាស់លាស់។',
    'alignment.addOrRemove': 'បង្កើតតំណថ្មីឬលុបមួយមុន។',
    'alignment.selectAndAdd': 'ជ្រើសផ្នែកដែលត្រូវគ្នានឹងតំណ។',
    'error.mustBeSignedToMakeChanges': 'អ្នកត្រូវតែចូលទៅកាន់ការផ្លាស់ប្តូរធ្វើឱ្យនៅ។',
    'error.mustBeSignedToTranslateBible': 'អ្នកត្រូវតែចូលក្នុងដើម្បីបកប្រែគម្ពីរ។',
    checking: 'កំពុងពិនិត្យមើល',
    'verseEditor.unexpectedError': 'កំហុស​ដែល​មិន​រំពឹង​ទុក​បាន​កើត​ឡើង​។',
    verses: 'ដោយខ',
    words: 'ដោយពាក្យ',
    'leftPanel.name': 'អត្ថបទដើមនៃព្រះគម្ពីរ',
    'rightPanel.name': 'ការបកប្រែព្រះគម្ពីរ',
    'bottomPanel.name': 'ធនធានសម្រាប់ព្រះគម្ពីរ',
    revisionHistory: 'ពិនិត្យ​ឡើង​វិញ​ប្រវត្តិសាស្ត្រ',
    'syntax.adv': 'Adverbial កែប្រែ',
    'syntax.adjp': 'ឃ្លា Adjectival',
    'syntax.advp': 'ឃ្លាគុណកិរិយា',
    'syntax.cl': 'ប្រការ',
    'syntax.conj': 'ការ​ភ្ជាប់',
    'syntax.cjp': 'ការ​ភ្ជាប់',
    'syntax.det': 'ការប្តេជ្ញាចិត្ត',
    'syntax.ijp': 'ឧទានសព្ទ',
    'syntax.nump': 'ឃ្លាលេខ',
    'syntax.np': 'ឃ្លានួន',
    'syntax.o': 'វត្ថុដោយផ្ទាល់',
    'syntax.o2': 'វត្ថុលើកទីពីរ',
    'syntax.oc': 'បំពេញវត្ថុ',
    'syntax.om': 'សញ្ញាសម្គាល់វត្ថុ',
    'syntax.io': 'វត្ថុប្រយោល',
    'syntax.p': 'មិនមែនជាពាក្យសំដី predicate',
    'syntax.pp': 'ឃ្លា​ធ្នាក់',
    'syntax.prep': 'ធ្នាក់',
    'syntax.ptcl': 'ភាគល្អិត',
    'syntax.rel': 'ភាគល្អិតដែលទាក់ទង',
    'syntax.s': 'ប្រធានបទ',
    'syntax.v': 'កិរិយា',
    'syntax.vc': 'ភ្ជាប់កិរិយា',
    'syntax.adjp.description': 'ឃ្លាដែលកែប្រែនាមរឺសព្វនាម',
    'syntax.adv.description':
      'កែប្រែកិរិយាស័ព្ទដែលបានកំណត់ជាធម្មតាបន្ថែមទៀតលក្ខណៈពេលវេលាកន្លែងប្រេកង់កម្រិត, ល ...',
    'syntax.advp.description':
      'ឃ្លាកែប្រែកិរិយាស័ព្ទ, ជាធម្មតាបន្ថែមទៀតកំណត់លក្ខណៈ, ពេលវេលា, កន្លែង, ប្រេកង់, សញ្ញាបត្រល',
    'syntax.cl.description': 'ក្រុមនៃពាក្យមួយដែលធ្វើឱ្យការអះអាងអំពីប្រធានបទនេះ។',
    'syntax.conj.description': 'ភ្ជាប់ពាក្យ, ឃ្លា, និងខ។',
    'syntax.cjp.description': 'ភ្ជាប់ពាក្យ, ឃ្លា, និងខ។',
    'syntax.det.description': 'បញ្ជាក់, កំណត់ឬកម្រិតឃ្លានាមមួយ។',
    'syntax.ijp.description': 'ឧទានឬកន្សោមរំពេចនៃអារម្មណ៍។',
    'syntax.nump.description': 'ឃ្លាដែលរៀបរាប់អំពីបរិមាណលេខ',
    'syntax.np.description': 'ឃ្លាដែលមាននាមជាប្រធានរបស់ខ្លួនឬមុខងារដូចជានាមមួយនោះ។',
    'syntax.o.description':
      'ដែលត្រូវបានប៉ះពាល់ដោយផ្ទាល់ដែលបណ្តាល, ឬផលិតដោយសកម្មភាពរបស់កិរិយាស័ព្ទមួយ។',
    'syntax.o2.description':
      'ដែលត្រូវបានប៉ះពាល់ដោយផ្ទាល់ដែលបណ្តាល, ឬផលិតដោយសកម្មភាពរបស់កិរិយាស័ព្ទមួយ។',
    'syntax.oc.description': 'ធ្វើឱ្យការអះអាងអំពីវត្ថុដោយផ្ទាល់មួយ។',
    'syntax.om.description': 'បំណែកដែលបង្ហាញវត្ថុដោយផ្ទាល់មួយ។',
    'syntax.io.description':
      'វត្ថុដែលត្រូវបានរងផលប៉ះពាល់ដោយប្រយោលដោយសកម្មភាពមួយនៃសកម្មភាពជាញឹកញាប់អ្នកទទួលកិរិយាស័ព្ទមួយនេះ។',
    'syntax.p.description':
      'កំណត់ឬការរៀបរាប់អំពីប្រធានបទនេះជាញឹកញាប់ (ប៉ុន្តែមិនជានិច្ច) បានកើតឡើងរួមគ្នាជាមួយកិរិយាសព្ទភ្ជាប់ទំនាក់ទំនងរវាង។',
    'syntax.pp.description': 'ការកែប្រែឃ្លាដែលមានធ្នាក់មួយនិងវត្ថុរបស់ខ្លួន។',
    'syntax.prep.description':
      'បង្ហាញថាទំនាក់ទំនងនៃវត្ថុនៃធ្នាក់ដោយពាក្យផ្សេងទៀតដែលជាញឹកញាប់បានបង្ហាញថាដែលជាកន្លែងដែលឬនៅពេលដែល។',
    'syntax.ptcl.description':
      'សមាជិកម្នាក់មកពីក្រុមចម្រុះនៃពាក្យមុខងារដែលមិនមែនជារបស់ណាមួយនៃថ្នាក់ពាក្យវេយ្យាករណ៍នេះ inflected',
    'syntax.rel.description': 'បំណែកដែលបង្ហាញឃ្លាដែលទាក់ទង។',
    'syntax.s.description': 'ជាផ្នែកមួយនៃឃ្លាដែលនៅសល់នៃឃ្លានេះបានធ្វើឱ្យការអះអាងអំពីការនេះ។',
    'syntax.v.description':
      'បញ្ជាក់អ្វីមួយអំពីប្រធានបទនៃឃ្លានេះនិងអាចបង្ហាញពីសកម្មភាព, ព្រឹត្តិការណ៍, ឬស្ថានភាព។',
    'syntax.vc.description': 'ភ្ជាប់ប្រធានបទនៃឃ្លាដើម្បីបង្គ្រប់ប្រធានបទមួយ។',
    'general.close': 'បិទ',
    'general.action': 'សកម្មភាព',
    'general.select': 'ជ្រើស ...',
    'general.back': 'ត្រលប់ក្រោយ',
    'revisionHistory.noData': 'មិនមានប្រវត្តិការពិនិត្យឡើងវិញចំពោះការខនេះ។',
    'revisionHistory.translation': 'ការបកប្រែ',
    'revisionHistory.lastChanged': 'ចុងក្រោយបានប្តូរ',
    'sourceText.view': 'ចុចដើម្បីមើលអត្ថបទប្រភព',
    'sourceText.viewStructure': 'ចុចដើម្បីមើលអត្ថបទប្រភពរចនាសម្ព័ន',
    'error.projectIdIsRequired': 'លេខសម្គាល់គម្រោងគឺត្រូវបានទាមទារ។',
    'error.searchTermIsRequired': 'ពាក្យស្វែងរកមិនអាចលុប។',
    'error.bookIdIsRequired': 'លេខសម្គាល់សៀវភៅត្រូវបានទាមទារឱ្យរក្សាទុកឈ្មោះសៀវភៅ។',
    'error.bookNameCannotBeEmpty': 'ឈ្មោះសៀវភៅមិនអាចទទេ។',
    'error.signInIsRequiredToAccessProject': 'អ្នកត្រូវតែចូលក្នុងដើម្បីចូលដំណើរការគម្រោងរបស់អ្នក។',
    error: 'កំហុសក្នុងការ',
    'error.doNotHavePermissionAccessProject': 'អ្នកមិនមានសិទ្ធិដើម្បីចូលដំណើរការគម្រោងនេះ។',
    'error.unauthorized': 'ការចូលដំណើរការគ្មានការអនុញ្ញាតឬការចូលដំណើរការមិនត្រឹមត្រូវសញ្ញាសម្ងាត់។',
    'error.tokenExpired': 'ថូខឹនរបស់អ្នកបានផុតកំណត់។ សូមឡើងវិញចូល។',
    'error.invalidApiUrl': 'url API ដែលមិនត្រឹមត្រូវ។',
    'error.badRequestInvalidJson': 'សំណើអាក្រក់។ មិនត្រឹមត្រូវ JSON ប៉ុណ្ណោះ។',
    'error.windowIsNotDefined': 'បង្អួចគឺមិនត្រូវបានកំណត់។',
    'error.unexpectedError':
      'កំហុស​ដែល​មិន​រំពឹង​ទុក​បាន​កើត​ឡើង​។ សូមបិទបង្អួចនេះហើយព្យាយាមម្ដងទៀត។',
    'error.invalidDataType': 'ប្រភេទទិន្នន័យមិនត្រឹមត្រូវ។',
    'export.data': 'នាំចេញទិន្នន័យ',
    more: 'ច្រើនទៀត',
    'export.to.rtf': 'ការនាំចេញទៅកាន់ RTF',
    'export.to.usfm': 'ការនាំចេញទៅកាន់ USFM',
    'export.titlePreparingData': 'យើងកំពុងរៀបចំទិន្នន័យបកប្រែព្រះគម្ពីររបស់អ្នក។',
    'export.doNotClose':
      'ដំណើរការនេះអាចចំណាយពេលមួយ, សូមមិនបិទបង្អួចនេះហើយអ្នកនឹងត្រូវបានជូនដំណឹងនៅពេលដែលវាជាការត្រៀមខ្លួនជាស្រេចដើម្បីទាញយក។',
    'export.desc': 'អ្នកអាចនាំចេញនិងទាញយកទិន្នន័យការបកប្រែរបស់អ្នកដោយចុចប៊ូតុងខាងក្រោម។',
    'export.titleOfReady2Download': 'ទិន្នន័យដែលបានបកប្រែព្រះគម្ពីររបស់អ្នកគឺត្រៀមខ្លួនជាស្រេច។',
    'export.textOfReady2Download':
      'សូមចុចប៊ូតុងទាញយកខាងក្រោមដើម្បីទាញយកការបកប្រែព្រះគម្ពីររបស់អ្នក។',
    'export.statistics': 'ស្ថិតិ',
    'export.book': 'ឈ្មោះសៀវភៅ',
    'export.translatedVerses': 'ខគម្ពីរដែលបានបកប្រែ',
    'export.exportedVerses': 'ខគម្ពីរដែលបាននាំចេញ',
    'export.howDoUWant2Export': 'តើអ្នកចង់នាំចេញទិន្នន័យយ៉ាងដូចម្តេច?',
    'export.whichBooks': 'តើសៀវភៅ (s បាន) ដែលអ្នកចង់នាំចេញ?',
    'export.byBooks': 'ដោយសៀវភៅ',
    'export.byChapters': 'ដោយជំពូក',
    'export.byVerses': 'ដោយខគម្ពីរ',
    'export.booksInOt': 'គម្ពីរសញ្ញាចាស់',
    'export.booksInNt': 'គម្ពីរសញ្ញាថ្មី',
    'export.booksInBible': 'ព្រះគម្ពីរទាំងស្រុង',
    'export.booksInNone': 'គ្មាន',
    'export.selectRangeOfChapters': 'ជួរនៃជំពូក',
    'export.selectRangeOfVerses': 'ជួរនៃខ',
    'export.selectChapter': 'ជ្រើសជំពូក',
    'export.exportTypeIsRequired': 'អ្នកមិនបានជ្រើសនៅឡើយទេអំពីតើអ្នកចង់នាំចេញពីរបៀបទិន្នន័យ?',
    'export.haveNotYetSelectedBooks': 'អ្នកមិនបានជ្រើសសៀវភៅណាមួយនៅឡើយទេ។',
    'error.selectABook': 'អ្នកត្រូវតែជ្រើសសៀវភៅ។',
    'error.invalidBookId': 'លេខសម្គាល់សៀវភៅមិនត្រឹមត្រូវ។',
    'error.selectAChapter': 'អ្នកត្រូវតែជ្រើសជំពូកមួយ។',
    'error.invalidChapterRange': 'អ្នកត្រូវតែជ្រើសជួរជំពូកត្រឹមត្រូវ។',
    'error.invalidVerseRange': 'អ្នកត្រូវតែជ្រើសជួរខត្រឹមត្រូវ។',
    'error.invalidExportType': 'ប្រភេទនៃការនាំចេញមិនត្រឹមត្រូវ។',
    startover: 'សារ​ឡើងវិញ',
    download: 'ទាញយក',
    'download.all': 'ទាញយកឯកសារទាំងអស់',
    'contact.header': 'ទាក់ទង​មក​ពួក​យើង',
    'contact.returnAddress': 'អាសយដ្ឋានត្រឡប់',
    'contact.returnAddressPlaceholder': 'ដែលជាកន្លែងដែលយើងអាចឈានទៅដល់អ្នក?',
    'contact.subject': 'ប្រធានបទ',
    'contact.subjectPlaceholder': 'តើអ្នកត្រូវការជួយជាមួយនឹងអ្វី?',
    'contact.message': 'សារ',
    'contact.messagePlaceholder': 'តើយើងអាចជួយបានទេ?',
    'contact.sendMessage': 'ផ្ញើ​សារ',
    'contact.success': 'សារត្រូវបានផ្ញើដោយជោគជ័យ។',
    'contact.failure': 'មានបញ្ហាក្នុងការផ្ញើសារនេះ។ សូមទាក់ទង help@globalbibleinitiative.org ។',
    selectInterlinear: 'ចុចដើម្បីផ្លាស់ប្តូរអត្ថបទ Interlinear',
    'warning.screenTooSmall':
      'សូមប្តូរទៅជាគុណភាពបង្ហាញអេក្រង់ខ្ពស់ (អប្បបរមា 1024px ធំទូលាយ) ឬសូមពង្រីកបង្អួចកម្មវិធីរុករករបស់អ្នក។',
    'label.dictionary': 'មាលីសព្ទស្វែងរក',
    'dict.search.placeholder': 'បញ្ចូលពាក្យ (s បាន)',
    'tooltip.manuscript': 'សាត្រាស្លឹករឹត',
    'tooltip.strongsx': 'លេខខ្លាំងរបស់',
    'tooltip.translation': 'ការបកប្រែ',
    'tooltip.english': 'ភាសាអង់គ្លេស',
    'title.howToSearch': 'តើធ្វើដូចម្តេចដើម្បីប្រើការស្វែងរកមាលីសព្ទ',
    'desc.howToSearch':
      'បញ្ចូលពាក្យ (s) បានមកពីភាសាក្រិចឬភាសាហេប្រឺពាក្យ (s) បានពីការបកប្រែរបស់អ្នក, ឬលេខខ្លាំង (s បាន) ។ ឬចុចលើពាក្យភាសាក្រិចឬហេប្រ៊ូនៅក្នុងបន្ទះនៃ "ការបកប្រែរបស់អ្នក" ឬទស្សនៈតម្រឹមសម្រាប់ជម្រើសស្វែងរក។',
    'dict.noDataToDisplay': 'មិនមានទិន្នន័យដែលផ្គូផ្គងលក្ខណៈវិនិច្ឆ័យស្វែងរករបស់អ្នក។',
    'title.searchTermTooMany': 'អ្នកបានបញ្ចូលពាក្យគន្លឹះជាច្រើនផងដែរ',
    'desc.searchTermTooMany': 'អ្នកអាចស្វែងរកបានរហូតដល់ទៅ 8 ពាក្យគន្លឹះរាល់ពេល។',
    'title.searchDictError': 'កំហុស​ដែល​មិន​រំពឹង​ទុក​បាន​កើត​ឡើង​។',
    'desc.searchDictError': 'សូមព្យាយាមម្ដងទៀតនៅពេលក្រោយឬការគាំទ្ររបស់អតិថិជនទំនាក់ទំនង',
    'alignment.saveAndEditAlignment': 'រក្សាទុកនិងការតម្រឹមអត្ថបទថ្មី',
    'alignment.reviewAlignment': 'តម្រឹមអត្ថបទពិនិត្យឡើងវិញ',
    'dict.searchLemma': 'ចុចដើម្បីស្វែងរកពាក្យដើម',
    'dict.searchTranslation': 'ចុចដើម្បីស្វែងរកពាក្យបកប្រែ',
    'dict.searchStrongsX': 'ចុចដើម្បីស្វែងរកលេខខ្លាំង',
    'dict.showMenu': 'ចុចដើម្បីបង្ហាញអត្ថបទពេញលេញនៃការកើតឡើងក្នុងបរិបទ។',
    'dict.hideMenu': 'ចុចដើម្បីលាក់អត្ថបទពេញលេញនៃការកើតឡើងក្នុងបរិបទ។',
  },
  kn: {
    'languages.en': 'ಆಂಗ್ಲ',
    'languages.am': 'ಅಂಹರಿಕ್',
    'languages.bn': 'ಬಂಗಾಳಿ ಭಾಷಾ',
    'languages.ceb': 'ಸೆಬುಆನೋ ಭಾಷಾ',
    'languages.de': 'ಜರ್ಮನ್ ಭಾಷೆ',
    'languages.es-419': 'ಸ್ಪ್ಯಾನಿಷ್ ಭಾಷೆ',
    'languages.fr': 'ಫ್ರೆಂಚ್ ಭಾಷೆ',
    'languages.gu': 'ಗುಜರಾತಿ',
    'languages.hi': 'ಹಿಂದಿ',
    'languages.hr': 'ಕ್ರೊಯೇಶಿಯನ್ ಭಾಷಾ',
    'languages.hu': 'ಹಂಗೇರಿಯಾ ಭಾಷೆಯ',
    'languages.id': 'ಇಂಡೋನೇಶಿಯನ್ ಭಾಷಾ',
    'languages.km': 'ಖಮೇರ್',
    'languages.kn': 'ಕನ್ನಡ',
    'languages.ko': 'ಕೋರಿಯನ್ ಭಾಷೆ',
    'languages.lo': 'ಲಾವೋಷಿಯನ್ ಭಾಷಾ',
    'languages.ml': 'ಮಲಯಾಳಂ',
    'languages.mr': 'ಮರಾಠಿ',
    'languages.ms': 'ಮಲೇಷಿಯನ್',
    'languages.my': 'ಬರ್ಮೀಸ್ ಭಾಷಾ',
    'languages.ne': 'ನೇಪಾಳಿ ಭಾಷೆಯಲ್ಲಿ',
    'languages.plt': 'ಮಲಗಾಸಿ ಭಾಷಾ',
    'languages.pt-br': 'ಪೋರ್ಚುಗೀಸ್ (ಬ್ರೆಜಿಲ್) ಭಾಷಾ',
    'languages.ru': 'ರಷ್ಯಾದ ಭಾಷಾ',
    'languages.sw': 'ಸ್ವಹಿಲಿ',
    'languages.ta': 'ತಮಿಳು ಭಾಷಾ',
    'languages.th': 'ಥಾಯ್ ಭಾಷಾ',
    'languages.tl': 'ಟ್ಯಾಗಲೋಗ್',
    'languages.te': 'Telegu ಭಾಷಾ',
    'languages.vi': 'ವಿಯೇಟ್ನಾಮೀಸ್ ಭಾಷಾ',
    'languages.zh-hans': 'ಚೀನೀ ಭಾಷಾ (ಸರಳೀಕೃತ)',
    'languages.zh-hant': 'ಚೀನೀ ಭಾಷಾ (ಸಾಂಪ್ರದಾಯಿಕ)',
    'header.home': 'ಮುಖಪುಟ',
    'header.help': 'ಸಹಾಯ',
    'header.project': 'ಪ್ರಾಜೆಕ್ಟ್',
    'header.contact': 'ಸಂಪರ್ಕಿಸಿ',
    'help.contactSupport': 'ಸಹಾಯಕ್ಕಾಗಿ help@youtranslate.bible ನಮಗೆ ಇಮೇಲ್ ಮಾಡಿ.',
    'home.ytbProvides': 'YouTranslate.Bible ಮುಕ್ತವಾಗಿ ಒದಗಿಸುತ್ತದೆ:',
    'home.easyToUseTool': 'ಸುಲಭ ಯಾ ಬಳಸಲು ಆನ್ಲೈನ್ ಅನುವಾದ ಉಪಕರಣವನ್ನು',
    'home.originalTextHelp': 'ಅದರ ಮೂಲ ಭಾಷೆಯಲ್ಲಿಯೇ ಬೈಬಲ್ ಮೂಲಗ್ರಂಥದ ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಸಹಾಯ',
    'home.referencesAndResources': 'ರೆಫರೆನ್ಸ್ ಮತ್ತು ತರಬೇತಿ ಸಂಪನ್ಮೂಲಗಳನ್ನು',
    'home.computerAssistance': 'ರಾಜ್ಯ ಯಾ ಕಲೆ ಕಂಪ್ಯೂಟರ್ ತಂತ್ರಜ್ಞಾನ ಅನುವಾದಕರು ನೆರವಾಗಲು',
    'profile.userProfile': 'ಬಳಕೆದಾರ ಪ್ರೊಫೈಲ್',
    'profile.language': 'ಭಾಷಾ',
    switchDirection: 'ಸ್ವಿಚ್ ಪಠ್ಯ ನಿರ್ದೇಶನ',
    'profile.update': 'ನವೀಕರಣ',
    'profile.languageWarning':
      'Google Translate ತಾತ್ಕಾಲಿಕವಾಗಿ ಇಂಗ್ಲೀಷ್ ಬೇರೆ ಭಾಷೆಗಳಲ್ಲಿ ಬಳಕೆದಾರ ಇಂಟರ್ಫೇಸ್ ಭಾಷಾಂತರಿಸಲು ಬಳಸಲಾಯಿತು. YTB ​​ನಿಖರತೆ ಅಥವಾ ಭಾಷಾಂತರ ವಿಶ್ವಾಸಾರ್ಹತೆ ಮೇಲೆ ನಿಯಂತ್ರಣ ಹೊಂದಿದೆ. Google ನಿಂದ ಪಡೆದ ಅನುವಾದ ಲೇಬಲ್ಗಳು ಮತ್ತು ವಿವರಣೆಗಳು ಮೇಲೆ ಭರವಸೆ ಯಾರಾದರೂ ಭಾಷಾಂತರಿಸಿ ತನ್ನ ಸ್ವಂತ ಅಪಾಯ ಮಾಡುತ್ತದೆ. ಯಾರಾದರೂ ಇಂಗ್ಲೀಷ್ ಬೇರೆ ಭಾಷೆಗಳಲ್ಲಿ ಬಳಕೆದಾರ ಇಂಟರ್ಫೇಸ್ ಹೆಚ್ಚು ನಿಖರ ಮತ್ತು ವಿಶ್ವಾಸಾರ್ಹ ಅನುವಾದ ನೀಡಲು ಸ್ವಯಂ ಸೇವಕರಿಗೆ ಆಸಕ್ತಿ ಇದ್ದರೆ, GBI ಇಮೇಲ್ ಕಳುಹಿಸಲು ಸಂಪರ್ಕಿಸಿ ಆಯ್ಕೆಯನ್ನು ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡಿ.',
    'usernav.profile': 'ಪ್ರೊಫೈಲ್',
    'usernav.logout': 'ಲಾಗ್ ಔಟ್',
    'usernav.logout.modalTitle': 'YouTranslate.Bible ಆಫ್ ಲಾಗ್ಔಟ್',
    'usernav.logout.modalText':
      'ನೀವು ನಿರ್ಗಮಿಸಬೇಕಾಗುತ್ತದೆ ಬಯಸಿದ್ದೀರಾ? ಎಲ್ಲಾ ಉಳಿಸದ ಕೆಲಸವನ್ನು ಕಳೆದುಹೋಗುತ್ತವೆ.',
    cancel: 'ರದ್ದು',
    delete: 'ಅಳಿಸು',
    create: 'ರಚಿಸಿ',
    update: 'ನವೀಕರಣ',
    edit: 'ತಿದ್ದು',
    save: 'ಉಳಿಸಿ',
    add: 'ಸೇರಿಸಿ',
    translate: 'ಅನುವಾದಿಸು',
    'more.options': 'ಇನ್ನಷ್ಟು ಆಯ್ಕೆಗಳು',
    'project.create.title': 'ಬೈಬಲ್ ಅನುವಾದ ಪ್ರಾಜೆಕ್ಟ್',
    'project.create.list': 'ಪ್ರಾಜೆಕ್ಟ್ ಪಟ್ಟಿ',
    'project.list.deleteProject': 'ಪ್ರಾಜೆಕ್ಟ್ ಅಳಿಸು?',
    'project.list.areYouSure': 'ಈ ಪ್ರಾಜೆಕ್ಟ್ ಅನ್ನು ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿರುವಿರಾ?',
    'project.list.workWillBeDeleted': 'ಯೋಜನೆ ಮತ್ತು ಸಂಬಂಧಿಸಿದ ಯಾವುದೇ ಕೆಲಸ ಅಳಿಸಲ್ಪಡುತ್ತದೆ.',
    'project.list.completion': 'ಕಾಮಗಾರಿಯು:',
    'project.list.createdDate': 'ರಚಿಸಿದ ದಿನಾಂಕ:',
    'project.list.beginningDate': 'ಆರಂಭದಲ್ಲಿ ದಿನಾಂಕ:',
    'project.list.targetCompletionDate': 'ಟಾರ್ಗೆಟ್ ಕಾಮಗಾರಿಯು ದಿನಾಂಕ:',
    'project.list.description': 'ವಿವರಣೆ:',
    'project.edit': 'ಯೋಜನೆಯನ್ನು ಸಂಪಾದಿಸು',
    'project.list.translateBible': 'ಭಾಷಾಂತರಿಸಿ ಬೈಬಲ್',
    'project.list.empty': 'ನಿಮ್ಮ ಅನುವಾದ ಯೋಜನೆಯ ಪಟ್ಟಿ ಖಾಲಿಯಾಗಿದೆ.',
    'project.list.myProjects': 'ನನ್ನ ಬೈಬಲ್ ಅನುವಾದ ಯೋಜನೆಗಳು',
    'project.list.newProject': 'ಹೊಸ ಯೋಜನೆ',
    'project.create.createNew': 'ಹೊಸ ಪ್ರಾಜೆಕ್ಟ್ ರಚಿಸಿ',
    'project.namePlaceholder': 'ಯೋಜನೆಯ ಹೆಸರನ್ನು ನಮೂದಿಸಿ',
    'project.descriptionPlaceholder': 'ಯೋಜನೆಯ ವಿವರಣೆ ನಮೂದಿಸಿ',
    'project.name': 'ಯೋಜನೆಯ ಹೆಸರು',
    'project.beginningDate': 'ಆರಂಭದಲ್ಲಿ ದಿನಾಂಕ',
    'project.targetCompletionDate': 'ಟಾರ್ಗೆಟ್ ಕಾಮಗಾರಿಯು ದಿನಾಂಕ',
    'project.description': 'ವಿವರಣೆ',
    'project.isRTL': 'ಅನುವಾದ ಬಲದಿಂದ ಬಿಟ್ಟು',
    'project.manage-users': 'ಅನುವಾದಕರು ನಿರ್ವಹಿಸಿ',
    'project.new-user': 'ಹೊಸ ಭಾಷಾಂತರಕಾರ',
    'project.add-user': 'ಭಾಷಾಂತರಕಾರ ಸೇರಿಸು',
    'project.users': 'ಪ್ರಾಜೆಕ್ಟ್ ಅನುವಾದಕರು',
    'project.read-only': 'ಓದಲು ಮಾತ್ರ',
    'project.read&write': 'ಓದು ಬರೆ',
    'project.owner': 'ಮಾಲೀಕ',
    'project.translator': 'ಅನುವಾದಕ',
    'project.message.no-such-user': 'ಅಂತಹ ಭಾಷಾಂತರಕಾರ ಅಸ್ತಿತ್ವದಲ್ಲಿದೆ',
    'project.message.user-added': 'ಅನುವಾದಕ ಯಶಸ್ವಿಯಾಗಿ ಸೇರಿಸಲಾಗಿದೆ',
    'project.message.user-deleted': 'ಅನುವಾದಕ ತೆಗೆದುಹಾಕಲಾಗಿದೆ.',
    'project.message.only-owner-can-manage-users':
      'ಮಾತ್ರ ಯೋಜನೆಯ ಮಾಲೀಕರು ಬಳಕೆದಾರರನ್ನು ನಿರ್ವಹಿಸಬಹುದು',
    'project.shared': 'ಸಹಯೋಗದ ಯೋಜನೆಯಾಗಿದೆ',
    'general.delete-user': 'ಅಳಿಸು ಭಾಷಾಂತರಕಾರ',
    'general.edit-user': 'ಬಳಕೆದಾರರನ್ನು ಸಂಪಾದಿಸಿ',
    'general.name': 'ಹೆಸರು',
    'general.email': 'ಮೇಲ್',
    'general.permissions': 'ಅನುಮತಿಗಳು',
    'general.role': 'ಪಾತ್ರ',
    signin: 'ಸೈನ್ ಇನ್',
    'signin.emailPlaceholder': 'ಇಮೇಲ್ ವಿಳಾಸ',
    'signin.passwordPlaceholder': 'ಗುಪ್ತಪದ',
    'signin.forgotPassword': 'ನಿಮ್ಮ ಪಾಸ್ವರ್ಡ್ ಮರೆತಿರುವಿರಾ?',
    'signin.facebook': 'ಫೇಸ್ಬುಕ್ ಜೊತೆ ಸೈನ್ ಇನ್',
    'signin.google': 'Google ನೊಂದಿಗೆ ಸೈನ್ ಇನ್',
    'signin.dontHaveAccount': 'ಒಂದು ಖಾತೆಯನ್ನು ಹೊಂದಿಲ್ಲ?',
    'signin.createOne': 'ಒಂದನ್ನು ತಯಾರಿಸು',
    'signup.join': 'YouTranslate.Bible ಸೇರಿ',
    'signup.createAccountPrompt': 'ಆರಂಭಿಸಲು ನಿಮ್ಮ ಉಚಿತ ಖಾತೆಯನ್ನು ರಚಿಸಿ.',
    'signup.createAccount': 'ಖಾತೆ ತೆರೆ',
    'signup.emailPlaceholder': 'ಇಮೇಲ್ ವಿಳಾಸ',
    'signup.passwordPlaceholder': 'ಗುಪ್ತಪದ',
    'passwordReset.title': 'ಗುಪ್ತಪದ ಮರುಹೊಂದಿಸಿ',
    'passwordReset.prompt': 'ನಿಮ್ಮ ಪಾಸ್ವರ್ಡ್ ಮರುಹೊಂದಿಸಲು ಸೂಚನೆಗಳೊಂದಿಗೆ ಇಮೇಲ್ ವಿನಂತಿಸಿ.',
    'passwordReset.emailPlaceholder': 'ಇಮೇಲ್ ವಿಳಾಸ',
    'passwordReset.requestButtonLabel': 'ವಿನಂತಿ ಇಮೇಲ್',
    'passwordReset.success': 'ಇಮೇಲ್ ಕಳುಹಿಸಲಾಗಿದೆ.',
    'passwordReset.error': 'ದೋಷ ಕಂಡುಬಂದಿದೆ.',
    'translation.project': 'ಯೋಜನೆ:',
    'translation.backToProjectList': 'ಯೋಜನೆಯ ಪಟ್ಟಿಗೆ ಮತ್ತೆ',
    'translation.book': 'ಪುಸ್ತಕ:',
    'translation.chapter': 'ಅಧ್ಯಾಯ:',
    'translation.prompt':
      'ಈ ಅಧ್ಯಾಯದಲ್ಲಿ ಬಿಡಲು ನೀವು ಬಯಸುವಿರಾ? ಎಲ್ಲಾ ಉಳಿಸದ ಕೆಲಸವನ್ನು ಕಳೆದುಹೋಗುತ್ತವೆ.',
    'translation.prev': 'ಹಿಂದಿನದು',
    'translation.next': 'ಮುಂದಿನ',
    'translation.previousChapter': 'ಹಿಂದಿನ ಅಧ್ಯಾಯ',
    'translation.nextChapter': 'ಮುಂದಿನ ಅಧ್ಯಾಯದಲ್ಲಿ',
    'navigator.book': 'ಪುಸ್ತಕ:',
    'navigator.chapter': 'ಅಧ್ಯಾಯ:',
    'navigator.oldTestament': 'ಹಳೆಯ ಸಾಕ್ಷಿ',
    'navigator.newTestament': 'ಹೊಸ ಒಡಂಬಡಿಕೆಯಲ್ಲಿ',
    'navigator.selectBook': 'ಪುಸ್ತಕ ಆಯ್ಕೆ',
    'navigator.search': 'ಹುಡುಕಿ Kannada',
    'navigator.changeBook': 'ಚೇಂಜ್ ಪುಸ್ತಕದ',
    'navigator.changeChapter': 'ಚೇಂಜ್ ಅಧ್ಯಾಯ',
    Genesis: 'ಜೆನೆಸಿಸ್',
    Exodus: 'ಎಕ್ಸೋಡಸ್',
    Leviticus: 'ಲೆವಿಟಿಕಸ್',
    Numbers: 'ಸಂಖ್ಯೆಗಳು',
    Deuteronomy: 'ಧರ್ಮೋಪದೇಶಕಾಂಡ',
    Joshua: 'ಜೋಶುವಾ',
    Judges: 'ನ್ಯಾಯಾಧೀಶರು',
    Ruth: 'ರುತ್',
    '1 Samuel': '1 ಸ್ಯಾಮ್ಯುಯೆಲ್',
    '2 Samuel': '2 ಸ್ಯಾಮ್ಯುಯೆಲ್',
    '1 Kings': '1 ಅರಸುಗಳು',
    '2 Kings': '2 ಅರಸುಗಳು',
    '1 Chronicles': '1 ಕ್ರಾನಿಕಲ್ಸ್',
    '2 Chronicles': '2 ಕ್ರಾನಿಕಲ್ಸ್',
    Ezra: 'ಎಜ್ರಾ',
    Nehemiah: 'ನೆಹೆಮಿಯಾ',
    Esther: 'ಎಸ್ತರ್',
    Job: 'ಜಾಬ್',
    Psalms: 'ಪ್ಸಾಮ್ಸ್',
    Proverbs: 'ನಾಣ್ಣುಡಿ',
    Ecclesiastes: 'ಪ್ರಸಂಗಿ',
    'Song of Songs': 'ಸಾಂಗ್ಸ್ ಆಫ್ ಸಾಂಗ್',
    Isaiah: 'ಯೆಶಾಯ',
    Jeremiah: 'ಜೆರೇಮಿಃ',
    Lamentations: 'ಪ್ರಲಾಪಗಳು',
    Ezekiel: 'ಎಝೆಕಿಯೆಲ್',
    Daniel: 'ಡೇನಿಯಲ್',
    Hosea: 'ಹೊಸಿಯೊ',
    Joel: 'ಜೋಯಲ್',
    Amos: 'ಅಮೋಸ್',
    Obadiah: 'ಓಬದ್ಯನಿಗೆ',
    Jonah: 'ಜೋನ್ನಾ',
    Micah: 'ವಿಾಕನ',
    Nahum: 'ನಾಹುಂ',
    Habakkuk: 'ಹಬಾಕ್ಕುಕ್',
    Zephaniah: 'ಝೆಫಾನಿಯಾ',
    Haggai: 'ಹಗ್ಗಾಯನ',
    Zechariah: 'ಜೆಕರಾಯಾ',
    Malachi: 'ಮಲಾಚಿ',
    Matthew: 'ಮ್ಯಾಥ್ಯೂ',
    Mark: 'ಮಾರ್ಕ್',
    Luke: 'ಲ್ಯೂಕ್',
    John: 'ಜಾನ್',
    Acts: 'ಕಾಯಿದೆಗಳು',
    Romans: 'ರೋಮನ್ನರು',
    '1 Corinthians': '1 ಕೊರಿಂಥದವರಿಗೆ',
    '2 Corinthians': '2 ಕೊರಿಂಥದವರಿಗೆ',
    Galatians: 'ಗಲಾಷಿಯನ್ಸ್',
    Ephesians: 'ಎಫೆಸಿಯನ್ಸ್',
    Philippians: 'ಫಿಲಿಪ್ಪಿಯವರಿಗೆ',
    Colossians: 'ಕೊಲೊಸ್ಸೆಯವರಿಗೆ',
    '1 Thessalonians': '1 ಥೆಸಲೊನೀಕದವರಿಗೆ',
    '2 Thessalonians': '2 ಥೆಸಲೊನೀಕದವರಿಗೆ',
    '1 Timothy': '1 ತಿಮೋತಿ',
    '2 Timothy': '2 ತಿಮೋತಿ',
    Titus: 'ಟೈಟಸ್',
    Philemon: 'ಫಿಲೆಮನ್',
    Hebrews: 'ಹೀಬ್ರೂ',
    James: 'ಜೇಮ್ಸ್',
    '1 Peter': '1 ಪೇತ್ರ',
    '2 Peter': '2 ಪೀಟರ್',
    '1 John': '1 ಜಾನ್',
    '2 John': '2 ಜಾನ್',
    '3 John': '3 ಜಾನ್',
    Jude: 'ಜೂಡ್',
    Revelation: 'ರೆವೆಲೆಶನ್',
    'resources.prompt':
      'ನೀವು ಭಾಷಾಂತರಿಸುವ ಅಂಗೀಕಾರದ ಹೆಚ್ಚುವರಿ ಒಳನೋಟ ಬೈಬಲಿನ ಸಂಪನ್ಮೂಲಗಳನ್ನು ಸೇರಿಸಿ.',
    'resources.add': 'ಒಂದು ಬೈಬಲಿನ ಸಂಪನ್ಮೂಲ ಸೇರಿಸಿ',
    Manuscript: 'ಹಸ್ತಪ್ರತಿ',
    Morphology: 'ಮಾರ್ಫಾಲಜಿ',
    Lexicon: 'ಲೆಕ್ಸಿಕನ್',
    'Syntactic Tree': 'ವಾಕ್ಯರಚನೆ ಟ್ರೀ',
    'Go to project list': 'ಯೋಜನೆಯ ಪಟ್ಟಿಗೆ ಹೋಗು',
    'Project not found': 'ಪ್ರಾಜೆಕ್ಟ್ ಕಂಡುಬಂದಿಲ್ಲ ಅಥವಾ ಸಾಕಷ್ಟು ಅನುಮತಿಗಳನ್ನು.',
    'error.referenceDoesNotExistForResource':
      'ಈ ಬೈಬಲ್ ಅನುವಾದ ಆಯ್ಕೆ ಅಂಗೀಕಾರದ ವಿಷಯ ಹೊಂದಿರುವುದಿಲ್ಲ.',
    'references.addAnother': 'ಮತ್ತೊಂದು ಅನುವಾದ ಸೇರಿಸಿ',
    'references.referencePrompt': 'ಇತರ ಬೈಬಲ್ ಅನುವಾದಗಳನ್ನು ಸೇರಿಸಿ ಹೆಚ್ಚುವರಿ ಒಳನೋಟ.',
    'references.add': 'ಬೈಬಲ್ ಅನುವಾದ ಸೇರಿಸಿ',
    'references.licensing': 'ಪರವಾನಗಿ ಮಾಹಿತಿ',
    'references.licensingEmpty': 'ಯಾವುದೇ ಪರವಾನಗಿ ಮಾಹಿತಿ ಈ ಐಟಂ ಕಂಡುಬರಲಿಲ್ಲ.',
    'translation.complete': 'ಅನುವಾದ ಸಂಪೂರ್ಣ',
    Alignment: 'ಸಾಲು',
    Share: 'ಹಂಚಿಕೊಳ್ಳಿ',
    'Your Translation': 'ನಿಮ್ಮ ಅನುವಾದ',
    'Enter your translation': 'ನಿಮ್ಮ ಅನುವಾದ ನಮೂದಿಸಿ',
    OK: 'ಸರಿ',
    'error.verseTranslationEmpty': 'ಈ ಅನುವಾದ ವಿಷಯ ಖಾಲಿ ಬಿಡುವಂತಿಲ್ಲ.',
    'translation.hide.completed': 'ಪೂರ್ಣಗೊಂಡಿತು ಮರೆಮಾಡಿ',
    alignment: 'ಸಾಲು',
    'alignment.verify': 'ಪರಿಶೀಲಿಸಿ ಮತ್ತು ಮುಚ್ಚಿ.',
    'alignment.close': 'ಪರಿಶೀಲಿಸುವಿಕೆ ಹತ್ತಿರದಿಂದ',
    'alignment.backToEditor': 'ಸಂಪಾದಕರಿಗೆ ಬ್ಯಾಕ್',
    'alignment.error':
      'ಏನೋ ತಪ್ಪಾಗಿದೆ. ಸಮಸ್ಯೆ ಮುಂದುವರಿದರೆ, ದಯವಿಟ್ಟು YouTranslate.Bible ತಂಡವನ್ನು ಸಂಪರ್ಕಿಸಿ.',
    'alignment.add': 'ಲಿಂಕ್ ಸೇರಿಸಿ',
    'alignment.delete': 'ಲಿಂಕ್ ತೆಗೆದುಹಾಕಿ',
    'alignment.clear': 'ಆಯ್ಕೆಯನ್ನು ತೆರವುಗೊಳಿಸಿ',
    'alignment.reverse': 'ರಿವರ್ಸ್ ಪ್ರದರ್ಶನ',
    'alignment.removeOrClear': 'ಆಯ್ಕೆ ಲಿಂಕ್ ಅಥವಾ ಆಯ್ಕೆಯನ್ನು ತೆರವುಗೊಳಿಸಿ ತೆಗೆದುಹಾಕಿ.',
    'alignment.addOrRemove': 'ಹೊಸ ಲಿಂಕ್ ರಚಿಸಿ ಅಥವಾ ಹಿಂದಿನ ಅಳಿಸಿ.',
    'alignment.selectAndAdd': 'ಲಿಂಕ್ ಅನುಗುಣವಾದ ಭಾಗಗಳು ಆಯ್ಕೆಮಾಡಿ.',
    'error.mustBeSignedToMakeChanges': 'ನೀವು ಬದಲಾವಣೆಗಳನ್ನು ಮಾಡಲು ಸೈನ್ ಇನ್ ಮಾಡಬೇಕು.',
    'error.mustBeSignedToTranslateBible': 'ನೀವು ಬೈಬಲ್ ಭಾಷಾಂತರಿಸಲು ಸೈನ್ ಇನ್ ಮಾಡಬೇಕು.',
    checking: 'ಪರಿಶೀಲಿಸಲಾಗುತ್ತಿದೆ',
    'verseEditor.unexpectedError': 'ಅನಿರೀಕ್ಷಿತ ದೋಷ ಸಂಭವಿಸಿದೆ.',
    verses: 'ಶ್ಲೋಕ ಮೂಲಕ',
    words: 'ಪದಗಳ ಮೂಲಕ',
    'leftPanel.name': 'ಬೈಬಲ್ ಮೂಲ ಪಠ್ಯ',
    'rightPanel.name': 'ಬೈಬಲ್ ಅನುವಾದಗಳು',
    'bottomPanel.name': 'ಬೈಬಲ್ ಸಂಪನ್ಮೂಲಗಳು',
    revisionHistory: 'ಪರಿಷ್ಕರಣ ಇತಿಹಾಸ',
    'syntax.adv': 'ಕ್ರಿಯಾವಿಶೇಷಣದ ಮಾರ್ಪಡಿಸುವ',
    'syntax.adjp': 'ಗುಣವಿಶೇಷದ ಫ್ರೇಸ್',
    'syntax.advp': 'ಕ್ರಿಯಾವಿಶೇಷಣ ಫ್ರೇಸ್',
    'syntax.cl': 'ಷರತ್ತು',
    'syntax.conj': 'ಯುತಿ',
    'syntax.cjp': 'ಯುತಿ',
    'syntax.det': 'Determiner',
    'syntax.ijp': 'ಭಾವಸೂಚಕಾವ್ಯಯ',
    'syntax.nump': 'ಸಂಖ್ಯಾತ್ಮಕ ಫ್ರೇಸ್',
    'syntax.np': 'ನಾಮಪದ ಫ್ರೇಸ್',
    'syntax.o': 'ನೇರ ವಸ್ತು',
    'syntax.o2': 'ಎರಡನೇ ವಸ್ತು',
    'syntax.oc': 'ವಸ್ತು ಪೂರಕವಾಗಿ',
    'syntax.om': 'ವಸ್ತು ಮಾರ್ಕರ್',
    'syntax.io': 'ಪರೋಕ್ಷ ವಸ್ತು',
    'syntax.p': 'ನಾನ್-ವರ್ಬಲ್ ಆಖ್ಯಾತ',
    'syntax.pp': 'ಉಪಸರ್ಗ ಫ್ರೇಸ್',
    'syntax.prep': 'ಉಪಸರ್ಗ',
    'syntax.ptcl': 'ಪಾರ್ಟಿಕಲ್',
    'syntax.rel': 'ರಿಲೇಟಿವ್ ಪಾರ್ಟಿಕಲ್',
    'syntax.s': 'ವಿಷಯ',
    'syntax.v': 'ಕ್ರಿಯಾಪದ',
    'syntax.vc': 'ಜೋಡಿಸುವ ಕ್ರಿಯಾಪದ',
    'syntax.adjp.description': 'ನಾಮಪದ ಅಥವಾ ಸರ್ವನಾಮ ಮಾರ್ಪಡಿಸಿಕೊಳ್ಳುವ ಫ್ರೇಸ್',
    'syntax.adv.description':
      'ಮಾರ್ಪಡಿಸುತ್ತದೆ ಕ್ರಿಯಾಪದ, ಸಾಮಾನ್ಯವಾಗಿ ಮತ್ತಷ್ಟು ವಿವರಿಸುವ ರೀತಿಯಲ್ಲಿ, ಸಮಯ, ಸ್ಥಳ, ಆವರ್ತನ, ಶ್ರೇಣಿ ಇತ್ಯಾದಿ ...',
    'syntax.advp.description':
      'ಫ್ರೇಸ್, ಕ್ರಿಯಾಪದ ಮಾರ್ಪಡಿಸುವ ವಿಶಿಷ್ಟವಾಗಿ, ಮತ್ತಷ್ಟು ವಿವರಿಸುವ ರೀತಿಯಲ್ಲಿ, ಸಮಯ, ಸ್ಥಳ, ಆವರ್ತನ, ಶ್ರೇಣಿ ಇತ್ಯಾದಿ',
    'syntax.cl.description': 'ಗುಂಪು ಪದಗಳ ವಿಷಯದ ಬಗ್ಗೆ ಪ್ರತಿಪಾದನೆಯಾಗಿ ಮಾಡುತ್ತದೆ.',
    'syntax.conj.description': 'ಪದಗಳು, ನುಡಿಗಟ್ಟುಗಳು, ಮತ್ತು ವಿಧಿಗಳು ಸಂಪರ್ಕಿಸುತ್ತದೆ.',
    'syntax.cjp.description': 'ಪದಗಳು, ನುಡಿಗಟ್ಟುಗಳು, ಮತ್ತು ವಿಧಿಗಳು ಸಂಪರ್ಕಿಸುತ್ತದೆ.',
    'syntax.det.description':
      'ನಿರ್ದಿಷ್ಟಪಡಿಸುತ್ತದೆ, ಗುರುತಿಸಬಲ್ಲ, ಅಥವಾ ನಾಮಪದವಾಗಿ ಪದಗುಚ್ಛ ಪರಿಮಾಣಿಸುತ್ತದೆ.',
    'syntax.ijp.description': 'ಆಶ್ಚರ್ಯಸೂಚಕ ಅಥವಾ ಭಾವನೆಯ ಹಠಾತ್ ಅಭಿವ್ಯಕ್ತಿ.',
    'syntax.nump.description': 'ಸಂಖ್ಯಾತ್ಮಕ ಪ್ರಮಾಣವನ್ನು ವಿವರಿಸುವ ಫ್ರೇಸ್',
    'syntax.np.description':
      'ಅದರ ಮುಖ್ಯಸ್ಥರನ್ನಾಗಿ ನಾಮಪದ ಅಥವಾ ನಾಮಪದವಾಗಿ ಆ ಕಾರ್ಯಗಳುಳ್ಳ ಒಂದು ನುಡಿಗಟ್ಟು.',
    'syntax.o.description':
      'ಆ ನೇರವಾಗಿ ತೊಂದರೆಗೊಳಗಾಗುತ್ತದೆ ಕಾರಣವಾಗಿವೆ ಅಥವಾ ಕ್ರಿಯಾಪದವಾಗಿ ಪರಿಣಾಮ ಉತ್ಪನ್ನವಾಗುವ.',
    'syntax.o2.description':
      'ಆ ನೇರವಾಗಿ ತೊಂದರೆಗೊಳಗಾಗುತ್ತದೆ ಕಾರಣವಾಗಿವೆ ಅಥವಾ ಕ್ರಿಯಾಪದವಾಗಿ ಪರಿಣಾಮ ಉತ್ಪನ್ನವಾಗುವ.',
    'syntax.oc.description': 'ನೇರ ವಸ್ತು ಬಗ್ಗೆ ಪ್ರತಿಪಾದನೆಯಾಗಿ ಮಾಡುತ್ತದೆ.',
    'syntax.om.description': 'ನೇರ ವಸ್ತು ಪರಿಚಯಿಸುತ್ತದೆ ಪಾರ್ಟಿಕಲ್.',
    'syntax.io.description':
      'ಪರೋಕ್ಷವಾಗಿ ಕ್ರಿಯಾಶೀಲ ಪ್ರಭಾವಿತ ವಸ್ತು, ಕ್ರಿಯಾಪದದ ಕ್ರಿಯೆಯನ್ನು ಸಾಮಾನ್ಯವಾಗಿ ಸ್ವೀಕರಿಸುವವರು.',
    'syntax.p.description':
      'ಗುರುತಿಸುತ್ತದೆ ಅಥವಾ ವಿಷಯದ ವಿವರಿಸುತ್ತದೆ ಆಗಾಗ (ಆದರೆ ಯಾವಾಗಲೂ ಅಲ್ಲ) ಒಟ್ಟಿಗೆ ಲಿಂಕ್ ಕ್ರಿಯಾಪದ ಉಂಟಾಗುತ್ತದೆ.',
    'syntax.pp.description': 'ಉಪಸರ್ಗವು ಮತ್ತು ಅದರ ವಸ್ತು ಒಳಗೊಂಡಿರುವ ಮಾರ್ಪಡಿಸುವ ನುಡಿಗಟ್ಟು.',
    'syntax.prep.description':
      'ಸಾಮಾನ್ಯವಾಗಿ ಸೂಚಿಸುವ ಎಲ್ಲಿ ಅಥವಾ, ಇತರ ಪದಗಳೊಂದಿಗೆ ಉಪಸರ್ಗವೊಂದರ ವಸ್ತುವಿನ ಸಂಬಂಧವನ್ನು ಸೂಚಿಸುತ್ತದೆ.',
    'syntax.ptcl.description':
      'ಕಾರ್ಯ ಪದಗಳ ವೈವಿಧ್ಯಮಯ ಗುಂಪನ್ನು ಒಂದು ಸದಸ್ಯ ರೂಪನಿಷ್ಪತ್ತಿ ವ್ಯಾಕರಣ ಪದ ವರ್ಗಗಳು ಯಾವುದೇ ಸೇರದ',
    'syntax.rel.description': 'ಸಂಬಂಧ ಷರತ್ತು ಬಗ್ಗೆ ಪರಿಚಯಿಸುವ ಪಾರ್ಟಿಕಲ್.',
    'syntax.s.description': 'ಷರತ್ತು ಉಳಿದ ಬಗ್ಗೆ ಸಮರ್ಥನೆಯನ್ನು ಮಾಡುವ ಕಲಂ ಭಾಗ.',
    'syntax.v.description':
      'ಷರತ್ತು ವಿಷಯದ ಬಗ್ಗೆ ಏನಾದರೂ ಹೇಳುತ್ತದೆ ಮತ್ತು ಕ್ರಿಯೆ, ಘಟನೆ, ಅಥವಾ ಸ್ಥಿತಿ ವ್ಯಕ್ತಪಡಿಸಬಹುದು.',
    'syntax.vc.description': 'ವಿಷಯ ಪೂರಕ ಒಂದು ಷರತ್ತು ವಿಷಯದ ಕೊಂಡಿಗಳು.',
    'general.close': 'ಮುಚ್ಚು',
    'general.action': 'ಕ್ರಿಯೆ',
    'general.select': 'ಆಯ್ಕೆ ಮಾಡಿ...',
    'general.back': 'ಬ್ಯಾಕ್',
    'revisionHistory.noData': 'ಈ ಪದ್ಯ ಯಾವುದೇ ಪರಿಷ್ಕರಣೆ ಇತಿಹಾಸವೇ ಇದೆ.',
    'revisionHistory.translation': 'ಅನುವಾದ',
    'revisionHistory.lastChanged': 'ಕೊನೆಯ ಚೇಂಜ್ಡ್',
    'sourceText.view': 'ಮೂಲ ಪಠ್ಯವನ್ನು ವೀಕ್ಷಿಸಲು ಕ್ಲಿಕ್ ಮಾಡಿ',
    'sourceText.viewStructure': 'ಮೂಲ ಪಠ್ಯ ರಚನೆ ವೀಕ್ಷಿಸಲು ಕ್ಲಿಕ್ ಮಾಡಿ',
    'error.projectIdIsRequired': 'ಪ್ರಾಜೆಕ್ಟ್ ID ಅಗತ್ಯವಿದೆ.',
    'error.searchTermIsRequired': 'ಹುಡುಕಾಟದ ಪದ ಖಾಲಿ ಸಾಧ್ಯವಿಲ್ಲ.',
    'error.bookIdIsRequired': 'ಪುಸ್ತಕ ಐಡಿ ಪುಸ್ತಕದ ಹೆಸರನ್ನು ಶೇಖರಿಸಿಡಲು ಅಗತ್ಯವಿದೆ.',
    'error.bookNameCannotBeEmpty': 'ಪುಸ್ತಕ ಹೆಸರು ಖಾಲಿ ಇರುವಂತಿಲ್ಲ.',
    'error.signInIsRequiredToAccessProject':
      'ನಿಮ್ಮ ಯೋಜನೆಯನ್ನು ಪ್ರವೇಶಿಸಲು ನೀವು ಸೈನ್ ಇನ್ ಮಾಡಬೇಕು.',
    error: 'ದೋಷ',
    'error.doNotHavePermissionAccessProject': 'ಈ ಯೋಜನೆಯ ಪ್ರವೇಶಿಸಲು ಅನುಮತಿಯನ್ನು ಹೊಂದಿಲ್ಲ.',
    'error.unauthorized': 'ಅನಧಿಕೃತ ಪ್ರವೇಶ ಅಥವಾ ಅಮಾನ್ಯ ಪ್ರವೇಶ ಟೋಕನ್.',
    'error.tokenExpired': 'ನಿಮ್ಮ ಟೋಕನ್ ಅವಧಿ ಮುಗಿದಿದೆ. ದಯವಿಟ್ಟು ಮರು ಲಾಗಿನ್.',
    'error.invalidApiUrl': 'ಅಮಾನ್ಯವಾದ API URL.',
    'error.badRequestInvalidJson': 'ಕೆಟ್ಟ ಕೋರಿಕೆ. ಅಮಾನ್ಯವಾದ JSON.',
    'error.windowIsNotDefined': 'ವಿಂಡೋ ವಿವರಿಸಲಾಗಿಲ್ಲ.',
    'error.unexpectedError': 'ಅನಿರೀಕ್ಷಿತ ದೋಷ ಸಂಭವಿಸಿದೆ. ಈ ವಿಂಡೋ ಮುಚ್ಚಿ ಹಾಗೂ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ.',
    'error.invalidDataType': 'ಅಮಾನ್ಯ ಡೇಟಾ ಪ್ರಕಾರ.',
    'export.data': 'ಡೇಟಾ ರಫ್ತು',
    more: 'ಇನ್ನಷ್ಟು',
    'export.to.rtf': 'ಆರ್ಟಿಎಪ್ ರಫ್ತು',
    'export.to.usfm': 'USFM ರಫ್ತು',
    'export.titlePreparingData': 'ನಿಮ್ಮ ಬೈಬಲ್ ಅನುವಾದ ಡೇಟಾ ತಯಾರಿ.',
    'export.doNotClose':
      'ಈ ಪ್ರಕ್ರಿಯೆಯು ಸ್ವಲ್ಪ ಸಮಯ ತೆಗೆದುಕೊಳ್ಳಬಹುದು, ದಯವಿಟ್ಟು ಹತ್ತಿರ ಈ ವಿಂಡೋ ಮಾಡಲು ಮತ್ತು ಯಾವಾಗ ಡೌನ್ಲೋಡ್ ಸಿದ್ಧವಾಗಿದೆ ನೀವು ತಿಳಿಸಲಾಗುವುದು.',
    'export.desc':
      'ನೀವು ರಫ್ತು ಮತ್ತು ಕೆಳಗೆ ಗುಂಡಿಗಳು ಕ್ಲಿಕ್ಕಿಸಿ ನಿಮ್ಮ ಅನುವಾದ ಡೇಟಾ ಡೌನ್ಲೋಡ್ ಮಾಡಬಹುದು.',
    'export.titleOfReady2Download': 'ನಿಮ್ಮ ಬೈಬಲ್ ಅನುವಾದ ಡೇಟಾ ಸಿದ್ಧವಾಗಿದೆ.',
    'export.textOfReady2Download':
      'ನಿಮ್ಮ ಬೈಬಲ್ ಅನುವಾದ ಡೌನ್ಲೋಡ್ ಕೆಳಗಿನ ಡೌನ್ಲೋಡ್ ಬಟನ್ ಕ್ಲಿಕ್ ಮಾಡಿ.',
    'export.statistics': 'ಅಂಕಿಅಂಶ',
    'export.book': 'ಪುಸ್ತಕದ ಹೆಸರನ್ನು',
    'export.translatedVerses': 'ಅನುವಾದ ಪದ್ಯಗಳನ್ನು',
    'export.exportedVerses': 'ರಫ್ತಾಗುವ ಪದ್ಯಗಳನ್ನು',
    'export.howDoUWant2Export': 'ನೀವು ಮಾಹಿತಿಯ ರಫ್ತು ಮಾಡಲು ಬಯಸುತ್ತೀರಿ?',
    'export.whichBooks': 'ಯಾವ ಪುಸ್ತಕ (ಗಳು) ನೀವು ರಫ್ತು ಮಾಡಲು ಬಯಸುತ್ತೀರಿ?',
    'export.byBooks': 'ಪುಸ್ತಕಗಳಿಂದ',
    'export.byChapters': 'ಅಧ್ಯಾಯಗಳು ಮೂಲಕ',
    'export.byVerses': 'ಪದ್ಯಗಳನ್ನು ಮೂಲಕ',
    'export.booksInOt': 'ಹಳೆಯ ಸಾಕ್ಷಿ',
    'export.booksInNt': 'ಹೊಸ ಒಡಂಬಡಿಕೆಯಲ್ಲಿ',
    'export.booksInBible': 'ಇಡೀ ಬೈಬಲ್',
    'export.booksInNone': 'ಯಾವುದೂ',
    'export.selectRangeOfChapters': 'ಅಧ್ಯಾಯಗಳ ರೇಂಜ್',
    'export.selectRangeOfVerses': 'ಅಧ್ಯಾಯಗಳಿವೆ ರೇಂಜ್',
    'export.selectChapter': 'ಒಂದು ಅಧ್ಯಾಯ ಆಯ್ಕೆಮಾಡಿ',
    'export.exportTypeIsRequired':
      'ಹೇಗೆ ನೀವು ರಫ್ತು ಡೇಟಾವನ್ನು ಬಯಸುತ್ತೀರಿ ನೀವು ಇನ್ನೂ ಬಗ್ಗೆ ಆಯ್ಕೆಯಾಗದಿರುವ?',
    'export.haveNotYetSelectedBooks': 'ನೀವು ಇನ್ನೂ ಯಾವುದೇ ಪುಸ್ತಕ ಆಯ್ಕೆ ಮಾಡಿಲ್ಲ.',
    'error.selectABook': 'ನೀವು ಪುಸ್ತಕ ಆಯ್ಕೆ ಮಾಡಬೇಕು.',
    'error.invalidBookId': 'ಅಮಾನ್ಯವಾದ ಪುಸ್ತಕದ id.',
    'error.selectAChapter': 'ನೀವು ಅಧ್ಯಾಯ ಆಯ್ಕೆ ಮಾಡಬೇಕು.',
    'error.invalidChapterRange': 'ನೀವು ಮಾನ್ಯವಾದ ಅಧ್ಯಾಯ ಶ್ರೇಣಿಯನ್ನು ಆಯ್ಕೆ ಮಾಡಬೇಕು.',
    'error.invalidVerseRange': 'ನೀವು ಮಾನ್ಯವಾದ ಪದ್ಯ ಶ್ರೇಣಿಯನ್ನು ಆಯ್ಕೆ ಮಾಡಬೇಕು.',
    'error.invalidExportType': 'ಅಮಾನ್ಯವಾದ ರಫ್ತಿನ ರೀತಿಯ.',
    startover: 'ಆರಂಭಿಸು',
    download: 'ಡೌನ್ಲೋಡ್',
    'download.all': 'ಎಲ್ಲ ಫೈಲ್ಗಳು ಡೌನ್ಲೋಡ್',
    'contact.header': 'ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ',
    'contact.returnAddress': 'ಹಿಂಬರುವ ವಿಳಾಸ',
    'contact.returnAddressPlaceholder': 'ನಾವು ನೀವು ಅಲ್ಲಿ ತಲುಪಬಹುದು?',
    'contact.subject': 'ವಿಷಯ',
    'contact.subjectPlaceholder': 'ನೀವು ಏನು ಜೊತೆ ಸಹಾಯ ಬೇಕು?',
    'contact.message': 'ಸಂದೇಶ',
    'contact.messagePlaceholder': 'ನಾವು ಹೇಗೆ ಸಹಾಯ ಮಾಡಬಹುದು?',
    'contact.sendMessage': 'ಸಂದೇಶ ಕಳುಹಿಸಿ',
    'contact.success': 'ಸಂದೇಶವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಕಳುಹಿಸಲಾಗಿದೆ.',
    'contact.failure':
      'ಈ ಸಂದೇಶವನ್ನು ಕಳುಹಿಸುವುದರಲ್ಲಿ ಸಮಸ್ಯೆಯಿದೆ. ದಯವಿಟ್ಟು help@globalbibleinitiative.org ಸಂಪರ್ಕಿಸಿ.',
    selectInterlinear: 'ಒಳಪಂಕ್ತಿಯ ಪಠ್ಯ ಬದಲಾಯಿಸಲು ಕ್ಲಿಕ್ ಮಾಡಿ',
    'warning.screenTooSmall':
      'ಹೆಚ್ಚಿನ ಸ್ಕ್ರೀನ್ ರೆಸಲ್ಯೂಶನ್ (ಕನಿಷ್ಠ 1024px ವ್ಯಾಪಕ) ಬದಲಾಯಿಸಬಹುದು ಅಥವಾ ನಿಮ್ಮ ಬ್ರೌಸರ್ ವಿಂಡೋವನ್ನು ವಿಸ್ತಾರಗೊಳಿಸುವ ದಯವಿಟ್ಟು ದಯವಿಟ್ಟು.',
    'label.dictionary': 'ಕಾನ್ಕಾರ್ಡಾನ್ಸ್ ಹುಡುಕು',
    'dict.search.placeholder': 'ಪದ (ಗಳು) ನಮೂದಿಸಿ',
    'tooltip.manuscript': 'ಹಸ್ತಪ್ರತಿ',
    'tooltip.strongsx': 'ಪ್ರಬಲ ಸಂಖ್ಯೆ',
    'tooltip.translation': 'ಅನುವಾದ',
    'tooltip.english': 'ಆಂಗ್ಲ',
    'title.howToSearch': 'ಸಾಮರಸ್ಯದಲ್ಲಿ ಹುಡುಕಾಟ ಹೇಗೆ ಬಳಸುವುದು',
    'desc.howToSearch':
      'ಗ್ರೀಕ್ ಅಥವಾ ಹೀಬ್ರೂ, ಪದ (ಗಳು) ನಿಮ್ಮ ಅನುವಾದಕ್ಕಿಂತ, ಅಥವಾ ಪ್ರಬಲ ಸಂಖ್ಯೆ (ಗಳು) ನಿಂದ ಪದ (ಗಳು) ನಮೂದಿಸಿ. ಅಥವಾ "ನಿಮ್ಮ ಅನುವಾದ" ಅಥವಾ ಹುಡುಕಾಟ ಆಯ್ಕೆಗಳಿಗಾಗಿ ಸಾಲು ವೀಕ್ಷಣೆಗಳು ಫಲಕದಲ್ಲಿ ಗ್ರೀಕ್ ಅಥವಾ ಹೀಬ್ರೂ ಪದಗಳ ಮೇಲೆ ಕ್ಲಿಕ್.',
    'dict.noDataToDisplay': 'ನಿಮ್ಮ ಶೋಧ ಮಾನದಂಡಕ್ಕೆ ಹೊಂದಿಕೆಯಾಗುವ ಯಾವುದೇ ಅಂಕಿಅಂಶಗಳಿವೆ.',
    'title.searchTermTooMany': 'ನೀವು ಹಲವಾರು ಕೀವರ್ಡ್ಗಳನ್ನು ನಮೂದಿಸಿರುವ',
    'desc.searchTermTooMany': 'ನೀವು 8 ಕೀವರ್ಡ್ಗಳನ್ನು ಪ್ರತಿ ಬಾರಿ ವರೆಗೆ ಹುಡುಕಬಹುದು.',
    'title.searchDictError': 'ಅನಿರೀಕ್ಷಿತ ದೋಷ ಸಂಭವಿಸಿದೆ.',
    'desc.searchDictError': 'ದಯವಿಟ್ಟು ನಂತರ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ ಅಥವಾ ಗ್ರಾಹಕ ಬೆಂಬಲವನ್ನು ಸಂಪರ್ಕಿಸಿ',
    'alignment.saveAndEditAlignment': 'ಉಳಿಸಿ ಮತ್ತು ಅಲೈನ್ ಹೊಸ ಪಠ್ಯ',
    'alignment.reviewAlignment': 'ರಿವ್ಯೂ ಪಠ್ಯ ಜೋಡಣೆ',
    'dict.searchLemma': 'ಮೂಲ ಪದ ಹುಡುಕಲು ಕ್ಲಿಕ್ ಮಾಡಿ',
    'dict.searchTranslation': 'ಅನುವಾದ ಪದ ಹುಡುಕಲು ಕ್ಲಿಕ್ ಮಾಡಿ',
    'dict.searchStrongsX': 'ಪ್ರಬಲ ನಂಬರ್ ಹುಡುಕಲು ಕ್ಲಿಕ್ ಮಾಡಿ',
    'dict.showMenu': 'ಸಂದರ್ಭಕ್ಕೆ ಘಟನೆಗಳ ಪೂರ್ಣ ಪಠ್ಯ ತೋರಿಸಲು ಕ್ಲಿಕ್ ಮಾಡಿ.',
    'dict.hideMenu': 'ಸಂದರ್ಭಕ್ಕೆ ಘಟನೆಗಳ ಪೂರ್ಣ ಪಠ್ಯ ಮರೆಮಾಡಲು ಕ್ಲಿಕ್ ಮಾಡಿ.',
  },
  ko: {
    'languages.en': '영어',
    'languages.am': '암하라어',
    'languages.bn': '벵골어',
    'languages.ceb': '세부 아노 언어',
    'languages.de': '독일어',
    'languages.es-419': '스페인어',
    'languages.fr': '프랑스어',
    'languages.gu': '구자라트어',
    'languages.hi': '힌디 어',
    'languages.hr': '크로아티아어 언어',
    'languages.hu': '헝가리어',
    'languages.id': '인도네시아어',
    'languages.km': '크메르어',
    'languages.kn': '칸나다어',
    'languages.ko': '한국어',
    'languages.lo': '라오스 언어',
    'languages.ml': '말라얄람어',
    'languages.mr': '마라타어',
    'languages.ms': '말레이시아',
    'languages.my': '버마어 언어',
    'languages.ne': '네팔어',
    'languages.plt': '말라가 시어',
    'languages.pt-br': '포르투갈어 (브라질) 언어',
    'languages.ru': '러시아어',
    'languages.sw': '스와힐리어',
    'languages.ta': '타밀어',
    'languages.th': '태국어',
    'languages.tl': '타갈로그어',
    'languages.te': '텔루구 어 언어',
    'languages.vi': '베트남어',
    'languages.zh-hans': '중국어 (간체)',
    'languages.zh-hant': '중국어 (번체)',
    'header.home': '집',
    'header.help': '도움',
    'header.project': '계획',
    'header.contact': '접촉',
    'help.contactSupport': '지원 help@youtranslate.bible에 이메일을 보내 주시기 바랍니다.',
    'home.ytbProvides': 'YouTranslate.Bible 자유롭게 제공',
    'home.easyToUseTool': '사용하기 쉬운 온라인 번역 도구',
    'home.originalTextHelp': '도움말은 원래 언어로 된 성경의 텍스트를 이해하는',
    'home.referencesAndResources': '참조 및 교육 자원',
    'home.computerAssistance': '최첨단 컴퓨터 기술 번역을 지원하기 위해',
    'profile.userProfile': '유저 프로필',
    'profile.language': '언어',
    switchDirection: '스위치의 텍스트 방향',
    'profile.update': '최신 정보',
    'profile.languageWarning':
      '구글은 일시적으로 영어가 아닌 다른 언어로 사용자 인터페이스를 번역하는 데 사용 된 번역. YTB는 번역의 정확성이나 신뢰성에 제어하지 않습니다. 구글에서 얻을 번역 된 라벨과 설명에 의존 누구나 번역은 자신의 위험에 그렇게. 사람이 영어가 아닌 다른 언어로 사용자 인터페이스의보다 정확하고 신뢰할 수있는 번역을 제공하기 위해 자원 봉사에 관심이있는 경우, GBI으로 이메일을 보내 연락처 옵션을 클릭하십시오.',
    'usernav.profile': '프로필',
    'usernav.logout': '로그 아웃',
    'usernav.logout.modalTitle': 'YouTranslate.Bible의 로그 아웃',
    'usernav.logout.modalText':
      '당신은 당신이 로그 아웃 하시겠습니까? 저장되지 않은 모든 작업이 손실됩니다.',
    cancel: '취소',
    delete: '지우다',
    create: '창조하다',
    update: '최신 정보',
    edit: '편집하다',
    save: '저장',
    add: '더하다',
    translate: '옮기다',
    'more.options': '추가 옵션',
    'project.create.title': '성경 번역 프로젝트',
    'project.create.list': '프로젝트 목록',
    'project.list.deleteProject': '프로젝트를 삭제 하시겠습니까?',
    'project.list.areYouSure': '이 프로젝트를 삭제 하시겠습니까?',
    'project.list.workWillBeDeleted': '이 프로젝트와 관련된 모든 작업이 삭제됩니다.',
    'project.list.completion': '완성:',
    'project.list.createdDate': '만든 날짜 :',
    'project.list.beginningDate': '날짜부터 :',
    'project.list.targetCompletionDate': '목표 완료 날짜:',
    'project.list.description': '기술:',
    'project.edit': '프로젝트 편집',
    'project.list.translateBible': '성경을 번역',
    'project.list.empty': '번역 프로젝트 목록이 비어 있습니다.',
    'project.list.myProjects': '내 성경 번역 프로젝트',
    'project.list.newProject': '새 프로젝트',
    'project.create.createNew': '새로운 프로젝트 만들기',
    'project.namePlaceholder': '프로젝트 이름을 입력합니다',
    'project.descriptionPlaceholder': '프로젝트에 대한 설명을 입력합니다',
    'project.name': '프로젝트 이름',
    'project.beginningDate': '시작 날짜',
    'project.targetCompletionDate': '목표 완료 날짜',
    'project.description': '기술',
    'project.isRTL': '번역은 오른쪽에서 왼쪽입니다',
    'project.manage-users': '번역 관리',
    'project.new-user': '새로운 번역기',
    'project.add-user': '추가 번역기',
    'project.users': '프로젝트 번역',
    'project.read-only': '읽기 전용',
    'project.read&write': '읽기 및 쓰기',
    'project.owner': '소유자',
    'project.translator': '역자',
    'project.message.no-such-user': '그런 번역기는 존재하지 않는다',
    'project.message.user-added': '번역기가 성공적으로 추가',
    'project.message.user-deleted': '번역기를 제거합니다.',
    'project.message.only-owner-can-manage-users':
      '사용자를 관리 할 수 ​​있습니다 만 프로젝트 소유자',
    'project.shared': '공동 프로젝트',
    'general.delete-user': '삭제 번역기',
    'general.edit-user': '사용자 편집',
    'general.name': '이름',
    'general.email': '이메일',
    'general.permissions': '권한',
    'general.role': '역할',
    signin: '로그인',
    'signin.emailPlaceholder': '이메일 주소',
    'signin.passwordPlaceholder': '암호',
    'signin.forgotPassword': '비밀번호를 잊어 버렸습니까?',
    'signin.facebook': '페이스 북으로 로그인',
    'signin.google': '구글에 로그인',
    'signin.dontHaveAccount': '계정이 없으십니까?',
    'signin.createOne': '하나를 만들기',
    'signup.join': 'YouTranslate.Bible 가입',
    'signup.createAccountPrompt': '시작하는 무료 계정을 만듭니다.',
    'signup.createAccount': '계정 만들기',
    'signup.emailPlaceholder': '이메일 주소',
    'signup.passwordPlaceholder': '암호',
    'passwordReset.title': '비밀번호 초기화',
    'passwordReset.prompt': '비밀번호 재설정 안내 이메일을 요청합니다.',
    'passwordReset.emailPlaceholder': '이메일 주소',
    'passwordReset.requestButtonLabel': '요청 이메일',
    'passwordReset.success': '이메일이 전송되었습니다.',
    'passwordReset.error': '오류가 발생했습니다.',
    'translation.project': '계획:',
    'translation.backToProjectList': '프로젝트 목록으로 돌아 가기',
    'translation.book': '책:',
    'translation.chapter': '장:',
    'translation.prompt': '이 장을 떠나 하시겠습니까? 저장되지 않은 모든 작업이 손실됩니다.',
    'translation.prev': '이전',
    'translation.next': '다음',
    'translation.previousChapter': '이전 장',
    'translation.nextChapter': '다음 장',
    'navigator.book': '책:',
    'navigator.chapter': '장:',
    'navigator.oldTestament': '구약 성서',
    'navigator.newTestament': '신약 성서',
    'navigator.selectBook': '책을 선택',
    'navigator.search': '검색',
    'navigator.changeBook': '변경의 책',
    'navigator.changeChapter': '변경 장',
    Genesis: '창세기',
    Exodus: '이동',
    Leviticus: '레위기',
    Numbers: '번호',
    Deuteronomy: '신명기',
    Joshua: '여호수아',
    Judges: '심사 위원',
    Ruth: '룻',
    '1 Samuel': '사무엘',
    '2 Samuel': '사무엘',
    '1 Kings': '왕상',
    '2 Kings': '열왕기하',
    '1 Chronicles': '역 대상',
    '2 Chronicles': '역대',
    Ezra: '에즈라',
    Nehemiah: '느헤미야',
    Esther: '에스더',
    Job: '일',
    Psalms: '시편',
    Proverbs: '잠언',
    Ecclesiastes: '전도서',
    'Song of Songs': '노래의 노래',
    Isaiah: '이사야',
    Jeremiah: '예레미야',
    Lamentations: '예레미야 애가',
    Ezekiel: '에스겔',
    Daniel: '다니엘',
    Hosea: '호세아',
    Joel: '조엘',
    Amos: '아모스',
    Obadiah: '오바댜',
    Jonah: '요나',
    Micah: '미가',
    Nahum: '나훔',
    Habakkuk: '하박국',
    Zephaniah: '스바 니야',
    Haggai: '학개',
    Zechariah: '스가랴',
    Malachi: '말라기',
    Matthew: '마태 복음',
    Mark: '표',
    Luke: '누가 복음',
    John: '남자',
    Acts: '사도 행전',
    Romans: '로마서',
    '1 Corinthians': '고린도 전서',
    '2 Corinthians': '고린도 후서',
    Galatians: '갈라디아서',
    Ephesians: '에베소서',
    Philippians: '빌립보서',
    Colossians: '골',
    '1 Thessalonians': '데살로니가 전서',
    '2 Thessalonians': '데살로니가 후서',
    '1 Timothy': '디모데 전서',
    '2 Timothy': '디모데 후서',
    Titus: '디도',
    Philemon: '빌레몬서',
    Hebrews: '히브리서',
    James: '제임스',
    '1 Peter': '베드로 전서',
    '2 Peter': '2 피터',
    '1 John': '요한 1 서',
    '2 John': '2 존',
    '3 John': '3 존',
    Jude: '주드',
    Revelation: '시현',
    'resources.prompt': '당신이 번역하는 통로에 추가 통찰력 성경 자원을 추가합니다.',
    'resources.add': '성경 자원 추가',
    Manuscript: '원고',
    Morphology: '형태',
    Lexicon: '사전',
    'Syntactic Tree': '구문 트리',
    'Go to project list': '프로젝트 목록으로 이동',
    'Project not found': '프로젝트를 찾을 수 없거나 권한이 부족하지.',
    'error.referenceDoesNotExistForResource':
      '이 성경 번역은 선택한 경로에 대한 내용이 포함되어 있지 않습니다.',
    'references.addAnother': '다른 번역을 추가',
    'references.referencePrompt': '추가 통찰력 다른 성경 번역을 추가합니다.',
    'references.add': '성경 번역을 추가',
    'references.licensing': '라이센스 정보',
    'references.licensingEmpty': '어떤 라이센스 정보는이 항목에 대한 찾을 수 없습니다.',
    'translation.complete': '번역 완료',
    Alignment: '조정',
    Share: '공유',
    'Your Translation': '번역',
    'Enter your translation': '번역을 입력',
    OK: '확인',
    'error.verseTranslationEmpty': '당신은 번역 내용이 비어 떠날 수 없다.',
    'translation.hide.completed': '숨기기 완료',
    alignment: '조정',
    'alignment.verify': '확인하고 닫습니다.',
    'alignment.close': '확인하지 않고 닫기',
    'alignment.backToEditor': '편집기로 돌아 가기',
    'alignment.error':
      '문제가 발생했습니다. 문제가 지속되면 YouTranslate.Bible 팀에 문의하시기 바랍니다.',
    'alignment.add': '링크 추가',
    'alignment.delete': '링크 제거',
    'alignment.clear': '명확한 선택',
    'alignment.reverse': '역 표시',
    'alignment.removeOrClear': '선택한 링크 나 명확한 선택을 제거합니다.',
    'alignment.addOrRemove': '새 링크를 만들거나 이전을 삭제합니다.',
    'alignment.selectAndAdd': '링크 세그먼트를 해당 선택합니다.',
    'error.mustBeSignedToMakeChanges': '당신은 메이크업의 변화에 ​​로그인해야합니다.',
    'error.mustBeSignedToTranslateBible': '당신은 성경을 번역하려면 로그인해야합니다.',
    checking: '확인 중',
    'verseEditor.unexpectedError': '예기치 않은 오류가 발생했습니다.',
    verses: '운문으로',
    words: '단어',
    'leftPanel.name': '성경의 원문',
    'rightPanel.name': '성경의 번역',
    'bottomPanel.name': '성경에 대한 자료',
    revisionHistory: '개정 내역',
    'syntax.adv': '부사 수정',
    'syntax.adjp': '형용사 구문',
    'syntax.advp': '부사 구문',
    'syntax.cl': '절',
    'syntax.conj': '접속사',
    'syntax.cjp': '접속사',
    'syntax.det': '결정',
    'syntax.ijp': '감탄사',
    'syntax.nump': '수치 구문',
    'syntax.np': '명사구',
    'syntax.o': '목적어',
    'syntax.o2': '두 번째 객체',
    'syntax.oc': '개체 보완',
    'syntax.om': '객체 마커',
    'syntax.io': '간접 목적어',
    'syntax.p': '비 언어 적 술어',
    'syntax.pp': '전치사구',
    'syntax.prep': '전치사',
    'syntax.ptcl': '입자',
    'syntax.rel': '상대 입자',
    'syntax.s': '제목',
    'syntax.v': '동사',
    'syntax.vc': '연결 동사',
    'syntax.adjp.description': '명사 나 대명사를 수정 구문',
    'syntax.adv.description': '수정 동사 전형적 상기 형성 방법, 시간, 공간, 주파수, 과정, 등 ...',
    'syntax.advp.description':
      '일반적으로 상기 구문 동사 수정 형성 방법, 시간, 공간, 주파수, 정도 등',
    'syntax.cl.description': '주제에 대한 주장을하게 단어의 그룹입니다.',
    'syntax.conj.description': '단어, 구 및 절을 연결합니다.',
    'syntax.cjp.description': '단어, 구 및 절을 연결합니다.',
    'syntax.det.description': '지정, 식별, 또는 정량화 명사구를.',
    'syntax.ijp.description': '느낌표 또는 감정의 갑작스러운 식입니다.',
    'syntax.nump.description': '수치 수량을 설명 구문',
    'syntax.np.description': '그것의 머리와 같은 명사 또는 명사로 그 기능이있는 문구.',
    'syntax.o.description': '즉, 이는 직접적으로 영향을 유발하거나, 동사의 작용에 의해 생성된다.',
    'syntax.o2.description': '즉, 이는 직접적으로 영향을 유발하거나, 동사의 작용에 의해 생성된다.',
    'syntax.oc.description': '직접 개체에 대한 주장을 만듭니다.',
    'syntax.om.description': '직접 객체를 소개 입자.',
    'syntax.io.description': '간접적 작용에 의해 영향을받는 객체, 동사의 행동의 자주받는 사람.',
    'syntax.p.description':
      '식별 또는 주제를 설명 자주 (항상은 아니지만)는 연결 동사와 함께 발생합니다.',
    'syntax.pp.description': '전치사와 그 객체로 구성된 수정 문구.',
    'syntax.prep.description':
      '여기서 종종하거나 나타내는 다른 단어와 전치사의 오브젝트의 관계를 나타낸다.',
    'syntax.ptcl.description':
      '굴곡 문법 단어 클래스 중 하나에 속하지 않는 기능 단어의 다양한 그룹의 한 멤버',
    'syntax.rel.description': '상대 절을 소개 입자.',
    'syntax.s.description': '조항의 나머지 부분에 대한 주장을 만드는 조항의 일부입니다.',
    'syntax.v.description':
      '조항의 주제에 대해 뭔가를 미국과 동작, 이벤트, 또는 조건을 표현할 수 있습니다.',
    'syntax.vc.description': '제목 보완하기 위해 조항의 제목을 링크합니다.',
    'general.close': '닫기',
    'general.action': '동작',
    'general.select': '고르다...',
    'general.back': '뒤',
    'revisionHistory.noData': '이 구절에 대한 개정 이력이 없습니다.',
    'revisionHistory.translation': '번역',
    'revisionHistory.lastChanged': '마지막으로 변경',
    'sourceText.view': '원본 텍스트를 보려면 클릭하세요',
    'sourceText.viewStructure': '원본 텍스트 구조를 보려면 클릭하세요',
    'error.projectIdIsRequired': '프로젝트 ID가 필요합니다.',
    'error.searchTermIsRequired': '검색어는 지울 수 없습니다.',
    'error.bookIdIsRequired': '예약 ID는 책 이름을 저장하는 데 필요합니다.',
    'error.bookNameCannotBeEmpty': '책 이름은 비워 둘 수 없습니다.',
    'error.signInIsRequiredToAccessProject':
      '당신은 당신의 프로젝트에 액세스하려면 로그인해야합니다.',
    error: '오류',
    'error.doNotHavePermissionAccessProject': '이 프로젝트에 액세스 할 수있는 권한이 없습니다.',
    'error.unauthorized': '무단 액세스 또는 유효하지 않은 액세스 토큰.',
    'error.tokenExpired': '귀하의 토큰이 만료되었습니다. 다시 로그인하시기 바랍니다.',
    'error.invalidApiUrl': '잘못된 API URL입니다.',
    'error.badRequestInvalidJson': '잘못된 요청. 잘못된 JSON.',
    'error.windowIsNotDefined': '창은 정의되어 있지 않습니다.',
    'error.unexpectedError': '예기치 않은 오류가 발생했습니다. 이 창을 닫고 다시 시도하십시오.',
    'error.invalidDataType': '데이터 형식이 잘못되었습니다.',
    'export.data': '데이터 내보내기',
    more: '더',
    'export.to.rtf': 'RTF로 내보내기',
    'export.to.usfm': 'USFM로 내보내기',
    'export.titlePreparingData': '우리는 당신의 성경 번역 데이터를 준비하고있다.',
    'export.doNotClose':
      '이 프로세스는 시간이 걸릴 수 있습니다, 닫지이 창을하고 다운로드 할 준비가되면 당신은 통지하겠습니다.',
    'export.desc': '당신은 내보내고 아래 버튼을 클릭하여 변환 데이터를 다운로드 할 수 있습니다.',
    'export.titleOfReady2Download': '당신의 성경 번역 데이터는 준비가되어 있습니다.',
    'export.textOfReady2Download':
      '성경 번역을 다운로드하려면 아래의 다운로드 버튼을 클릭하십시오.',
    'export.statistics': '통계',
    'export.book': '책 이름',
    'export.translatedVerses': '번역 된 구절',
    'export.exportedVerses': '수출 구절',
    'export.howDoUWant2Export': '어떻게 데이터를 내보낼 수 있습니까?',
    'export.whichBooks': '당신은 내보낼 않는 책 (들)?',
    'export.byBooks': '책으로',
    'export.byChapters': '장으로',
    'export.byVerses': '구절에 의해',
    'export.booksInOt': '구약 성서',
    'export.booksInNt': '신약 성서',
    'export.booksInBible': '전 성경',
    'export.booksInNone': '없음',
    'export.selectRangeOfChapters': '장 범위',
    'export.selectRangeOfVerses': '구절의 범위',
    'export.selectChapter': '장을 선택',
    'export.exportTypeIsRequired':
      '당신이 수출 데이터를 원하는 어떻게 당신이 아직 대해 선택하지 않은?',
    'export.haveNotYetSelectedBooks': '아직 어떤 책을 선택하지 않았습니다.',
    'error.selectABook': '당신은 책을 선택해야합니다.',
    'error.invalidBookId': '잘못된 책 ID.',
    'error.selectAChapter': '당신은 장을 선택해야합니다.',
    'error.invalidChapterRange': '유효한 장의 범위를 선택해야합니다.',
    'error.invalidVerseRange': '유효한 구절 범위를 선택해야합니다.',
    'error.invalidExportType': '잘못된 수출 유형입니다.',
    startover: '다시 시작하다',
    download: '다운로드',
    'download.all': '모든 파일을 다운로드',
    'contact.header': '문의하기',
    'contact.returnAddress': '반송 주소',
    'contact.returnAddressPlaceholder': '우리는 어디에서 당신을 도달 할 수 있습니까?',
    'contact.subject': '제목',
    'contact.subjectPlaceholder': '어떤 도움이 필요 하신가요?',
    'contact.message': '메시지',
    'contact.messagePlaceholder': '우리가 어떻게 도와 드릴까요?',
    'contact.sendMessage': '문자 보내',
    'contact.success': '메시지가 성공적으로 전송되었습니다.',
    'contact.failure':
      '이 메시지를 전송 문제가 발생했습니다. help@globalbibleinitiative.org에 문의하시기 바랍니다.',
    selectInterlinear: '행간 텍스트를 변경하려면 클릭',
    'warning.screenTooSmall':
      '더 높은 화면 해상도 (와이드 최소가 1024px)로 전환하십시오하거나 브라우저 창을 확대하십시오.',
    'label.dictionary': '일치 검색',
    'dict.search.placeholder': '단어 (들)을 입력',
    'tooltip.manuscript': '원고',
    'tooltip.strongsx': '스트롱 번호',
    'tooltip.translation': '번역',
    'tooltip.english': '영어',
    'title.howToSearch': '일치 검색을 사용하는 방법',
    'desc.howToSearch':
      '그리스어 나 히브리어 단어 (들) 번역에서, 또는 강한 번호 (들)에서 단어 (들)를 입력합니다. 또는 "번역"또는 검색 옵션에 대한 정렬보기 패널에서 그리스의 히브리어 단어를 클릭합니다.',
    'dict.noDataToDisplay': '검색 기준과 일치하는 데이터가 없습니다.',
    'title.searchTermTooMany': '당신은 너무 많은 키워드를 입력',
    'desc.searchTermTooMany': '당신은 8 개 키워드마다까지 검색 할 수 있습니다.',
    'title.searchDictError': '예기치 않은 오류가 발생했습니다.',
    'desc.searchDictError': '나중에 다시 시도하거나 고객 지원 센터에 문의하시기 바랍니다',
    'alignment.saveAndEditAlignment': '저장 및 정렬 새로운 텍스트',
    'alignment.reviewAlignment': '검토 텍스트 정렬',
    'dict.searchLemma': '원래 단어를 검색 클릭',
    'dict.searchTranslation': '번역 단어를 검색 클릭',
    'dict.searchStrongsX': '스트롱 번호를 검색 클릭',
    'dict.showMenu': '맥락에서 발생의 전체 텍스트를 표시하려면 클릭합니다.',
    'dict.hideMenu': '맥락에서 발생의 전체 텍스트를 숨기려면 클릭합니다.',
  },
  lo: {
    'languages.en': 'ພາສາອັງກິດ',
    'languages.am': 'Amharic',
    'languages.bn': 'Bengali ພາສາ',
    'languages.ceb': 'Cebuano ພາສາ',
    'languages.de': 'ພາສາເຢຍລະມັນ',
    'languages.es-419': 'ພາສາແອສປາໂຍນ',
    'languages.fr': 'ພາສາຝຣັ່ງ',
    'languages.gu': 'Gujarati',
    'languages.hi': 'ຮິນດູ',
    'languages.hr': 'Croatian ພາສາ',
    'languages.hu': 'ຮັງກາຣີພາສາ',
    'languages.id': 'ພາສາອິນໂດເນເຊຍ',
    'languages.km': 'Khmer',
    'languages.kn': 'Kannada',
    'languages.ko': 'ພາສາເກົາຫຼີ',
    'languages.lo': 'ພາສາລາວ',
    'languages.ml': 'ມາລາຢາລໍາ',
    'languages.mr': 'Marathi',
    'languages.ms': 'ມາເລເຊຍ',
    'languages.my': 'ພະມ່າພາສາ',
    'languages.ne': 'ເນປານພາສາ',
    'languages.plt': 'Malagasy ພາສາ',
    'languages.pt-br': 'ປອກຕຸຍການ (Brazil) ພາສາ',
    'languages.ru': 'ພາສາລັດເຊຍ',
    'languages.sw': 'Swahili',
    'languages.ta': 'ທະມິນພາສາ',
    'languages.th': 'ພາສາໄທ',
    'languages.tl': 'Tagalog',
    'languages.te': 'Telegu ພາສາ',
    'languages.vi': 'ພາສາຫວຽດນາມ',
    'languages.zh-hans': 'ພາສາຈີນ (ແບບງ່າຍ)',
    'languages.zh-hant': 'ພາສາຈີນ (ດັ້ງເດີມ)',
    'header.home': 'ຫນ້າທໍາອິດ',
    'header.help': 'ຊ່ວຍເຫຼືອ',
    'header.project': 'ໂຄງການ',
    'header.contact': 'ຕິດຕໍ່',
    'help.contactSupport':
      'ກະລຸນາສົ່ງອີເມວຫາພວກເຮົາທີ່ help@youtranslate.bible ສໍາລັບການຊ່ວຍເຫຼືອ.',
    'home.ytbProvides': 'YouTranslate.Bible freely ໃຫ້:',
    'home.easyToUseTool': 'ການງ່າຍທີ່ຈະນໍາໃຊ້ເຄື່ອງມືການແປພາສາອອນໄລນ໌',
    'home.originalTextHelp': 'ການຊ່ວຍເຫຼືອທີ່ຈະເຂົ້າໃຈຂໍ້ຄວາມຂອງພະຄໍາພີໄດ້ໃນພາສາຕົ້ນສະບັບຂອງຕົນ',
    'home.referencesAndResources': 'ຊັບພະຍາກອນເອກະສານແລະການຝຶກອົບຮົມ',
    'home.computerAssistance': 'ລັດຂອງການສິນລະປະເຕັກໂນໂລຊີຄອມພິວເຕີເພື່ອຊ່ວຍແປພາສາ',
    'profile.userProfile': 'Profile User',
    'profile.language': 'ພາສາ',
    switchDirection: 'ປ່ຽນທິດທາງຂໍ້ຄວາມ',
    'profile.update': 'ການປັບປຸງ',
    'profile.languageWarning':
      'ກູໂກແປພາສາໄດ້ຖືກນໍາໃຊ້ເພື່ອແປພາສາໃນການໂຕ້ຕອບຜູ້ໃຊ້ເປັນພາສາອື່ນທີ່ບໍ່ແມ່ນພາສາອັງກິດເປັນການຊົ່ວຄາວ. YTB ​​ມີການຄວບຄຸມໃນໄລຍະຄວາມຖືກຕ້ອງຫຼືຄວາມຫມັ້ນຄົງຂອງການແປພາສາທີ່ບໍ່ມີ. ທຸກຄົນອາໄສຢູ່ໃນສະຫຼາກແປແລະຄໍາອະທິບາຍໄດ້ຈາກກູໂກແປພາສາບໍ່ສະນັ້ນຢູ່ໃນຄວາມສ່ຽງຂອງຕົນເອງ. ຖ້າຫາກວ່າໃຜແມ່ນມີຄວາມສົນໃຈໃນສາສະຫມັກທີ່ຈະສະຫນອງການແປພາສາທີ່ຖືກຕ້ອງແລະເຊື່ອຖືໄດ້ຂອງການໂຕ້ຕອບຂອງຜູ້ໃຊ້ເປັນພາສາອື່ນທີ່ບໍ່ແມ່ນພາສາອັງກິດ, ກະລຸນາຄລິກໃສ່ຕົວເລືອກຕິດຕໍ່ເພື່ອສົ່ງອີເມລ໌ໄປ GBI.',
    'usernav.profile': 'Profile',
    'usernav.logout': 'ອອກ​ຈາກ​ລະ​ບົບ',
    'usernav.logout.modalTitle': 'ອອກຈາກລະບົບຂອງ YouTranslate.Bible',
    'usernav.logout.modalText':
      'ທ່ານແນ່ໃຈບໍວ່າທ່ານຕ້ອງການທີ່ຈະອອກຈາກລະບົບ? ການເຮັດວຽກທີ່ບໍ່ເຊື່ອທັງຫມົດຈະໄດ້ຮັບການສູນເສຍ.',
    cancel: 'ຍົກເລີກການ',
    delete: 'Delete',
    create: 'ສ້າງ',
    update: 'ການປັບປຸງ',
    edit: 'ດັດແກ້',
    save: 'ບັນທຶກ',
    add: 'ຕື່ມ',
    translate: 'Translate',
    'more.options': 'ທາງເລືອກໃນການເພີ່ມເຕີມ',
    'project.create.title': 'ໂຄງການການແປພາສາພະຄໍາພີ',
    'project.create.list': 'ບັນຊີລາຍຊື່ໂຄງການ',
    'project.list.deleteProject': 'ລຶບ Project?',
    'project.list.areYouSure': 'ທ່ານທີ່ທ່ານກໍາລັງໃຫ້ແນ່ໃຈວ່າຕ້ອງການທີ່ຈະລົບໂຄງການນີ້?',
    'project.list.workWillBeDeleted': 'ໂຄງການແລະການເຮັດວຽກທີ່ກ່ຽວຂ້ອງຈະໄດ້ຮັບການລຶບ.',
    'project.list.completion': 'ສໍາເລັດ:',
    'project.list.createdDate': 'ວັນທີ່ສ້າງ:',
    'project.list.beginningDate': 'ເລີ່ມຕົ້ນວັນທີ່:',
    'project.list.targetCompletionDate': 'ເປົ້າຫມາຍສໍາເລັດທີ່ສະຫມັກ:',
    'project.list.description': 'ອະທິບາຍ:',
    'project.edit': 'ແກ້ໄຂໂຄງການ',
    'project.list.translateBible': 'Translate ຄໍາພີໄບເບິນ',
    'project.list.empty': 'ບັນຊີລາຍຊື່ໂຄງການການແປພາສາຂອງທ່ານແມ່ນເປົ່າ.',
    'project.list.myProjects': 'Projects ຂອງຂ້າພະເຈົ້າຄໍາພີໄບເບິນການແປພາສາ',
    'project.list.newProject': 'ໂຄງການໃຫມ່',
    'project.create.createNew': 'ສ້າງໂຄງການໃຫມ່',
    'project.namePlaceholder': 'ກະລຸນາໃສ່ຊື່ໂຄງການ',
    'project.descriptionPlaceholder': 'ກະລຸນາໃສ່ຄໍາອະທິບາຍໂຄງການ',
    'project.name': 'ຊື່ໂຄງການ',
    'project.beginningDate': 'ເລີ່ມຕົ້ນວັນທີ່',
    'project.targetCompletionDate': 'ເປົ້າຫມາຍສໍາເລັດວັນທີ່',
    'project.description': 'ຄໍາອະທິບາຍ',
    'project.isRTL': 'ການແປພາສາທີ່ຖືກຕ້ອງ, ຫາຊ້າຍ',
    'project.manage-users': 'ການຄຸ້ມຄອງການແປພາສາ',
    'project.new-user': 'ການແປພາສາໃຫມ່',
    'project.add-user': 'Add ແປພາສາ',
    'project.users': 'ແປໂຄງການ',
    'project.read-only': 'ອ່ານ​ຢ່າງ​ດຽວ',
    'project.read&write': 'ອ່ານແລະຂຽນ',
    'project.owner': 'ເຈົ້າຂອງ',
    'project.translator': 'ການແປພາສາ',
    'project.message.no-such-user': 'ແປດັ່ງກ່າວຢູ່',
    'project.message.user-added': 'ການແປພາສາເພີ່ມສົບຜົນສໍາເລັດ',
    'project.message.user-deleted': 'ການແປພາສາອອກ.',
    'project.message.only-owner-can-manage-users': 'ເຈົ້າຂອງໂຄງການພຽງແຕ່ສາມາດມີການຄຸ້ມຄອງຜູ້ຊົມໃຊ້',
    'project.shared': 'ໂຄງການຮ່ວມມື',
    'general.delete-user': 'ແປພາສາ Delete',
    'general.edit-user': 'ຜູ້ແກ້ໄຂ',
    'general.name': 'ຊື່',
    'general.email': 'ອີເມລ໌',
    'general.permissions': 'ການອະນຸຍາດ',
    'general.role': 'ພາລະບົດບາດ',
    signin: 'ເຂົ້າ​ສູ່​ລະ​ບົບ',
    'signin.emailPlaceholder': 'ທີ່​ຢູ່​ອີ​ເມວ',
    'signin.passwordPlaceholder': 'ລະຫັດຜ່ານ',
    'signin.forgotPassword': 'ລືມລະຫັດຜ່ານຂອງທ່ານ?',
    'signin.facebook': 'ເຂົ້າສູ່ລະບົບກັບເຟສບຸກ',
    'signin.google': 'ເຂົ້າສູ່ລະບົບກັບກູໂກ',
    'signin.dontHaveAccount': 'ຍັງບໍ່ມີບັນຊີຢູ່ແລ້ວ?',
    'signin.createOne': 'ລົງທະບຽນໃຫມ່',
    'signup.join': 'ເຂົ້າຮ່ວມ YouTranslate.Bible',
    'signup.createAccountPrompt': 'ສ້າງບັນຊີຂອງທ່ານເພື່ອເລີ່ມຕົ້ນການ.',
    'signup.createAccount': 'ສ້າງ​ບັນ​ຊີ',
    'signup.emailPlaceholder': 'ທີ່​ຢູ່​ອີ​ເມວ',
    'signup.passwordPlaceholder': 'ລະຫັດຜ່ານ',
    'passwordReset.title': 'Reset Password',
    'passwordReset.prompt': 'ຂໍອີເມລ໌ທີ່ມີຄໍາແນະນໍາປ່ຽນລະຫັດຜ່ານຂອງທ່ານ.',
    'passwordReset.emailPlaceholder': 'ທີ່​ຢູ່​ອີ​ເມວ',
    'passwordReset.requestButtonLabel': 'ຂໍໃຊ້ບໍລິ Email',
    'passwordReset.success': 'ອີ​ເມວ​ໄດ້​ຖືກ​ສົ່ງ​ໄປ​ແລ້ວ.',
    'passwordReset.error': 'ຂໍ້​ຜິດ​ພາດ​ໄດ້​ເກີດ​ຂຶ້ນ.',
    'translation.project': 'PROJECT:',
    'translation.backToProjectList': 'ກັບຄືນໄປບ່ອນບັນຊີລາຍຊື່ໂຄງການ',
    'translation.book': 'ປື້ມບັນ:',
    'translation.chapter': 'ບົດທີ:',
    'translation.prompt':
      'ທ່ານທີ່ທ່ານກໍາລັງໃຫ້ແນ່ໃຈວ່າຕ້ອງການທີ່ຈະອອກຈາກບົດນີ້? ການເຮັດວຽກທີ່ບໍ່ເຊື່ອທັງຫມົດຈະໄດ້ຮັບການສູນເສຍ.',
    'translation.prev': 'prev',
    'translation.next': 'ຖັດໄປ',
    'translation.previousChapter': 'ບົດທີ່ຜ່ານມາ',
    'translation.nextChapter': 'ບົດຕໍ່ໄປ',
    'navigator.book': 'ປື້ມບັນ:',
    'navigator.chapter': 'ບົດທີ:',
    'navigator.oldTestament': 'ພຣະສັນຍາເດີມ',
    'navigator.newTestament': 'ພຣະສັນຍາໃຫມ່',
    'navigator.selectBook': 'ເລືອກຫນັງສື',
    'navigator.search': 'ຄົ້ນຫາ',
    'navigator.changeBook': 'book ການປ່ຽນແປງ',
    'navigator.changeChapter': 'ປ່ຽນແປງບົດ',
    Genesis: 'ປະຖົມການ',
    Exodus: 'ອົບພະຍົບ',
    Leviticus: 'ລະບຽບພວກເລວີ',
    Numbers: 'ຈໍານວນ',
    Deuteronomy: 'ພຣະບັນຍັດສອງ',
    Joshua: 'Joshua',
    Judges: 'ຜູ້ພິພາກສາ',
    Ruth: 'Ruth',
    '1 Samuel': '1 ຊາມູເອນ',
    '2 Samuel': '2 ຊາມູເອນ',
    '1 Kings': '1 ກະສັດ',
    '2 Kings': '2 ກະສັດ',
    '1 Chronicles': '1 ຕສຸ້ນຕ່ອຍໂຕ້ວ',
    '2 Chronicles': '2 ພົງສາວະດານ',
    Ezra: 'Ezra',
    Nehemiah: 'ເນເຫມີຢາໄດ້',
    Esther: 'Esther',
    Job: 'ວຽກເຮັດງານທໍາ',
    Psalms: 'ເພງສະດຸດີ',
    Proverbs: 'ສຸພາສິດ',
    Ecclesiastes: 'ປັນຍາຈານ',
    'Song of Songs': 'ສຸພາສິດ',
    Isaiah: 'ເອຊາຢາ',
    Jeremiah: 'Jeremiah',
    Lamentations: 'ຄ້ໍາຄວນ',
    Ezekiel: 'ເອເຊກຽນ',
    Daniel: 'Daniel',
    Hosea: 'Hosea',
    Joel: 'Joel',
    Amos: 'Amos',
    Obadiah: 'ໂອບາດີຢາ',
    Jonah: 'Jonah',
    Micah: 'Micah',
    Nahum: 'Nahum',
    Habakkuk: 'ຮາບາກຸກ',
    Zephaniah: 'Zephaniah',
    Haggai: 'ອາໂມສ',
    Zechariah: 'ຊາຂາຣີຢາ',
    Malachi: 'ມາລາກີ',
    Matthew: 'Matthew',
    Mark: 'Mark',
    Luke: 'ລູກາ',
    John: 'John',
    Acts: 'ການກະທໍາ',
    Romans: 'Romans',
    '1 Corinthians': '1 ໂກຣິນໂທ',
    '2 Corinthians': '2 ໂກຣິນໂທ',
    Galatians: 'ຄາລາເຕຍ',
    Ephesians: 'ເອເຟໂຊ',
    Philippians: 'Philippians',
    Colossians: 'Colossians',
    '1 Thessalonians': '1 ເທຊະໂລນິກ',
    '2 Thessalonians': '2 ເທຊະໂລນິກ',
    '1 Timothy': '1 ຕີໂມທຽວ',
    '2 Timothy': '2 ຕີໂມທຽວ',
    Titus: 'Titus',
    Philemon: 'ຟີເລໂມນ',
    Hebrews: 'ເຮັບເຣີ',
    James: 'James',
    '1 Peter': '1 ເປໂຕ',
    '2 Peter': '2 ເປໂຕ',
    '1 John': '1 John',
    '2 John': '2 John',
    '3 John': '3 John',
    Jude: 'Jude',
    Revelation: 'ການເປີດເຜີຍ',
    'resources.prompt':
      'ຕື່ມການຊັບພະຍາກອນໃນຄໍາພີໄບສໍາລັບຄວາມເຂົ້າໃຈເພີ່ມເຕີມເຂົ້າໄປໃນ passage ທີ່ທ່ານກໍາລັງແປ.',
    'resources.add': 'ເພີ່ມຊັບພະຍາກອນໃນຄໍາພີໄບ',
    Manuscript: 'ຫນັງສືໃບລານ',
    Morphology: 'ສັນຖານວິທະ',
    Lexicon: 'Lexicon',
    'Syntactic Tree': 'syntactic ເປັນໄມ້ຢືນຕົ້ນ',
    'Go to project list': 'ໄປທີ່ບັນຊີລາຍຊື່ໂຄງການ',
    'Project not found': 'ໂຄງການບໍ່ພົບຫຼືການອະນຸຍາດບໍ່ພຽງພໍ.',
    'error.referenceDoesNotExistForResource':
      'ການແປພາສາຄໍາພີໄບເບິນບໍ່ໄດ້ປະກອບດ້ວຍເນື້ອໃນສໍາລັບ passage ທີ່ເລືອກ.',
    'references.addAnother': 'ຕື່ມການແປພາສາອື່ນ',
    'references.referencePrompt': 'ຕື່ມການແປພາສາຄໍາພີໄບເບິນສໍາລັບຄວາມເຂົ້າໃຈເພີ່ມເຕີມ.',
    'references.add': 'ຕື່ມການແປພາສາພະຄໍາພີ',
    'references.licensing': 'ຂໍ້ມູນການອະນຸຍາດ',
    'references.licensingEmpty': 'ບໍ່ມີຂໍ້ມູນລິຂະສິດໄດ້ພົບເຫັນສໍາລັບລາຍການນີ້.',
    'translation.complete': 'ສໍາເລັດການແປພາສາ',
    Alignment: 'ການຈັດຕໍາແຫນ່ງ',
    Share: 'Share',
    'Your Translation': 'ການແປພາສາຂອງທ່ານ',
    'Enter your translation': 'ກະລຸນາໃສ່ການແປພາສາຂອງທ່ານ',
    OK: 'ຕົກ​ລົງ',
    'error.verseTranslationEmpty': 'ທ່ານບໍ່ສາມາດປ່ອຍໃຫ້ເນື້ອໃນການແປພາສາລົບ.',
    'translation.hide.completed': 'ເຊື່ອງສໍາເລັດ',
    alignment: 'ການຈັດຕໍາແຫນ່ງ',
    'alignment.verify': 'ກວດສອບແລະປິດ.',
    'alignment.close': 'ປິດໂດຍບໍ່ມີການຢືນຢັນ',
    'alignment.backToEditor': 'ກັບຄືນໄປບ່ອນບັນນາທິການ',
    'alignment.error':
      'ບາງສິ່ງບາງຢ່າງເອງເຮັດຜິດພາດ. ຖ້າຫາກວ່າຍັງຄົງຄ້າງບັນຫາ, ກະລຸນາຕິດຕໍ່ທີມງານ YouTranslate.Bible.',
    'alignment.add': 'ເພີ່ມການເຊື່ອມຕໍ່',
    'alignment.delete': 'ລົບລິ້ງ',
    'alignment.clear': 'ເລືອກທີ່ຈະແຈ້ງ',
    'alignment.reverse': 'ສະແດງ Reverse',
    'alignment.removeOrClear': 'ເອົາລິ້ງທີ່ເລືອກຫຼືການຄັດເລືອກທີ່ຈະແຈ້ງ.',
    'alignment.addOrRemove': 'ສ້າງການເຊື່ອມຕໍ່ໃຫມ່ຫລືລົບຫນຶ່ງທີ່ຜ່ານມາ.',
    'alignment.selectAndAdd': 'ເລືອກສ່ວນທີ່ສອດຄ້ອງກັນກັບການເຊື່ອມຕໍ່.',
    'error.mustBeSignedToMakeChanges': 'ທ່ານຕ້ອງເຂົ້າສູ່ລະບົບເພື່ອການປ່ຽນແປງເຮັດ.',
    'error.mustBeSignedToTranslateBible': 'ທ່ານຕ້ອງເຂົ້າສູ່ລະບົບການແປພາສາພະຄໍາພີໄດ້.',
    checking: 'ກໍາລັງກວດສອບ',
    'verseEditor.unexpectedError': 'ມີຂໍ້ຜິດພາດທີ່ບໍ່ຄາດຄິດໄດ້ເກີດຂຶ້ນ.',
    verses: 'by Verse',
    words: 'by Word',
    'leftPanel.name': 'ຂໍ້ຄວາມຕົ້ນສະບັບຂອງຄໍາພີໄບເບິນ',
    'rightPanel.name': 'ການແປພາສາຂອງພະຄໍາພີໄດ້',
    'bottomPanel.name': 'ຊັບພະຍາກອນຄໍາພີໄບເບິນ',
    revisionHistory: 'ປະຫວັດການດັດແກ້',
    'syntax.adv': 'Adverbial Modifier',
    'syntax.adjp': 'ປະໂຫຍກຄໍາຄຸນສັບ',
    'syntax.advp': 'ປະໂຫຍກລິຍາພິເສດ',
    'syntax.cl': 'ອານຸປະໂຫຍດ',
    'syntax.conj': 'ຮ່ວມ',
    'syntax.cjp': 'ຮ່ວມ',
    'syntax.det': 'ການຕັດສິນ',
    'syntax.ijp': 'ຄໍາອຸທານ',
    'syntax.nump': 'ປະໂຫຍກຈໍານວນຫລາຍ',
    'syntax.np': 'ພາສາປະໂຫຍກ',
    'syntax.o': 'ວັດຖຸໂດຍກົງ',
    'syntax.o2': 'ວັດຖຸສອງ',
    'syntax.oc': 'Complement ວັດຖຸ',
    'syntax.om': 'ວັດຖຸ Marker',
    'syntax.io': 'ວັດຖຸທາງອ້ອມ',
    'syntax.p': 'ບໍ່ແມ່ນດ້ວຍຄໍາເວົ້າລິຢາ',
    'syntax.pp': 'ປະໂຫຍກບຸພະບົດ',
    'syntax.prep': 'preposition',
    'syntax.ptcl': 'ອະນຸພາກ',
    'syntax.rel': 'ອະນຸພາກພີ່ນ້ອງ',
    'syntax.s': 'Subject',
    'syntax.v': 'ພາສາ',
    'syntax.vc': 'ເຊື່ອມໂຍງ Verb',
    'syntax.adjp.description': 'ປະໂຫຍກທີ່ປຽນເປັນພາສາຫລືຄໍາສັບພະນາມ',
    'syntax.adv.description':
      'ປັບປຸງແກ້ໄຂພາສາຂອງ, ຕາມປົກກະຕິເພີ່ມເຕີມກໍາຫນົດລັກສະນະ, ທີ່ໃຊ້ເວລາ, ສະຖານທີ່, ຄວາມຖີ່, ປະລິນຍາ, ແລະອື່ນໆ ...',
    'syntax.advp.description':
      'ປະໂຫຍກປັບປຸງພາສາຂອງ, ຕາມປົກກະຕິເພີ່ມເຕີມກໍາຫນົດລັກສະນະ, ທີ່ໃຊ້ເວລາ, ສະຖານທີ່, ຄວາມຖີ່, ປະລິນຍາ, ແລະອື່ນໆ',
    'syntax.cl.description': 'A ກຸ່ມຂອງຄໍາສັບຕ່າງໆທີ່ເຮັດໃຫ້ຍື່ນຍັນກ່ຽວກັບວິຊາດັ່ງກ່າວ.',
    'syntax.conj.description': 'ເຊື່ອມຕໍ່ຄໍາສັບຕ່າງໆ, ປະໂຫຍກທີ່, ແລະອານຸປະໂຫຍດ.',
    'syntax.cjp.description': 'ເຊື່ອມຕໍ່ຄໍາສັບຕ່າງໆ, ປະໂຫຍກທີ່, ແລະອານຸປະໂຫຍດ.',
    'syntax.det.description': 'ບຸ, ລະບຸ, ຫຼືດ້ານປະລິມານປະໂຫຍກພາສາ.',
    'syntax.ijp.description': 'Exclamation ຫຼືການສະແດງອອກຢ່າງກະທັນຫັນຂອງຄວາມຮູ້ສຶກ.',
    'syntax.nump.description': 'ປະໂຫຍກທີ່ອະທິບາຍປະລິມານຈໍານວນຫລາຍ',
    'syntax.np.description':
      'A ປະໂຫຍກທີ່ມີພາສາເປັນຫົວຫນ້າຂອງຕົນຫຼືການເຄື່ອນໄຫວທີ່ເປັນພາສາຕົນເອງໄດ້.',
    'syntax.o.description': 'ສິ່ງທີ່ຮັບຜົນກະທົບໂດຍກົງທີ່ເກີດຈາກ, ຫຼືຜະລິດໂດຍການປະຕິບັດຂອງຄໍາໄດ້.',
    'syntax.o2.description': 'ສິ່ງທີ່ຮັບຜົນກະທົບໂດຍກົງທີ່ເກີດຈາກ, ຫຼືຜະລິດໂດຍການປະຕິບັດຂອງຄໍາໄດ້.',
    'syntax.oc.description': 'ເຮັດໃຫ້ຍື່ນຍັນກ່ຽວກັບວັດຖຸ Direct.',
    'syntax.om.description': 'ອະນຸພາກທີ່ແນະນໍາວັດຖຸໂດຍກົງ.',
    'syntax.io.description':
      'ຈຸດປະສົງທີ່ຖືກຮັບຜົນກະທົບໂດຍທາງອ້ອມໂດຍການປະຕິບັດເປັນ, ມັກຜູ້ຮັບຂອງການປະຕິບັດຂອງຄໍາໄດ້.',
    'syntax.p.description':
      'ກໍານົດຕົນຕົວຫຼືອະທິບາຍວິຊາດັ່ງກ່າວ, ມັກ (ແຕ່ບໍ່ແມ່ນສະເຫມີໄປ) ເກີດຂຶ້ນພ້ອມໆກັນກັບພາເຊື່ອມຕໍ່.',
    'syntax.pp.description': 'ປະໂຫຍກປັບປຸງປະກອບມີການ preposition ແລະວັດຖຸຂອງຕົນ.',
    'syntax.prep.description':
      'ສະແດງຄວາມສໍາພັນຂອງຈຸດປະສົງຂອງ preposition ທີ່ມີຄໍາສັບຕ່າງໆອື່ນໆ, ມັກຈະຊີ້ບອກບ່ອນທີ່ຫຼືໃນເວລາທີ່.',
    'syntax.ptcl.description':
      'ຫນຶ່ງສະມາຊິກຈາກກຸ່ມທີ່ແຕກຕ່າງກັນຂອງຄໍາສັບຕ່າງໆການທໍາງານທີ່ບໍ່ຂຶ້ນກັບການໃດໆຂອງ inflected ຫ້ອງຮຽນຄໍາ grammatical',
    'syntax.rel.description': 'ອະນຸພາກທີ່ແນະນໍາການອານຸປະໂຫຍດຂອງພີ່ນ້ອງ.',
    'syntax.s.description':
      'ສ່ວນຂອງອານຸປະໂຫຍດທີ່ສ່ວນທີ່ເຫຼືອຂອງອານຸປະໂຫຍດທີ່ເຮັດໃຫ້ຍື່ນຍັນກ່ຽວກັບ.',
    'syntax.v.description':
      'ລັດບາງສິ່ງບາງຢ່າງກ່ຽວກັບວິຊາຂອງອານຸປະໂຫຍດແລະຂໍສະແດງການປະຕິບັດ, ກໍລະນີ, ຫຼືສະພາບ.',
    'syntax.vc.description': 'ການເຊື່ອມຕໍ່ວິຊາຂອງອານຸປະໂຫຍດເພື່ອໃຫ້ສົມບູນຂຶ້ນໄດ້.',
    'general.close': 'ປິດ',
    'general.action': 'ປະຕິບັດ',
    'general.select': 'ເລືອກ ...',
    'general.back': 'ກັບຄືນໄປບ່ອນ',
    'revisionHistory.noData': 'ບໍ່ມີປະຫວັດສາດການແກ້ໄຂສໍາລັບຂໍ້ນີ້ເປັນຄໍາ.',
    'revisionHistory.translation': 'ການແປພາສາ',
    'revisionHistory.lastChanged': 'ປ່ຽນຫຼ້າສຸດ',
    'sourceText.view': 'ກົດເພື່ອເບິ່ງຂໍ້ຄວາມທີ່ມາ',
    'sourceText.viewStructure': 'ກົດເພື່ອເບິ່ງໂຄງສ້າງແຫຼ່ງຂໍ້ຄວາມ',
    'error.projectIdIsRequired': 'ໂຄງການລະຫັດທີ່ຕ້ອງການ.',
    'error.searchTermIsRequired': 'ໄລຍະການຊອກຫາບໍ່ສາມາດລ້າງ.',
    'error.bookIdIsRequired': 'Book ID ຈໍາເປັນຕ້ອງມີການເກັບຮັກສາຊື່ຫນັງສື.',
    'error.bookNameCannotBeEmpty': 'ຊື່ຫນັງສືບໍ່ສາມາດປ່ອຍຫວ່າງ.',
    'error.signInIsRequiredToAccessProject': 'ທ່ານຕ້ອງເຂົ້າສູ່ລະບົບໃນການເຂົ້າເຖິງໂຄງການຂອງທ່ານ.',
    error: 'Error',
    'error.doNotHavePermissionAccessProject': 'ທ່ານບໍ່ໄດ້ຮັບສິດໃນການເຂົ້າເຖິງໂຄງການນີ້.',
    'error.unauthorized': 'ການເຂົ້າເຖິງຮັບອະນຸຍາດຫຼືການເຂົ້າເຖິງທີ່ບໍ່ຖືກຕ້ອງ token.',
    'error.tokenExpired': 'token ຂອງທ່ານໄດ້ຫມົດອາຍຸແລ້ວ. ກະລຸນາ Re: ເຂົ້າສູ່ລະບົບ.',
    'error.invalidApiUrl': 'url API ບໍ່ຖືກຕ້ອງ.',
    'error.badRequestInvalidJson': 'ການຮ້ອງຂໍບໍ່ຖືກຕ້ອງ. ບໍ່ຖືກຕ້ອງ JSON.',
    'error.windowIsNotDefined': 'ປ່ອງຢ້ຽມບໍ່ໄດ້ກໍາຫນົດ.',
    'error.unexpectedError':
      'ມີຂໍ້ຜິດພາດທີ່ບໍ່ຄາດຄິດໄດ້ເກີດຂຶ້ນ. ກະລຸນາປິດປ່ອງຢ້ຽມນີ້ແລະພະຍາຍາມອີກເທື່ອຫນຶ່ງ.',
    'error.invalidDataType': 'ຊະນິດຂໍ້ມູນທີ່ບໍ່ຖືກຕ້ອງ.',
    'export.data': 'ຂໍ້ມູນການສົ່ງອອກ',
    more: 'ເພີ່ມເຕີມ',
    'export.to.rtf': 'ສົ່ງອອກໄປຍັງ RTF',
    'export.to.usfm': 'ສົ່ງອອກໄປຍັງ USFM',
    'export.titlePreparingData': 'ພວກເຮົາກໍາລັງກະກຽມຂໍ້ມູນການແປພາສາຄໍາພີໄບເບິນ.',
    'export.doNotClose':
      'ຂະບວນການນີ້ອາດໃຊ້ເວລາໃນຂະນະທີ່ເປັນ, ກະລຸນາເຮັດແນວໃດບໍ່ປິດປ່ອງຢ້ຽມນີ້ແລະທ່ານຈະໄດ້ຮັບແຈ້ງໃນເວລາທີ່ມັນແມ່ນພ້ອມທີ່ຈະດາວນ໌ໂຫລດ.',
    'export.desc':
      'ທ່ານສາມາດສົ່ງອອກແລະດາວນ໌ໂຫລດຂໍ້ມູນການແປພາສາຂອງທ່ານໂດຍການຄລິກໃສ່ປຸ່ມຂ້າງລຸ່ມນີ້.',
    'export.titleOfReady2Download': 'ຂໍ້ມູນການແປພາສາພະຄໍາພີຂອງທ່ານແມ່ນກຽມພ້ອມ.',
    'export.textOfReady2Download':
      'ກະລຸນາໃຫ້ຄລິກໃສ່ປຸ່ມດາວໂຫລດຂ້າງລຸ່ມນີ້ເພື່ອດາວນ໌ໂຫລດແປພາສາຄໍາພີໄບເບິນ.',
    'export.statistics': 'ສະຖິຕິ',
    'export.book': 'ຊື່ຫນັງສື',
    'export.translatedVerses': 'ຂໍ້ພຣະຄໍາພີທີ່ໄດ້ແປ',
    'export.exportedVerses': 'ຂໍ້ພຣະຄໍາພີໄດ້ຖືກສົ່ງອອກ',
    'export.howDoUWant2Export': 'ເຮັດແນວໃດທ່ານຕ້ອງການທີ່ຈະສົ່ງອອກຂໍ້ມູນ?',
    'export.whichBooks': 'ທ່ານທີ່ຫນັງສື (s) ຕ້ອງການທີ່ຈະສົ່ງອອກ?',
    'export.byBooks': 'ໂດຍຫນັງສື',
    'export.byChapters': 'ໂດຍບົດ',
    'export.byVerses': 'ໂດຍຂໍ້',
    'export.booksInOt': 'ພຣະສັນຍາເດີມ',
    'export.booksInNt': 'ພຣະສັນຍາໃຫມ່',
    'export.booksInBible': 'ຄໍາພີທັງຫມົດ',
    'export.booksInNone': 'ບໍ່ມີ',
    'export.selectRangeOfChapters': 'ລະດັບຂອງບົດ',
    'export.selectRangeOfVerses': 'ລະດັບຂອງຂໍ້ທີ',
    'export.selectChapter': 'ເລືອກບົດ',
    'export.exportTypeIsRequired': 'ທ່ານຍັງບໍ່ໄດ້ເລືອກກ່ຽວກັບວິທີທີ່ທ່ານຕ້ອງການທີ່ຈະສົ່ງອອກຂໍ້ມູນ?',
    'export.haveNotYetSelectedBooks': 'ທ່ານຍັງບໍ່ໄດ້ເລືອກຫນັງສືໃດຫນຶ່ງ.',
    'error.selectABook': 'ທ່ານຕ້ອງເລືອກຫນັງສືໄດ້.',
    'error.invalidBookId': 'ລະຫັດຫນັງສືບໍ່ຖືກຕ້ອງ.',
    'error.selectAChapter': 'ທ່ານຕ້ອງເລືອກເອົາບົດ.',
    'error.invalidChapterRange': 'ທ່ານຕ້ອງເລືອກເອົາລະດັບພາກທີ່ຖືກຕ້ອງ.',
    'error.invalidVerseRange': 'ທ່ານຕ້ອງເລືອກເອົາລະດັບຂໍ້ທີ່ຖືກຕ້ອງ.',
    'error.invalidExportType': 'ປະເພດການສົ່ງອອກທີ່ບໍ່ຖືກຕ້ອງ.',
    startover: 'ເລີ່ມໃນໄລຍະ',
    download: 'ດາວນ໌ໂຫລດ',
    'download.all': 'ດາວນ໌ໂຫລດໄຟລ໌ທັງຫມົດ',
    'contact.header': 'ຕິດ​ຕໍ່​ພວກ​ເຮົາ',
    'contact.returnAddress': 'Return ທີ່ຢູ່',
    'contact.returnAddressPlaceholder': 'ບ່ອນທີ່ພວກເຮົາສາມາດບັນລຸທ່ານ?',
    'contact.subject': 'Subject',
    'contact.subjectPlaceholder': 'ເປັນແນວໃດທ່ານຕ້ອງການຄວາມຊ່ວຍເຫຼືອມີ?',
    'contact.message': 'ຂໍ້ຄວາມ',
    'contact.messagePlaceholder': 'ແນວໃດທີ່ພວກເຮົາສາມາດຊ່ວຍເຫຼືອ?',
    'contact.sendMessage': 'ສົ່ງ​ຂໍ້​ຄວາມ',
    'contact.success': 'ຂໍ້ຄວາມຖືກສົ່ງສົບຜົນສໍາເລັດ.',
    'contact.failure': 'ມີປັນຫາໃນການສົ່ງຂໍ້ຄວາມນີ້. ກະລຸນາຕິດຕໍ່ຫາ help@globalbibleinitiative.org.',
    selectInterlinear: 'ກົດມີການປ່ຽນແປງຂໍ້ຄວາມ Interlinear',
    'warning.screenTooSmall':
      'ກະລຸນາປ່ຽນການແກ້ໄຂບັນຈໍສູງກວ່າ (ຕ່ໍາສຸດ 1024px ກວ້າງ) ຫຼືກະລຸນາເປີດກວ້າງປ່ອງຢ້ຽມຂອງຕົວທ່ອງເວັບຂອງທ່ານ.',
    'label.dictionary': 'ຄວາມສອດຄ້ອງການຄົ້ນຫາ',
    'dict.search.placeholder': 'ກະລຸນາໃສ່ຄໍາວ່າ (s)',
    'tooltip.manuscript': 'ຫນັງສືໃບລານ',
    'tooltip.strongsx': 'ຈໍານວນທີ່ເຂັ້ມແຂງຂອງ',
    'tooltip.translation': 'ການແປພາສາ',
    'tooltip.english': 'ພາສາອັງກິດ',
    'title.howToSearch': 'ວິທີການໃຊ້ຄົ້ນຫາຄວາມສອດຄ້ອງ',
    'desc.howToSearch':
      'ກະລຸນາໃສ່ຄໍາວ່າ (s) ຈາກກເຣັກຫລືຍິວ, ຄໍາ (s) ຈາກການແປພາສາຂອງທ່ານ, ຫຼືຈໍານວນທີ່ເຂັ້ມແຂງ (s). ຫຼືຄລິກໃສ່ກເຣັກຫລືຍິວຄໍາໃນຄະນະກໍາມະ "ການແປພາສາຂອງທ່ານ" ຫລື views ຕັ້ງສູນສໍາລັບທາງເລືອກໃນການຊອກຫາ.',
    'dict.noDataToDisplay': 'ບໍ່ມີຂໍ້ມູນບໍ່ມີທີ່ກົງກັບເກນການຄົ້ນຫາຂອງທ່ານແມ່ນ.',
    'title.searchTermTooMany': 'ທ່ານໄດ້ເຂົ້າໄປຄໍາຈໍານວນຫຼາຍເກີນໄປ',
    'desc.searchTermTooMany': 'ທ່ານສາມາດຄົ້ນຫາໄດ້ເຖິງ 8 ຄໍາໃນແຕ່ລະຄັ້ງ.',
    'title.searchDictError': 'ມີຂໍ້ຜິດພາດທີ່ບໍ່ຄາດຄິດໄດ້ເກີດຂຶ້ນ.',
    'desc.searchDictError': 'ກະລຸນາພະຍາຍາມອີກເທື່ອຫນຶ່ງຕໍ່ມາຫຼືສະຫນັບສະຫນູນການພົວພັນລູກຄ້າ',
    'alignment.saveAndEditAlignment': 'ຂໍ້ຄວາມ Save ແລະຈັດໃຫມ່',
    'alignment.reviewAlignment': 'ການທົບທວນຄືນການຈັດຂໍ້ຄວາມຂໍ້ຄວາມ',
    'dict.searchLemma': 'ກົດໃນການຊອກຫາຄໍາວ່າຕົ້ນສະບັບ',
    'dict.searchTranslation': 'ກົດໃນການຊອກຫາຄໍາວ່າການແປພາສາ',
    'dict.searchStrongsX': 'ກົດໃນການຊອກຫາຈໍານວນຂອງທີ່ເຂັ້ມແຂງ',
    'dict.showMenu': 'ກົດສະແດງໃຫ້ເຫັນຄວາມເຕັມໄປດ້ວຍການປະກົດຕົວໃນສະພາບການ.',
    'dict.hideMenu': 'ກົດເພື່ອຊ່ອນຂໍ້ຄວາມເຕັມໄປດ້ວຍການປະກົດຕົວໃນສະພາບການ.',
  },
  ml: {
    'languages.en': 'ഇംഗ്ലീഷ്',
    'languages.am': 'അംഹറിക്',
    'languages.bn': 'ബംഗാളി ഭാഷ',
    'languages.ceb': 'സെബുവാനോ ഭാഷ',
    'languages.de': 'ജര്മന് ഭാഷ',
    'languages.es-419': 'സ്പാനിഷ് ഭാഷ',
    'languages.fr': 'ഫ്രഞ്ച് ഭാഷ',
    'languages.gu': 'ഗുജറാത്തി',
    'languages.hi': 'ഹിന്ദി',
    'languages.hr': 'ക്രൊയേഷ്യൻ ഭാഷ',
    'languages.hu': 'ഹംഗേറിയൻ ഭാഷ',
    'languages.id': 'ഇന്തോനേഷ്യൻ ഭാഷ',
    'languages.km': 'ഖെമർ',
    'languages.kn': 'കന്നഡ',
    'languages.ko': 'കൊറിയന് ഭാഷ',
    'languages.lo': 'ലാവോഷിയൻ ഭാഷ',
    'languages.ml': 'മലയാളം',
    'languages.mr': 'മറാത്തി',
    'languages.ms': 'മലേഷ്യൻ',
    'languages.my': 'ബർമീസ് ഭാഷ',
    'languages.ne': 'നേപ്പാളി ഭാഷ',
    'languages.plt': 'പ്രവേശിക്കുക ഭാഷ',
    'languages.pt-br': 'പോർച്ചുഗീസ് (ബ്രസീൽ) ഭാഷ',
    'languages.ru': 'റഷ്യന് ഭാഷ',
    'languages.sw': 'സ്വാഹിലി',
    'languages.ta': 'തമിഴ് ഭാഷ',
    'languages.th': 'തായ് ഭാഷ',
    'languages.tl': 'തഗാലോഗ്',
    'languages.te': 'തെലുങ്ക് ഭാഷ',
    'languages.vi': 'വിയറ്റ്നാമീസ് ഭാഷ',
    'languages.zh-hans': 'ചൈനീസ് ഭാഷ (സിമ്പ്ലിഫീദ്)',
    'languages.zh-hant': 'ചൈനീസ് ഭാഷ (പരമ്പരാഗതം)',
    'header.home': 'വീട്',
    'header.help': 'സഹായിക്കൂ',
    'header.project': 'പദ്ധതി',
    'header.contact': 'കോൺടാക്റ്റ്',
    'help.contactSupport': 'സഹായത്തിനായി help@youtranslate.bible എന്നതിൽ ഞങ്ങൾക്ക് ഇമെയിൽ ചെയ്യുക.',
    'home.ytbProvides': 'YouTranslate.Bible സ്വതന്ത്രമായി നൽകുന്നു:',
    'home.easyToUseTool': 'എളുപ്പത്തിൽ ഉപയോഗിക്കാവുന്ന ഓൺലൈൻ വിവർത്തനം ഉപകരണം',
    'home.originalTextHelp': 'അതിന്റെ യഥാർത്ഥ ഭാഷകളിൽ ബൈബിൾ വാചകം മനസ്സിലാക്കാൻ സഹായം',
    'home.referencesAndResources': 'റഫറൻസ് പരിശീലനവും ഉറവിടങ്ങൾ',
    'home.computerAssistance': 'പരിഭാഷകരുടെ സഹായിക്കാൻ സംസ്ഥാന-അതിവിശിഷ്ട കമ്പ്യൂട്ടർ സാങ്കേതിക',
    'profile.userProfile': 'ഉപയോക്തൃ പ്രൊഫൈൽ',
    'profile.language': 'ഭാഷ',
    switchDirection: 'സ്വിച്ച് വാചക ദിശ',
    'profile.update': 'അപ്ഡേറ്റ് ചെയ്യുക',
    'profile.languageWarning':
      'Google വിവർത്തനം താൽക്കാലികമായി ഇംഗ്ലീഷ് അല്ലാത്ത മറ്റ് ഭാഷകളിലും ഉപയോക്തൃ പ്രതലം വിവർത്തനം ചെയ്യാൻ ഉപയോഗിച്ചു. യ്ത്ബ് വിവർത്തനം കൃത്യത അല്ലെങ്കിൽ വിശ്വാസ്യത യാതൊരു നിയന്ത്രണവും ഉണ്ട്. വിവർത്തനം വിവർത്തനം ലേബലുകൾ, വിശദീകരണങ്ങൾ ഗൂഗിൾ നിന്നും ലഭിച്ച ആശ്രയിക്കാതെ ആർക്കും തന്റെ സ്വന്തം ഉത്തരവാദിത്വത്തിൽ തന്നെ. ആരെങ്കിലും ഇംഗ്ലീഷ് അല്ലാത്ത മറ്റ് ഭാഷകളിലും ഉപയോക്തൃ പ്രതലം കൂടുതൽ കൃത്യമായും വിശ്വസനീയത കുറഞ്ഞ വിവർത്തന നൽകാൻ സന്നദ്ധപ്രവർത്തനം താത്പര്യമുള്ള എങ്കിൽ, ഗ്ബി ഒരു ഇമെയിൽ അയയ്ക്കാൻ ബന്ധപ്പെടാനുള്ള ഓപ്ഷൻ ക്ലിക്ക് ചെയ്യുക.',
    'usernav.profile': 'പ്രൊഫൈൽ',
    'usernav.logout': 'ലോഗൗട്ട്',
    'usernav.logout.modalTitle': 'YouTranslate.Bible ഓഫ് ലോഗൗട്ട്',
    'usernav.logout.modalText':
      'നിങ്ങൾക്ക് ഉറപ്പാണോ ലോഗൌട്ട് ചെയ്യണമെന്ന് ഉറപ്പാണോ? എല്ലാ സംരക്ഷിക്കാത്ത വർക്ക് നഷ്ടപ്പെടും.',
    cancel: 'റദ്ദാക്കുക',
    delete: 'ഇല്ലാതാക്കുക',
    create: 'സൃഷ്ടിക്കാൻ',
    update: 'അപ്ഡേറ്റ് ചെയ്യുക',
    edit: 'തിരുത്തുക',
    save: 'രക്ഷിക്കും',
    add: 'ചേർക്കുക',
    translate: 'വിവര്ത്തനം ചെയ്യുക',
    'more.options': 'കൂടുതൽ ഓപ്ഷനുകൾ',
    'project.create.title': 'ബൈബിൾ പരിഭാഷ പദ്ധതി',
    'project.create.list': 'പദ്ധതി ലിസ്റ്റ്',
    'project.list.deleteProject': 'പ്രോജക്റ്റ് ഇല്ലാതാക്കുക?',
    'project.list.areYouSure': 'നിങ്ങൾക്ക് ഈ പ്രോജക്റ്റ് ഇല്ലാതാക്കണമെന്ന് ഉറപ്പാണോ?',
    'project.list.workWillBeDeleted': 'പദ്ധതി ബന്ധപ്പെട്ട വല്ല പണിക്കും ഇല്ലാതാക്കും.',
    'project.list.completion': 'പൂർത്തിയാക്കൽ:',
    'project.list.createdDate': 'സൃഷ്ടിച്ച തീയതി:',
    'project.list.beginningDate': 'ആരംഭിക്കുന്നു തീയതി:',
    'project.list.targetCompletionDate': 'പൂർത്തിയാക്കുകയായിരുന്നു:',
    'project.list.description': 'വിവരണം:',
    'project.edit': 'എഡിറ്റ് പദ്ധതി',
    'project.list.translateBible': 'ബൈബിൾ വിവർത്തനം',
    'project.list.empty': 'നിങ്ങളുടെ ട്രാൻസ്ലേഷൻ പരിപാടിയുടെ ലിസ്റ്റ് ശൂന്യമാണ്.',
    'project.list.myProjects': 'എന്റെ ബൈബിൾ പരിഭാഷ പദ്ധതികൾ',
    'project.list.newProject': 'പുതിയ പദ്ധതി',
    'project.create.createNew': 'ഒരു പുതിയ പ്രോജക്റ്റ് സൃഷ്ടിക്കുക',
    'project.namePlaceholder': 'പ്രോജക്റ്റ് നാമം നൽകുക',
    'project.descriptionPlaceholder': 'പദ്ധതി വിവരണം നൽകുക',
    'project.name': 'പദ്ധതിയുടെ പേര്',
    'project.beginningDate': 'ആരംഭിക്കുന്നു തീയതി',
    'project.targetCompletionDate': 'പൂർത്തിയാക്കുകയായിരുന്നു',
    'project.description': 'വിവരണം',
    'project.isRTL': 'പരിഭാഷ വലത്തുനിന്ന് ഇടത്തോട്ട് ആണ്',
    'project.manage-users': 'പരിഭാഷകർ നിയന്ത്രിക്കുക',
    'project.new-user': 'പുതിയ വിവർത്തകൻ',
    'project.add-user': 'വിവർത്തകൻ ചേർക്കുക',
    'project.users': 'പദ്ധതി പരിഭാഷകർക്ക്',
    'project.read-only': 'വായിക്കാൻ മാത്രം',
    'project.read&write': 'വായിക്കുക & എഴുതുക',
    'project.owner': 'ഉടമ',
    'project.translator': 'വിവര്ത്തകന്',
    'project.message.no-such-user': 'അത്തരം വിവർത്തകൻ നിലവിലുണ്ട്',
    'project.message.user-added': 'പരിഭാഷകൻ വിജയകരമായി ചേർത്തു',
    'project.message.user-deleted': 'പരിഭാഷകൻ നീക്കം.',
    'project.message.only-owner-can-manage-users':
      'മാത്രം പ്രോജക്റ്റ് ഉടമ ഉപയോക്താക്കളെ നിയന്ത്രിക്കാനാകൂ',
    'project.shared': 'സഹകരണ പദ്ധതിയാണിത്',
    'general.delete-user': 'ഇല്ലാതാക്കുക വിവർത്തകൻ',
    'general.edit-user': 'ഉപയോക്താവിനെ എഡിറ്റുചെയ്യുക',
    'general.name': 'പേര്',
    'general.email': 'ഇ-മെയിൽ',
    'general.permissions': 'അനുമതികൾ',
    'general.role': 'പങ്ക്',
    signin: 'സൈൻ ഇൻ',
    'signin.emailPlaceholder': 'ഈ - മെയില് വിലാസം',
    'signin.passwordPlaceholder': 'Password',
    'signin.forgotPassword': 'നിങ്ങളുടെ രഹസ്യ വാക്ക് മറന്നോ?',
    'signin.facebook': 'Facebook ഉപയോഗിച്ച് സൈൻ ഇൻ',
    'signin.google': 'Google ഉപയോഗിച്ച് സൈൻ ഇൻ',
    'signin.dontHaveAccount': 'ഒരു അക്കൗണ്ട് ഇല്ലേ?',
    'signin.createOne': 'ഒരെണ്ണം ഉണ്ടാക്കുക',
    'signup.join': 'YouTranslate.Bible ചേരുക',
    'signup.createAccountPrompt': 'ആരംഭിക്കുന്നതിന് നിങ്ങളുടെ സൗജന്യ അക്കൗണ്ട് സൃഷ്ടിക്കുക.',
    'signup.createAccount': 'അക്കൗണ്ട് സൃഷ്ടിക്കുക',
    'signup.emailPlaceholder': 'ഈ - മെയില് വിലാസം',
    'signup.passwordPlaceholder': 'Password',
    'passwordReset.title': 'പാസ്വേഡ് റീസെറ്റ്',
    'passwordReset.prompt':
      'നിങ്ങളുടെ പാസ്വേഡ് പുനഃക്രമീകരിക്കാൻ നിർദ്ദേശങ്ങളടങ്ങിയ ഒരു ഇമെയിൽ അഭ്യർത്ഥിക്കുക.',
    'passwordReset.emailPlaceholder': 'ഈ - മെയില് വിലാസം',
    'passwordReset.requestButtonLabel': 'അഭ്യർത്ഥന ഇമെയിൽ',
    'passwordReset.success': 'ഇമെയിൽ അയച്ചു.',
    'passwordReset.error': 'ഒരു പിശക് സംഭവിച്ചു.',
    'translation.project': 'പദ്ധതി:',
    'translation.backToProjectList': 'പദ്ധതി പട്ടികയിലേക്ക് തിരികെ',
    'translation.book': 'പുസ്തകം:',
    'translation.chapter': 'അദ്ധ്യായം:',
    'translation.prompt':
      'നിങ്ങൾക്ക് ഈ അധ്യായം വിടണമെന്ന് തീർച്ചയാണോ? എല്ലാ സംരക്ഷിക്കാത്ത വർക്ക് നഷ്ടപ്പെടും.',
    'translation.prev': 'മുമ്പത്തെ',
    'translation.next': 'അടുത്തത്',
    'translation.previousChapter': 'മുമ്പത്തെ അധ്യായം',
    'translation.nextChapter': 'അടുത്ത അധ്യായം',
    'navigator.book': 'പുസ്തകം:',
    'navigator.chapter': 'അദ്ധ്യായം:',
    'navigator.oldTestament': 'പഴയ നിയമം',
    'navigator.newTestament': 'പുതിയ നിയമം',
    'navigator.selectBook': 'ഒരു പുസ്തകം തിരഞ്ഞെടുക്കുക',
    'navigator.search': 'തിരയൽ',
    'navigator.changeBook': 'പുസ്തകം മാറ്റുക',
    'navigator.changeChapter': 'മാറ്റുക അധ്യായം',
    Genesis: 'ഉല്പത്തി',
    Exodus: 'പുറപ്പാട്',
    Leviticus: 'ലേവ്യപുസ്തകം',
    Numbers: 'നമ്പറുകൾ',
    Deuteronomy: 'ആവർത്തനം',
    Joshua: 'യോശുവ',
    Judges: 'ന്യായാധിപന്മാർ',
    Ruth: 'ruth',
    '1 Samuel': '1 ശമൂവേൽ',
    '2 Samuel': '2 ശമൂവേൽ',
    '1 Kings': '1 രാജാക്കന്മാർ',
    '2 Kings': '2 രാജാക്കന്മാർ',
    '1 Chronicles': '1 ദിനവൃത്താന്തം',
    '2 Chronicles': '2 ദിനവൃത്താന്തം',
    Ezra: 'എസ്രാ',
    Nehemiah: 'നെഹെമ്യാവു',
    Esther: 'എസ്ഥേർ',
    Job: 'ജോലി',
    Psalms: 'സങ്കീർത്തനങ്ങൾ',
    Proverbs: 'സദൃശ്യവാക്യങ്ങൾ',
    Ecclesiastes: 'സഭാപ്രസംഗി',
    'Song of Songs': 'ഉത്തമഗീതം',
    Isaiah: 'യെശയ്യാവു',
    Jeremiah: 'യിരെമ്യാവു',
    Lamentations: 'വിലാപങ്ങൾ',
    Ezekiel: 'യെഹെസ്കേൽ',
    Daniel: 'ദാനിയേൽ',
    Hosea: 'ഹോശേയ',
    Joel: 'യോവേൽ',
    Amos: 'ആമോസ്',
    Obadiah: 'ഓബദ്യാവു',
    Jonah: 'യൂനുസ്',
    Micah: 'മീഖാവു',
    Nahum: 'നഹൂം',
    Habakkuk: 'ഹബക്കൂക്',
    Zephaniah: 'സെഫന്യാവു',
    Haggai: 'ഹഗ്ഗായി',
    Zechariah: 'സെഖർയ്യാവു',
    Malachi: 'മലാഖി',
    Matthew: 'മത്തായി',
    Mark: 'അടയാളം',
    Luke: 'ലൂക്കോസ്',
    John: 'ജോൺ',
    Acts: 'പ്രവൃത്തികൾ',
    Romans: 'റോമർ',
    '1 Corinthians': '1 കൊരിന്ത്യർ',
    '2 Corinthians': '2 കൊരിന്ത്യർ',
    Galatians: 'ഗലാത്യർ',
    Ephesians: 'എഫെസ്യർ',
    Philippians: 'ഫിലി',
    Colossians: 'കൊലൊസ്സ്യർ',
    '1 Thessalonians': '1 തെസ്സലൊനീക്യർ',
    '2 Thessalonians': '2 തെസ്സലൊനീക്യർ',
    '1 Timothy': '1 തിമൊഥെയൊസ്',
    '2 Timothy': '2 തിമൊഥെയൊസ്',
    Titus: 'ടൈറ്റസ്',
    Philemon: 'ഫിലേമോൻ',
    Hebrews: 'എബ്രായർ',
    James: 'ജെയിംസ്',
    '1 Peter': '1 പത്രോസ്',
    '2 Peter': '2 പത്രോസ്',
    '1 John': '1 യോഹന്നാൻ',
    '2 John': '2 യോഹന്നാൻ',
    '3 John': '3 യോഹന്നാൻ',
    Jude: 'യൂദാ',
    Revelation: 'വെളിപ്പെടുന്ന',
    'resources.prompt': 'നിങ്ങൾ വിവർത്തനം ചുരം അധിക ഉൾക്കാഴ്ച എഴുതുന്നത് വിഭവങ്ങൾ ചേർക്കുക.',
    'resources.add': 'ഒരു ബൈബിൾ റിസോഴ്സ് ചേർക്കുക',
    Manuscript: 'കയ്യെഴുത്തുപ്രതി',
    Morphology: 'രൂപഘടന',
    Lexicon: 'ലെക്സിക്കൺ',
    'Syntactic Tree': 'വായനാനുഭവം ട്രീ',
    'Go to project list': 'പദ്ധതി ലിസ്റ്റിലേക്ക് പോകുക',
    'Project not found': 'പദ്ധതി കണ്ടെത്തി അല്ലെങ്കിൽ അപര്യാപ്തമായ അനുമതികൾ.',
    'error.referenceDoesNotExistForResource':
      'ഈ ബൈബിൾ പരിഭാഷ തിരഞ്ഞെടുത്ത പോകാനായി ഉള്ളടക്കം അടങ്ങിയിട്ടില്ല.',
    'references.addAnother': 'മറ്റൊരു വിവർത്തനം ചേർക്കുക',
    'references.referencePrompt': 'അധിക ഉൾക്കാഴ്ച മറ്റ് ബൈബിൾ വിവർത്തനങ്ങൾ ചേർക്കുക.',
    'references.add': 'ഒരു ബൈബിൾ വിവർത്തനം ചേർക്കുക',
    'references.licensing': 'ലൈസൻസിംഗ് വിവരം',
    'references.licensingEmpty': 'ഇല്ല ലൈസൻസിംഗ് വിവരങ്ങൾ ഈ ഇനം കണ്ടെത്തിയില്ല.',
    'translation.complete': 'പരിഭാഷ പൂർത്തിയായി',
    Alignment: 'വിന്യാസം',
    Share: 'പങ്കിടുക',
    'Your Translation': 'നിങ്ങളുടെ പരിഭാഷ',
    'Enter your translation': 'നിങ്ങളുടെ വിവർത്തന നൽകുക',
    OK: 'ശരി',
    'error.verseTranslationEmpty': 'നിങ്ങൾ ശൂന്യമായ വിവർത്തന ഉള്ളടക്കം വിട്ടു കഴിയില്ല.',
    'translation.hide.completed': 'മറയ്ക്കുക പൂർത്തിയായി',
    alignment: 'വിന്യാസം',
    'alignment.verify': 'പരിശോധിക്കുക അടക്കുക.',
    'alignment.close': 'പരിശോധിച്ചുറപ്പിക്കാതെ അടയ്ക്കുക',
    'alignment.backToEditor': 'എഡിറ്റർ മടങ്ങുക',
    'alignment.error':
      'എന്തോ കുഴപ്പം സംഭവിച്ചു. പ്രശ്നം നിലനിൽക്കുകയാണെങ്കിൽ, YouTranslate.Bible ടീം ബന്ധപ്പെടുക.',
    'alignment.add': 'ലിങ്ക് ചേർക്കുക',
    'alignment.delete': 'ലിങ്ക് നീക്കം',
    'alignment.clear': 'തിരഞ്ഞെടുത്തത് മായ്ക്കുക',
    'alignment.reverse': 'റിവേഴ്സ് പ്രദർശിപ്പിക്കുക',
    'alignment.removeOrClear': 'തിരഞ്ഞെടുത്ത ലിങ്ക് അല്ലെങ്കിൽ വ്യക്തമായ തിരഞ്ഞെടുപ്പ് മാറ്റുക.',
    'alignment.addOrRemove': 'ഒരു പുതിയ ലിങ്ക് സൃഷ്ടിക്കുക അല്ലെങ്കിൽ മുൻ ഒന്ന് ഇല്ലാതാക്കണം.',
    'alignment.selectAndAdd': 'ലിങ്ക് സെഗ്മെൻറ് സദൃശ്യമായ തിരഞ്ഞെടുക്കുക.',
    'error.mustBeSignedToMakeChanges': 'നിങ്ങൾ വരുത്തുന്ന മാറ്റങ്ങൾ പ്രവേശിച്ചിരിക്കണം.',
    'error.mustBeSignedToTranslateBible': 'ബൈബിൾ വിവർത്തനം ചെയ്യാൻ ഇൻ ചെയ്തിരിക്കണം.',
    checking: 'പരിശോധന',
    'verseEditor.unexpectedError': 'അപ്രതീക്ഷിതമായ ഒരു പിശക് സംഭവിച്ചു.',
    verses: 'Verse വഴി',
    words: 'പദം പ്രകാരം',
    'leftPanel.name': 'ബൈബിൾ യഥാർത്ഥ ഉള്ളടക്കം',
    'rightPanel.name': 'ബൈബിൾ വിവർത്തനങ്ങൾ',
    'bottomPanel.name': 'ബൈബിൾ ഉറവിടങ്ങൾ',
    revisionHistory: 'റിവിഷൻ ചരിത്രം',
    'syntax.adv': 'അദ്വെര്ബിഅല് മോഡിഫയർ',
    'syntax.adjp': 'വിശേഷണം വാചകം',
    'syntax.advp': 'ക്രിയാവിശേഷണം വാചകം',
    'syntax.cl': 'ഉടന്വടി',
    'syntax.conj': 'സംയോജിച്ച്',
    'syntax.cjp': 'സംയോജിച്ച്',
    'syntax.det': 'ദെതെര്മിനെര്',
    'syntax.ijp': 'interjection',
    'syntax.nump': 'ന്യൂമറിക്കൽ വാചകം',
    'syntax.np': 'നാമം വാചകം',
    'syntax.o': 'നേരിട്ടുള്ള ഒബ്ജക്റ്റ്',
    'syntax.o2': 'രണ്ടാം ഒബ്ജക്റ്റ്',
    'syntax.oc': 'ഒബ്ജക്റ്റ് Complement',
    'syntax.om': 'ഒബ്ജക്റ്റ് മാർക്കർ',
    'syntax.io': 'പരോക്ഷ ഒബ്ജക്റ്റ്',
    'syntax.p': 'നോൺ-സംഭാഷണം ആഖ്യാത',
    'syntax.pp': 'പ്രെപൊസിതിഒനല് വാചകം',
    'syntax.prep': 'വിഭക്തുപസര്ഗം',
    'syntax.ptcl': 'കണം',
    'syntax.rel': 'ആപേക്ഷിക പാർട്ടിക്കിൾ',
    'syntax.s': 'വിഷയം',
    'syntax.v': 'കിയാപദം',
    'syntax.vc': 'ലിങ്കു ക്രിയ',
    'syntax.adjp.description': 'മാറ്റം ഒരുനാമപദമാണു് അല്ലെങ്കിൽ സർവ്വനാമം .സ്കൂളിലേക്കുള്ള',
    'syntax.adv.description':
      'മാറ്റം ക്രിയ, സാധാരണ കൂടുതൽ വിധത്തിൽ, സമയം, സ്ഥലം, ആവൃത്തി, ഡിഗ്രി, തുടങ്ങിയ നിർവ്വചനത്തിൽ ...',
    'syntax.advp.description':
      'വാചകം ക്രിയ മാറ്റം സാധാരണ കൂടുതൽ വിധത്തിൽ, സമയം, സ്ഥലം, ആവൃത്തി, ഡിഗ്രി, മുതലായവ നിർവ്വചനത്തിൽ',
    'syntax.cl.description': 'വിഷയത്തെക്കുറിച്ച് ഒരു വാദത്തെ സത്യമെന്ന് വാക്കുകൾ ഒരു സംഘം.',
    'syntax.conj.description': 'വാക്കുകൾ ശൈലികളും ക്ലോസുകളെ ബന്ധിപ്പിക്കുന്നു.',
    'syntax.cjp.description': 'വാക്കുകൾ ശൈലികളും ക്ലോസുകളെ ബന്ധിപ്പിക്കുന്നു.',
    'syntax.det.description':
      'വ്യക്തമാക്കുക, തിരിച്ചറിയുക, അല്ലെങ്കിൽ കുഅംതിഫിഎസ് ഒരുനാമപദമാണു് വാക്യം.',
    'syntax.ijp.description': 'ആശ്ചര്യചിഹ്നം അല്ലെങ്കിൽ വികാര പെട്ടെന്ന് പദപ്രയോഗം.',
    'syntax.nump.description': 'സംഖ്യാപരമായ അളവ് വിവരിക്കുന്ന വാചകം',
    'syntax.np.description':
      'ഒരു ക്രിയ അല്ലെങ്കിൽ അതിന്റെ തല പോലെ ഒരു ക്രിയ ആ പ്രവർത്തനങ്ങൾ ഉണ്ട് എന്ന് ഒരു വാക്യം.',
    'syntax.o.description':
      'ഏത് നേരിട്ട്, ബാധിച്ചത് കാരണം, അല്ലെങ്കിൽ ഒരു ക്രിയ പ്രവർത്തനം നിർമ്മിക്കുന്നത് ആ.',
    'syntax.o2.description':
      'ഏത് നേരിട്ട്, ബാധിച്ചത് കാരണം, അല്ലെങ്കിൽ ഒരു ക്രിയ പ്രവർത്തനം നിർമ്മിക്കുന്നത് ആ.',
    'syntax.oc.description': 'നേരിട്ടുള്ള ഒബ്ജക്റ്റ് ഏകദേശം ഒരു പരിധിവരെ ചെയ്യുന്നു.',
    'syntax.om.description': 'നേരിട്ട് വസ്തു ഉൾപ്പെടുത്താൻ കണികാ.',
    'syntax.io.description':
      'പരോക്ഷമായി ഒരു പ്രവർത്തനം ബാധിക്കുന്ന ഒബ്ജക്റ്റ് ക്രിയ പ്രവർത്തന പലപ്പോഴും സ്വീകർത്താവ്.',
    'syntax.p.description':
      'തിരിച്ചറിയുന്ന അല്ലെങ്കിൽ വിഷയം വിവരിക്കുന്ന, പലപ്പോഴും (എപ്പോഴും ഇല്ല) ഒരു ലിങ്കിംഗ് ക്രിയാപദം ഉപയോഗിച്ച് ഒന്നിച്ചു സംഭവിക്കുന്നത്.',
    'syntax.pp.description': 'പരിഷ്ക്കരിക്കുന്നത് .സമയം അതിന്റെ വസ്തുവിന്റെ അടങ്ങുന്ന വാചകം.',
    'syntax.prep.description':
      'എവിടെ അല്ലെങ്കിൽ പലപ്പോഴും സൂചിപ്പിക്കുന്ന, മറ്റ് വാക്കുകളാൽ വിഭക്തുപസര്ഗം എന്ന വസ്തുവിന്റെ ബന്ധം സൂചിപ്പിക്കുന്നു.',
    'syntax.ptcl.description':
      'ഇന്ഫ്ലെച്തെദ് വ്യാകരണ വചനം ക്ലാസുകൾ ഉൾപ്പെടുന്നില്ലായെങ്കിൽ എന്ന് ഫംഗ്ഷൻ വാക്കുകളുടെ വിഭിന്ന ഗ്രൂപ്പിൽ നിന്നും ഒരു അംഗം',
    'syntax.rel.description': 'ഒരു ബന്ധു ക്ലോസ് ഉൾപ്പെടുത്താൻ കണികാ.',
    'syntax.s.description': 'ാ ം ബാക്കി ഏകദേശം ഒരു വാദത്തെ ചെയ്യുന്നു വ്യവസ്ഥ ഭാഗമായി.',
    'syntax.v.description':
      'എസ് വ്യവസ്ഥ വിഷയത്തെക്കുറിച്ച് എന്തെങ്കിലും നടപടി, ഇവന്റ്, അല്ലെങ്കിൽ അവസ്ഥ വരാം.',
    'syntax.vc.description': 'ഒരു വിഷയം യോജിച്ച ഒരു ക്ലോസ് വിഷയം ഉപയോഗിക്കുന്നില്ല.',
    'general.close': 'അടയ്ക്കുക',
    'general.action': 'ആക്ഷൻ',
    'general.select': 'തിരഞ്ഞെടുക്കുക ...',
    'general.back': 'തിരികെ',
    'revisionHistory.noData': 'ഈ വാക്യം യാതൊരു അവലോകന ചരിത്രത്തെ ഇല്ല.',
    'revisionHistory.translation': 'വിവർത്തനം',
    'revisionHistory.lastChanged': 'അവസാനം മാറ്റിയത്',
    'sourceText.view': 'കാഴ്ച ഉറവിട ടെക്സ്റ്റ് ക്ലിക്കുചെയ്യുക',
    'sourceText.viewStructure': 'കാഴ്ച ഉറവിട ടെക്സ്റ്റ് ഘടന ക്ലിക്കുചെയ്യുക',
    'error.projectIdIsRequired': 'പ്രോജക്ട് ഐഡി ആവശ്യമാണ്.',
    'error.searchTermIsRequired': 'തിരയൽ പദം ശൂന്യമാക്കാനാവില്ല.',
    'error.bookIdIsRequired': 'ബുക്ക് ഐഡി സ്റ്റോർ പുസ്തകത്തിന്റെ പേര് ആവശ്യമാണ്.',
    'error.bookNameCannotBeEmpty': 'പുസ്തകത്തിന്റെ പേര് ശൂന്യമായിടാൻ കഴിയില്ല.',
    'error.signInIsRequiredToAccessProject': 'നിങ്ങളുടെ പദ്ധതി ആക്സസ് ചെയ്യാൻ ഇൻ ചെയ്തിരിക്കണം.',
    error: 'പിശക്',
    'error.doNotHavePermissionAccessProject': 'ഈ പദ്ധതി ആക്സസ് ചെയ്യാൻ അനുമതി ഇല്ല.',
    'error.unauthorized': 'അംഗീകൃതമല്ലാത്ത ആക്സസ് അസാധുവാണ് ആക്സസ് ടോക്കൺ.',
    'error.tokenExpired': 'നിങ്ങളുടെ ടോക്കൺ കാലഹരണപ്പെട്ടു. ദയവായി വീണ്ടും ലോഗിൻ.',
    'error.invalidApiUrl': 'അസാധുവായ API URL.',
    'error.badRequestInvalidJson': 'മോശം അഭ്യർത്ഥന. അസാധുവായ ഒരു JSON.',
    'error.windowIsNotDefined': 'വിൻഡോ നിർവ്വചിക്കുന്നില്ല.',
    'error.unexpectedError':
      'അപ്രതീക്ഷിതമായ ഒരു പിശക് സംഭവിച്ചു. ഈ ജാലകം അടച്ച് വീണ്ടും ശ്രമിക്കുക.',
    'error.invalidDataType': 'അസാധുവായ ഡാറ്റ തരം.',
    'export.data': 'കയറ്റുമതി ഡാറ്റ',
    more: 'കൂടുതൽ',
    'export.to.rtf': 'RTF എക്സ്പോർട്ട് ചെയ്യുക',
    'export.to.usfm': 'ഉസ്ഫ്മ് എക്സ്പോർട്ട് ചെയ്യുക',
    'export.titlePreparingData': 'നിങ്ങളുടെ ബൈബിൾ വിവർത്തന ഡാറ്റ തയ്യാറാക്കുകയാണ്.',
    'export.doNotClose':
      'ഈ പ്രക്രിയ ഒരു സമയം എടുത്തേക്കാം, അടയ്ക്കുമ്പോൾ ഈ ജാലകം ചെയ്യാൻ അത് ഡൌൺലോഡ് തയ്യാറാകുമ്പോൾ നിങ്ങളെ അറിയിക്കും ദയവായി.',
    'export.desc':
      'നിങ്ങൾ കയറ്റുമതി താഴെ ബട്ടണുകൾ ക്ലിക്ക് ചെയ്ത് നിങ്ങളുടെ വിവർത്തന ഡാറ്റ ഡൗൺലോഡ് ചെയ്യാവുന്നതാണ്.',
    'export.titleOfReady2Download': 'നിങ്ങളുടെ ബൈബിൾ പരിഭാഷയെ ഡാറ്റ തയ്യാറാണ്.',
    'export.textOfReady2Download':
      'നിങ്ങളുടെ ബൈബിൾ വിവർത്തനം ഡൌൺലോഡ് ചെയ്യാൻ താഴെയുള്ള ഡൗൺലോഡ് ബട്ടൺ ക്ലിക്ക് ചെയ്യുക.',
    'export.statistics': 'സ്ഥിതിവിവരക്കണക്കുകൾ',
    'export.book': 'പുസ്തകം പേര്',
    'export.translatedVerses': 'വിവർത്തനം ചെയ്ത സൂക്തങ്ങൾ',
    'export.exportedVerses': 'എക്സ്പോർട്ട് സൂക്തങ്ങൾ',
    'export.howDoUWant2Export': 'നിങ്ങൾ എങ്ങനെ കയറ്റുമതി ഡാറ്റ ആഗ്രഹിക്കുന്നത്?',
    'export.whichBooks': 'നിങ്ങൾ ഏത് പുസ്തകം (ങ്ങൾ) കയറ്റുമതി ചെയ്യേണ്ടത്?',
    'export.byBooks': 'പുസ്തകങ്ങൾ വഴി',
    'export.byChapters': 'അധ്യായങ്ങൾ വഴി',
    'export.byVerses': 'നില്പ്പുന്ട്',
    'export.booksInOt': 'പഴയ നിയമം',
    'export.booksInNt': 'പുതിയ നിയമം',
    'export.booksInBible': 'മുഴുവൻ ബൈബിൾ',
    'export.booksInNone': 'ഒന്നുമില്ല',
    'export.selectRangeOfChapters': 'അധ്യായങ്ങൾ പരിധി',
    'export.selectRangeOfVerses': 'സൂക്തങ്ങൾ ശ്രേണി',
    'export.selectChapter': 'ഒരു അധ്യായം തിരഞ്ഞെടുക്കുക',
    'export.exportTypeIsRequired':
      'നിങ്ങൾ ഇതുവരെ കയറ്റുമതി ഡാറ്റ ആഗ്രഹിക്കുന്നത് എങ്ങനെ തിരഞ്ഞെടുത്തിട്ടില്ല?',
    'export.haveNotYetSelectedBooks': 'നിങ്ങൾ ഇതുവരെ പുസ്തകം തിരഞ്ഞെടുത്തിട്ടില്ല.',
    'error.selectABook': 'നിങ്ങൾ ഒരു പുസ്തകം തിരഞ്ഞെടുക്കണം.',
    'error.invalidBookId': 'അസാധുവായ പുസ്തകം ഐഡി.',
    'error.selectAChapter': 'നിങ്ങൾ ഒരു അധ്യായം തിരഞ്ഞെടുക്കണം.',
    'error.invalidChapterRange': 'നിങ്ങൾ ഒരു സാധുവായ അധ്യായം ശ്രേണി തിരഞ്ഞെടുക്കണം.',
    'error.invalidVerseRange': 'നിങ്ങൾ ഒരു സാധുവായ വാക്യം ശ്രേണി തിരഞ്ഞെടുക്കണം.',
    'error.invalidExportType': 'അസാധുവായ എക്സ്പോർട്ട് തരം.',
    startover: 'ആരംഭിച്ചുകഴിഞ്ഞു',
    download: 'ഡൗൺലോഡ്',
    'download.all': 'എല്ലാ ഫയലുകളും ഡൗൺലോഡ്',
    'contact.header': 'ഞങ്ങളെ സമീപിക്കുക',
    'contact.returnAddress': 'തിരിച്ചയക്കാനുള്ള വിലാസം',
    'contact.returnAddressPlaceholder': 'ഞങ്ങൾ നിങ്ങൾക്ക് എവിടെ എത്തിച്ചേരാൻ കഴിയും?',
    'contact.subject': 'വിഷയം',
    'contact.subjectPlaceholder': 'എന്ത് സഹായമാണ് എത്ര പണം വേണം?',
    'contact.message': 'സന്ദേശം',
    'contact.messagePlaceholder': 'എങ്ങനെ നമുക്ക് സഹായിക്കാം?',
    'contact.sendMessage': 'സന്ദേശം അയയ്ക്കുക',
    'contact.success': 'സന്ദേശം അയച്ചു.',
    'contact.failure': 'ഈ സന്ദേശം ഒരു പ്രശ്നമുണ്ടായി. help@globalbibleinitiative.org ബന്ധപ്പെടുക.',
    selectInterlinear: 'ടെക്സ്റ്റ് ഇന്റർലീനിയർ മാറ്റം ക്ലിക്കുചെയ്യുക',
    'warning.screenTooSmall':
      'ഉയർന്ന സ്ക്രീൻ റെസലൂഷൻ (കുറഞ്ഞത് ൧൦൨൪പ്ക്സ വൈഡ്) മാറുന്നതിനോ നിങ്ങളുടെ ബ്രൗസർ വിൻഡോ വിസ്തൃതമാക്കുക ദയവായി.',
    'label.dictionary': 'പൊരുത്തമുള്ള തിരയൽ',
    'dict.search.placeholder': 'വാക്ക് (ങ്ങൾ) നൽകുക',
    'tooltip.manuscript': 'കയ്യെഴുത്തുപ്രതി',
    'tooltip.strongsx': 'ശക്തമായ നമ്പർ',
    'tooltip.translation': 'വിവർത്തനം',
    'tooltip.english': 'ഇംഗ്ലീഷ്',
    'title.howToSearch': 'എങ്ങനെ പൊരുത്തമുള്ള തിരയൽ നടത്താനും',
    'desc.howToSearch':
      'ഗ്രീക്ക് അല്ലെങ്കിൽ ഹീബ്രു നിന്നും, വചനം (ങ്ങൾ) മുതൽ നിങ്ങളുടെ വിവർത്തന വചനം (ങ്ങൾ) നൽകുക, അല്ലെങ്കിൽ ശക്തമായ നമ്പർ (ങ്ങൾ). അല്ലെങ്കിൽ അല്ലെങ്കിൽ "നിങ്ങളുടെ വിവർത്തനം" എന്ന പാനലിൽ ഗ്രീക്ക് അല്ലെങ്കിൽ ഹീബ്രു വാക്കുകൾ തിരയൽ ഐച്ഛികങ്ങൾക്കായി അലൈന്മെന്റ് കാഴ്ചകൾ ക്ലിക്ക് ചെയ്യുക.',
    'dict.noDataToDisplay': 'നിങ്ങളുടെ തിരയൽ മാനദണ്ഡം പൊരുത്തപ്പെടുന്ന ഡാറ്റ ഉണ്ട്.',
    'title.searchTermTooMany': 'നിങ്ങൾ വളരെയധികം കീവേഡുകൾ നൽകി',
    'desc.searchTermTooMany': 'നിങ്ങൾ 8 കീവേഡുകൾ ഓരോ തവണയും വരെ തിരയാൻ കഴിയും.',
    'title.searchDictError': 'അപ്രതീക്ഷിതമായ ഒരു പിശക് സംഭവിച്ചു.',
    'desc.searchDictError': 'പിന്നീട് അല്ലെങ്കിൽ കസ്റ്റമർ കെയറുമായി ബന്ധപ്പെടുക വീണ്ടും ശ്രമിക്കുക',
    'alignment.saveAndEditAlignment': 'സംരക്ഷിക്കുക, വിന്യസിക്കുക പുതിയ ടെക്സ്റ്റ്',
    'alignment.reviewAlignment': 'അവലോകന ടെക്സ്റ്റ് വിന്യാസം',
    'dict.searchLemma': 'യഥാർത്ഥ പദം തിരയാനും ക്ലിക്കുചെയ്യുക',
    'dict.searchTranslation': 'വിവർത്തന വചനം തിരയാൻ ക്ലിക്ക്',
    'dict.searchStrongsX': 'ശക്തമായ നമ്പർ തിരയാൻ ക്ലിക്ക്',
    'dict.showMenu': 'പശ്ചാത്തലത്തിൽ സംഭവങ്ങൾക്ക് പൂർണ്ണ ടെക്സ്റ്റ് കാണിക്കാൻ ക്ലിക്ക്.',
    'dict.hideMenu': 'പശ്ചാത്തലത്തിൽ സംഭവങ്ങൾക്ക് പൂർണ്ണ ടെക്സ്റ്റ് മറയ്ക്കാൻ ക്ലിക്കുചെയ്യുക.',
  },
  mr: {
    'languages.en': 'इंग्रजी',
    'languages.am': 'अम्हारिक',
    'languages.bn': 'बंगाली भाषा',
    'languages.ceb': 'सिबुआनो भाषा',
    'languages.de': 'जर्मन भाषा',
    'languages.es-419': 'स्पॅनिश भाषा',
    'languages.fr': 'फ्रेंच भाषा',
    'languages.gu': 'गुजराती',
    'languages.hi': 'हिंदी',
    'languages.hr': 'क्रोएशियन भाषा',
    'languages.hu': 'हंगेरियन भाषा',
    'languages.id': 'इंडोनेशियन भाषा',
    'languages.km': 'ख्मेर',
    'languages.kn': 'कन्नड',
    'languages.ko': 'कोरियन भाषा',
    'languages.lo': 'लाओतिअन भाषा',
    'languages.ml': 'मल्याळम',
    'languages.mr': 'मराठी',
    'languages.ms': 'मलेशियन',
    'languages.my': 'बर्मी भाषा',
    'languages.ne': 'नेपाळी भाषा',
    'languages.plt': 'मलागसी भाषा',
    'languages.pt-br': 'पोर्तुगीज (ब्राझिल) भाषा',
    'languages.ru': 'रशियन भाषा',
    'languages.sw': 'स्वाहिली',
    'languages.ta': 'तमिळ भाषा',
    'languages.th': 'थाई भाषा',
    'languages.tl': 'टागालोग',
    'languages.te': 'Telegu भाषा',
    'languages.vi': 'व्हिएतनामी भाषा',
    'languages.zh-hans': 'चीनी भाषा (सरलीकृत)',
    'languages.zh-hant': 'चीनी भाषा (पारंपारिक)',
    'header.home': 'मुख्यपृष्ठ',
    'header.help': 'मदत',
    'header.project': 'प्रकल्प',
    'header.contact': 'संपर्क',
    'help.contactSupport': 'मदत help@youtranslate.bible येथे आम्हाला ईमेल करा.',
    'home.ytbProvides': 'YouTranslate.Bible मुक्तपणे उपलब्ध:',
    'home.easyToUseTool': 'एक-सुलभ वापर ऑनलाइन अनुवाद साधन',
    'home.originalTextHelp': 'त्याच्या मूळ भाषांमध्ये बायबल मजकूर समजून मदत',
    'home.referencesAndResources': 'संदर्भ आणि प्रशिक्षण संसाधने',
    'home.computerAssistance': 'राज्य-ऑफ-द-आर्ट संगणक तंत्रज्ञान अनुवादकांची मदत करण्यासाठी',
    'profile.userProfile': 'वापरकर्ता प्रोफाइल',
    'profile.language': 'इंग्रजी',
    switchDirection: 'स्विच मजकूर दिशा',
    'profile.update': 'अद्यतनित करा',
    'profile.languageWarning':
      'Google भाषांतर इंग्रजी तात्पुरते पेक्षा इतर भाषांमध्ये वापरकर्ता इंटरफेस अनुवाद करण्यासाठी वापरले होते. YTB ​​अनुवाद अचूकता किंवा विश्वसनीयता नियंत्रण नाही. Google कडून प्राप्त अनुवादित लेबले आणि स्पष्टीकरण विसंबून कोणीही अनुवाद त्याच्या किंवा तिच्या स्वत: च्या जोखमीवर म्हणून नाही. कोणालाही इंग्रजी व्यतिरिक्त इतर भाषांमध्ये वापरकर्ता इंटरफेस अधिक अचूक आणि विश्वसनीय भाषांतर प्रदान करण्यासाठी स्वयंसेवक मध्ये स्वारस्य आहे, तर, संपर्क पर्याय वर क्लिक करा GBI एक ईमेल पाठवू.',
    'usernav.profile': 'प्रोफाइल',
    'usernav.logout': 'बाहेर पडणे',
    'usernav.logout.modalTitle': 'YouTranslate.Bible च्या लॉग आऊट',
    'usernav.logout.modalText':
      'आपल्याला खात्री आहे आपण लॉग आऊट करू इच्छिता? सर्व जतन न केलेले काम गमावला जाईल.',
    cancel: 'रद्द करा',
    delete: 'हटवा',
    create: 'तयार करा',
    update: 'अद्यतनित करा',
    edit: 'सुधारणे',
    save: 'जतन करा',
    add: 'जोडा',
    translate: 'भाषांतर',
    'more.options': 'अधिक पर्याय',
    'project.create.title': 'बायबल भाषांतर प्रकल्प',
    'project.create.list': 'प्रकल्प यादी',
    'project.list.deleteProject': 'प्रकल्प हटवा?',
    'project.list.areYouSure': 'आपण हा प्रकल्प हटवू इच्छिता आपल्याला खात्री आहे?',
    'project.list.workWillBeDeleted': 'प्रकल्प आणि संबंधित कोणतेही काम काढून टाकले जातील.',
    'project.list.completion': 'पूर्ण:',
    'project.list.createdDate': 'तयार केल्याची तारीख:',
    'project.list.beginningDate': 'सुरुवात दिनांक:',
    'project.list.targetCompletionDate': 'लक्ष्य पूर्ण झाल्याची तारीख:',
    'project.list.description': 'वर्णन:',
    'project.edit': 'योजना संपादित करा',
    'project.list.translateBible': 'भाषांतर बायबल',
    'project.list.empty': 'आपली भाषांतर प्रकल्प सूची रिक्त आहे.',
    'project.list.myProjects': 'माझे बायबल भाषांतर प्रकल्प',
    'project.list.newProject': 'नवीन प्रकल्प',
    'project.create.createNew': 'एक नवीन प्रकल्प तयार',
    'project.namePlaceholder': 'प्रकल्प नाव प्रविष्ट करा',
    'project.descriptionPlaceholder': 'प्रकल्प वर्णन प्रविष्ट करा',
    'project.name': 'प्रकल्पाचे नाव',
    'project.beginningDate': 'सुरुवात तारीख',
    'project.targetCompletionDate': 'लक्ष्य पूर्ण झाल्याची तारीख',
    'project.description': 'वर्णन',
    'project.isRTL': 'भाषांतर उजवीकडून डावीकडे आहे',
    'project.manage-users': 'Marathi: व्यवस्थापित करा',
    'project.new-user': 'नवीन भाषांतर',
    'project.add-user': 'अनुवादक जोडा',
    'project.users': 'प्रकल्प अनुवादकांची',
    'project.read-only': 'फक्त वाचा',
    'project.read&write': 'वाचा लिहा',
    'project.owner': 'मालक',
    'project.translator': 'अनुवादक',
    'project.message.no-such-user': 'असा कोणताही भाषांतर अस्तित्वात',
    'project.message.user-added': 'अनुवादक यशस्वीरित्या जोडले',
    'project.message.user-deleted': 'अनुवादक काढले.',
    'project.message.only-owner-can-manage-users':
      'केवळ प्रकल्प मालक वापरकर्त्यांना व्यवस्थापित करू शकता',
    'project.shared': 'सहयोगी प्रकल्प',
    'general.delete-user': 'भाषांतर हटवा',
    'general.edit-user': 'वापरकर्ता संपादन करा',
    'general.name': 'नाव',
    'general.email': 'ई-मेल',
    'general.permissions': 'परवानग्या',
    'general.role': 'भूमिका',
    signin: 'साइन इन करा',
    'signin.emailPlaceholder': 'ई-मेल पत्ता',
    'signin.passwordPlaceholder': 'पासवर्ड',
    'signin.forgotPassword': 'आपला संकेतशब्द विसरलात?',
    'signin.facebook': 'फेसबुक साइन इन करा',
    'signin.google': 'Google सह साइन इन',
    'signin.dontHaveAccount': 'खाते नाही?',
    'signin.createOne': 'एक बनव',
    'signup.join': 'YouTranslate.Bible सामील व्हा',
    'signup.createAccountPrompt': 'सुरुवात करण्यासाठी आपल्या विनामूल्य खाते तयार करा.',
    'signup.createAccount': 'खाते तयार करा',
    'signup.emailPlaceholder': 'ई-मेल पत्ता',
    'signup.passwordPlaceholder': 'पासवर्ड',
    'passwordReset.title': 'पासवर्ड रीसेट करा',
    'passwordReset.prompt': 'आपला संकेतशब्द रीसेट करण्यासाठी सूचनांसह ईमेल विनंती करा.',
    'passwordReset.emailPlaceholder': 'ई-मेल पत्ता',
    'passwordReset.requestButtonLabel': 'ईमेल विनंती',
    'passwordReset.success': 'इमेल पाठवला आहे.',
    'passwordReset.error': 'त्रुटी आढळली आहे.',
    'translation.project': 'प्रकल्प:',
    'translation.backToProjectList': 'प्रकल्प यादी परत',
    'translation.book': 'पुस्तक:',
    'translation.chapter': 'Chapter:',
    'translation.prompt':
      'आपण या धडा सोडू इच्छिता आपल्याला खात्री आहे? सर्व जतन न केलेले काम गमावला जाईल.',
    'translation.prev': 'मागील',
    'translation.next': 'पुढे',
    'translation.previousChapter': 'मागील अध्यायात',
    'translation.nextChapter': 'पुढील अध्यायात',
    'navigator.book': 'पुस्तक:',
    'navigator.chapter': 'Chapter:',
    'navigator.oldTestament': 'जुना करार',
    'navigator.newTestament': 'नवा करार',
    'navigator.selectBook': 'एक पुस्तक निवडा',
    'navigator.search': 'शोधा',
    'navigator.changeBook': 'पुस्तक बदला',
    'navigator.changeChapter': 'बदला धडा',
    Genesis: 'उत्पत्ति',
    Exodus: 'Exodus',
    Leviticus: 'लेवीय',
    Numbers: 'संख्या',
    Deuteronomy: 'अनुवाद',
    Joshua: 'यहोशवा',
    Judges: 'न्यायाधीश',
    Ruth: 'रूथ',
    '1 Samuel': '1 शमुवेल',
    '2 Samuel': '2 शमुवेल',
    '1 Kings': '1 राजे',
    '2 Kings': '2 राजे',
    '1 Chronicles': '1 इतिहास',
    '2 Chronicles': '2 इतिहास',
    Ezra: 'एज्रा',
    Nehemiah: 'नहेम्या',
    Esther: 'एस्तेर',
    Job: 'नोकरी',
    Psalms: 'Psalms',
    Proverbs: 'नीतिसूत्रे',
    Ecclesiastes: 'उपदेशक',
    'Song of Songs': 'गाणी गीत',
    Isaiah: 'यशया',
    Jeremiah: 'यिर्मया',
    Lamentations: 'विलापगीत',
    Ezekiel: 'यहेज्केल',
    Daniel: 'डॅनियल',
    Hosea: 'होशेय',
    Joel: 'जोएल',
    Amos: 'आमोस',
    Obadiah: 'ओबद्या',
    Jonah: 'योना',
    Micah: 'मीखा',
    Nahum: 'निनवे',
    Habakkuk: 'हबक्कूक',
    Zephaniah: 'सफन्या',
    Haggai: 'हाग्गय',
    Zechariah: 'जखऱ्या',
    Malachi: 'मलाखी',
    Matthew: 'मॅथ्यू',
    Mark: 'मार्क',
    Luke: 'लूक',
    John: 'जॉन',
    Acts: 'कायदे',
    Romans: 'रोम',
    '1 Corinthians': '1 करिंथकर',
    '2 Corinthians': '2 करिंथकर',
    Galatians: 'गलती',
    Ephesians: 'इफिस',
    Philippians: 'फिलिप्पैकर',
    Colossians: 'कलस्सैकर',
    '1 Thessalonians': '1 थेस्सलनीका येथील',
    '2 Thessalonians': '2 थेस्सलनीकाकरांस',
    '1 Timothy': '1 तीमथ्य',
    '2 Timothy': '2 तीमथ्य',
    Titus: 'तीत',
    Philemon: 'फिलेमोन',
    Hebrews: 'इब्री लोकांस',
    James: 'जेम्स',
    '1 Peter': '1 पेत्र',
    '2 Peter': '2 पेत्र',
    '1 John': '1 योहान',
    '2 John': '2 योहान',
    '3 John': '3 योहान',
    Jude: 'यहूदा',
    Revelation: 'प्रकटीकरण',
    'resources.prompt': 'आपण अनुवाद रस्ता अतिरिक्त माहिती साठी बायबलातील संसाधने जोडा.',
    'resources.add': 'एक बायबलातील संसाधन जोडा',
    Manuscript: 'हस्तलिखित',
    Morphology: 'शब्द कसे बनतात त्याचे शास्त्र',
    Lexicon: 'शब्दकोश',
    'Syntactic Tree': 'वितरकांची वृक्ष',
    'Go to project list': 'प्रकल्प यादी जा',
    'Project not found': 'प्रकल्प आढळले नाही किंवा अपूर्ण परवानग्या नाही.',
    'error.referenceDoesNotExistForResource': 'या बायबल अनुवाद निवडले रस्ता सामग्री असू शकत नाही.',
    'references.addAnother': 'आणखी एक अनुवाद जोडा',
    'references.referencePrompt': 'अतिरिक्त माहिती इतर बायबल भाषांतरे जोडा.',
    'references.add': 'बायबल अनुवाद जोडा',
    'references.licensing': 'परवाना देण्याची माहिती',
    'references.licensingEmpty': 'कोणत्याही परवाना माहिती या आयटम आढळला नाही.',
    'translation.complete': 'भाषांतर पूर्ण',
    Alignment: 'संरेखन',
    Share: 'सामायिक करा',
    'Your Translation': 'आपले भाषांतर',
    'Enter your translation': 'आपली भाषांतर प्रविष्ट करा',
    OK: 'ठीक आहे',
    'error.verseTranslationEmpty': 'आपण अनुवाद सामग्री रिक्त सोडू शकत नाही.',
    'translation.hide.completed': 'लपवा पूर्ण',
    alignment: 'संरेखन',
    'alignment.verify': 'सत्यापित करा आणि बंद करा.',
    'alignment.close': 'सत्यापित न करता बंद करा',
    'alignment.backToEditor': 'संपादक परत',
    'alignment.error':
      'काहीतरी चूक झाली. समस्या कायम राहिल्यास, YouTranslate.Bible टीमशी संपर्क साधा.',
    'alignment.add': 'दुवा जोडा',
    'alignment.delete': 'दुवा काढा',
    'alignment.clear': 'निवड साफ करा',
    'alignment.reverse': 'उलट प्रदर्शन',
    'alignment.removeOrClear': 'निवडलेले दुवा किंवा स्पष्ट निवड काढा.',
    'alignment.addOrRemove': 'एक नवीन दुवा तयार करा किंवा मागील हटवा.',
    'alignment.selectAndAdd': 'दुवा विभागांची संबंधित निवडा.',
    'error.mustBeSignedToMakeChanges': 'आपण केलेले बदल साइन इन करणे आवश्यक आहे.',
    'error.mustBeSignedToTranslateBible': 'आपण बायबल अनुवाद साइन इन करणे आवश्यक आहे.',
    checking: 'तपासत',
    'verseEditor.unexpectedError': 'एक अनपेक्षित त्रुटी आली आहे.',
    verses: 'काव्य करून',
    words: 'शब्दानुसार',
    'leftPanel.name': 'बायबलच्या मूळ मजकूर',
    'rightPanel.name': 'बायबल भाषांतरे',
    'bottomPanel.name': 'बायबल संसाधने',
    revisionHistory: 'आवृत्ती इतिहास',
    'syntax.adv': 'क्रियाविशेषण सुधारणा',
    'syntax.adjp': 'विशेषणाचे लिहिलंय',
    'syntax.advp': 'क्रियाविशेषण लिहिलंय',
    'syntax.cl': 'कलम',
    'syntax.conj': 'उभयान्वयी अव्यय',
    'syntax.cjp': 'उभयान्वयी अव्यय',
    'syntax.det': 'Determiner',
    'syntax.ijp': 'उद्गारवाचक शब्द',
    'syntax.nump': 'अंकीय लिहिलंय',
    'syntax.np': 'नाम लिहिलंय',
    'syntax.o': 'थेट ऑब्जेक्ट',
    'syntax.o2': 'दुसरा ऑब्जेक्ट',
    'syntax.oc': 'ऑब्जेक्ट पूरक',
    'syntax.om': 'ऑब्जेक्ट मार्कर',
    'syntax.io': 'अप्रत्यक्ष ऑब्जेक्ट',
    'syntax.p': 'नॉन-कूट सत्य विधान',
    'syntax.pp': 'Prepositional लिहिलंय',
    'syntax.prep': 'शब्दयोगी अव्यय',
    'syntax.ptcl': 'कण',
    'syntax.rel': 'सापेक्ष कण',
    'syntax.s': 'विषय',
    'syntax.v': 'क्रियापद',
    'syntax.vc': 'जोड क्रियापद',
    'syntax.adjp.description': 'एक नाम किंवा सर्वनाम फेरफार की वाक्यांश',
    'syntax.adv.description':
      'फेरफार क्रियापद, विशेषत: पुढील व्याख्या रीतीने, वेळ, स्थान, वारंवारता, पदवी, इ ...',
    'syntax.advp.description':
      'वाक्यांश क्रियापद, बदल विशेषत: पुढील व्याख्या रीतीने, वेळ, स्थान, वारंवारता, पदवी इ',
    'syntax.cl.description': 'शब्द एक गट विषय एक ठाम मत करते.',
    'syntax.conj.description': 'शब्द, वाक्ये, कलम जोडते.',
    'syntax.cjp.description': 'शब्द, वाक्ये, कलम जोडते.',
    'syntax.det.description': 'हे निर्दिष्ट करते, ओळखतो, किंवा quantifies एक नाम वाक्यांश.',
    'syntax.ijp.description': 'उद्गार किंवा भावना अचानक अभिव्यक्ती.',
    'syntax.nump.description': 'सांख्यिकीय प्रमाण वर्णन करणारा वाक्यांश',
    'syntax.np.description':
      'त्याच्या डोक्यावर एक नाम किंवा एक नाम म्हणून की कार्ये आहे की एक वाक्यांश.',
    'syntax.o.description': 'जे थेट परिणाम झाला आहे झाल्याने किंवा क्रियापद क्रिया निर्मीत.',
    'syntax.o2.description': 'जे थेट परिणाम झाला आहे झाल्याने किंवा क्रियापद क्रिया निर्मीत.',
    'syntax.oc.description': 'थेट ऑब्जेक्ट बद्दल एक ठाम मत करते.',
    'syntax.om.description': 'थेट ऑब्जेक्ट समाविष्टीत आहे की कण.',
    'syntax.io.description':
      'अप्रत्यक्ष एक क्रिया प्रभावित आहे ऑब्जेक्ट, एक क्रियापद च्या कारवाईची अनेकदा प्राप्तकर्ता.',
    'syntax.p.description':
      'ओळखतो किंवा विषय वर्णन अनेकदा (पण नाही नेहमी) एकत्र एक दुवा साधून क्रियापद सह उद्भवते.',
    'syntax.pp.description': 'एक शब्दयोगी अव्यय आणि त्याच्या ऑब्जेक्ट होणारी संपादीत वाक्यांश.',
    'syntax.prep.description':
      'अनेकदा, कुठे हे सूचित इतर शब्द शब्दयोगी अव्यय च्या ऑब्जेक्ट संबंध सूचित करते.',
    'syntax.ptcl.description':
      'शब्द कार्याचे विविध गट पासून एक सदस्य inflected व्याकरण शब्द वर्ग कोणत्याही संबंधित नाही की',
    'syntax.rel.description': 'नातेवाईक खंड समाविष्टीत आहे की कण.',
    'syntax.s.description': 'खंड उर्वरित बद्दल एक ठाम मत करते खंड भाग.',
    'syntax.v.description':
      'खंड विषय बद्दल काहीतरी स्टेट्स आणि कृती, घटना, किंवा वाईट स्थितीत व्यक्त करू शकता.',
    'syntax.vc.description': 'एक विषय म्हणुन एक खंड विषय दुवे.',
    'general.close': 'बंद',
    'general.action': 'कृती',
    'general.select': 'निवडा ...',
    'general.back': 'मागे',
    'revisionHistory.noData': 'या वचनात नाही पुनरावृत्ती इतिहास आहे.',
    'revisionHistory.translation': 'भाषांतर',
    'revisionHistory.lastChanged': 'अंतिम बदलले',
    'sourceText.view': 'स्रोत मजकूर पाहण्यासाठी क्लिक करा',
    'sourceText.viewStructure': 'स्रोत मजकूर रचना पाहण्यासाठी क्लिक करा',
    'error.projectIdIsRequired': 'प्रकल्प ID आवश्यक आहे.',
    'error.searchTermIsRequired': 'शोध संज्ञा रिकामे करू शकत नाही.',
    'error.bookIdIsRequired': 'पुस्तक आयडी पुस्तकाच्या नावाने संग्रहित करणे आवश्यक आहे.',
    'error.bookNameCannotBeEmpty': 'पुस्तक नाव रिकामे असू शकत नाही.',
    'error.signInIsRequiredToAccessProject':
      'आपण आपले प्रकल्प प्रवेश करण्यासाठी साइन इन करणे आवश्यक आहे.',
    error: 'त्रुटी',
    'error.doNotHavePermissionAccessProject': 'आपण हा प्रकल्प प्रवेश करण्याची परवानगी नाही.',
    'error.unauthorized': 'अनधिकृत प्रवेश किंवा अवैध प्रवेश टोकन.',
    'error.tokenExpired': 'आपले टोकन कालबाह्य झाले आहे. कृपया पुन्हा लॉगिन.',
    'error.invalidApiUrl': 'अवैध API url.',
    'error.badRequestInvalidJson': 'चुकीची विनंती. अवैध JSON.',
    'error.windowIsNotDefined': 'विंडो परिभाषित केलेले नाही.',
    'error.unexpectedError': 'एक अनपेक्षित त्रुटी आली आहे. विंडो बंद करा आणि पुन्हा प्रयत्न करा.',
    'error.invalidDataType': 'अवैध डेटा प्रकार.',
    'export.data': 'डेटा निर्यात',
    more: 'अधिक',
    'export.to.rtf': 'RTF निर्यात',
    'export.to.usfm': 'USFM निर्यात',
    'export.titlePreparingData': 'आम्ही आपल्या बायबल अनुवाद डेटा तयार करत आहोत.',
    'export.doNotClose':
      'ही प्रक्रिया एक वेळ लागू शकतो, नाही बंद ही विंडो आणि डाउनलोड करण्यासाठी सज्ज आहे, तेव्हा आपण सूचित केले जाईल करा.',
    'export.desc': 'आपण निर्यात आणि खाली बटणे वर क्लिक करून आपल्या अनुवाद डेटा डाउनलोड करू शकता.',
    'export.titleOfReady2Download': 'आपले बायबल अनुवाद डेटा तयार आहे.',
    'export.textOfReady2Download':
      'आपल्या बायबल अनुवाद डाउनलोड करण्यासाठी खालील डाउनलोड बटण क्लिक करा.',
    'export.statistics': 'सांख्यिकी',
    'export.book': 'पुस्तक नाव',
    'export.translatedVerses': 'अनुवादित अध्याय',
    'export.exportedVerses': 'निर्यात अध्याय',
    'export.howDoUWant2Export': 'आपण कसे डेटा निर्यात करू इच्छिता?',
    'export.whichBooks': 'कोणत्या पुस्तक (चे) आपण निर्यात करू इच्छिता?',
    'export.byBooks': 'पुस्तके करून',
    'export.byChapters': 'अध्याय करून',
    'export.byVerses': 'अध्याय करून',
    'export.booksInOt': 'जुना करार',
    'export.booksInNt': 'नवा करार',
    'export.booksInBible': 'संपूर्ण बायबल',
    'export.booksInNone': 'काहीही नाही',
    'export.selectRangeOfChapters': 'अध्याय श्रेणी',
    'export.selectRangeOfVerses': 'अध्याय श्रेणी',
    'export.selectChapter': 'एक धडा निवडा',
    'export.exportTypeIsRequired':
      'आपण निर्यात डेटा कसे करू इच्छिता आपण अद्याप बद्दल निवडलेले नाहीत?',
    'export.haveNotYetSelectedBooks': 'आपण अद्याप कोणतीही पुस्तक निवडले नाही.',
    'error.selectABook': 'आपण एक पुस्तक निवडणे आवश्यक आहे.',
    'error.invalidBookId': 'अवैध पुस्तक आयडी.',
    'error.selectAChapter': 'आपण धडा निवडणे आवश्यक आहे.',
    'error.invalidChapterRange': 'आपण एक वैध धडा श्रेणी निवडणे आवश्यक आहे.',
    'error.invalidVerseRange': 'आपण एक वैध काव्य श्रेणी निवडणे आवश्यक आहे.',
    'error.invalidExportType': 'अवैध निर्यात प्रकार.',
    startover: 'प्रारंभ',
    download: 'डाउनलोड',
    'download.all': 'सर्व फायली डाउनलोड',
    'contact.header': 'आमच्याशी संपर्क साधा',
    'contact.returnAddress': 'परत पत्ता',
    'contact.returnAddressPlaceholder': 'आपण कुठे पोहोचू शकतात?',
    'contact.subject': 'विषय',
    'contact.subjectPlaceholder': 'आपण काय मदत हवी आहे?',
    'contact.message': 'संदेश',
    'contact.messagePlaceholder': 'आपण कशा प्रकारे मदत करू शकतो?',
    'contact.sendMessage': 'संदेश पाठवा',
    'contact.success': 'संदेश यशस्वीरित्या पाठविला गेला.',
    'contact.failure': 'हा संदेश पाठवत एक समस्या आली. help@globalbibleinitiative.org संपर्क साधा.',
    selectInterlinear: 'ओळींच्या मध्ये लिहिलेला मजकूर मजकूर बदलण्यासाठी क्लिक करा',
    'warning.screenTooSmall':
      'उच्च स्क्रीन रिझोल्यूशन (किमान 1024px वाइड) स्विच करू किंवा आपल्या ब्राउझर विंडो प्रसार करा करा.',
    'label.dictionary': 'ऐक्य शोध',
    'dict.search.placeholder': 'शब्द (चे) प्रविष्ट करा',
    'tooltip.manuscript': 'हस्तलिखित',
    'tooltip.strongsx': 'मजबूत च्या क्रमांक',
    'tooltip.translation': 'भाषांतर',
    'tooltip.english': 'इंग्रजी',
    'title.howToSearch': 'ऐक्य शोध कसे वापरावे',
    'desc.howToSearch':
      'ग्रीक किंवा हिब्रू शब्द (s) अनुवाद, किंवा मजबूत क्रमांक (चे) पासून शब्द (चे) प्रविष्ट करा. किंवा "आपले भाषांतर" किंवा पर्याय शोधण्यास संरेखन दृश्ये पॅनेलमध्ये ग्रीक किंवा हिब्रू शब्द वर क्लिक करा.',
    'dict.noDataToDisplay': 'आपला शोध निकष जुळत नाही डेटा आहेत.',
    'title.searchTermTooMany': 'आपल्याकडे बरेच कीवर्ड प्रविष्ट केला आहे',
    'desc.searchTermTooMany': 'आपण प्रत्येक वेळी 8 कीवर्ड पर्यंत शोधू शकता.',
    'title.searchDictError': 'एक अनपेक्षित त्रुटी आली आहे.',
    'desc.searchDictError': 'कृपया नंतर पुन्हा प्रयत्न किंवा संपर्क ग्राहक समर्थन करा',
    'alignment.saveAndEditAlignment': 'जतन करा आणि संरेखित नवीन मजकूर',
    'alignment.reviewAlignment': 'मजकूर संरेखन पुनरावलोकन',
    'dict.searchLemma': 'मूळ शब्द शोधण्यासाठी क्लिक करा',
    'dict.searchTranslation': 'अनुवाद शब्द शोधण्यासाठी क्लिक करा',
    'dict.searchStrongsX': 'मजबूत क्रमांक शोधण्यासाठी क्लिक करा',
    'dict.showMenu': 'संदर्भात घटना पूर्ण मजकूर दर्शविण्यासाठी क्लिक करा.',
    'dict.hideMenu': 'संदर्भात घटना संपूर्ण मजकूर लपविण्यासाठी क्लिक करा.',
  },
  ms: {
    'languages.en': 'bahasa inggeris',
    'languages.am': 'Amharic',
    'languages.bn': 'Bengali Bahasa',
    'languages.ceb': 'Cebuano Bahasa',
    'languages.de': 'Bahasa Jerman',
    'languages.es-419': 'Bahasa Sepanyol',
    'languages.fr': 'Bahasa Perancis',
    'languages.gu': 'Gujarati',
    'languages.hi': 'Hindi',
    'languages.hr': 'Croatian Bahasa',
    'languages.hu': 'Hungary Bahasa',
    'languages.id': 'Bahasa Indonesia',
    'languages.km': 'Khmer',
    'languages.kn': 'Kannada',
    'languages.ko': 'Bahasa Korea',
    'languages.lo': 'Laos Bahasa',
    'languages.ml': 'Malayalam',
    'languages.mr': 'Marathi',
    'languages.ms': 'Malaysia',
    'languages.my': 'Bahasa Burma',
    'languages.ne': 'Nepal Bahasa',
    'languages.plt': 'Malagasy Bahasa',
    'languages.pt-br': 'Portugis (Brazil) Bahasa',
    'languages.ru': 'Bahasa Rusia',
    'languages.sw': 'Swahili',
    'languages.ta': 'Tamil Bahasa',
    'languages.th': 'Bahasa Thai',
    'languages.tl': 'Tagalog',
    'languages.te': 'Telegu Bahasa',
    'languages.vi': 'Bahasa Vietnam',
    'languages.zh-hans': 'Bahasa Cina (Mudah)',
    'languages.zh-hant': 'Bahasa Cina (Tradisional)',
    'header.home': 'rumah',
    'header.help': 'bantuan',
    'header.project': 'projek',
    'header.contact': 'Hubungi',
    'help.contactSupport': 'Sila e-mel kami di help@youtranslate.bible untuk mendapatkan bantuan.',
    'home.ytbProvides': 'YouTranslate.Bible bebas menyediakan:',
    'home.easyToUseTool': 'Alat penterjemahan dalam talian yang mudah untuk digunakan',
    'home.originalTextHelp': 'Bantuan untuk memahami teks Alkitab dalam bahasa-bahasa asal',
    'home.referencesAndResources': 'sumber rujukan dan latihan',
    'home.computerAssistance': 'State-of-the-art teknologi komputer untuk membantu penterjemah',
    'profile.userProfile': 'Profil pengguna',
    'profile.language': 'bahasa',
    switchDirection: 'Tukar Text Direction',
    'profile.update': 'Kemas kini',
    'profile.languageWarning':
      'Terjemahan Google digunakan untuk menterjemah antara muka pengguna ke dalam bahasa selain daripada bahasa Inggeris buat sementara waktu. YTB ​​mempunyai kawalan ke atas ketepatan atau kebolehpercayaan terjemahan. Sesiapa sahaja bergantung kepada label diterjemahkan dan penjelasan yang diambil dari Google Translate berbuat demikian atas risiko mereka sendiri. Barangsiapa yang berminat untuk menjadi sukarelawan untuk menyediakan terjemahan yang lebih tepat dan boleh dipercayai antara muka pengguna ke dalam bahasa selain daripada bahasa Inggeris, sila klik pada pilihan Hubungi untuk menghantar e-mel kepada GBI.',
    'usernav.profile': 'Profil',
    'usernav.logout': 'Log keluar',
    'usernav.logout.modalTitle': 'Log keluar dari YouTranslate.Bible',
    'usernav.logout.modalText':
      'Adakah anda pasti anda mahu log keluar? Semua kerja yang tidak disimpan akan hilang.',
    cancel: 'Batal',
    delete: 'Padam',
    create: 'Buat',
    update: 'Kemas kini',
    edit: 'Edit',
    save: 'jimat',
    add: 'Tambah',
    translate: 'Terjemahkan',
    'more.options': 'pilihan',
    'project.create.title': 'Alkitab Projek Terjemahan',
    'project.create.list': 'senarai projek',
    'project.list.deleteProject': 'Memadam Project?',
    'project.list.areYouSure': 'Adakah anda pasti anda mahu memadamkan projek ini?',
    'project.list.workWillBeDeleted': 'Projek ini dan apa-apa kerja yang berkaitan akan dipadam.',
    'project.list.completion': 'Selesai:',
    'project.list.createdDate': 'Dicipta Date:',
    'project.list.beginningDate': 'Bermula Date:',
    'project.list.targetCompletionDate': 'Sasaran Siap Date:',
    'project.list.description': 'Penerangan:',
    'project.edit': 'Edit Projek',
    'project.list.translateBible': 'menterjemahkan Bible',
    'project.list.empty': 'senarai projek terjemahan anda kosong.',
    'project.list.myProjects': 'Alkitab Projek Saya Terjemahan',
    'project.list.newProject': 'Projek baru',
    'project.create.createNew': 'Buat Projek baru',
    'project.namePlaceholder': 'Masukkan nama projek',
    'project.descriptionPlaceholder': 'Masukkan penerangan projek',
    'project.name': 'Nama Projek',
    'project.beginningDate': 'bermula Tarikh',
    'project.targetCompletionDate': 'Sasaran Tarikh Siap',
    'project.description': 'Penerangan',
    'project.isRTL': 'Terjemahan dari kanan-ke-kiri',
    'project.manage-users': 'Urus Translators',
    'project.new-user': 'penterjemah baru',
    'project.add-user': 'penterjemah add',
    'project.users': 'penterjemah projek',
    'project.read-only': 'Baca sahaja',
    'project.read&write': 'Baca tulis',
    'project.owner': 'Owner',
    'project.translator': 'penterjemah',
    'project.message.no-such-user': 'No penterjemah tersebut wujud',
    'project.message.user-added': 'Penterjemah berjaya diletakkan',
    'project.message.user-deleted': 'Penterjemah dikeluarkan.',
    'project.message.only-owner-can-manage-users':
      'pemilik projek hanya boleh menguruskan pengguna',
    'project.shared': 'projek bersama',
    'general.delete-user': 'penterjemah padam',
    'general.edit-user': 'Edit pengguna',
    'general.name': 'nama',
    'general.email': 'E-mel',
    'general.permissions': 'kebenaran',
    'general.role': 'peranan',
    signin: 'Daftar masuk',
    'signin.emailPlaceholder': 'Alamat emel',
    'signin.passwordPlaceholder': 'kata laluan',
    'signin.forgotPassword': 'Lupa kata laluan anda?',
    'signin.facebook': 'Daftar masuk dengan Facebook',
    'signin.google': 'Log masuk dengan Google',
    'signin.dontHaveAccount': 'Tiada akaun?',
    'signin.createOne': 'Buat satu',
    'signup.join': 'Sertai YouTranslate.Bible',
    'signup.createAccountPrompt': 'Buat akaun percuma anda bermula.',
    'signup.createAccount': 'Buat akaun',
    'signup.emailPlaceholder': 'Alamat emel',
    'signup.passwordPlaceholder': 'kata laluan',
    'passwordReset.title': 'Memadam kata laluan',
    'passwordReset.prompt': 'Meminta e-mel dengan arahan untuk menetapkan semula kata laluan anda.',
    'passwordReset.emailPlaceholder': 'Alamat emel',
    'passwordReset.requestButtonLabel': 'permintaan Email',
    'passwordReset.success': 'E-mel telah dihantar.',
    'passwordReset.error': 'Ralat telah berlaku.',
    'translation.project': 'PROJEK:',
    'translation.backToProjectList': 'Kembali ke senarai projek',
    'translation.book': 'book:',
    'translation.chapter': 'bab:',
    'translation.prompt':
      'Adakah anda pasti anda mahu meninggalkan bab ini? Semua kerja yang tidak disimpan akan hilang.',
    'translation.prev': 'terdahulu',
    'translation.next': 'Seterusnya',
    'translation.previousChapter': 'bab sebelum',
    'translation.nextChapter': 'bab seterusnya',
    'navigator.book': 'book:',
    'navigator.chapter': 'bab:',
    'navigator.oldTestament': 'Perjanjian lama',
    'navigator.newTestament': 'Perjanjian baru',
    'navigator.selectBook': 'Pilih buku',
    'navigator.search': 'cari',
    'navigator.changeBook': 'Tukar buku',
    'navigator.changeChapter': 'Tukar bab',
    Genesis: 'Kejadian',
    Exodus: 'Keluaran',
    Leviticus: 'Imamat',
    Numbers: 'nombor',
    Deuteronomy: 'Ulangan',
    Joshua: 'joshua',
    Judges: 'hakim',
    Ruth: 'Ruth',
    '1 Samuel': '1 Samuel',
    '2 Samuel': '2 Samuel',
    '1 Kings': '1 Raja-raja',
    '2 Kings': '2 Raja-raja',
    '1 Chronicles': '1 Tawarikh',
    '2 Chronicles': '2 Chronicles',
    Ezra: 'Ezra',
    Nehemiah: 'Nehemiah',
    Esther: 'Esther',
    Job: 'pekerjaan',
    Psalms: 'mazmur',
    Proverbs: 'peribahasa',
    Ecclesiastes: 'Ecclesiastes',
    'Song of Songs': 'Song of Songs',
    Isaiah: 'Yesaya',
    Jeremiah: 'Yeremia',
    Lamentations: 'Lamentations',
    Ezekiel: 'Yehezkiel',
    Daniel: 'Daniel',
    Hosea: 'Hosea',
    Joel: 'Joel',
    Amos: 'Amos',
    Obadiah: 'Obaja',
    Jonah: 'Jonah',
    Micah: 'Micah',
    Nahum: 'Nahum',
    Habakkuk: 'Habakkuk',
    Zephaniah: 'Zefanya',
    Haggai: 'Haggai',
    Zechariah: 'Zakharia',
    Malachi: 'Maleakhi',
    Matthew: 'Matthew',
    Mark: 'Mark',
    Luke: 'Luke',
    John: 'John',
    Acts: 'perbuatan',
    Romans: 'Romans',
    '1 Corinthians': '1 Corinthians',
    '2 Corinthians': '2 Corinthians',
    Galatians: 'Galatians',
    Ephesians: 'Efesus',
    Philippians: 'Filipi',
    Colossians: 'Kolose',
    '1 Thessalonians': '1 Tesalonika',
    '2 Thessalonians': '2 Tesalonika',
    '1 Timothy': '1 Timothy',
    '2 Timothy': '2 Timothy',
    Titus: 'Titus',
    Philemon: 'Philemon',
    Hebrews: 'Ibrani',
    James: 'James',
    '1 Peter': '1 Peter',
    '2 Peter': '2 Peter',
    '1 John': '1 John',
    '2 John': '2 John',
    '3 John': '3 John',
    Jude: 'Jude',
    Revelation: 'Wahyu',
    'resources.prompt':
      'Menambah sumber Alkitab untuk wawasan tambahan ke dalam laluan yang anda sedang terjemahkan.',
    'resources.add': 'Tambah sumber Alkitab',
    Manuscript: 'manuskrip',
    Morphology: 'morfologi',
    Lexicon: 'Lexicon',
    'Syntactic Tree': 'sintaktik Tree',
    'Go to project list': 'Pergi ke senarai projek',
    'Project not found': 'Projek tidak dijumpai atau kebenaran yang tidak mencukupi.',
    'error.referenceDoesNotExistForResource':
      'Ini terjemahan Bible tidak mengandungi kandungan untuk laluan yang dipilih.',
    'references.addAnother': 'Menambah terjemahan lain',
    'references.referencePrompt': 'Menambah terjemahan Bible lain untuk wawasan tambahan.',
    'references.add': 'Menambah penterjemahan Bible',
    'references.licensing': 'Maklumat Pelesenan',
    'references.licensingEmpty': 'Tidak ada maklumat pelesenan telah dijumpai untuk item ini.',
    'translation.complete': 'lengkap terjemahan',
    Alignment: 'penjajaran',
    Share: 'Kongsi',
    'Your Translation': 'Terjemahan anda',
    'Enter your translation': 'Masukkan terjemahan',
    OK: 'okey',
    'error.verseTranslationEmpty':
      'Anda tidak boleh meninggalkan kandungan penterjemahan mengosongkan.',
    'translation.hide.completed': 'Menyembunyikan selesai',
    alignment: 'penjajaran',
    'alignment.verify': 'Mengesahkan dan menutup.',
    'alignment.close': 'Tutup tanpa Mengesahkan',
    'alignment.backToEditor': 'Kembali Kepada Pengarang',
    'alignment.error':
      'Ada sesuatu yang tidak salah. Jika masalah berterusan, sila hubungi pasukan YouTranslate.Bible.',
    'alignment.add': 'Tambah Pautan',
    'alignment.delete': 'Buang Link',
    'alignment.clear': 'Kosongkan Pilihan',
    'alignment.reverse': 'Paparan terbalik',
    'alignment.removeOrClear': 'Alih keluar pautan yang dipilih atau pemilihan yang jelas.',
    'alignment.addOrRemove': 'Membuat pautan baharu atau memadamkan apa yang sebelumnya.',
    'alignment.selectAndAdd': 'Pilih sama segmen untuk link.',
    'error.mustBeSignedToMakeChanges': 'Anda mesti log masuk untuk membuat perubahan.',
    'error.mustBeSignedToTranslateBible': 'Anda mesti log masuk untuk menterjemahkan Alkitab.',
    checking: 'Semakan',
    'verseEditor.unexpectedError': 'Ralat tidak dijangka telah berlaku.',
    verses: 'oleh Verse',
    words: 'by Word',
    'leftPanel.name': 'Teks Asal Alkitab',
    'rightPanel.name': 'Terjemahan Alkitab',
    'bottomPanel.name': 'Sumber untuk Alkitab',
    revisionHistory: 'Sejarah semakan',
    'syntax.adv': 'kata keterangan Modifier',
    'syntax.adjp': 'frasa adjektif',
    'syntax.advp': 'frasa kata keterangan',
    'syntax.cl': 'Fasal',
    'syntax.conj': 'sempena',
    'syntax.cjp': 'sempena',
    'syntax.det': 'determiner',
    'syntax.ijp': 'kata seru',
    'syntax.nump': 'frasa berangka',
    'syntax.np': 'Noun Phrase',
    'syntax.o': 'Objek langsung',
    'syntax.o2': 'Objek kedua',
    'syntax.oc': 'objek Pelengkap',
    'syntax.om': 'objek Marker',
    'syntax.io': 'Objek tidak langsung',
    'syntax.p': 'Bukan Lisan Predikat',
    'syntax.pp': 'Frasa sendi nama',
    'syntax.prep': 'kata depan',
    'syntax.ptcl': 'zarah',
    'syntax.rel': 'zarah relatif',
    'syntax.s': 'tertakluk',
    'syntax.v': 'kata kerja',
    'syntax.vc': 'menghubungkan Kata kerja',
    'syntax.adjp.description': 'Frasa yang mengubah kata nama atau kata ganti',
    'syntax.adv.description':
      'Suai kata kerja, biasanya perlu menentukan cara, masa, tempat, kekerapan, tahap, dan sebagainya ...',
    'syntax.advp.description':
      'Frasa mengubah kata kerja, biasanya perlu menentukan cara, masa, tempat, kekerapan, tahap, dan lain-lain',
    'syntax.cl.description': 'Sekumpulan kata-kata yang membuat dakwaan mengenai perkara tersebut.',
    'syntax.conj.description': 'Menyambung kata-kata, frasa dan klausa.',
    'syntax.cjp.description': 'Menyambung kata-kata, frasa dan klausa.',
    'syntax.det.description': 'Menentukan, mengenal pasti, atau kuantiti frasa nama.',
    'syntax.ijp.description': 'Seru atau ungkapan tiba-tiba emosi.',
    'syntax.nump.description': 'Frasa yang menerangkan kuantiti berangka',
    'syntax.np.description':
      'Satu frasa yang mempunyai kata nama sebagai ketua atau yang berfungsi sebagai kata nama.',
    'syntax.o.description':
      'Yang yang secara langsung terjejas, menyebabkan, atau dihasilkan oleh tindakan kata kerja.',
    'syntax.o2.description':
      'Yang yang secara langsung terjejas, menyebabkan, atau dihasilkan oleh tindakan kata kerja.',
    'syntax.oc.description': 'Membuat dakwaan mengenai Objek Langsung.',
    'syntax.om.description': 'Zarah yang memperkenalkan objek langsung.',
    'syntax.io.description':
      'Objek yang secara tidak langsung terjejas oleh tindakan, sering penerima tindakan kata kerja.',
    'syntax.p.description':
      'Mengenal pasti atau menerangkan subjek ini, sering (tetapi tidak semestinya) berlaku bersama-sama dengan kata kerja yang menghubungkan.',
    'syntax.pp.description': 'frasa mengubah suai terdiri daripada kata depan dan matlamatnya.',
    'syntax.prep.description':
      'Menunjukkan hubungan objek kata depan dengan kata-kata lain, sering menunjukkan di mana atau apabila.',
    'syntax.ptcl.description':
      'Seorang ahli dari kumpulan pelbagai kata tugas yang tidak tergolong dalam mana-mana kelas perkataan tatabahasa inflected',
    'syntax.rel.description': 'Zarah yang memperkenalkan klausa relatif.',
    'syntax.s.description': 'Bahagian fasal yang sepanjang fasal membuat dakwaan tentang.',
    'syntax.v.description':
      'Menyatakan sesuatu tentang subjek fasal dan boleh menyatakan tindakan, peristiwa, atau keadaan.',
    'syntax.vc.description': 'Pautan subjek klausa untuk pelengkap mata pelajaran.',
    'general.close': 'Tutup',
    'general.action': 'Tindakan',
    'general.select': 'Pilih ...',
    'general.back': 'Kembali',
    'revisionHistory.noData': 'Tiada sejarah semakan bagi ayat ini.',
    'revisionHistory.translation': 'terjemahan',
    'revisionHistory.lastChanged': 'lepas berubah',
    'sourceText.view': 'Klik untuk melihat teks sumber',
    'sourceText.viewStructure': 'Klik untuk melihat struktur teks sumber',
    'error.projectIdIsRequired': 'ID Projek dikehendaki.',
    'error.searchTermIsRequired': 'Istilah carian tidak boleh kosong.',
    'error.bookIdIsRequired': 'Buku ID diperlukan untuk menyimpan nama buku.',
    'error.bookNameCannotBeEmpty': 'nama buku tidak boleh kosong.',
    'error.signInIsRequiredToAccessProject': 'Anda mesti log masuk untuk mengakses projek anda.',
    error: 'ralat',
    'error.doNotHavePermissionAccessProject':
      'Anda tidak mempunyai kebenaran untuk mengakses projek ini.',
    'error.unauthorized': 'akses tanpa kebenaran atau akses yang tidak sah token.',
    'error.tokenExpired': 'token anda telah tamat tempoh. Sila log masuk semula.',
    'error.invalidApiUrl': 'url API tidak sah.',
    'error.badRequestInvalidJson': 'Permintaan buruk. Tidak sah JSON.',
    'error.windowIsNotDefined': 'Tetingkap tidak ditakrifkan.',
    'error.unexpectedError':
      'Ralat tidak dijangka telah berlaku. Sila tutup tetingkap ini dan cuba lagi.',
    'error.invalidDataType': 'Jenis data yang tidak sah.',
    'export.data': 'Eksport Data',
    more: 'lebih',
    'export.to.rtf': 'Eksport ke RTF',
    'export.to.usfm': 'Eksport ke USFM',
    'export.titlePreparingData': 'Kami sedang membuat persediaan data terjemahan Bible anda.',
    'export.doNotClose':
      'Proses ini mungkin mengambil sedikit masa, sila jangan tutup tetingkap ini dan anda akan diberitahu apabila ia sedia untuk dimuat turun.',
    'export.desc':
      'Anda boleh mengeksport dan memuat turun data terjemahan anda dengan mengklik butang di bawah.',
    'export.titleOfReady2Download': 'data terjemahan Alkitab anda sudah bersedia.',
    'export.textOfReady2Download':
      'Sila klik butang muat turun di bawah untuk memuat turun terjemahan Bible anda.',
    'export.statistics': 'Statistik',
    'export.book': 'nama buku',
    'export.translatedVerses': 'ayat diterjemahkan',
    'export.exportedVerses': 'ayat dieksport',
    'export.howDoUWant2Export': 'Bagaimana anda mahu untuk mengeksport data?',
    'export.whichBooks': 'Yang buku (s) yang anda ingin eksport?',
    'export.byBooks': 'oleh buku-buku',
    'export.byChapters': 'dalam bab-bab',
    'export.byVerses': 'oleh ayat-ayat',
    'export.booksInOt': 'Perjanjian lama',
    'export.booksInNt': 'Perjanjian baru',
    'export.booksInBible': 'seluruh Alkitab',
    'export.booksInNone': 'Tiada',
    'export.selectRangeOfChapters': 'Pelbagai bab',
    'export.selectRangeOfVerses': 'Pelbagai ayat-ayat',
    'export.selectChapter': 'Pilih bab',
    'export.exportTypeIsRequired':
      'Anda belum lagi dipilih kira-kira bagaimana anda mahu data eksport?',
    'export.haveNotYetSelectedBooks': 'Anda belum memilih mana-mana buku.',
    'error.selectABook': 'Anda mesti memilih buku.',
    'error.invalidBookId': 'ID buku tidak sah.',
    'error.selectAChapter': 'Anda mesti memilih bab.',
    'error.invalidChapterRange': 'Anda mesti memilih pelbagai bab yang sah.',
    'error.invalidVerseRange': 'Anda mesti memilih pelbagai ayat yang sah.',
    'error.invalidExportType': 'jenis eksport tidak sah.',
    startover: 'Mula semula',
    download: 'muat turun',
    'download.all': 'Muat turun semua fail',
    'contact.header': 'Hubungi Kami',
    'contact.returnAddress': 'Pulang Alamat',
    'contact.returnAddressPlaceholder': 'Di mana kita boleh sampai kepada anda?',
    'contact.subject': 'tertakluk',
    'contact.subjectPlaceholder': 'Apa yang anda perlu membantu dengan?',
    'contact.message': 'mesej',
    'contact.messagePlaceholder': 'Bagaimana kita boleh membantu?',
    'contact.sendMessage': 'Menghantar mesej',
    'contact.success': 'Mesej berjaya dihantar.',
    'contact.failure':
      'Terdapat masalah menghantar mesej ini. Sila hubungi help@globalbibleinitiative.org.',
    selectInterlinear: 'Klik untuk menukar teks antara baris',
    'warning.screenTooSmall':
      'Sila tukar ke resolusi skrin yang lebih tinggi (minimum 1024px lebar) atau sila meluaskan tetingkap pelayar anda.',
    'label.dictionary': 'Concordance Cari',
    'dict.search.placeholder': 'Masukkan perkataan (s)',
    'tooltip.manuscript': 'manuskrip',
    'tooltip.strongsx': 'Strong Nombor',
    'tooltip.translation': 'terjemahan',
    'tooltip.english': 'bahasa inggeris',
    'title.howToSearch': 'Bagaimana untuk menggunakan carian konkordans',
    'desc.howToSearch':
      'Masukkan perkataan (s) dari Greek atau Ibrani, perkataan (s) dari terjemahan, atau Nombor Strong (s). Atau klik pada butang Greek atau Ibrani perkataan dalam panel "terjemahan Anda" atau pandangan Penjajaran untuk pilihan carian.',
    'dict.noDataToDisplay': 'Tiada data yang sepadan dengan kriteria carian anda.',
    'title.searchTermTooMany': 'Anda telah memasukkan terlalu banyak kata kunci',
    'desc.searchTermTooMany': 'Anda boleh sehingga 8 kata kunci setiap kali.',
    'title.searchDictError': 'Ralat tidak dijangka telah berlaku.',
    'desc.searchDictError': 'Sila cuba lagi kemudian atau hubungi sokongan pelanggan',
    'alignment.saveAndEditAlignment': 'Text Simpan dan Align New',
    'alignment.reviewAlignment': 'Ulasan Teks Penjajaran',
    'dict.searchLemma': 'Klik untuk mencari perkataan asal',
    'dict.searchTranslation': 'Klik untuk mencari perkataan terjemahan',
    'dict.searchStrongsX': 'Klik untuk mencari nombor Strong',
    'dict.showMenu': 'Klik untuk menunjukkan teks penuh kejadian dalam konteks.',
    'dict.hideMenu': 'Klik untuk menyembunyikan teks penuh kejadian dalam konteks.',
  },
  my: {
    'languages.en': 'အင်္ဂလိပ်',
    'languages.am': 'ခြ',
    'languages.bn': 'ဘင်္ဂါလီဘာသာစကားကို',
    'languages.ceb': 'Cebuano အဘိဓါန်ဘာသာစကားများ',
    'languages.de': 'ဂျာမန်ဘာသာစကား',
    'languages.es-419': 'စပိန်ဘာသာစကား',
    'languages.fr': 'ပြင်သစ်ဘာသာစကားများ',
    'languages.gu': 'ဂူ',
    'languages.hi': 'ဟိန်ဒီ',
    'languages.hr': 'ခရိုအရှေဘာသာစကားများ',
    'languages.hu': 'ဟနျဂရေီဘာသာစကားများ',
    'languages.id': 'အင်ဒိုနီးရှားဘာသာစကားများ',
    'languages.km': 'ခမာ',
    'languages.kn': 'ကန်နာဒါ',
    'languages.ko': 'ကိုရီးယားဘာသာစကား',
    'languages.lo': 'လာအိုပြည်သူ့ဒီမိုကရက်တစ်ဘာသာစကားများ',
    'languages.ml': 'မလေးရာလ',
    'languages.mr': 'မာရသီ',
    'languages.ms': 'မလေးရှား',
    'languages.my': 'မြန်မာဘာသာစကားကို',
    'languages.ne': 'နီပေါဘာသာစကားများ',
    'languages.plt': 'အာလာဂါစီဘာသာစကားများ',
    'languages.pt-br': 'ပေါ်တူဂီ (ဘရာဇီး) ဘာသာစကားများ',
    'languages.ru': 'ရုရှားဘာသာစကားများ',
    'languages.sw': 'ဆွာဟီလီ',
    'languages.ta': 'တမီးဘာသာစကားများ',
    'languages.th': 'ထိုင်းဘာသာစကား',
    'languages.tl': 'အဘိဓါန် Tagalog-',
    'languages.te': 'Telegu ဘာသာစကားများ',
    'languages.vi': 'ဗီယက်နမ်ဘာသာစကားများ',
    'languages.zh-hans': 'တရုတ်ဘာသာစကားကို (မျက်မှောက်ရေးရာ)',
    'languages.zh-hant': 'တရုတ်ဘာသာစကားကို (ရိုးရာ)',
    'header.home': 'နေအိမ်',
    'header.help': 'ကူညီပါ',
    'header.project': 'စီမံကိန်း',
    'header.contact': 'ထိတှေ့',
    'help.contactSupport': 'အကူအညီအတွက် help@youtranslate.bible မှာကျွန်တော်တို့ကိုအီးမေးလ်ပို့ပါ။',
    'home.ytbProvides': 'YouTranslate.Bible လွတ်လပ်စွာထောက်ပံ့:',
    'home.easyToUseTool': 'လွယ်ကူသော-to-အသုံးပြုမှုအွန်လိုင်းဘာသာပြန် tool ကို',
    'home.originalTextHelp': 'မူရင်းဘာသာစကားများကမျြးစာ၏စာသားကိုနားလည်ရန်အကူအညီ',
    'home.referencesAndResources': 'ကိုးကားစရာများနှင့်လေ့ကျင့်ရေးအရင်းအမြစ်များကို',
    'home.computerAssistance': 'ဘာသာပြန်များကူညီပေးဖို့ပြည်နယ်-Of-The-Art ကွန်ပျူတာနည်းပညာ',
    'profile.userProfile': 'အသုံးပြုသူပရိုဖိုင်',
    'profile.language': 'ဘာသာစကား',
    switchDirection: 'switch စာသား Direction အဖွဲ့',
    'profile.update': 'Update ကို',
    'profile.languageWarning':
      'Google ကယာယီအင်္ဂလိပ်ထက်အခြားဘာသာစကားများသို့ user interface ကိုဘာသာပြန်ဆိုဖို့အသုံးပြုခဲ့ပါတယ် Translate ။ YTB ​​ဘာသာပြန်များ၏တိကျမှန်ကန်မှုကိုသို့မဟုတ်ယုံကြည်စိတ်ချရကျော်မျှမထိန်းချုပ်ရှိပါတယ်။ Google ထံမှရရှိသောဘာသာပြန်ထားသောတံဆိပ်များနှင့်ရှင်းလင်းချက်အပေါ်မှီခိုမည်သူမဆို Translate သူသို့မဟုတ်သူမ၏ကိုယ်ပိုင်အန္တရာယ်မှာဒါပါဘူး။ မည်သူမဆိုအင်္ဂလိပ်ထက်အခြားဘာသာစကားများသို့ user interface ကိုပိုမိုတိကျမှန်ကန်ပြီးယုံကြည်စိတ်ချရသောဘာသာပြန်ချက်ပေးစေတနာ့ဝန်ထမ်းအတွက်စိတ်ဝင်စားသည်ဆိုပါ GBI အီးမေးလ်ပေးပို့ဖို့ဆက်သွယ်ပါ option ကိုအပေါ်နှိပျပါ။',
    'usernav.profile': 'ပရိုဖိုင်းကို',
    'usernav.logout': 'logout',
    'usernav.logout.modalTitle': 'YouTranslate.Bible ၏ logout',
    'usernav.logout.modalText':
      'သင်လော့ဂ်အောက်ချင်သေချာပါသလား? အားလုံးမသိမ်းရသေးသောအလုပ်ဆုံးရှုံးခဲ့ရပါလိမ့်မည်။',
    cancel: 'Cancel',
    delete: 'Delete',
    create: 'ဖန်တီး',
    update: 'Update ကို',
    edit: 'Edit ကို',
    save: 'ကြော်ငြာကို Save လုပ်ရန်',
    add: 'add',
    translate: 'ဘာသာပြန်',
    'more.options': 'more options',
    'project.create.title': 'ကမျြးစာကိုဘာသာပြန်စာပေစီမံကိန်း',
    'project.create.list': 'Project မှစာရင်း',
    'project.list.deleteProject': 'စီမံကိန်းကိုဖျက်မလား?',
    'project.list.areYouSure': 'သငျသညျဤစီမံကိန်းကိုဖျက်ပစ်ချင်သင်သေချာပါသလား?',
    'project.list.workWillBeDeleted': 'အဆိုပါစီမံကိန်းနှင့်ဆက်စပ်မည်သည့်အလုပ်ကိုမှဖျက်ပစ်လိမ့်မည်။',
    'project.list.completion': 'ပြီးစီး:',
    'project.list.createdDate': 'Created နေ့စွဲ:',
    'project.list.beginningDate': 'နေ့စွဲကစပြီး:',
    'project.list.targetCompletionDate': 'ပစ်မှတ်ပြီးစီးနေ့စွဲ:',
    'project.list.description': 'ဖော်ပြချက်:',
    'project.edit': 'Edit ကိုစီမံကိန်း',
    'project.list.translateBible': 'ကမျြးစာကို Translate',
    'project.list.empty': 'သင့်ရဲ့ဘာသာပြန်စီမံကိန်းအတွက်စာရင်းဗလာဖြစ်နေသည်။',
    'project.list.myProjects': 'အကြှနျုပျ၏ကမျြးစာကိုဘာသာပြန်စာပေစီမံကိန်းများ',
    'project.list.newProject': 'နယူးစီမံကိန်း',
    'project.create.createNew': 'အသစ်တခုစီမံကိန်း Create',
    'project.namePlaceholder': 'စီမံကိန်း၏အမည်ထည့်ပါ',
    'project.descriptionPlaceholder': 'စီမံကိန်းကိုဖော်ပြချက် Enter',
    'project.name': 'စီမံကိန်းအမည်',
    'project.beginningDate': 'စတင်နေ့စွဲ',
    'project.targetCompletionDate': 'ပစ်မှတ်ပြီးစီးနေ့စွဲ',
    'project.description': 'ဖေါ်ပြချက်',
    'project.isRTL': 'ဘာသာပြန်ချက် Right-To-လက်ဝဲဖြစ်ပါသည်',
    'project.manage-users': 'ဘာသာစီမံခန့်ခွဲရန်',
    'project.new-user': 'နယူးဘာသာပြန်ဆိုသူ',
    'project.add-user': 'ဘာသာပြန်ဆိုသူ Add',
    'project.users': 'Project မှဘာသာပြန်များ',
    'project.read-only': 'သာ Read',
    'project.read&write': 'ဖတ်ရေး',
    'project.owner': 'ပိုင်ဆိုင်သူ',
    'project.translator': 'ဘာသာပြန်သူ',
    'project.message.no-such-user': 'အဘယ်သူမျှမထိုကဲ့သို့သောဘာသာပြန်ဆိုသူတည်ရှိ',
    'project.message.user-added': 'ဘာသာပြန်ဆိုသူအောင်မြင်စွာကဆက်ပြောသည်',
    'project.message.user-deleted': 'ဘာသာပြန်ဆိုသူဖယ်ရှားခဲ့သည်။',
    'project.message.only-owner-can-manage-users':
      'သာစီမံကိန်းကိုပိုင်ရှင်အသုံးပြုသူများကိုစီမံခန့်ခွဲနိုင်တယ်',
    'project.shared': 'ပူးပေါင်းစီမံကိန်းအား',
    'general.delete-user': 'delete ဘာသာပြန်ဆိုသူ',
    'general.edit-user': 'Edit ကိုအသုံးပြုသူ',
    'general.name': 'အမည်',
    'general.email': 'E-mail ကို',
    'general.permissions': 'permissions',
    'general.role': 'အခန်းက္ပ',
    signin: 'ဆိုင်းအင်လုပ်ခြင်း',
    'signin.emailPlaceholder': 'အီးမေးလိပ်စာ',
    'signin.passwordPlaceholder': 'Password ကို',
    'signin.forgotPassword': 'သင့်ရဲ့စကားဝှက်ကိုမေ့နေပါသလား?',
    'signin.facebook': 'Facebook ကနဲ့ Sign',
    'signin.google': 'Google ဖြင့်မှတ်ပုံတင်ရန်',
    'signin.dontHaveAccount': 'အကောင့်တစ်ခုရှိသည်မဟုတ်လော',
    'signin.createOne': 'တဦးတည်း Create',
    'signup.join': 'YouTranslate.Bible join',
    'signup.createAccountPrompt': 'စတင်သင့်ရဲ့အခမဲ့အကောင့်ကိုဖန်တီးပါ။',
    'signup.createAccount': 'အကောင့်ပြုလုပ်ပါ',
    'signup.emailPlaceholder': 'အီးမေးလိပ်စာ',
    'signup.passwordPlaceholder': 'Password ကို',
    'passwordReset.title': 'Password ကို Reset',
    'passwordReset.prompt':
      'သင့်ရဲ့စကားဝှက်ကိုပြန်ညှိရန်ညွှန်ကြားချက်နှင့်အတူအီးမေးလ်တစ်စောင်တောင်းဆိုခြင်း။',
    'passwordReset.emailPlaceholder': 'အီးမေးလိပ်စာ',
    'passwordReset.requestButtonLabel': 'တောင်းဆိုမှုအီးမေးလ်',
    'passwordReset.success': 'အီးမေးလ်ပို့ရန်ကိုစေလွှတ်ခဲ့သည်။',
    'passwordReset.error': 'အမှားတစ်ခုဖြစ်ပေါ်နေပါသည်။',
    'translation.project': 'စီမံကိန်း:',
    'translation.backToProjectList': 'စီမံကိန်းကိုစာရင်းကိုပြန်သွားရန်',
    'translation.book': 'စာအုပ်:',
    'translation.chapter': 'အခန်းကြီး:',
    'translation.prompt':
      'သငျသညျဤအခနျးစွန့်ခွာချင်သင်သေချာပါသလား? အားလုံးမသိမ်းရသေးသောအလုပ်ဆုံးရှုံးခဲ့ရပါလိမ့်မည်။',
    'translation.prev': 'prev',
    'translation.next': 'နောက်တစ်ခု',
    'translation.previousChapter': 'ယခင်မျြးအခနျးကွီး',
    'translation.nextChapter': 'နောကျအခနျး',
    'navigator.book': 'စာအုပ်:',
    'navigator.chapter': 'အခန်းကြီး:',
    'navigator.oldTestament': 'ဓမ်မဟောငျးကမျြး',
    'navigator.newTestament': 'ဓမ္မသစ်ကျမ်း',
    'navigator.selectBook': 'စာအုပ်တစ်အုပ်ကိုရွေးချယ်ပါ',
    'navigator.search': 'ရှာဖှေ',
    'navigator.changeBook': 'ပြောင်းလဲမှုစာအုပ်',
    'navigator.changeChapter': 'ပြောင်းလဲမှုမျြးအခနျးကွီး',
    Genesis: 'ကမ်ဘာဦး',
    Exodus: 'ထှကျမွောကျရာ',
    Leviticus: 'ဝတ်ပြုရာကျမ်း',
    Numbers: 'တောလည်ရာ',
    Deuteronomy: 'တရားဟောရာ',
    Joshua: 'ယောရှုသည်',
    Judges: 'တရားသူကြီးမှတ်စာ',
    Ruth: 'ရုသသည်',
    '1 Samuel': '1 ရှမွေလသည်',
    '2 Samuel': '2 ရှမွေလက',
    '1 Kings': '1 ဓမ္မရာဇဝင်',
    '2 Kings': '2 ရာဇဝင်',
    '1 Chronicles': '1 ရာဇဝင်ချုပ်',
    '2 Chronicles': '2 ရာဇဝင်',
    Ezra: 'ဧဇရ',
    Nehemiah: 'နေဟမိ',
    Esther: 'ဧသတာ',
    Job: 'အလုပ်',
    Psalms: 'ဆာလံကမျြး',
    Proverbs: 'သုတ္တံ',
    Ecclesiastes: 'ဒေသနာ',
    'Song of Songs': 'Songs ရဲ့သီချင်း',
    Isaiah: 'ကျဟရှောယ',
    Jeremiah: 'ယေရမိက',
    Lamentations: 'မြည်တမ်းစကား',
    Ezekiel: 'ယေဇကျေလ',
    Daniel: 'ဒံယလေက',
    Hosea: 'ဟောရှေ',
    Joel: 'ယောလ',
    Amos: 'အာမုတ်',
    Obadiah: 'ဩဗဒိ',
    Jonah: 'ယောန',
    Micah: 'မိက္ခာ',
    Nahum: 'နာဟုံ',
    Habakkuk: 'ဟဗက်ကုတျ',
    Zephaniah: 'ဇေဖနိ',
    Haggai: 'ဟဂ်ဂဲ',
    Zechariah: 'ဇာခရိ',
    Malachi: 'မာလခိ',
    Matthew: 'မဿဲ',
    Mark: 'အမှတ်',
    Luke: 'လုကာ',
    John: 'ယောဟနျသ',
    Acts: 'တမန်တော်ဝတ္ထု',
    Romans: 'ရောမ',
    '1 Corinthians': '1 ကောရိန်သု',
    '2 Corinthians': '2 ကောရိန္သု',
    Galatians: 'ဂလာတိ',
    Ephesians: 'ဧဖက်',
    Philippians: 'ဖိလိပ္ပိ',
    Colossians: 'ကောလောသဲ',
    '1 Thessalonians': '1 သက်သာလောနိတ်',
    '2 Thessalonians': '2 သက်သာလောနိတ်',
    '1 Timothy': '1 တိမောသေ',
    '2 Timothy': '2 တိမောသေ',
    Titus: 'တိတု',
    Philemon: 'ဖိလမေုနျ',
    Hebrews: 'ဟေဗြဲ',
    James: 'ဂျိမ်း',
    '1 Peter': '1 ပေတရု',
    '2 Peter': '2 ပေတရု',
    '1 John': '1 ယော',
    '2 John': '2 ယော',
    '3 John': '3 ယောဟနျ',
    Jude: 'ယုဒ',
    Revelation: 'ဖွင့်ပြချက်',
    'resources.prompt':
      'သငျသညျဘာသာပြန်ဆိုထားပါသည်ကျမ်းပိုဒ်သို့အပိုဆောင်းထိုးထွင်းသိမြင်ဘို့ကျမ်းစာအရင်းအမြစ်တွေကိုထည့်ပါ။',
    'resources.add': 'တစ်ဦးကမျြးစာအရင်းအမြစ် Add',
    Manuscript: 'လက်ရေးမူများမှာတွေ့နိုင်ပါတယ်',
    Morphology: 'shape သုက်ပိုးပုံသဏ္ဌာန်',
    Lexicon: 'အဘိဓာန်',
    'Syntactic Tree': 'Syntactic သစ်ပင်',
    'Go to project list': 'စီမံကိန်းအတွက်စာရင်းသို့သွားရန်',
    'Project not found': 'Project မှတွေ့ရှိခဲ့သို့မဟုတ်မလုံလောက်ခွင့်ပြုချက်မရ။',
    'error.referenceDoesNotExistForResource':
      'ဤသည်ကမျြးစာဘာသာရွေးချယ်ထားကျမ်းပိုဒ်များအတွက်အကြောင်းအရာဆံ့မခံပါဘူး။',
    'references.addAnother': 'အခြားဘာသာပြန်ချက်ထည့်ပါ',
    'references.referencePrompt': 'အပိုဆောင်းထိုးထွင်းသိမြင်အခြားဘာသာပြန်ကျမ်းချက်ထည့်ပါ။',
    'references.add': 'ကမျြးစာဘာသာပြန်ချက်ထည့်ပါ',
    'references.licensing': 'ပြန်ကြားရေးလိုင်စင်',
    'references.licensingEmpty': 'လိုင်စင်အချက်အလက်များကိုဤအရာအတွက်ရှာတွေ့ခဲ့သည်မရှိပါ။',
    'translation.complete': 'ဘာသာပြန်ချက်ပြည့်စုံ',
    Alignment: 'alignment',
    Share: 'ဝေစု',
    'Your Translation': 'သင့်ရဲ့ဘာသာပြန်စာပေ',
    'Enter your translation': 'သင်၏ဘာသာပြန် Enter',
    OK: 'ရလား',
    'error.verseTranslationEmpty': 'သငျသညျအချည်းနှီးဘာသာပြန်အကြောင်းအရာမချန်မထားနိုင်ပါ။',
    'translation.hide.completed': 'ဖျောက်ပြီးစီး',
    alignment: 'alignment',
    'alignment.verify': 'Verify နှင့်ပိတ်ပါ။',
    'alignment.close': 'စိစစ်ခြင်းမရှိဘဲအနီးကပ်',
    'alignment.backToEditor': 'Editor ကိုပြန်သွားရန်',
    'alignment.error':
      'တစ်စုံတစ်ခုမှားယွင်းခဲ့သည်။ ထိုပြဿနာကို အကယ်. လျှင်, YouTranslate.Bible အဖွဲ့ဆက်သွယ်နိုင်ပါသည်။',
    'alignment.add': 'Link ကို Add',
    'alignment.delete': 'Link ကို Remove',
    'alignment.clear': 'Clear ကိုရွေးချယ်ရေး',
    'alignment.reverse': 'ပြောင်းပြန်ပြရန်',
    'alignment.removeOrClear':
      'ရွေးချယ်ထားသော link ကိုသို့မဟုတ်ရှင်းရှင်းလင်းလင်းရွေးချယ်ရေးဖယ်ရှားပါ။',
    'alignment.addOrRemove': 'သစ်တစ်ခု link ကို Create တစ်ခုသို့မဟုတ်ယခင်ဖျက်ပါ။',
    'alignment.selectAndAdd': 'လင့်ခ်မှအစိတ်အပိုင်းများနဲ့သက်ဆိုင်တဲ့ရွေးချယ်ပါ။',
    'error.mustBeSignedToMakeChanges': 'သငျသညျလုပ်အပြောင်းအလဲများအတွက်လက်မှတ်ရေးထိုးရမည်ဖြစ်သည်။',
    'error.mustBeSignedToTranslateBible':
      'သငျသညျကမျြးစာကိုဘာသာပြန်ဆိုအတွက်လက်မှတ်ရေးထိုးရမည်ဖြစ်သည်။',
    checking: 'စစ်ဆေးခြင်းကို',
    'verseEditor.unexpectedError': 'မျှော်လင့်မထားသောအမှားတစ်ခုဖြစ်ပေါ်နေပါသည်။',
    verses: 'အခနျးငယျအားဖြင့်',
    words: 'သမ်မာကမျြးခွငျးအားဖွငျ့',
    'leftPanel.name': 'သမ္မာကျမ်းစာကိုမူရင်းစာသား',
    'rightPanel.name': 'သမ္မာကျမ်းစာကိုဘာသာပြန်ချက်များ',
    'bottomPanel.name': 'ကမျြးစာကိုများအတွက်အရင်းအမြစ်များ',
    revisionHistory: 'တည်းဖြတ်မူသမိုင်း',
    'syntax.adv': 'Adverbial မွမ်းမံ',
    'syntax.adjp': 'Adjectival Phrase',
    'syntax.advp': 'adverb Phrase',
    'syntax.cl': 'အချက်',
    'syntax.conj': 'တွဲဖက်',
    'syntax.cjp': 'တွဲဖက်',
    'syntax.det': 'ဆုံးဖြတ်ချက်',
    'syntax.ijp': 'Interjection',
    'syntax.nump': 'numerical Phrase',
    'syntax.np': 'Noun Phrase',
    'syntax.o': 'တိုက်ရိုက်အရာဝတ္ထု',
    'syntax.o2': 'ဒုတိယအအရာဝတ္ထု',
    'syntax.oc': 'object ကိုဖြည့်',
    'syntax.om': 'object မှတ်သားမှု',
    'syntax.io': 'သွယ်ဝိုက်အရာဝတ္ထု',
    'syntax.p': 'non-နှုတ်ဖြင့် Predicate',
    'syntax.pp': 'preposition Phrase',
    'syntax.prep': 'ဝိဘတ်',
    'syntax.ptcl': 'အမှုန်',
    'syntax.rel': 'ဆွေမျိုးမှုန်',
    'syntax.s': 'ဘာသာရပ်',
    'syntax.v': 'ကြိယာ',
    'syntax.vc': 'လင့်ခ်ချိတ်ခြင်းကြိယာ',
    'syntax.adjp.description': 'ထားသောစာပိုဒ်တိုများ Modify နာမ်သို့မဟုတ်နာမ်စားကြောင်း',
    'syntax.adv.description':
      'Modify ကြိယာ, ပုံမှန်အားဖြင့်နောက်ထပ်စသည်တို့ထုံးစံ, အချိန်, နေရာအကြိမ်ရေ, ဒီဂရီ, defining ...',
    'syntax.advp.description':
      'ပုံမှန်အားဖြင့်နောက်ထပ်စသည်တို့ကိုထုံးစံ, အချိန်, နေရာအကြိမ်ရေ, ဒီဂရီ, defining ကြိယာပြုပြင်မွမ်းမံထားသောစာပိုဒ်တိုများ,',
    'syntax.cl.description': 'ဘာသာရပ်နှင့် ပတ်သက်. တစ်ခုအခိုင်အမာစေသည်စကားများတစ်ဦးကအုပ်စုတစု။',
    'syntax.conj.description': 'စကားလုံးများ, စာပိုဒ်တိုများ, နှင့်ဝါကျကိုဆက်သွယ်။',
    'syntax.cjp.description': 'စကားလုံးများ, စာပိုဒ်တိုများ, နှင့်ဝါကျကိုဆက်သွယ်။',
    'syntax.det.description':
      'သတ်မှတ်ပေးသည်, identifier, ဒါမှမဟုတ်တစ်ဦးနာမ်ထားသောစာပိုဒ်တိုများကိန်းဂဏန်းဖြင့်။',
    'syntax.ijp.description': 'အာမေဍိတ်သို့မဟုတ်စိတ်လှုပ်ရှားမှု၏ရုတ်တရက်စကားရပ်။',
    'syntax.nump.description': 'ကိန်းဂဏန်းအရေအတွက်ဖော်ပြသောထားသောစာပိုဒ်တိုများ',
    'syntax.np.description':
      'နာမ်အဖြစ်က၎င်း၏ဦးခေါင်းအဖြစ်နာမ်သို့မဟုတ်ကြောင့်လုပ်ငန်းဆောင်တာရှိပါတယ်တဲ့ထားသောစာပိုဒ်တိုများ။',
    'syntax.o.description':
      'အရာတိုက်ရိုက်ထိခိုက်စေခြင်းသို့မဟုတ်ကြိယာ၏အရေးယူမှုအားဖြင့်ထုတ်လုပ်ကြောင်း။',
    'syntax.o2.description':
      'အရာတိုက်ရိုက်ထိခိုက်စေခြင်းသို့မဟုတ်ကြိယာ၏အရေးယူမှုအားဖြင့်ထုတ်လုပ်ကြောင်း။',
    'syntax.oc.description': 'အဆိုပါတိုက်ရိုက်အရာဝတ္ထုတွေအကြောင်းတစ်ခုအခိုင်အမာစေသည်။',
    'syntax.om.description': 'တိုက်ရိုက်အရာဝတ္ထုမိတ်ဆက်ကြောင့်အမှုန်။',
    'syntax.io.description':
      'သွယ်ဝိုက်လုပ်ဆောင်ချက်တစ်ခုကြောင့်ထိခိုက်ကြောင်း object တစ်ခုကြိယာ၏လုပ်ဆောင်ချက်မကြာခဏလက်ခံရရှိသူ။',
    'syntax.p.description':
      'မကြာခဏ identifier သို့မဟုတ်ဘာသာရပ်ဖော်ပြထားတယ်, (သို့သော်မပေးအမြဲ) link တစ်ခုကြိယာနှင့်အတူတွေ့ရှိနိုင်ပါသည်။',
    'syntax.pp.description': 'တစ်ဦးဝိဘတ်နှင့်၎င်း၏အရာဝတ္ထုပါဝင်သည်ဟုထားသောစာပိုဒ်တိုများမွမ်းမံ။',
    'syntax.prep.description':
      'ဘယ်မှာသို့မဟုတ်သည့်အခါမကြာခဏညွှန်း, အခြားစကားနှင့်ဝိဘတ်၏အရာဝတ္ထုများ၏ဆက်ဆံရေးမျိုးဖော်ပြသည်။',
    'syntax.ptcl.description':
      'အဆိုပါအများကိန်းများသဒ္ဒါစကားလုံးအတန်းမဆိုပိုင်မ function ကိုစကားလုံးများ၏မတူကွဲပြားအုပ်စုတစ်စုကနေတစျခုမှာအဖွဲ့ဝင်',
    'syntax.rel.description': 'ဆွေမျိုးအပိုဒ်မိတ်ဆက်ကြောင့်အမှုန်။',
    'syntax.s.description':
      'အဆိုပါအပိုဒ်၏ကျန်အကြောင်းကိုတစ်ခုအခိုင်အမာသောအပိုဒ်၏အစိတ်အပိုင်းတစ်ခု။',
    'syntax.v.description':
      'စတိတ်အဆိုပါအပိုဒ်၏ဘာသာရပ်အကြောင်းကိုတစ်ခုခုနဲ့အရေးယူ, အဖြစ်အပျက်, ဒါမှမဟုတ်အခွအေနေကိုဖော်ပြလိမ့်မည်။',
    'syntax.vc.description': 'အကြောင်းအရာအဖြည့်တစ်ဦးအပိုဒ်၏ဘာသာရပ်ချိတ်ဆက်ထားပါတယ်။',
    'general.close': 'ပိတ်',
    'general.action': 'လှုပ်ရှားမှု',
    'general.select': 'ရွေးရန် ...',
    'general.back': 'ပြန်.',
    'revisionHistory.noData': 'ဤကျမ်းပိုဒ်အဘို့အဘယ်သူမျှမတည်းဖြတ်မူရာဇဝင်များရှိပါသည်။',
    'revisionHistory.translation': 'ဘာသာပြန်ချက်',
    'revisionHistory.lastChanged': 'နောက်ဆုံးပြောင်းထား',
    'sourceText.view': 'အမြင်အရင်းအမြစ်စာသားကိုဖို့ကိုနှိပ်ပါ',
    'sourceText.viewStructure': 'အမြင်အရင်းအမြစ်စာသားကိုဖွဲ့စည်းပုံမှာဖို့ကိုနှိပ်ပါ',
    'error.projectIdIsRequired': 'စီမံကိန်း ID ကိုလိုအပ်ပါသည်။',
    'error.searchTermIsRequired': 'ရှာရန်သက်တမ်းသွန်လို့မရပါဘူး။',
    'error.bookIdIsRequired': 'စာအုပ် ID ကိုစတိုးဆိုင်စာအုပ်အမည်အားလိုအပ်သည်။',
    'error.bookNameCannotBeEmpty': 'စာအုပ်အမည်အားအချည်းနှီးမဖြစ်နိုင်ပါ။',
    'error.signInIsRequiredToAccessProject':
      'သင်သည်သင်၏စီမံကိန်းကိုဝင်ရောက်ဖို့အတွက်လက်မှတ်ရေးထိုးရမည်ဖြစ်သည်။',
    error: 'အမှား',
    'error.doNotHavePermissionAccessProject':
      'သငျသညျဤစီမံကိန်းဝင်ရောက်ဖို့ခွင့်ပြုချက်မရှိကြပါဘူး။',
    'error.unauthorized':
      'ခွင့်ပြုချက်မရှိဘဲဝင်ရောက်ခွင့်သို့မဟုတ်မမှန်ကန်ပါ access ကိုတိုကင်ဖြစ်သည်။',
    'error.tokenExpired': 'သင့်ရဲ့လက္ခဏာသက်သေသက်တမ်းကုန်သွားပြီ။ Re-ရဲ့ login ပေးပါ။',
    'error.invalidApiUrl': 'မှားနေသော API ကို url ။',
    'error.badRequestInvalidJson': 'တောင်းဆိုမှုမှားယွင်းနေခြင်း။ မှားနေသော JSON ။',
    'error.windowIsNotDefined': 'ဝင်းဒိုးကိုသတ်မှတ်မထားပါ။',
    'error.unexpectedError':
      'မျှော်လင့်မထားသောအမှားတစ်ခုဖြစ်ပေါ်နေပါသည်။ ဒီဝင်းဒိုးကိုပိတ်လိုက်ပြီးထပ်ကြိုးစားပါ။',
    'error.invalidDataType': 'မှားနေသောဒေတာအမျိုးအစား။',
    'export.data': 'ပို့ကုန်မှာ Data',
    more: 'နောက်ထပ်',
    'export.to.rtf': 'RTF တင်ပို့',
    'export.to.usfm': 'USFM တင်ပို့',
    'export.titlePreparingData':
      'ငါတို့သည်သင်တို့၏ကမျြးစာကိုဘာသာပြန်ချက် data တွေကိုပြင်ဆင်နေကြသည်။',
    'export.doNotClose':
      'ဤလုပ်ငန်းစဉ်ခဏယူစေခြင်းငှါ, မနီးစပ်ဒီဝင်းဒိုးကိုပြုပါကျေးဇူးပြုပြီးနှင့် Download ပြုလုပ်ရန်အဆင်သင့်ဖြစ်တဲ့အခါသင်အကြောင်းကြားပါလိမ့်မည်။',
    'export.desc':
      'သငျသညျတင်ပို့ခြင်းနှင့်အောက်တွင်ဖော်ပြထားသောခလုတ်နှိပ်ခြင်းအားဖြင့်သင့်ရဲ့ဘာသာပြန် data တွေကိုဒေါင်းလုဒ်ဆွဲနိုင်ပါသည်။',
    'export.titleOfReady2Download': 'သင့်ရဲ့ကမျြးစာကိုဘာသာပြန်ချက် data တွေကိုအဆင်သင့်ဖြစ်ပါတယ်။',
    'export.textOfReady2Download':
      'သင့်ရဲ့ကမျြးစာကိုဘာသာပြန်ချက်ဒေါင်းလုတ်လုပ်ဖို့အောက်ကဒေါင်းလုပ် button ကို click နှိပ်ပေးပါ။',
    'export.statistics': 'စာရင်းအင်း',
    'export.book': 'စာအုပ်အမည်အား',
    'export.translatedVerses': 'ဘာသာပြန်ထားသောကျမ်းပိုဒ်',
    'export.exportedVerses': 'တင်ပို့ကျမ်းပိုဒ်',
    'export.howDoUWant2Export': 'သငျသညျပို့ကုန်ဒေတာကိုဘယ်လိုချင်ပါသလဲ?',
    'export.whichBooks': 'သငျသညျတင်ပို့မှုမှဘယ်စာအုပ် (s) ကိုလိုခငျြပါသလဲ',
    'export.byBooks': 'စာအုပ်များအားဖြင့်',
    'export.byChapters': 'အခန်းကြီးအားဖြင့်',
    'export.byVerses': 'ကျမ်းပိုဒ်အားဖြင့်',
    'export.booksInOt': 'ဓမ်မဟောငျးကမျြး',
    'export.booksInNt': 'ဓမ္မသစ်ကျမ်း',
    'export.booksInBible': 'မြေတပြင်လုံးသမ်မာကမျြး',
    'export.booksInNone': 'အဘယ်သူမျှမ',
    'export.selectRangeOfChapters': 'အခန်းကြီး၏ range',
    'export.selectRangeOfVerses': 'ကျမ်းပိုဒ်၏ range',
    'export.selectChapter': 'တစ်ဦးကျအခနျးကို Select လုပ်ပါ',
    'export.exportTypeIsRequired':
      'သငျသညျသေးသငျသညျပို့ကုန်ဒေတာချင်ကြဘူးဘယ်လိုရွေးချယ်ထားသောပြီမဟုတ်လော',
    'export.haveNotYetSelectedBooks': 'သငျသညျသေးမဆိုစာအုပ်ကိုရွေးချယ်ကြပြီမဟုတ်။',
    'error.selectABook': 'သင်တစ်ဦးစာအုပ်ရွေးချယ်ရမည်။',
    'error.invalidBookId': 'မှားနေသောစာအုပ် ID ကို။',
    'error.selectAChapter': 'သင်တစ်ဦးအခနျးကွီးရွေးချယ်ရမည်။',
    'error.invalidChapterRange': 'သင်တစ်ဦးခိုင်လုံသောမျြးအခနျးကွီးအကွာအဝေးရွေးချယ်ရမည်။',
    'error.invalidVerseRange': 'သင်တစ်ဦးခိုင်လုံသောကျမ်းပိုဒ်အကွာအဝေးရွေးချယ်ရမည်။',
    'error.invalidExportType': 'မှားနေသောပြည်ပပို့ကုန်အမျိုးအစား။',
    startover: 'ကျော် Start',
    download: 'ဒေါင်းလုပ်',
    'download.all': 'ဖိုင်များအားလုံး Download',
    'contact.header': 'ကြှနျုပျတို့ကိုဆကျသှယျရနျ',
    'contact.returnAddress': 'ပြန်သွားလိပ်စာ',
    'contact.returnAddressPlaceholder': 'ငါတို့သည်သင်တို့အဘယ်မှာလက်လှမ်းမှီနိုင်သလဲ',
    'contact.subject': 'ဘာသာရပ်',
    'contact.subjectPlaceholder': 'သင်နှင့်အတူအကူအညီနဲ့အဘယ်အရာလိုအပ်သလဲ?',
    'contact.message': 'မက်ဆေ့ခ်ျကို',
    'contact.messagePlaceholder': 'ကျွန်တော်တို့ဘယ်လိုကူညီပေးနိုင်သလဲ',
    'contact.sendMessage': 'Send ကို Message',
    'contact.success': 'မက်ဆေ့ခ်ျကိုအောင်မြင်စွာလှေတျတျောမူခဲ့သညျ။',
    'contact.failure':
      'ဤသတင်းစကားပေးပို့ခြင်းပြဿနာတစ်ခုရှိခဲ့သည်။ help@globalbibleinitiative.org ကိုဆက်သွယ်ပါ။',
    selectInterlinear: 'စာသားမညှအပြောင်းအလဲလုပ်ဖို့ကိုနှိပ်ပါ',
    'warning.screenTooSmall':
      'မြင့်မား screen resolution ကို (နိမ့်ဆုံးကျယ်ပြန့် 1024px) သို့ပြောင်းရန် ကျေးဇူးပြု. သို့မဟုတ်သင့် browser ကိုပြတင်းပေါက်ကယျြဝနျးပါ။',
    'label.dictionary': 'Concordance ရှာရန်',
    'dict.search.placeholder': 'စကားလုံး (s) ကိုရိုက်ထည့်ပါ',
    'tooltip.manuscript': 'လက်ရေးမူများမှာတွေ့နိုင်ပါတယ်',
    'tooltip.strongsx': 'ရဲ့အားကောင်းတဲ့အရေအတွက်',
    'tooltip.translation': 'ဘာသာပြန်ချက်',
    'tooltip.english': 'အင်္ဂလိပ်',
    'title.howToSearch': 'မာတိကာရှာဖွေရေးသုံးစွဲဖို့ကိုဘယ်လို',
    'desc.howToSearch':
      ', ဂရိသို့မဟုတ်ဟီးဘရူးထံမှစကားလုံး (s) ကိုရိုက်ထည့်ပါစကားလုံး (s) ကိုသင့်ရဲ့ဘာသာပြန်ချက်အနေဖြင့်ဖြစ်စေ, ခိုင်ခံ့သောနံပါတ် (s) ကို။ ဒါမှမဟုတ် "သင်၏ဘာသာပြန်ချက်" ၏ panel ကိုအတွက်ဂရိသို့မဟုတ်ဟီးဘရူးစကားလုံးများကိုသို့မဟုတ်ရှာဖွေရေး options များအဘို့ Alignment အမြင်များအပေါ်ကိုကလစ်နှိပ်ပါ။',
    'dict.noDataToDisplay': 'သင့်ရဲ့ရှာဖွေရေးစံနှုန်းများကိုက်ညီမှုမရှိသည့်ဒေတာရှိပါတယ်။',
    'title.searchTermTooMany': 'သင်လည်းအများအပြားသော့ချက်စာလုံးများထဲသို့ဝင်ခဲ့ကြ',
    'desc.searchTermTooMany':
      'သငျသညျ 8 သော့ချက်စာလုံးများတစ်ခုချင်းစီကိုအချိန်အထိရှာဖွေနိုင်ပါသည်။',
    'title.searchDictError': 'မျှော်လင့်မထားသောအမှားတစ်ခုဖြစ်ပေါ်နေပါသည်။',
    'desc.searchDictError': 'နောက်ပိုင်းမှာသို့မဟုတ်အဆက်အသွယ်ဖောက်သည်ထောက်ခံမှုထပ်ကြိုးစားပါ',
    'alignment.saveAndEditAlignment': 'ကြော်ငြာကို Save လုပ်ရန်နှင့်ညှိရန်နယူးစာသား',
    'alignment.reviewAlignment': 'ဆန်းစစ်ခြင်းစာသား Alignment',
    'dict.searchLemma': 'မူရင်းစကားလုံးရှာဖွေဖို့ကိုနှိပ်ပါ',
    'dict.searchTranslation': 'ဘာသာပြန်ချက်စကားလုံးရှာဖွေဖို့ကိုနှိပ်ပါ',
    'dict.searchStrongsX': 'ရဲ့ခိုင်ခံ့သောအရေအတွက်ကရှာဖွေဖို့ကိုနှိပ်ပါ',
    'dict.showMenu': 'အခြေအနေတွင်ကွိနှင့်ပြည့်ဝ၏စာသားကိုပြသနိုင်ဖို့ကိုကလစ်နှိပ်ပါ။',
    'dict.hideMenu': 'အခြေအနေတွင်ကွိနှင့်ပြည့်ဝ၏စာသားကိုဖုံးကွယ်ဖို့ကိုနှိပ်ပါ။',
  },
  ne: {
    'languages.en': 'अंग्रेजी',
    'languages.am': 'अम्हारिक',
    'languages.bn': 'बंगाली भाषा',
    'languages.ceb': 'सेबुआनो भाषा',
    'languages.de': 'जर्मन भाषा',
    'languages.es-419': 'स्पनिश भाषा',
    'languages.fr': 'फ्रेन्च भाषा',
    'languages.gu': 'गुजराती',
    'languages.hi': 'हिन्दी',
    'languages.hr': 'क्रोएसियाली भाषा',
    'languages.hu': 'हंगेरी भाषा',
    'languages.id': 'इन्डोनेसियाली भाषा',
    'languages.km': 'खमेर',
    'languages.kn': 'कन्नड',
    'languages.ko': 'कोरियन भाषा',
    'languages.lo': 'लाओसियन भाषा',
    'languages.ml': 'मलयालम',
    'languages.mr': 'मराठी',
    'languages.ms': 'मलेशियन',
    'languages.my': 'बर्मेली भाषा',
    'languages.ne': 'नेपाली भाषा',
    'languages.plt': 'मालागासी भाषा',
    'languages.pt-br': 'पोर्चुगिज (ब्राजिल) भाषा',
    'languages.ru': 'रूसी भाषा',
    'languages.sw': 'स्वाहिली',
    'languages.ta': 'तामिल भाषा',
    'languages.th': 'थाई भाषा',
    'languages.tl': 'टागालोग',
    'languages.te': 'Telegu भाषा',
    'languages.vi': 'भियतनामी भाषा',
    'languages.zh-hans': 'चिनियाँ भाषा (सरलीकृत)',
    'languages.zh-hant': 'चिनियाँ भाषा (परम्परागत)',
    'header.home': 'घर',
    'header.help': 'सहायता',
    'header.project': 'परियोजना',
    'header.contact': 'सम्पर्क',
    'help.contactSupport': 'सहायताको लागि help@youtranslate.bible हामीलाई इमेल गर्नुहोस्।',
    'home.ytbProvides': 'YouTranslate.Bible खुलेर प्रदान गर्दछ:',
    'home.easyToUseTool': 'एक-गर्न-सजिलो प्रयोग अनलाइन अनुवाद उपकरण',
    'home.originalTextHelp': 'यसको मूल भाषामा बाइबल पाठ बुझ्न मद्दत',
    'home.referencesAndResources': 'सन्दर्भ र प्रशिक्षण स्रोतहरू',
    'home.computerAssistance': 'राज्य-को-को-कला कम्प्युटर प्रविधिको अनुवादकहरू सहयोग गर्न',
    'profile.userProfile': 'प्रयोगकर्ता प्रोफाइल',
    'profile.language': 'भाषा',
    switchDirection: 'स्विच पाठ दिशा',
    'profile.update': 'अद्यावधिक',
    'profile.languageWarning':
      'गुगल अनुवाद अस्थायी रूपमा अंग्रेजी भन्दा अन्य भाषामा प्रयोगकर्ता इन्टरफेस अनुवाद गर्न प्रयोग भएको थियो। YTB ​​अनुवाद को शुद्धता वा विश्वसनीयता माथि कुनै नियन्त्रण छ। अनुवाद कसैले अनुवाद लेबल र व्याख्या Google बाट प्राप्त परेर उनको आफ्नै जोखिममा त गर्छ। जो कोहीले अंग्रेजी भन्दा अन्य भाषामा प्रयोगकर्ता इन्टरफेस बढी सही र विश्वसनीय अनुवाद प्रदान गर्न स्वयं मा रुचि छ भने, gbi एउटा इमेल पठाउन संपर्क विकल्प मा क्लिक गर्नुहोस्।',
    'usernav.profile': 'प्रोफाइल',
    'usernav.logout': 'बाहिर निस्कनु',
    'usernav.logout.modalTitle': 'YouTranslate.Bible को लगआउट',
    'usernav.logout.modalText':
      'तपाईं लग आउट गर्न चाहनुहुन्छ? सबै सुरक्षित नगरिएका काम नष्ट हुनेछन्।',
    cancel: 'रद्द',
    delete: 'मेटाउन',
    create: 'सिर्जना',
    update: 'अद्यावधिक',
    edit: 'सम्पादन',
    save: 'सेव',
    add: 'थप',
    translate: 'अनुवाद',
    'more.options': 'थप विकल्पहरू',
    'project.create.title': 'बाइबल अनुवाद परियोजना',
    'project.create.list': 'परियोजना सूची',
    'project.list.deleteProject': 'परियोजना मेटाउने?',
    'project.list.areYouSure': 'तपाईं यस परियोजना मेट्न निश्चित हुनुहुन्छ?',
    'project.list.workWillBeDeleted': 'परियोजना र सम्बन्धित कुनै पनि काम मेटिनेछ।',
    'project.list.completion': 'पूरा:',
    'project.list.createdDate': 'सिर्जना तिथि:',
    'project.list.beginningDate': 'सुरु मिति:',
    'project.list.targetCompletionDate': 'लक्ष्य सकिने मिति:',
    'project.list.description': 'विवरण:',
    'project.edit': 'परियोजना सम्पादन',
    'project.list.translateBible': 'बाइबल अनुवाद',
    'project.list.empty': 'तपाईंको अनुवाद परियोजना सूची खाली छ।',
    'project.list.myProjects': 'मेरो बाइबल अनुवाद परियोजनाहरु',
    'project.list.newProject': 'नयाँ परियोजना',
    'project.create.createNew': 'नयाँ परियोजना सिर्जना',
    'project.namePlaceholder': 'परियोजना नाम प्रविष्ट गर्नुहोस्',
    'project.descriptionPlaceholder': 'परियोजना विवरण प्रविष्ट गर्नुहोस्',
    'project.name': 'परियोजनाको नाम',
    'project.beginningDate': 'सुरु तिथि',
    'project.targetCompletionDate': 'लक्ष्य सकिने मिति',
    'project.description': 'विवरण',
    'project.isRTL': 'अनुवाद दायाँ--बायाँ छ',
    'project.manage-users': 'अनुवादक व्यवस्थापन',
    'project.new-user': 'नयाँ अनुवादक',
    'project.add-user': 'add अनुवादक',
    'project.users': 'परियोजना अनुवादकहरू',
    'project.read-only': 'पढ्न मात्र मिल्ने',
    'project.read&write': 'पढ्ने र लेख्ने',
    'project.owner': 'मालिक',
    'project.translator': 'अनुवादक',
    'project.message.no-such-user': 'यस्तो कुनै अनुवादक अवस्थित',
    'project.message.user-added': 'अनुवादक सफलतापूर्वक थपियो',
    'project.message.user-deleted': 'अनुवादक हटाइयो।',
    'project.message.only-owner-can-manage-users':
      'परियोजना को मालिक मात्र प्रयोगकर्ता व्यवस्थापन गर्न सक्छ',
    'project.shared': 'सहयोगी परियोजना',
    'general.delete-user': 'मेटाउने अनुवादक',
    'general.edit-user': 'प्रयोगकर्ता सम्पादन',
    'general.name': 'नाम',
    'general.email': 'ई-मेल',
    'general.permissions': 'अनुमति',
    'general.role': 'भूमिका',
    signin: 'साइन इन',
    'signin.emailPlaceholder': 'इ - मेल ठेगाना',
    'signin.passwordPlaceholder': 'पासवर्ड',
    'signin.forgotPassword': 'तपाईंको पासवर्ड बिर्सनु भयो?',
    'signin.facebook': 'फेसबुक साइन इन',
    'signin.google': 'Google संग साइन',
    'signin.dontHaveAccount': 'खाता छैन?',
    'signin.createOne': 'सिर्जना',
    'signup.join': 'YouTranslate.Bible सामेल',
    'signup.createAccountPrompt': 'सुरु गर्न तपाईँको निःशुल्क खाता सिर्जना गर्नुहोस्।',
    'signup.createAccount': 'खाता सिर्जना गर्नुहोस्',
    'signup.emailPlaceholder': 'इ - मेल ठेगाना',
    'signup.passwordPlaceholder': 'पासवर्ड',
    'passwordReset.title': 'पासवर्ड रिसेट गर्नुहोस्',
    'passwordReset.prompt': 'आफ्नो पासवर्ड रिसेट गर्न निर्देशन भएको इमेल अनुरोध गर्नुहोस्।',
    'passwordReset.emailPlaceholder': 'इ - मेल ठेगाना',
    'passwordReset.requestButtonLabel': 'इमेल अनुरोध',
    'passwordReset.success': 'इमेल पठाइएको छ।',
    'passwordReset.error': 'त्रुटी देखा पर्यो।',
    'translation.project': 'परियोजना:',
    'translation.backToProjectList': 'सूची परियोजना फिर्ता',
    'translation.book': 'पुस्तक:',
    'translation.chapter': 'अध्याय:',
    'translation.prompt':
      'तपाईं यस अध्यायमा छोड्न चाहनुहुन्छ निश्चित हुनुहुन्छ? सबै सुरक्षित नगरिएका काम नष्ट हुनेछन्।',
    'translation.prev': 'पछिल्लो',
    'translation.next': 'अर्को',
    'translation.previousChapter': 'अघिल्लो अध्याय',
    'translation.nextChapter': 'अर्को अध्याय',
    'navigator.book': 'पुस्तक:',
    'navigator.chapter': 'अध्याय:',
    'navigator.oldTestament': 'पुरानो नियमका',
    'navigator.newTestament': 'नयाँ नियमको',
    'navigator.selectBook': 'पुस्तक चयन',
    'navigator.search': 'खोज',
    'navigator.changeBook': 'पुस्तक परिवर्तन',
    'navigator.changeChapter': 'अध्याय परिवर्तन',
    Genesis: 'उत्पत्ति',
    Exodus: 'प्रस्थान',
    Leviticus: 'लेवी',
    Numbers: 'संख्या',
    Deuteronomy: 'व्यवस्था',
    Joshua: 'यहोशू',
    Judges: 'न्यायाधीशहरूले',
    Ruth: 'रूथ',
    '1 Samuel': '1 शमूएल',
    '2 Samuel': '2 शमूएल',
    '1 Kings': '1 राजा',
    '2 Kings': '2 राजा',
    '1 Chronicles': '1 इतिहास',
    '2 Chronicles': '2 इतिहास',
    Ezra: 'एज्रा',
    Nehemiah: 'नहेम्याह',
    Esther: 'एस्तर',
    Job: 'काम',
    Psalms: 'भजनमा',
    Proverbs: 'हितोपदेश',
    Ecclesiastes: 'उपदेशक',
    'Song of Songs': 'गीतहरूको गीत',
    Isaiah: 'यशैया',
    Jeremiah: 'यर्मिया',
    Lamentations: 'विलाप',
    Ezekiel: 'इजकिएल',
    Daniel: 'दानियल',
    Hosea: 'होशे',
    Joel: 'योएल',
    Amos: 'आमोस',
    Obadiah: 'ओबद्याले',
    Jonah: 'योना',
    Micah: 'मीका',
    Nahum: 'नहूम',
    Habakkuk: 'हबकूक',
    Zephaniah: 'सपन्याह',
    Haggai: 'हाग्गै',
    Zechariah: 'जकरिया',
    Malachi: 'मलाकी',
    Matthew: 'मत्ती',
    Mark: 'मार्क',
    Luke: 'लूका',
    John: 'जन',
    Acts: 'प्रेरित',
    Romans: 'रोमी',
    '1 Corinthians': '1 कोरिन्थी',
    '2 Corinthians': '2 कोरिन्थी',
    Galatians: 'गलाती',
    Ephesians: 'एफिसी',
    Philippians: 'फिलिप्पी',
    Colossians: 'कलस्सी',
    '1 Thessalonians': '1 थिस्सलोनिकी',
    '2 Thessalonians': '2 थिस्सलोनिकी',
    '1 Timothy': '1 तिमोथी',
    '2 Timothy': '2 तिमोथी',
    Titus: 'तीतस',
    Philemon: 'फिलेमोन',
    Hebrews: 'हिब्रू',
    James: 'जेम्स',
    '1 Peter': '1 पत्रुस',
    '2 Peter': '2 पत्रुस',
    '1 John': '1 यूहन्ना',
    '2 John': '2 यूहन्ना',
    '3 John': '3 यूहन्ना',
    Jude: 'यहूदा',
    Revelation: 'प्रकाश',
    'resources.prompt':
      'तपाईं अनुवाद गर्दै खण्डमा मा थप अन्तरदृष्टि लागि बाइबलीय स्रोतहरू थप्नुहोस्।',
    'resources.add': 'बाइबलीय स्रोत थप्न',
    Manuscript: 'पांडुलिपि',
    Morphology: 'आकृति',
    Lexicon: 'Lexicon',
    'Syntactic Tree': 'Syntactic ट्री',
    'Go to project list': 'सूची परियोजना जाने',
    'Project not found': 'परियोजना फेला परेन वा अपर्याप्त अनुमतिहरू।',
    'error.referenceDoesNotExistForResource':
      'यस बाइबल अनुवाद चयन खण्डमा लागि सामग्री समावेश गर्दैन।',
    'references.addAnother': 'अर्को अनुवाद थप्न',
    'references.referencePrompt': 'थप अन्तरदृष्टि लागि बाइबल अन्य अनुवाद थप्नुहोस्।',
    'references.add': 'बाइबल अनुवाद थप्न',
    'references.licensing': 'इजाजत सूचना',
    'references.licensingEmpty': 'कुनै इजाजत जानकारी यस वस्तुको लागि फेला परेन।',
    'translation.complete': 'अनुवाद पूरा',
    Alignment: 'पङ्क्तिबद्ध',
    Share: 'शेयर',
    'Your Translation': 'तपाईंको अनुवाद',
    'Enter your translation': 'तपाईंको अनुवाद प्रविष्ट',
    OK: 'ठिक छ',
    'error.verseTranslationEmpty': 'तपाईं अनुवाद सामग्री खाली छोड्न सक्नुहुन्छ।',
    'translation.hide.completed': 'पूरा लुकाउनुहोस्',
    alignment: 'पङ्क्तिबद्ध',
    'alignment.verify': 'प्रमाणित गर्न र बन्द गर्नुहोस्।',
    'alignment.close': 'बिना रुजुगर्दैछ बन्द',
    'alignment.backToEditor': 'सम्पादक फिर्ता',
    'alignment.error':
      'केहि गलत भयो। समस्या यथावत् छ भने, YouTranslate.Bible टोली सम्पर्क गर्नुहोस्।',
    'alignment.add': 'लिंक थप्न',
    'alignment.delete': 'लिंक हटाउन',
    'alignment.clear': 'चयन स्पष्ट',
    'alignment.reverse': 'उल्टो प्रदर्शन',
    'alignment.removeOrClear': 'चयन गरिएको लिङ्क वा स्पष्ट चयन हटाउनुहोस्।',
    'alignment.addOrRemove': 'नयाँ लिङ्क सिर्जना गर्न वा अघिल्लो एक मेट्नुहोस्।',
    'alignment.selectAndAdd': 'लिंक गर्न खण्डहरूमा अनुरूप चयन गर्नुहोस्।',
    'error.mustBeSignedToMakeChanges': 'तपाईंले परिवर्तनहरू मा हस्ताक्षर हुनुपर्छ।',
    'error.mustBeSignedToTranslateBible': 'के तपाईं बाइबल अनुवाद मा हस्ताक्षर हुनुपर्छ।',
    checking: 'जाँच',
    'verseEditor.unexpectedError': 'एउटा अनपेक्षित त्रुटि देखा परेको छ।',
    verses: 'पद द्वारा',
    words: 'शब्द द्वारा',
    'leftPanel.name': 'बाइबल को मूल पाठ',
    'rightPanel.name': 'बाइबल अनुवाद',
    'bottomPanel.name': 'बाइबल लागि संसाधन',
    revisionHistory: 'संशोधन इतिहास',
    'syntax.adv': 'Adverbial परिमार्जक',
    'syntax.adjp': 'विशेषणात्मक पदावली',
    'syntax.advp': 'adverb पदावली',
    'syntax.cl': 'खण्ड',
    'syntax.conj': 'संयोजनमा',
    'syntax.cjp': 'संयोजनमा',
    'syntax.det': 'Determiner',
    'syntax.ijp': 'विस्मयाधिबोधक',
    'syntax.nump': 'संख्यात्मक पदावली',
    'syntax.np': 'संज्ञा वाक्यांश',
    'syntax.o': 'प्रत्यक्ष वस्तु',
    'syntax.o2': 'दोस्रो वस्तु',
    'syntax.oc': 'वस्तु रङथप्नुहोस्',
    'syntax.om': 'वस्तु मार्कर',
    'syntax.io': 'अप्रत्यक्ष वस्तु',
    'syntax.p': 'गैर-मौखिक Predicate',
    'syntax.pp': 'Prepositional पदावली',
    'syntax.prep': 'निपात',
    'syntax.ptcl': 'कण',
    'syntax.rel': 'सापेक्ष कण',
    'syntax.s': 'विषय',
    'syntax.v': 'क्रिया',
    'syntax.vc': 'जोडने क्रियासमावेशीकरण',
    'syntax.adjp.description': 'एक संज्ञा वा सर्वनाम modifies कि वाक्यांश',
    'syntax.adv.description':
      'Modifies को क्रिया, सामान्यतया थप परिभाषित तरिका, समय, स्थान, आवृत्ति, डिग्री, आदि ...',
    'syntax.advp.description':
      'वाक्यांश क्रिया परिमार्जन, सामान्यतया थप परिभाषित तरिका, समय, स्थान, आवृत्ति, डिग्री, आदि',
    'syntax.cl.description': 'शब्दहरू एक समूह विषयमा एक दाबी बनाउँछ कि।',
    'syntax.conj.description': 'शब्द, वाक्यांश र क्लाज जडान।',
    'syntax.cjp.description': 'शब्द, वाक्यांश र क्लाज जडान।',
    'syntax.det.description': 'निर्दिष्ट, पहिचान, वा quantifies एक संज्ञा वाक्यांश।',
    'syntax.ijp.description': 'उद्गार वा भावना को अचानक अभिव्यक्ति।',
    'syntax.nump.description': 'संख्यात्मक मात्रा वर्णन वाक्यांश',
    'syntax.np.description':
      'यसको टाउको रूपमा संज्ञा वा त्यो कार्य एक संज्ञा रूपमा छ कि एक वाक्यांश।',
    'syntax.o.description': 'त्यो जो कारण सीधा प्रभावित छ, वा क्रिया को कार्य द्वारा उत्पादित।',
    'syntax.o2.description': 'त्यो जो कारण सीधा प्रभावित छ, वा क्रिया को कार्य द्वारा उत्पादित।',
    'syntax.oc.description': 'प्रत्यक्ष वस्तु बारेमा एउटा दाबी बनाउँछ।',
    'syntax.om.description': 'एक प्रत्यक्ष वस्तु परिचय कि कण।',
    'syntax.io.description':
      'अप्रत्यक्ष कार्य प्रभावित छ वस्तु, एक क्रिया को कार्य को अक्सर प्राप्तकर्ता।',
    'syntax.p.description': 'पहिचान वा विषय, वर्णन अक्सर (तर सधैं) सँगै एक लिङ्क क्रिया संग हुन्छ।',
    'syntax.pp.description': 'परिमार्जन वाक्यांश एक निपात र यसको वस्तु मिलेर।',
    'syntax.prep.description':
      'अक्सर संकेत जहाँ वा, अर्को शब्दमा संग निपात को वस्तु को सम्बन्ध संकेत गर्छ।',
    'syntax.ptcl.description':
      'समारोह शब्दहरूको विविध समूहबाट एक सदस्य विभक्ति व्याकरण शब्द कक्षाहरू कुनै पनि होइन भन्ने',
    'syntax.rel.description': 'एक नातेदार दफा परिचय कि कण।',
    'syntax.s.description': 'को दफा बाँकी बारेमा एउटा दाबी बनाउँछ भन्ने दफा को भाग।',
    'syntax.v.description':
      'को दफा विषयको बारेमा केहि राज्य अमेरिका र कार्य, घटना, वा अवस्था व्यक्त गर्न सक्छ।',
    'syntax.vc.description': 'विषय पूरक एउटा दफा विषयको लिंक।',
    'general.close': 'बन्द',
    'general.action': 'कार्य',
    'general.select': 'चयन गर्नुहोस् ...',
    'general.back': 'फिर्ता',
    'revisionHistory.noData': 'यो पद को लागि कुनै संशोधन इतिहास छ।',
    'revisionHistory.translation': 'अनुवाद',
    'revisionHistory.lastChanged': 'पछिल्लो परिवर्तन',
    'sourceText.view': 'स्रोत पाठ हेर्न क्लिक गर्नुहोस्',
    'sourceText.viewStructure': 'स्रोत पाठ संरचना हेर्न क्लिक गर्नुहोस्',
    'error.projectIdIsRequired': 'परियोजना परिचय आवश्यक छ।',
    'error.searchTermIsRequired': 'खोज शब्द खाली गर्न सक्दैन।',
    'error.bookIdIsRequired': 'पुस्तक परिचय पुस्तक नाम भण्डारण गर्न आवश्यक छ।',
    'error.bookNameCannotBeEmpty': 'पुस्तक नाम खाली हुन सक्दैन।',
    'error.signInIsRequiredToAccessProject': 'तपाईं आफ्नो परियोजना पहुँच गर्न साइन इन हुनुपर्छ।',
    error: 'त्रुटि',
    'error.doNotHavePermissionAccessProject': 'तपाईं यस परियोजना पहुँच गर्न अनुमति छैन।',
    'error.unauthorized': 'अनधिकृत पहुँच वा अवैध पहुँच टोकन।',
    'error.tokenExpired': 'तपाईंको टोकन समाप्त भएको छ। कृपया पुन: लग इन।',
    'error.invalidApiUrl': 'अवैध एपीआई URL।',
    'error.badRequestInvalidJson': 'खराब अनुरोध। अवैध JSON।',
    'error.windowIsNotDefined': 'विन्डो परिभाषित छैन।',
    'error.unexpectedError':
      'एउटा अनपेक्षित त्रुटि देखा परेको छ। कृपया यो विन्डो बन्द र फेरि प्रयास गर्नुहोस्।',
    'error.invalidDataType': 'अवैध डाटा प्रकार।',
    'export.data': 'निर्यात डाटा',
    more: 'थप',
    'export.to.rtf': 'RTF को निर्यात',
    'export.to.usfm': 'USFM निर्यात',
    'export.titlePreparingData': 'हामी आफ्नो बाइबल अनुवाद डाटा तयार छन्।',
    'export.doNotClose':
      'यो प्रक्रिया एक समय लाग्न सक्छ, कृपया नजिक यो विन्डो गर्न र यसलाई डाउनलोड गर्न तयार छ जब तपाईं सूचित गरिनेछ।',
    'export.desc': 'तपाईं निर्यात र तल बटन क्लिक गरेर आफ्नो अनुवाद डाटा डाउनलोड गर्न सक्छन्।',
    'export.titleOfReady2Download': 'तपाईंको बाइबल अनुवाद डाटा तयार छ।',
    'export.textOfReady2Download':
      'तपाईंको बाइबल अनुवाद डाउनलोड गर्न तलको डाउनलोड बटन क्लिक गर्नुहोस्।',
    'export.statistics': 'तथ्याङ्क',
    'export.book': 'पुस्तक नाम',
    'export.translatedVerses': 'अनुवाद पद',
    'export.exportedVerses': 'निर्यात पद',
    'export.howDoUWant2Export': 'डाटा निर्यात गर्न तपाईं कसरी गर्न चाहनुहुन्छ?',
    'export.whichBooks': 'जो किताब (हरू) तपाईं निर्यात गर्न चाहनुहुन्छ?',
    'export.byBooks': 'पुस्तकहरू द्वारा',
    'export.byChapters': 'अध्याय द्वारा',
    'export.byVerses': 'पद द्वारा',
    'export.booksInOt': 'पुरानो नियमका',
    'export.booksInNt': 'नयाँ नियमको',
    'export.booksInBible': 'पूरै बाइबल',
    'export.booksInNone': 'कुनै पनि',
    'export.selectRangeOfChapters': 'अध्याय को सीमा',
    'export.selectRangeOfVerses': 'पद को सीमा',
    'export.selectChapter': 'एक अध्याय चयन',
    'export.exportTypeIsRequired':
      'तपाईं कसरी निर्यात डाटा गर्न चाहनुहुन्छ तपाईंले अहिलेसम्म बारेमा चयन छैन?',
    'export.haveNotYetSelectedBooks': 'तपाईंले अहिलेसम्म कुनै पनि पुस्तक चयन गरेको छैन।',
    'error.selectABook': 'तपाईं एक पुस्तक चयन गर्नुपर्छ।',
    'error.invalidBookId': 'अवैध पुस्तक परिचय।',
    'error.selectAChapter': 'तपाईं एक अध्याय चयन गर्नुपर्छ।',
    'error.invalidChapterRange': 'तपाईंले वैध अध्याय दायरा चयन गर्नुपर्छ।',
    'error.invalidVerseRange': 'तपाईंले वैध पद दायरा चयन गर्नुपर्छ।',
    'error.invalidExportType': 'अवैध निर्यात प्रकार।',
    startover: 'शुरू',
    download: 'डाउनलोड',
    'download.all': 'सबै फाइलहरू डाउनलोड',
    'contact.header': 'हामीलाई सम्पर्क गर्नुहोस',
    'contact.returnAddress': 'फिर्ती ठेगाना',
    'contact.returnAddressPlaceholder': 'हामी तपाईं कहाँ पुग्न सक्छ?',
    'contact.subject': 'विषय',
    'contact.subjectPlaceholder': 'तिमीलाई के मा सहयोग चाहिएको छ?',
    'contact.message': 'सन्देश',
    'contact.messagePlaceholder': 'हामी कसरी मदत गर्न सक्छ?',
    'contact.sendMessage': 'सन्देश पठाउन',
    'contact.success': 'सन्देश सफलतापूर्वक पठाइएको थियो।',
    'contact.failure':
      'यो सन्देश पठाउन समस्या थियो। कृपया help@globalbibleinitiative.org सम्पर्क गर्नुहोस्।',
    selectInterlinear: 'इन्टरलिनियर पाठ परिवर्तन गर्न क्लिक गर्नुहोस्',
    'warning.screenTooSmall':
      'एक उच्च स्क्रिन संकल्प (न्यूनतम 1024px व्यापक) स्विच गर्नुहोस् वा कृपया आफ्नो ब्राउजर विन्डो ठूला मनका।',
    'label.dictionary': 'कबूल खोज',
    'dict.search.placeholder': 'शब्द (हरू) प्रविष्ट गर्नुहोस्',
    'tooltip.manuscript': 'पांडुलिपि',
    'tooltip.strongsx': 'बलियो नम्बर',
    'tooltip.translation': 'अनुवाद',
    'tooltip.english': 'अंग्रेजी',
    'title.howToSearch': 'कबूल खोज कसरी प्रयोग गर्ने',
    'desc.howToSearch':
      '(हरू) ग्रीक वा हिब्रू, शब्द आफ्नो अनुवाद, वा बलियो नम्बर (हरू) बाट शब्द (हरू) प्रविष्ट गर्नुहोस्। वा "तपाईंको अनुवाद" वा खोज विकल्प लागि पंक्तिबद्ध दृश्यहरु प्यानल मा ग्रीक वा हिब्रू शब्द मा क्लिक गर्नुहोस्।',
    'dict.noDataToDisplay': 'तपाईंको खोज मापदण्ड मेल खाने कुनै डाटा छन्।',
    'title.searchTermTooMany': 'तपाईं पनि धेरै किवर्ड प्रविष्ट गर्नुभएको',
    'desc.searchTermTooMany': 'तपाईं 8 प्रत्येक समय किवर्ड खोज गर्न सक्नुहुन्छ।',
    'title.searchDictError': 'एउटा अनपेक्षित त्रुटि देखा परेको छ।',
    'desc.searchDictError': 'पछि फेरि प्रयास गर्नुहोस् वा सम्पर्क ग्राहक समर्थन',
    'alignment.saveAndEditAlignment': 'सेव र पङ्क्तिबद्ध नयाँ पाठ',
    'alignment.reviewAlignment': 'समीक्षा पाठ पंक्तिबद्ध',
    'dict.searchLemma': 'मूल शब्द खोजी गर्न क्लिक गर्नुहोस्',
    'dict.searchTranslation': 'अनुवाद शब्द खोजी गर्न क्लिक गर्नुहोस्',
    'dict.searchStrongsX': 'बलियो नम्बर खोजी गर्न क्लिक गर्नुहोस्',
    'dict.showMenu': 'सन्दर्भमा घटनाहरू पूर्ण पाठ देखाउन क्लिक गर्नुहोस्।',
    'dict.hideMenu': 'सन्दर्भमा घटनाहरू पूर्ण पाठ लुकाउन क्लिक गर्नुहोस्।',
  },
  plt: {
    'languages.en': 'anglisy',
    'languages.am': 'Amharic',
    'languages.bn': 'Bengali Language',
    'languages.ceb': 'Cebuano Language',
    'languages.de': 'Anarana Language',
    'languages.es-419': 'Espaniola Language',
    'languages.fr': 'French Language',
    'languages.gu': 'Gujarati',
    'languages.hi': 'Hindi',
    'languages.hr': 'Kroaty Language',
    'languages.hu': 'Fiteny hongariana',
    'languages.id': 'Fiteny indonezianina',
    'languages.km': 'Khmer',
    'languages.kn': 'Kannada',
    'languages.ko': 'Koreana Language',
    'languages.lo': 'Laosianina Language',
    'languages.ml': 'Malayalam',
    'languages.mr': 'Marathi',
    'languages.ms': 'Maleziana',
    'languages.my': 'Birmana Language',
    'languages.ne': 'Nepali Language',
    'languages.plt': 'Malagasy Fiteny',
    'languages.pt-br': 'Anarana (Brazil) Language',
    'languages.ru': 'Rosiana Language',
    'languages.sw': 'Swahili',
    'languages.ta': 'Tamil Language',
    'languages.th': 'Thai Language',
    'languages.tl': 'Tagalog',
    'languages.te': 'Telegu Language',
    'languages.vi': 'vietnamiana Language',
    'languages.zh-hans': 'Fiteny sinoa (Simplified)',
    'languages.zh-hant': 'Fiteny sinoa (Nentim-paharazana)',
    'header.home': 'Home',
    'header.help': 'Vonjeo',
    'header.project': 'Project',
    'header.contact': 'Contact',
    'help.contactSupport': 'Mba mailaka antsika help@youtranslate.bible fanampiana.',
    'home.ytbProvides': 'Manome malalaka YouTranslate.Bible:',
    'home.easyToUseTool': "Mora-to-fampiasana fitaovana fandikan-teny amin'ny aterineto",
    'home.originalTextHelp':
      "Ampio hahatakatra ny soratra ao amin'ny Baiboly amin'ny fiteny tany am-boalohany",
    'home.referencesAndResources': 'Reference sy ny fampiofanana loharanon-karena',
    'home.computerAssistance':
      'State-of-the-kanto solosaina teknolojia mba hanampiana ny mpandika teny',
    'profile.userProfile': 'User Profile',
    'profile.language': 'fiteny',
    switchDirection: 'Switch Text Direction',
    'profile.update': 'Update',
    'profile.languageWarning':
      "Google Zavatra nampiasaina mba handika ny mpampiasa interface tsara ho amin'ny fiteny hafa ankoatra ny teny anglisy vetivety. YTB ​​tsy misy fanaraha-maso ny ny marina na azo itokisana ny fandikan-teny. Na iza na iza miantehitra amin'ny voadika marika sy ny fanazavana azo avy amin'ny Google Zavatra manao izany amin'ny azy manokana doza. Raha misy olona liana amin'ny an-tsitrapo mba hanomezana marina sy azo antoka kokoa ny fandikana ny mpampiasa interface tsara ho amin'ny fiteny hafa ankoatra ny teny anglisy, tsindrio eo amin'ny safidy mba Contact Takelaky ny serasera ho GBI.",
    'usernav.profile': 'Profile',
    'usernav.logout': 'Miala',
    'usernav.logout.modalTitle': 'Logout ny YouTranslate.Bible',
    'usernav.logout.modalText':
      'Azonao antoka tianao Logout? Tsy voavonjy rehetra dia ho very asa.',
    cancel: 'hanafoana',
    delete: 'Mamafa',
    create: 'mamorona',
    update: 'Update',
    edit: 'Edit',
    save: 'Save',
    add: 'Add',
    translate: 'translate',
    'more.options': 'More safidy',
    'project.create.title': 'Malagasy Bible Project',
    'project.create.list': 'Project list',
    'project.list.deleteProject': 'Fafao Project?',
    'project.list.areYouSure': 'Siora ianao fa te-hamafa ity tetikasa ity?',
    'project.list.workWillBeDeleted': 'Ny tetikasa sy ny asa rehetra mifandray dia ho voafafa.',
    'project.list.completion': 'vita:',
    'project.list.createdDate': 'Noforonina Date:',
    'project.list.beginningDate': 'Nanomboka Date:',
    'project.list.targetCompletionDate': 'Target Mahafeno Date:',
    'project.list.description': 'Description:',
    'project.edit': 'Edit Project',
    'project.list.translateBible': 'Zavatra Bible',
    'project.list.empty': 'Ny fandikan-teny tetikasa lisitra dia foana tsy mitondra fanatitra.',
    'project.list.myProjects': 'Ny Bible Malagasy Projects',
    'project.list.newProject': 'New Project',
    'project.create.createNew': 'Mamorona vaovao Project',
    'project.namePlaceholder': 'Tetikasa Ampidiro anarana',
    'project.descriptionPlaceholder': 'Tetikasa Ampidiro Famaritana',
    'project.name': 'Project anarana',
    'project.beginningDate': 'Nanomboka Date',
    'project.targetCompletionDate': 'Target Mahafeno Date',
    'project.description': 'Description',
    'project.isRTL': 'Fandikan-teny marina-to-ankavia',
    'project.manage-users': 'mitantana Translators',
    'project.new-user': 'New mpandika teny',
    'project.add-user': 'Add mpandika teny',
    'project.users': 'Project mpandika teny',
    'project.read-only': 'Vakio ihany',
    'project.read&write': 'Vakio & Soraty',
    'project.owner': 'Owner',
    'project.translator': 'Translator',
    'project.message.no-such-user': 'Tsy misy toy izany misy mpandika teny',
    'project.message.user-added': 'Translator nanampy soa aman-tsara',
    'project.message.user-deleted': 'Translator nesorina.',
    'project.message.only-owner-can-manage-users':
      "Ihany no tompon'ny tetikasa afaka mitantana mpampiasa",
    'project.shared': 'fiaraha tetikasa',
    'general.delete-user': 'Mamafa mpandika teny',
    'general.edit-user': 'Hanova ny mpampiasa',
    'general.name': 'Anarana',
    'general.email': 'E-mail',
    'general.permissions': 'Permissions',
    'general.role': 'anjara asa',
    signin: 'Hiditra',
    'signin.emailPlaceholder': 'Adiresy mailaka',
    'signin.passwordPlaceholder': 'Password',
    'signin.forgotPassword': 'Hadinonao ny tenimiafinao?',
    'signin.facebook': "Midira amin'ny Facebook",
    'signin.google': "Midira amin'ny Google",
    'signin.dontHaveAccount': 'Aza manana kaonty?',
    'signin.createOne': 'hamorona iray',
    'signup.join': 'Join YouTranslate.Bible',
    'signup.createAccountPrompt': 'Mamorona kaonty hanombohana maimaim-poana.',
    'signup.createAccount': 'Hamorona kaonty',
    'signup.emailPlaceholder': 'Adiresy mailaka',
    'signup.passwordPlaceholder': 'Password',
    'passwordReset.title': 'Password Reset',
    'passwordReset.prompt':
      "Mangataka mailaka miaraka amin'ny torolalana famerenana ny tenimiafina.",
    'passwordReset.emailPlaceholder': 'Adiresy mailaka',
    'passwordReset.requestButtonLabel': 'Request Email',
    'passwordReset.success': 'Ilay mailaka.',
    'passwordReset.error': 'Fahadisoana no nitranga.',
    'translation.project': 'TETIKASA:',
    'translation.backToProjectList': "Miverina amin'ny tetikasa lisitra",
    'translation.book': 'Book:',
    'translation.chapter': 'Chapter:',
    'translation.prompt':
      'Marina ve ianao te-hiala ity toko ity? Tsy voavonjy rehetra dia ho very asa.',
    'translation.prev': 'Prev',
    'translation.next': 'Manaraka',
    'translation.previousChapter': 'Previous toko',
    'translation.nextChapter': 'Next toko',
    'navigator.book': 'Book:',
    'navigator.chapter': 'Chapter:',
    'navigator.oldTestament': 'Old Testament',
    'navigator.newTestament': 'New Testament',
    'navigator.selectBook': 'Select boky',
    'navigator.search': 'Search',
    'navigator.changeBook': 'Change boky',
    'navigator.changeChapter': 'Change toko',
    Genesis: 'Genesis',
    Exodus: 'fifindra',
    Leviticus: 'Levitikosy',
    Numbers: 'Numbers',
    Deuteronomy: 'Deuteronomy',
    Joshua: 'Joshua',
    Judges: 'Mpitsara',
    Ruth: 'Ruth',
    '1 Samuel': '1 Samuel',
    '2 Samuel': '2 Samuel',
    '1 Kings': '1 Mpanjaka',
    '2 Kings': '2 Mpanjaka',
    '1 Chronicles': '1 Tantara',
    '2 Chronicles': '2 Tantara',
    Ezra: 'Ezra',
    Nehemiah: 'Nehemiah',
    Esther: 'Esther',
    Job: 'Job',
    Psalms: 'Psalms',
    Proverbs: 'Ohabolana',
    Ecclesiastes: 'Mpitoriteny',
    'Song of Songs': "Tonon-kiran'i Solomona",
    Isaiah: 'Isaiah',
    Jeremiah: 'Jeremiah',
    Lamentations: 'Fitomaniana',
    Ezekiel: 'Ezekiel',
    Daniel: 'Daniel',
    Hosea: 'Hosea',
    Joel: 'Joel',
    Amos: 'Amos',
    Obadiah: 'Obadia',
    Jonah: 'Jona',
    Micah: 'Mika',
    Nahum: 'Nahum',
    Habakkuk: 'Habakoka',
    Zephaniah: 'Zefania',
    Haggai: 'Hagay',
    Zechariah: 'Zakaria',
    Malachi: 'Malakia',
    Matthew: 'Matthew',
    Mark: 'marika',
    Luke: 'Luke',
    John: 'John',
    Acts: "Asan'ny Apostoly",
    Romans: 'Romana',
    '1 Corinthians': '1 Korintiana',
    '2 Corinthians': '2 Korintiana',
    Galatians: 'Galatianina',
    Ephesians: 'Efesianina',
    Philippians: 'Filipianina',
    Colossians: 'Kolosiana',
    '1 Thessalonians': '1 Tesalonianina',
    '2 Thessalonians': '2 Tesalonianina',
    '1 Timothy': '1 Timothy',
    '2 Timothy': '2 Timothy',
    Titus: 'Titus',
    Philemon: 'Philemon',
    Hebrews: 'Hebrews',
    James: 'Malagasy',
    '1 Peter': '1 Peter',
    '2 Peter': '2 Peter',
    '1 John': '1 John',
    '2 John': '2 John',
    '3 John': '3 Jôhany',
    Jude: 'Joda',
    Revelation: 'Revelation',
    'resources.prompt':
      'Add Baiboly loharanon-karena fanampiny hahatakatra ilay andalana ianao mandika.',
    'resources.add': 'Hanampy ara-Baiboly loharano',
    Manuscript: 'Sora-tanana',
    Morphology: 'Morphologie',
    Lexicon: 'Lexicon',
    'Syntactic Tree': 'Syntactic Tree',
    'Go to project list': "Mandehana any amin'ny tetikasa lisitra",
    'Project not found': "Project tsy hita na tsy fahampian'ny alalana.",
    'error.referenceDoesNotExistForResource':
      "Fandikan-teny ao amin'ny Baiboly dia tsy ahitana afa-po ny andalana voafantina.",
    'references.addAnother': 'Add hafa fandikan-teny',
    'references.referencePrompt':
      "Add fandikan-tenin'ny Baiboly hafa ho an'ny lalin-tsaina fanampiny.",
    'references.add': 'Add fandikan-teny ara-baiboly',
    'references.licensing': 'Licensing Information',
    'references.licensingEmpty': "Tsy misy fahazoan-dalana vaovao hita ho an'ny teny.",
    'translation.complete': 'Translation tanteraka',
    Alignment: 'Fanitsiana',
    Share: 'Share',
    'Your Translation': 'ny Translation',
    'Enter your translation': 'Ampidiro ny fandikan-teny',
    OK: 'ok',
    'error.verseTranslationEmpty': 'Tsy afaka mandao ny fandikan-teny afa-po foana.',
    'translation.hide.completed': 'afeno vita',
    alignment: 'Fanitsiana',
    'alignment.verify': 'Hamarino ka hanidy.',
    'alignment.close': 'Close tsy misy fanamarinana',
    'alignment.backToEditor': 'Back to Editor',
    'alignment.error':
      'Nisy zavatra tsy nety. Raha mbola mitohy ny olana, dia mifandraisa ny YouTranslate.Bible ekipa.',
    'alignment.add': 'Add Link',
    'alignment.delete': 'Esory Link',
    'alignment.clear': 'Clear Selection',
    'alignment.reverse': 'mifanohitra Display',
    'alignment.removeOrClear': 'Esory ny rohy na mazava voafantina fifantenana.',
    'alignment.addOrRemove': 'Mamorona vaovao mamafa rohy na ny teo aloha indray.',
    'alignment.selectAndAdd': 'Mifidiana mifanaraka fizarana ny rohy.',
    'error.mustBeSignedToMakeChanges': 'Tsy maintsy ho nanao sonia hanao fanovana.',
    'error.mustBeSignedToTranslateBible': 'Tsy maintsy ho nanao sonia tao mba handika ny Baiboly.',
    checking: 'fanamarinana',
    'verseEditor.unexpectedError': 'Tsy nampoizina no nitranga fahadisoana.',
    verses: 'By Verse',
    words: 'By Teny',
    'leftPanel.name': 'Original lahatsoratry ny Baiboly',
    'rightPanel.name': "Zavatra ao Amin'ny Baiboly",
    'bottomPanel.name': 'Resources ny Baiboly',
    revisionHistory: 'Revision History',
    'syntax.adv': 'fanononana Modifier',
    'syntax.adjp': 'Adjectival Phrase',
    'syntax.advp': 'Anarana Phrase',
    'syntax.cl': 'fepetra',
    'syntax.conj': 'Anarana iombonana',
    'syntax.cjp': 'Anarana iombonana',
    'syntax.det': 'Determiner',
    'syntax.ijp': 'Anarana iombonana',
    'syntax.nump': 'isa Phrase',
    'syntax.np': 'Anarana iombonana Phrase',
    'syntax.o': 'Direct Object',
    'syntax.o2': 'faharoa Object',
    'syntax.oc': "zavatra famenon'ny",
    'syntax.om': 'zavatra Marker',
    'syntax.io': 'mivantana Object',
    'syntax.p': 'Non-Verbal enti-',
    'syntax.pp': 'Litera Phrase',
    'syntax.prep': 'Anarana iombonana',
    'syntax.ptcl': 'sombiny',
    'syntax.rel': 'havany sombiny',
    'syntax.s': 'Subject',
    'syntax.v': "Endriky ny matoanteny amin'ny",
    'syntax.vc': 'mampitohy matoanteny',
    'syntax.adjp.description': 'Andian-teny izay Manova ny anarana na ny mpisolo',
    'syntax.adv.description':
      "Manova ny matoanteny amin'ny, matetika famaritana bebe kokoa izany koa, ny fotoana, toerana, matetika, mari-pahaizana, sns ...",
    'syntax.advp.description':
      "Andian-teny manova ny matoanteny amin'ny, matetika famaritana bebe kokoa izany koa, ny fotoana, toerana, matetika, mari-pahaizana, sns",
    'syntax.cl.description': 'Ny andian-teny izay mahatonga ny filazana momba ilay foto-kevitra.',
    'syntax.conj.description': 'Mampifandray teny, andian-teny, sy ny fepetra.',
    'syntax.cjp.description': 'Mampifandray teny, andian-teny, sy ny fepetra.',
    'syntax.det.description': 'Dia mamaritra, mamantatra, na andian-teny quantifies ny anarana.',
    'syntax.ijp.description': 'Tsoraka na tampoka ny fihetseham-po fanehoana.',
    'syntax.nump.description': 'Andian-teny izay mamaritra isa be',
    'syntax.np.description':
      'Ny andian-teny izay manana anarana ho toy ny loha na ny andraikitra ho toy ny anarana.',
    'syntax.o.description':
      "Izay voakasika mivantana, niteraka, na novokarin'ny ny hetsika ny matoanteny amin'ny.",
    'syntax.o2.description':
      "Izay voakasika mivantana, niteraka, na novokarin'ny ny hetsika ny matoanteny amin'ny.",
    'syntax.oc.description': 'Nanao ny filazana momba ny Direct zavatra.',
    'syntax.om.description': 'Sombiny izay mampahafantatra mivantana zavatra.',
    'syntax.io.description':
      "Zavatra izay ankolaka fiantraikany eo amin'ny asa, matetika ny mpandray ny hetsika ny matoanteny amin'ny.",
    'syntax.p.description':
      "Ampitoviana na mamaritra ny foto-kevitra, matetika (fa tsy foana) miara-mitranga amin'ny mampitohy ny matoanteny.",
    'syntax.pp.description': 'Manova andian-teny ahitana ny mpampiankin-teny sy ny zavatra.',
    'syntax.prep.description':
      "Manondro fifandraisana ny zavatra ny mpampiankin-teny amin'ny teny hafa, matetika no mampiseho aiza na rehefa.",
    'syntax.ptcl.description':
      "Mpikambana iray avy amin'ny vondrona samihafa ny asa teny izay tsy isan 'akory va ny fototeny kilasy fianarana teny fitsipi-pitenenana",
    'syntax.rel.description': 'Sombiny izay mampiditra ny havany fepetra.',
    'syntax.s.description':
      "Ny ampahany amin'ny fepetra izay ny sisa amin'ny fepetra mahatonga ny filazana momba ny.",
    'syntax.v.description':
      'Hoy ny zavatra momba ny foto-kevitra ny fepetra ary mety maneho hetsika, zava-nitranga, na ny toe-javatra.',
    'syntax.vc.description':
      "Mampifandray ny foto-kevitra ny fepetra ho an'ny foto-kevitra famenon'i.",
    'general.close': 'Close',
    'general.action': 'Action',
    'general.select': 'Select ...',
    'general.back': 'miverina',
    'revisionHistory.noData': "Tsy misy fanitsiana tantara ho amin'ity andininy ity.",
    'revisionHistory.translation': 'Translation',
    'revisionHistory.lastChanged': 'Last Hohavaozina',
    'sourceText.view': 'Click to view lahatsoratra loharano',
    'sourceText.viewStructure': 'Click to view rafitra lahatsoratra loharano',
    'error.projectIdIsRequired': 'Tetikasa ID dia ilaina.',
    'error.searchTermIsRequired': 'Search teny tsy hanaisotra.',
    'error.bookIdIsRequired': 'Boky ID dia takiana mba hitahiry boky anarany.',
    'error.bookNameCannotBeEmpty': 'Anarana Book tsy afaka ny ho foana.',
    'error.signInIsRequiredToAccessProject':
      'Tsy maintsy ho nanao sonia ao ny miditra ny tetikasa.',
    error: 'Error',
    'error.doNotHavePermissionAccessProject':
      'Tsy manana fahazoan-dalana hiditra ity tetikasa ity.',
    'error.unauthorized': 'Tsy nahazoan-dalana fidirana na ny tsy manan-kery fidirana famantarana.',
    'error.tokenExpired': 'Ny famantarana efa lany daty. Azafady re-fidirana.',
    'error.invalidApiUrl': 'URL tsy mety API.',
    'error.badRequestInvalidJson': 'Fangatahana tsy mety. Tsy mety JSON.',
    'error.windowIsNotDefined': 'Window tsy voafaritra.',
    'error.unexpectedError':
      'Tsy nampoizina no nitranga fahadisoana. Azafady manakatona varavarankely izany ka andramo indray.',
    'error.invalidDataType': 'Karazana tahirin-kevitra tsy mety.',
    'export.data': 'Export Data',
    more: 'Bebe kokoa',
    'export.to.rtf': "Export amin'ny tahirinao RTF",
    'export.to.usfm': 'Export ho USFM',
    'export.titlePreparingData': 'Izahay dia manomana ny Baiboly fandikan-teny tahirin-kevitra.',
    'export.doNotClose':
      'Ity dingana ity dia mety haka ny fotoana kelikely, masìna ianao, aza akaiky varavarankely izany dia ho nampahafantatra rehefa efa ho télécharger.',
    'export.desc':
      "Azonao atao ny fanondranana sy maka ny fandikan-teny angona amin'ny fipihana bokotra etsy ambany.",
    'export.titleOfReady2Download': 'Ny fandikan-teny Baiboly tahirin-kevitra dia vonona.',
    'export.textOfReady2Download':
      'Tsindrio ny bokotra eto ambany ity mba Download télécharger ny Baiboly fandikan-teny.',
    'export.statistics': 'Statistics',
    'export.book': 'Book anarana',
    'export.translatedVerses': 'nandika andininy',
    'export.exportedVerses': 'aondrana andininy',
    'export.howDoUWant2Export': 'Ahoana no tianao ny fanondranana angona?',
    'export.whichBooks': 'Iza boky (s) no tianao fanondranana?',
    'export.byBooks': 'By boky',
    'export.byChapters': 'By toko',
    'export.byVerses': 'By andininy',
    'export.booksInOt': 'Old Testament',
    'export.booksInNt': 'New Testament',
    'export.booksInBible': 'Baiboly iray manontolo',
    'export.booksInNone': 'tsy misy',
    'export.selectRangeOfChapters': 'Range ny toko',
    'export.selectRangeOfVerses': 'Andininy isan-karazany',
    'export.selectChapter': 'Select a toko',
    'export.exportTypeIsRequired':
      'Ianao tsy mbola voafantina momba ny fomba no tianao fanondranana antontan-kevitra?',
    'export.haveNotYetSelectedBooks': 'Ianao tsy mbola voafantina boky rehetra.',
    'error.selectABook': 'Tsy maintsy mifidy ny boky.',
    'error.invalidBookId': 'Boky tsy mety ID.',
    'error.selectAChapter': 'Tsy maintsy mifidy ny toko.',
    'error.invalidChapterRange': 'Tsy maintsy mifidy ny toko manan-kery isan-karazany.',
    'error.invalidVerseRange': 'Tsy maintsy mifidy ny andininy manan-kery isan-karazany.',
    'error.invalidExportType': 'Tsy mety fanondranana karazana.',
    startover: 'hanomboka ny',
    download: 'Download',
    'download.all': 'Download ny raki-daza',
    'contact.header': 'Mifandraisa aminay',
    'contact.returnAddress': "Miverina amin'ny Address",
    'contact.returnAddressPlaceholder': 'Aiza no mahatratra anao?',
    'contact.subject': 'Subject',
    'contact.subjectPlaceholder': "Inona no tokony hanampy amin'ny?",
    'contact.message': 'Message',
    'contact.messagePlaceholder': 'Ahoana no ahafahantsika manampy?',
    'contact.sendMessage': 'Send Message',
    'contact.success': 'Hafatra nalefa soa aman-tsara.',
    'contact.failure':
      'Nisy olana nandefa ity hafatra ity. Mba andefaso olona help@globalbibleinitiative.org.',
    selectInterlinear: "Tsindrio ny hanova isan'andalana lahatsoratra",
    'warning.screenTooSmall':
      'Masìna ianao, mifamadika ho ambony kokoa vahaolana efijery (ny sakany kely indrindra 1024px) na azafady hanitatra ny varavarankely navigateur.',
    'label.dictionary': 'fizahan-teny Search',
    'dict.search.placeholder': 'Ampidiro ny teny (s)',
    'tooltip.manuscript': 'Sora-tanana',
    'tooltip.strongsx': 'Ny Strong Number',
    'tooltip.translation': 'Translation',
    'tooltip.english': 'anglisy',
    'title.howToSearch': 'Ahoana ny fampiasana fizahan-teny fikarohana',
    'desc.howToSearch':
      "Ampidiro ny teny (s) Avy amin'ny teny grika na hebreo, ny teny (s) amin'ny fandikan-teny, na Strong Number (s). Na tsindrio eo amin'ny teny grika na hebreo eo amin'ny tontonana ny \"Ny fandikan-teny\" na fomba fijery Fanitsiana safidy ho an'ny fikarohana.",
    'dict.noDataToDisplay': 'Tsy misy tahirin-kevitra izay mifanaraka ny fikarohana mason-tsivana.',
    'title.searchTermTooMany': 'Efa niditra teny fanalahidy be loatra',
    'desc.searchTermTooMany': "Afaka mitady hatramin'ny 8 isaky ny teny fanalahidy.",
    'title.searchDictError': 'Tsy nampoizina no nitranga fahadisoana.',
    'desc.searchDictError':
      'Azafady andramo indray taty aoriana, na fifandraisana mpanjifa fanohanana',
    'alignment.saveAndEditAlignment': 'Save sy Alahatra an New Text',
    'alignment.reviewAlignment': 'Review Text Fanitsiana',
    'dict.searchLemma': 'Tsindrio ny teny tany am-boalohany karohy',
    'dict.searchTranslation': 'Tsindrio ny fikarohana fandikan-teny',
    'dict.searchStrongsX': 'Tsindrio ny hitady ny isa Strong',
    'dict.showMenu': "Tsindrio haneho tohiny ny Miseho eo amin'ny teny manodidina.",
    'dict.hideMenu': "Tsindrio hanafina tohiny ny Miseho eo amin'ny teny manodidina.",
  },
  'pt-br': {
    'languages.en': 'Inglês',
    'languages.am': 'amárico',
    'languages.bn': 'Bengali Idioma',
    'languages.ceb': 'Cebuano Língua',
    'languages.de': 'Língua alemã',
    'languages.es-419': 'Língua espanhola',
    'languages.fr': 'Lingua francesa',
    'languages.gu': 'Gujarati',
    'languages.hi': 'hindi',
    'languages.hr': 'croata Idioma',
    'languages.hu': 'Língua húngara',
    'languages.id': 'Língua Indonésia',
    'languages.km': 'Khmer',
    'languages.kn': 'Kannada',
    'languages.ko': 'Língua coreana',
    'languages.lo': 'Idioma do Laos',
    'languages.ml': 'malayalam',
    'languages.mr': 'marata',
    'languages.ms': 'Malaysian',
    'languages.my': 'birmanês Idioma',
    'languages.ne': 'Nepali Língua',
    'languages.plt': 'Malagasy Idioma',
    'languages.pt-br': 'Português (Brasil) Idioma',
    'languages.ru': 'Língua russa',
    'languages.sw': 'swahili',
    'languages.ta': 'Tamil Idioma',
    'languages.th': 'língua tailandesa',
    'languages.tl': 'Tagalog',
    'languages.te': 'Telegu Idioma',
    'languages.vi': 'Idioma vietnamita',
    'languages.zh-hans': 'Idioma Chinês (simplificado)',
    'languages.zh-hant': 'Idioma Chinês (Tradicional)',
    'header.home': 'Casa',
    'header.help': 'Socorro',
    'header.project': 'Projeto',
    'header.contact': 'Contato',
    'help.contactSupport': 'Envie um email para help@youtranslate.bible para obter assistência.',
    'home.ytbProvides': 'YouTranslate.Bible fornece livremente:',
    'home.easyToUseTool': 'Uma ferramenta de tradução online fácil de usar',
    'home.originalTextHelp': 'Ajuda a compreender o texto da Bíblia em suas línguas originais',
    'home.referencesAndResources': 'Materiais de referência e de treinamento',
    'home.computerAssistance':
      'State-of-the-art tecnologia de computador para auxiliar os tradutores',
    'profile.userProfile': 'Perfil de usuário',
    'profile.language': 'Língua',
    switchDirection: 'Direção do texto interruptor',
    'profile.update': 'Atualizar',
    'profile.languageWarning':
      'Traduz Google foi usado para traduzir a interface do usuário em diferentes do Inglês temporariamente idiomas. YTB ​​não tem controle sobre a precisão ou confiabilidade da tradução. Qualquer pessoa contando com os rótulos traduzidos e explicações obtidos a partir Google Translate fá-lo por sua conta e risco. Se alguém estiver interessado em ser voluntário para fornecer tradução mais precisa e confiável da interface do usuário em outros idiomas além do Inglês, por favor clique na opção Fale para enviar um e-mail para GBI.',
    'usernav.profile': 'Perfil',
    'usernav.logout': 'Sair',
    'usernav.logout.modalTitle': 'Sair de YouTranslate.Bible',
    'usernav.logout.modalText':
      'Tem certeza que deseja sair? Todo o trabalho não salvo será perdido.',
    cancel: 'Cancelar',
    delete: 'Excluir',
    create: 'Crio',
    update: 'Atualizar',
    edit: 'Editar',
    save: 'Salve ',
    add: 'Adicionar',
    translate: 'Traduzir',
    'more.options': 'Mais opções',
    'project.create.title': 'Bíblia Projecto de Tradução',
    'project.create.list': 'lista de projetos',
    'project.list.deleteProject': 'Excluir projeto?',
    'project.list.areYouSure': 'Tem certeza de que deseja excluir este projeto?',
    'project.list.workWillBeDeleted': 'O projeto e qualquer trabalho associado será excluído.',
    'project.list.completion': 'conclusão:',
    'project.list.createdDate': 'Data de criação:',
    'project.list.beginningDate': 'Data de início:',
    'project.list.targetCompletionDate': 'Data de Conclusão do Objetivo:',
    'project.list.description': 'Descrição:',
    'project.edit': 'Editar Projeto',
    'project.list.translateBible': 'traduzir Bíblia',
    'project.list.empty': 'Sua lista projecto de tradução está vazio.',
    'project.list.myProjects': 'Minha Bíblia Projectos de Tradução',
    'project.list.newProject': 'Novo projeto',
    'project.create.createNew': 'Criar um novo projeto',
    'project.namePlaceholder': 'Digite o nome do projeto',
    'project.descriptionPlaceholder': 'Digite descrição do projeto',
    'project.name': 'Nome do Projeto',
    'project.beginningDate': 'Data de início',
    'project.targetCompletionDate': 'Data de Conclusão do Objetivo',
    'project.description': 'Descrição',
    'project.isRTL': 'A tradução é da direita para a esquerda',
    'project.manage-users': 'Gerenciar Tradutores',
    'project.new-user': 'New tradutor',
    'project.add-user': 'add tradutor',
    'project.users': 'tradutores do projeto',
    'project.read-only': 'Somente leitura',
    'project.read&write': 'Ler escrever',
    'project.owner': 'Proprietário',
    'project.translator': 'Tradutor',
    'project.message.no-such-user': 'não existe tal Tradutor',
    'project.message.user-added': 'Tradutor adicionado com sucesso',
    'project.message.user-deleted': 'Tradutor removido.',
    'project.message.only-owner-can-manage-users':
      'proprietário do projeto só pode gerenciar usuários',
    'project.shared': 'projeto colaborativo',
    'general.delete-user': 'tradutor de exclusão',
    'general.edit-user': 'Editar usuário',
    'general.name': 'Nome',
    'general.email': 'O email',
    'general.permissions': 'permissões',
    'general.role': 'Função',
    signin: 'assinar em',
    'signin.emailPlaceholder': 'Endereço de e-mail',
    'signin.passwordPlaceholder': 'Senha',
    'signin.forgotPassword': 'Esqueceu sua senha?',
    'signin.facebook': 'Faça login no Facebook',
    'signin.google': 'Faça login no Google',
    'signin.dontHaveAccount': 'Não tem uma conta?',
    'signin.createOne': 'Crie um',
    'signup.join': 'Junte YouTranslate.Bible',
    'signup.createAccountPrompt': 'Criar a sua conta gratuita para começar.',
    'signup.createAccount': 'Criar Conta',
    'signup.emailPlaceholder': 'Endereço de e-mail',
    'signup.passwordPlaceholder': 'Senha',
    'passwordReset.title': 'Redefinição de senha',
    'passwordReset.prompt': 'Solicitar um e-mail com instruções para redefinir sua senha.',
    'passwordReset.emailPlaceholder': 'Endereço de e-mail',
    'passwordReset.requestButtonLabel': 'Request Email',
    'passwordReset.success': 'E-mail foi enviado.',
    'passwordReset.error': 'Ocorreu um erro.',
    'translation.project': 'PROJETO:',
    'translation.backToProjectList': 'Voltar à lista de projeto',
    'translation.book': 'Livro:',
    'translation.chapter': 'Capítulo:',
    'translation.prompt':
      'Tem certeza de que deseja sair desse capítulo? Todo o trabalho não salvo será perdido.',
    'translation.prev': 'prev',
    'translation.next': 'Próximo',
    'translation.previousChapter': 'capítulo anterior',
    'translation.nextChapter': 'Próximo Capítulo',
    'navigator.book': 'Livro:',
    'navigator.chapter': 'Capítulo:',
    'navigator.oldTestament': 'Antigo Testamento',
    'navigator.newTestament': 'Novo Testamento',
    'navigator.selectBook': 'Escolha um livro',
    'navigator.search': 'Procurar',
    'navigator.changeBook': 'Alterar livro',
    'navigator.changeChapter': 'mudança capítulo',
    Genesis: 'Gênese',
    Exodus: 'Êxodo',
    Leviticus: 'Levítico',
    Numbers: 'Números',
    Deuteronomy: 'Deuteronômio',
    Joshua: 'Joshua',
    Judges: 'Juízes',
    Ruth: 'Ruth',
    '1 Samuel': '1 Samuel',
    '2 Samuel': '2 Samuel',
    '1 Kings': '1 Reis',
    '2 Kings': '2 Reis',
    '1 Chronicles': '1 Crônicas',
    '2 Chronicles': '2 Crônicas',
    Ezra: 'Ezra',
    Nehemiah: 'Neemias',
    Esther: 'Esther',
    Job: 'Trabalho',
    Psalms: 'Salmos',
    Proverbs: 'Provérbios',
    Ecclesiastes: 'eclesiastes',
    'Song of Songs': 'Cântico dos Cânticos',
    Isaiah: 'Isaías',
    Jeremiah: 'Jeremiah',
    Lamentations: 'Lamentações',
    Ezekiel: 'Ezequiel',
    Daniel: 'Daniel',
    Hosea: 'Oséias',
    Joel: 'Joel',
    Amos: 'Amos',
    Obadiah: 'Obadias',
    Jonah: 'Jonah',
    Micah: 'Miquéias',
    Nahum: 'Nahum',
    Habakkuk: 'Habacuque',
    Zephaniah: 'Sofonias',
    Haggai: 'Ageu',
    Zechariah: 'Zacarias',
    Malachi: 'Malaquias',
    Matthew: 'Mateus',
    Mark: 'Marca',
    Luke: 'Lucas',
    John: 'John',
    Acts: 'Atos',
    Romans: 'Romans',
    '1 Corinthians': '1 Coríntios',
    '2 Corinthians': '2 Coríntios',
    Galatians: 'Gálatas',
    Ephesians: 'Efésios',
    Philippians: 'Filipenses',
    Colossians: 'Colossenses',
    '1 Thessalonians': '1 Tessalonicenses',
    '2 Thessalonians': '2 Tessalonicenses',
    '1 Timothy': '1 Timothy',
    '2 Timothy': '2 Timothy',
    Titus: 'Titus',
    Philemon: 'Philemon',
    Hebrews: 'Hebreus',
    James: 'James',
    '1 Peter': '1 Pedro',
    '2 Peter': '2 Pedro',
    '1 John': '1 John',
    '2 John': '2 John',
    '3 John': '3 John',
    Jude: 'Jude',
    Revelation: 'Revelação',
    'resources.prompt':
      'Adicionar recursos bíblicos para esclarecimentos adicionais sobre a passagem que você está traduzindo.',
    'resources.add': 'Adicionar um recurso bíblico',
    Manuscript: 'Manuscrito',
    Morphology: 'Morfologia',
    Lexicon: 'Léxico',
    'Syntactic Tree': 'Árvore sintática',
    'Go to project list': 'Ir para a lista de projetos',
    'Project not found': 'Projeto não encontrado ou permissões insuficientes.',
    'error.referenceDoesNotExistForResource':
      'Esta tradução da Bíblia não contém conteúdo para a passagem selecionada.',
    'references.addAnother': 'Adicionar outra tradução',
    'references.referencePrompt':
      'Adicionar outras traduções da Bíblia para esclarecimentos adicionais.',
    'references.add': 'Adicionar uma tradução da Bíblia',
    'references.licensing': 'Informações sobre licenciamento',
    'references.licensingEmpty': 'Sem informações de licenciamento foi encontrado para este item.',
    'translation.complete': 'completa tradução',
    Alignment: 'Alinhamento',
    Share: 'Compartilhar',
    'Your Translation': 'seu Tradução',
    'Enter your translation': 'Digite sua tradução',
    OK: 'Está bem',
    'error.verseTranslationEmpty': 'Você não pode deixar o conteúdo de tradução esvaziar.',
    'translation.hide.completed': 'Esconder concluída',
    alignment: 'Alinhamento',
    'alignment.verify': 'Verifique e feche.',
    'alignment.close': 'Fechar sem verificar',
    'alignment.backToEditor': 'Voltar para o Editor',
    'alignment.error':
      'Algo deu errado. Se as persistir problemas, entre em contato com a equipe YouTranslate.Bible.',
    'alignment.add': 'Adicionar link',
    'alignment.delete': 'Remover link',
    'alignment.clear': 'Seleção clara',
    'alignment.reverse': 'Exibição inversa',
    'alignment.removeOrClear': 'Remover o link selecionado ou seleção claro.',
    'alignment.addOrRemove': 'Criar uma nova ligação ou excluir uma anterior.',
    'alignment.selectAndAdd': 'Selecione segmentos correspondente ao link.',
    'error.mustBeSignedToMakeChanges': 'Você deve fazer o login para mudanças fazer.',
    'error.mustBeSignedToTranslateBible': 'Você deve fazer o login para traduzir a Bíblia.',
    checking: 'checagem',
    'verseEditor.unexpectedError': 'Ocorreu um erro inesperado.',
    verses: 'por Verso',
    words: 'por Palavra',
    'leftPanel.name': 'Texto original da Bíblia',
    'rightPanel.name': 'Traduções da Bíblia',
    'bottomPanel.name': 'Recursos para a Bíblia',
    revisionHistory: 'Histórico de Revisão',
    'syntax.adv': 'Modificador adverbial',
    'syntax.adjp': 'Frase adjetiva',
    'syntax.advp': 'Frase advérbio',
    'syntax.cl': 'Cláusula',
    'syntax.conj': 'Conjunção',
    'syntax.cjp': 'Conjunção',
    'syntax.det': 'determinador',
    'syntax.ijp': 'interjeição',
    'syntax.nump': 'Frase numérica',
    'syntax.np': 'substantivo Frase',
    'syntax.o': 'Objeto direto',
    'syntax.o2': 'segundo Objetivo',
    'syntax.oc': 'Complemento objeto',
    'syntax.om': 'objeto marcador',
    'syntax.io': 'Objeto indireto',
    'syntax.p': 'Não-Verbal predicado',
    'syntax.pp': 'Locução prepositiva',
    'syntax.prep': 'Preposição',
    'syntax.ptcl': 'Partícula',
    'syntax.rel': 'Particle relativa',
    'syntax.s': 'Sujeito',
    'syntax.v': 'Verbo',
    'syntax.vc': 'Verbo de ligação',
    'syntax.adjp.description': 'Frase que modifica um substantivo ou pronome',
    'syntax.adv.description':
      'Modifica o verbo, tipicamente também a definição de forma, tempo, local, a frequência, o grau, etc. ...',
    'syntax.advp.description':
      'Frase modificar o verbo, tipicamente também a definição de forma, tempo, local, a frequência, o grau, etc.',
    'syntax.cl.description': 'Um grupo de palavras que faz uma afirmação sobre o assunto.',
    'syntax.conj.description': 'Conecta as palavras, frases e cláusulas.',
    'syntax.cjp.description': 'Conecta as palavras, frases e cláusulas.',
    'syntax.det.description': 'Especifica, identifica, ou quantifica um sintagma nominal.',
    'syntax.ijp.description': 'Exclamação ou expressão repentina de emoção.',
    'syntax.nump.description': 'Frase que descreve quantidade numérica',
    'syntax.np.description':
      'Uma frase que tem um substantivo como sua cabeça ou que funciona como um substantivo.',
    'syntax.o.description':
      'Aquilo que é diretamente afetado, causado, ou produzidos pela ação de um verbo.',
    'syntax.o2.description':
      'Aquilo que é diretamente afetado, causado, ou produzidos pela ação de um verbo.',
    'syntax.oc.description': 'Faz uma afirmação sobre o objeto direto.',
    'syntax.om.description': 'Partícula que introduz um objeto direto.',
    'syntax.io.description':
      'Objeto que é indiretamente afetada por uma ação, muitas vezes o destinatário da ação de um verbo.',
    'syntax.p.description':
      'Identifica ou descreve o assunto, muitas vezes (mas nem sempre) ocorre junto com um verbo de ligação.',
    'syntax.pp.description': 'Modificando frase consistindo de uma preposição e seu objeto.',
    'syntax.prep.description':
      'Indica relação do objecto da preposição com outras palavras, frequentemente indicando onde ou quando.',
    'syntax.ptcl.description':
      'Um membro do grupo diverso de palavras de função que não pertencem a nenhuma das classes de palavras gramaticais flexionadas',
    'syntax.rel.description': 'Partícula que introduz uma cláusula relativa.',
    'syntax.s.description': 'A parte da cláusula que o resto da cláusula faz uma afirmação sobre.',
    'syntax.v.description':
      'Afirma algo sobre o sujeito da oração e podem expressar ação, evento ou condição.',
    'syntax.vc.description': 'Liga o objecto de uma cláusula de um complemento assunto.',
    'general.close': 'Perto',
    'general.action': 'Açao',
    'general.select': 'Escolha ...',
    'general.back': 'Costas',
    'revisionHistory.noData': 'Não há histórico de revisões para este verso.',
    'revisionHistory.translation': 'Tradução',
    'revisionHistory.lastChanged': 'alterado pela última vez',
    'sourceText.view': 'Clique para ver texto de origem',
    'sourceText.viewStructure': 'Clique para ver a estrutura de texto de origem',
    'error.projectIdIsRequired': 'ID do projeto é necessário.',
    'error.searchTermIsRequired': 'termo de pesquisa não pode esvaziar.',
    'error.bookIdIsRequired': 'Book ID é necessário para armazenar o nome do livro.',
    'error.bookNameCannotBeEmpty': 'Book nome não pode estar vazio.',
    'error.signInIsRequiredToAccessProject': 'Você deve fazer o login para acessar seu projeto.',
    error: 'Erro',
    'error.doNotHavePermissionAccessProject': 'Você não tem permissão para acessar este projecto.',
    'error.unauthorized': 'acesso não autorizado ou acesso inválido token.',
    'error.tokenExpired': 'O token expirou. Por favor, re-login.',
    'error.invalidApiUrl': 'url API inválido.',
    'error.badRequestInvalidJson': 'Pedido ruim. Inválido JSON.',
    'error.windowIsNotDefined': 'Janela não está definido.',
    'error.unexpectedError':
      'Ocorreu um erro inesperado. Por favor, feche esta janela e tente novamente.',
    'error.invalidDataType': 'tipo de dados inválido.',
    'export.data': 'Exportar dados',
    more: 'Mais',
    'export.to.rtf': 'Exportar para RTF',
    'export.to.usfm': 'Exportar para USFM',
    'export.titlePreparingData': 'Estamos preparando os seus dados de tradução da Bíblia.',
    'export.doNotClose':
      'Este processo pode demorar um pouco, por favor, não feche esta janela e você será notificado quando estiver pronto para download.',
    'export.desc':
      'Você pode exportar e baixar os seus dados de tradução, clicando nos botões abaixo.',
    'export.titleOfReady2Download': 'Seus dados tradução da Bíblia está pronto.',
    'export.textOfReady2Download':
      'Por favor, clique no botão de download abaixo para baixar a sua tradução da Bíblia.',
    'export.statistics': 'Estatisticas',
    'export.book': 'nome do livro',
    'export.translatedVerses': 'versos traduzidos',
    'export.exportedVerses': 'versos exportados',
    'export.howDoUWant2Export': 'Como você deseja exportar os dados?',
    'export.whichBooks': 'Que livro (s) que você deseja exportar?',
    'export.byBooks': 'por livros',
    'export.byChapters': 'por capítulos',
    'export.byVerses': 'por versos',
    'export.booksInOt': 'Antigo Testamento',
    'export.booksInNt': 'Novo Testamento',
    'export.booksInBible': 'Bíblia inteira',
    'export.booksInNone': 'Nenhum',
    'export.selectRangeOfChapters': 'Escala dos capítulos',
    'export.selectRangeOfVerses': 'Faixa de versos',
    'export.selectChapter': 'Seleccione um capítulo',
    'export.exportTypeIsRequired': 'Você ainda não selecionou sobre como você quer exportar dados?',
    'export.haveNotYetSelectedBooks': 'Você ainda não selecionou nenhum livro.',
    'error.selectABook': 'Você deve selecionar um livro.',
    'error.invalidBookId': 'ID livro inválido.',
    'error.selectAChapter': 'Você deve selecionar um capítulo.',
    'error.invalidChapterRange': 'Você deve selecionar um intervalo capítulo válido.',
    'error.invalidVerseRange': 'Você deve selecionar um intervalo verso válido.',
    'error.invalidExportType': 'Inválido tipo de exportação.',
    startover: 'Recomeçar',
    download: 'Baixar',
    'download.all': 'Baixar todos os arquivos',
    'contact.header': 'Contate-Nos',
    'contact.returnAddress': 'Endereço de devolução',
    'contact.returnAddressPlaceholder': 'Onde podemos chegar até você?',
    'contact.subject': 'Sujeito',
    'contact.subjectPlaceholder': 'Com o que você precisa de ajuda?',
    'contact.message': 'mensagem',
    'contact.messagePlaceholder': 'Como podemos ajudar?',
    'contact.sendMessage': 'Enviar mensagem',
    'contact.success': 'Mensagem foi enviada com sucesso.',
    'contact.failure':
      'Houve um problema ao enviar esta mensagem. Entre em contato com help@globalbibleinitiative.org.',
    selectInterlinear: 'Clique para alterar o texto interlinear',
    'warning.screenTooSmall':
      'Por favor mudar para uma resolução de tela superior (mínimo 1024px de largura) ou por favor alargar a janela do navegador.',
    'label.dictionary': 'concordância Pesquisa',
    'dict.search.placeholder': 'Digite palavra (s)',
    'tooltip.manuscript': 'Manuscrito',
    'tooltip.strongsx': 'Número de Strong',
    'tooltip.translation': 'Tradução',
    'tooltip.english': 'Inglês',
    'title.howToSearch': 'Como usar pesquisa de concordância',
    'desc.howToSearch':
      'Digite palavra (s) do (s) o grego ou hebraico, a palavra de sua tradução, ou Número Strong (s). Ou clique nas palavras gregas ou hebraicas no painel de "Sua tradução" ou vistas de alinhamento para opções de pesquisa.',
    'dict.noDataToDisplay': 'Não existem dados que correspondem aos seus critérios de pesquisa.',
    'title.searchTermTooMany': 'Você inseriu muitas palavras-chave',
    'desc.searchTermTooMany': 'Você pode pesquisar até 8 palavras-chave de cada vez.',
    'title.searchDictError': 'Ocorreu um erro inesperado.',
    'desc.searchDictError': 'Por favor, tente novamente mais tarde ou suporte ao cliente contato',
    'alignment.saveAndEditAlignment': 'Salvar e Align Novo texto',
    'alignment.reviewAlignment': 'Revisão Alinhamento de texto',
    'dict.searchLemma': 'Clique para procurar palavra original',
    'dict.searchTranslation': 'Clique para procurar palavra tradução',
    'dict.searchStrongsX': 'Clique para pesquisar o número do Forte',
    'dict.showMenu': 'Clique para mostrar texto completo das ocorrências em contexto.',
    'dict.hideMenu': 'Clique para ocultar texto completo das ocorrências em contexto.',
  },
  ru: {
    'languages.en': 'английский',
    'languages.am': 'амхарский',
    'languages.bn': 'Бенгальский язык',
    'languages.ceb': 'Cebuano Язык',
    'languages.de': 'Немецкий язык',
    'languages.es-419': 'Испанский язык',
    'languages.fr': 'Французский язык',
    'languages.gu': 'гуджарати',
    'languages.hi': 'хинди',
    'languages.hr': 'Хорватский язык',
    'languages.hu': 'Венгерский язык',
    'languages.id': 'Индонезийский язык',
    'languages.km': 'кхмерский',
    'languages.kn': 'каннада',
    'languages.ko': 'Корейский язык',
    'languages.lo': 'лаосской Язык',
    'languages.ml': 'Malayalam',
    'languages.mr': 'маратхи',
    'languages.ms': 'малазийский',
    'languages.my': 'Бирманский язык',
    'languages.ne': 'Непальский Язык',
    'languages.plt': 'Малагасийский язык',
    'languages.pt-br': 'Португальский (Бразилия) Язык',
    'languages.ru': 'Русский язык',
    'languages.sw': 'суахили',
    'languages.ta': 'тамильский язык',
    'languages.th': 'Тайский язык',
    'languages.tl': 'тагальского',
    'languages.te': 'телугу Язык',
    'languages.vi': 'Вьетнамский язык',
    'languages.zh-hans': 'Китайский язык (упрощенный)',
    'languages.zh-hant': 'Китайский язык (традиционный)',
    'header.home': 'Домой',
    'header.help': 'Помогите',
    'header.project': 'проект',
    'header.contact': 'контакт',
    'help.contactSupport': 'Пожалуйста, напишите нам по адресу help@youtranslate.bible для помощи.',
    'home.ytbProvides': 'YouTranslate.Bible свободно обеспечивает:',
    'home.easyToUseTool': 'Простой в использовании интерактивный инструмент для перевода',
    'home.originalTextHelp': 'Помогите понять текст Библии на языке оригинала',
    'home.referencesAndResources': 'Справочные и учебные ресурсы',
    'home.computerAssistance':
      'Состояние дел современных компьютерных технологий для оказания помощи переводчиков',
    'profile.userProfile': 'Профиль пользователя',
    'profile.language': 'язык',
    switchDirection: 'Переключить Направление текста',
    'profile.update': 'Обновить',
    'profile.languageWarning':
      'Google Переводчик был использован для перевода пользовательского интерфейса с английского на другие языки временно. YTB ​​не имеет никакого контроля над точностью и достоверностью перевода. Любой полагаться на переводных этикетки и объяснения, полученные от Google Translate делает это на его собственный страх и риск. Если кто-то заинтересован в добровольчество, чтобы обеспечить более точный и надежный перевод пользовательского интерфейса с английского на другие языки, пожалуйста, выберите опцию Контакт, чтобы отправить по электронной почте GBI.',
    'usernav.profile': 'Профиль',
    'usernav.logout': 'Выйти',
    'usernav.logout.modalTitle': 'Выход из YouTranslate.Bible',
    'usernav.logout.modalText':
      'Вы действительно хотите выйти? Все несохраненные данные будут потеряны.',
    cancel: 'Отмена',
    delete: 'Удалить',
    create: 'Создайте',
    update: 'Обновить',
    edit: 'редактировать',
    save: 'Сохранить',
    add: 'Добавить',
    translate: 'Перевести',
    'more.options': 'Больше вариантов',
    'project.create.title': 'Проект перевода Библии',
    'project.create.list': 'список проектов',
    'project.list.deleteProject': 'Удалить проект?',
    'project.list.areYouSure': 'Вы уверены, что хотите удалить этот проект?',
    'project.list.workWillBeDeleted': 'Проект и любая работа, связанная будут удалены.',
    'project.list.completion': 'Завершение:',
    'project.list.createdDate': 'Дата создания:',
    'project.list.beginningDate': 'Начиная с даты:',
    'project.list.targetCompletionDate': 'Дата завершения выполнения задания:',
    'project.list.description': 'Описание:',
    'project.edit': 'Редактирование проекта',
    'project.list.translateBible': 'Перевести Библию',
    'project.list.empty': 'Ваш список перевода проекта пуст.',
    'project.list.myProjects': 'Мои Библии Проекты перевода',
    'project.list.newProject': 'Новый проект',
    'project.create.createNew': 'Создание нового проекта',
    'project.namePlaceholder': 'Введите имя проекта',
    'project.descriptionPlaceholder': 'Введите описание проекта',
    'project.name': 'Название проекта',
    'project.beginningDate': 'Начиная с даты',
    'project.targetCompletionDate': 'Дата завершения выполнения задания',
    'project.description': 'Описание',
    'project.isRTL': 'Перевод справа налево',
    'project.manage-users': 'Управление переводчиков',
    'project.new-user': 'Новый переводчик',
    'project.add-user': 'Добавить переводчик',
    'project.users': 'переводчики проекта',
    'project.read-only': 'только для чтения',
    'project.read&write': 'Читай пиши',
    'project.owner': 'владелец',
    'project.translator': 'Переводчик',
    'project.message.no-such-user': 'Нет такой переводчик не существует',
    'project.message.user-added': 'Переводчик успешно добавлен',
    'project.message.user-deleted': 'Переводчик удален.',
    'project.message.only-owner-can-manage-users':
      'Только владелец проекта может управлять пользователями',
    'project.shared': 'Совместный проект',
    'general.delete-user': 'Удалить переводчик',
    'general.edit-user': 'Изменить пользователя',
    'general.name': 'название',
    'general.email': 'Эл. почта',
    'general.permissions': 'права доступа',
    'general.role': 'Роль',
    signin: 'войти в систему',
    'signin.emailPlaceholder': 'Адрес электронной почты',
    'signin.passwordPlaceholder': 'пароль',
    'signin.forgotPassword': 'Забыли Ваш пароль?',
    'signin.facebook': 'Авторизоваться с помощью Фэйсбука',
    'signin.google': 'Войти с помощью Google',
    'signin.dontHaveAccount': 'Не зарегистрированы?',
    'signin.createOne': 'Создай',
    'signup.join': 'Регистрация YouTranslate.Bible',
    'signup.createAccountPrompt': 'Создайте свой бесплатный аккаунт, чтобы начать.',
    'signup.createAccount': 'Регистрация',
    'signup.emailPlaceholder': 'Адрес электронной почты',
    'signup.passwordPlaceholder': 'пароль',
    'passwordReset.title': 'Восстановление пароля',
    'passwordReset.prompt': 'Запрос по электронной почте с инструкциями по восстановлению пароля.',
    'passwordReset.emailPlaceholder': 'Адрес электронной почты',
    'passwordReset.requestButtonLabel': 'Запрос Email',
    'passwordReset.success': 'Письмо было отправлено.',
    'passwordReset.error': 'Произошла ошибка.',
    'translation.project': 'ПРОЕКТ:',
    'translation.backToProjectList': 'Вернуться к списку проектов',
    'translation.book': 'Книга:',
    'translation.chapter': 'Глава:',
    'translation.prompt':
      'Вы уверены, что хотите покинуть эту главу? Все несохраненные данные будут потеряны.',
    'translation.prev': 'Предыдущая',
    'translation.next': 'следующий',
    'translation.previousChapter': 'Предыдущая глава',
    'translation.nextChapter': 'Следующая глава',
    'navigator.book': 'Книга:',
    'navigator.chapter': 'Глава:',
    'navigator.oldTestament': 'Ветхий Завет',
    'navigator.newTestament': 'Новый Завет',
    'navigator.selectBook': 'Выберите книгу',
    'navigator.search': 'Поиск',
    'navigator.changeBook': 'Изменение книги',
    'navigator.changeChapter': 'Изменить раздел',
    Genesis: 'генезис',
    Exodus: 'исход',
    Leviticus: 'Левит',
    Numbers: 'чисел',
    Deuteronomy: 'Второзаконие',
    Joshua: 'Джошуа',
    Judges: 'Книга судей',
    Ruth: 'Рут',
    '1 Samuel': '1 Samuel',
    '2 Samuel': '2 Samuel',
    '1 Kings': '1 Kings',
    '2 Kings': '2 Kings',
    '1 Chronicles': '1-я Паралипоменон',
    '2 Chronicles': '2-я Паралипоменон',
    Ezra: 'Ezra',
    Nehemiah: 'Неемия',
    Esther: 'Эстер',
    Job: 'работа',
    Psalms: 'Псалмы',
    Proverbs: 'пословицы',
    Ecclesiastes: 'Экклезиаст',
    'Song of Songs': 'Песнь Песней',
    Isaiah: 'Исайя',
    Jeremiah: 'Иеремия',
    Lamentations: 'плач Иеремии',
    Ezekiel: 'Иезекииль',
    Daniel: 'Даниил',
    Hosea: 'Осия',
    Joel: 'Joel',
    Amos: 'амосс',
    Obadiah: 'Овадия',
    Jonah: 'Иона',
    Micah: 'Михей',
    Nahum: 'Наум',
    Habakkuk: 'Аввакум',
    Zephaniah: 'Софония',
    Haggai: 'Аггей',
    Zechariah: 'Захария',
    Malachi: 'Малахия',
    Matthew: 'Мэтью',
    Mark: 'отметка',
    Luke: 'Люк',
    John: 'Джон',
    Acts: 'акты',
    Romans: 'римляне',
    '1 Corinthians': '1-е Коринфянам',
    '2 Corinthians': '2-е Коринфянам',
    Galatians: 'Галатам',
    Ephesians: 'Ефесянам',
    Philippians: 'Филиппийцам',
    Colossians: 'Колоссянам',
    '1 Thessalonians': '1 Фессалоникийцам',
    '2 Thessalonians': '2 Фессалоникийцам',
    '1 Timothy': '1 Timothy',
    '2 Timothy': '2 Timothy',
    Titus: 'Titus',
    Philemon: 'Филимон',
    Hebrews: 'Евреям',
    James: 'Джеймс',
    '1 Peter': '1 Peter',
    '2 Peter': '2 Peter',
    '1 John': '1 John',
    '2 John': '2 John',
    '3 John': '3 John',
    Jude: 'Jude',
    Revelation: 'Открытие',
    'resources.prompt':
      'Добавить Библейские ресурсы для дополнительного проникновения в проход вы переводите.',
    'resources.add': 'Добавить библейский ресурс',
    Manuscript: 'манускрипт',
    Morphology: 'Морфология',
    Lexicon: 'Лексикон',
    'Syntactic Tree': 'Синтаксическое Дерево',
    'Go to project list': 'Перейти к списку проектов',
    'Project not found': 'Проект не найден или недостаточно прав.',
    'error.referenceDoesNotExistForResource':
      'Этот перевод Библии не содержит контент для выбранного отрывка.',
    'references.addAnother': 'Добавить другой перевод',
    'references.referencePrompt': 'Добавить другие переводы Библии для дополнительного понимания.',
    'references.add': 'Добавить перевод Библии',
    'references.licensing': 'информация о лицензировании',
    'references.licensingEmpty':
      'Нет лицензирование информации не была найдена для этого элемента.',
    'translation.complete': 'полный перевод',
    Alignment: 'центровка',
    Share: 'доля',
    'Your Translation': 'Ваш перевод',
    'Enter your translation': 'Введите свой перевод',
    OK: 'хорошо',
    'error.verseTranslationEmpty': 'Вы не можете оставить содержание перевода опорожнить.',
    'translation.hide.completed': 'Скрыть завершения',
    alignment: 'центровка',
    'alignment.verify': 'Проверить и закрыть.',
    'alignment.close': 'Закрыть без проверки',
    'alignment.backToEditor': 'Назад к редактору',
    'alignment.error':
      'Что-то пошло не так. Если проблема не устраняется, обратитесь в YouTranslate.Bible команды.',
    'alignment.add': 'Добавить ссылку',
    'alignment.delete': 'Удалить ссылку',
    'alignment.clear': 'Понятный выбор',
    'alignment.reverse': 'Обратный дисплей',
    'alignment.removeOrClear': 'Удалить выбранную ссылку или четкий выбор.',
    'alignment.addOrRemove': 'Создать новую ссылку или удалить предыдущую.',
    'alignment.selectAndAdd': 'Выберите соответствующие сегменты для связи.',
    'error.mustBeSignedToMakeChanges': 'Вы должны войти в систему, чтобы изменения делают.',
    'error.mustBeSignedToTranslateBible': 'Вы должны войти в систему, чтобы перевести Библию.',
    checking: 'проверка',
    'verseEditor.unexpectedError': 'Произошла непредвиденная ошибка.',
    verses: 'стих',
    words: 'По Слову',
    'leftPanel.name': 'Оригинальный текст Библии',
    'rightPanel.name': 'Переводы Библии',
    'bottomPanel.name': 'Ресурсы для Библии',
    revisionHistory: 'лист регистраций изменений',
    'syntax.adv': 'Обстоятельство',
    'syntax.adjp': 'прилагательный разговорник',
    'syntax.advp': 'Наречие разговорник',
    'syntax.cl': 'пункт',
    'syntax.conj': 'конъюнкция',
    'syntax.cjp': 'конъюнкция',
    'syntax.det': 'детерминанта',
    'syntax.ijp': 'междометие',
    'syntax.nump': 'Численный разговорник',
    'syntax.np': 'Словосочетание',
    'syntax.o': 'Прямой объект',
    'syntax.o2': 'Второй объект',
    'syntax.oc': 'комплемент объекта',
    'syntax.om': 'Объект Маркер',
    'syntax.io': 'Косвенное дополнение',
    'syntax.p': 'Невербальный Predicate',
    'syntax.pp': 'предложной',
    'syntax.prep': 'Предлог',
    'syntax.ptcl': 'частица',
    'syntax.rel': 'Относительная Particle',
    'syntax.s': 'Тема',
    'syntax.v': 'глагол',
    'syntax.vc': 'Связывая глагол',
    'syntax.adjp.description': 'Фраза, которая изменяет существительное или местоимение',
    'syntax.adv.description':
      'Модифицирует глагол, как правило, дальнейшее определение образом, время, место, частота, степень, и т.д. ...',
    'syntax.advp.description':
      'Фраза модификация глагола, как правило, дальнейшее определение образом, время, место, частота, степень и т.д.',
    'syntax.cl.description': 'Группа слов, делает утверждение о предмете.',
    'syntax.conj.description': 'Подключается слова, фразы и предложения.',
    'syntax.cjp.description': 'Подключается слова, фразы и предложения.',
    'syntax.det.description': 'Определяет, определяет или квантифицирует именная.',
    'syntax.ijp.description': 'Восклицательный или внезапное выражение эмоций.',
    'syntax.nump.description': 'Фраза, которая описывает численную величину',
    'syntax.np.description':
      'Фраза, которая имеет существительное в качестве его руководителя или что функции, как существительное.',
    'syntax.o.description':
      'То, что непосредственно влияет, вызванные, или произведены под действием глагола.',
    'syntax.o2.description':
      'То, что непосредственно влияет, вызванные, или произведены под действием глагола.',
    'syntax.oc.description': 'Делает утверждение о прямом объекте.',
    'syntax.om.description': 'Частица, которая вводит прямой объект.',
    'syntax.io.description':
      'Объект, который косвенно влияет на действия, часто получатель действия глагола.',
    'syntax.p.description':
      'Обозначаю или описывает предмет, часто (но не всегда) происходит вместе с связующим глаголом.',
    'syntax.pp.description': 'Модификация фраза, состоящая из предлога и его объекта.',
    'syntax.prep.description':
      'Указывает отношения объекта предлога с другими словами, часто с указанием, где и когда.',
    'syntax.ptcl.description':
      'Один член из разнородной группы функциональных слов, которые не принадлежат ни одному из флективных грамматических классов слов',
    'syntax.rel.description': 'Частица, которая вводит придаточное предложение.',
    'syntax.s.description': 'Часть статьи о том, что остальная часть статьи делает утверждение о.',
    'syntax.v.description':
      'Государства-то о предмете оговорки и может выражать действие, событие или состояние.',
    'syntax.vc.description': 'Ссылки на тему оговорки к теме дополнения.',
    'general.close': 'близко',
    'general.action': 'действие',
    'general.select': 'Выбрать...',
    'general.back': 'назад',
    'revisionHistory.noData': 'Там нет истории изменений для этого стиха.',
    'revisionHistory.translation': 'Перевод',
    'revisionHistory.lastChanged': 'Последний Измененный',
    'sourceText.view': 'Нажмите, чтобы посмотреть исходный текст',
    'sourceText.viewStructure': 'Нажмите для просмотра исходного текста структуры',
    'error.projectIdIsRequired': 'Идентификатор проекта требуется.',
    'error.searchTermIsRequired': 'Критерий поиска не может опустошить.',
    'error.bookIdIsRequired': 'Книга ID требуется для хранения названия книги.',
    'error.bookNameCannotBeEmpty': 'Название книги не может быть пустым.',
    'error.signInIsRequiredToAccessProject':
      'Вы должны войти в систему для доступа к вашему проекту.',
    error: 'ошибка',
    'error.doNotHavePermissionAccessProject': 'У вас нет разрешения на доступ к этому проекту.',
    'error.unauthorized': 'Несанкционированный доступ или недопустимый маркер доступа.',
    'error.tokenExpired': 'Ваш токен истек. Пожалуйста, повторно Войти.',
    'error.invalidApiUrl': 'Invalid API URL.',
    'error.badRequestInvalidJson': 'Неверный запрос. Invalid JSON.',
    'error.windowIsNotDefined': 'Окно не определено.',
    'error.unexpectedError':
      'Произошла непредвиденная ошибка. Пожалуйста, закройте это окно и повторите попытку.',
    'error.invalidDataType': 'Неверный тип данных.',
    'export.data': 'Экспорт данных',
    more: 'Больше',
    'export.to.rtf': 'Экспорт в формате RTF',
    'export.to.usfm': 'Экспорт в USFM',
    'export.titlePreparingData': 'Мы готовим данные по переводу Библии.',
    'export.doNotClose':
      'Этот процесс может занять некоторое время, пожалуйста, не закрывайте это окно, и вы будете уведомлены, когда он будет готов для загрузки.',
    'export.desc': 'Вы можете экспортировать и загрузить данные перевода, нажав кнопку ниже.',
    'export.titleOfReady2Download': 'Ваши данные Библии перевод готов.',
    'export.textOfReady2Download':
      'Пожалуйста, нажмите на кнопку загрузки ниже, чтобы скачать перевод Библии.',
    'export.statistics': 'Статистика',
    'export.book': 'название книги',
    'export.translatedVerses': 'переведенные стихи',
    'export.exportedVerses': 'экспортируемые стихи',
    'export.howDoUWant2Export': 'Как вы хотите экспортировать данные?',
    'export.whichBooks': 'Какая книга (ы) вы хотите экспортировать?',
    'export.byBooks': 'По книгам',
    'export.byChapters': 'По главам',
    'export.byVerses': 'По стихам',
    'export.booksInOt': 'Ветхий Завет',
    'export.booksInNt': 'Новый Завет',
    'export.booksInBible': 'Вся Библия',
    'export.booksInNone': 'Никто',
    'export.selectRangeOfChapters': 'Диапазон глав',
    'export.selectRangeOfVerses': 'Диапазон стихов',
    'export.selectChapter': 'Выберите раздел',
    'export.exportTypeIsRequired': 'Вы еще не выбрали, как вы хотите экспортировать данные?',
    'export.haveNotYetSelectedBooks': 'Вы еще не выбрали ни одной книги.',
    'error.selectABook': 'Вы должны выбрать книгу.',
    'error.invalidBookId': 'Invalid книга ID.',
    'error.selectAChapter': 'Вы должны выбрать главу.',
    'error.invalidChapterRange': 'Вы должны выбрать правильный выбор раздела.',
    'error.invalidVerseRange': 'Вы должны выбрать правильный диапазон стиха.',
    'error.invalidExportType': 'Неверный тип экспорта.',
    startover: 'Начать сначала',
    download: 'Скачать',
    'download.all': 'Скачать все файлы',
    'contact.header': 'Связаться с нами',
    'contact.returnAddress': 'Обратный адрес',
    'contact.returnAddressPlaceholder': 'Где мы можем связаться с вами?',
    'contact.subject': 'Тема',
    'contact.subjectPlaceholder': 'Как я могу вам помочь?',
    'contact.message': 'Сообщение',
    'contact.messagePlaceholder': 'Как мы можем помочь?',
    'contact.sendMessage': 'Отправить сообщение',
    'contact.success': 'Сообщение было успешно отправлено.',
    'contact.failure':
      'Существовал проблема отправки этого сообщения. Пожалуйста, свяжитесь с help@globalbibleinitiative.org.',
    selectInterlinear: 'Нажмите, чтобы изменить подстрочный текст',
    'warning.screenTooSmall':
      'Переключитесь на более высокое разрешение экрана (минимум 1024px шириной) или, пожалуйста, расширить окно браузера.',
    'label.dictionary': 'Concordance Поиск',
    'dict.search.placeholder': 'Введите слово ()',
    'tooltip.manuscript': 'манускрипт',
    'tooltip.strongsx': 'Номер Стронга',
    'tooltip.translation': 'Перевод',
    'tooltip.english': 'английский',
    'title.howToSearch': 'Как пользоваться поиском конкорданса',
    'desc.howToSearch':
      'Введите слово (ы) с греческого или на иврите, слово (а) от вашего перевода, или Strong номера (ов). Или нажмите на слова греческого или иврита в панели «Ваш перевод» или просмотров Alignment для параметров поиска.',
    'dict.noDataToDisplay': 'Там нет никаких данных, которые соответствуют критериям поиска.',
    'title.searchTermTooMany': 'Вы ввели слишком много ключевых слов',
    'desc.searchTermTooMany': 'Вы можете искать до 8 ключевых слов каждый раз.',
    'title.searchDictError': 'Произошла непредвиденная ошибка.',
    'desc.searchDictError':
      'Пожалуйста, повторите попытку позже или обратитесь в службу поддержки клиентов',
    'alignment.saveAndEditAlignment': 'Сохранить и Align Новый текст',
    'alignment.reviewAlignment': 'Обзор Выравнивание текста',
    'dict.searchLemma': 'Нажмите для поиска оригинального слова',
    'dict.searchTranslation': 'Нажмите для поиска перевода слов',
    'dict.searchStrongsX': 'Нажмите для поиска Strong номера модели',
    'dict.showMenu': 'Нажмите, чтобы показать полный текст вхождений в контексте.',
    'dict.hideMenu': 'Нажмите, чтобы скрыть полный текст вхождений в контексте.',
  },
  sw: {
    'languages.en': 'english',
    'languages.am': 'Kiamhari',
    'languages.bn': 'Kibengali lugha',
    'languages.ceb': 'Cebuano lugha',
    'languages.de': 'Lugha ya Kijerumani',
    'languages.es-419': 'lugha ya Kihispania',
    'languages.fr': 'Lugha ya Kifaransa',
    'languages.gu': 'Kigujarati',
    'languages.hi': 'hindi',
    'languages.hr': 'Kikorasia lugha',
    'languages.hu': 'hungarian lugha',
    'languages.id': 'Kiindonesia lugha',
    'languages.km': 'Khmer',
    'languages.kn': 'kannada',
    'languages.ko': 'Korea lugha',
    'languages.lo': 'Laotian lugha',
    'languages.ml': 'Kimalayalam',
    'languages.mr': 'Marathi',
    'languages.ms': 'Malaysia',
    'languages.my': 'Kiburma lugha',
    'languages.ne': 'Kinepali lugha',
    'languages.plt': 'Malagasy lugha',
    'languages.pt-br': 'Kireno (Brazil) lugha',
    'languages.ru': 'russian lugha',
    'languages.sw': 'Kiswahili',
    'languages.ta': 'Tamil lugha',
    'languages.th': 'thai lugha',
    'languages.tl': 'Kitagalogi',
    'languages.te': 'Telegu lugha',
    'languages.vi': 'Kivietinamu lugha',
    'languages.zh-hans': 'Lugha ya Kichina (Kilichorahisishwa)',
    'languages.zh-hant': 'Lugha ya Kichina (cha Jadi)',
    'header.home': 'Home',
    'header.help': 'Msaada',
    'header.project': 'Mradi',
    'header.contact': 'Mawasiliano',
    'help.contactSupport': 'Tafadhali email yetu katika help@youtranslate.bible kwa msaada.',
    'home.ytbProvides': 'YouTranslate.Bible uhuru hutoa:',
    'home.easyToUseTool': 'rahisi kutumia zana ya tafsiri mtandaoni',
    'home.originalTextHelp': 'Msaada kuelewa maandiko ya Biblia katika lugha yake ya asili',
    'home.referencesAndResources': 'Kumbukumbu na mafunzo rasilimali',
    'home.computerAssistance': 'Hali ya-ya sanaa teknolojia ya kompyuta ili kusaidia watafsiri',
    'profile.userProfile': 'profile user',
    'profile.language': 'lugha',
    switchDirection: 'Switch Nakala Direction',
    'profile.update': 'Mwisho',
    'profile.languageWarning':
      'Google Tafsiri ilitumika kutafsiri user interface kwa lugha nyingine zaidi ya Kiingereza kwa muda. YTB ​​hana mamlaka juu ya usahihi au kuegemea ya tafsiri. Mtu yeyote kutegemea maandiko kutafsiriwa na maelezo zilizopatikana kutoka Google Tafsiri anafanya hivyo katika hatari yake mwenyewe. Kama mtu yeyote ni nia ya kujitolea kwa kutoa sahihi zaidi na ya kuaminika tafsiri za kiolesura cha mtumiaji kwa lugha nyingine zaidi ya Kiingereza, tafadhali bonyeza Mawasiliano chaguo kutuma barua pepe kwa GBI.',
    'usernav.profile': 'Profile',
    'usernav.logout': 'Toka',
    'usernav.logout.modalTitle': 'Toka wa YouTranslate.Bible',
    'usernav.logout.modalText':
      'Una uhakika unataka ili kuondoka? All kazi wasiookoka itakuwa waliopotea.',
    cancel: 'kufuta',
    delete: 'Futa',
    create: 'kujenga',
    update: 'Mwisho',
    edit: 'Edit',
    save: 'Save',
    add: 'Kuongeza',
    translate: 'Tafsiri',
    'more.options': 'chaguo zaidi',
    'project.create.title': 'Biblia tafsiri Project',
    'project.create.list': 'orodha Project',
    'project.list.deleteProject': 'Futa Project?',
    'project.list.areYouSure': 'Una uhakika unataka kufuta mradi huu?',
    'project.list.workWillBeDeleted': 'mradi na kazi yoyote yanayohusiana yatafutwa.',
    'project.list.completion': 'Mwisho:',
    'project.list.createdDate': 'Imeundwa Tarehe:',
    'project.list.beginningDate': 'Kuanzia Tarehe:',
    'project.list.targetCompletionDate': 'Target Kukamilisha Tarehe:',
    'project.list.description': 'Maelezo:',
    'project.edit': 'Edit Project',
    'project.list.translateBible': 'Tafsiri ya Biblia',
    'project.list.empty': 'Orodha yako ya tafsiri mradi ni tupu.',
    'project.list.myProjects': 'My Biblia tafsiri Miradi',
    'project.list.newProject': 'Project New',
    'project.create.createNew': 'Kujenga mpya Project',
    'project.namePlaceholder': 'Ingiza jina mradi',
    'project.descriptionPlaceholder': 'Weka maelezo ya mradi',
    'project.name': 'Jina la mradi',
    'project.beginningDate': 'kuanzia tarehe',
    'project.targetCompletionDate': 'Target Kukamilisha Tarehe',
    'project.description': 'Maelezo',
    'project.isRTL': 'Tafsiri ni haki-hadi-kushoto',
    'project.manage-users': 'kusimamia Watafsiri',
    'project.new-user': 'translator New',
    'project.add-user': 'kuongeza translator',
    'project.users': 'Watafsiri Project',
    'project.read-only': 'kusoma tu',
    'project.read&write': 'Read & Write',
    'project.owner': 'mmiliki',
    'project.translator': 'Translator',
    'project.message.no-such-user': 'Hakuna translator hiyo uliopo',
    'project.message.user-added': 'Translator imeongezwa',
    'project.message.user-deleted': 'Translator kuondolewa.',
    'project.message.only-owner-can-manage-users':
      'mmiliki wa mradi tu unaweza kusimamia watumiaji',
    'project.shared': 'mradi shirikishi',
    'general.delete-user': 'translator Futa',
    'general.edit-user': 'Edit user',
    'general.name': 'jina',
    'general.email': 'E-mail',
    'general.permissions': 'Idhini',
    'general.role': 'Wajibu',
    signin: 'Weka sahihi',
    'signin.emailPlaceholder': 'Barua pepe',
    'signin.passwordPlaceholder': 'password',
    'signin.forgotPassword': 'Umesahau nenosiri yako?',
    'signin.facebook': 'Ingia kwa kutumia Facebook',
    'signin.google': 'Ingia kwa kutumia Google',
    'signin.dontHaveAccount': 'Huna akaunti ya?',
    'signin.createOne': 'kujenga moja',
    'signup.join': 'Kujiunga YouTranslate.Bible',
    'signup.createAccountPrompt': 'Fungua akaunti yako ya bure ili kuanza.',
    'signup.createAccount': 'Tengeneza akaunti',
    'signup.emailPlaceholder': 'Barua pepe',
    'signup.passwordPlaceholder': 'password',
    'passwordReset.title': 'wa kuweka upya nenosiri',
    'passwordReset.prompt': 'Ombi barua pepe na maelekezo ya kuweka upya nenosiri lako.',
    'passwordReset.emailPlaceholder': 'Barua pepe',
    'passwordReset.requestButtonLabel': 'ombi pepe',
    'passwordReset.success': 'Barua pepe imetumwa.',
    'passwordReset.error': 'Kosa limetokea.',
    'translation.project': 'PROJECT:',
    'translation.backToProjectList': 'Rudi kwenye orodha ya mradi',
    'translation.book': 'kitabu:',
    'translation.chapter': 'Sura:',
    'translation.prompt':
      'Una uhakika unataka kuondoka sura hii? All kazi wasiookoka itakuwa waliopotea.',
    'translation.prev': 'Awali',
    'translation.next': 'next',
    'translation.previousChapter': 'sura ya awali',
    'translation.nextChapter': 'sura inayofuata',
    'navigator.book': 'kitabu:',
    'navigator.chapter': 'Sura:',
    'navigator.oldTestament': 'Agano la Kale',
    'navigator.newTestament': 'Agano jipya',
    'navigator.selectBook': 'chagua kitabu',
    'navigator.search': 'Tafuta',
    'navigator.changeBook': 'Badilisha kitabu',
    'navigator.changeChapter': 'Badilisha sura',
    Genesis: 'Mwanzo',
    Exodus: 'Kutoka',
    Leviticus: 'Walawi',
    Numbers: 'Hesabu',
    Deuteronomy: 'Kumbukumbu',
    Joshua: 'Joshua',
    Judges: 'Waamuzi',
    Ruth: 'Ruth',
    '1 Samuel': '1 Samuel',
    '2 Samuel': '2 Samuel',
    '1 Kings': '1 Wafalme',
    '2 Kings': '2 Wafalme',
    '1 Chronicles': '1 Mambo ya Nyakati',
    '2 Chronicles': '2 Mambo ya Nyakati',
    Ezra: 'Ezra',
    Nehemiah: 'Nehemia',
    Esther: 'Esther',
    Job: 'Job',
    Psalms: 'Zaburi',
    Proverbs: 'methali',
    Ecclesiastes: 'Mhubiri',
    'Song of Songs': 'Wimbo',
    Isaiah: 'Isaya',
    Jeremiah: 'Jeremiah',
    Lamentations: 'maombolezo',
    Ezekiel: 'Ezekiel',
    Daniel: 'Daniel',
    Hosea: 'Hosea',
    Joel: 'Joel',
    Amos: 'Amos',
    Obadiah: 'Obadia',
    Jonah: 'Yona',
    Micah: 'Mika',
    Nahum: 'Nahumu',
    Habakkuk: 'Habakuki',
    Zephaniah: 'Sefania',
    Haggai: 'Hagai',
    Zechariah: 'Zakaria',
    Malachi: 'Malaki',
    Matthew: 'Mathayo',
    Mark: 'Mark',
    Luke: 'Luke',
    John: 'John',
    Acts: 'Matendo',
    Romans: 'Warumi',
    '1 Corinthians': '1 Wakorintho',
    '2 Corinthians': '2 Wakorintho',
    Galatians: 'Wagalatia',
    Ephesians: 'Waefeso',
    Philippians: 'Wafilipi',
    Colossians: 'Wakolosai',
    '1 Thessalonians': '1 Wathesalonike',
    '2 Thessalonians': '2 Wathesalonike',
    '1 Timothy': 'Timothy 1',
    '2 Timothy': '2 Timothy',
    Titus: 'Titus',
    Philemon: 'Philemon',
    Hebrews: 'Waebrania',
    James: 'James',
    '1 Peter': '1 Petro',
    '2 Peter': '2 Peter',
    '1 John': '1 John',
    '2 John': '2 John',
    '3 John': '3 Yohana',
    Jude: 'Jude',
    Revelation: 'Ufunuo',
    'resources.prompt':
      'Kuongeza rasilimali Biblia kwa ufahamu wa ziada kwenye kifungu unayotafsiri.',
    'resources.add': 'Kuongeza rasilimali Biblia',
    Manuscript: 'Muswada',
    Morphology: 'Mofolojia',
    Lexicon: 'Lexicon',
    'Syntactic Tree': 'Kiwango cha kisintaksia Tree',
    'Go to project list': 'Nenda kwenye orodha ya mradi',
    'Project not found': 'Mradi haipatikani au ruhusa halitoshi.',
    'error.referenceDoesNotExistForResource':
      'tafsiri hii Biblia haina maudhui ya kifungu kuchaguliwa.',
    'references.addAnother': 'Kuongeza tafsiri nyingine',
    'references.referencePrompt': 'Kuongeza Biblia nyingine kwa ufahamu zaidi.',
    'references.add': 'Kuongeza tafsiri Biblia',
    'references.licensing': 'leseni ya habari',
    'references.licensingEmpty': 'Hakuna maelezo ya leseni ya kupatikana kwa bidhaa hii.',
    'translation.complete': 'tafsiri kamili',
    Alignment: 'Mpangilio',
    Share: 'Kushiriki',
    'Your Translation': 'tafsiri yako',
    'Enter your translation': 'Weka tafsiri yako',
    OK: 'sawa',
    'error.verseTranslationEmpty': 'Huwezi kuacha tafsiri maudhui tupu.',
    'translation.hide.completed': 'Ficha kukamilika',
    alignment: 'Mpangilio',
    'alignment.verify': 'Kuthibitisha na kufunga.',
    'alignment.close': 'Close bila kuthibitisha',
    'alignment.backToEditor': 'Nyuma kwa Mhariri',
    'alignment.error':
      'Kulikuwa na tatizo. Kama likiendelea tatizo, tafadhali wasiliana na timu YouTranslate.Bible.',
    'alignment.add': 'Ongeza Kiungo',
    'alignment.delete': 'Ondoa Kiungo',
    'alignment.clear': 'Futa Uchaguzi',
    'alignment.reverse': 'kinyume Display',
    'alignment.removeOrClear': 'Kuondoa kiungo au uteuzi wa wazi.',
    'alignment.addOrRemove': 'Kiungo kipya au futa moja uliopita.',
    'alignment.selectAndAdd': 'Kuchagua sambamba makundi ya kiungo.',
    'error.mustBeSignedToMakeChanges': 'Lazima uwe umeingia ili kufanya mabadiliko.',
    'error.mustBeSignedToTranslateBible': 'Lazima uwe umeingia ili kutafsiri Biblia.',
    checking: 'kuangalia',
    'verseEditor.unexpectedError': 'hitilafu isiyotarajiwa imetokea.',
    verses: 'By Verse',
    words: 'kwa Neno',
    'leftPanel.name': 'Original Nakala ya Biblia',
    'rightPanel.name': 'Tafsiri za Biblia',
    'bottomPanel.name': 'Rasilimali kwa ajili ya Biblia',
    revisionHistory: 'Historia ya marekebisho',
    'syntax.adv': 'adverbial vya kurekebisha',
    'syntax.adjp': 'Viambishi Fungu la maneno',
    'syntax.advp': 'kielezi Fungu la maneno',
    'syntax.cl': 'kifungu',
    'syntax.conj': 'kushirikiana',
    'syntax.cjp': 'kushirikiana',
    'syntax.det': 'Determiner',
    'syntax.ijp': 'Interjection',
    'syntax.nump': 'Numerical Fungu la maneno',
    'syntax.np': 'nomino Fungu la maneno',
    'syntax.o': 'moja kwa moja kitu',
    'syntax.o2': 'kitu cha pili',
    'syntax.oc': 'kitu inayosaidia',
    'syntax.om': 'kitu la alama',
    'syntax.io': 'moja kwa moja kitu',
    'syntax.p': 'Bila maneno prediketo',
    'syntax.pp': 'Prepositional Fungu la maneno',
    'syntax.prep': 'kihusishi',
    'syntax.ptcl': 'chembe',
    'syntax.rel': 'jamaa Chembe',
    'syntax.s': 'Subject',
    'syntax.v': 'kitendo',
    'syntax.vc': 'kuunganisha Verb',
    'syntax.adjp.description': 'Kauli ambayo hugeuza nomino au kiwakilishi',
    'syntax.adv.description':
      'Modifies kitenzi, kawaida zaidi kufafanua namna, wakati, mahali, mzunguko, kiwango, nk ...',
    'syntax.advp.description':
      'Maneno ya kubadilisha kitendo, kawaida zaidi kufafanua namna, wakati, mahali, mzunguko, kiwango, nk',
    'syntax.cl.description': 'kundi la maneno ambayo inafanya Madai kuhusu somo.',
    'syntax.conj.description': 'Huunganisha maneno, maneno, na vipengele.',
    'syntax.cjp.description': 'Huunganisha maneno, maneno, na vipengele.',
    'syntax.det.description': 'Inabainisha, kubainisha, au quantifies nomino maneno.',
    'syntax.ijp.description': 'Mshangao au onyesho ghafla kwa hisia.',
    'syntax.nump.description': 'Kauli ambayo inaeleza wingi namba',
    'syntax.np.description': 'maneno ambayo ina nomino kama mkuu wake au kuwa kazi kama jina.',
    'syntax.o.description':
      'Kile ambacho ni moja kwa moja walioathirika, unasababishwa, au zinazozalishwa na hatua ya kitenzi.',
    'syntax.o2.description':
      'Kile ambacho ni moja kwa moja walioathirika, unasababishwa, au zinazozalishwa na hatua ya kitenzi.',
    'syntax.oc.description': 'Hufanya Madai kuhusu Kitu moja kwa moja.',
    'syntax.om.description': 'Chembe hiyo utangulizi kitu moja kwa moja.',
    'syntax.io.description':
      'Kitu kwamba ni moja kwa moja walioathirika na hatua, mara nyingi mpokeaji wa utekelezaji wa kitendo.',
    'syntax.p.description':
      'Inatambua au inaelezea somo, mara nyingi (lakini si mara zote) hutokea pamoja na kitenzi kuunganisha.',
    'syntax.pp.description': 'Kurekebisha maneno yenye kihusishi na kitu wake.',
    'syntax.prep.description':
      'Inaonyesha uhusiano wa kitu cha kihusishi kwa maneno mengine, mara nyingi kuonyesha wapi au wakati.',
    'syntax.ptcl.description':
      'Mjumbe mmoja kutoka makundi tofauti ya maneno ya kazi ambazo si yoyote ya inflected madarasa sarufi neno',
    'syntax.rel.description': 'Chembe hiyo utangulizi kifungu jamaa.',
    'syntax.s.description': 'sehemu ya kifungu kwamba mapumziko ya kifungu hufanya Madai kuhusu.',
    'syntax.v.description':
      'Inasema kitu kuhusu somo la kifungu na inaweza kueleza hatua, tukio, au hali.',
    'syntax.vc.description': 'Viungo chini ya kifungu na mada inayosaidia.',
    'general.close': 'Close',
    'general.action': 'Action',
    'general.select': 'Chagua ...',
    'general.back': 'Back',
    'revisionHistory.noData': 'Hakuna historia ya marekebisho kwa kifungu hiki.',
    'revisionHistory.translation': 'tafsiri',
    'revisionHistory.lastChanged': 'Mara ya mwisho ilibadilishwa',
    'sourceText.view': 'Bofya ili kuona nakala chanzo',
    'sourceText.viewStructure': 'Bofya ili kuona muundo nakala chanzo',
    'error.projectIdIsRequired': 'ID Project inahitajika.',
    'error.searchTermIsRequired': 'Ya utafutaji hawawezi tupu.',
    'error.bookIdIsRequired': 'Kitabu ID inahitajika kuhifadhi kitabu jina.',
    'error.bookNameCannotBeEmpty': 'jina Kitabu hakiwezi kuwa tupu.',
    'error.signInIsRequiredToAccessProject': 'Lazima uwe umeingia ili kufikia mradi wako.',
    error: 'Kosa',
    'error.doNotHavePermissionAccessProject': 'Si lazima ruhusa ya kupata mradi huu.',
    'error.unauthorized': 'kupata ruhusa au kupata idhini batili.',
    'error.tokenExpired': 'ishara yako ina muda wake. Tafadhali re-kuingia.',
    'error.invalidApiUrl': 'url batili API.',
    'error.badRequestInvalidJson': 'Ombi baya. Batili JSON.',
    'error.windowIsNotDefined': 'Dirisha si defined.',
    'error.unexpectedError':
      'hitilafu isiyotarajiwa imetokea. Tafadhali funga dirisha hili na jaribu tena.',
    'error.invalidDataType': 'Aina batili data.',
    'export.data': 'Data Export',
    more: 'zaidi',
    'export.to.rtf': 'Export kwa RTF',
    'export.to.usfm': 'Export kwa USFM',
    'export.titlePreparingData': 'Sisi ni maandalizi data yako Biblia tafsiri.',
    'export.doNotClose':
      'Hatua hii inaweza kuchukua muda, tafadhali wala karibu dirisha hili na utakuwa taarifa wakati ni tayari kupakuliwa.',
    'export.desc': 'Unaweza kuhamisha na kupakua data yako ya tafsiri kwa kubonyeza kifungo chini.',
    'export.titleOfReady2Download': 'Yako ya tafsiri ya Biblia data iko tayari.',
    'export.textOfReady2Download':
      'Tafadhali bonyeza download kifungo chini ya shusha tafsiri yako Biblia.',
    'export.statistics': 'Takwimu',
    'export.book': 'jina kitabu',
    'export.translatedVerses': 'mistari kutafsiriwa',
    'export.exportedVerses': 'mistari nje',
    'export.howDoUWant2Export': 'Je, unataka kuhamisha data?',
    'export.whichBooks': 'Ni kitabu (s) unataka kuuza nje?',
    'export.byBooks': 'By vitabu',
    'export.byChapters': 'By sura',
    'export.byVerses': 'By mistari',
    'export.booksInOt': 'Agano la Kale',
    'export.booksInNt': 'Agano jipya',
    'export.booksInBible': 'zima Biblia',
    'export.booksInNone': 'hakuna',
    'export.selectRangeOfChapters': 'Aina ya sura',
    'export.selectRangeOfVerses': 'Mbalimbali ya mistari',
    'export.selectChapter': 'Kuchagua sura ya',
    'export.exportTypeIsRequired': 'Bado kuchaguliwa kuhusu jinsi gani unataka kuhamisha data?',
    'export.haveNotYetSelectedBooks': 'Bado kuchaguliwa kitabu chochote.',
    'error.selectABook': 'Lazima uchague kitabu.',
    'error.invalidBookId': 'ID kitabu si sahihi.',
    'error.selectAChapter': 'Lazima uchague sura.',
    'error.invalidChapterRange': 'Lazima uchague halali sura mbalimbali.',
    'error.invalidVerseRange': 'Lazima uchague halali mstari masafa.',
    'error.invalidExportType': 'Aina ya kuuza nje batili.',
    startover: 'Anza tena',
    download: 'download',
    'download.all': 'Download faili zote',
    'contact.header': 'Wasiliana nasi',
    'contact.returnAddress': 'Return ya anwani',
    'contact.returnAddressPlaceholder': 'Tunaweza kufikia wewe?',
    'contact.subject': 'Subject',
    'contact.subjectPlaceholder': 'Ni nini wanahitaji msaada na?',
    'contact.message': 'Ujumbe',
    'contact.messagePlaceholder': 'Tunawezaje kusaidia?',
    'contact.sendMessage': 'Tuma Ujumbe',
    'contact.success': 'Ujumbe ulifanikiwa kutumwa.',
    'contact.failure':
      'Kulikuwa na tatizo la kutuma ujumbe huu. Tafadhali wasiliana help@globalbibleinitiative.org.',
    selectInterlinear: 'Bofya ili kubadilisha maandishi Interlinear',
    'warning.screenTooSmall':
      'Tafadhali kubadili screen azimio juu (kiwango cha chini 1024px pana) au tafadhali kuongeza dirisha la kivinjari.',
    'label.dictionary': 'upatano Search',
    'dict.search.placeholder': 'Ingiza neno (s)',
    'tooltip.manuscript': 'Muswada',
    'tooltip.strongsx': 'Nguvu ya Namba',
    'tooltip.translation': 'tafsiri',
    'tooltip.english': 'english',
    'title.howToSearch': 'Jinsi ya kutumia upatano search',
    'desc.howToSearch':
      'Ingiza neno (s) kutoka Kigiriki au Kiebrania, neno (s) kutoka katika tafsiri yako, au Nambari Nguvu (s). Au bonyeza Kigiriki au Kiebrania maneno katika jopo la "Tafsiri yako" au maoni Mpangilio kwa chaguo za utafutaji.',
    'dict.noDataToDisplay': 'Hakuna data wanaofanana na unayemtafuta.',
    'title.searchTermTooMany': 'Umeingiza maneno mengi',
    'desc.searchTermTooMany': 'Unaweza kutafuta hadi 8 maneno kila wakati.',
    'title.searchDictError': 'hitilafu isiyotarajiwa imetokea.',
    'desc.searchDictError': 'Tafadhali jaribu tena baadaye au msaada mawasiliano mteja',
    'alignment.saveAndEditAlignment': 'Hifadhi na Panga New Nakala',
    'alignment.reviewAlignment': 'Tathmini Nakala Alignment',
    'dict.searchLemma': 'Bofya ili kutafuta neno awali',
    'dict.searchTranslation': 'Bofya ili kutafuta tafsiri ya neno',
    'dict.searchStrongsX': 'Bofya ili kutafuta Nguvu namba',
    'dict.showMenu': 'Bofya ili kuonyesha maandishi kamili ya matukio katika muktadha.',
    'dict.hideMenu': 'Bofya ili kuficha maandishi kamili ya matukio katika muktadha.',
  },
  ta: {
    'languages.en': 'ஆங்கிலம்',
    'languages.am': 'அம்ஹரிக்',
    'languages.bn': 'பெங்காலி மொழி',
    'languages.ceb': 'செபுவானோ மொழி',
    'languages.de': 'ஜெர்மன் மொழி',
    'languages.es-419': 'ஸ்பானிஷ் மொழி',
    'languages.fr': 'பிரஞ்சு மொழி',
    'languages.gu': 'குஜராத்தி',
    'languages.hi': 'இந்தி',
    'languages.hr': 'குரோஷியன் மொழி',
    'languages.hu': 'ஹங்கேரியன் மொழி',
    'languages.id': 'இந்தோனேஷியன் மொழி',
    'languages.km': 'கெமெர்',
    'languages.kn': 'கன்னடம்',
    'languages.ko': 'கொரிய மொழி',
    'languages.lo': 'லாவோடியன் மொழி',
    'languages.ml': 'மலையாளம்',
    'languages.mr': 'மராத்தி',
    'languages.ms': 'மலேசிய',
    'languages.my': 'பர்மிஸ் மொழி',
    'languages.ne': 'நேபாளி மொழி',
    'languages.plt': 'மலகாஸி மொழி',
    'languages.pt-br': 'போர்த்துகீசியம் (பிரேசில்) மொழி',
    'languages.ru': 'ரஷ்ய மொழி',
    'languages.sw': 'சுவாஹிலி',
    'languages.ta': 'தமிழ் மொழி',
    'languages.th': 'தாய்லாந்து மொழி',
    'languages.tl': 'டாகாலோக்',
    'languages.te': 'தெலுங்கு மொழி',
    'languages.vi': 'வியட்நாமிய மொழி',
    'languages.zh-hans': 'சீன மொழி (எளிதாக்கப்பட்டது)',
    'languages.zh-hant': 'சீன மொழி (பாரம்பரிய)',
    'header.home': 'வீடு',
    'header.help': 'உதவி',
    'header.project': 'திட்ட',
    'header.contact': 'தொடர்பு',
    'help.contactSupport': 'உதவி help@youtranslate.bible எங்களை தொடர்பு கொள்ளவும்.',
    'home.ytbProvides': 'YouTranslate.Bible சுதந்திரமாக வழங்குகிறது:',
    'home.easyToUseTool': 'ஒரு சுலபமான பயன்படுத்தக்கூடிய ஆன்லைன் மொழிபெயர்ப்பு கருவி',
    'home.originalTextHelp': 'உதவி அதன் அசல் மொழிகளில் பைபிள் உரை புரிந்து கொள்ள',
    'home.referencesAndResources': 'குறிப்பு மற்றும் பயிற்சி வளங்கள்',
    'home.computerAssistance': 'ஸ்டேட்-ஆஃப்-த-ஆர்ட் கணினி தொழில்நுட்பம் மொழிபெயர்ப்பாளர்கள் உதவ',
    'profile.userProfile': 'பயனர் விவரம்',
    'profile.language': 'மொழி',
    switchDirection: 'ஸ்விட்ச் உரை இயக்கம்',
    'profile.update': 'புதுப்பிக்கப்பட்டது',
    'profile.languageWarning':
      'Google Translate தற்காலிகமாக ஆங்கிலம் அல்லாத பிற மொழிகளில் உங்கள் பயனர் இடைமுகத்தை மொழிபெயர்க்க பயன்படுத்தப்பட்டது. YTB ​​மொழிபெயர்ப்பு துல்லியம் அல்லது நம்பகத்தன்மையில் இருந்த எவ்விதமான கட்டுப்பாடும் கொண்டிருப்பதில்லை. மொழிபெயர்க்க லேபிள்கள் மற்றும் விளக்கங்கள் கூகிள் பெறப்பட்ட சார்ந்து எவரும் மொழிபெயர் அவரது சொந்த விருப்பத்தின் பேரில்தான் அவ்வாறு செய்கின்றார். யாரையும் ஆங்கிலம் அல்லாத பிற மொழிகளில் உங்கள் பயனர் இடைமுகத்தை மிகவும் துல்லியமான மற்றும் நம்பகமான மொழிபெயர்ப்பு வழங்க தன்னார்வ ஆர்வமுள்ள என்றால், GBI ஒரு மின்னஞ்சல் அனுப்புவதற்கு, தொடர்பு விருப்பத்தை கிளிக் செய்யுங்கள்.',
    'usernav.profile': 'பதிவு செய்தது',
    'usernav.logout': 'வெளியேறு',
    'usernav.logout.modalTitle': 'YouTranslate.Bible இன் வெளியேறு',
    'usernav.logout.modalText':
      'உறுதியாக உள்ளீர்களா வெளியேறி வேண்டும்? சேமிக்கப்படாத அனைத்து வேலை இழக்கப்படும்.',
    cancel: 'ரத்து',
    delete: 'அழி',
    create: 'உருவாக்கவும்',
    update: 'புதுப்பிக்கப்பட்டது',
    edit: 'தொகு',
    save: 'சேமி',
    add: 'கூட்டு',
    translate: 'மொழிபெயர்',
    'more.options': 'மேலும் விருப்பங்கள்',
    'project.create.title': 'பைபிள் மொழிபெயர்ப்பு திட்ட',
    'project.create.list': 'திட்ட பட்டியலில்',
    'project.list.deleteProject': 'திட்டப்பணியை நீக்கு?',
    'project.list.areYouSure': 'இந்தத் திட்டப்பணியை நீக்க வேண்டும் உறுதியாக உள்ளீர்களா?',
    'project.list.workWillBeDeleted': 'திட்டம் மற்றும் தொடர்புடைய எந்த வேலை நீக்கப்படும்.',
    'project.list.completion': 'நிறைவு:',
    'project.list.createdDate': 'உருவாக்கிய தேதி:',
    'project.list.beginningDate': 'தேதி தொடங்கி:',
    'project.list.targetCompletionDate': 'இலக்கு நிறைவு நாள்:',
    'project.list.description': 'விளக்கம்:',
    'project.edit': 'புராஜெக்ட்டைத் திருத்து',
    'project.list.translateBible': 'பைபிள் மொழிபெயர்',
    'project.list.empty': 'உங்கள் மொழிபெயர்ப்பு திட்டம் பட்டியல் காலியாக உள்ளது.',
    'project.list.myProjects': 'என் பைபிள் மொழிபெயர்ப்பு திட்டங்கள்',
    'project.list.newProject': 'புதிய திட்டம்',
    'project.create.createNew': 'ஒரு புதிய திட்ட உருவாக்கவும்',
    'project.namePlaceholder': 'திட்டம் பெயரை உள்ளிடவும்',
    'project.descriptionPlaceholder': 'திட்டம் விளக்கத்தை உள்ளிடவும்',
    'project.name': 'திட்டப்பணி பெயர்',
    'project.beginningDate': 'தொடங்கி தேதி',
    'project.targetCompletionDate': 'இலக்கு நிறைவு தேதி',
    'project.description': 'விளக்கம்',
    'project.isRTL': 'மொழிபெயர்ப்பு வலது புறத்திலிருந்து இடது உள்ளது',
    'project.manage-users': 'மொழிபெயர்ப்பாளர் நிர்வகிக்கவும்',
    'project.new-user': 'புதிய மொழிபெயர்ப்பாளர்',
    'project.add-user': 'மொழிபெயர்ப்பாளர் சேர்',
    'project.users': 'திட்ட மொழிபெயர்ப்பாளர்கள்',
    'project.read-only': 'படிக்க மட்டும்',
    'project.read&write': 'படிக்க & எழுத',
    'project.owner': 'உரிமையாளர்',
    'project.translator': 'மொழிபெயர்ப்பாளர்',
    'project.message.no-such-user': 'அதுபோன்ற மொழிபெயர்ப்பாளர் உள்ளது',
    'project.message.user-added': 'மொழிபெயர்ப்பாளர் வெற்றிகரமாக சேர்க்கப்பட்டது',
    'project.message.user-deleted': 'மொழிபெயர்ப்பாளர் நீக்கப்பட்டது.',
    'project.message.only-owner-can-manage-users':
      'ஒரே திட்டம் உரிமையாளர் மட்டுமே பயனர்களை நிர்வகிக்க முடியும்',
    'project.shared': 'கூட்டுமுயற்சித் திட்டம்',
    'general.delete-user': 'நீக்கு மொழிபெயர்ப்பாளர்',
    'general.edit-user': 'பயனர் திருத்த',
    'general.name': 'பெயர்',
    'general.email': 'மின்னஞ்சல்',
    'general.permissions': 'அனுமதிகள்',
    'general.role': 'பங்கு',
    signin: 'உள்நுழையவும்',
    'signin.emailPlaceholder': 'மின்னஞ்சல் முகவரி',
    'signin.passwordPlaceholder': 'கடவுச்சொல்',
    'signin.forgotPassword': 'உங்கள் கடவுச்சொல்லை மறந்து விட்டீர்களா?',
    'signin.facebook': 'பேஸ்பு கொண்டு உள்நுழையவும்',
    'signin.google': 'கூகிள் கொண்டு உள்நுழையவும்',
    'signin.dontHaveAccount': 'ஒரு கணக்கு இல்லையா?',
    'signin.createOne': 'ஒன்றை உருவாக்கவும்',
    'signup.join': 'YouTranslate.Bible சேர',
    'signup.createAccountPrompt': 'தொடங்க உங்கள் இலவச கணக்கை உருவாக்கவும்.',
    'signup.createAccount': 'உங்கள் கணக்கை துவங்குங்கள்',
    'signup.emailPlaceholder': 'மின்னஞ்சல் முகவரி',
    'signup.passwordPlaceholder': 'கடவுச்சொல்',
    'passwordReset.title': 'கடவுச்சொல் மீட்டமைப்பு',
    'passwordReset.prompt':
      'உங்கள் கடவுச்சொல்லை மீட்டமைக்க வழிமுறைகளுடன் கூடிய மின்னஞ்சலைக் கோரு.',
    'passwordReset.emailPlaceholder': 'மின்னஞ்சல் முகவரி',
    'passwordReset.requestButtonLabel': 'மின்னஞ்சல் வழி',
    'passwordReset.success': 'மின்னஞ்சல் அனுப்பப்பட்டுள்ளது.',
    'passwordReset.error': 'தவறு நிகழ்ந்துவிட்டது.',
    'translation.project': 'கருத்திட்டம்:',
    'translation.backToProjectList': 'திட்டம் பட்டியலில் மீண்டும்',
    'translation.book': 'நூல்:',
    'translation.chapter': 'அத்தியாயம்:',
    'translation.prompt':
      'நீங்கள் இந்த அத்தியாயம் விட்டு வேண்டும் உறுதியாக உள்ளீர்களா? சேமிக்கப்படாத அனைத்து வேலை இழக்கப்படும்.',
    'translation.prev': 'முன்',
    'translation.next': 'அடுத்தது',
    'translation.previousChapter': 'முந்தைய அத்தியாயம்',
    'translation.nextChapter': 'அடுத்த அத்தியாயம்',
    'navigator.book': 'நூல்:',
    'navigator.chapter': 'அத்தியாயம்:',
    'navigator.oldTestament': 'பழைய ஏற்பாடு',
    'navigator.newTestament': 'புதிய ஏற்பாடு',
    'navigator.selectBook': 'ஒரு புத்தகம் தேர்ந்தெடுக்கவும்',
    'navigator.search': 'தேடல்',
    'navigator.changeBook': 'மாற்றம் புத்தகம்',
    'navigator.changeChapter': 'மாற்றம் அத்தியாயம்',
    Genesis: 'ஆதியாகமம்',
    Exodus: 'யாத்திராகமம்',
    Leviticus: 'லேவிடிகிஸ்',
    Numbers: 'எண்கள்',
    Deuteronomy: 'உபாகமம்',
    Joshua: 'யோசுவா',
    Judges: 'நீதிபதிகள்',
    Ruth: 'ரூத்',
    '1 Samuel': '1 சாமுவேல்',
    '2 Samuel': '2 சாமுவேல்',
    '1 Kings': '1 கிங்ஸ்',
    '2 Kings': '2 கிங்ஸ்',
    '1 Chronicles': '1 நாளாகமம்',
    '2 Chronicles': '2 நாளாகமம்',
    Ezra: 'எஸ்ரா',
    Nehemiah: 'நெகேமியா',
    Esther: 'எஸ்தர்',
    Job: 'வேலை',
    Psalms: 'சங்கீதம்',
    Proverbs: 'பழமொழிகள்',
    Ecclesiastes: 'பிரசங்கி',
    'Song of Songs': 'பாடல்கள் பாடல்',
    Isaiah: 'ஏசாயா',
    Jeremiah: 'ஜெரிமியா',
    Lamentations: 'புலம்பல்',
    Ezekiel: 'எசேக்கியேல்',
    Daniel: 'டேனியல்',
    Hosea: 'ஓசியா',
    Joel: 'ஜோயல்',
    Amos: 'அமோஸ்',
    Obadiah: 'ஒபதியா',
    Jonah: 'ஜோனா',
    Micah: 'மீகா',
    Nahum: 'நாகூம்',
    Habakkuk: 'ஆபகூக்',
    Zephaniah: 'செப்பனியா',
    Haggai: 'ஆகாய்',
    Zechariah: 'சகரியா',
    Malachi: 'மல்கியா',
    Matthew: 'மத்தேயு',
    Mark: 'மார்க்',
    Luke: 'லூக்கா',
    John: 'ஜான்',
    Acts: 'செயல்கள்',
    Romans: 'ரோமர்',
    '1 Corinthians': '1 கொரிந்தியர்',
    '2 Corinthians': '2 கொரிந்தியர்',
    Galatians: 'கலாத்தியர்',
    Ephesians: 'எபேசியர்',
    Philippians: 'பிலிப்பியர்',
    Colossians: 'கொலோசெயர்',
    '1 Thessalonians': '1 தெசலோனிக்கேயர்',
    '2 Thessalonians': '2 தெசலோனிக்கேயர்',
    '1 Timothy': '1 தீமோத்தேயு',
    '2 Timothy': '2 டிமோதி',
    Titus: 'டைட்டஸ்',
    Philemon: 'ஃபிலோமின்',
    Hebrews: 'எபிரேயர்',
    James: 'ஜேம்ஸ்',
    '1 Peter': '1 பீட்டர்',
    '2 Peter': '2 பீட்டர்',
    '1 John': '1 யோவான்',
    '2 John': '2 ஜான்',
    '3 John': '3 ஜான்',
    Jude: 'ஜூட்',
    Revelation: 'வெளிப்பாடு',
    'resources.prompt':
      'நீங்கள் மொழிபெயர்க்கும் பத்தியில் கூடுதல் பார்வையை விவிலிய வளங்கள் சேர்க்கவும்.',
    'resources.add': 'ஒரு விவிலிய வள சேர்',
    Manuscript: 'கையெழுத்துப்படி',
    Morphology: 'உருவியலில்',
    Lexicon: 'லெக்சிகன்',
    'Syntactic Tree': 'தொடரியல் மரம்',
    'Go to project list': 'திட்டம் பட்டியலிற்குச் செல்லலாம்',
    'Project not found': 'திட்ட கண்டுபிடிக்கப்பட்டதா அல்லது போதிய அனுமதி இல்லை.',
    'error.referenceDoesNotExistForResource':
      'இந்த பைபிள் மொழிபெயர்ப்பு தேர்ந்தெடுக்கப்பட்டுள்ளன வழிப்பாதையை உள்ளடக்கம் இல்லை.',
    'references.addAnother': 'மற்றொரு மொழிபெயர்ப்புக்கும் சேர்',
    'references.referencePrompt': 'கூடுதல் பார்வையை மற்ற பைபிள் மொழிபெயர்ப்பு சேர்க்க.',
    'references.add': 'பைபிள் மொழிபெயர்ப்பு சேர்',
    'references.licensing': 'உரிமத் தகவல்',
    'references.licensingEmpty': 'உரிமம் தகவல் எதுவும் இல்லை இந்த உருப்படியை காணப்படவில்லை.',
    'translation.complete': 'மொழிபெயர்ப்பு முழுமையான',
    Alignment: 'சீரமைப்பு',
    Share: 'பகிர்',
    'Your Translation': 'உங்கள் மொழிபெயர்ப்பு',
    'Enter your translation': 'உங்கள் மொழிபெயர்ப்பு சேர்க்கவும்',
    OK: 'சரி',
    'error.verseTranslationEmpty': 'நீங்கள் மொழிபெயர்ப்பு உள்ளடக்கத்தை காலியாக விட முடியாது.',
    'translation.hide.completed': 'நிறைவு மறை',
    alignment: 'சீரமைப்பு',
    'alignment.verify': 'சரிபார்க்கவும் மற்றும் மூட.',
    'alignment.close': 'சரிபார்ப்புச் இல்லாமல் மூட',
    'alignment.backToEditor': 'ஆசிரியர் மீண்டும்',
    'alignment.error':
      'ஏதோ தவறு நடைபெற்றிருக்கிறது. சிக்கல் தொடர்ந்தால், YouTranslate.Bible அணி தொடர்பு கொள்ளவும்.',
    'alignment.add': 'இணைப்பு சேர்க்க',
    'alignment.delete': 'இணைப்பு நீக்க',
    'alignment.clear': 'தெளிவு தேர்வு',
    'alignment.reverse': 'தலைகீழ் காட்சி',
    'alignment.removeOrClear':
      'தேர்ந்தெடுக்கப்பட்டுள்ளன இணைப்பு அல்லது தேர்ந்தெடுத்ததை அழி நீக்கவும்.',
    'alignment.addOrRemove': 'ஒரு புதிய இணைப்பு உருவாக்கவும் அல்லது முந்தைய உள்ளதை நீக்கவும்.',
    'alignment.selectAndAdd': 'இணைப்பை நீக்க பகுதியில் தொடர்புடைய தேர்ந்தெடுக்கவும்.',
    'error.mustBeSignedToMakeChanges': 'நீங்கள் செய்யும் மாற்றங்கள் உள்நுழையப்படுவீர்கள் வேண்டும்.',
    'error.mustBeSignedToTranslateBible': 'நீங்கள் பைபிள் மொழிபெயர்க்க உள்நுழைந்திருக்க வேண்டும்.',
    checking: 'சரிபார்க்கிறது',
    'verseEditor.unexpectedError': 'எதிர்பாராத பிழை ஏற்பட்டுள்ளது.',
    verses: 'வசனம் மூலம்',
    words: 'வார்த்தை மூலம்',
    'leftPanel.name': 'பைபிள் மூல உரை',
    'rightPanel.name': 'பைபிள் மொழிபெயர்ப்புகள்',
    'bottomPanel.name': 'பைபிள் வளங்கள்',
    revisionHistory: 'மறுபார்வை வரலாறு',
    'syntax.adv': 'வினைதழுவிய மாற்றி',
    'syntax.adjp': 'பெயரடைக்குரிய சொற்றொடர்',
    'syntax.advp': 'வினையுரிச்சொல் சொற்றொடர்',
    'syntax.cl': 'உட்கூறு',
    'syntax.conj': 'இணைந்து',
    'syntax.cjp': 'இணைந்து',
    'syntax.det': 'துணிகாரணி',
    'syntax.ijp': 'வியப்பிடைச்சொல்',
    'syntax.nump': 'எண்ணியல் சொற்றொடர்',
    'syntax.np': 'பெயர்ச்சொல் சொற்றொடர்',
    'syntax.o': 'நேரடி பொருள்',
    'syntax.o2': 'இரண்டாவது பொருள்',
    'syntax.oc': 'பொருள் நிறைவுடன்',
    'syntax.om': 'பொருள் குறிப்பான்',
    'syntax.io': 'மறைமுக பொருள்',
    'syntax.p': 'அல்லாத வாய்மொழி பயனிலைச்',
    'syntax.pp': 'முன்னிடை சொற்றொடர்',
    'syntax.prep': 'முன்னிடைச்சொல்',
    'syntax.ptcl': 'துகள்',
    'syntax.rel': 'உறவினர் துகள்',
    'syntax.s': 'பொருள்',
    'syntax.v': 'வினை',
    'syntax.vc': 'இணைத்தல் வினைச்சொல்',
    'syntax.adjp.description': 'பெயர்ச்சொல் அல்லது பிரதிபெயர் மாற்றியமைக்கும் சொற்றொடர்',
    'syntax.adv.description':
      'மாற்றங்களும் வினை, பொதுவாக மேலும் வரையறுக்கும் முறையில், நேரம், இடம், அதிர்வெண், பட்டம், போன்றவை ...',
    'syntax.advp.description':
      'சொற்றொடர், வினை மாற்றியமைப்பதன் பொதுவாக மேலும் வரையறுக்கும் முறையில், நேரம், இடம், அதிர்வெண், பட்டம், முதலியன',
    'syntax.cl.description': 'பொருள் பற்றி ஒரு வலியுறுத்தல் செய்கிறது சொற்களின் குழு.',
    'syntax.conj.description': 'வார்த்தைகள், சொற்றொடர்கள், மற்றும் பிரிவுகளைக் இணைக்கிறது.',
    'syntax.cjp.description': 'வார்த்தைகள், சொற்றொடர்கள், மற்றும் பிரிவுகளைக் இணைக்கிறது.',
    'syntax.det.description':
      'குறிப்பிடுகிறது, முக்கியத்துவத்தை கண்டறிவது, அல்லது அளவிடுவதோடு ஒரு பெயர்ச்சொல் சொற்றொடர்.',
    'syntax.ijp.description': 'ஆச்சரியக்குறி அல்லது உணர்ச்சி திடீர் வெளிப்பாடு.',
    'syntax.nump.description': 'எண் அளவு விவரிக்கும் சொற்றொடர்',
    'syntax.np.description':
      'அல்லது அதன் தலைவராக ஒரு பெயர்ச்சொல்லாக ஒரு பெயராகக் என்று செயல்பாடுகள் உள்ளன என்று ஒரு சொற்றொடர்.',
    'syntax.o.description':
      'அந்த நேரடியாக, பாதிக்கப்பட்ட காரணமாக, அல்லது ஒரு வினை நடவடிக்கை உற்பத்தி செய்யப்படுகிறது.',
    'syntax.o2.description':
      'அந்த நேரடியாக, பாதிக்கப்பட்ட காரணமாக, அல்லது ஒரு வினை நடவடிக்கை உற்பத்தி செய்யப்படுகிறது.',
    'syntax.oc.description': 'நேரடி பொருள் பற்றி ஒரு வலியுறுத்தல் படமாக்கும்.',
    'syntax.om.description': 'ஒரு நேரடி பொருள் அறிமுகப்படுத்துகிற துகள்.',
    'syntax.io.description':
      'மறைமுகமாக ஒரு நடவடிக்கை பாதிக்கப்படும் பொருள், ஒரு வினை நடவடிக்கையின் அடிக்கடி பெறுநர்.',
    'syntax.p.description':
      'அடையாளங்கண்டு அல்லது பொருள் விளக்கும் அடிக்கடி (ஆனால் எப்போதும் அல்ல) ஒன்றாக ஒரு இணைக்கும் வினை நிகழ்கிறது.',
    'syntax.pp.description':
      'ஒரு முன்னிடைச்சொல் மற்றும் அதன் பொருள் கொண்ட திருத்துதல் சொற்றொடர்.',
    'syntax.prep.description':
      'அடிக்கடி அங்கு அல்லது என்பதைக் குறிக்கும், வேறு வார்த்தைகளில் முன்னிடைச்சொல் எடுத்துக்கொண்ட பொருளின் உறவு குறிக்கிறது.',
    'syntax.ptcl.description':
      'இலக்கணத் இலக்கண சொல் வகுப்புகளில் எந்த சேர்ந்தவை வேண்டாம் என்று செயல்பாடு வார்த்தைகள் பலதரப்பட்ட இருந்து ஒரு உறுப்பினர்',
    'syntax.rel.description': 'ஒரு உறவினர் உட்கூறு அறிமுகப்படுத்துகிற துகள்.',
    'syntax.s.description':
      'உட்கூறு மீதமுள்ள பற்றி ஒரு வலியுறுத்தல் செய்கிறது அந்த உட்கூறின் பகுதி.',
    'syntax.v.description':
      'உட்கூறு பொருள் பற்றி ஏதாவது கூறுகிறது மற்றும் நடவடிக்கை, நிகழ்வு, அல்லது நிலை தெரிவிக்கக்கூடும்.',
    'syntax.vc.description': 'ஒரு பொருள் நிறைவுடன் ஒரு உட்கூறு பொருள் இணைப்புகள்.',
    'general.close': 'நெருக்கமான',
    'general.action': 'அதிரடி',
    'general.select': 'தேர்வு ...',
    'general.back': 'மீண்டும்',
    'revisionHistory.noData': 'இந்த வசனம் எந்த திருத்தம் வரலாறு உள்ளது.',
    'revisionHistory.translation': 'மொழிபெயர்ப்பு',
    'revisionHistory.lastChanged': 'மாற்றப்பட்டது கடைசியாக',
    'sourceText.view': 'மூல உரை காண கிளிக்',
    'sourceText.viewStructure': 'மூல உரை அமைப்பு காண கிளிக்',
    'error.projectIdIsRequired': 'திட்ட ஐடி தேவை.',
    'error.searchTermIsRequired': 'தேடல் கால காலி முடியாது.',
    'error.bookIdIsRequired': 'புத்தக ஐடி நூலின் பெயரைத் சேமிக்க வேண்டும்.',
    'error.bookNameCannotBeEmpty': 'புத்தக பெயர் காலியாக இருக்க முடியாது.',
    'error.signInIsRequiredToAccessProject': 'உங்கள் திட்டப்பணியை அணுக உள்நுழைய வேண்டும்.',
    error: 'பிழை',
    'error.doNotHavePermissionAccessProject': 'இந்த திட்டம் அணுக அனுமதி இல்லை.',
    'error.unauthorized': 'அங்கீகரிக்கப்படாத அணுகல் அல்லது தவறான அணுகல் டோக்கன்.',
    'error.tokenExpired': 'உங்கள் டோக்கன் காலாவதியானது. செய்து, மீண்டும் உள்நுழைவு.',
    'error.invalidApiUrl': 'தவறான API URL.',
    'error.badRequestInvalidJson': 'தவறான கோரிக்கை. தவறான JSON.',
    'error.windowIsNotDefined': 'ஜன்னல் வரையறுக்கப்படவில்லை.',
    'error.unexpectedError':
      'எதிர்பாராத பிழை ஏற்பட்டுள்ளது. இந்த சாளரத்தை மூடி மீண்டும் முயற்சிக்கவும்.',
    'error.invalidDataType': 'தவறான தரவு வகை.',
    'export.data': 'ஏற்றுமதி தரவு',
    more: 'மேலும்',
    'export.to.rtf': 'ஆக ஏற்றுமதி செய்',
    'export.to.usfm': 'USFM ஏற்றுமதி செய்',
    'export.titlePreparingData': 'நாம் உங்கள் பைபிள் மொழிபெயர்ப்பு தரவு தயாராகி வருகின்றன.',
    'export.doNotClose':
      'இந்த செயல்முறை சிறிது நேரம் ஆகலாம், இல்லை நெருங்கிய இந்த சாளரத்தில் செய்ய பதிவிறக்கம் செய்ய தயாராக இருக்கும் போது நீங்கள் அறிவிக்கப்படும் தயவு செய்து.',
    'export.desc':
      'ஏற்றுமதிக்கு மற்றும் கீழே பொத்தான்கள் கிளிக் செய்வதன் மூலம் உங்கள் மொழிபெயர்ப்பு தரவு பதிவிறக்கம் செய்து கொள்ளலாம்.',
    'export.titleOfReady2Download': 'உங்கள் பைபிள் மொழிபெயர்ப்பு தரவு தயாராக உள்ளது.',
    'export.textOfReady2Download':
      'உங்கள் பைபிள் மொழிபெயர்ப்பு பதிவிறக்க கீழே பதிவிறக்கம் பட்டனைக் கிளிக் செய்யவும்.',
    'export.statistics': 'புள்ளியியல்',
    'export.book': 'நூலின் பெயரைத்',
    'export.translatedVerses': 'மொழிபெயர்க்கப்பட்ட வசனங்கள்',
    'export.exportedVerses': 'ஏற்றுமதி செய்யப்படும் வசனங்கள்',
    'export.howDoUWant2Export': 'எப்படி நீங்கள் தரவு ஏற்றுமதி செய்யவேண்டும்?',
    'export.whichBooks': 'நீங்கள் எந்த புத்தகம் (ங்கள்) ஏற்றுமதி செய்ய விரும்புகிறீர்கள்?',
    'export.byBooks': 'புத்தகங்கள் மூலம்',
    'export.byChapters': 'அத்தியாயங்கள் மூலம்',
    'export.byVerses': 'செய்யுள்களால்',
    'export.booksInOt': 'பழைய ஏற்பாடு',
    'export.booksInNt': 'புதிய ஏற்பாடு',
    'export.booksInBible': 'முழு பைபிள்',
    'export.booksInNone': 'யாரும்',
    'export.selectRangeOfChapters': 'அத்தியாயங்கள் வரம்பில்',
    'export.selectRangeOfVerses': 'வசனங்கள் வரம்பில்',
    'export.selectChapter': 'ஒரு அத்தியாயம் தேர்ந்தெடுக்கவும்',
    'export.exportTypeIsRequired':
      'நீங்கள் ஏற்றுமதி தரவு விரும்பவில்லை எப்படி நீங்கள் இன்னும் பற்றி தேர்ந்தெடுக்கவில்லை?',
    'export.haveNotYetSelectedBooks': 'நீங்கள் இதுவரை எந்தச் புத்தகம் தேர்ந்தெடுக்கவில்லை.',
    'error.selectABook': 'நீங்கள் ஒரு புத்தகம் தேர்ந்தெடுக்க வேண்டும்.',
    'error.invalidBookId': 'தவறான புத்தகம் ஐடி.',
    'error.selectAChapter': 'நீங்கள் ஒரு அத்தியாயம் தேர்ந்தெடுக்க வேண்டும்.',
    'error.invalidChapterRange': 'நீங்கள் சரியான அத்தியாயம் வரம்பைத் தேர்ந்தெடுக்க வேண்டும்.',
    'error.invalidVerseRange': 'நீங்கள் சரியான வசனம் வரம்பைத் தேர்ந்தெடுக்க வேண்டும்.',
    'error.invalidExportType': 'தவறான ஏற்றுமதி வகை.',
    startover: 'மீண்டும் ஆரம்பி',
    download: 'பதிவிறக்க Tamil',
    'download.all': 'அனைத்து கோப்புகளை பதிவிறக்கம்',
    'contact.header': 'எங்களை தொடர்பு கொள்ள',
    'contact.returnAddress': 'திரும்ப முகவரி',
    'contact.returnAddressPlaceholder': 'எங்கே நாம் உங்களை எவ்வாறு தொடர்புகொள்வது?',
    'contact.subject': 'பொருள்',
    'contact.subjectPlaceholder': 'உனக்கு என்ன உதவி வேண்டும்?',
    'contact.message': 'செய்தி',
    'contact.messagePlaceholder': 'நாங்கள் உங்களுக்கு எப்படி உதவ முடியும்?',
    'contact.sendMessage': 'செய்தி அனுப்ப',
    'contact.success': 'செய்தி வெற்றிகரமாக அனுப்பப்பட்டது.',
    'contact.failure':
      'இந்த செய்தியை அனுப்புவதில் சிக்கல் ஏற்பட்டது. help@globalbibleinitiative.org தொடர்பு கொள்ளவும்.',
    selectInterlinear: 'Interlinear உரை மாற்ற கிளிக் செய்யவும்',
    'warning.screenTooSmall':
      'அதிக திரை தீர்மானம் (குறைந்தபட்ச 1024px பரந்த) மாறலாம் அல்லது உலாவி சாளரத்தை விரிவுபடுத்தும் கொள்க.',
    'label.dictionary': 'ஒற்றுமை தேடுதல்',
    'dict.search.placeholder': 'வார்த்தை (ங்கள்) உள்ளிடவும்',
    'tooltip.manuscript': 'கையெழுத்துப்படி',
    'tooltip.strongsx': 'வலிமையான எண்',
    'tooltip.translation': 'மொழிபெயர்ப்பு',
    'tooltip.english': 'ஆங்கிலம்',
    'title.howToSearch': 'ஒற்றுமை தேடல் எப்படி பயன்படுத்துவது',
    'desc.howToSearch':
      'கிரேக்கம் அல்லது ஹீப்ரு, வார்த்தை (கள்) உங்கள் மொழிபெயர்ப்பு, அல்லது வலுவான எண் (கள்) வார்த்தை (ங்கள்) உள்ளிடவும். அல்லது "உங்கள் மொழிபெயர்ப்பு" அல்லது தேடுதல் வசதிகள் சீரமைப்பு காட்சிகள் குழு உள்ள கிரேக்கம் அல்லது ஹீப்ரு வார்த்தைகள் மீது கிளிக் செய்யவும்.',
    'dict.noDataToDisplay': 'உங்கள் தேடல் நிபந்தனையுடன் பொருந்தும் தகவல்களும் இருக்கின்றன.',
    'title.searchTermTooMany': 'நீங்கள் பல வார்த்தைகளின் உள்ளிட்ட',
    'desc.searchTermTooMany': 'நீங்கள் 8 வார்த்தைகளின் ஒவ்வொரு முறையும் வரை தேடலாம்.',
    'title.searchDictError': 'எதிர்பாராத பிழை ஏற்பட்டுள்ளது.',
    'desc.searchDictError': 'பின்னர் மீண்டும் முயற்சிக்கவும் அல்லது வாடிக்கையாளர் ஆதரவைத்',
    'alignment.saveAndEditAlignment': 'சேமி மற்றும் சீரமை புதிய உரை',
    'alignment.reviewAlignment': 'விமர்சனம் உரை சீரமைப்பு',
    'dict.searchLemma': 'வேர்சொல் தேட இங்கே கிளிக் செய்யவும்',
    'dict.searchTranslation': 'மொழிபெயர்ப்பு சொல் தேட இங்கே கிளிக் செய்யவும்',
    'dict.searchStrongsX': 'வலுவான எண் தேட இங்கே கிளிக் செய்யவும்',
    'dict.showMenu': 'சூழலில் ஏற்படுவதன் முழு உரை காட்ட கிளிக் செய்யவும்.',
    'dict.hideMenu': 'சூழலில் ஏற்படுவதன் முழு உரை மறைக்க கிளிக் செய்யவும்.',
  },
  th: {
    'languages.en': 'อังกฤษ',
    'languages.am': 'อัมฮาริค',
    'languages.bn': 'บังคลาเทศภาษา',
    'languages.ceb': 'Cebuano ภาษา',
    'languages.de': 'ภาษาเยอรมัน',
    'languages.es-419': 'ภาษาสเปน',
    'languages.fr': 'ภาษาฝรั่งเศส',
    'languages.gu': 'คุชราต',
    'languages.hi': 'ภาษาฮินดี',
    'languages.hr': 'โครเอเชียภาษา',
    'languages.hu': 'ฮังการีภาษา',
    'languages.id': 'ภาษาชาวอินโดนีเซีย',
    'languages.km': 'ขอม',
    'languages.kn': 'ดา',
    'languages.ko': 'ภาษาเกาหลี',
    'languages.lo': 'ภาษาลาว',
    'languages.ml': 'มาลายาลัม',
    'languages.mr': 'ฐี',
    'languages.ms': 'ชาวมาเลเซีย',
    'languages.my': 'ภาษาพม่า',
    'languages.ne': 'เนปาลภาษา',
    'languages.plt': 'มาดากัสการ์ภาษา',
    'languages.pt-br': 'โปรตุเกส (บราซิล) ภาษา',
    'languages.ru': 'ภาษารัสเซีย',
    'languages.sw': 'ภาษาสวาฮิลี',
    'languages.ta': 'ภาษาทมิฬ',
    'languages.th': 'ภาษาไทย',
    'languages.tl': 'ตากาล็อก',
    'languages.te': 'Telegu ภาษา',
    'languages.vi': 'ภาษาเวียดนาม',
    'languages.zh-hans': 'ภาษาจีน (ดั้งเดิม)',
    'languages.zh-hant': 'ภาษาจีน (ดั้งเดิม)',
    'header.home': 'บ้าน',
    'header.help': 'ช่วยด้วย',
    'header.project': 'โครงการ',
    'header.contact': 'ติดต่อ',
    'help.contactSupport': 'กรุณาส่ง email มาที่ help@youtranslate.bible เพื่อขอความช่วยเหลือ',
    'home.ytbProvides': 'YouTranslate.Bible ได้อย่างอิสระให้:',
    'home.easyToUseTool': 'ที่ง่ายต่อการใช้เครื่องมือการแปลออนไลน์',
    'home.originalTextHelp': 'ช่วยให้เข้าใจข้อความของพระคัมภีร์ในภาษาเดิม',
    'home.referencesAndResources': 'ทรัพยากรการอ้างอิงและการฝึกอบรม',
    'home.computerAssistance': 'state-of-the-Art เทคโนโลยีคอมพิวเตอร์เพื่อช่วยแปล',
    'profile.userProfile': 'ประวัติผู้ใช้',
    'profile.language': 'ภาษา',
    switchDirection: 'สวิทช์ทิศทางข้อความ',
    'profile.update': 'ปรับปรุง',
    'profile.languageWarning':
      'Google Translate ถูกใช้ในการแปลส่วนติดต่อผู้ใช้เป็นภาษาอื่นที่ไม่ใช่ภาษาอังกฤษชั่วคราว YTB ​​มีการควบคุมความถูกต้องหรือความน่าเชื่อถือของการแปลไม่มี ทุกคนที่อาศัยอยู่ในฉลากแปลและคำอธิบายที่ได้รับจาก Google Translate ไม่ให้ความเสี่ยงของตนเอง ถ้าใครมีความสนใจในการเป็นอาสาสมัครเพื่อให้การแปลที่ถูกต้องและเชื่อถือได้ของอินเตอร์เฟซผู้ใช้เป็นภาษาอื่นที่ไม่ใช่ภาษาอังกฤษโปรดคลิกที่ตัวเลือกการติดต่อที่จะส่งอีเมลไปที่ GBI',
    'usernav.profile': 'ข้อมูลส่วนตัว',
    'usernav.logout': 'ออกจากระบบ',
    'usernav.logout.modalTitle': 'ออกจากระบบของ YouTranslate.Bible',
    'usernav.logout.modalText':
      'คุณแน่ใจว่าคุณต้องการที่จะออกจากระบบ? ทำงานไม่ได้บันทึกทั้งหมดจะหายไป',
    cancel: 'ยกเลิก',
    delete: 'ลบ',
    create: 'สร้าง',
    update: 'ปรับปรุง',
    edit: 'แก้ไข',
    save: 'บันทึก',
    add: 'เพิ่ม',
    translate: 'แปลภาษา',
    'more.options': 'ตัวเลือกเพิ่มเติม',
    'project.create.title': 'โครงการแปลพระคัมภีร์',
    'project.create.list': 'รายชื่อโครงการ',
    'project.list.deleteProject': 'ลบโครงการ?',
    'project.list.areYouSure': 'คุณแน่ใจหรือไม่ว่าต้องการลบโครงการนี้หรือไม่',
    'project.list.workWillBeDeleted': 'โครงการและการทำงานใด ๆ ที่เกี่ยวข้องจะถูกลบ',
    'project.list.completion': 'แล้วเสร็จ:',
    'project.list.createdDate': 'วันที่สร้าง:',
    'project.list.beginningDate': 'เริ่มต้นวันที่:',
    'project.list.targetCompletionDate': 'เป้าหมายแล้วเสร็จวันที่:',
    'project.list.description': 'รายละเอียด:',
    'project.edit': 'แก้ไขโครงการ',
    'project.list.translateBible': 'แปลพระคัมภีร์',
    'project.list.empty': 'รายชื่อโครงการการแปลของคุณว่างเปล่า',
    'project.list.myProjects': 'โครงการของพระคัมภีร์แปล',
    'project.list.newProject': 'โครงการใหม่',
    'project.create.createNew': 'สร้างโครงการใหม่',
    'project.namePlaceholder': 'ใส่ชื่อโครงการ',
    'project.descriptionPlaceholder': 'ใส่รายละเอียดโครงการ',
    'project.name': 'ชื่อโครงการ',
    'project.beginningDate': 'เริ่มต้นวันที่',
    'project.targetCompletionDate': 'เป้าหมายวันที่เสร็จสมบูรณ์',
    'project.description': 'ลักษณะ',
    'project.isRTL': 'แปลเป็นขวาไปซ้าย',
    'project.manage-users': 'จัดการแปล',
    'project.new-user': 'แปลใหม่',
    'project.add-user': 'เพิ่มแปล',
    'project.users': 'แปลโครงการ',
    'project.read-only': 'อ่านเท่านั้น',
    'project.read&write': 'อ่านเขียน',
    'project.owner': 'เจ้าของ',
    'project.translator': 'นักแปล',
    'project.message.no-such-user': 'ไม่มีแปลดังกล่าวอยู่',
    'project.message.user-added': 'นักแปลเพิ่มเรียบร้อยแล้ว',
    'project.message.user-deleted': 'นักแปลลบออก',
    'project.message.only-owner-can-manage-users': 'เจ้าของโครงการเท่านั้นที่สามารถจัดการผู้ใช้',
    'project.shared': 'โครงการความร่วมมือ',
    'general.delete-user': 'แปลลบ',
    'general.edit-user': 'แก้ไขผู้ใช้',
    'general.name': 'ชื่อ',
    'general.email': 'E-mail',
    'general.permissions': 'สิทธิ์',
    'general.role': 'บทบาท',
    signin: 'เข้าสู่ระบบ',
    'signin.emailPlaceholder': 'ที่อยู่อีเมล',
    'signin.passwordPlaceholder': 'รหัสผ่าน',
    'signin.forgotPassword': 'ลืมรหัสผ่านหรือไม่?',
    'signin.facebook': 'เข้าสู่ระบบด้วย Facebook',
    'signin.google': 'เข้าสู่ระบบกับ Google',
    'signin.dontHaveAccount': 'ไม่ได้มีบัญชีอยู่แล้ว?',
    'signin.createOne': 'สร้างหนึ่ง',
    'signup.join': 'เข้าร่วม YouTranslate.Bible',
    'signup.createAccountPrompt': 'สร้างบัญชีฟรีของคุณจะเริ่มต้น',
    'signup.createAccount': 'สร้างบัญชี',
    'signup.emailPlaceholder': 'ที่อยู่อีเมล',
    'signup.passwordPlaceholder': 'รหัสผ่าน',
    'passwordReset.title': 'รีเซ็ตรหัสผ่าน',
    'passwordReset.prompt': 'ขออีเมลที่มีคำแนะนำเพื่อรีเซ็ตรหัสผ่านของคุณ',
    'passwordReset.emailPlaceholder': 'ที่อยู่อีเมล',
    'passwordReset.requestButtonLabel': 'ขอใช้บริการอีเมล์',
    'passwordReset.success': 'ได้ส่งอีเมล.',
    'passwordReset.error': 'เกิดข้อผิดพลาด.',
    'translation.project': 'โครงการ:',
    'translation.backToProjectList': 'กลับไปยังรายการโครงการ',
    'translation.book': 'หนังสือ:',
    'translation.chapter': 'บท:',
    'translation.prompt': 'คุณแน่ใจหรือไม่ว่าต้องการออกจากบทนี้? ทำงานไม่ได้บันทึกทั้งหมดจะหายไป',
    'translation.prev': 'ก่อนหน้า',
    'translation.next': 'ต่อไป',
    'translation.previousChapter': 'บทก่อนหน้า',
    'translation.nextChapter': 'บทต่อไป',
    'navigator.book': 'หนังสือ:',
    'navigator.chapter': 'บท:',
    'navigator.oldTestament': 'พันธสัญญาเดิม',
    'navigator.newTestament': 'พันธสัญญาใหม่',
    'navigator.selectBook': 'เลือกหนังสือ',
    'navigator.search': 'ค้นหา',
    'navigator.changeBook': 'หนังสือเปลี่ยน',
    'navigator.changeChapter': 'เปลี่ยนบท',
    Genesis: 'แหล่งกำเนิด',
    Exodus: 'พระธรรม',
    Leviticus: 'เลวีนิติ',
    Numbers: 'เบอร์',
    Deuteronomy: 'เฉลยธรรมบัญญัติ',
    Joshua: 'โจชัว',
    Judges: 'ผู้พิพากษา',
    Ruth: 'ความสงสาร',
    '1 Samuel': '1 ซามูเอล',
    '2 Samuel': '2 ซามูเอล',
    '1 Kings': '1 พงศ์กษัตริย์',
    '2 Kings': '2 พงศ์กษัตริย์',
    '1 Chronicles': '1 พงศาวดาร',
    '2 Chronicles': '2 พงศาวดาร',
    Ezra: 'เอสรา',
    Nehemiah: 'เนหะมีย์',
    Esther: 'เอสเธอร์',
    Job: 'งาน',
    Psalms: 'เพลงสดุดี',
    Proverbs: 'สุภาษิต',
    Ecclesiastes: 'ปัญญาจารย์',
    'Song of Songs': 'เพลงเพลง',
    Isaiah: 'อิสยาห์',
    Jeremiah: 'เยเรมีย์',
    Lamentations: 'คร่ำครวญ',
    Ezekiel: 'เอเสเคียล',
    Daniel: 'แดเนียล',
    Hosea: 'โฮเชยา',
    Joel: 'โจเอล',
    Amos: 'เอมัส',
    Obadiah: 'โอบาดีห์',
    Jonah: 'โยนาห์',
    Micah: 'มีคาห์',
    Nahum: 'นาฮูม',
    Habakkuk: 'ฮาบากุก',
    Zephaniah: 'เศฟันยาห์',
    Haggai: 'ฮักกัย',
    Zechariah: 'เศคาริยา',
    Malachi: 'มาลาคี',
    Matthew: 'แมทธิว',
    Mark: 'เครื่องหมาย',
    Luke: 'ลุค',
    John: 'จอห์น',
    Acts: 'การกระทำ',
    Romans: 'ชาวโรมัน',
    '1 Corinthians': '1 โครินธ์',
    '2 Corinthians': '2 โครินธ์',
    Galatians: 'กาลาเทีย',
    Ephesians: 'เอเฟซัส',
    Philippians: 'ฟิลิป',
    Colossians: 'โคโลสี',
    '1 Thessalonians': '1 เธสะโลนิ',
    '2 Thessalonians': '2 สะโลนิกา',
    '1 Timothy': '1 ทิโมธี',
    '2 Timothy': '2 ทิโมธี',
    Titus: 'ติตัส',
    Philemon: 'ฟิเล',
    Hebrews: 'ฮีบรู',
    James: 'เจมส์',
    '1 Peter': '1 เปโตร',
    '2 Peter': '2 เปโตร',
    '1 John': '1 ยอห์',
    '2 John': '2 จอห์น',
    '3 John': '3 จอห์น',
    Jude: 'จูด',
    Revelation: 'การเปิดเผย',
    'resources.prompt':
      'เพิ่มทรัพยากรในพระคัมภีร์ไบเบิลสำหรับความเข้าใจเพิ่มเติมลงในทางที่คุณกำลังจะแปล',
    'resources.add': 'เพิ่มทรัพยากรในพระคัมภีร์ไบเบิล',
    Manuscript: 'ต้นฉบับ',
    Morphology: 'สัณฐานวิทยา',
    Lexicon: 'พจนานุกรม',
    'Syntactic Tree': 'วากยสัมพันธ์ต้นไม้',
    'Go to project list': 'ไปที่รายการโครงการ',
    'Project not found': 'โครงการไม่พบหรือสิทธิ์ไม่เพียงพอ',
    'error.referenceDoesNotExistForResource': 'นี้แปลพระคัมภีร์ไม่ได้มีเนื้อหาสำหรับทางเลือก',
    'references.addAnother': 'เพิ่มการแปลอีก',
    'references.referencePrompt': 'เพิ่มการแปลพระคัมภีร์อื่น ๆ สำหรับข้อมูลเชิงลึกเพิ่มเติม',
    'references.add': 'เพิ่มการแปลพระคัมภีร์',
    'references.licensing': 'ข้อมูลใบอนุญาต',
    'references.licensingEmpty': 'ยังไม่มีข้อมูลการออกใบอนุญาตก็พบว่าสำหรับรายการนี้',
    'translation.complete': 'สมบูรณ์แปล',
    Alignment: 'การวางแนว',
    Share: 'หุ้น',
    'Your Translation': 'การแปลของคุณ',
    'Enter your translation': 'ใส่การแปลของคุณ',
    OK: 'ตกลง',
    'error.verseTranslationEmpty': 'คุณไม่สามารถออกจากเนื้อหาการแปลที่ว่างเปล่า',
    'translation.hide.completed': 'ซ่อนเสร็จสมบูรณ์',
    alignment: 'การวางแนว',
    'alignment.verify': 'ตรวจสอบและใกล้',
    'alignment.close': 'ปิดการตรวจสอบโดยไม่ต้อง',
    'alignment.backToEditor': 'กลับไปแก้ไข',
    'alignment.error': 'บางอย่างผิดพลาด. หากยังคงมีปัญหาโปรดติดต่อทีม YouTranslate.Bible',
    'alignment.add': 'เพิ่มลิ้งค์',
    'alignment.delete': 'ลบลิงก์',
    'alignment.clear': 'เลือกที่ชัดเจน',
    'alignment.reverse': 'จอแสดงผลแบบย้อนกลับ',
    'alignment.removeOrClear': 'นำลิงก์ที่เลือกหรือตัวเลือกที่ชัดเจน',
    'alignment.addOrRemove': 'สร้างการเชื่อมโยงใหม่หรือลบหนึ่งก่อนหน้านี้',
    'alignment.selectAndAdd': 'เลือกกลุ่มที่สอดคล้องกับการเชื่อมโยง',
    'error.mustBeSignedToMakeChanges': 'คุณต้องลงนามในการเปลี่ยนแปลงให้',
    'error.mustBeSignedToTranslateBible': 'คุณต้องลงนามในการแปลพระคัมภีร์',
    checking: 'การตรวจสอบ',
    'verseEditor.unexpectedError': 'ข้อผิดพลาดที่ไม่คาดคิดได้เกิดขึ้น.',
    verses: 'โดย Verse',
    words: 'ด้วยคำพูด',
    'leftPanel.name': 'ข้อความเดิมของพระคัมภีร์',
    'rightPanel.name': 'คำของพระคัมภีร์',
    'bottomPanel.name': 'ทรัพยากรสำหรับพระคัมภีร์',
    revisionHistory: 'ประวัติการแก้ไข',
    'syntax.adv': 'คำวิเศษณ์ปรับปรุง',
    'syntax.adjp': 'วลีคำคุณศัพท์',
    'syntax.advp': 'วลีคำวิเศษณ์',
    'syntax.cl': 'ประโยค',
    'syntax.conj': 'การเชื่อม',
    'syntax.cjp': 'การเชื่อม',
    'syntax.det': 'มุ่งมั่น',
    'syntax.ijp': 'คำอุทาน',
    'syntax.nump': 'วลีเชิงตัวเลข',
    'syntax.np': 'นามวลี',
    'syntax.o': 'วัตถุโดยตรง',
    'syntax.o2': 'วัตถุที่สอง',
    'syntax.oc': 'ส่วนประกอบวัตถุ',
    'syntax.om': 'วัตถุเครื่องหมาย',
    'syntax.io': 'วัตถุทางอ้อม',
    'syntax.p': 'ที่ไม่ใช่คำพูดคำกริยา',
    'syntax.pp': 'บุพบทวลี',
    'syntax.prep': 'บุพบท',
    'syntax.ptcl': 'อนุภาค',
    'syntax.rel': 'อนุภาคญาติ',
    'syntax.s': 'เรื่อง',
    'syntax.v': 'คำกริยา',
    'syntax.vc': 'การเชื่อมโยงคำกริยา',
    'syntax.adjp.description': 'วลีที่ปรับเปลี่ยนคำนามหรือคำสรรพนาม',
    'syntax.adv.description':
      'ปรับเปลี่ยนคำกริยามักจะเพิ่มเติมการกำหนดลักษณะที่เวลาสถานที่ความถี่ศึกษาระดับปริญญา ฯลฯ ...',
    'syntax.advp.description':
      'วลีปรับเปลี่ยนคำกริยามักจะเพิ่มเติมการกำหนดลักษณะที่เวลาสถานที่ความถี่ศึกษาระดับปริญญา ฯลฯ',
    'syntax.cl.description': 'กลุ่มของคำที่ทำให้การยืนยันเกี่ยวกับเรื่อง',
    'syntax.conj.description': 'เชื่อมต่อคำวลีและคำสั่ง',
    'syntax.cjp.description': 'เชื่อมต่อคำวลีและคำสั่ง',
    'syntax.det.description': 'ระบุ, ระบุหรือประเมินนามวลี',
    'syntax.ijp.description': 'เสียงอุทานหรือแสดงออกอย่างฉับพลันของอารมณ์ความรู้สึก',
    'syntax.nump.description': 'วลีที่อธิบายปริมาณตัวเลข',
    'syntax.np.description': 'วลีที่มีคำนามเป็นหัวหน้าหรือฟังก์ชั่นที่เป็นคำนาม',
    'syntax.o.description': 'สิ่งที่ได้รับผลกระทบโดยตรงที่เกิดหรือผลิตโดยการกระทำของคำกริยา',
    'syntax.o2.description': 'สิ่งที่ได้รับผลกระทบโดยตรงที่เกิดหรือผลิตโดยการกระทำของคำกริยา',
    'syntax.oc.description': 'ทำให้การยืนยันเกี่ยวกับวัตถุโดยตรง',
    'syntax.om.description': 'อนุภาคที่แนะนำวัตถุโดยตรง',
    'syntax.io.description':
      'วัตถุที่ได้รับผลกระทบทางอ้อมโดยการกระทำมักผู้รับการกระทำของคำกริยาที่',
    'syntax.p.description':
      'ระบุหรืออธิบายเรื่องที่มักจะ ( แต่ไม่เสมอ) เกิดขึ้นพร้อมกับการเชื่อมโยงคำกริยา',
    'syntax.pp.description': 'วลีแก้ไขประกอบด้วยคำบุพบทและวัตถุ',
    'syntax.prep.description':
      'แสดงให้เห็นความสัมพันธ์ของวัตถุของคำบุพบทกับคำอื่น ๆ ที่มักจะแสดงให้เห็นสถานที่หรือเวลา',
    'syntax.ptcl.description':
      'หนึ่งในสมาชิกจากกลุ่มที่มีความหลากหลายของคำฟังก์ชั่นที่ไม่ได้อยู่ในส่วนใดของผันเรียนไวยากรณ์คำ',
    'syntax.rel.description': 'อนุภาคที่แนะนำประโยคญาติ',
    'syntax.s.description': 'ส่วนหนึ่งของข้อที่เหลือของประโยคที่ทำให้การยืนยันเกี่ยวกับ',
    'syntax.v.description':
      'ระบุสิ่งที่เกี่ยวกับเรื่องของประโยคและอาจแสดงการกระทำเหตุการณ์หรือสภาพ',
    'syntax.vc.description': 'เชื่อมโยงเรื่องของประโยคหนึ่งที่จะเติมเต็มเรื่อง',
    'general.close': 'ปิด',
    'general.action': 'หนังบู๊',
    'general.select': 'เลือก...',
    'general.back': 'กลับ',
    'revisionHistory.noData': 'ไม่มีประวัติการแก้ไขสำหรับข้อนี้',
    'revisionHistory.translation': 'การแปล',
    'revisionHistory.lastChanged': 'การเปลี่ยนแปลงล่าสุด',
    'sourceText.view': 'คลิกเพื่อดูข้อความต้นฉบับ',
    'sourceText.viewStructure': 'คลิกเพื่อดูโครงสร้างแหล่งที่มาของข้อความ',
    'error.projectIdIsRequired': 'โครงการ ID เป็นสิ่งจำเป็น',
    'error.searchTermIsRequired': 'คำค้นหาที่ไม่สามารถว่างเปล่า',
    'error.bookIdIsRequired': 'รหัสหนังสือเป็นสิ่งจำเป็นในการจัดเก็บชื่อหนังสือ',
    'error.bookNameCannotBeEmpty': 'ชื่อหนังสือต้องไม่ว่างเปล่า',
    'error.signInIsRequiredToAccessProject': 'คุณต้องลงชื่อเข้าใช้เพื่อเข้าถึงโครงการของคุณ',
    error: 'ความผิดพลาด',
    'error.doNotHavePermissionAccessProject': 'คุณไม่ได้รับอนุญาตให้เข้าถึงโครงการนี้',
    'error.unauthorized': 'การเข้าถึงไม่ได้รับอนุญาตหรือโทเค็นการเข้าถึงที่ไม่ถูกต้อง',
    'error.tokenExpired': 'โทเค็นของคุณหมดอายุ กรุณาเข้าสู่ระบบอีกครั้ง',
    'error.invalidApiUrl': 'URL API ไม่ถูกต้อง',
    'error.badRequestInvalidJson': 'คำขอที่ไม่ดี JSON ไม่ถูกต้อง',
    'error.windowIsNotDefined': 'หน้าต่างไม่ได้กำหนดไว้',
    'error.unexpectedError': 'ข้อผิดพลาดที่ไม่คาดคิดได้เกิดขึ้น. โปรดปิดหน้าต่างนี้และลองอีกครั้ง',
    'error.invalidDataType': 'ชนิดของข้อมูลที่ไม่ถูกต้อง',
    'export.data': 'ข้อมูลการส่งออก',
    more: 'มากกว่า',
    'export.to.rtf': 'ส่งออกไปยัง RTF',
    'export.to.usfm': 'ส่งออกไปยัง USFM',
    'export.titlePreparingData': 'เรากำลังเตรียมข้อมูลการแปลพระคัมภีร์ของคุณ',
    'export.doNotClose':
      'กระบวนการนี้อาจใช้เวลาสักครู่กรุณาอย่าปิดหน้าต่างนี้และคุณจะได้รับแจ้งเมื่อมันพร้อมที่จะดาวน์โหลด',
    'export.desc': 'คุณสามารถส่งออกและดาวน์โหลดข้อมูลการแปลของคุณโดยการคลิกที่ปุ่มด้านล่าง',
    'export.titleOfReady2Download': 'ข้อมูลการแปลพระคัมภีร์ของคุณพร้อม',
    'export.textOfReady2Download':
      'กรุณาคลิกที่ปุ่มดาวน์โหลดด้านล่างเพื่อดาวน์โหลดแปลพระคัมภีร์ของคุณ',
    'export.statistics': 'สถิติ',
    'export.book': 'ชื่อหนังสือ',
    'export.translatedVerses': 'โองการแปล',
    'export.exportedVerses': 'โองการส่งออก',
    'export.howDoUWant2Export': 'คุณจะทำอย่างไรต้องการส่งออกข้อมูล?',
    'export.whichBooks': 'ท่านใดที่หนังสือ (s) ต้องการที่จะส่งออก?',
    'export.byBooks': 'โดยหนังสือ',
    'export.byChapters': 'โดยบท',
    'export.byVerses': 'โดยโองการ',
    'export.booksInOt': 'พันธสัญญาเดิม',
    'export.booksInNt': 'พันธสัญญาใหม่',
    'export.booksInBible': 'ทั้งพระคัมภีร์',
    'export.booksInNone': 'ไม่มี',
    'export.selectRangeOfChapters': 'ช่วงของบท',
    'export.selectRangeOfVerses': 'ช่วงข้อพระคัมภีร์',
    'export.selectChapter': 'เลือกบท',
    'export.exportTypeIsRequired': 'คุณยังไม่ได้เลือกเกี่ยวกับวิธีการที่คุณต้องการจะส่งออกข้อมูล?',
    'export.haveNotYetSelectedBooks': 'คุณยังไม่ได้เลือกหนังสือเล่มใด',
    'error.selectABook': 'คุณต้องเลือกหนังสือ',
    'error.invalidBookId': 'ID หนังสือไม่ถูกต้อง',
    'error.selectAChapter': 'คุณต้องเลือกบท',
    'error.invalidChapterRange': 'คุณต้องเลือกช่วงบทที่ถูกต้อง',
    'error.invalidVerseRange': 'คุณต้องเลือกช่วงกลอนที่ถูกต้อง',
    'error.invalidExportType': 'ประเภทการส่งออกไม่ถูกต้อง',
    startover: 'เริ่มต้นใหม่',
    download: 'ดาวน์โหลด',
    'download.all': 'ดาวน์โหลดไฟล์ทั้งหมด',
    'contact.header': 'ติดต่อเรา',
    'contact.returnAddress': 'ย้อนกลับที่อยู่',
    'contact.returnAddressPlaceholder': 'ที่เราสามารถติดต่อคุณได้อย่างไร',
    'contact.subject': 'เรื่อง',
    'contact.subjectPlaceholder': 'สิ่งใดที่คุณต้องการความช่วยเหลือ?',
    'contact.message': 'ข่าวสาร',
    'contact.messagePlaceholder': 'วิธีที่เราสามารถช่วย?',
    'contact.sendMessage': 'ส่งข้อความ',
    'contact.success': 'ข้อความถูกส่งเรียบร้อยแล้ว',
    'contact.failure': 'มีปัญหาในการส่งข้อความนี้ กรุณาติดต่อ help@globalbibleinitiative.org',
    selectInterlinear: 'คลิ๊กเพื่อเปลี่ยนข้อความระหว่างงบรรทัด',
    'warning.screenTooSmall':
      'โปรดเปลี่ยนไปใช้ความละเอียดหน้าจอที่สูงขึ้น (ขั้นต่ำ 1024px กว้าง) หรือโปรดขยายหน้าต่างเบราว์เซอร์ของคุณ',
    'label.dictionary': 'สอดคล้องค้นหา',
    'dict.search.placeholder': 'ป้อนคำ (s)',
    'tooltip.manuscript': 'ต้นฉบับ',
    'tooltip.strongsx': 'จำนวนที่แข็งแกร่งของ',
    'tooltip.translation': 'การแปล',
    'tooltip.english': 'อังกฤษ',
    'title.howToSearch': 'วิธีการใช้ค้นหาสอดคล้อง',
    'desc.howToSearch':
      'ป้อนคำ (s) มาจากภาษากรีกหรือภาษาฮิบรูคำ (s) จากการแปลของคุณหรือจำนวนที่แข็งแกร่ง (s) หรือคลิกที่กรีกหรือภาษาฮิบรูคำในแผงของ "การแปลของคุณ" หรือมุมมองที่จัดสำหรับตัวเลือกการค้นหา',
    'dict.noDataToDisplay': 'ไม่มีข้อมูลที่ตรงกับเกณฑ์การค้นหาของคุณ',
    'title.searchTermTooMany': 'คุณได้ป้อนคำหลักมากเกินไป',
    'desc.searchTermTooMany': 'คุณสามารถค้นหาได้ถึง 8 คำหลักในแต่ละครั้ง',
    'title.searchDictError': 'ข้อผิดพลาดที่ไม่คาดคิดได้เกิดขึ้น.',
    'desc.searchDictError': 'โปรดลองอีกครั้งในภายหลังหรือติดต่อฝ่ายสนับสนุนลูกค้า',
    'alignment.saveAndEditAlignment': 'ข้อความบันทึกและจัดใหม่',
    'alignment.reviewAlignment': 'ทบทวนการจัดข้อความ',
    'dict.searchLemma': 'คลิกเพื่อค้นหาคำเดิม',
    'dict.searchTranslation': 'คลิกเพื่อค้นหาคำแปล',
    'dict.searchStrongsX': 'คลิ๊กเพื่อค้นหาหมายเลขของที่แข็งแกร่ง',
    'dict.showMenu': 'คลิกเพื่อแสดงข้อความเต็มของเหตุการณ์ที่เกิดขึ้นในบริบท',
    'dict.hideMenu': 'คลิกเพื่อซ่อนข้อความเต็มรูปแบบของเหตุการณ์ที่เกิดขึ้นในบริบท',
  },
  tl: {
    'languages.en': 'Ingles',
    'languages.am': 'Amharic',
    'languages.bn': 'Bengali Wika',
    'languages.ceb': 'Cebuano Wika',
    'languages.de': 'German Wika',
    'languages.es-419': 'Spanish Wika',
    'languages.fr': 'Pranses Wika',
    'languages.gu': 'Gujarati',
    'languages.hi': 'Hindi',
    'languages.hr': 'Croatian Wika',
    'languages.hu': 'Hungarian Wika',
    'languages.id': 'Indonesian Wika',
    'languages.km': 'Khmer',
    'languages.kn': 'Kannada',
    'languages.ko': 'Wikang Koreano',
    'languages.lo': 'Laotian Wika',
    'languages.ml': 'Malayalam',
    'languages.mr': 'Marathi',
    'languages.ms': 'Malaysian',
    'languages.my': 'Burmese Wika',
    'languages.ne': 'Nepali Wika',
    'languages.plt': 'Malagasy Wika',
    'languages.pt-br': 'Portuges (Brazil) Wika',
    'languages.ru': 'Russian Wika',
    'languages.sw': 'Swahili',
    'languages.ta': 'Tamil Wika',
    'languages.th': 'Thai Wika',
    'languages.tl': 'Tagalog',
    'languages.te': 'Telegu Wika',
    'languages.vi': 'Wikang Vietnamese',
    'languages.zh-hans': 'Chinese Language (Pinapayak)',
    'languages.zh-hant': 'Chinese Language (Tradisyunal)',
    'header.home': 'bahay',
    'header.help': 'Tulong',
    'header.project': 'proyekto',
    'header.contact': 'Makipag-ugnay sa',
    'help.contactSupport':
      'Mangyaring mag-email sa amin sa help@youtranslate.bible para sa tulong.',
    'home.ytbProvides': 'YouTranslate.Bible malayang nagbibigay ng:',
    'home.easyToUseTool': 'Ang isang madaling-gamitin na online na kasangkapan translation',
    'home.originalTextHelp': 'Tulong upang maunawaan ang teksto ng Bibliya sa orihinal na mga wika',
    'home.referencesAndResources': 'Reference at pagsasanay mapagkukunan',
    'home.computerAssistance':
      'State-of-the-art na computer na teknolohiya upang tulungan ang mga tagapagsalin',
    'profile.userProfile': 'Profile ng gumagamit',
    'profile.language': 'wika',
    switchDirection: 'Lumipat Text Direksyon',
    'profile.update': 'I-update',
    'profile.languageWarning':
      'Google Translate ay ginamit upang isalin ang user interface sa wikang maliban sa Ingles pansamantala. YTB ​​ay walang kontrol sa katumpakan o pagiging maaasahan ng pagsasalin. Kahit sino ay umaasa sa mga isinalin na mga etiketa at mga paliwanag na nakuha mula sa Google Translate nagagawa ito sa kanyang sariling peligro. Kung sinuman ay interesado sa volunteering upang magbigay ng mas tumpak at maaasahang translation ng user interface sa mga wikang maliban sa Ingles, mangyaring mag-click sa opsyon Makipag-ugnay sa upang magpadala ng isang email sa GBI.',
    'usernav.profile': 'profile',
    'usernav.logout': 'Mag-logout',
    'usernav.logout.modalTitle': 'Mag-logout sa YouTranslate.Bible',
    'usernav.logout.modalText':
      'Sigurado ka bang gusto mong mag-logout? Ang lahat ng hindi na-save na trabaho ay mawawala.',
    cancel: 'kanselahin',
    delete: 'Delete',
    create: 'lumikha',
    update: 'I-update',
    edit: 'I-edit',
    save: 'I-save ang',
    add: 'magdagdag',
    translate: 'Isalin',
    'more.options': 'Higit pang mga opsyon',
    'project.create.title': 'Bible Translation Project',
    'project.create.list': 'listahan Project',
    'project.list.deleteProject': 'Burahin Project?',
    'project.list.areYouSure': 'Sigurado ka bang gusto mong tanggalin ang proyektong ito?',
    'project.list.workWillBeDeleted': 'Ang proyekto at anumang trabaho na nauugnay ay tatanggalin.',
    'project.list.completion': 'pagkumpleto:',
    'project.list.createdDate': 'Nilikha Petsa:',
    'project.list.beginningDate': 'Simula ng petsa:',
    'project.list.targetCompletionDate': 'Takdang pasahan:',
    'project.list.description': 'Paglalarawan:',
    'project.edit': 'Edit Project',
    'project.list.translateBible': 'Isalin sa Bibliya',
    'project.list.empty': 'Ang iyong listahan translation project ay walang laman.',
    'project.list.myProjects': 'My Bible Translation Proyekto',
    'project.list.newProject': 'Bagong proyekto',
    'project.create.createNew': 'Lumikha ng isang bagong Project',
    'project.namePlaceholder': 'Ipasok ang pangalan ng proyekto',
    'project.descriptionPlaceholder': 'Ipasok ang paglalarawan ng proyekto',
    'project.name': 'Pangalan ng proyekto',
    'project.beginningDate': 'simula ng Petsa',
    'project.targetCompletionDate': 'takdang pasahan',
    'project.description': 'paglalarawan',
    'project.isRTL': 'Translation ay kanan-papuntang-kaliwa',
    'project.manage-users': 'pamahalaan ang Mga Tagapagsalin',
    'project.new-user': 'New tagasalin',
    'project.add-user': 'add tagasalin',
    'project.users': 'tagasalin Project',
    'project.read-only': 'Basahin lamang',
    'project.read&write': 'Basa sulat',
    'project.owner': 'may-ari',
    'project.translator': 'tagasalin',
    'project.message.no-such-user': 'Walang ganitong tagasalin umiiral',
    'project.message.user-added': 'Tagasalin matagumpay na naidagdag',
    'project.message.user-deleted': 'dahil Translator.',
    'project.message.only-owner-can-manage-users':
      'Tanging may-ari ng proyekto ay maaaring pamahalaan ang mga user',
    'project.shared': 'collaborative proyekto',
    'general.delete-user': 'Delete tagasalin',
    'general.edit-user': 'edit ang user',
    'general.name': 'pangalan',
    'general.email': 'E-mail',
    'general.permissions': 'Pahintulot',
    'general.role': 'papel',
    signin: 'Mag-sign in',
    'signin.emailPlaceholder': 'email address',
    'signin.passwordPlaceholder': 'password',
    'signin.forgotPassword': 'Nakalimutan ang Iyong Password?',
    'signin.facebook': 'Mag-sign in gamit ang Facebook',
    'signin.google': 'Mag-sign in sa Google',
    'signin.dontHaveAccount': 'Huwag magkaroon ng isang account?',
    'signin.createOne': 'Gumawa ng isa',
    'signup.join': 'Sumali YouTranslate.Bible',
    'signup.createAccountPrompt': 'Lumikha ng iyong libreng account upang magsimula.',
    'signup.createAccount': 'Gumawa ng Account',
    'signup.emailPlaceholder': 'email address',
    'signup.passwordPlaceholder': 'password',
    'passwordReset.title': 'password reset',
    'passwordReset.prompt':
      'Humiling ng isang email na may mga tagubilin upang i-reset ang iyong password.',
    'passwordReset.emailPlaceholder': 'email Address',
    'passwordReset.requestButtonLabel': 'Humiling ng Email',
    'passwordReset.success': 'Email ay naipadala na.',
    'passwordReset.error': 'May nangyaring pagakamali.',
    'translation.project': 'PROJECT:',
    'translation.backToProjectList': 'Bumalik sa listahan ng mga proyekto',
    'translation.book': 'Aklat:',
    'translation.chapter': 'Chapter:',
    'translation.prompt':
      'Sigurado ka bang gusto mong umalis sa kabanatang ito? Ang lahat ng hindi na-save na trabaho ay mawawala.',
    'translation.prev': 'Prev',
    'translation.next': 'susunod',
    'translation.previousChapter': 'Previous chapter',
    'translation.nextChapter': 'Sunod na kabanata',
    'navigator.book': 'Aklat:',
    'navigator.chapter': 'Chapter:',
    'navigator.oldTestament': 'Lumang Tipan',
    'navigator.newTestament': 'Bagong Tipan',
    'navigator.selectBook': 'Pumili ng isang aklat',
    'navigator.search': 'Hanapan',
    'navigator.changeBook': 'Baguhin aklat',
    'navigator.changeChapter': 'Baguhin ang kabanatang',
    Genesis: 'simula',
    Exodus: 'pag-aalisan',
    Leviticus: 'Levitico',
    Numbers: 'Numero',
    Deuteronomy: 'Dyuteronomi',
    Joshua: 'Joshua',
    Judges: 'Mga hukom',
    Ruth: 'awa',
    '1 Samuel': '1 Samuel',
    '2 Samuel': '2 Samuel',
    '1 Kings': '1 Mga Hari',
    '2 Kings': '2 Mga Hari',
    '1 Chronicles': '1 Mga Cronica',
    '2 Chronicles': '2 Mga Cronica',
    Ezra: 'Ezra',
    Nehemiah: 'Nehemias',
    Esther: 'Esther',
    Job: 'trabaho',
    Psalms: 'Mga Awit',
    Proverbs: 'Kawikaan',
    Ecclesiastes: 'Ang Mangangaral',
    'Song of Songs': 'Awit ng mga Awit',
    Isaiah: 'Isaya',
    Jeremiah: 'Jeremiah',
    Lamentations: 'pagpipighati',
    Ezekiel: 'Ezekiel',
    Daniel: 'Daniel',
    Hosea: 'Hosea',
    Joel: 'Joel',
    Amos: 'Amos',
    Obadiah: 'Obadiah',
    Jonah: 'Jonas',
    Micah: 'Micah',
    Nahum: 'Nahum',
    Habakkuk: 'Habacuc',
    Zephaniah: 'Zephaniah',
    Haggai: 'Haggai',
    Zechariah: 'Zacarias',
    Malachi: 'Malakias',
    Matthew: 'Matthew',
    Mark: 'marka',
    Luke: 'Luke',
    John: 'John',
    Acts: 'Mga Gawa',
    Romans: 'Roma',
    '1 Corinthians': '1 Mga Taga-Corinto',
    '2 Corinthians': '2 Mga Taga-Corinto',
    Galatians: 'Mga Taga-Galacia',
    Ephesians: 'Mga Taga-Efeso',
    Philippians: 'Filipos',
    Colossians: 'Mga Taga-Colosas',
    '1 Thessalonians': '1 Tesalonica',
    '2 Thessalonians': '2 Mga Taga-Tesalonica',
    '1 Timothy': '1 Timoteo',
    '2 Timothy': '2 Timoteo',
    Titus: 'Tito',
    Philemon: 'Philemon',
    Hebrews: 'Hebreo',
    James: 'James',
    '1 Peter': '1 Pedro',
    '2 Peter': '2 Pedro',
    '1 John': '1 Juan',
    '2 John': '2 John',
    '3 John': '3 John',
    Jude: 'Jude',
    Revelation: 'paghahayag',
    'resources.prompt':
      'Magdagdag ng mga mapagkukunan Bibliya para sa karagdagang pananaw sa mga talata na iyong isinasalin.',
    'resources.add': 'Magdagdag ng isang Bibliya na mapagkukunan',
    Manuscript: 'manuskrito',
    Morphology: 'morpolohiya',
    Lexicon: 'leksikon',
    'Syntactic Tree': 'sintaktik Tree',
    'Go to project list': 'Pumunta sa listahan ng mga proyekto',
    'Project not found': 'Project ay hindi nahanap o hindi sapat na mga pahintulot.',
    'error.referenceDoesNotExistForResource':
      'Ito translation Biblia ay hindi naglalaman ng nilalaman para sa mga napiling mga talata.',
    'references.addAnother': 'Magdagdag ng isa pang translation',
    'references.referencePrompt':
      'Magdagdag ng iba pang mga pagsasalin ng Bibliya para sa karagdagang pananaw.',
    'references.add': 'Magdagdag ng isang salin ng Bibliya',
    'references.licensing': 'Paglilisensya Impormasyon',
    'references.licensingEmpty':
      'Walang paglilisensya ng impormasyon ay natagpuan para sa item na ito.',
    'translation.complete': 'translation kumpleto',
    Alignment: 'pagkakahanay',
    Share: 'magbahagi',
    'Your Translation': 'Ang iyong Translation',
    'Enter your translation': 'Ipasok ang iyong pagsasalin',
    OK: 'OK',
    'error.verseTranslationEmpty':
      'Hindi ka maaaring umalis ang nilalaman translation alisan ng laman.',
    'translation.hide.completed': 'Itago ang nakumpleto',
    alignment: 'pagkakahanay',
    'alignment.verify': 'I-verify at isara.',
    'alignment.close': 'Isara walang Pag-verify',
    'alignment.backToEditor': 'Bumalik sa Editor',
    'alignment.error':
      'May nangyaring mali. Kung ang nagpatuloy problema, mangyaring makipag-ugnay sa YouTranslate.Bible team.',
    'alignment.add': 'Magdagdag ng Link',
    'alignment.delete': 'alisin ang Link',
    'alignment.clear': 'Limasin ang Pagpili',
    'alignment.reverse': 'reverse Display',
    'alignment.removeOrClear': 'Alisin ang napiling link o malinaw na pagpili.',
    'alignment.addOrRemove': 'Lumikha ng isang bagong link o magtanggal ng isang nakaraan.',
    'alignment.selectAndAdd': 'Piliin kaukulang mga segment sa link na ito.',
    'error.mustBeSignedToMakeChanges': 'Kailangang naka-sign in ka sa make pagbabago.',
    'error.mustBeSignedToTranslateBible': 'Kailangang naka-sign in upang isalin ang Bibliya.',
    checking: 'Sinusuri',
    'verseEditor.unexpectedError': 'Isang hindi inaasahang error na naganap.',
    verses: 'Sa pamamagitan ng Verse',
    words: 'Sa pamamagitan ng Salita',
    'leftPanel.name': 'Orihinal na Teksto ng Bibliya',
    'rightPanel.name': 'Pagsasalin ng Bibliya',
    'bottomPanel.name': 'Mga mapagkukunan para sa Bibliya',
    revisionHistory: 'Kasaysayan ng rebisyon',
    'syntax.adv': 'pang-abay na pang-modify',
    'syntax.adjp': 'adjectival Parirala',
    'syntax.advp': 'pang-abay Parirala',
    'syntax.cl': 'sugnay',
    'syntax.conj': 'kasabay',
    'syntax.cjp': 'kasabay',
    'syntax.det': 'Determiner',
    'syntax.ijp': 'pandamdam',
    'syntax.nump': 'de-numerong Parirala',
    'syntax.np': 'pangngalan Parirala',
    'syntax.o': 'akusatibo',
    'syntax.o2': 'ikalawang Bagay',
    'syntax.oc': 'bagay Makadagdag',
    'syntax.om': 'bagay Marker',
    'syntax.io': 'Hindi direktang bagay',
    'syntax.p': 'Non-pandiwang Tambalan',
    'syntax.pp': 'preposisyonal Parirala',
    'syntax.prep': 'pang-ukol',
    'syntax.ptcl': 'maliit na butil',
    'syntax.rel': 'kamag-anak Particle',
    'syntax.s': 'paksa',
    'syntax.v': 'pandiwa',
    'syntax.vc': 'Pag-link ng pandiwa',
    'syntax.adjp.description': 'Parirala na nagbabago sa isang pangngalan o panghalip',
    'syntax.adv.description':
      'Binabago ang pandiwa, karaniwang iba pang mga tumutukoy na paraan, oras, lugar, kadalasan, degree, etc ...',
    'syntax.advp.description':
      'Parirala pagbabago ng pandiwa, karaniwang iba pang mga tumutukoy na paraan, oras, lugar, kadalasan, degree, atbp',
    'syntax.cl.description':
      'Ang isang grupo ng mga salita na ginagawang isang badya tungkol sa paksa.',
    'syntax.conj.description': 'Nag-uugnay sa mga salita, parirala, at mga clauses.',
    'syntax.cjp.description': 'Nag-uugnay sa mga salita, parirala, at mga clauses.',
    'syntax.det.description': 'Tinutukoy, kinikilala, o quantifies isang pangngalan parirala.',
    'syntax.ijp.description': 'Tandang o biglaang pagpapahayag ng damdamin.',
    'syntax.nump.description': 'Pariralang naglalarawan sa de-numerong dami',
    'syntax.np.description':
      'Isang parirala na may pangngalan bilang pinuno nito o na function bilang isang pangngalan.',
    'syntax.o.description':
      'Na kung saan ay direktang apektado, sanhi, o nagawa sa pamamagitan ng mga aksyon ng pandiwa.',
    'syntax.o2.description':
      'Na kung saan ay direktang apektado, sanhi, o nagawa sa pamamagitan ng mga aksyon ng pandiwa.',
    'syntax.oc.description': 'Gagawa ng isang badya tungkol sa Direct Object.',
    'syntax.om.description': 'Particle na introduces isang direktang object.',
    'syntax.io.description':
      'Bagay na di-tuwirang apektado ng isang aksyon, madalas na ang tatanggap ng mga aksyon ng pandiwa.',
    'syntax.p.description':
      'Kinikilala o naglalarawan sa paksa, madalas (ngunit hindi palaging) nangyayari kasama ng pandiwang tagapag-ugnay.',
    'syntax.pp.description':
      'Binabago ang pariralang binubuo ng isang pang-ukol at ang kanyang object.',
    'syntax.prep.description':
      'Ipinapahiwatig ng relasyon ng mga bagay ng pang-ukol sa iba pang mga salita, madalas na nagpapahiwatig kung saan o kailan.',
    'syntax.ptcl.description':
      "Isang kasapi mula sa iba't ibang grupo ng function na salita na hindi nabibilang sa alinman sa mga inflected pambalarila mga klase ng salita",
    'syntax.rel.description': 'Particle na nagpapakilala sa isang kamag-anak sugnay.',
    'syntax.s.description':
      'Ang bahagi ng sugnay na ang natitirang bahagi ng sugnay na gumagawa ng isang badya tungkol sa.',
    'syntax.v.description':
      'Nagpapahayag ng isang bagay tungkol sa mga paksa ng sugnay at maaaring ipahayag ang action, kaganapan, o kondisyon.',
    'syntax.vc.description': 'Link ang paksa ng isang sugnay sa isang paksa pampuno.',
    'general.close': 'malapit',
    'general.action': 'aksyon',
    'general.select': 'Piliin ang ...',
    'general.back': 'likod',
    'revisionHistory.noData': 'Walang kasaysayan ng rebisyon para sa mga taludtod na ito.',
    'revisionHistory.translation': 'pagsasalin',
    'revisionHistory.lastChanged': 'huling Binagong',
    'sourceText.view': 'I-click upang tingnan ang source text',
    'sourceText.viewStructure': 'I-click upang tingnan ang source text istraktura',
    'error.projectIdIsRequired': 'Project ID ay kinakailangan.',
    'error.searchTermIsRequired':
      'Terminong ginamit sa paghahanap ay hindi maaaring alisin ang laman.',
    'error.bookIdIsRequired': 'Book ID ay kinakailangan upang mag-imbak ng pangalan ng libro.',
    'error.bookNameCannotBeEmpty': 'Pangalan ng libro ay hindi maaaring iwanang blangko.',
    'error.signInIsRequiredToAccessProject':
      'Kailangang naka-sign in upang ma-access ang iyong proyekto.',
    error: 'pagkakamali',
    'error.doNotHavePermissionAccessProject':
      'Hindi kang pahintulot upang ma-access proyektong ito.',
    'error.unauthorized': 'Di-awtorisadong pag-access o hindi wastong pag-access token.',
    'error.tokenExpired': 'Ang iyong token ay napaso na. Mangyaring muling pag-login.',
    'error.invalidApiUrl': 'Di-wastong url API.',
    'error.badRequestInvalidJson': 'Bad kahilingan. Di-wastong JSON.',
    'error.windowIsNotDefined': 'Window ay hindi tinukoy.',
    'error.unexpectedError':
      'Isang hindi inaasahang error na naganap. Pakisara ang window at subukan muli.',
    'error.invalidDataType': 'Hindi wastong uri ng data.',
    'export.data': 'I-export ang Data',
    more: 'pa',
    'export.to.rtf': 'I-export sa RTF',
    'export.to.usfm': 'I-export sa USFM',
    'export.titlePreparingData': 'Kami ay paghahanda ng iyong data translation Bibliya.',
    'export.doNotClose':
      'Ang prosesong ito ay maaaring tumagal ng isang habang, mangyaring huwag isara ang window na ito at ikaw ay aabisuhan kapag ito ay handa na upang i-download.',
    'export.desc':
      'Maaari mong i-export at i-download ang iyong data pagsasalin sa pamamagitan ng pag-click pindutan sa ibaba.',
    'export.titleOfReady2Download': 'Ang iyong translation Bibliya na data ay handa na.',
    'export.textOfReady2Download':
      'Mangyaring i-click ang pag-download na pindutan sa ibaba upang i-download ang iyong pagsasalin Bibliya.',
    'export.statistics': 'Statistics',
    'export.book': 'pangalan ng Aklat',
    'export.translatedVerses': 'isinalin bersikulo',
    'export.exportedVerses': 'Nailipat na talata',
    'export.howDoUWant2Export': 'Paano mo gustong upang i-export ng data?',
    'export.whichBooks': 'Aling libro (s) ang gusto mong pag-export?',
    'export.byBooks': 'Sa pamamagitan ng mga libro',
    'export.byChapters': 'Sa pamamagitan ng mga kabanata',
    'export.byVerses': 'Sa pamamagitan ng mga talata',
    'export.booksInOt': 'Lumang Tipan',
    'export.booksInNt': 'Bagong Tipan',
    'export.booksInBible': 'buong Biblia',
    'export.booksInNone': 'wala',
    'export.selectRangeOfChapters': 'Hanay ng mga kabanata',
    'export.selectRangeOfVerses': 'Hanay ng mga bersikulo',
    'export.selectChapter': 'Pumili ng kabanata',
    'export.exportTypeIsRequired':
      'Hindi ka pa napiling tungkol sa kung paano mo gustong i-export ang data?',
    'export.haveNotYetSelectedBooks': 'Hindi ka pa pumipili ng anumang mga libro.',
    'error.selectABook': 'Kailangan mong pumili ng isang libro.',
    'error.invalidBookId': 'Di-wastong aklat ID.',
    'error.selectAChapter': 'Dapat kang pumili ng kabanata.',
    'error.invalidChapterRange': 'Dapat kang pumili ng wastong hanay kabanata.',
    'error.invalidVerseRange': 'Dapat kang pumili ng wastong hanay taludtod.',
    'error.invalidExportType': 'Hindi wastong uri ng pag-export.',
    startover: 'Magsimula muli',
    download: 'Download',
    'download.all': 'I-download ang lahat ng mga file',
    'contact.header': 'Makipag-ugnayan sa amin',
    'contact.returnAddress': 'tirahan',
    'contact.returnAddressPlaceholder': 'Saan kami maaaring maabot sa iyo?',
    'contact.subject': 'paksa',
    'contact.subjectPlaceholder': 'Saan mo kailangan ng tulong?',
    'contact.message': 'mensahe',
    'contact.messagePlaceholder': 'Paano kami makakatulong?',
    'contact.sendMessage': 'Magpadala ng Mensahe',
    'contact.success': 'Mensahe ay matagumpay na naipadala.',
    'contact.failure':
      'Nagkaroon ng problema sa pagpapadala ng mensaheng ito. Mangyaring makipag-ugnay help@globalbibleinitiative.org.',
    selectInterlinear: 'I-click upang baguhin ang interlinear text',
    'warning.screenTooSmall':
      'Mangyaring lumipat sa isang mas mataas na resolution ng screen (minimum 1024px lapad) o mangyaring lumawak ang iyong browser window.',
    'label.dictionary': 'Concordance Paghahanap',
    'dict.search.placeholder': 'Ipasok ang salita (s)',
    'tooltip.manuscript': 'manuskrito',
    'tooltip.strongsx': 'Number ni Strong',
    'tooltip.translation': 'pagsasalin',
    'tooltip.english': 'Ingles',
    'title.howToSearch': 'Paano gamitin ang concordance search',
    'desc.howToSearch':
      'Ipasok ang (mga) salita mula sa Griyego o Hebreo, salita (s) mula sa iyong pagsasalin, o Strong Number (s). O kaya i-click sa Greek o Hebrew salita sa panel ng "Ang iyong translation" o Alignment tanawin para sa mga pagpipilian sa paghahanap.',
    'dict.noDataToDisplay': 'Walang mga data na tumutugma sa iyong pamantayan sa paghahanap.',
    'title.searchTermTooMany': 'Nagpasok ka ng masyadong maraming mga keyword',
    'desc.searchTermTooMany': 'Maaari kang maghanap sa hanggang sa 8 mga keyword sa bawat oras.',
    'title.searchDictError': 'Isang hindi inaasahang error na naganap.',
    'desc.searchDictError':
      'Mangyaring subukan muli sa ibang pagkakataon o makipag-ugnay sa suporta sa customer',
    'alignment.saveAndEditAlignment': 'I-save at I-align Bagong Tekstong',
    'alignment.reviewAlignment': 'Review Text Alignment',
    'dict.searchLemma': 'I-click upang hanapin ang orihinal na salita',
    'dict.searchTranslation': 'I-click upang maghanap ng pagsasalin ng salita',
    'dict.searchStrongsX': 'I-click upang hanapin ang Strong ni number',
    'dict.showMenu': 'I-click upang ipakita ang buong teksto ng mga pangyayari sa konteksto.',
    'dict.hideMenu': 'I-click upang itago ang buong teksto ng mga pangyayari sa konteksto.',
  },
  te: {
    'languages.en': 'ఆంగ్ల',
    'languages.am': 'Amharic',
    'languages.bn': 'బెంగాలీ భాషా',
    'languages.ceb': 'సేబుఆనో భాషా',
    'languages.de': 'జర్మన్ భాష',
    'languages.es-419': 'స్పానిష్ భాష',
    'languages.fr': 'ఫ్రెంచ్ భాష',
    'languages.gu': 'gujarati',
    'languages.hi': 'హిందీ',
    'languages.hr': 'Croatian భాషా',
    'languages.hu': 'హంగేరియన్ భాషా',
    'languages.id': 'ఇండోనేషియన్ భాష',
    'languages.km': 'ఖ్మేర్',
    'languages.kn': 'కన్నడ',
    'languages.ko': 'కొరియన్ బాష',
    'languages.lo': 'లావోయేషన్ భాషా',
    'languages.ml': 'మలయాళం',
    'languages.mr': 'మరాఠీ',
    'languages.ms': 'మలేషియన్',
    'languages.my': 'బర్మీస్ భాషా',
    'languages.ne': 'నేపాలీ భాష',
    'languages.plt': 'మాలాగసి భాషా',
    'languages.pt-br': 'పోర్చుగీస్ (బ్రెజిల్) భాషా',
    'languages.ru': 'రష్యన్ భాష',
    'languages.sw': 'Swahili',
    'languages.ta': 'తమిళ భాష',
    'languages.th': 'థాయ్ భాష',
    'languages.tl': 'Tagalog',
    'languages.te': 'Telegu భాషా',
    'languages.vi': 'vietnamese భాషా',
    'languages.zh-hans': 'చైనీస్ భాష (సరళీకృత)',
    'languages.zh-hant': 'చైనీస్ భాష (సాంప్రదాయ)',
    'header.home': 'హోమ్',
    'header.help': 'సహాయం',
    'header.project': 'ప్రాజెక్ట్',
    'header.contact': 'సంప్రదించండి',
    'help.contactSupport': 'సహాయం కోసం help@youtranslate.bible వద్ద మాకు ఇమెయిల్ దయచేసి.',
    'home.ytbProvides': 'YouTranslate.Bible ఉచితంగా అందిస్తుంది:',
    'home.easyToUseTool': 'ఒక సులభమైన వాడేందుకు ఆన్లైన్ అనువాదం సాధనం',
    'home.originalTextHelp': 'దాని అసలు భాషలలో బైబిల్ టెక్స్ట్ అర్థం సహాయం',
    'home.referencesAndResources': 'సూచన మరియు శిక్షణ వనరులు',
    'home.computerAssistance': 'రాష్ట్ర ఆఫ్ ఆర్ట్ అనువాదకుల సహాయంగా కంప్యూటర్ సాంకేతిక',
    'profile.userProfile': 'వినియోగదారు వివరాలు',
    'profile.language': 'భాషా',
    switchDirection: 'స్విచ్ టెక్స్ట్ దిశ',
    'profile.update': 'నవీకరణ',
    'profile.languageWarning':
      'Google Translate తాత్కాలికంగా ఇంగ్లీష్ కంటే ఇతర భాషలలో యూజర్ ఇంటర్ఫేస్ అనువదించడానికి ఉపయోగించేవారు. YTB ​​అనువాద కచ్చితత్వం లేదా విశ్వసనీయత నియంత్రించలేరు ఉంది. అనువాదం లేబుల్స్ మరియు వివరణలు Google నుండి పొందిన ఆధారపడటం ఎవరైనా అనువదించు అతని లేదా ఆమె స్వంత పూచీతో అవుతున్నారు. ఎవరైనా యూజర్ ఇంటర్ఫేస్ యొక్క మరింత ఖచ్చితమైన మరియు నమ్మకమైన అనువాదం అందించడానికి ఇంగ్లీష్ కంటే ఇతర భాషలలో స్వయంసేవకంగా ఆసక్తి ఉంటే, కు GBI ఒక ఇమెయిల్ పంపండి సంప్రదించండి ఎంపికను క్లిక్ చేయండి.',
    'usernav.profile': 'ప్రొఫైల్',
    'usernav.logout': 'లాగౌట్',
    'usernav.logout.modalTitle': 'YouTranslate.Bible యొక్క లాగ్అవుట్',
    'usernav.logout.modalText':
      'మీరు ఖచ్చితంగా ఉన్నాయి లాగ్అవుట్ అనుకుంటున్నారా? అన్ని సేవ్ చేయని పనిని పోతాయి.',
    cancel: 'రద్దు చేయండి',
    delete: 'తొలగించు',
    create: 'సృష్టించు',
    update: 'నవీకరణ',
    edit: 'మార్చు',
    save: 'సేవ్',
    add: 'చేర్చు',
    translate: 'అనువదించు',
    'more.options': 'మరిన్ని ఎంపికలు',
    'project.create.title': 'బైబిల్ అనువాద ప్రాజెక్ట్',
    'project.create.list': 'ప్రాజెక్ట్ జాబితా',
    'project.list.deleteProject': 'ప్రాజెక్ట్ను తొలగించు?',
    'project.list.areYouSure': 'మీరు ఈ ప్రాజెక్ట్ తొలగించడానికి ఉండాలనుకుంటున్నారా?',
    'project.list.workWillBeDeleted': 'ప్రాజెక్ట్ మరియు సంబంధం ఏ పని తొలగించబడుతుంది.',
    'project.list.completion': 'పూర్తి:',
    'project.list.createdDate': 'రూపొందించబడింది తేదీ:',
    'project.list.beginningDate': 'ప్రారంభమై తేదీ:',
    'project.list.targetCompletionDate': 'టార్గెట్ పూర్తిచేసే తేదీ:',
    'project.list.description': 'వివరణ:',
    'project.edit': 'ప్రాజెక్ట్ని సవరించు',
    'project.list.translateBible': 'బైబిల్ అనువదించు',
    'project.list.empty': 'మీ అనువాదం ప్రాజెక్టు జాబితా ఖాళీగా ఉంది.',
    'project.list.myProjects': 'నా బైబిల్ అనువాద ప్రాజెక్ట్స్',
    'project.list.newProject': 'కొత్త ప్రాజెక్ట్',
    'project.create.createNew': 'ఒక కొత్త ప్రాజెక్ట్ సృష్టించు',
    'project.namePlaceholder': 'ప్రాజెక్ట్ పేరును నమోదు',
    'project.descriptionPlaceholder': 'ప్రాజెక్ట్ వివరణ ఎంటర్',
    'project.name': 'ప్రాజెక్ట్ పేరు',
    'project.beginningDate': 'ప్రారంభమై తేదీ',
    'project.targetCompletionDate': 'టార్గెట్ పూర్తిచేసే తేదీ',
    'project.description': 'వివరణ',
    'project.isRTL': 'అనువాద కుడి నుంచి ఎడమకు',
    'project.manage-users': 'అనువాదకుల నిర్వహించండి',
    'project.new-user': 'న్యూ అనువాదకుడు',
    'project.add-user': 'యాడ్ అనువాదకుడు',
    'project.users': 'ప్రాజెక్టు అనువాదకుల',
    'project.read-only': 'చదవడానికి మాత్రమే',
    'project.read&write': 'చదువు రాయి',
    'project.owner': 'యజమాని',
    'project.translator': 'అనువాదకుడు',
    'project.message.no-such-user': 'అటువంటి అనువాదకుడు ఉంది',
    'project.message.user-added': 'అనువాదకుడు విజయవంతంగా జోడించారు',
    'project.message.user-deleted': 'అనువాదకుడు తొలగించబడింది.',
    'project.message.only-owner-can-manage-users':
      'మాత్రమే ప్రాజెక్ట్ యజమాని వినియోగదారులు నిర్వహించవచ్చు',
    'project.shared': 'సహకార ప్రాజెక్ట్',
    'general.delete-user': 'తొలగించు అనువాదకుడు',
    'general.edit-user': 'యూజర్ మార్చు',
    'general.name': 'పేరు',
    'general.email': 'ఇ-మెయిల్',
    'general.permissions': 'అనుమతులు',
    'general.role': 'పాత్ర',
    signin: 'సైన్ ఇన్',
    'signin.emailPlaceholder': 'ఇమెయిల్ చిరునామా',
    'signin.passwordPlaceholder': 'పాస్వర్డ్',
    'signin.forgotPassword': 'మీ పాస్వర్డ్ మర్చిపోయారా?',
    'signin.facebook': 'ఫేస్బుక్ తో సైన్ ఇన్',
    'signin.google': 'Google తో సైన్ ఇన్',
    'signin.dontHaveAccount': 'ఒక ఖాతా లేదా?',
    'signin.createOne': 'ఒకటి సృష్టించు',
    'signup.join': 'YouTranslate.Bible చేరండి',
    'signup.createAccountPrompt': 'ప్రారంభించడానికి మీ ఉచిత ఖాతా సృష్టించు.',
    'signup.createAccount': 'ఖాతా సృష్టించు',
    'signup.emailPlaceholder': 'ఇమెయిల్ చిరునామా',
    'signup.passwordPlaceholder': 'పాస్వర్డ్',
    'passwordReset.title': 'పాస్వర్డ్ రీసెట్',
    'passwordReset.prompt': 'మీ పాస్వర్డ్ను రీసెట్ సూచనలతో కూడిన ఒక ఇమెయిల్ను అభ్యర్థన.',
    'passwordReset.emailPlaceholder': 'ఇమెయిల్ అడ్రస్',
    'passwordReset.requestButtonLabel': 'అభ్యర్థన ఇమెయిల్',
    'passwordReset.success': 'ఇమెయిల్ పంపబడింది.',
    'passwordReset.error': 'ఒక లోపము సంభవించినది.',
    'translation.project': 'PROJECT:',
    'translation.backToProjectList': 'ప్రాజెక్ట్ జాబితాకు తిరిగి',
    'translation.book': 'పుస్తకం:',
    'translation.chapter': 'అధ్యాయము:',
    'translation.prompt': 'మీరు ఈ అధ్యాయం వదిలి ఉండాలనుకుంటున్నారా? అన్ని సేవ్ చేయని పనిని పోతాయి.',
    'translation.prev': 'మునుపటి',
    'translation.next': 'తరువాత',
    'translation.previousChapter': 'మునుపటి అధ్యాయంలో',
    'translation.nextChapter': 'తదుపరి అధ్యాయం',
    'navigator.book': 'పుస్తకం:',
    'navigator.chapter': 'అధ్యాయము:',
    'navigator.oldTestament': 'పాత నిబంధన',
    'navigator.newTestament': 'కొత్త నిబంధన',
    'navigator.selectBook': 'ఒక పుస్తకం ఎంచుకోవడానికి',
    'navigator.search': 'వెతకండి',
    'navigator.changeBook': 'మార్చు పుస్తకం',
    'navigator.changeChapter': 'మార్చు అధ్యాయం',
    Genesis: 'ఆదికాండము',
    Exodus: 'ఎక్సోడస్',
    Leviticus: 'లెవిటికస్',
    Numbers: 'సంఖ్యలు',
    Deuteronomy: 'ద్వితీయోపదేశకాండము',
    Joshua: 'జాషువా',
    Judges: 'న్యాయమూర్తులు',
    Ruth: 'రూత్',
    '1 Samuel': '1 సమూయేలు',
    '2 Samuel': '2 సమూయేలు',
    '1 Kings': '1 రాజులు',
    '2 Kings': '2 రాజులు',
    '1 Chronicles': '1 దినవృత్తాంతములు',
    '2 Chronicles': '2 క్రానికల్స్',
    Ezra: 'ఎజ్రా',
    Nehemiah: 'నెహెమ్యా',
    Esther: 'ఎస్తేర్',
    Job: 'ఉద్యోగం',
    Psalms: 'కీర్తనలు',
    Proverbs: 'సామెతలు',
    Ecclesiastes: 'ప్రసంగి',
    'Song of Songs': 'సాంగ్ అఫ్ సాంగ్స్',
    Isaiah: 'యెషయా',
    Jeremiah: 'యిర్మీయా',
    Lamentations: 'విలాపవాక్యములు',
    Ezekiel: 'యెహెజ్కేలు',
    Daniel: 'డేనియల్',
    Hosea: 'హోషేయా',
    Joel: 'జోయెల్',
    Amos: 'అమోస్',
    Obadiah: 'ఓబద్యా',
    Jonah: 'జోనా',
    Micah: 'మీకా',
    Nahum: 'నహుం',
    Habakkuk: 'హబక్కూకు',
    Zephaniah: 'జెఫన్యా',
    Haggai: 'హగ్గయి',
    Zechariah: 'జెకర్యా',
    Malachi: 'మలాకీ',
    Matthew: 'మాథ్యూ',
    Mark: 'మార్క్',
    Luke: 'ల్యూక్',
    John: 'జాన్',
    Acts: 'చట్టాలు',
    Romans: 'రోమన్లు',
    '1 Corinthians': '1 కొరింథీయులకు',
    '2 Corinthians': '2 కొరింథీయులకు',
    Galatians: 'గలతీయులకు',
    Ephesians: 'ఎఫెసీయులకు',
    Philippians: 'ఫిలిప్పీయులకు',
    Colossians: 'Colossians',
    '1 Thessalonians': '1 Thessalonians',
    '2 Thessalonians': '2 Thessalonians',
    '1 Timothy': '1 తిమోతి',
    '2 Timothy': '2 తిమోతి',
    Titus: 'తీతుకు',
    Philemon: 'Philemon',
    Hebrews: 'హెబ్రీయులు',
    James: 'జేమ్స్',
    '1 Peter': '1 పేతురు',
    '2 Peter': '2 పేతురు',
    '1 John': '1 జాన్',
    '2 John': '2 యోహాను',
    '3 John': '3 యోహాను',
    Jude: 'జూడ్',
    Revelation: 'ప్రకటన',
    'resources.prompt':
      'మీరు అనువదిస్తున్న మార్గంలోకి అదనపు అంతర్దృష్టి కోసం బైబిల్ వనరులను జోడించవచ్చు.',
    'resources.add': 'గ్రంధముల వనరు జోడించు',
    Manuscript: 'మాన్యుస్క్రిప్ట్',
    Morphology: 'స్వరూప శాస్త్రం',
    Lexicon: 'లెక్సికాన్',
    'Syntactic Tree': 'వాక్యనిర్మాణ ట్రీ',
    'Go to project list': 'ప్రాజెక్ట్ జాబితాకు వెళ్ళండి',
    'Project not found': 'ప్రాజెక్టు దొరకలేదు లేదా సరిపోని అనుమతుల కాదు.',
    'error.referenceDoesNotExistForResource':
      'ఈ బైబిల్ అనువాద ఎంపిక ఆమోదానికి కంటెంట్ కలిగి లేదు.',
    'references.addAnother': 'మరో అనువాద జోడించండి',
    'references.referencePrompt': 'అదనపు అంతర్దృష్టి కోసం ఇతర బైబిలు అనువాదాలు జోడించవచ్చు.',
    'references.add': 'ఒక బైబిల్ అనువాద జోడించండి',
    'references.licensing': 'లైసెన్సింగ్ సమాచారం',
    'references.licensingEmpty': 'ఎటువంటి లైసెన్స్ సమాచారం ఈ అంశం కనుగొనబడలేదు.',
    'translation.complete': 'అనువాద పూర్తి',
    Alignment: 'అమరిక',
    Share: 'Share',
    'Your Translation': 'మీ అనువాదం',
    'Enter your translation': 'మీ అనువాదం ఎంటర్',
    OK: 'అలాగే',
    'error.verseTranslationEmpty': 'మీకు అనువాదం కంటెంట్ ఖాళీగా ఉంచకూడదు.',
    'translation.hide.completed': 'పూర్తి దాచు',
    alignment: 'అమరిక',
    'alignment.verify': 'ధృవీకరించి మూసివేయండి.',
    'alignment.close': 'ధృవీకరించకుండానే Close',
    'alignment.backToEditor': 'కు ఎడిటర్ తిరిగి',
    'alignment.error':
      'ఎక్కడో తేడ జరిగింది. సమస్య కొనసాగితే, దయచేసి YouTranslate.Bible జట్టు సంప్రదించండి.',
    'alignment.add': 'లింక్ ను జోడించు',
    'alignment.delete': 'లింక్ తొలగించు',
    'alignment.clear': 'క్లియర్ ఎన్నిక',
    'alignment.reverse': 'రివర్స్ డిస్ప్లే',
    'alignment.removeOrClear': 'ఎంపిక లింక్ లేదా స్పష్టమైన ఎంపిక తొలగించండి.',
    'alignment.addOrRemove': 'ఒక కొత్త లింక్ సృష్టించడానికి లేదా ఒక మునుపటి తొలగించండి.',
    'alignment.selectAndAdd': 'లింక్ సంబంధిత విభాగాలు ఎంచుకోండి.',
    'error.mustBeSignedToMakeChanges': 'మీరు చేసిన మార్పులు సైన్ ఇన్ చేయాలి.',
    'error.mustBeSignedToTranslateBible': 'మీరు బైబిల్ అనువదించడానికి సైన్ ఇన్ చెయ్యాలి.',
    checking: 'తనిఖీ చేస్తోంది',
    'verseEditor.unexpectedError': 'ఊహించని లోపం సంభవించింది.',
    verses: 'వెర్స్ ద్వారా',
    words: 'పద ద్వారా',
    'leftPanel.name': 'బైబిల్ యొక్క వాస్తవ వచనం',
    'rightPanel.name': 'బైబిల్ అనువాదాలు',
    'bottomPanel.name': 'బైబిల్ వనరుల',
    revisionHistory: 'కూర్పుల చరిత్ర',
    'syntax.adv': 'క్రియావిశేషణ సవరించే',
    'syntax.adjp': 'విశేషణ ఫ్రేజ్',
    'syntax.advp': 'క్రియా విశేషణం ఫ్రేజ్',
    'syntax.cl': 'ఉపవాక్య',
    'syntax.conj': 'సముచ్చయం',
    'syntax.cjp': 'సముచ్చయం',
    'syntax.det': 'Determiner',
    'syntax.ijp': 'Interjection',
    'syntax.nump': 'న్యూమరికల్ ఫ్రేజ్',
    'syntax.np': 'నామవాచక పదము',
    'syntax.o': 'ప్రత్యక్ష ఆబ్జెక్ట్',
    'syntax.o2': 'రెండవ ఆబ్జెక్ట్',
    'syntax.oc': 'ఆబ్జెక్ట్ కాంప్లిమెంట్',
    'syntax.om': 'ఆబ్జెక్ట్ మార్కర్',
    'syntax.io': 'పరోక్ష వస్తువు',
    'syntax.p': 'అశాబ్దిక అఖ్యాతం',
    'syntax.pp': 'విభక్తి ఫ్రేజ్',
    'syntax.prep': 'విభక్తి',
    'syntax.ptcl': 'కణ',
    'syntax.rel': 'సంబంధిత కణ',
    'syntax.s': 'Subject',
    'syntax.v': 'క్రియ',
    'syntax.vc': 'లింకింగ్ Verb',
    'syntax.adjp.description': 'ఒక నామవాచకం లేదా సర్వనామం మారుస్తుందని సరిపోలే',
    'syntax.adv.description':
      'మార్పు క్రియా, సాధారణంగా మరింత నిర్వచించు పద్ధతిలో, సమయం, స్థలం, ఫ్రీక్వెన్సీ, డిగ్రీ, etc ...',
    'syntax.advp.description':
      'పదబంధాన్ని క్రియా సవరించుట సాధారణంగా మరింత నిర్వచించు పద్ధతిలో, సమయం, స్థలం, ఫ్రీక్వెన్సీ, డిగ్రీ, మొదలైనవి',
    'syntax.cl.description': 'పదాల సమూహం విషయం గురించి ఒక ప్రకటన చేస్తుంది.',
    'syntax.conj.description': 'పదాలు, పదబంధాలు మరియు ఉపవాక్యాలు కలుపుతుంది.',
    'syntax.cjp.description': 'పదాలు, పదబంధాలు మరియు ఉపవాక్యాలు కలుపుతుంది.',
    'syntax.det.description': 'పేర్కొంటుంది, గుర్తిస్తుంది, లేదా నిర్ణయించవచ్చు నామవాచకం పదబంధం.',
    'syntax.ijp.description': 'ఆశ్చర్యార్థకం లేదా భావోద్వేగ ఆకస్మిక వ్యక్తీకరణ.',
    'syntax.nump.description': 'సంఖ్యా పరిమాణం వివరించే సరిపోలే',
    'syntax.np.description': 'దాని అధిపతిగా నామవాచకం లేక నామవాచకంగా ఆ పనులు చేసే ఒక పదబంధం.',
    'syntax.o.description':
      'ఆ నేరుగా ప్రభావితం కలుగుతుంది, లేదా ఒక క్రియ యొక్క చర్య ద్వారా ఉత్పత్తి.',
    'syntax.o2.description':
      'ఆ నేరుగా ప్రభావితం కలుగుతుంది, లేదా ఒక క్రియ యొక్క చర్య ద్వారా ఉత్పత్తి.',
    'syntax.oc.description': 'ప్రత్యక్ష ఆబ్జెక్ట్ గురించి ఒక ప్రకటన చేస్తుంది.',
    'syntax.om.description': 'ప్రత్యక్ష వస్తువు పరిచయం చేసే కణ.',
    'syntax.io.description':
      'పరోక్షముగా చర్య ద్వారా ప్రభావితం అని వస్తువు, ఒక క్రియ యొక్క చర్య యొక్క తరచుగా గ్రహీత.',
    'syntax.p.description':
      'గుర్తిస్తుంది లేదా విషయం, వివరిస్తుంది తరచూ (కానీ ఎల్లప్పుడూ) కలిసి ఒక లింక్ క్రియతో ఏర్పడుతుంది.',
    'syntax.pp.description': 'సవరించుట పదబంధం విభక్తి మరియు దాని వస్తువు కలిగి.',
    'syntax.prep.description':
      'తరచుగా లేదా మీరు సూచించే, ఇతర పదాలతో విభక్తి వస్తువు యొక్క సంబంధాన్ని సూచిస్తుంది.',
    'syntax.ptcl.description':
      'ఎలాంటి ప్రభావం వ్యాకరణ పదం తరగతులు ఏ చెందిన లేని ఫంక్షన్ పదాలను భిన్నత్వం నుండి ఒక సభ్యుడిని',
    'syntax.rel.description': 'సాపేక్ష నిబంధన పరిచయం చేసే కణ.',
    'syntax.s.description': 'నిబంధన యొక్క మిగిలిన గురించి ఒక ప్రకటన చేస్తుంది నిబంధన భాగంగా.',
    'syntax.v.description':
      'నిబంధన విషయం గురించి ఏదో చెపుతుంది వ్యక్తం చర్య, సంఘటన, లేదా పరిస్థితి.',
    'syntax.vc.description': 'ఒక విషయం పూరక ఒక నిబంధన విషయం లింకులు.',
    'general.close': 'దగ్గరగా',
    'general.action': 'యాక్షన్',
    'general.select': 'ఎంచుకోండి...',
    'general.back': 'తిరిగి',
    'revisionHistory.noData': 'ఈ పద్యం ఏ పునర్విమర్శ చరిత్ర ఉంది.',
    'revisionHistory.translation': 'అనువాదం',
    'revisionHistory.lastChanged': 'చివరి మార్చబడింది',
    'sourceText.view': 'మూలం టెక్స్ట్ వీక్షించడానికి క్లిక్',
    'sourceText.viewStructure': 'మూలం టెక్స్ట్ నిర్మాణాన్ని చూడటానికి క్లిక్ చేయండి',
    'error.projectIdIsRequired': 'ప్రాజెక్టు ID అవసరం.',
    'error.searchTermIsRequired': 'శోధన పదం ఖాళీ కాదు.',
    'error.bookIdIsRequired': 'బుక్ ID పుస్తకం పేరును నిల్వ అవసరం.',
    'error.bookNameCannotBeEmpty': 'బుక్ పేరు ఖాళీగా ఉండకూడదు.',
    'error.signInIsRequiredToAccessProject':
      'మీరు మీ ప్రాజెక్ట్ ప్రాప్తి చేయడానికి సైన్ ఇన్ చేయాలి.',
    error: 'లోపం',
    'error.doNotHavePermissionAccessProject': 'మీరు ఈ ప్రాజెక్ట్ యాక్సెస్ అనుమతి లేదు.',
    'error.unauthorized': 'అనధికార యాక్సెస్ లేదా చెల్లని ప్రాప్యత టోకెన్.',
    'error.tokenExpired': 'మీ టోకెన్ గడువు ముగిసింది. దయచేసి తిరిగి లాగిన్.',
    'error.invalidApiUrl': 'చెల్లని API url.',
    'error.badRequestInvalidJson': 'తప్పుడు విన్నపం. చెల్లని JSON.',
    'error.windowIsNotDefined': 'విండో నిర్వచించబడలేదు.',
    'error.unexpectedError':
      'ఊహించని లోపం సంభవించింది. దయచేసి ఈ విండోను మూసివేసి, మళ్ళీ ప్రయత్నించండి.',
    'error.invalidDataType': 'చెల్లని డేటా రకం.',
    'export.data': 'డేటాను ఎగుమతి',
    more: 'మరింత',
    'export.to.rtf': 'RTF ఎగుమతి',
    'export.to.usfm': 'కు USFM ఎగుమతి',
    'export.titlePreparingData': 'మేము మీ బైబిల్ అనువాద డేటా సన్నద్ధమవుతున్నట్లు.',
    'export.doNotClose':
      'ఈ ప్రక్రియ కొంత సమయం పట్టవచ్చు, కాని దగ్గరగా ఈ విండో దయచేసి మరియు డౌన్లోడ్ సిద్ధంగా ఉన్నప్పుడు మీరు తెలియజేయబడుతుంది.',
    'export.desc':
      'మీరు ఎగుమతి మరియు క్రింద బటన్లు క్లిక్ చేయడం ద్వారా మీ అనువాదం డేటా డౌన్లోడ్ చేసుకోవచ్చు.',
    'export.titleOfReady2Download': 'మీ బైబిల్ అనువాద డేటా సిద్ధంగా ఉంది.',
    'export.textOfReady2Download':
      'మీ బైబిల్ అనువాదం డౌన్లోడ్ క్రింద డౌన్లోడ్ బటన్ క్లిక్ చేయండి.',
    'export.statistics': 'గణాంకాలు',
    'export.book': 'బుక్ పేరు',
    'export.translatedVerses': 'అనువాదం శ్లోకాలు',
    'export.exportedVerses': 'ఎగుమతి శ్లోకాలు',
    'export.howDoUWant2Export': 'ఎలా మీరు డేటాను ఎగుమతి అనుకుంటున్నారు?',
    'export.whichBooks': 'ఏ పుస్తకం (లు) మీరు ఎగుమతి అనుకుంటున్నారు?',
    'export.byBooks': 'పుస్తకాలు ద్వారా',
    'export.byChapters': 'అధ్యాయాలు ద్వారా',
    'export.byVerses': 'పద్యాల ద్వారా',
    'export.booksInOt': 'పాత నిబంధన',
    'export.booksInNt': 'కొత్త నిబంధన',
    'export.booksInBible': 'మొత్తం బైబిల్',
    'export.booksInNone': 'గమనిక',
    'export.selectRangeOfChapters': 'అధ్యాయాలు రేంజ్',
    'export.selectRangeOfVerses': 'పద్యాల యొక్క రేంజ్',
    'export.selectChapter': 'ఒక అధ్యాయం ఎంచుకోండి',
    'export.exportTypeIsRequired':
      'ఎలా మీరు ఎగుమతి డేటా అనుకుంటున్నారు మీరు ఇంకా గురించి ఎంపికచేసుకోలేదు?',
    'export.haveNotYetSelectedBooks': 'మీరు ఇంకా ఏ పుస్తకం ఎంచుకోలేదు.',
    'error.selectABook': 'మీరు ఒక పుస్తకం ఎంచుకోవాలి.',
    'error.invalidBookId': 'చెల్లని పుస్తకాన్ని ID.',
    'error.selectAChapter': 'మీరు ఒక అధ్యాయం ఎంచుకోవాలి.',
    'error.invalidChapterRange': 'మీరు చెల్లుబాటు అయ్యే అధ్యాయంలో పరిధిని ఎంచుకోవాలి.',
    'error.invalidVerseRange': 'మీరు చెల్లుబాటు అయ్యే పద్యం పరిధిని ఎంచుకోవాలి.',
    'error.invalidExportType': 'చెల్లని ఎగుమతి రకం.',
    startover: 'మళ్లీ మొదలెట్టు',
    download: 'డౌన్లోడ్',
    'download.all': 'అన్ని ఫైళ్లను డౌన్లోడ్',
    'contact.header': 'మమ్మల్ని సంప్రదించండి',
    'contact.returnAddress': 'తిరిగి చిరునామా',
    'contact.returnAddressPlaceholder': 'మేము మీరు ఎక్కడ చేరతాయి?',
    'contact.subject': 'Subject',
    'contact.subjectPlaceholder': 'ఏం మీరు సహాయం అవసరం?',
    'contact.message': 'సందేశం',
    'contact.messagePlaceholder': 'మేము ఏ విధంగా సహయపడగలము?',
    'contact.sendMessage': 'సందేశము పంపుము',
    'contact.success': 'సందేశం విజయవంతంగా పంపబడింది.',
    'contact.failure':
      'ఈ సందేశాన్ని పంపడం ఒక సమస్య ఉంది. దయచేసి help@globalbibleinitiative.org సంప్రదించండి.',
    selectInterlinear: 'ఇంటెర్లీనియర్ టెక్స్ట్ మార్చడానికి క్లిక్ చేయండి',
    'warning.screenTooSmall':
      'అధిక స్క్రీన్ రిజల్యూషన్ (కనీస 1024px విస్తృత) మారడం లేదా మీ బ్రౌజర్ విండో పెంచడానికి దయచేసి.',
    'label.dictionary': 'సామరస్య శోధన',
    'dict.search.placeholder': 'పదం (లు) నమోదు',
    'tooltip.manuscript': 'మాన్యుస్క్రిప్ట్',
    'tooltip.strongsx': 'స్ట్రాంగ్స్ సంఖ్య',
    'tooltip.translation': 'అనువాదం',
    'tooltip.english': 'ఆంగ్ల',
    'title.howToSearch': 'సామరస్య శోధనను ఉపయోగించండి ఎలా',
    'desc.howToSearch':
      'మీ అనువాదం గ్రీకు లేదా హిబ్రూ పదం (లు), లేదా బలమైన సంఖ్య (లు) నుండి పదం (లు) నమోదు చేయండి. లేదా "మీ అనువాదం" లేదా శోధన ఎంపికలు కోసం సమలేఖనం వీక్షణలు ప్యానెల్లో గ్రీక్ లేదా హిబ్రూ పదాలు క్లిక్.',
    'dict.noDataToDisplay': 'సెర్చ్ క్రైటీరియాను సరిపోలిన ఏ అంశాలు ఉన్నాయి.',
    'title.searchTermTooMany': 'మీరు చాలా కీవర్డ్లు ప్రవేశించాయి',
    'desc.searchTermTooMany': 'మీరు 8 కీవర్డ్లు ప్రతిసారీ వరకు శోధించవచ్చు.',
    'title.searchDictError': 'ఊహించని లోపం సంభవించింది.',
    'desc.searchDictError': 'దయచేసి తర్వాత మళ్లీ ప్రయత్నించండి లేదా పరిచయం కస్టమర్ మద్దతు',
    'alignment.saveAndEditAlignment': 'సేవ్ మరియు సమలేఖనం క్రొత్త టెక్స్ట్',
    'alignment.reviewAlignment': 'సమీక్ష టెక్స్ట్ సమలేఖనం',
    'dict.searchLemma': 'అసలు పదం కోసం శోధించడానికి క్లిక్ చేయండి',
    'dict.searchTranslation': 'అనువాదం పదం కోసం శోధించడానికి క్లిక్ చేయండి',
    'dict.searchStrongsX': 'బలమైన యొక్క సంఖ్య శోధించడానికి ఇక్కడ క్లిక్ చేయండి',
    'dict.showMenu': 'సందర్భంలో ఏర్పడడం పూర్తి టెక్స్ట్ చూపించడానికి క్లిక్ చేయండి.',
    'dict.hideMenu': 'సందర్భంలో ఏర్పడడం పూర్తి టెక్స్ట్ దాచడానికి క్లిక్ చేయండి.',
  },
  vi: {
    'languages.en': 'Tiếng Anh',
    'languages.am': 'Amharic',
    'languages.bn': 'Tiếng Bengal',
    'languages.ceb': 'Cebuano Ngôn ngữ',
    'languages.de': 'Tiếng Đức',
    'languages.es-419': 'Tiếng Tây Ban Nha',
    'languages.fr': 'Ngôn ngữ Pháp',
    'languages.gu': 'Gujarati',
    'languages.hi': 'Tiếng Hin-ddi',
    'languages.hr': 'Croatia Ngôn ngữ',
    'languages.hu': 'Ngôn ngữ hungarian',
    'languages.id': 'Ngôn ngữ Indonesia',
    'languages.km': 'Khmer',
    'languages.kn': 'kannada',
    'languages.ko': 'Ngôn ngữ Hàn Quốc',
    'languages.lo': 'Ngôn ngữ Lào',
    'languages.ml': 'Malayalam',
    'languages.mr': 'Marathi',
    'languages.ms': 'người Malaysia',
    'languages.my': 'Miến Điện Ngôn ngữ',
    'languages.ne': 'Nepali Ngôn ngữ',
    'languages.plt': 'Malagasy Ngôn ngữ',
    'languages.pt-br': 'Bồ Đào Nha (Brazil) Ngôn ngữ',
    'languages.ru': 'Ngôn ngữ Nga',
    'languages.sw': 'swahili',
    'languages.ta': 'Ngôn ngữ Tamil',
    'languages.th': 'Tiếng Thái Lan',
    'languages.tl': 'Tagalog',
    'languages.te': 'Telegu Ngôn ngữ',
    'languages.vi': 'Ngôn ngữ tiếng Việt',
    'languages.zh-hans': 'Trung Quốc Ngôn ngữ (Giản thể)',
    'languages.zh-hant': 'Trung Quốc Ngôn ngữ (Phồn thể)',
    'header.home': 'Trang Chủ',
    'header.help': 'Cứu giúp',
    'header.project': 'Dự án',
    'header.contact': 'Tiếp xúc',
    'help.contactSupport':
      'Vui lòng gửi email cho chúng tôi tại help@youtranslate.bible để được giúp đỡ.',
    'home.ytbProvides': 'YouTranslate.Bible tự do cung cấp:',
    'home.easyToUseTool': 'Một công cụ dịch thuật trực tuyến dễ sử dụng',
    'home.originalTextHelp':
      'Trợ giúp để hiểu được văn bản của Kinh Thánh trong ngôn ngữ ban đầu của nó',
    'home.referencesAndResources': 'nguồn tài liệu tham khảo và đào tạo',
    'home.computerAssistance': 'Nhà nước-of-the-art công nghệ máy tính để hỗ trợ dịch',
    'profile.userProfile': 'Thông tin người dùng',
    'profile.language': 'ngôn ngữ',
    switchDirection: 'Text Direction switch',
    'profile.update': 'cập nhật',
    'profile.languageWarning':
      'Google Translate đã được sử dụng để dịch các giao diện người dùng sang ngôn ngữ khác ngoài tiếng Anh tạm thời. YTB ​​không kiểm soát tính chính xác hay độ tin cậy của bản dịch. Bất cứ ai cũng dựa vào các nhãn dịch và giải thích được lấy từ Google Translate làm như vậy có nguy cơ của riêng mình. Nếu bất cứ ai quan tâm đến hoạt động tình nguyện để cung cấp dịch chính xác hơn và đáng tin cậy của giao diện người dùng sang ngôn ngữ khác ngoài tiếng Anh, bạn hãy click vào tùy chọn Liên hệ để gửi email đến GBI.',
    'usernav.profile': 'Hồ sơ',
    'usernav.logout': 'Đăng xuất',
    'usernav.logout.modalTitle': 'Thoát của YouTranslate.Bible',
    'usernav.logout.modalText':
      'Bạn có chắc chắn bạn muốn thoát? Tất cả công việc chưa được lưu sẽ bị mất.',
    cancel: 'hủy bỏ',
    delete: 'Xóa bỏ',
    create: 'Tạo nên',
    update: 'cập nhật',
    edit: 'Biên tập',
    save: 'Tiết kiệm',
    add: 'Thêm vào',
    translate: 'Phiên dịch',
    'more.options': 'Lựa chọn khác',
    'project.create.title': 'Dự án dịch Kinh Thánh',
    'project.create.list': 'danh mục dự án',
    'project.list.deleteProject': 'Xóa dự án?',
    'project.list.areYouSure': 'Bạn có chắc chắn muốn xóa dự án này?',
    'project.list.workWillBeDeleted': 'dự án và bất kỳ công việc liên quan sẽ bị xóa.',
    'project.list.completion': 'Hoàn thành:',
    'project.list.createdDate': 'Ngày tạo ra:',
    'project.list.beginningDate': 'Ngày bắt đầu:',
    'project.list.targetCompletionDate': 'Ngày Target hoàn thành:',
    'project.list.description': 'Sự miêu tả:',
    'project.edit': 'chỉnh sửa dự án',
    'project.list.translateBible': 'Dịch Kinh Thánh',
    'project.list.empty': 'Danh mục dự án dịch của bạn đang trống.',
    'project.list.myProjects': 'My Kinh Thánh Các dự án dịch',
    'project.list.newProject': 'Dự án mới',
    'project.create.createNew': 'Tạo một dự án mới',
    'project.namePlaceholder': 'Nhập tên dự án',
    'project.descriptionPlaceholder': 'Nhập mô tả dự án',
    'project.name': 'Tên dự án',
    'project.beginningDate': 'Ngày bắt đầu',
    'project.targetCompletionDate': 'Mục tiêu hoàn thành ngày',
    'project.description': 'Sự miêu tả',
    'project.isRTL': 'Dịch là từ phải sang trái',
    'project.manage-users': 'quản lý dịch',
    'project.new-user': 'dịch mới',
    'project.add-user': 'Thêm phiên dịch',
    'project.users': 'dịch dự án',
    'project.read-only': 'Chỉ đọc',
    'project.read&write': 'Đọc viết',
    'project.owner': 'Chủ nhân',
    'project.translator': 'thông dịch viên',
    'project.message.no-such-user': 'Không dịch như vậy tồn tại',
    'project.message.user-added': 'Translator thêm thành công',
    'project.message.user-deleted': 'Translator gỡ bỏ.',
    'project.message.only-owner-can-manage-users': 'Chủ dự án chỉ có thể quản lý người dùng',
    'project.shared': 'dự án hợp tác',
    'general.delete-user': 'dịch Xóa',
    'general.edit-user': 'Người dùng biên tập',
    'general.name': 'Tên',
    'general.email': 'E-mail',
    'general.permissions': 'Quyền',
    'general.role': 'Vai trò',
    signin: 'Đăng nhập',
    'signin.emailPlaceholder': 'Địa chỉ email',
    'signin.passwordPlaceholder': 'Mật khẩu',
    'signin.forgotPassword': 'Quên mật khẩu?',
    'signin.facebook': 'Đăng nhập bằng facebook',
    'signin.google': 'Đăng nhập bằng Google',
    'signin.dontHaveAccount': 'Không có tài khoản rồi?',
    'signin.createOne': 'Tạo một cái',
    'signup.join': 'Tham gia YouTranslate.Bible',
    'signup.createAccountPrompt': 'Tạo tài khoản miễn phí của bạn để bắt đầu.',
    'signup.createAccount': 'Tạo tài khoản',
    'signup.emailPlaceholder': 'Địa chỉ email',
    'signup.passwordPlaceholder': 'Mật khẩu',
    'passwordReset.title': 'Đặt lại mật khẩu',
    'passwordReset.prompt': 'Yêu cầu một email với hướng dẫn để đặt lại mật khẩu của bạn.',
    'passwordReset.emailPlaceholder': 'Địa chỉ email',
    'passwordReset.requestButtonLabel': 'Yêu cầu Email',
    'passwordReset.success': 'Email đã được gửi.',
    'passwordReset.error': 'Một lỗi đã xảy ra.',
    'translation.project': 'DỰ ÁN:',
    'translation.backToProjectList': 'Quay lại danh sách dự án',
    'translation.book': 'Sách:',
    'translation.chapter': 'Chương:',
    'translation.prompt':
      'Bạn có chắc chắn muốn rời khỏi chương này? Tất cả công việc chưa được lưu sẽ bị mất.',
    'translation.prev': 'Trước',
    'translation.next': 'Kế tiếp',
    'translation.previousChapter': 'Chương trước',
    'translation.nextChapter': 'Chương tiếp theo',
    'navigator.book': 'Sách:',
    'navigator.chapter': 'Chương:',
    'navigator.oldTestament': 'Di chúc cũ',
    'navigator.newTestament': 'Di chúc mới',
    'navigator.selectBook': 'Chọn một cuốn sách',
    'navigator.search': 'Tìm kiếm',
    'navigator.changeBook': 'thay đổi cuốn sách',
    'navigator.changeChapter': 'thay đổi chương',
    Genesis: 'sự bắt đầu',
    Exodus: 'Cuộc di cư',
    Leviticus: 'Leviticus',
    Numbers: 'số',
    Deuteronomy: 'Đệ nhị luật',
    Joshua: 'Joshua',
    Judges: 'Ban giám khảo',
    Ruth: 'lòng trắc ẩn',
    '1 Samuel': '1 Samuel',
    '2 Samuel': '2 Samuel',
    '1 Kings': '1 Kings',
    '2 Kings': '2 Kings',
    '1 Chronicles': '1 Chronicles',
    '2 Chronicles': '2 Chronicles',
    Ezra: 'Ezra',
    Nehemiah: 'Nehemiah',
    Esther: 'Esther',
    Job: 'Việc làm',
    Psalms: 'Thánh Vịnh',
    Proverbs: 'Châm ngôn',
    Ecclesiastes: 'Giảng Viên',
    'Song of Songs': 'Song of Songs',
    Isaiah: 'Isaiah',
    Jeremiah: 'nhà tiên đoán yếm thế',
    Lamentations: 'sự phàn nàn',
    Ezekiel: 'Ezekiel',
    Daniel: 'Daniel',
    Hosea: 'Hosea',
    Joel: 'Joel',
    Amos: 'Amos',
    Obadiah: 'Obadiah',
    Jonah: 'anh chàng số đen',
    Micah: 'Micah',
    Nahum: 'Nahum',
    Habakkuk: 'Habacúc',
    Zephaniah: 'Zephaniah',
    Haggai: 'A-ghê',
    Zechariah: 'Zechariah',
    Malachi: 'Malachi',
    Matthew: 'Matthew',
    Mark: 'dấu',
    Luke: 'Luke',
    John: 'John',
    Acts: 'Hành vi',
    Romans: 'Người La Mã',
    '1 Corinthians': '1 Corinthians',
    '2 Corinthians': '2 Corinthians',
    Galatians: 'Gal',
    Ephesians: 'Êphêsô',
    Philippians: 'Phi-líp',
    Colossians: 'Côlôxê',
    '1 Thessalonians': '1 Têsalônica',
    '2 Thessalonians': '2 Têsalônica',
    '1 Timothy': '1 Timothy',
    '2 Timothy': '2 Timothy',
    Titus: 'Tít',
    Philemon: 'Philêmôn',
    Hebrews: 'Do Thái',
    James: 'James',
    '1 Peter': '1 Peter',
    '2 Peter': '2 Peter',
    '1 John': '1 John',
    '2 John': '2 John',
    '3 John': '3 John',
    Jude: 'Jude',
    Revelation: 'sự tiết lộ',
    'resources.prompt':
      'Thêm tài nguyên Kinh Thánh cho cái nhìn sâu sắc bổ sung vào những đoạn văn bạn muốn dịch.',
    'resources.add': 'Thêm một nguồn lực Kinh Thánh',
    Manuscript: 'bản viết tay',
    Morphology: 'hình thái học',
    Lexicon: 'từ điển',
    'Syntactic Tree': 'cú pháp Tree',
    'Go to project list': 'Tới danh sách dự án',
    'Project not found': 'Dự án không tìm thấy hoặc không đủ quyền.',
    'error.referenceDoesNotExistForResource':
      'dịch Kinh Thánh này không chứa nội dung cho việc thông qua lựa chọn.',
    'references.addAnother': 'Thêm dịch khác',
    'references.referencePrompt': 'Thêm bản dịch Kinh Thánh khác cho cái nhìn sâu sắc thêm.',
    'references.add': 'Thêm một bản dịch Kinh Thánh',
    'references.licensing': 'Thông tin cấp phép',
    'references.licensingEmpty': 'Không có thông tin cấp phép đã được tìm thấy cho mặt hàng này.',
    'translation.complete': 'dịch thuật hoàn thành',
    Alignment: 'sự liên kết',
    Share: 'Chia sẻ',
    'Your Translation': 'dịch của bạn',
    'Enter your translation': 'Nhập dịch của bạn',
    OK: 'đồng ý',
    'error.verseTranslationEmpty': 'Bạn không thể rời khỏi nội dung dịch rỗng.',
    'translation.hide.completed': 'Ẩn hoàn thành',
    alignment: 'sự liên kết',
    'alignment.verify': 'Xác minh và đóng lại.',
    'alignment.close': 'Đóng mà không Xác minh',
    'alignment.backToEditor': 'Về biên tập viên',
    'alignment.error':
      'Một cái gì đó đã đi sai. Nếu vẫn còn tồn tại vấn đề, xin vui lòng liên hệ với nhóm YouTranslate.Bible.',
    'alignment.add': 'Thêm liên kết',
    'alignment.delete': 'Remove Link',
    'alignment.clear': 'Xoá Lựa chọn',
    'alignment.reverse': 'Hiển thị ngược',
    'alignment.removeOrClear': 'Hủy bỏ các liên kết được lựa chọn hoặc lựa chọn rõ ràng.',
    'alignment.addOrRemove': 'Tạo một liên kết mới hoặc xóa một trước.',
    'alignment.selectAndAdd': 'Chọn phân khúc tương ứng với liên kết.',
    'error.mustBeSignedToMakeChanges': 'Bạn phải đăng nhập để thay đổi.',
    'error.mustBeSignedToTranslateBible': 'Bạn phải đăng nhập để dịch Kinh Thánh.',
    checking: 'kiểm tra',
    'verseEditor.unexpectedError': 'Một lỗi không mong muốn đã xảy ra.',
    verses: 'bởi Verse',
    words: 'bằng Lời',
    'leftPanel.name': 'Văn bản Gốc của Kinh Thánh',
    'rightPanel.name': 'Dịch của Kinh Thánh',
    'bottomPanel.name': 'Tài nguyên cho Kinh Thánh',
    revisionHistory: 'Lịch sử sửa đổi',
    'syntax.adv': 'Trạng từ bổ nghĩa',
    'syntax.adjp': 'cụm tính từ',
    'syntax.advp': 'cụm từ trạng từ',
    'syntax.cl': 'điều khoản',
    'syntax.conj': 'Kết hợp',
    'syntax.cjp': 'Kết hợp',
    'syntax.det': 'determiner',
    'syntax.ijp': 'thán từ',
    'syntax.nump': 'cụm từ số',
    'syntax.np': 'Cụm danh từ',
    'syntax.o': 'Đối tượng trực tiếp',
    'syntax.o2': 'Object thứ hai',
    'syntax.oc': 'Đối tượng bổ sung',
    'syntax.om': 'Object Marker',
    'syntax.io': 'Object gián tiếp',
    'syntax.p': 'Không bằng lời Predicate',
    'syntax.pp': 'cụm giới từ',
    'syntax.prep': 'Preposition',
    'syntax.ptcl': 'hạt',
    'syntax.rel': 'hạt tương đối',
    'syntax.s': 'Môn học',
    'syntax.v': 'động từ',
    'syntax.vc': 'Liên kết Động từ',
    'syntax.adjp.description': 'Cụm từ mà sửa đổi một danh từ hoặc đại từ',
    'syntax.adv.description':
      'Sửa động từ, xác định thường hơn nữa cách, thời gian, địa điểm, tần số, mức độ, vv ...',
    'syntax.advp.description':
      'Cụm từ thay đổi động từ, thường tiếp tục xác định cách thức, thời gian, địa điểm, tần số, mức độ, vv',
    'syntax.cl.description': 'Một nhóm các từ mà làm cho một sự khẳng định về chủ đề này.',
    'syntax.conj.description': 'Kết nối từ, cụm từ, và mệnh đề.',
    'syntax.cjp.description': 'Kết nối từ, cụm từ, và mệnh đề.',
    'syntax.det.description': 'Chỉ định, xác định, hoặc định lượng một cụm danh từ.',
    'syntax.ijp.description': 'Chấm than hoặc biểu hiện đột ngột của cảm xúc.',
    'syntax.nump.description': 'Cụm từ mô tả số lượng số',
    'syntax.np.description':
      'Một cụm từ mà có một danh từ làm Trưởng ban hoặc có chức năng như một danh từ.',
    'syntax.o.description':
      'Đó là ảnh hưởng trực tiếp, gây ra, hoặc sản xuất do tác động của một động từ.',
    'syntax.o2.description':
      'Đó là ảnh hưởng trực tiếp, gây ra, hoặc sản xuất do tác động của một động từ.',
    'syntax.oc.description': 'Làm cho một sự khẳng định về đối tượng trực tiếp.',
    'syntax.om.description': 'Hạt mà giới thiệu một đối tượng trực tiếp.',
    'syntax.io.description':
      'Đối tượng đó là gián tiếp bị ảnh hưởng bởi một hành động, thường là những người nhận hành động của một động từ.',
    'syntax.p.description':
      'Xác định hoặc mô tả các đối tượng, thường (nhưng không phải luôn luôn) xảy ra cùng với một động từ liên kết.',
    'syntax.pp.description': 'Sửa đổi cụm từ bao gồm một giới từ và đối tượng của nó.',
    'syntax.prep.description':
      'Chỉ ra mối quan hệ của đối tượng của giới từ với các từ khác, thường chỉ ra nơi hoặc khi nào.',
    'syntax.ptcl.description':
      'Một thành viên của nhóm đa dạng các từ chức năng mà không thuộc về bất kỳ của các lớp từ ngữ pháp inflected',
    'syntax.rel.description': 'Hạt mà giới thiệu một mệnh đề quan hệ.',
    'syntax.s.description':
      'Các phần của mệnh đề mà phần còn lại của khoản làm cho một sự khẳng định về.',
    'syntax.v.description':
      'Nói điều gì đó về chủ đề của mệnh đề và có thể bày tỏ hành động, sự kiện hoặc điều kiện.',
    'syntax.vc.description': 'Liên kết chủ đề của một khoản để một sản phẩm bổ sung.',
    'general.close': 'Đóng',
    'general.action': 'Hoạt động',
    'general.select': 'Lựa chọn...',
    'general.back': 'Trở lại',
    'revisionHistory.noData': 'Không có lịch sử sửa đổi cho câu này.',
    'revisionHistory.translation': 'Dịch',
    'revisionHistory.lastChanged': 'Mới đổi',
    'sourceText.view': 'Nhấn vào đây để xem văn bản gốc',
    'sourceText.viewStructure': 'Nhấn vào đây để xem cấu trúc văn bản nguồn',
    'error.projectIdIsRequired': 'Project ID là bắt buộc.',
    'error.searchTermIsRequired': 'hạn tìm kiếm không thể có sản phẩm nào.',
    'error.bookIdIsRequired': 'Sách ID là cần thiết để lưu trữ tên cuốn sách.',
    'error.bookNameCannotBeEmpty': 'Tên cuốn sách không thể để trống.',
    'error.signInIsRequiredToAccessProject': 'Bạn phải đăng nhập để truy cập dự án của bạn.',
    error: 'lỗi',
    'error.doNotHavePermissionAccessProject': 'Bạn không có quyền truy cập vào dự án này.',
    'error.unauthorized': 'Truy cập trái phép hoặc truy cập không hợp lệ Token.',
    'error.tokenExpired': 'Mã thông báo đã hết hạn. Hãy đăng nhập lại.',
    'error.invalidApiUrl': 'url API không hợp lệ.',
    'error.badRequestInvalidJson': 'Bad yêu cầu. Không hợp lệ JSON.',
    'error.windowIsNotDefined': 'Cửa sổ không được định nghĩa.',
    'error.unexpectedError': 'Một lỗi không mong muốn đã xảy ra. Hãy đóng cửa sổ này và thử lại.',
    'error.invalidDataType': 'kiểu dữ liệu hợp lệ.',
    'export.data': 'Xuất dữ liệu',
    more: 'Hơn',
    'export.to.rtf': 'Xuất khẩu sang RTF',
    'export.to.usfm': 'Xuất khẩu sang USFM',
    'export.titlePreparingData': 'Chúng tôi đang chuẩn bị dữ liệu dịch Kinh Thánh của bạn.',
    'export.doNotClose':
      'Quá trình này có thể mất một thời gian, xin vui lòng không đóng cửa sổ này và bạn sẽ được thông báo khi nó đã sẵn sàng để tải về.',
    'export.desc': 'Bạn có thể xuất và tải dữ liệu dịch của bạn bằng cách nhấn vào nút bên dưới.',
    'export.titleOfReady2Download': 'dữ liệu dịch Kinh Thánh của bạn đã sẵn sàng.',
    'export.textOfReady2Download':
      'Xin vui lòng bấm vào nút tải về dưới đây để tải về bản dịch Kinh Thánh của bạn.',
    'export.statistics': 'Số liệu thống kê',
    'export.book': 'Tên sách',
    'export.translatedVerses': 'câu dịch',
    'export.exportedVerses': 'câu xuất khẩu',
    'export.howDoUWant2Export': 'Làm thế nào để bạn muốn xuất dữ liệu?',
    'export.whichBooks': 'Những cuốn sách (s) Bạn muốn xuất khẩu?',
    'export.byBooks': 'by sách',
    'export.byChapters': 'bởi chương',
    'export.byVerses': 'bởi câu',
    'export.booksInOt': 'Di chúc cũ',
    'export.booksInNt': 'Di chúc mới',
    'export.booksInBible': 'toàn bộ Kinh Thánh',
    'export.booksInNone': 'không ai',
    'export.selectRangeOfChapters': 'Phạm vi của chương',
    'export.selectRangeOfVerses': 'Phạm vi của các câu',
    'export.selectChapter': 'Chọn một chương',
    'export.exportTypeIsRequired': 'Bạn chưa chọn về làm thế nào để bạn muốn xuất dữ liệu?',
    'export.haveNotYetSelectedBooks': 'Bạn chưa chọn bất kỳ cuốn sách.',
    'error.selectABook': 'Bạn phải chọn một cuốn sách.',
    'error.invalidBookId': 'ID cuốn sách không hợp lệ.',
    'error.selectAChapter': 'Bạn phải chọn một chương.',
    'error.invalidChapterRange': 'Bạn phải chọn một loạt chương hợp lệ.',
    'error.invalidVerseRange': 'Bạn phải chọn một loạt câu hợp lệ.',
    'error.invalidExportType': 'loại xuất khẩu không hợp lệ.',
    startover: 'Bắt đầu lại',
    download: 'Tải xuống',
    'download.all': 'Tải về tất cả các file',
    'contact.header': 'Liên hệ chúng tôi',
    'contact.returnAddress': 'Trở Địa chỉ',
    'contact.returnAddressPlaceholder': 'chúng ta có thể tiếp cận với bạn ở đâu?',
    'contact.subject': 'Môn học',
    'contact.subjectPlaceholder': 'Bạn cần giúp về?',
    'contact.message': 'Thông điệp',
    'contact.messagePlaceholder': 'Làm thế nào chúng ta có thể giúp đỡ?',
    'contact.sendMessage': 'Gửi tin nhắn',
    'contact.success': 'Tin nhắn đã được gửi thành công.',
    'contact.failure':
      'Có một vấn đề gửi tin nhắn này. Vui lòng liên hệ help@globalbibleinitiative.org.',
    selectInterlinear: 'Nhấn vào đây để thay đổi văn bản viết ở giữa hàng',
    'warning.screenTooSmall':
      'Vui lòng chuyển sang độ phân giải màn hình cao hơn (tối thiểu 1024px rộng) hoặc xin mở rộng cửa sổ trình duyệt của bạn.',
    'label.dictionary': 'Concordance Tìm kiếm',
    'dict.search.placeholder': 'Nhập từ (s)',
    'tooltip.manuscript': 'bản viết tay',
    'tooltip.strongsx': 'Số Strong',
    'tooltip.translation': 'Dịch',
    'tooltip.english': 'Tiếng Anh',
    'title.howToSearch': 'Làm thế nào để sử dụng tìm kiếm sự phù hợp',
    'desc.howToSearch':
      'Nhập từ (s) từ Hy Lạp hoặc Hebrew, word (s) từ bản dịch của bạn, hoặc Số Mạnh (s). Hoặc nhấp vào từ tiếng Hy Lạp hoặc Hebrew trong bảng điều khiển của "dịch của bạn" hoặc quan điểm Alignment cho các tùy chọn tìm kiếm.',
    'dict.noDataToDisplay': 'Không có dữ liệu phù hợp với tiêu chí tìm kiếm của bạn.',
    'title.searchTermTooMany': 'Bạn đã nhập quá nhiều từ khóa',
    'desc.searchTermTooMany': 'Bạn có thể tìm kiếm lên đến 8 khóa mỗi lần.',
    'title.searchDictError': 'Một lỗi không mong muốn đã xảy ra.',
    'desc.searchDictError': 'Vui lòng thử lại sau hoặc hỗ trợ khách hàng tiếp xúc',
    'alignment.saveAndEditAlignment': 'Tiêu đề tiết kiệm và Align mới',
    'alignment.reviewAlignment': 'Xem lại bản Alignment',
    'dict.searchLemma': 'Nhấn vào đây để tìm kiếm từ gốc',
    'dict.searchTranslation': 'Nhấn vào đây để tìm kiếm từ dịch',
    'dict.searchStrongsX': 'Nhấn vào đây để tìm kiếm số điện thoại của Mạnh',
    'dict.showMenu': 'Nhấn vào đây để hiển thị đầy đủ nội dung của lần xuất hiện trong bối cảnh.',
    'dict.hideMenu': 'Nhấn vào đây để ẩn toàn văn lần xuất hiện trong ngữ cảnh.',
  },
  'zh-hans': {
    'languages.en': '英语',
    'languages.am': '阿姆哈拉语',
    'languages.bn': '孟加拉语',
    'languages.ceb': '宿务语',
    'languages.de': '德语',
    'languages.es-419': '西班牙语',
    'languages.fr': '法语',
    'languages.gu': '古吉拉特语',
    'languages.hi': '印地语',
    'languages.hr': '克罗地亚语',
    'languages.hu': '匈牙利语',
    'languages.id': '印度尼西亚语',
    'languages.km': '高棉语',
    'languages.kn': '卡纳达语',
    'languages.ko': '朝鲜语',
    'languages.lo': '老挝语',
    'languages.ml': '马拉雅拉姆语',
    'languages.mr': '马拉',
    'languages.ms': '马来西亚',
    'languages.my': '缅甸语',
    'languages.ne': '尼泊尔语',
    'languages.plt': '马达加斯加语',
    'languages.pt-br': '葡萄牙语（巴西）语言',
    'languages.ru': '俄语',
    'languages.sw': '斯瓦希里',
    'languages.ta': '泰米尔语',
    'languages.th': '泰语',
    'languages.tl': '他加禄语',
    'languages.te': '泰卢固语',
    'languages.vi': '越南语',
    'languages.zh-hans': '简体中文',
    'languages.zh-hant': '繁体中文',
    'header.home': '主页',
    'header.help': '帮助',
    'header.project': '项目',
    'header.contact': '联系',
    'help.contactSupport': '请help@youtranslate.bible援助给我们发电子邮件。',
    'home.ytbProvides': 'YouTranslate.Bible自由提供：',
    'home.easyToUseTool': '一个易于使用的在线翻译工具',
    'home.originalTextHelp': '有助于理解圣经的文字在其原来的语言',
    'home.referencesAndResources': '参考和培训资源',
    'home.computerAssistance': '国家的最先进的计算机技术，以协助翻译',
    'profile.userProfile': '用户资料',
    'profile.language': '语言',
    switchDirection: '转换文本方向',
    'profile.update': '更新',
    'profile.languageWarning':
      '谷歌翻译来翻译用户界面成英语暂时的其他语言。 YTB有过翻译的准确性和可靠性控制。任何人都依靠从谷歌获得的翻译标签和说明翻译在他或她自己承担风险。如果有人有兴趣加入志愿者，提供用户界面的更准确，更可靠的翻译成英文以外的语言，请点击联系选项，发送电子邮件到GBI。',
    'usernav.profile': '用户信息',
    'usernav.logout': '登出',
    'usernav.logout.modalTitle': '登出 YouTranslate.Bible',
    'usernav.logout.modalText': "确定要deng'ch？所有未保存的工作都将丢失。\n",
    cancel: '取消',
    delete: '删除',
    create: '创造',
    update: '更新',
    edit: '编辑',
    save: '保存',
    add: '添加',
    translate: '翻译',
    'more.options': '更多的选择',
    'project.create.title': '圣经翻译项目',
    'project.create.list': '项目列表',
    'project.list.deleteProject': '删除项目？',
    'project.list.areYouSure': '确定要删除这个项目？',
    'project.list.workWillBeDeleted': '项目和相关的任何工作都将被删除。',
    'project.list.completion': '完成：',
    'project.list.createdDate': '创建日期：',
    'project.list.beginningDate': '开始日期：',
    'project.list.targetCompletionDate': '目标完成日期：',
    'project.list.description': '说明：',
    'project.edit': '编辑项目',
    'project.list.translateBible': '翻译圣经',
    'project.list.empty': '你的翻译项目列表为空。',
    'project.list.myProjects': '我的圣经翻译项目',
    'project.list.newProject': '新项目',
    'project.create.createNew': '创建一个新项目',
    'project.namePlaceholder': '输入项目名称',
    'project.descriptionPlaceholder': '输入项目的描述',
    'project.name': '项目名称',
    'project.beginningDate': '开始日期',
    'project.targetCompletionDate': '目标完成日期',
    'project.description': '描述',
    'project.isRTL': '翻译是从右到左',
    'project.manage-users': '翻译管理',
    'project.new-user': '新翻译',
    'project.add-user': '添加翻译',
    'project.users': '项目翻译',
    'project.read-only': '只读',
    'project.read&write': '读写',
    'project.owner': '所有者',
    'project.translator': '翻译者',
    'project.message.no-such-user': '没有这样的翻译存在',
    'project.message.user-added': '翻译成功添加',
    'project.message.user-deleted': '译者删除。',
    'project.message.only-owner-can-manage-users': '只有项目所有者可以管理用户',
    'project.shared': '合作项目',
    'general.delete-user': '删除翻译',
    'general.edit-user': '编辑用户',
    'general.name': '名称',
    'general.email': '电子邮件',
    'general.permissions': '权限',
    'general.role': '角色',
    signin: '登入',
    'signin.emailPlaceholder': '电子邮件地址',
    'signin.passwordPlaceholder': '密码',
    'signin.forgotPassword': '忘记密码了吗？',
    'signin.facebook': '请用facebook',
    'signin.google': '用谷歌登录',
    'signin.dontHaveAccount': '已经有帐号了吗？',
    'signin.createOne': '创建账号',
    'signup.join': '加入YouTranslate.Bible',
    'signup.createAccountPrompt': '建立免费账户',
    'signup.createAccount': '創建帳號\n',
    'signup.emailPlaceholder': '电子邮件地址',
    'signup.passwordPlaceholder': '密码',
    'passwordReset.title': '重设密码',
    'passwordReset.prompt': '请求的电子邮件与说明重置您的密码。',
    'passwordReset.emailPlaceholder': '电子邮件地址',
    'passwordReset.requestButtonLabel': '申请邮箱',
    'passwordReset.success': '邮件已发送。',
    'passwordReset.error': '发生了错误。',
    'translation.project': '项目：',
    'translation.backToProjectList': '返回项目列表',
    'translation.book': '书：',
    'translation.chapter': '章节：',
    'translation.prompt': '你确定要离开本章？所有未保存的工作都将丢失',
    'translation.prev': '上一页',
    'translation.next': '下一页',
    'translation.previousChapter': '上一章',
    'translation.nextChapter': '下一章',
    'navigator.book': '书名:',
    'navigator.chapter': '章节：',
    'navigator.oldTestament': '旧约全书',
    'navigator.newTestament': '新约全书',
    'navigator.selectBook': '选择一本书',
    'navigator.search': '搜索',
    'navigator.changeBook': '换一本书',
    'navigator.changeChapter': '换一章',
    Genesis: '创世纪',
    Exodus: '出埃及记',
    Leviticus: '利未记',
    Numbers: '民数记',
    Deuteronomy: '申命记',
    Joshua: ' \n                 \n\n\n约书亚记',
    Judges: '士师记',
    Ruth: '路得记',
    '1 Samuel': ' \n                 \n\n\n撒母耳记上',
    '2 Samuel': '撒母耳记下',
    '1 Kings': '列王纪上',
    '2 Kings': '列王纪下',
    '1 Chronicles': '历代志上',
    '2 Chronicles': '历代志下',
    Ezra: '以斯拉记',
    Nehemiah: '尼希米记',
    Esther: '以斯帖记',
    Job: '约伯记',
    Psalms: '诗篇',
    Proverbs: '箴言',
    Ecclesiastes: '传道书',
    'Song of Songs': '雅歌',
    Isaiah: '以赛亚书',
    Jeremiah: '耶利米书',
    Lamentations: '耶利米哀歌',
    Ezekiel: '以西结书',
    Daniel: '但以理书',
    Hosea: '何西阿书',
    Joel: '约珥书',
    Amos: '阿摩司书',
    Obadiah: '俄巴底亚书',
    Jonah: '约拿书',
    Micah: '弥迦书',
    Nahum: '那鸿书',
    Habakkuk: '哈巴谷书',
    Zephaniah: '西番雅书',
    Haggai: '哈该书',
    Zechariah: '撒迦利亚书',
    Malachi: '玛拉基书',
    Matthew: '马太福音',
    Mark: '马可福音',
    Luke: '路加福音',
    John: '约翰福音',
    Acts: '使徒行传',
    Romans: '罗马书',
    '1 Corinthians': '哥林多前书',
    '2 Corinthians': '哥林多后书',
    Galatians: '加拉太书',
    Ephesians: '以弗所书',
    Philippians: '腓立比书',
    Colossians: '歌罗西书',
    '1 Thessalonians': '帖撒罗尼迦前书',
    '2 Thessalonians': '帖撒罗尼迦后书',
    '1 Timothy': '提摩太前书',
    '2 Timothy': '提摩太后书',
    Titus: '提多书',
    Philemon: '腓利门书\n',
    Hebrews: '希伯来书',
    James: '雅各书',
    '1 Peter': '彼得前书',
    '2 Peter': '彼得后书',
    '1 John': '约翰一书',
    '2 John': '约翰二书',
    '3 John': '约翰三书',
    Jude: '犹大书',
    Revelation: '启示录',
    'resources.prompt': '添加圣经资源，更深入地了解您所翻译的段落',
    'resources.add': '添加圣经资源',
    Manuscript: '原稿',
    Morphology: '词型',
    Lexicon: '词汇',
    'Syntactic Tree': '句法',
    'Go to project list': '转到项目列表',
    'Project not found': '项目未找到或权限不足。',
    'error.referenceDoesNotExistForResource': '这圣经翻译不包含所选段落的内容',
    'references.addAnother': '添加另一个翻译',
    'references.referencePrompt': '添加其他圣经翻译作参考',
    'references.add': '添加圣经翻译',
    'references.licensing': '许可信息',
    'references.licensingEmpty': '无许可信息被发现的这个项目。',
    'translation.complete': '翻译完成',
    Alignment: '对齐',
    Share: '分享',
    'Your Translation': '您的翻译',
    'Enter your translation': '输入您的翻译',
    OK: 'OK',
    'error.verseTranslationEmpty': '翻译内容不可为空白',
    'translation.hide.completed': '已隐藏',
    alignment: '对齐',
    'alignment.verify': '校对',
    'alignment.close': '不校对并关闭',
    'alignment.backToEditor': '校对',
    'alignment.error': '有问题。如果问题仍然存在，请与YouTranslate团队联系',
    'alignment.add': '添加链接',
    'alignment.delete': '删除链接',
    'alignment.clear': '清空选项',
    'alignment.reverse': '反转显示',
    'alignment.removeOrClear': '删除选定的链接或删除所有链接',
    'alignment.addOrRemove': '创建一个新的链接或删除之前的一个',
    'alignment.selectAndAdd': '选定所有需链接的词，然后单击添加按钮\n',
    'error.mustBeSignedToMakeChanges': '登录后才能修改',
    'error.mustBeSignedToTranslateBible': '登录后才能翻译圣经',
    checking: '检查',
    'verseEditor.unexpectedError': '发生意外的错误。',
    verses: '按节',
    words: '按词',
    'leftPanel.name': '圣经原文',
    'rightPanel.name': '圣经译本',
    'bottomPanel.name': '圣经资源',
    revisionHistory: '修订记录',
    'syntax.adv': '状语',
    'syntax.adjp': '形容词语',
    'syntax.advp': '副词短语',
    'syntax.cl': '条款',
    'syntax.conj': '连词',
    'syntax.cjp': '连词',
    'syntax.det': '确定',
    'syntax.ijp': '欹',
    'syntax.nump': '数值短语',
    'syntax.np': '名词短语',
    'syntax.o': '直接宾语',
    'syntax.o2': '第二个目的',
    'syntax.oc': '宾语补足语',
    'syntax.om': '对象标记',
    'syntax.io': '间接宾语',
    'syntax.p': '非谓语动词',
    'syntax.pp': '介词短语',
    'syntax.prep': '介词',
    'syntax.ptcl': '粒子',
    'syntax.rel': '相对粒子',
    'syntax.s': '学科',
    'syntax.v': '动词',
    'syntax.vc': '连接动词',
    'syntax.adjp.description': '短语修饰一个名词或代词',
    'syntax.adv.description': '修改动词，典型地进一步定义的方式，时间，地点，频率，程度，等等。',
    'syntax.advp.description': '短语修饰动词，典型地进一步定义的方式，时间，地点，频率，程度等',
    'syntax.cl.description': '一组词，使有关主体的断言。',
    'syntax.conj.description': '连接单词，短语和从句。',
    'syntax.cjp.description': '连接单词，短语和从句。',
    'syntax.det.description': '指定，识别或量词名词短语。',
    'syntax.ijp.description': '感叹号或情绪的突然表达。',
    'syntax.nump.description': '短语描述数值量',
    'syntax.np.description': '具有名词作为其头部或用作一个名词短语。',
    'syntax.o.description': '即，将其直接受到影响，引起的，或由一个动词的作用而产生。',
    'syntax.o2.description': '即，将其直接受到影响，引起的，或由一个动词的作用而产生。',
    'syntax.oc.description': '使有关直接宾语的断言。',
    'syntax.om.description': '粒子，介绍的直接对象。',
    'syntax.io.description': '对象，它是间接影响一个动作，一个动词的动作往往接受者。',
    'syntax.p.description': '识别或描述的主题，通常（但不总是）连同连接动词发生。',
    'syntax.pp.description': '修改短语由介词和它的对象。',
    'syntax.prep.description': '指示与换句话说介词的对象的关系，经常指示何处或何时。',
    'syntax.ptcl.description': '从不同群体的虚词一位成员不属于任何屈折语法词类',
    'syntax.rel.description': '颗粒，其引入了相对子句。',
    'syntax.s.description': '该条款的其余部分使有关断言的条款的一部分。',
    'syntax.v.description': '指出一些有关条款的主题，可以表达动作，事件或条件。',
    'syntax.vc.description': '链接子句主语补语的主题。',
    'general.close': '关',
    'general.action': '行动',
    'general.select': '选择...',
    'general.back': '背部',
    'revisionHistory.noData': '本节经文没有修订记录。',
    'revisionHistory.translation': '翻译的内容',
    'revisionHistory.lastChanged': '最后一次修订',
    'sourceText.view': '点击查看原文',
    'sourceText.viewStructure': '点击查看原文结构',
    'error.projectIdIsRequired': '项目ID是必需的。',
    'error.searchTermIsRequired': '搜索字词不能为空。',
    'error.bookIdIsRequired': '图书ID需要存储书籍名称。',
    'error.bookNameCannotBeEmpty': '书名不能为空。',
    'error.signInIsRequiredToAccessProject': '您必须登录后再可访问翻译项目。',
    error: '错误',
    'error.doNotHavePermissionAccessProject': '您没有权限访问这个项目。',
    'error.unauthorized': '未经授权的访问或无效的访问令牌。',
    'error.tokenExpired': '您的令牌已过期。请重新登录。',
    'error.invalidApiUrl': '无效API网址。',
    'error.badRequestInvalidJson': '错误的请求。无效的JSON。',
    'error.windowIsNotDefined': '窗口没有被定义。',
    'error.unexpectedError': '发生意外的错误。请关闭此窗口，然后再试一次。',
    'error.invalidDataType': '无效的数据类型。',
    'export.data': '导出数据',
    more: '更多',
    'export.to.rtf': '导出到RTF',
    'export.to.usfm': '导出到USFM',
    'export.titlePreparingData': '我们正在准备您的圣经翻译的数据。',
    'export.doNotClose':
      '这个过程可能需要一段时间，请不要关闭这个窗口，当它准备好时您将会收到通知。',
    'export.desc': '您可以通过点击下面的按钮导出并下载您的翻译资料。',
    'export.titleOfReady2Download': '你的圣经翻译数据已经就绪。',
    'export.textOfReady2Download': '请点击下面的下载按钮开始下载您的圣经翻译数据。',
    'export.statistics': '统计数据',
    'export.book': '书名',
    'export.translatedVerses': '翻译的经文数',
    'export.exportedVerses': '导出的经文数',
    'export.howDoUWant2Export': '您想以何种方式导出数据?',
    'export.whichBooks': '您想要导出哪些书？',
    'export.byBooks': '以书籍的方式',
    'export.byChapters': '以章节的方式',
    'export.byVerses': '以诗句的方式',
    'export.booksInOt': '旧约全书',
    'export.booksInNt': '新约全书',
    'export.booksInBible': '整本圣经',
    'export.booksInNone': '没有',
    'export.selectRangeOfChapters': '章范围',
    'export.selectRangeOfVerses': '经文范围',
    'export.selectChapter': '选择章节',
    'export.exportTypeIsRequired': '您还没有选择将如何导出数据?',
    'export.haveNotYetSelectedBooks': '您还没有选择任何一本书。',
    'error.selectABook': '您必须选择一本书。',
    'error.invalidBookId': '无效的书籍ID。',
    'error.selectAChapter': '你必须选择一个章节。',
    'error.invalidChapterRange': '你必须选择一个有效的章节范围。',
    'error.invalidVerseRange': '你必须选择一个有效的诗句范围。',
    'error.invalidExportType': '无效的导出方式。',
    startover: '重来',
    download: '下载',
    'download.all': '下载所有文件',
    'contact.header': '联系我们',
    'contact.returnAddress': '退货地址',
    'contact.returnAddressPlaceholder': '我们在哪里可以找到你？',
    'contact.subject': '学科',
    'contact.subjectPlaceholder': '你有什么需要帮助的？',
    'contact.message': '信息',
    'contact.messagePlaceholder': '我们能帮你什么吗？',
    'contact.sendMessage': '发信息',
    'contact.success': '邮件发送成功。',
    'contact.failure': '有发送此消息的问题。请联系help@globalbibleinitiative.org。',
    selectInterlinear: '点击更改行间的文本',
    'warning.screenTooSmall':
      '请切换到一个更高的屏幕分辨率（最低1,024像素宽），或者请扩大你的浏览器窗口。',
    'label.dictionary': '相关搜索',
    'dict.search.placeholder': '可输入手稿单词，译文单词，或史特朗号码.',
    'tooltip.manuscript': '手稿',
    'tooltip.strongsx': '史特朗号码',
    'tooltip.translation': '翻译',
    'tooltip.english': '英语',
    'title.howToSearch': '如何搜索词语索引',
    'desc.howToSearch':
      '您可以快速查找您的翻译历史中已链接的翻译词语，手稿（希伯来文，希腊文），以及史特朗号码。',
    'dict.noDataToDisplay': '没有找到匹配您的搜索条件的数据。',
    'title.searchTermTooMany': '同时搜索的关键词太多',
    'desc.searchTermTooMany': '不要同时搜索超过8个关键词。',
    'title.searchDictError': '发生意外的错误。',
    'desc.searchDictError': '请稍后重试，或与服务人员联系。',
    'alignment.saveAndEditAlignment': '检查单词对齐',
    'alignment.reviewAlignment': '保存并编辑单词对齐',
    'dict.searchLemma': '搜索原词',
    'dict.searchTranslation': '搜索翻译后的词',
    'dict.searchStrongsX': '搜索史特朗号码',
    'dict.showMenu': '单击显示在上下文中出现的全文。',
    'dict.hideMenu': '点击隐藏在上下文中出现的全文。',
  },
  'zh-hant': {
    'languages.en': '英語',
    'languages.am': '阿姆哈拉語',
    'languages.bn': '孟加拉語',
    'languages.ceb': '宿務語',
    'languages.de': '德語',
    'languages.es-419': '西班牙語',
    'languages.fr': '法語',
    'languages.gu': '古吉拉特語',
    'languages.hi': '印地語',
    'languages.hr': '克羅地亞語',
    'languages.hu': '匈牙利語',
    'languages.id': '印度尼西亞語',
    'languages.km': '高棉文',
    'languages.kn': '卡納達語',
    'languages.ko': '朝鮮語',
    'languages.lo': '老撾語',
    'languages.ml': '馬拉雅拉姆語',
    'languages.mr': '馬拉',
    'languages.ms': '馬來西亞',
    'languages.my': '緬甸語',
    'languages.ne': '尼泊爾語',
    'languages.plt': '馬達加斯加語',
    'languages.pt-br': '葡萄牙語（巴西）語言',
    'languages.ru': '俄語',
    'languages.sw': '斯瓦希裡',
    'languages.ta': '泰米爾語',
    'languages.th': '泰語',
    'languages.tl': '他加祿語',
    'languages.te': '泰盧固語',
    'languages.vi': '越南語',
    'languages.zh-hans': '簡體中文',
    'languages.zh-hant': '繁體中文',
    'header.home': '主頁\n',
    'header.help': '説明',
    'header.project': '項目',
    'header.contact': '聯繫',
    'help.contactSupport': '請help@youtranslate.bible援助給我們發電子郵件。',
    'home.ytbProvides': 'YouTranslate.Bible自由提供：',
    'home.easyToUseTool': '一個易於使用的在線翻譯工具',
    'home.originalTextHelp': '有助於理解聖經的文字在其原來的語言',
    'home.referencesAndResources': '參考和培訓資源',
    'home.computerAssistance': '國家的最先進的計算機技術，以協助翻譯',
    'profile.userProfile': '用戶資料',
    'profile.language': '語言',
    switchDirection: '轉換文本方向',
    'profile.update': '更新',
    'profile.languageWarning':
      '谷歌翻譯來翻譯用戶界面成英語暫時的其他語言。 YTB有過翻譯的準確性和可靠性控制。任何人都依靠從谷歌獲得的翻譯標籤和說明翻譯在他或她自己承擔風險。如果有人有興趣加入志願者，提供用戶界面的更準確，更可靠的翻譯成英文以外的語言，請點擊聯繫選項，發送電子郵件到GBI。',
    'usernav.profile': '使用者資訊\n',
    'usernav.logout': '登出',
    'usernav.logout.modalTitle': '登出 YouTranslate.Bible\n',
    'usernav.logout.modalText': "確定要deng'ch？ 所有未保存的工作都將丟失。\n",
    cancel: '取消',
    delete: '刪除',
    create: '創造',
    update: '更新',
    edit: '編輯',
    save: '保存',
    add: '添加',
    translate: '翻譯',
    'more.options': '更多的選擇',
    'project.create.title': '聖經翻譯項目',
    'project.create.list': '項目列表',
    'project.list.deleteProject': '刪除項目？',
    'project.list.areYouSure': '確定要刪除這個項目？',
    'project.list.workWillBeDeleted': '項目和相關的任何工作都將被刪除。',
    'project.list.completion': '完成：',
    'project.list.createdDate': '創建日期：',
    'project.list.beginningDate': '開始日期：',
    'project.list.targetCompletionDate': '目標完成日期：',
    'project.list.description': '說明：\n',
    'project.edit': '編輯項目',
    'project.list.translateBible': '翻譯聖經',
    'project.list.empty': '你的翻譯項目列表為空。',
    'project.list.myProjects': '我的聖經翻譯項目',
    'project.list.newProject': '新項目',
    'project.create.createNew': '創建一個新項目',
    'project.namePlaceholder': '輸入項目名稱',
    'project.descriptionPlaceholder': '輸入項目的描述',
    'project.name': '項目名稱',
    'project.beginningDate': '開始日期',
    'project.targetCompletionDate': '目標完成日期',
    'project.description': '描述',
    'project.isRTL': '翻譯是從右到左',
    'project.manage-users': '翻譯管理',
    'project.new-user': '新翻譯',
    'project.add-user': '添加翻譯',
    'project.users': '項目翻譯',
    'project.read-only': '只讀',
    'project.read&write': '讀寫',
    'project.owner': '所有者',
    'project.translator': '翻譯者',
    'project.message.no-such-user': '沒有這樣的翻譯存在',
    'project.message.user-added': '翻譯成功添加',
    'project.message.user-deleted': '譯者刪除。',
    'project.message.only-owner-can-manage-users': '只有項目所有者可以管理用戶',
    'project.shared': '合作項目',
    'general.delete-user': '刪除翻譯',
    'general.edit-user': '編輯用戶',
    'general.name': '名稱',
    'general.email': '電子郵件',
    'general.permissions': '權限',
    'general.role': '角色',
    signin: '登入',
    'signin.emailPlaceholder': '電子郵件地址',
    'signin.passwordPlaceholder': '密碼',
    'signin.forgotPassword': '忘記密碼了嗎？',
    'signin.facebook': '請用facebook',
    'signin.google': '用谷歌登錄\n',
    'signin.dontHaveAccount': '已經有帳號了嗎？\n',
    'signin.createOne': '創建帳號\n\n',
    'signup.join': '加入YouTranslate.Bible',
    'signup.createAccountPrompt': '建立免費帳戶\n',
    'signup.createAccount': '創建帳號\n\n',
    'signup.emailPlaceholder': '電子郵件地址',
    'signup.passwordPlaceholder': '密碼',
    'passwordReset.title': '重設密碼',
    'passwordReset.prompt': '請求的電子郵件與說明重置您的密碼。',
    'passwordReset.emailPlaceholder': '電子郵件地址',
    'passwordReset.requestButtonLabel': '申請郵箱',
    'passwordReset.success': '郵件已發送。',
    'passwordReset.error': '發生了錯誤。',
    'translation.project': '項目：',
    'translation.backToProjectList': '返回項目列表',
    'translation.book': '書：',
    'translation.chapter': '章節：',
    'translation.prompt': '你確定要離開本章？ 所有未保存的工作都將丟失\n',
    'translation.prev': '上一頁',
    'translation.next': '下一頁',
    'translation.previousChapter': '上一章',
    'translation.nextChapter': '下一章',
    'navigator.book': '書名：',
    'navigator.chapter': '章節：',
    'navigator.oldTestament': '舊約全書',
    'navigator.newTestament': '新約全書',
    'navigator.selectBook': '選擇一本書',
    'navigator.search': '搜索',
    'navigator.changeBook': '換一本書\n',
    'navigator.changeChapter': '換一章\n',
    Genesis: '創世紀',
    Exodus: '出埃及記',
    Leviticus: '利未記',
    Numbers: '民數記\n',
    Deuteronomy: '申命記',
    Joshua: '約書亞記\n',
    Judges: '士師記',
    Ruth: '路得記\n',
    '1 Samuel': '撒母耳記上',
    '2 Samuel': '撒母耳記下',
    '1 Kings': '列王紀上',
    '2 Kings': '列王紀下',
    '1 Chronicles': '歷代志上',
    '2 Chronicles': '歷代志下',
    Ezra: '以斯拉記',
    Nehemiah: '尼希米記',
    Esther: '以斯帖記',
    Job: '約伯記',
    Psalms: '詩篇',
    Proverbs: '箴言',
    Ecclesiastes: '傳道書',
    'Song of Songs': '雅歌',
    Isaiah: '以賽亞書',
    Jeremiah: '耶利米書',
    Lamentations: '耶利米哀歌',
    Ezekiel: '以西結書',
    Daniel: '但以理書',
    Hosea: '何西阿書',
    Joel: '約珥書',
    Amos: '阿摩司書',
    Obadiah: '俄巴底亞書',
    Jonah: '約拿書',
    Micah: '彌迦書',
    Nahum: '那鴻書',
    Habakkuk: '哈巴谷書',
    Zephaniah: '西番雅書',
    Haggai: '哈該書',
    Zechariah: '撒迦利亞書',
    Malachi: '瑪拉基書',
    Matthew: '馬太福音',
    Mark: '馬可福音',
    Luke: '路加福音',
    John: '約翰福音',
    Acts: '使徒行傳',
    Romans: '羅馬書',
    '1 Corinthians': '哥林多前書',
    '2 Corinthians': '哥林多後書',
    Galatians: '加拉太書',
    Ephesians: '以弗所書',
    Philippians: '腓立比書',
    Colossians: '歌羅西書',
    '1 Thessalonians': '帖撒羅尼迦前書',
    '2 Thessalonians': '帖撒羅尼迦後書',
    '1 Timothy': '提摩太前書',
    '2 Timothy': '提摩太後書',
    Titus: '提多書',
    Philemon: '腓利門書',
    Hebrews: '希伯來書',
    James: '雅各書',
    '1 Peter': '彼得前書',
    '2 Peter': '彼得後書',
    '1 John': '約翰一書',
    '2 John': '約翰二書',
    '3 John': '約翰三書',
    Jude: '猶大書',
    Revelation: '啟示錄',
    'resources.prompt': '添加聖經資源，更深入地瞭解您所翻譯的段落',
    'resources.add': '添加聖經資源',
    Manuscript: '原稿',
    Morphology: '詞型',
    Lexicon: '詞彙',
    'Syntactic Tree': '句法',
    'Go to project list': '轉到專案清單',
    'Project not found': '項目未找到或權限不足。',
    'error.referenceDoesNotExistForResource': '這聖經翻譯不包含所選段落的內容',
    'references.addAnother': '添加另一個翻譯',
    'references.referencePrompt': '添加其他聖經翻譯作參考',
    'references.add': '添加聖經翻譯',
    'references.licensing': '許可信息',
    'references.licensingEmpty': '無許可信息被發現的這個項目。',
    'translation.complete': '翻譯完成',
    Alignment: '對齊',
    Share: '分享',
    'Your Translation': '您的翻譯',
    'Enter your translation': '輸入您的翻譯',
    OK: 'OK',
    'error.verseTranslationEmpty': '翻譯內容不可為空白',
    'translation.hide.completed': '已隱藏',
    alignment: '對齊',
    'alignment.verify': '驗證並關閉。',
    'alignment.close': '不校對並關閉',
    'alignment.backToEditor': '返回編輯器',
    'alignment.error': '有問題。 如果問題仍然存在，請與YouTranslate團隊聯繫',
    'alignment.add': '添加鏈接',
    'alignment.delete': '刪除鏈接',
    'alignment.clear': '清空選項',
    'alignment.reverse': '反轉顯示',
    'alignment.removeOrClear': '刪除選定的連結或刪除所有連結',
    'alignment.addOrRemove': '創建一個新的連結或刪除之前的一個',
    'alignment.selectAndAdd': "選定suo'you需連結的詞，然後按一下添加按鈕",
    'error.mustBeSignedToMakeChanges': '登錄後才能修改',
    'error.mustBeSignedToTranslateBible': '登錄後才能翻譯聖經',
    checking: '檢查',
    'verseEditor.unexpectedError': '發生意外的錯誤。',
    verses: '按節',
    words: '按詞',
    'leftPanel.name': '聖經原文',
    'rightPanel.name': '聖經譯本',
    'bottomPanel.name': '聖經資源',
    revisionHistory: '修訂記錄',
    'syntax.adv': '狀語',
    'syntax.adjp': '形容詞語',
    'syntax.advp': '副詞短語',
    'syntax.cl': '條款',
    'syntax.conj': '連詞',
    'syntax.cjp': '連詞',
    'syntax.det': '確定',
    'syntax.ijp': '欹',
    'syntax.nump': '數值短語',
    'syntax.np': '名詞短語',
    'syntax.o': '直接賓語',
    'syntax.o2': '第二個目的',
    'syntax.oc': '賓語補足語',
    'syntax.om': '對象標記',
    'syntax.io': '間接賓語',
    'syntax.p': '非謂語動詞',
    'syntax.pp': '介詞短語',
    'syntax.prep': '介詞',
    'syntax.ptcl': '粒子',
    'syntax.rel': '相對粒子',
    'syntax.s': '學科',
    'syntax.v': '動詞',
    'syntax.vc': '連接動詞',
    'syntax.adjp.description': '短語修飾一個名詞或代詞',
    'syntax.adv.description': '修改動詞，典型地進一步定義的方式，時間，地點，頻率，程度，等等。',
    'syntax.advp.description': '短語修飾動詞，典型地進一步定義的方式，時間，地點，頻率，程度等',
    'syntax.cl.description': '一組詞，使有關主體的斷言。',
    'syntax.conj.description': '連接單詞，短語和從句。',
    'syntax.cjp.description': '連接單詞，短語和從句。',
    'syntax.det.description': '指定，識別或量詞名詞短語。',
    'syntax.ijp.description': '感嘆號或情緒的突然表達。',
    'syntax.nump.description': '短語描述數值量',
    'syntax.np.description': '具有名詞作為其頭部或用作一個名詞短語。',
    'syntax.o.description': '即，將其直接受到影響，引起的，或由一個動詞的作用而產生。',
    'syntax.o2.description': '即，將其直接受到影響，引起的，或由一個動詞的作用而產生。',
    'syntax.oc.description': '使有關直接賓語的斷言。',
    'syntax.om.description': '粒子，介紹的直接對象。',
    'syntax.io.description': '對象，它是間接影響一個動作，一個動詞的動作往往接受者。',
    'syntax.p.description': '識別或描述的主題，通常（但不總是）連同連接動詞發生。',
    'syntax.pp.description': '修改短語由介詞和它的對象。',
    'syntax.prep.description': '指示與換句話說介詞的對象的關係，經常指示何處或何時。',
    'syntax.ptcl.description': '從不同群體的虛詞一位成員不屬於任何屈折語法詞類',
    'syntax.rel.description': '顆粒，其引入了相對子句。',
    'syntax.s.description': '該條款的其餘部分使有關斷言的條款的一部分。',
    'syntax.v.description': '指出一些有關條款的主題，可以表達動作，事件或條件。',
    'syntax.vc.description': '鏈接子句主語補語的主題。',
    'general.close': '關',
    'general.action': '行動',
    'general.select': '選擇...',
    'general.back': '背部',
    'revisionHistory.noData': '本節經文沒有修訂記錄。',
    'revisionHistory.translation': '翻譯的內容',
    'revisionHistory.lastChanged': '最後一次修訂',
    'sourceText.view': '點擊查看原文',
    'sourceText.viewStructure': '點擊查看原文結構',
    'error.projectIdIsRequired': '項目ID是必需的。',
    'error.searchTermIsRequired': '搜索字詞不能為空。',
    'error.bookIdIsRequired': '圖書ID需要存儲書籍名稱。',
    'error.bookNameCannotBeEmpty': '書名不能為空。',
    'error.signInIsRequiredToAccessProject': '您必須登錄後再可訪問翻譯項目。',
    error: '錯誤',
    'error.doNotHavePermissionAccessProject': '您沒有權限訪問這個項目。',
    'error.unauthorized': '未經授權的訪問或無效的訪問令牌。',
    'error.tokenExpired': '您的令牌已過期。請重新登錄。',
    'error.invalidApiUrl': '無效API網址。',
    'error.badRequestInvalidJson': '錯誤的請求。無效的JSON。',
    'error.windowIsNotDefined': '窗口沒有被定義。',
    'error.unexpectedError': '發生意外的錯誤。請關閉此窗口，然後再試一次。',
    'error.invalidDataType': '無效的數據類型。',
    'export.data': '導出數據',
    more: '更多',
    'export.to.rtf': '導出到RTF',
    'export.to.usfm': '導出到USFM',
    'export.titlePreparingData': '我們正在準備您的聖經翻譯的數據。',
    'export.doNotClose':
      '這個過程可能需要一段時間，請不要關閉這個窗口，當它準備好時您將會收到通知。',
    'export.desc': '您可以通過點擊下面的按鈕導出並下載您的翻譯資料。',
    'export.titleOfReady2Download': '你的聖經翻譯數據已經就緒。',
    'export.textOfReady2Download': '請點擊下面的下載按鈕開始下載您的聖經翻譯數據。',
    'export.statistics': '統計數據',
    'export.book': '書名',
    'export.translatedVerses': '翻譯的經文數',
    'export.exportedVerses': '導出的經文數',
    'export.howDoUWant2Export': '您想以何種方式導出數據？',
    'export.whichBooks': '您想要導出哪些書？',
    'export.byBooks': '以書籍的方式',
    'export.byChapters': '以章節的方式',
    'export.byVerses': '以詩句的方式',
    'export.booksInOt': '舊約全書',
    'export.booksInNt': '新約全書',
    'export.booksInBible': '整本聖經',
    'export.booksInNone': '沒有',
    'export.selectRangeOfChapters': '章範圍',
    'export.selectRangeOfVerses': '經文範圍',
    'export.selectChapter': '選擇章節',
    'export.exportTypeIsRequired': '您還沒有選擇將如何導出數據？',
    'export.haveNotYetSelectedBooks': '您還沒有選擇任何一本書。',
    'error.selectABook': '您必須選擇一本書。',
    'error.invalidBookId': '無效的書籍ID。',
    'error.selectAChapter': '你必須選擇一個章節。',
    'error.invalidChapterRange': '你必須選擇一個有效的章節範圍。',
    'error.invalidVerseRange': '你必須選擇一個有效的詩句範圍。',
    'error.invalidExportType': '無效的導出方式。',
    startover: '重來',
    download: '下載',
    'download.all': '下載所有文件',
    'contact.header': '聯繫我們',
    'contact.returnAddress': '退貨地址',
    'contact.returnAddressPlaceholder': '我們在哪裡可以找到你？',
    'contact.subject': '學科',
    'contact.subjectPlaceholder': '你有什麼需要幫助的？',
    'contact.message': '信息',
    'contact.messagePlaceholder': '我們能幫你什麼嗎？',
    'contact.sendMessage': '發信息',
    'contact.success': '郵件發送成功。',
    'contact.failure': '有發送此消息的問題。請聯繫help@globalbibleinitiative.org。',
    selectInterlinear: '點擊更改行間的文本',
    'warning.screenTooSmall':
      '請切換到一個更高的屏幕分辨率（最低1,024像素寬），或者請擴大你的瀏覽器窗口。',
    'label.dictionary': '相關搜索',
    'dict.search.placeholder': '可輸入手稿單詞，譯文單詞，或史特朗號碼。',
    'tooltip.manuscript': '手稿',
    'tooltip.strongsx': '史特朗號碼',
    'tooltip.translation': '翻譯',
    'tooltip.english': '英語',
    'title.howToSearch': '如何搜索詞語索引',
    'desc.howToSearch':
      '您可以快速查找您的翻譯歷史中已鏈接的翻譯詞語，手稿（希伯來文，希臘文），以及史特朗號碼。',
    'dict.noDataToDisplay': '沒有找到匹配您的搜索條件的數據。',
    'title.searchTermTooMany': '同時搜索的關鍵詞太多',
    'desc.searchTermTooMany': '不要同時搜索超過8個關鍵詞。',
    'title.searchDictError': '發生意外的錯誤。',
    'desc.searchDictError': '請稍後重試，或與服務人員聯繫。',
    'alignment.saveAndEditAlignment': '檢查單詞對齊',
    'alignment.reviewAlignment': '保存並編輯單詞對齊',
    'dict.searchLemma': '搜索原詞',
    'dict.searchTranslation': '搜索翻譯後的詞',
    'dict.searchStrongsX': '搜索史特朗號碼',
    'dict.showMenu': '單擊顯示在上下文中出現的全文。',
    'dict.hideMenu': '點擊隱藏在上下文中出現的全文。',
  },
};
export default languageObject;
