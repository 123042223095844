import noop from '../lib/noop';

export const UPDATE_UI_CONFIG_REQUEST = 'UPDATE_UI_CONFIG_REQUEST';
export const UPDATE_UI_CONFIG_FAILURE = 'UPDATE_UI_CONFIG_FAILURE';
export const UPDATE_UI_CONFIG_SUCCESS = 'UPDATE_UI_CONFIG_SUCCESS';
export type Panel = 'Left' | 'Right' | 'Bottom';

export interface GridState {
  isNTrtl: boolean;
  isOTrtl: boolean;
  isNTltr: boolean;
  isOTltr: boolean;
  isNTrtlAlignSource: boolean;
  isOTrtlAlignSource: boolean;
  isNTltrAlignSource: boolean;
  isOTltrAlignSource: boolean;
  isNTrtlAlignTarget: boolean;
  isOTrtlAlignTarget: boolean;
  isNTltrAlignTarget: boolean;
  isOTltrAlignTarget: boolean;
  isLeftPanelOpen: boolean;
  isRightPanelOpen: boolean;
  isBottomPanelOpen: boolean;
}

export interface GridProps {
  isRTL: boolean;
  isNTrtl: boolean;
  isOTrtl: boolean;
  isNTltr: boolean;
  isOTltr: boolean;
  isNTrtlAlignSource: boolean;
  isOTrtlAlignSource: boolean;
  isNTltrAlignSource: boolean;
  isOTltrAlignSource: boolean;
  isNTrtlAlignTarget: boolean;
  isOTrtlAlignTarget: boolean;
  isNTltrAlignTarget: boolean;
  isOTltrAlignTarget: boolean;
  isLeftPanelOpen: boolean;
  isRightPanelOpen: boolean;
  isBottomPanelOpen: boolean;
  toggleManuscript: Function;
  updateUIConfig: Function;
}

export const DefaultGridProps: GridProps = {
  isRTL: false,
  isNTrtl: false,
  isOTrtl: false,
  isNTltr: false,
  isOTltr: false,
  isNTrtlAlignSource: false,
  isOTrtlAlignSource: false,
  isNTltrAlignSource: false,
  isOTltrAlignSource: false,
  isNTrtlAlignTarget: false,
  isOTrtlAlignTarget: false,
  isNTltrAlignTarget: false,
  isOTltrAlignTarget: false,
  isLeftPanelOpen: false,
  isRightPanelOpen: false,
  isBottomPanelOpen: false,
  toggleManuscript: noop,
  updateUIConfig: noop,
};

export const DefaultGridState: GridState = {
  isNTrtl: false,
  isOTrtl: false,
  isNTltr: false,
  isOTltr: false,
  isNTrtlAlignSource: false,
  isOTrtlAlignSource: false,
  isNTltrAlignSource: false,
  isOTltrAlignSource: false,
  isNTrtlAlignTarget: false,
  isOTrtlAlignTarget: false,
  isNTltrAlignTarget: false,
  isOTltrAlignTarget: false,
  isLeftPanelOpen: false,
  isRightPanelOpen: false,
  isBottomPanelOpen: false,
};

interface UpdateUIConfigRequestAction {
  type: typeof UPDATE_UI_CONFIG_REQUEST;
}

interface UpdateUIConfigFailureAction {
  type: typeof UPDATE_UI_CONFIG_FAILURE;
  error: string;
}

interface UpdateUIConfigSuccessAction {
  type: typeof UPDATE_UI_CONFIG_SUCCESS;
}

export type GridActionTypes =
  | UpdateUIConfigRequestAction
  | UpdateUIConfigFailureAction
  | UpdateUIConfigSuccessAction;
