import { REHYDRATE } from './translation';
import {
  ManuscriptData,
  ManuscriptSuggestions,
  SyntaxGroupData,
  ProjectTranslationVerse,
  Gloss,
  TranslationLink,
} from '../shared/structs';

export const CHANGE_VERSE_TRANSLATION = 'CHANGE_VERSE_TRANSLATION';
export const UPDATE_VERSE = 'UPDATE_VERSE';
export const UPDATE_VERSE_REQUEST = 'UPDATE_VERSE_REQUEST';
export const UPDATE_VERSE_SUCCESS = 'UPDATE_VERSE_SUCCESS';
export const UPDATE_VERSE_FAILURE = 'UPDATE_VERSE_FAILURE';
export const DISMISS_VERSE_CHANGES = 'DISMISS_VERSE_CHANGES';
export const OPEN_EDITOR = 'OPEN_EDITOR';
export const CLOSE_EDITOR = 'CLOSE_EDITOR';
export const UNCHECK_COMPLETE_BOX = 'UNCHECK_COMPLETE_BOX';
export const SHOW_ERROR = 'SHOW_ERROR';
export const TRIGGER_ALIGNMENT_FLAG = 'TRIGGER_ALIGNMENT_FLAG';
export const DISMISS_ALIGNMENT = 'DISMISS_ALIGNMENT';
export const UPDATE_VERSE_STATUS = 'UPDATE_VERSE_STATUS';
export const UPDATE_VERSE_STATUS_REQUEST = 'UPDATE_VERSE_STATUS_REQUEST';
export const UPDATE_VERSE_STATUS_SUCCESS = 'UPDATE_VERSE_STATUS_SUCCESS';
export const UPDATE_VERSE_STATUS_FAILURE = 'UPDATE_VERSE_STATUS_FAILURE';
export const FETCH_SUGGESTION = 'FETCH_SUGGESTION';
export const FETCH_SUGGESTION_REQUEST = 'FETCH_SUGGESTION_REQUEST';
export const FETCH_SUGGESTION_SUCCESS = 'FETCH_SUGGESTION_SUCCESS';
export const FETCH_SUGGESTION_FAILURE = 'FETCH_SUGGESTION_FAILURE';
export const VERIFY_ALIGNMENT = 'VERIFY_ALIGNMENT';

interface ShowError {
  type: typeof SHOW_ERROR;
  error: string;
}

interface UncheckCompleteBox {
  type: typeof UNCHECK_COMPLETE_BOX;
  textId: string;
}

interface ChangeVerse {
  type: typeof CHANGE_VERSE_TRANSLATION;
  textId: string;
  verseTranslation: string;
}

interface UpdateVerse {
  type: typeof UPDATE_VERSE;
  projectId: string;
  textId: string;
  verseTranslation: string;
}

interface UpdateVerseRequest {
  type: typeof UPDATE_VERSE_REQUEST;
  textId: string;
  complete: boolean;
}

interface UpdateVerseSuccess {
  type: typeof UPDATE_VERSE_SUCCESS;
  textId: string;
}

interface UpdateVerseFailure {
  type: typeof UPDATE_VERSE_FAILURE;
  error: any;
}

interface OpenEditor {
  type: typeof OPEN_EDITOR;
  textId: string;
}

interface CloseEditor {
  type: typeof CLOSE_EDITOR;
}

interface UndefinedAction {
  type: typeof undefined;
}

interface TriggerAlignment {
  verseCode: string;
  type: typeof TRIGGER_ALIGNMENT_FLAG;
}

interface DismissAlignment {
  type: typeof DISMISS_ALIGNMENT;
}

interface VerifyAlignment {
  type: typeof VERIFY_ALIGNMENT;
  verseCode: string;
  links: TranslationLink;
}

// ********************* Update verse status - begin *********************

interface UpdateVerseStatusRequest {
  type: typeof UPDATE_VERSE_STATUS_REQUEST;
  textId: string;
  complete: boolean;
}

interface UpdateVerseStatusSuccess {
  type: typeof UPDATE_VERSE_STATUS_SUCCESS;
  textId: string;
}

interface UpdateVerseStatusFailure {
  type: typeof UPDATE_VERSE_STATUS_FAILURE;
  error: any;
}

interface UpdateVerseStatus {
  type: typeof UPDATE_VERSE_STATUS;
  projectId: string;
  textId: string;
  complete: boolean;
}

// ********************* Update verse status - end *********************

interface DismissVerseChanges {
  type: typeof DISMISS_VERSE_CHANGES;
}

// ********************* Fetch suggestion - begin *********************
interface FetchSuggestionRequest {
  type: typeof FETCH_SUGGESTION_REQUEST;
  textId: string; // aka: textId
}

interface FetchSuggestionSuccess {
  type: typeof FETCH_SUGGESTION_SUCCESS;
  data: ManuscriptSuggestions;
  textId: string;
}

interface FetchSuggestionFailure {
  type: typeof FETCH_SUGGESTION_FAILURE;
  error: any;
}

interface FetchSuggestion {
  type: typeof FETCH_SUGGESTION;
  projectId: string;
  textId: string; // aka: textId
  versification: string;
}
// ********************* Fetch suggestion - end *********************

export type VerseEditorTypes =
  | REHYDRATE
  | ChangeVerse
  | UpdateVerse
  | UpdateVerseRequest
  | UpdateVerseSuccess
  | UpdateVerseFailure
  | DismissVerseChanges
  | OpenEditor
  | CloseEditor
  | UncheckCompleteBox
  | ShowError
  | UndefinedAction
  | TriggerAlignment
  | DismissAlignment
  | VerifyAlignment
  | UpdateVerseStatus
  | UpdateVerseStatusRequest
  | UpdateVerseStatusSuccess
  | UpdateVerseStatusFailure
  | FetchSuggestionRequest
  | FetchSuggestionSuccess
  | FetchSuggestionFailure
  | FetchSuggestion;

export interface VerseEditorState {
  loading: boolean;
  editingMode?: boolean;
  verseId?: string;
  bookId?: string;
  chapter?: number;
  license?: string;
  licenseMorphology?: string;
  licenseSyntax?: string;
  verseManuscriptData?: ManuscriptData[];
  verseTranslation?: string;
  verseTextMap: Map<string, string>;
  verseCompleteMap: Map<string, boolean>;
  verseModifiedMap: Map<string, boolean>;
  projectId?: string;
  updateVerse?: any;
  openEditor?: any;
  closeEditor?: any;
  editingGbiVerseCode?: string | undefined;
  updating?: boolean;
  error?: string;
  updatingGbiVerseCode?: string;
  triggerAlignmentFlag?: boolean;
  triggerAlignment?: Function;
  showSuggestionList?: boolean;
  suggestionListTop?: number;
  suggestionListLeft?: number;
  translation?: string;
  autoSuggestionMap: Map<string, ManuscriptSuggestions>;
  random?: number;
  displayRevisionHistory?: boolean;
  showError?: Function;
  translationArea?: HTMLDivElement | null;
}

export interface VerseEditorProps {
  loading: boolean;
  editingMode?: boolean;
  verseId?: string;
  bookId?: string;
  chapter?: number;
  license?: string;
  licenseMorphology?: string;
  licenseSyntax?: string;
  verseManuscriptData?: ManuscriptData[];
  syntaxGroupData: SyntaxGroupData[];
  verseTranslation?: string;
  verseTextMap: Map<string, string>;
  verseCompleteMap: Map<string, boolean>;
  verseModifiedMap: Map<string, boolean>;
  changeVerseTranslation?: any;
  triggerAlignment: Function;
  projectId?: string;
  updateVerse?: any;
  openEditor?: any;
  closeEditor?: any;
  editingGbiVerseCode?: string | undefined;
  updating?: boolean;
  uncheckCompleteBox?: any;
  random?: any;
  showError?: Function;
  error?: any;
  updatingGbiVerseCode?: string;
  updateVerseStatus?: any;
  fetchSuggestion?: any;
  fetchSuggestionFailure?: any;
  autoSuggestionMap: Map<string, ManuscriptSuggestions>;
  textId: string;
  verses: ProjectTranslationVerse[];
  displayRevisionHistory?: boolean;
  glosses: Gloss[];
  fetchGlosses: Function;
  isNTrtl: boolean;
  isOTrtl: boolean;
  isNTltr: boolean;
  isOTltr: boolean;
  isRTL: boolean;
  isProjectReadOnly: boolean;
  translationArea?: HTMLDivElement | null;
}

export interface SuggestionDataObj {
  targetText: string;
  verses: [];
}
