import { ManuscriptData, Gloss } from '../shared/structs';

export interface MorphologyViewState {
  isOldTestament: boolean;
  manuscriptDataByVerse: Record<string, ManuscriptData[]>;
}

export interface MorphologyViewProps {
  isOldTestament: boolean;
  manuscriptDataByVerse: Record<string, ManuscriptData[]>;
  lexiconData: Record<string, Record<string, string>>;
  glosses: Gloss[];
}

/* eslint import/prefer-default-export: "off" */
export const DefaultMorphologyViewProps = {
  isOldTestament: false,
  manuscriptDataByVerse: {},
  lexiconData: {},
  glosses: [],
};
