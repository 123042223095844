import { PASSWORD_RESET_COMPLETES, PASSWORD_RESET_DISMISS_MESSAGE } from '../types';
import firebase from '../lib/firebase';

const FirebaseAuth = firebase.createService('auth');

type DispatchResult = Promise<any>;

export function passwordResetAction(emailAddress: string): any {
  //
  return async (dispatch: any): DispatchResult => {
    //
    const finish = (msg: string): DispatchResult =>
      dispatch({
        type: PASSWORD_RESET_COMPLETES,
        passwordResetErrorMessage: msg,
      });

    const finishWithError = finish;
    const finishWithSuccess = () => finish('');

    try {
      //
      await (await FirebaseAuth.init()).sendPasswordResetEmail(emailAddress);

      finishWithSuccess();
      //
    } catch (error) {
      finishWithError(error.message);
    }
  };
}

export function messageResetAction(): any {
  return (dispatch: any): DispatchResult => dispatch({ type: PASSWORD_RESET_DISMISS_MESSAGE });
}
