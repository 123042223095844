import { UndefinedAction } from './lightbox';

export const OPEN_REVISION_HISTORY = 'OPEN_REVISION_HISTORY';
export const CLOSE_REVISION_HISTORY = 'CLOSE_REVISION_HISTORY';
export const FETCH_REVISION_HISTORY = 'FETCH_REVISION_HISTORY';
export const FETCH_REVISION_HISTORY_REQUEST = 'FETCH_REVISION_HISTORY_REQUEST';
export const FETCH_REVISION_HISTORY_FAILURE = 'FETCH_REVISION_HISTORY_FAILURE';
export const FETCH_REVISION_HISTORY_SUCCESS = 'FETCH_REVISION_HISTORY_SUCCESS';

// ********************* Revision History - begin *********************
interface OpenRevisionHistory {
  type: typeof OPEN_REVISION_HISTORY;
}

interface CloseRevisionHistory {
  type: typeof CLOSE_REVISION_HISTORY;
}

interface FetchRevisionHistoryDataRequest {
  type: typeof FETCH_REVISION_HISTORY_REQUEST;
}
interface FetchRevisionHistoryDataSuccess {
  type: typeof FETCH_REVISION_HISTORY_SUCCESS;
  data: any[];
}
interface FetchRevisionHistoryDataFailure {
  type: typeof FETCH_REVISION_HISTORY_FAILURE;
  error: any;
}
interface FetchRevisionHistoryData {
  type: typeof FETCH_REVISION_HISTORY;
  projectId: string;
  bookId: string;
  chapter: number;
}

// ********************* Revision History - end *********************

export type RevisionHistoryTypes =
  | UndefinedAction
  | OpenRevisionHistory
  | CloseRevisionHistory
  | FetchRevisionHistoryData
  | FetchRevisionHistoryDataRequest
  | FetchRevisionHistoryDataFailure
  | FetchRevisionHistoryDataSuccess;

export interface RevisionHistoryState {
  isLoading: boolean;
  projectId?: string;
  textId?: string;
  display?: boolean;
  error?: string;
  revisionHistoryArray?: any[] | undefined;
  openRevisionHistoryFunc: Function;
  closeRevisionHistoryFunc: Function;
  fetchRevisionHistoryDataFunc: Function;
}

export interface RevisionHistoryProps {
  isLoading?: boolean;
  editingMode?: boolean;
  projectId?: string;
  textId?: string;
  display?: boolean;
  error?: string;
  revisionHistoryArray?: any[] | undefined;
  openRevisionHistoryFunc: Function;
  closeRevisionHistoryFunc: Function;
  fetchRevisionHistoryDataFunc: Function;
}
