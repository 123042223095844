import * as types from '../types';
import firebase from '../lib/firebase';

const FirebaseDb = firebase.createService('db');
const FirebaseAuth = firebase.createService('auth');

// ********************* Revision History - begin *********************
export const openRevisionHistoryAction = (): types.RevisionHistoryTypes => {
  return {
    type: types.OPEN_REVISION_HISTORY,
  };
};

export const closeRevisionHistoryAction = (): types.RevisionHistoryTypes => {
  return {
    type: types.CLOSE_REVISION_HISTORY,
  };
};
// ********************* Revision History - end *********************

// Fetch revision history of a verse
export const fetchRevisionHistoryDataRequestAction = (): types.RevisionHistoryTypes => {
  return {
    type: types.FETCH_REVISION_HISTORY_REQUEST,
  };
};
export const fetchRevisionHistoryDataFailureAction = (
  error: string,
): types.RevisionHistoryTypes => {
  return {
    type: types.FETCH_REVISION_HISTORY_FAILURE,
    error,
  };
};
export const fetchRevisionHistoryDataSuccessAction = (data: any[]): types.RevisionHistoryTypes => {
  return {
    type: types.FETCH_REVISION_HISTORY_SUCCESS,
    data,
  };
};
export const fetchRevisionHistoryDataAction = (projectId: string, textId: string): any => {
  return async (dispatch: any): Promise<any> => {
    try {
      dispatch(fetchRevisionHistoryDataRequestAction());
      const currentUser = await (await FirebaseAuth.init()).getCurrentSignedInUser();
      if (currentUser && Object.prototype.hasOwnProperty.call(currentUser, 'uid')) {
        const result = await (await FirebaseDb.init()).getTranslationVerseData(
          currentUser.uid,
          projectId,
          textId,
        );
        const { revisionHistory } = result.data;
        dispatch(fetchRevisionHistoryDataSuccessAction(revisionHistory));
      } else {
        throw new Error(types.USER_IS_NOT_SIGNED);
      }
    } catch (error) {
      if (Object.prototype.hasOwnProperty.call(error, 'message')) {
        if (error.message === types.USER_IS_NOT_SIGNED) {
          dispatch(
            fetchRevisionHistoryDataFailureAction(
              `You must be signed in to fetch translation list.`,
            ),
          );
        } else {
          dispatch(fetchRevisionHistoryDataFailureAction(error.message));
        }
      } else if (typeof error === 'string') {
        dispatch(fetchRevisionHistoryDataFailureAction(error));
      } else {
        dispatch(fetchRevisionHistoryDataFailureAction(String(error)));
      }
    }
  };
};
