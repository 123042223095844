/* istanbul ignore file */
import * as types from '../types';
import firebase from '../lib/firebase';
import * as bookFunc from '../lib/book';
import { Gloss } from '../shared/structs';

const FirebaseDb = firebase.createService('db');
const FirebaseAuth = firebase.createService('auth');

export const fetchGlossRequestAction = (textId: string): types.GlossTypes => {
  return {
    type: types.FETCH_GLOSS_REQUEST,
    textId,
  };
};

export const fetchGlossSuccessAction = (
  textId: string,
  data: Gloss[],
  version: string,
): types.GlossTypes => {
  return {
    type: types.FETCH_GLOSS_SUCCESS,
    data,
    textId,
    version,
  };
};

export const fetchGlossFailureAction = (error: any): types.GlossTypes => {
  return {
    type: types.FETCH_GLOSS_FAILURE,
    error,
  };
};

export const fetchGlossAction = (bookId: string, chapterNumber: number, version: string): any => {
  return async (dispatch: any): Promise<any> => {
    const textId = bookFunc.generateBookChapterCode(bookId, chapterNumber);
    try {
      dispatch(fetchGlossRequestAction(textId));
      const currentUser = await (await FirebaseAuth.init()).getCurrentSignedInUser();

      if (currentUser && Object.prototype.hasOwnProperty.call(currentUser, 'uid')) {
        const glosses = await (await FirebaseDb.init()).getGlosses(textId, version);
        dispatch(fetchGlossSuccessAction(textId, glosses, version));
      } else {
        throw new Error(types.USER_IS_NOT_SIGNED);
      }
    } catch (error) {
      if (Object.prototype.hasOwnProperty.call(error, 'message')) {
        if (error.message === types.USER_IS_NOT_SIGNED) {
          dispatch(fetchGlossFailureAction('error.mustBeSignedToMakeChanges'));
        } else {
          dispatch(fetchGlossFailureAction(error.message));
        }
      } else if (typeof error === 'string') {
        dispatch(fetchGlossFailureAction(error));
      } else {
        dispatch(fetchGlossFailureAction(String(error)));
      }
    }
  };
};

export const fetchGlossVersionsRequestAction = (): types.GlossTypes => {
  return {
    type: types.FETCH_GLOSS_VERSIONS_REQUEST,
  };
};

export const fetchGlossVersionsSuccessAction = (data: any): types.GlossTypes => {
  return {
    type: types.FETCH_GLOSS_VERSIONS_SUCCESS,
    data,
  };
};

export const fetchGlossVersionsFailureAction = (error: any): types.GlossTypes => {
  return {
    type: types.FETCH_GLOSS_VERSIONS_FAILURE,
    error,
  };
};

export const fetchGlossVersionsAction = (): any => {
  return async (dispatch: any): Promise<any> => {
    try {
      dispatch(fetchGlossVersionsRequestAction());
      const currentUser = await (await FirebaseAuth.init()).getCurrentSignedInUser();

      if (currentUser && Object.prototype.hasOwnProperty.call(currentUser, 'uid')) {
        const glossVersions = await (await FirebaseDb.init()).getGlossVersions();
        dispatch(fetchGlossVersionsSuccessAction(glossVersions));
      } else {
        throw new Error(types.USER_IS_NOT_SIGNED);
      }
    } catch (error) {
      if (Object.prototype.hasOwnProperty.call(error, 'message')) {
        if (error.message === types.USER_IS_NOT_SIGNED) {
          dispatch(fetchGlossVersionsFailureAction('error.mustBeSignedToMakeChanges'));
        } else {
          dispatch(fetchGlossVersionsFailureAction(error.message));
        }
      } else if (typeof error === 'string') {
        dispatch(fetchGlossVersionsFailureAction(error));
      } else {
        dispatch(fetchGlossVersionsFailureAction(String(error)));
      }
    }
  };
};
