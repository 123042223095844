import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import * as actions from '../../actions';
import { AppState } from '../../reducers';
import * as functions from './functions';
import { DictContentType, DictDataResponse } from './dataType';

const ToolBar = (
  searchResult: DictDataResponse,
  dispatch: any,
  showSubMenu: boolean,
): React.ReactElement => {
  const disabled = Object.keys(searchResult).length < 1;
  let className = 'btn middle-animate btn-tool btn-tool-selected';
  let messageId = 'dict.hideMenu';
  if (!showSubMenu) {
    className = 'btn middle-animate btn-tool ';
    messageId = 'dict.showMenu';
  }

  return (
    <FormattedMessage id={messageId}>
      {(message: string): React.ReactElement => (
        <OverlayTrigger
          key="search-concordance-btn-show-hide-menu"
          placement="top"
          overlay={<Tooltip id="tooltip-search-btn-show-hide-menu">{message}</Tooltip>}
        >
          <button
            type="button"
            className={className}
            disabled={disabled}
            onClick={(): void => {
              dispatch(actions.toggleSubMenuAction(showSubMenu));
            }}
          >
            <i className="fas fa-stream" />
          </button>
        </OverlayTrigger>
      )}
    </FormattedMessage>
  );
};

const SearchBox = (): React.ReactElement => {
  const { searchTerm, projectId, searchResult, showSubMenu } = useSelector((state: AppState) => {
    return { ...state.dictionary, ...state.translation };
  });

  const dispatch = useDispatch();

  const contentType = functions.detectContentType(searchTerm);

  const cssClass = ['form-control', 'dictionary-search-input'];
  if (contentType === DictContentType.hebrew) {
    cssClass.push('rtl');
  }

  return (
    <FormattedMessage id="dict.search.placeholder">
      {(placeholder: string): React.ReactElement => (
        <div className="form-group search-box ">
          <form
            onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
              e.preventDefault();
              dispatch(actions.searchBoxSubmitsAction(projectId, searchTerm));
            }}
          >
            <table>
              <tbody>
                <tr>
                  <td style={{ width: 'auto' }}>
                    <button
                      type="submit"
                      className="btn middle-animate submit-btn"
                      disabled={searchTerm.trim().length < 1}
                    >
                      <i className="fas fa-search" />
                    </button>

                    <input
                      type="text"
                      className={cssClass.join(' ')}
                      key="dictionary-search-input"
                      placeholder={placeholder}
                      value={searchTerm}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                        dispatch(actions.searchBoxChangesAction(e.target.value));
                      }}
                    />

                    {searchTerm && (
                      <button
                        type="button"
                        className="btn middle-animate clear-btn"
                        onClick={(): void => {
                          dispatch(actions.searchBoxClearAction());
                        }}
                      >
                        <i className="fas fa-times" />
                      </button>
                    )}
                  </td>
                  <td style={{ width: '25px' }}>{ToolBar(searchResult, dispatch, showSubMenu)}</td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      )}
    </FormattedMessage>
  );
};

export default React.memo(SearchBox);
