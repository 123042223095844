import { ManuscriptData } from '../shared/structs';

export interface ManuscriptDisplay {
  text: string;
  positions: string[];
}
const getDisplaySegments = (verseManuscriptData: ManuscriptData[]): ManuscriptDisplay[] => {
  return verseManuscriptData?.reduce(
    (accumulator: ManuscriptDisplay[], verseDatum): ManuscriptDisplay[] => {
      const primaryPosition = verseDatum.positionId.substring(0, 3);
      const secondaryPosition = verseDatum.positionId.slice(3, verseDatum.positionId.length);
      if (secondaryPosition === '' || secondaryPosition === '1') {
        const positions = [];
        positions.push(verseDatum.positionId);
        return accumulator.concat({
          text: verseDatum.segment.trim(),
          positions,
        });
      }
      return accumulator.map(
        (item: ManuscriptDisplay): ManuscriptDisplay => {
          if (
            item.positions.filter((position: string): boolean => {
              return position.substring(0, 3) === primaryPosition;
            }).length
          ) {
            const combinedText = item.text.trim() + verseDatum.segment.trim();
            const prevPositions = item.positions;
            return {
              text: combinedText,
              positions: prevPositions.concat(verseDatum.positionId),
            };
          }
          return item;
        },
      );
    },
    new Array<ManuscriptDisplay>(),
  );
};

export default getDisplaySegments;
