import * as types from '../types';
import firebase from '../lib/firebase';

const FirebaseDb = firebase.createService('db');
const FirebaseAuth = firebase.createService('auth');

export const closeNavigator = (): types.NavigatorActionTypes => {
  return {
    type: types.CLOSE_NAVIGATOR,
  };
};

export const displayNavigator = (): types.NavigatorActionTypes => {
  return {
    type: types.DISPLAY_NAVIGATOR,
  };
};

export const changeBook = (bookId: string): types.NavigatorActionTypes => {
  return {
    type: types.CHANGE_BOOK,
    bookId,
  };
};

export const changeChapter = (chapter: number): types.NavigatorActionTypes => {
  return {
    type: types.CHANGE_CHAPTER,
    chapter,
  };
};

export const searchBook = (keyword: string): types.NavigatorActionTypes => {
  return {
    type: types.SEARCH_BOOK,
    keyword,
  };
};

export const editBookName = (
  projectId: string,
  editingBookId: string,
): types.NavigatorActionTypes => {
  return {
    type: types.EDIT_BOOK_NAME,
    projectId,
    editingBookId,
  };
};

export const changeBookName = (
  projectId: string,
  editingBookId: string,
  translatedBookName: string,
): types.NavigatorActionTypes => {
  return {
    type: types.CHANGE_BOOK_NAME,
    projectId,
    editingBookId,
    translatedBookName,
  };
};

export const cancelBookName = (
  projectId: string,
  editingBookId: string,
): types.NavigatorActionTypes => {
  return {
    type: types.CANCEL_BOOK_NAME,
    projectId,
    editingBookId,
  };
};

// ************ FETCH BOOK NAMES - BEGIN ************
export const fetchBookNamesRequest = (): types.NavigatorActionTypes => {
  return {
    type: types.FETCH_BOOK_NAMES_REQUEST,
  };
};

export const fetchBookNamesFailure = (error: string): types.NavigatorActionTypes => {
  return {
    type: types.FETCH_BOOK_NAMES_FAILURE,
    error,
  };
};

export const fetchBookNamesSuccess = (
  translatedBookNameMap: types.BookNameData,
): types.NavigatorActionTypes => {
  return {
    type: types.FETCH_BOOK_NAMES_SUCCESS,
    translatedBookNameMap,
  };
};

export const fetchBookNamesAction = (projectId: string): any => {
  return async (dispatch: any): Promise<any> => {
    try {
      dispatch(fetchBookNamesRequest());

      if (!projectId) {
        dispatch(fetchBookNamesFailure('error.projectIdIsRequired'));
      } else {
        const currentUser = await (await FirebaseAuth.init()).getCurrentSignedInUser();

        if (currentUser && Object.prototype.hasOwnProperty.call(currentUser, 'uid')) {
          // user exits in system
          const data: types.BookNameData = await (
            await FirebaseDb.init()
          ).getProjectTranslatedBookNames(currentUser.uid, projectId);

          dispatch(fetchBookNamesSuccess(data));
        } else {
          throw new Error(types.USER_IS_NOT_SIGNED);
        }
      }
    } catch (error) {
      if (Object.prototype.hasOwnProperty.call(error, 'message')) {
        if (error.message === types.USER_IS_NOT_SIGNED) {
          dispatch(fetchBookNamesFailure('error.signInIsRequiredToAccessProject'));
        } else {
          dispatch(fetchBookNamesFailure(error.message));
        }
      } else if (typeof error === 'string') {
        dispatch(fetchBookNamesFailure(error));
      } else {
        dispatch(fetchBookNamesFailure(String(error)));
      }
    }
  };
};

// ************ SAVE BOOK NAME - BEGIN ************
export const saveBookNameRequest = (): types.NavigatorActionTypes => {
  return {
    type: types.SAVE_BOOK_NAME_REQUEST,
  };
};

export const saveBookNameFailure = (error: string): types.NavigatorActionTypes => {
  return {
    type: types.SAVE_BOOK_NAME_FAILURE,
    error,
  };
};

export const saveBookNameSuccess = (): types.NavigatorActionTypes => {
  return {
    type: types.SAVE_BOOK_NAME_SUCCESS,
  };
};

export const saveBookNameAction = (
  projectId: string,
  editingBookId: string,
  translatedBookName: string,
): any => {
  return async (dispatch: any): Promise<any> => {
    try {
      dispatch(saveBookNameRequest());

      if (!projectId) {
        dispatch(saveBookNameFailure('error.projectIdIsRequired'));
      } else if (!editingBookId) {
        dispatch(saveBookNameFailure('error.bookIdIsRequired'));
      } else if (!translatedBookName) {
        dispatch(saveBookNameFailure('error.bookNameCannotBeEmpty'));
      } else {
        const currentUser = await (await FirebaseAuth.init()).getCurrentSignedInUser();

        if (currentUser && Object.prototype.hasOwnProperty.call(currentUser, 'uid')) {
          // user exits in system
          await (await FirebaseDb.init()).saveProjectTranslatedBookName(
            currentUser.uid,
            projectId,
            editingBookId,
            translatedBookName,
          );

          dispatch(fetchBookNamesAction(projectId));
          dispatch(saveBookNameSuccess());
        } else {
          throw new Error(types.USER_IS_NOT_SIGNED);
        }
      }
    } catch (error) {
      if (Object.prototype.hasOwnProperty.call(error, 'message')) {
        if (error.message === types.USER_IS_NOT_SIGNED) {
          dispatch(saveBookNameFailure('error.signInIsRequiredToAccessProject'));
        } else {
          dispatch(saveBookNameFailure(error.message));
        }
      } else if (typeof error === 'string') {
        dispatch(saveBookNameFailure(error));
      } else {
        dispatch(saveBookNameFailure(String(error)));
      }
    }
  };
};
