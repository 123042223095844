// Types and constants associated with the Password Reset component.

// App state associated with the Password
// Reset component.  (The component also uses
// emailAddr from the signup state.)
//
export interface PasswordResetState {
  passwordResetErrorMessage: string;
  passwordResetSuccessIndication: boolean;
}

// Properties of the Password Reset component.
//
export interface PasswordResetProps {
  // Properties computed from the state.
  emailAddr: string;
  passwordResetErrorMessage: string;
  passwordResetSuccessIndication: boolean;
  // Injected action functions, which have
  // bound the dispatch function.
  emailAddrUpdate?: any;
  passwordReset?: any;
  messageReset?: any;
}

// Actions for the Password Reset component.

export const PASSWORD_RESET_COMPLETES = 'PASSWORD_RESET_COMPLETES';
export const PASSWORD_RESET_DISMISS_MESSAGE = 'PASSWORD_RESET_DISMISS_MESSAGE';

// The request to reset the password has completed,
// successfully or otherwise.
//
interface PasswordResetCompletesAction {
  type: typeof PASSWORD_RESET_COMPLETES;

  // Successful completion if error message is ''.
  passwordResetErrorMessage: string;
}

// The notifier with the error message or indication
// of success should be dismissed.
//
interface PasswordResetDismissMessageAction {
  type: typeof PASSWORD_RESET_DISMISS_MESSAGE;
}

export type PasswordResetActionTypes =
  | PasswordResetCompletesAction
  | PasswordResetDismissMessageAction;
