import { MemoryVerseReference } from '../../shared/structs/projectTranslations';

export interface GenericMap<V> {
  [key: string]: V;
}
export interface DictData {
  strongs: string;
  strongsX: string;
  lemma: string;
  root: string;
  term: string;
  segment: string;
  english: string;
  cat: string;
  segmentVerses: SegmentVerses;
}

export interface SegmentVerse {
  textId: string;
  positionId: string;
  strongs: string;
  strongsX: string;
  lemma: string;
  segment: string;
  english: string;
  cat: string;
}

export interface SegmentVerses {
  [textId: string]: SegmentVerse;
}

export interface Dict {
  [root: string]: DictData;
}

export enum DictContentType {
  strongs,
  hebrew,
  greek,
  translation,
}
export interface DictDataElement {
  targetText: string;
  sourceText: string;
  count: number;
  sourceManuscript: string;
  sourceLemma: string;
  english: string;
  verses: MemoryVerseReference[];

  sourceSegments?: string[];
  sourceSegmentsCount?: number;
  targetSegmentsCount?: number;
  totalSegmentsCount?: number;
  targetSegments?: string[];
}

export interface DictDataResponseItem {
  segment: string;
  data: DictDataElement[];
  type?: number;
  targetText?: string;
  strongsX?: string;
}

export interface DictDataResponse {
  [strongsX: string]: DictDataResponseItem;
}
