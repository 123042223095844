import React, { ReactElement } from 'react';
// import { Link } from 'react-router-dom';
// import { Tab, Nav, Container, Row, Col } from 'react-bootstrap';
import { Container, Row, Col } from 'react-bootstrap';

/*
const IconHeader = (props: { icon: string; subtitle: string }): ReactElement => {
  const { icon, subtitle } = props;
  return (
    <div className="icon-container d-flex flex-column justify-content-center align-items-center">
      <div className="fa-stack fa-3x">
        <i className="fa fa-circle fa-stack-2x icon-background" />
        <i className={`fa fa-${icon} fa-stack-1x icon-foreground`} />
      </div>
      <div className="icon-subtitle">{subtitle}</div>
      <div className="icon-learn-more">Learn More</div>
    </div>
  );
};
*/

const Home = (): ReactElement => {
  // prettier-ignore
  return (
    <Container className="home-page">
      <Row className="justify-content-md-center">
        <Col md={8}>
          <h1 id="Welcome">Welcome to YouTranslate.Bible</h1>

          <p>
            <strong>YouTranslate.Bible (YTB)</strong>
            {' '}
            is a concept for an online machine-assisted Bible translation drafting and checking tool.
            It is designed with a
            simple user interface to keep the complexity of Bible translation in the background while helping
            translators do quality translation.
          </p>
          <h2 id="Drafting">Simple Translation Memory-Assisted Drafting</h2>

          <p>
            Anyone can experiment with YTB as a Bible translation drafting tool with just a valid e-mail account. It is
            free and easy to use, and offers basic machine assistance in translation, in particular translation
            memory-assistance. However, it is not intended to be a one-stop tool that meets all the needs of a
            Bible translation team from beginning to end. A Bible translation team should make sure its needs
            in the following areas are or will be well-met elsewhere:
          </p>
          <ul>
            <li>Needs for training in the theory and practice of translation.</li>
            <li>Needs for project setup and project management. </li>
            <li>Needs for the formatting and publishing of your work.</li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
