import React, { ReactElement } from 'react';
import { Popover, OverlayTrigger, Tabs, Tab } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { AppState } from '../../reducers';

import * as types from '../../types';
import SegmentGroup from './segmentGroup';
import Segment from './segment';
import { SyntaxGroupData, ManuscriptData } from '../../shared/structs';
import { getVerseLinkedWords } from '../../shared/functions';
import { VerseIdParser } from '../../shared/verseIdParser';

export class SourceTextComp extends React.Component<types.SourceTextProps, types.SourceTextState> {
  private direction = false;

  // eslint-disable-next-line react/sort-comp
  private tabTitle(
    type: 'natural' | 'functional' | 'switchRTL' | 'switchFunctionalRTL',
  ): ReactElement {
    const { license, licenseMorphology, licenseSyntax } = this.props;
    // const messageId = type === 'natural' ? 'sourceText.view' : 'sourceText.viewStructure';
    // const icon = type === 'natural' ? 'lines' : 'horizontal';
    let messageId = '';
    let icon = '';

    switch (type) {
      case 'natural':
        messageId = 'sourceText.view';
        icon = 'lines';
        break;
      case 'functional':
        messageId = 'sourceText.viewStructure';
        icon = 'horizontal';
        break;
      default:
        break;
    }

    return (
      <OverlayTrigger
        rootClose
        key={type}
        trigger={['hover', 'focus']}
        placement="left"
        popperConfig={{
          modifiers: { preventOverflow: {} },
        }}
        // prettier-ignore
        overlay={(
          <Popover id={`${type}-view-tab`}>
            <Popover.Title as="h3">
              <FormattedMessage id={messageId} />
            </Popover.Title>
            <Popover.Content className="attribution-text">
              <p>{license}</p>
              <p>{licenseMorphology}</p>
              <p>{licenseSyntax}</p>
            </Popover.Content>
          </Popover>
        )}
      >
        <i className={`fas fa-grip-${icon}`} />
      </OverlayTrigger>
    );
  }

  private renderFlatSyntax(): ReactElement {
    const { manuscriptSuggestions, verseManuscriptData, textId, glosses, verses } = this.props;
    let linkedWords: Record<number, string> = {};
    if (textId && verses) {
      linkedWords = getVerseLinkedWords(textId, verses);
    }

    const stack: ReactElement[] = [];
    let n = 0;
    verseManuscriptData.forEach((element: ManuscriptData, index: number): void => {
      n += 1;
      const linkSuggestions = manuscriptSuggestions.linkSuggestions[element.strongsX];
      const memorySuggestions = manuscriptSuggestions.memorySuggestions[element.strongsX];
      const linkedTargetWords = linkedWords[index];

      stack.push(
        <Segment
          textId={textId}
          position={n}
          manuscriptData={element}
          linkSuggestions={linkSuggestions}
          memorySuggestions={memorySuggestions}
          key={element.positionId}
          glosses={glosses}
          linkedTargetWords={linkedTargetWords}
        />,
      );
    });

    let classvar = 'auto-suggestion-concordance flat';
    if (this.direction) {
      // reverse the stack
      // stack = stack.reverse();
      classvar = 'auto-suggestion-concordance flat-rtl';
    }

    return (
      <div className={classvar} key="auto-suggestion-concordance">
        {stack}
      </div>
    );
  }

  private renderNestedSyntax(): ReactElement {
    const {
      manuscriptSuggestions,
      verseManuscriptData,
      syntaxGroupData,
      textId,
      glosses,
    } = this.props;

    const segmentGroups0 = syntaxGroupData.filter((datum) => datum.level === 0);
    let segmentGroups = segmentGroups0.map(
      (syntaxGroupDatum: SyntaxGroupData): ReactElement => {
        let childSegmentGroups = syntaxGroupData
          .filter((datum) => datum.level > 0)
          .filter(
            (datum) =>
              datum.startIndex >= syntaxGroupDatum.startIndex &&
              datum.endIndex <= syntaxGroupDatum.endIndex,
          );

        if (this.direction) {
          childSegmentGroups = childSegmentGroups.reverse();
        }
        return (
          <SegmentGroup
            textId={textId}
            key={`segment-group-${syntaxGroupDatum.startIndex}-${syntaxGroupDatum.endIndex}`}
            manuscriptSuggestions={manuscriptSuggestions}
            verseManuscriptData={verseManuscriptData || []}
            allSyntaxGroupData={syntaxGroupData}
            syntaxGroup={syntaxGroupDatum}
            childSyntaxGroupData={childSegmentGroups}
            glosses={glosses}
            renderDirection={this.direction}
          />
        );
      },
    );

    let classvar = 'auto-suggestion-concordance';
    if (this.direction) {
      // reverse the stack
      segmentGroups = segmentGroups.reverse();
      classvar = 'auto-suggestion-concordance nested-rtl';
    }

    return (
      <div className={classvar} key="auto-suggestion-concordance">
        {segmentGroups}
      </div>
    );
  }

  public render(): ReactElement {
    const { isRTL, textId, isNTrtl, isOTrtl, isNTltr, isOTltr } = this.props;
    // are we in OT or NT?
    const verseIdParser = new VerseIdParser();
    const testament = verseIdParser.isNT(textId) ? 'nt' : 'ot';
    // set the right-to-left variable
    if (isRTL) {
      this.direction = isRTL;
      // right to left direction 'fa-align-left' (reverse)
      if (testament === 'ot' && isOTrtl === true) {
        this.direction = false;
      } else if (testament === 'nt' && isNTrtl === true) {
        this.direction = false;
      }
    } else {
      this.direction = false;
      // right to left direction 'fa-align-left' (reverse)
      if (testament === 'ot' && isOTltr === true) {
        this.direction = true;
      } else if (testament === 'nt' && isNTltr === true) {
        this.direction = true;
      }
    }

    return (
      <Tabs defaultActiveKey="natural" id="verse-syntax" variant="pills">
        <Tab
          mountOnEnter
          eventKey="natural"
          id="natural-syntax-display"
          title={this.tabTitle('natural')}
        >
          {this.renderFlatSyntax()}
        </Tab>
        <Tab
          mountOnEnter
          eventKey="functional"
          id="functional-syntax-display"
          title={this.tabTitle('functional')}
        >
          {this.renderNestedSyntax()}
        </Tab>
      </Tabs>
    );
  }
}

const mapStateToProps = (state: AppState): any => {
  const props = {
    verses: state.translation.verseTranslations,
  };

  return props;
};
const SourceText = connect(mapStateToProps, {})(SourceTextComp);
export default SourceText;
