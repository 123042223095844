/* eslint-disable import/prefer-default-export */
import React, { ReactElement } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import moment from 'moment';
import { Modal } from 'react-bootstrap';
import * as types from '../../types';
import * as actions from '../../actions';
import { AppState } from '../../reducers';
import Spinner from '../spinner';
import { nameInitial } from '../../lib/user';
import { BookInfo, VerseIdParser } from '../../shared/verseIdParser';

const verseIdParser: VerseIdParser = new VerseIdParser();

export class RevisionHistoryApp extends React.Component<
  types.RevisionHistoryProps,
  types.RevisionHistoryState
> {
  public openRevisionHistory(): void {
    const { openRevisionHistoryFunc, fetchRevisionHistoryDataFunc, projectId, textId } = this.props;

    if (openRevisionHistoryFunc) {
      openRevisionHistoryFunc();
    }

    if (fetchRevisionHistoryDataFunc) {
      fetchRevisionHistoryDataFunc(projectId, textId);
    }
  }

  public closeRevisionHistory(): void {
    const { closeRevisionHistoryFunc } = this.props;

    if (closeRevisionHistoryFunc) {
      closeRevisionHistoryFunc();
    }
  }

  public displayLightbox(): ReactElement {
    const { isLoading, revisionHistoryArray, display, textId } = this.props;

    return (
      <Modal
        size="lg"
        show={display}
        onHide={(): void => {
          this.closeRevisionHistory();
        }}
        key="lightbox-revision-history"
        dialogClassName="lightbox-revision-history"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="modal-title">
              <FormattedMessage id="revisionHistory" />
              {((): ReactElement => {
                if (textId) {
                  const result: BookInfo = verseIdParser.getReadableReferenceForGbiId(textId);
                  return (
                    <>
                      <span> - </span>
                      <FormattedMessage id={result.book} />
                      {` ${result.ref}`}
                    </>
                  );
                }

                return <></>;
              })()}
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {((): ReactElement => {
            if (revisionHistoryArray && revisionHistoryArray.length) {
              revisionHistoryArray.sort((a: any, b: any) => {
                return b.createdAt.seconds - a.createdAt.seconds;
              });

              return (
                <table className="table table-hover">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" style={{ width: '5%' }}>
                        #
                      </th>
                      <th scope="col">
                        <FormattedMessage id="revisionHistory.translation" />
                      </th>
                      <th scope="col" style={{ width: '25%' }}>
                        <FormattedMessage id="revisionHistory.lastChanged" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {((): ReactElement[] => {
                      let n = revisionHistoryArray.length + 1;
                      return revisionHistoryArray.map((history) => {
                        n -= 1;
                        return (
                          <tr key={`revision-history-row-${n}`}>
                            <th scope="row">
                              <sub>{n}</sub>
                            </th>
                            <td>{history.text}</td>
                            <td>
                              <p className="username">{nameInitial(history.userDisplayName)}</p>
                              <p className="date">
                                {((): string | undefined => {
                                  return moment(history.createdAt.seconds * 1000).format(
                                    'MM/DD/YYYY @ hh:mm a',
                                  );
                                })()}
                              </p>
                            </td>
                          </tr>
                        );
                      });
                    })()}
                  </tbody>
                </table>
              );
            }

            return (
              <div className="card">
                <div className="card-body text-center">
                  <FormattedMessage id="revisionHistory.noData" />
                </div>
              </div>
            );
          })()}

          {isLoading && <Spinner />}
        </Modal.Body>
      </Modal>
    );
  }

  public render(): ReactElement {
    return (
      <>
        <button
          type="button"
          className="btn btn-link btn-link-ytb"
          onClick={(): void => {
            this.openRevisionHistory();
          }}
        >
          <i className="fas fa-history fa-lg" />
          <FormattedMessage id="revisionHistory" />
        </button>

        {this.displayLightbox()}
      </>
    );
  }
}

export const mapStateToProps = (state: AppState): types.RevisionHistoryState => {
  const { displayRevisionHistory, editingGbiVerseCode } = state.verseEditor;
  const { projectId } = state.translation;

  const props = {
    ...state.revisionHistory,
    display: displayRevisionHistory,
    projectId,
    textId: editingGbiVerseCode,
  };

  return props;
};

export const mapDispatchToProps = (dispatch: Dispatch): types.RevisionHistoryProps => ({
  openRevisionHistoryFunc: (): void => {
    dispatch(actions.openRevisionHistoryAction());
  },

  closeRevisionHistoryFunc: (): void => {
    dispatch(actions.closeRevisionHistoryAction());
  },

  fetchRevisionHistoryDataFunc: (projectId: string, textId: string): void => {
    dispatch(actions.fetchRevisionHistoryDataAction(projectId, textId));
  },
});

export const RevisionHistory = connect(mapStateToProps, mapDispatchToProps)(RevisionHistoryApp);
