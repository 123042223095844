/* eslint-disable import/prefer-default-export */
import React, { ReactElement } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { ProjectProps, ProjectData } from '../../types';
import noop from '../../lib/noop';

import {
  projectChangeData,
  projectChangeBool,
  projectCancelForm,
  editProjectFailureRequest,
  projectLoaded,
  clearError,
  closeLightboxEditingProject,
  fetchProject,
  editProject,
} from '../../actions';
import { AppState } from '../../reducers';
import Spinner from '../spinner';
import ProjectBase from './projectBase';

export class ProjectEditingApp extends ProjectBase {
  private projectNameLength = 3;

  public static defaultProps: ProjectProps = {
    description: '',
    isFetching: false,
    loading: false,
    projectName: '',
    userEmail: '',
    isRTL: false,
    isReadOnly: false,

    cancelAddingProjectUserFunc: noop,
    changeViewFunc: noop,
    clearErrorAndMessageFunc: noop,
    closeLightboxEditingProjectFunc: noop,
    closeLightboxManageProjectUsersFunc: noop,
    closeLightboxRemovingProjectFunc: noop,
    createProjectFunc: noop,
    deleteProjectFunc: noop,
    deleteProjectUserFunc: noop,
    editProjectFunc: noop,
    fetchProjectFunc: noop,
    fetchProjectsFunc: noop,
    handleUserEmailChangeFunc: noop,
    handleUserPermissionChangeFunc: noop,
    saveProjectUserFunc: noop,
    searchProjectUserEmailFunc: noop,
    showLightboxEditingProjectFunc: noop,
    showLightboxManageProjectUsersFunc: noop,
    showLightboxRemovingProjectFunc: noop,
    ShowProjectCreationFunc: noop,
    showViewOfAddingProjectUserFunc: noop,

    displayLightboxOfExportData: false,
    isCreatingData: false,
    projectIdOfExportData: undefined,
    projectNameOfExportData: undefined,
    openLightboxOfExportDataFunc: noop,
    closeLightboxOfExportDataFunc: noop,
    dataExportsFunc: noop,
    dataExportErrorFunc: noop,
  };

  private isRTL = false;

  public componentDidMount(): void {
    const { editProjectId, fetchProjectFunc } = this.props;
    if (editProjectId) {
      fetchProjectFunc(editProjectId);
    }

    this.isRTL = this.props.isRTL;
  }

  private handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    const { updateFrom } = this.props;
    updateFrom(e.target.id, e.target.value);
  }

  private handleCheckbox(e: any): void {
    const { updateRTL } = this.props;
    updateRTL(e.target.id, e.target.checked);

    if (e.target.checked) {
      // checkbox - on
      this.isRTL = true;
    } else {
      // checkbox -off
      this.isRTL = false;
    }
  }

  private handleSubmit(e: React.FormEvent<HTMLFormElement>): void {
    e.preventDefault();

    const {
      formHasError,
      projectNameEditing,
      descriptionEditing,
      editProjectFunc,
      editProjectId,
    } = this.props;

    const projectData = {
      projectName: projectNameEditing ? projectNameEditing.trim() : projectNameEditing,
      description: descriptionEditing ? descriptionEditing.trim() : descriptionEditing,
      isRTL: this.isRTL,
    };

    const { error } = this.projectValidation(projectData);

    if (error && Object.prototype.hasOwnProperty.call(error, 'details')) {
      const item = error.details.pop();
      if (Object.prototype.hasOwnProperty.call(item, 'message')) {
        formHasError(item.message);
      }
    } else {
      editProjectFunc(editProjectId, projectData);
    }
  }

  public render(): ReactElement {
    const {
      projectNameEditing,
      descriptionEditing,
      isRTL,
      editingError,
      message,
      clearErrorAndMessageFunc,
      isFetching,
      closeLightboxEditingProjectFunc,
      isFetchingProject,
      editingProject,
    } = this.props;

    return (
      <div className="modal" role="dialog">
        <div className="modal-dialog">
          <form onSubmit={this.handleSubmit.bind(this)} className="edit-project">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  <FormattedMessage id="project.edit" />
                </h5>
                <button
                  type="button"
                  className="close"
                  onClick={(): void => {
                    closeLightboxEditingProjectFunc();
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body" style={{ minHeight: '120px' }}>
                {isFetching && <Spinner />}

                {editingError && (
                  <div className="alert alert-danger mb-4" role="alert">
                    {editingError}
                    <button
                      type="button"
                      className="close"
                      onClick={clearErrorAndMessageFunc.bind(this)}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                )}

                {message && (
                  <div className="alert alert-success mb-4" role="alert">
                    {message}
                    <button
                      type="button"
                      className="close"
                      onClick={clearErrorAndMessageFunc.bind(this)}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                )}

                <div className="form-group">
                  <label htmlFor="projectNameEditing" className="font-weight-bold required-field">
                    <FormattedMessage id="project.name" />
                  </label>
                  <FormattedMessage id="project.namePlaceholder">
                    {(placeholder: any): ReactElement => (
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="projectNameEditing"
                        placeholder={placeholder}
                        onChange={this.handleChange.bind(this)}
                        value={projectNameEditing}
                        required
                        minLength={this.projectNameLength}
                      />
                    )}
                  </FormattedMessage>
                </div>

                <div className="form-group mt-2">
                  <label htmlFor="descriptionEditing" className="font-weight-bold required-field">
                    <FormattedMessage id="project.description" />
                  </label>
                  <FormattedMessage id="project.descriptionPlaceholder">
                    {(placeholder: any): ReactElement => (
                      <textarea
                        className="form-control form-control-lg"
                        id="descriptionEditing"
                        placeholder={placeholder}
                        rows={5}
                        onChange={this.handleChange.bind(this)}
                        value={descriptionEditing}
                        required
                      />
                    )}
                  </FormattedMessage>
                </div>

                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="isRTL"
                    checked={isRTL}
                    onChange={this.handleCheckbox.bind(this)}
                  />
                  <label className="custom-control-label" htmlFor="isRTL">
                    <FormattedMessage id="project.isRTL" />
                  </label>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-ytb"
                  style={{ minWidth: '100px' }}
                  onClick={noop}
                >
                  <FormattedMessage id="update" />
                </button>

                <button
                  type="button"
                  className="btn btn-secondary"
                  style={{ minWidth: '70px' }}
                  onClick={(): void => {
                    closeLightboxEditingProjectFunc();
                  }}
                >
                  <FormattedMessage id="cancel" />
                </button>
              </div>

              {(isFetchingProject || editingProject) && <Spinner />}
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState): ProjectProps => {
  const props = {
    ...state.project,
  };

  return props;
};

const mapDispatchToProps = (dispatch: Dispatch): object => ({
  updateFrom: (key: string, value: string): void => {
    dispatch(projectChangeData(key, value));
  },

  updateRTL: (key: string, value: boolean): void => {
    dispatch(projectChangeBool(key, value));
  },

  cancelFrom: (): void => {
    dispatch(projectCancelForm());
  },

  formHasError: (error: string): void => {
    dispatch(editProjectFailureRequest(error));
  },

  loaded: (): void => {
    dispatch(projectLoaded());
  },

  clearErrorAndMessageFunc: (): void => {
    dispatch(clearError());
  },

  closeLightboxEditingProjectFunc: (): void => {
    dispatch(closeLightboxEditingProject());
  },

  fetchProjectFunc: (projectId: string): void => {
    dispatch(fetchProject(projectId));
  },

  editProjectFunc: (projectId: string, projectData: ProjectData): void => {
    dispatch(editProject(projectId, projectData));
  },
});

export const ProjectEditing = connect(mapStateToProps, mapDispatchToProps)(ProjectEditingApp);
