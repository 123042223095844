import React from 'react';
import { useDispatch } from 'react-redux';
import SearchBox from './searchBox';
import ContentList from './contentList';
import * as actions from '../../actions';

const DictionaryView = (): React.ReactElement => {
  const didMount = React.useRef(true);
  const dispatch = useDispatch();

  React.useEffect((): any => {
    if (didMount.current) {
      didMount.current = false;
    }

    return (): any => {
      // unmount
      dispatch(actions.searchBoxClearAction());
    };
    // eslint-disable-next-line
  }, []);

  React.useEffect((): void => {
    if (didMount.current !== true) {
      // did update
      didMount.current = false;
    }
  });

  return (
    <div
      key="component-dictionary"
      className="dict-container d-flex flex-column align-content-stretch"
    >
      <SearchBox key="dict-component-SearchBox" />
      <ContentList key="dict-component-ContentList" />
    </div>
  );
};

// Prevent component from re-rendering if its parent were re-rendering.
export default React.memo(DictionaryView);
