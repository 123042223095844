export enum BibleBooks {
  Genesis = 1,
  Exodus = 2,
  Leviticus = 3,
  Numbers = 4,
  Deuteronomy = 5,
  Joshua = 6,
  Judges = 7,
  Ruth = 8,
  Samuel1 = 9,
  Samuel2 = 10,
  Kings1 = 11,
  Kings2 = 12,
  Chronicles1 = 13,
  Chronicles2 = 14,
  Ezra = 15,
  Nehemiah = 16,
  Esther = 17,
  Job = 18,
  Psalms = 19,
  Proverbs = 20,
  Ecclesiastes = 21,
  SongOfSongs = 22,
  Isaiah = 23,
  Jeremiah = 24,
  Lamentations = 25,
  Ezekiel = 26,
  Daniel = 27,
  Hosea = 28,
  Joel = 29,
  Amos = 30,
  Obadiah = 31,
  Jonah = 32,
  Micah = 33,
  Nahum = 34,
  Habakkuk = 35,
  Zephaniah = 36,
  Haggai = 37,
  Zechariah = 38,
  Malachi = 39,
  Matthew = 40,
  Mark = 41,
  Luke = 42,
  John = 43,
  Acts = 44,
  Romans = 45,
  Corinthians1 = 46,
  Corinthians2 = 47,
  Galatians = 48,
  Ephesians = 49,
  Philippians = 50,
  Colossians = 51,
  Thessalonians1 = 52,
  Thessalonians2 = 53,
  Timothy1 = 54,
  Timothy2 = 55,
  Titus = 56,
  Philemon = 57,
  Hebrews = 58,
  James = 59,
  Peter1 = 60,
  Peter2 = 61,
  John1 = 62,
  John2 = 63,
  John3 = 64,
  Jude = 65,
  Revelation = 66,
}

export enum BibleBookNames {
  Genesis = 'Genesis',
  Exodus = 'Exodus',
  Leviticus = 'Leviticus',
  Numbers = 'Numbers',
  Deuteronomy = 'Deuteronomy',
  Joshua = 'Joshua',
  Judges = 'Judges',
  Ruth = 'Ruth',
  Samuel1 = '1 Samuel',
  Samuel2 = '2 Samuel',
  Kings1 = '1 Kings',
  Kings2 = '2 Kings',
  Chronicles1 = '1 Chronicles',
  Chronicles2 = '2 Chronicles',
  Ezra = 'Ezra',
  Nehemiah = 'Nehemiah',
  Esther = 'Esther',
  Job = 'Job',
  Psalms = 'Psalms',
  Proverbs = 'Proverbs',
  Ecclesiastes = 'Ecclesiastes',
  SongOfSongs = 'Song of Songs',
  Isaiah = 'Isaiah',
  Jeremiah = 'Jeremiah',
  Lamentations = 'Lamentations',
  Ezekiel = 'Ezekiel',
  Daniel = 'Daniel',
  Hosea = 'Hosea',
  Joel = 'Joel',
  Amos = 'Amos',
  Obadiah = 'Obadiah',
  Jonah = 'Jonah',
  Micah = 'Micah',
  Nahum = 'Nahum',
  Habakkuk = 'Habakkuk',
  Zephaniah = 'Zephaniah',
  Haggai = 'Haggai',
  Zechariah = 'Zechariah',
  Malachi = 'Malachi',
  Matthew = 'Matthew',
  Mark = 'Mark',
  Luke = 'Luke',
  John = 'John',
  Acts = 'Acts',
  Romans = 'Romans',
  Corinthians1 = '1 Corinthians',
  Corinthians2 = '2 Corinthians',
  Galatians = 'Galatians',
  Ephesians = 'Ephesians',
  Philippians = 'Philippians',
  Colossians = 'Colossians',
  Thessalonians1 = '1 Thessalonians',
  Thessalonians2 = '2 Thessalonians',
  Timothy1 = '1 Timothy',
  Timothy2 = '2 Timothy',
  Titus = 'Titus',
  Philemon = 'Philemon',
  Hebrews = 'Hebrews',
  James = 'James',
  Peter1 = '1 Peter',
  Peter2 = '2 Peter',
  John1 = '1 John',
  John2 = '2 John',
  John3 = '3 John',
  Jude = 'Jude',
  Revelation = 'Revelation',
}

export enum BibleChapters {
  Genesis = 50,
  Exodus = 40,
  Leviticus = 27,
  Numbers = 36,
  Deuteronomy = 34,
  Joshua = 24,
  Judges = 21,
  Ruth = 4,
  Samuel1 = 31,
  Samuel2 = 24,
  Kings1 = 22,
  Kings2 = 25,
  Chronicles1 = 29,
  Chronicles2 = 36,
  Ezra = 10,
  Nehemiah = 13,
  Esther = 10,
  Job = 42,
  Psalms = 150,
  Proverbs = 31,
  Ecclesiastes = 12,
  SongOfSongs = 8,
  Isaiah = 66,
  Jeremiah = 52,
  Lamentations = 5,
  Ezekiel = 48,
  Daniel = 12,
  Hosea = 14,
  Joel = 3,
  Amos = 9,
  Obadiah = 1,
  Jonah = 4,
  Micah = 7,
  Nahum = 3,
  Habakkuk = 3,
  Zephaniah = 3,
  Haggai = 2,
  Zechariah = 14,
  Malachi = 4,
  Matthew = 28,
  Mark = 16,
  Luke = 24,
  John = 21,
  Acts = 28,
  Romans = 16,
  Corinthians1 = 16,
  Corinthians2 = 13,
  Galatians = 6,
  Ephesians = 6,
  Philippians = 4,
  Colossians = 4,
  Thessalonians1 = 5,
  Thessalonians2 = 3,
  Timothy1 = 6,
  Timothy2 = 4,
  Titus = 3,
  Philemon = 1,
  Hebrews = 13,
  James = 5,
  Peter1 = 5,
  Peter2 = 3,
  John1 = 5,
  John2 = 1,
  John3 = 1,
  Jude = 1,
  Revelation = 22,
}
/**
 * Structure of Bible verse data.
 */
export interface BibleVerses {
  [bookName: string]: number[];
}

/**
 * The number of verses in each chapter of Books.
 */
export const bibleVerse: BibleVerses = {
  Genesis: [
    31,
    25,
    24,
    26,
    32,
    22,
    24,
    22,
    29,
    32,
    32,
    20,
    18,
    24,
    21,
    16,
    27,
    33,
    38,
    18,
    34,
    24,
    20,
    67,
    34,
    35,
    46,
    22,
    35,
    43,
    55,
    32,
    20,
    31,
    29,
    43,
    36,
    30,
    23,
    23,
    57,
    38,
    34,
    34,
    28,
    34,
    31,
    22,
    33,
    26,
  ],
  Exodus: [
    22,
    25,
    22,
    31,
    23,
    30,
    25,
    32,
    35,
    29,
    10,
    51,
    22,
    31,
    27,
    36,
    16,
    27,
    25,
    26,
    36,
    31,
    33,
    18,
    40,
    37,
    21,
    43,
    46,
    38,
    18,
    35,
    23,
    35,
    35,
    38,
    29,
    31,
    43,
    38,
  ],
  Leviticus: [
    17,
    16,
    17,
    35,
    19,
    30,
    38,
    36,
    24,
    20,
    47,
    8,
    59,
    57,
    33,
    34,
    16,
    30,
    37,
    27,
    24,
    33,
    44,
    23,
    55,
    46,
    34,
  ],
  Numbers: [
    54,
    34,
    51,
    49,
    31,
    27,
    89,
    26,
    23,
    36,
    35,
    16,
    33,
    45,
    41,
    50,
    13,
    32,
    22,
    29,
    35,
    41,
    30,
    25,
    18,
    65,
    23,
    31,
    40,
    16,
    54,
    42,
    56,
    29,
    34,
    13,
  ],
  Deuteronomy: [
    46,
    37,
    29,
    49,
    33,
    25,
    26,
    20,
    29,
    22,
    32,
    32,
    18,
    29,
    23,
    22,
    20,
    22,
    21,
    20,
    23,
    30,
    25,
    22,
    19,
    19,
    26,
    68,
    29,
    20,
    30,
    52,
    29,
    12,
  ],
  Joshua: [
    18,
    24,
    17,
    24,
    15,
    27,
    26,
    35,
    27,
    43,
    23,
    24,
    33,
    15,
    63,
    10,
    18,
    28,
    51,
    9,
    45,
    34,
    16,
    33,
  ],
  Judges: [36, 23, 31, 24, 31, 40, 25, 35, 57, 18, 40, 15, 25, 20, 20, 31, 13, 31, 30, 48, 25],
  Ruth: [22, 23, 18, 22],
  Samuel1: [
    28,
    36,
    21,
    22,
    12,
    21,
    17,
    22,
    27,
    27,
    15,
    25,
    23,
    52,
    35,
    23,
    58,
    30,
    24,
    42,
    15,
    23,
    29,
    22,
    44,
    25,
    12,
    25,
    11,
    31,
    13,
  ],
  Samuel2: [
    27,
    32,
    39,
    12,
    25,
    23,
    29,
    18,
    13,
    19,
    27,
    31,
    39,
    33,
    37,
    23,
    29,
    33,
    43,
    26,
    22,
    51,
    39,
    25,
  ],
  Kings1: [53, 46, 28, 34, 18, 38, 51, 66, 28, 29, 43, 33, 34, 31, 34, 34, 24, 46, 21, 43, 29, 53],
  Kings2: [
    18,
    25,
    27,
    44,
    27,
    33,
    20,
    29,
    37,
    36,
    21,
    21,
    25,
    29,
    38,
    20,
    41,
    37,
    37,
    21,
    26,
    20,
    37,
    20,
    30,
  ],
  Chronicles1: [
    54,
    55,
    24,
    43,
    26,
    81,
    40,
    40,
    44,
    14,
    47,
    40,
    14,
    17,
    29,
    43,
    27,
    17,
    19,
    8,
    30,
    19,
    32,
    31,
    31,
    32,
    34,
    21,
    30,
  ],
  Chronicles2: [
    17,
    18,
    17,
    22,
    14,
    42,
    22,
    18,
    31,
    19,
    23,
    16,
    22,
    15,
    19,
    14,
    19,
    34,
    11,
    37,
    20,
    12,
    21,
    27,
    28,
    23,
    9,
    27,
    36,
    27,
    21,
    33,
    25,
    33,
    27,
    23,
  ],
  Ezra: [11, 70, 13, 24, 17, 22, 28, 36, 15, 44],
  Nehemiah: [11, 20, 32, 23, 19, 19, 73, 18, 38, 39, 36, 47, 31],
  Esther: [22, 23, 15, 17, 14, 14, 10, 17, 32, 3],
  Job: [
    22,
    13,
    26,
    21,
    27,
    30,
    21,
    22,
    35,
    22,
    20,
    25,
    28,
    22,
    35,
    22,
    16,
    21,
    29,
    29,
    34,
    30,
    17,
    25,
    6,
    14,
    23,
    28,
    25,
    31,
    40,
    22,
    33,
    37,
    16,
    33,
    24,
    41,
    30,
    24,
    34,
    17,
  ],
  Psalms: [
    6,
    12,
    8,
    8,
    12,
    10,
    17,
    9,
    20,
    18,
    7,
    8,
    6,
    7,
    5,
    11,
    15,
    50,
    14,
    9,
    13,
    31,
    6,
    10,
    22,
    12,
    14,
    9,
    11,
    12,
    24,
    11,
    22,
    22,
    28,
    12,
    40,
    22,
    13,
    17,
    13,
    11,
    5,
    26,
    17,
    11,
    9,
    14,
    20,
    23,
    19,
    9,
    6,
    7,
    23,
    13,
    11,
    11,
    17,
    12,
    8,
    12,
    11,
    10,
    13,
    20,
    7,
    35,
    36,
    5,
    24,
    20,
    28,
    23,
    10,
    12,
    20,
    72,
    13,
    19,
    16,
    8,
    18,
    12,
    13,
    17,
    7,
    18,
    52,
    17,
    16,
    15,
    5,
    23,
    11,
    13,
    12,
    9,
    9,
    5,
    8,
    28,
    22,
    35,
    45,
    48,
    43,
    13,
    31,
    7,
    10,
    10,
    9,
    8,
    18,
    19,
    2,
    29,
    176,
    7,
    8,
    9,
    4,
    8,
    5,
    6,
    5,
    6,
    8,
    8,
    3,
    18,
    3,
    3,
    21,
    26,
    9,
    8,
    24,
    13,
    10,
    7,
    12,
    15,
    21,
    10,
    20,
    14,
    9,
    6,
  ],
  Proverbs: [
    33,
    22,
    35,
    27,
    23,
    35,
    27,
    36,
    18,
    32,
    31,
    28,
    25,
    35,
    33,
    33,
    28,
    24,
    29,
    30,
    31,
    29,
    35,
    34,
    28,
    28,
    27,
    28,
    27,
    33,
    31,
  ],
  Ecclesiastes: [18, 26, 22, 16, 20, 12, 29, 17, 18, 20, 10, 14],
  SongOfSongs: [17, 17, 11, 16, 16, 13, 13, 14],
  Isaiah: [
    31,
    22,
    26,
    6,
    30,
    13,
    25,
    22,
    21,
    34,
    16,
    6,
    22,
    32,
    9,
    14,
    14,
    7,
    25,
    6,
    17,
    25,
    18,
    23,
    12,
    21,
    13,
    29,
    24,
    33,
    9,
    20,
    24,
    17,
    10,
    22,
    38,
    22,
    8,
    31,
    29,
    25,
    28,
    28,
    25,
    13,
    15,
    22,
    26,
    11,
    23,
    15,
    12,
    17,
    13,
    12,
    21,
    14,
    21,
    22,
    11,
    12,
    19,
    12,
    25,
    24,
  ],
  Jeremiah: [
    19,
    37,
    25,
    31,
    31,
    30,
    34,
    22,
    26,
    25,
    23,
    17,
    27,
    22,
    21,
    21,
    27,
    23,
    15,
    18,
    14,
    30,
    40,
    10,
    38,
    24,
    22,
    17,
    32,
    24,
    40,
    44,
    26,
    22,
    19,
    32,
    21,
    28,
    18,
    16,
    18,
    22,
    13,
    30,
    5,
    28,
    7,
    47,
    39,
    46,
    64,
    34,
  ],
  Lamentations: [22, 22, 66, 22, 22],
  Ezekiel: [
    28,
    10,
    27,
    17,
    17,
    14,
    27,
    18,
    11,
    22,
    25,
    28,
    23,
    23,
    8,
    63,
    24,
    32,
    14,
    49,
    32,
    31,
    49,
    27,
    17,
    21,
    36,
    26,
    21,
    26,
    18,
    32,
    33,
    31,
    15,
    38,
    28,
    23,
    29,
    49,
    26,
    20,
    27,
    31,
    25,
    24,
    23,
    35,
  ],
  Daniel: [21, 49, 30, 37, 31, 28, 28, 27, 27, 21, 45, 13],
  Hosea: [11, 23, 5, 19, 15, 11, 16, 14, 17, 15, 12, 14, 16, 9],
  Joel: [20, 32, 21],
  Amos: [15, 16, 15, 13, 27, 14, 17, 14, 15],
  Obadiah: [21],
  Jonah: [17, 10, 10, 11],
  Micah: [16, 13, 12, 13, 15, 16, 20],
  Nahum: [15, 13, 19],
  Habakkuk: [17, 20, 19],
  Zephaniah: [18, 15, 20],
  Haggai: [15, 23],
  Zechariah: [21, 13, 10, 14, 11, 15, 14, 23, 17, 12, 17, 14, 9, 21],
  Malachi: [14, 17, 18, 6],
  Matthew: [
    25,
    23,
    17,
    25,
    48,
    34,
    29,
    34,
    38,
    42,
    30,
    50,
    58,
    36,
    39,
    28,
    27,
    35,
    30,
    34,
    46,
    46,
    39,
    51,
    46,
    75,
    66,
    20,
  ],
  Mark: [45, 28, 35, 41, 43, 56, 37, 38, 50, 52, 33, 44, 37, 72, 47, 20],
  Luke: [
    80,
    52,
    38,
    44,
    39,
    49,
    50,
    56,
    62,
    42,
    54,
    59,
    35,
    35,
    32,
    31,
    37,
    43,
    48,
    47,
    38,
    71,
    56,
    53,
  ],
  John: [51, 25, 36, 54, 47, 71, 53, 59, 41, 42, 57, 50, 38, 31, 27, 33, 26, 40, 42, 31, 25],
  Acts: [
    26,
    47,
    26,
    37,
    42,
    15,
    60,
    40,
    43,
    48,
    30,
    25,
    52,
    28,
    41,
    40,
    34,
    28,
    41,
    38,
    40,
    30,
    35,
    27,
    27,
    32,
    44,
    31,
  ],
  Romans: [32, 29, 31, 25, 21, 23, 25, 39, 33, 21, 36, 21, 14, 23, 33, 27],
  Corinthians1: [31, 16, 23, 21, 13, 20, 40, 13, 27, 33, 34, 31, 13, 40, 58, 24],
  Corinthians2: [24, 17, 18, 18, 21, 18, 16, 24, 15, 18, 33, 21, 14],
  Galatians: [24, 21, 29, 31, 26, 18],
  Ephesians: [23, 22, 21, 32, 33, 24],
  Philippians: [30, 30, 21, 23],
  Colossians: [29, 23, 25, 18],
  Thessalonians1: [10, 20, 13, 18, 28],
  Thessalonians2: [12, 17, 18],
  Timothy1: [20, 15, 16, 16, 25, 21],
  Timothy2: [18, 26, 17, 22],
  Titus: [16, 15, 15],
  Philemon: [25],
  Hebrews: [14, 18, 19, 16, 14, 20, 28, 13, 28, 39, 40, 29, 25],
  James: [27, 26, 18, 17, 20],
  Peter1: [25, 25, 22, 19, 14],
  Peter2: [21, 22, 18],
  John1: [10, 29, 24, 21, 21],
  John2: [13],
  John3: [15],
  Jude: [25],
  Revelation: [
    20,
    29,
    22,
    11,
    14,
    17,
    17,
    13,
    21,
    11,
    19,
    17,
    18,
    20,
    8,
    21,
    18,
    24,
    21,
    15,
    27,
    21,
  ],
};

export const getVerseNumberByBookAndChapter = (bookId: string, chapter: number): number => {
  const bookVerses: number[] = bibleVerse[bookId];
  const chapterIndex = chapter > 0 ? chapter - 1 : 0;
  return bookVerses[chapterIndex];
};

export enum BibleBookCategory {
  // old testament
  Genesis = 'law',
  Exodus = 'law',
  Leviticus = 'law',
  Numbers = 'law',
  Deuteronomy = 'law',

  Joshua = 'history',
  Judges = 'history',
  Ruth = 'history',
  Samuel1 = 'history',
  Samuel2 = 'history',
  Kings1 = 'history',
  Kings2 = 'history',
  Chronicles1 = 'history',
  Chronicles2 = 'history',
  Ezra = 'history',
  Nehemiah = 'history',
  Esther = 'history',

  Job = 'poetry',
  Psalms = 'poetry',
  Proverbs = 'poetry',
  Ecclesiastes = 'poetry',
  SongOfSongs = 'poetry',

  Isaiah = 'prophets',
  Jeremiah = 'prophets',
  Lamentations = 'prophets',
  Ezekiel = 'prophets',
  Daniel = 'prophets',

  Hosea = 'prophets',
  Joel = 'prophets',
  Amos = 'prophets',
  Obadiah = 'prophets',
  Jonah = 'prophets',
  Micah = 'prophets',
  Nahum = 'prophets',
  Habakkuk = 'prophets',
  Zephaniah = 'prophets',
  Haggai = 'prophets',
  Zechariah = 'prophets',
  Malachi = 'prophets',

  // new testament
  Matthew = 'gospels',
  Mark = 'gospels',
  Luke = 'gospels',
  John = 'gospels',

  Acts = 'history',

  Romans = 'letters',
  Corinthians1 = 'letters',
  Corinthians2 = 'letters',
  Galatians = 'letters',
  Ephesians = 'letters',
  Philippians = 'letters',
  Colossians = 'letters',
  Thessalonians1 = 'letters',
  Thessalonians2 = 'letters',
  Timothy1 = 'letters',
  Timothy2 = 'letters',
  Titus = 'letters',
  Philemon = 'letters',
  Hebrews = 'letters',
  James = 'letters',
  Peter1 = 'letters',
  Peter2 = 'letters',
  John1 = 'letters',
  John2 = 'letters',
  John3 = 'letters',
  Jude = 'letters',

  Revelation = 'prophecy',
}

export const BIBLE_BOOK_CHAPTER_RANGE: Record<string, number[]> = {
  Genesis: [1, 50],
  Exodus: [51, 90],
  Leviticus: [91, 117],
  Numbers: [118, 153],
  Deuteronomy: [154, 187],
  Joshua: [188, 211],
  Judges: [212, 232],
  Ruth: [233, 236],
  Samuel1: [237, 267],
  Samuel2: [268, 291],
  Kings1: [292, 313],
  Kings2: [314, 338],
  Chronicles1: [339, 376],
  Chronicles2: [368, 403],
  Ezra: [404, 413],
  Nehemiah: [414, 426],
  Esther: [427, 436],
  Job: [437, 478],
  Psalms: [479, 628],
  Proverbs: [629, 659],
  Ecclesiastes: [660, 671],
  SongOfSongs: [672, 679],
  Isaiah: [680, 745],
  Jeremiah: [746],
  Lamentations: [798, 802],
  Ezekiel: [803, 850],
  Daniel: [851, 862],
  Hosea: [863, 876],
  Joel: [877, 879],
  Amos: [880, 888],
  Obadiah: [889, 889],
  Jonah: [890, 893],
  Micah: [894, 900],
  Nahum: [901, 903],
  Habakkuk: [904, 906],
  Zephaniah: [907, 909],
  Haggai: [910, 911],
  Zechariah: [912, 925],
  Malachi: [926, 929],
  Matthew: [930, 957],
  Mark: [958, 973],
  Luke: [974, 997],
  John: [998, 1018],
  Acts: [1019, 1047],
  Romans: [1047, 1062],
  Corinthians1: [1063, 1078],
  Corinthians2: [1079, 1091],
  Galatians: [1092, 1097],
  Ephesians: [1098, 1103],
  Philippians: [1104, 1107],
  Colossians: [1108, 1111],
  Thessalonians1: [1112, 1116],
  Thessalonians2: [1117, 1119],
  Timothy1: [1120, 1125],
  Timothy2: [1126, 1129],
  Titus: [1130, 1132],
  Philemon: [1133, 1133],
  Hebrews: [1134, 1146],
  James: [1147, 1151],
  Peter1: [1152, 1156],
  Peter2: [1157, 1159],
  John1: [1160, 1164],
  John2: [1165, 1165],
  John3: [1166, 1166],
  Jude: [1167, 1167],
  Revelation: [1168, 1189],
};

const LOGOS_BIBLE_ABBREVS: Record<string, BibleBooks> = {
  Ge: BibleBooks.Genesis,
  Ex: BibleBooks.Exodus,
  Le: BibleBooks.Leviticus,
  Nu: BibleBooks.Numbers,
  De: BibleBooks.Deuteronomy,
  Jos: BibleBooks.Joshua,
  Jdg: BibleBooks.Judges,
  Ru: BibleBooks.Ruth,
  '1Sa': BibleBooks.Samuel1,
  '2Sa': BibleBooks.Samuel2,
  '1Ki': BibleBooks.Kings1,
  '2Ki': BibleBooks.Kings2,
  '1Ch': BibleBooks.Chronicles1,
  '2Ch': BibleBooks.Chronicles2,
  Ezr: BibleBooks.Ezra,
  Ne: BibleBooks.Nehemiah,
  Es: BibleBooks.Esther,
  Job: BibleBooks.Job,
  Ps: BibleBooks.Psalms,
  Pr: BibleBooks.Proverbs,
  Ec: BibleBooks.Ecclesiastes,
  So: BibleBooks.SongOfSongs,
  Is: BibleBooks.Isaiah,
  Je: BibleBooks.Jeremiah,
  La: BibleBooks.Lamentations,
  Eze: BibleBooks.Ezekiel,
  Da: BibleBooks.Daniel,
  Ho: BibleBooks.Hosea,
  Joe: BibleBooks.Joel,
  Am: BibleBooks.Amos,
  Ob: BibleBooks.Obadiah,
  Jon: BibleBooks.Jonah,
  Mic: BibleBooks.Micah,
  Na: BibleBooks.Nahum,
  Hab: BibleBooks.Habakkuk,
  Zep: BibleBooks.Zephaniah,
  Hag: BibleBooks.Haggai,
  Zec: BibleBooks.Zechariah,
  Mal: BibleBooks.Malachi,
  Mt: BibleBooks.Matthew,
  Mk: BibleBooks.Mark,
  Lk: BibleBooks.Luke,
  Jn: BibleBooks.John,
  Ac: BibleBooks.Acts,
  Ro: BibleBooks.Romans,
  '1Co': BibleBooks.Corinthians1,
  '2Co': BibleBooks.Corinthians2,
  Ga: BibleBooks.Galatians,
  Eph: BibleBooks.Ephesians,
  Php: BibleBooks.Philippians,
  Col: BibleBooks.Colossians,
  '1Th': BibleBooks.Thessalonians1,
  '2Th': BibleBooks.Thessalonians2,
  '1Ti': BibleBooks.Timothy1,
  '2Ti': BibleBooks.Timothy2,
  Tit: BibleBooks.Titus,
  Phm: BibleBooks.Philemon,
  Heb: BibleBooks.Hebrews,
  Jam: BibleBooks.James,
  '1Pe': BibleBooks.Peter1,
  '2Pe': BibleBooks.Peter2,
  '1Jn': BibleBooks.John1,
  '2Jn': BibleBooks.John2,
  '3Jn': BibleBooks.John3,
  Jud: BibleBooks.Jude,
  Rev: BibleBooks.Revelation,
};

const GBI_BIBLE_ABBREVS: Record<string, BibleBooks> = {
  gn: BibleBooks.Genesis,
  ex: BibleBooks.Exodus,
  lv: BibleBooks.Leviticus,
  nu: BibleBooks.Numbers,
  dt: BibleBooks.Deuteronomy,
  js: BibleBooks.Joshua,
  ju: BibleBooks.Judges,
  ru: BibleBooks.Ruth,
  '1s': BibleBooks.Samuel1,
  '2s': BibleBooks.Samuel2,
  '1k': BibleBooks.Kings1,
  '2k': BibleBooks.Kings2,
  '1c': BibleBooks.Chronicles1,
  '2c': BibleBooks.Chronicles2,
  er: BibleBooks.Ezra,
  ne: BibleBooks.Nehemiah,
  es: BibleBooks.Esther,
  jb: BibleBooks.Job,
  ps: BibleBooks.Psalms,
  pr: BibleBooks.Proverbs,
  ec: BibleBooks.Ecclesiastes,
  ca: BibleBooks.SongOfSongs,
  is: BibleBooks.Isaiah,
  je: BibleBooks.Jeremiah,
  lm: BibleBooks.Lamentations,
  ek: BibleBooks.Ezekiel,
  da: BibleBooks.Daniel,
  ho: BibleBooks.Hosea,
  jl: BibleBooks.Joel,
  am: BibleBooks.Amos,
  ob: BibleBooks.Obadiah,
  jn: BibleBooks.Jonah,
  mi: BibleBooks.Micah,
  na: BibleBooks.Nahum,
  hb: BibleBooks.Habakkuk,
  zp: BibleBooks.Zephaniah,
  hg: BibleBooks.Haggai,
  zc: BibleBooks.Zechariah,
  ma: BibleBooks.Malachi,
  Mat: BibleBooks.Matthew,
  Mrk: BibleBooks.Mark,
  Luk: BibleBooks.Luke,
  Jhn: BibleBooks.John,
  Act: BibleBooks.Acts,
  Rom: BibleBooks.Romans,
  '1Co': BibleBooks.Corinthians1,
  '2Co': BibleBooks.Corinthians2,
  Gal: BibleBooks.Galatians,
  Eph: BibleBooks.Ephesians,
  Php: BibleBooks.Philippians,
  Col: BibleBooks.Colossians,
  '1Th': BibleBooks.Thessalonians1,
  '2Th': BibleBooks.Thessalonians2,
  '1Tm': BibleBooks.Timothy1,
  '2Tm': BibleBooks.Timothy2,
  Tit: BibleBooks.Titus,
  Phm: BibleBooks.Philemon,
  Heb: BibleBooks.Hebrews,
  Jms: BibleBooks.James,
  '1Pe': BibleBooks.Peter1,
  '2Pe': BibleBooks.Peter2,
  '1Jn': BibleBooks.John1,
  '2Jn': BibleBooks.John2,
  '3Jn': BibleBooks.John3,
  Jud: BibleBooks.Jude,
  Rev: BibleBooks.Revelation,
};

export const BIBLE_API_ABBREVS_TO_BIBLE_BOOKS: Record<string, number> = {
  GEN: BibleBooks.Genesis,
  EXO: BibleBooks.Exodus,
  LEV: BibleBooks.Leviticus,
  NUM: BibleBooks.Numbers,
  DEU: BibleBooks.Deuteronomy,
  JOS: BibleBooks.Joshua,
  JDG: BibleBooks.Judges,
  RUT: BibleBooks.Ruth,
  '1SA': BibleBooks.Samuel1,
  '2SA': BibleBooks.Samuel2,
  '1KI': BibleBooks.Kings1,
  '2KI': BibleBooks.Kings2,
  '1CH': BibleBooks.Chronicles1,
  '2CH': BibleBooks.Chronicles2,
  EZR: BibleBooks.Ezra,
  NEH: BibleBooks.Nehemiah,
  EST: BibleBooks.Esther,
  JOB: BibleBooks.Job,
  PSA: BibleBooks.Psalms,
  PRO: BibleBooks.Proverbs,
  ECC: BibleBooks.Ecclesiastes,
  SNG: BibleBooks.SongOfSongs,
  ISA: BibleBooks.Isaiah,
  JER: BibleBooks.Jeremiah,
  LAM: BibleBooks.Lamentations,
  EZK: BibleBooks.Ezekiel,
  DAN: BibleBooks.Daniel,
  HOS: BibleBooks.Hosea,
  JOL: BibleBooks.Joel,
  AMO: BibleBooks.Amos,
  OBA: BibleBooks.Obadiah,
  JON: BibleBooks.Jonah,
  MIC: BibleBooks.Micah,
  NAM: BibleBooks.Nahum,
  HAB: BibleBooks.Habakkuk,
  ZEP: BibleBooks.Zephaniah,
  HAG: BibleBooks.Haggai,
  ZEC: BibleBooks.Zechariah,
  MAL: BibleBooks.Malachi,
  MAT: BibleBooks.Matthew,
  MRK: BibleBooks.Mark,
  LUK: BibleBooks.Luke,
  JHN: BibleBooks.John,
  ACT: BibleBooks.Acts,
  ROM: BibleBooks.Romans,
  '1CO': BibleBooks.Corinthians1,
  '2CO': BibleBooks.Corinthians2,
  GAL: BibleBooks.Galatians,
  EPH: BibleBooks.Ephesians,
  PHP: BibleBooks.Philippians,
  COL: BibleBooks.Colossians,
  '1TH': BibleBooks.Thessalonians1,
  '2TH': BibleBooks.Thessalonians2,
  '1TI': BibleBooks.Timothy1,
  '2TI': BibleBooks.Timothy2,
  TIT: BibleBooks.Titus,
  PHM: BibleBooks.Philemon,
  HEB: BibleBooks.Hebrews,
  JAS: BibleBooks.James,
  '1PE': BibleBooks.Peter1,
  '2PE': BibleBooks.Peter2,
  '1JN': BibleBooks.John1,
  '2JN': BibleBooks.John2,
  '3JN': BibleBooks.John3,
  JUD: BibleBooks.Jude,
  REV: BibleBooks.Revelation,
};

export enum Manuscript {
  OT = 'wlc',
  NT = 'na28',
}

export type StrongsXPrefix = 'h' | 'g';

export type BookInfo = {
  book: string | undefined;
  ref: string | undefined;
};

const bibleBooksToBibleApiAbbrevsConverter = (): Map<number, string> => {
  const map = new Map<number, string>();
  Object.keys(BIBLE_API_ABBREVS_TO_BIBLE_BOOKS).forEach((record): void => {
    map.set(BIBLE_API_ABBREVS_TO_BIBLE_BOOKS[record], record);
  });
  return map;
};

export const BIBLE_BOOKS_TO_BIBLE_API_ABBREVS: Map<
  number,
  string
> = bibleBooksToBibleApiAbbrevsConverter();

export class VerseIdParser {
  public parse(reference: string): string {
    const logosRegex = /Bible:(\w+)\s(\d+):(\d+)/;
    const parsedReference = logosRegex.exec(reference);
    if (parsedReference === null) {
      throw new Error(`Cannot parse reference: ${reference}`);
    } else {
      const [book, chapter, verse] = parsedReference.splice(1, 3);
      let bookNum = '';
      try {
        bookNum = LOGOS_BIBLE_ABBREVS[book].toString();
      } catch (e) {
        throw new Error(`Cannot find reference to: ${book}.`);
      }

      const bookId = bookNum.padStart(2, '0');
      const chapterId = chapter.padStart(3, '0');
      const verseId = verse.padStart(3, '0');
      return `${bookId}${chapterId}${verseId}`;
    }
  }

  public parseSegments(book: string, chapter: string, verse: string): string {
    let bookName: string = book;
    if (book.match(/^(\d)\s(\w+)/)) {
      bookName = book.replace(/^(\d)\s(\w+)/, '$2$1');
    }

    const bookId = String(BibleBooks[bookName as keyof typeof BibleBooks]);
    const chapterId = chapter.padStart(3, '0');
    const verseId = verse.padStart(3, '0');
    return `${bookId.padStart(2, '0')}${chapterId}${verseId}`;
  }

  public parseGbiAbbrevs(abbrev: string): string {
    const parsed = abbrev.match(/^(\d?[A-Za-z]+)(\d+):(\d+)/);
    if (parsed === null) {
      throw new Error(`Cannot parse GBI abbreviation: ${abbrev}`);
    } else {
      const bookAbbrev = parsed[1];
      const bookId = GBI_BIBLE_ABBREVS[bookAbbrev].toString().padStart(2, '0');
      const chapterId = parsed[2].padStart(3, '0');
      const verseId = parsed[3].padStart(3, '0');
      return `${bookId}${chapterId}${verseId}`;
    }
  }

  public isOT(gbiId: string): boolean {
    return Number(gbiId.substring(0, 2)) <= 39;
  }

  public isNT(gbiId: string): boolean {
    return Number(gbiId.substring(0, 2)) >= 40 && Number(gbiId.substring(0, 2)) <= 66;
  }

  public createGbiId(bookId: string, chapterId: string, verseId: string): string {
    return `${bookId.padStart(2, '0')}${chapterId.padStart(3, '0')}${verseId.padStart(3, '0')}`;
  }

  public parseGbiId(gbiId: string | undefined): any {
    if (gbiId && gbiId.length === 8) {
      return {
        book: gbiId.slice(0, 2),
        chapter: gbiId.slice(2, 5),
        verse: gbiId.slice(5),
      };
    }

    throw Error('Invalid gbiId.');
  }

  public manuscriptId(gbiId: string): string {
    let manuscript = '';
    if (this.isOT(gbiId)) {
      manuscript = Manuscript.OT;
    } else if (this.isNT(gbiId)) {
      manuscript = Manuscript.NT;
    }

    return manuscript;
  }

  public strongsXPrefix(gbiId: string): StrongsXPrefix {
    // NOTE: for memory processing, we will use lowercase for everything
    return this.isNT(gbiId) ? 'g' : 'h';
  }

  public versificationFieldId(versification: string): string {
    return `${versification.toLowerCase()}Ids`;
  }

  public getReadableReferenceForGbiId(gbiId: string | undefined): BookInfo {
    const { book, chapter, verse } = this.parseGbiId(gbiId);
    const bookEnum = BibleBooks[Number(book)];
    const bookName = BibleBookNames[bookEnum as keyof typeof BibleBookNames];
    const displayVerse = verse.replace(/^0{0,2}/, '');
    return { book: bookName, ref: `${Number(chapter)}:${displayVerse}` };
  }
}

export enum ExportTypes {
  Books = 1,
  Chapters = 2,
  Verses = 3,
}
