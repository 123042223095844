/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useSelector } from 'react-redux';

import { FormattedMessage } from 'react-intl';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AppState } from '../../reducers';

import {
  DictDataResponse,
  DictContentType,
  DictDataElement,
  DictDataResponseItem,
} from './dataType';
import { MemoryVerseReference } from '../../shared/structs/projectTranslations';
import { strEscape, highlightLinkedWords } from '../../shared/functions';
import Spinner from '../spinner';
import { VerseIdParser } from '../../shared/verseIdParser';

const VerseIdParserInstance = new VerseIdParser();

const ContentTooManySearchWords = (): React.ReactElement => (
  <div className="dict-help" key="dict-help-searchTermTooMany">
    <h2>
      <FormattedMessage id="title.searchTermTooMany" />
    </h2>
    <p>
      <FormattedMessage id="desc.searchTermTooMany" />
    </p>
  </div>
);

const ContentHelp = (): React.ReactElement => (
  <div className="dict-help" key="dict-help-howToSearch">
    <h2>
      <FormattedMessage id="title.howToSearch" />
    </h2>
    <p>
      <FormattedMessage id="desc.howToSearch" />
    </p>
  </div>
);

const ContentError = (): React.ReactElement => (
  <div className="dict-help" key="dict-help-howToSearch">
    <h2>
      <FormattedMessage id="title.searchDictError" />
    </h2>
    <p>
      <FormattedMessage id="desc.searchDictError" />
    </p>
  </div>
);

const ContentNoDate = (): React.ReactElement => (
  <div className="dict-help" key="dict-help-noDataToDisplay">
    <h2>
      <FormattedMessage id="dict.noDataToDisplay" />
    </h2>
  </div>
);

const handleClickSubTitle = (e: React.MouseEvent, id: string): void => {
  // avoid to re-render in case there is a long list.
  const el = document.getElementById(id);
  if (el?.classList.contains('show')) {
    el?.classList.remove('show');
    el?.classList.replace('collapse', 'collapsing');
  } else {
    el?.classList.replace('collapsing', 'collapse');
    el?.classList.add('show');
  }
};

const VerseInfo = (
  word: string,
  suggestionData: MemoryVerseReference[],
  showSubMenu: boolean,
  id: string,
): React.ReactElement => {
  const n = -1;

  const listRecord = suggestionData.reduce((acc: Record<string, MemoryVerseReference>, item) => {
    const unique = acc;

    if (!unique[item.textId]) {
      unique[item.textId] = item;
    }

    if ('locationKeys' in unique[item.textId] === false) {
      unique[item.textId].locationKeys = [];
    }

    unique[item.textId].locationKeys?.push(item.locationKey);
    return unique;
  }, {});

  const list = Object.keys(listRecord)
    .sort()
    .map((textId) => {
      const { text, locationKeys } = listRecord[textId];
      let html = strEscape(text);

      if (locationKeys) {
        html = highlightLinkedWords(word, text, locationKeys);
      }

      const verseInfo = VerseIdParserInstance.getReadableReferenceForGbiId(textId);

      return (
        <li className="list-group-item" key={`dict-word-list-dropdown-list-${textId}-${n}`}>
          <FormattedMessage id={verseInfo.book}>
            {(book): React.ReactElement => <sub>{`${book} ${verseInfo.ref}`}</sub>}
          </FormattedMessage>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </li>
      );
    });

  const className = showSubMenu
    ? 'list-group border sub-menu middle-animate collapse show'
    : 'list-group border sub-menu middle-animate collapsing';

  return (
    <ul
      className={className}
      style={{ marginBottom: '1rem' }}
      id={id}
      key={`dict-list-VerseInfo-${id}`}
    >
      {list}
    </ul>
  );
};

const ConcordanceList = (
  data: DictDataElement,
  isTranslation: boolean,
  showSubMenu: boolean,
): React.ReactElement => {
  const { sourceText, targetText, sourceLemma, english, count, verses } = data;
  const key = `${sourceText}-${targetText}`;
  const id = `dict-submenu-${key}`;
  return (
    <div key={`dict-list-Concordance-${key}`}>
      <div className="sub-title">
        <div
          role="link"
          className="link"
          onClick={(e: React.MouseEvent): void => {
            handleClickSubTitle(e, id);
          }}
          key={`dict-list-Concordance-sub-title-link-${id}`}
          id={`link-${id}`}
        >
          {((): React.ReactElement => {
            if (isTranslation) {
              return (
                <>
                  <FormattedMessage id="tooltip.manuscript">
                    {(message: string): React.ReactElement => (
                      <OverlayTrigger
                        key={`overlayTooltip-manuscript-${key}`}
                        placement="top"
                        overlay={<Tooltip id={`tooltip-manuscript-${key}`}>{message}</Tooltip>}
                      >
                        <span className="manuscript">{sourceLemma}</span>
                      </OverlayTrigger>
                    )}
                  </FormattedMessage>

                  <FormattedMessage id="tooltip.strongsx">
                    {(message: string): React.ReactElement => (
                      <OverlayTrigger
                        key={`overlayTooltip-StrongsX-${key}`}
                        placement="top"
                        overlay={<Tooltip id={`tooltip-StrongsX-${key}`}>{message}</Tooltip>}
                      >
                        <span className="strongs">{sourceText}</span>
                      </OverlayTrigger>
                    )}
                  </FormattedMessage>

                  <FormattedMessage id="tooltip.translation">
                    {(message: string): React.ReactElement => (
                      <OverlayTrigger
                        key={`overlayTooltip-translation-${key}`}
                        placement="top"
                        overlay={<Tooltip id={`tooltip-translation-${key}`}>{message}</Tooltip>}
                      >
                        <span className="translation">{targetText}</span>
                      </OverlayTrigger>
                    )}
                  </FormattedMessage>

                  <FormattedMessage id="tooltip.english">
                    {(message: string): React.ReactElement => (
                      <OverlayTrigger
                        key={`overlayTooltip-english-${key}`}
                        placement="top"
                        overlay={<Tooltip id={`tooltip-english-${key}`}>{message}</Tooltip>}
                      >
                        <span className="english">{`(${english})`}</span>
                      </OverlayTrigger>
                    )}
                  </FormattedMessage>
                </>
              );
            }

            return (
              <FormattedMessage id="tooltip.translation">
                {(message: string): React.ReactElement => (
                  <OverlayTrigger
                    key={`overlayTooltip-translation-${key}`}
                    placement="top"
                    overlay={<Tooltip id={`tooltip-translation-${key}`}>{message}</Tooltip>}
                  >
                    <span className="translation">{targetText}</span>
                  </OverlayTrigger>
                )}
              </FormattedMessage>
            );
          })()}
        </div>
        <span className="occurrence">{count}</span>
      </div>

      {verses && VerseInfo(targetText, verses, showSubMenu, id)}
    </div>
  );
};

const StrongsXList = (
  strongsX: string,
  input: DictDataResponseItem,
  showSubMenu: boolean,
): React.ReactElement[] => {
  const stack: React.ReactElement[] = [];
  const items = input.data;

  if (items?.length) {
    const { sourceLemma, english } = items[0];
    const key = `${sourceLemma}|${strongsX}`;
    stack.push(
      <div className="dict-list" key={`dict-list-${strongsX}`}>
        <div className="row" key={`dict-list-${strongsX}-title`}>
          <div className="col">
            <h5>
              <FormattedMessage id="tooltip.manuscript">
                {(message: string): React.ReactElement => (
                  <OverlayTrigger
                    key={`overlayTooltip-StrongsList-manuscript-${key}`}
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-StrongsList-manuscript-${key}`}>{message}</Tooltip>
                    }
                  >
                    <span className="manuscript">{sourceLemma}</span>
                  </OverlayTrigger>
                )}
              </FormattedMessage>

              <FormattedMessage id="tooltip.strongsx">
                {(message: string): React.ReactElement => (
                  <OverlayTrigger
                    key={`overlayTooltip-StrongsList-StrongsX-${key}`}
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-StrongsList-StrongsX-${key}`}>{message}</Tooltip>
                    }
                  >
                    <span className="strongs">{strongsX}</span>
                  </OverlayTrigger>
                )}
              </FormattedMessage>

              <FormattedMessage id="tooltip.english">
                {(message: string): React.ReactElement => (
                  <OverlayTrigger
                    key={`overlayTooltip-StrongsList-english-${key}`}
                    placement="top"
                    overlay={<Tooltip id={`tooltip-StrongsList-english-${key}`}>{message}</Tooltip>}
                  >
                    <span className="english">{`(${english})`}</span>
                  </OverlayTrigger>
                )}
              </FormattedMessage>
            </h5>
          </div>
        </div>

        {((): React.ReactElement[] => {
          return items
            .filter((item: DictDataElement): boolean => item.count > 0)
            .sort(
              (left: DictDataElement, right: DictDataElement): number => right.count - left.count,
            )
            .map(
              (item: DictDataElement): React.ReactElement => {
                const keyLocal = `${item.sourceText}-${item.targetText}`;
                return (
                  <div className="row" key={`dict-list-${strongsX}-Concordance-${keyLocal}`}>
                    <div className="col ml-3">{ConcordanceList(item, false, showSubMenu)}</div>
                  </div>
                );
              },
            );
        })()}
      </div>,
    );
  } else {
    stack.push(ContentError());
  }

  return stack;
};

const TranslationList = (
  keyword: string,
  input: DictDataResponseItem,
  showSubMenu: boolean,
): React.ReactElement => {
  const list = input.data
    .sort((left: DictDataElement, right: DictDataElement) => right.count - left.count)
    .map(
      (element: DictDataElement): React.ReactElement => {
        const keyLocal = `${element.sourceText}-${element.targetText}`;
        return (
          <div className="row" key={`dict-list-menu-${keyword}-${keyLocal}`}>
            <div className="col ml-3">{ConcordanceList(element, true, showSubMenu)}</div>
          </div>
        );
      },
    );

  return (
    <div className="dict-list" key={`dict-list-${keyword}`}>
      <div className="row" key={`dict-list-title-${keyword}`}>
        <div className="col">
          <h5>{input.targetText}</h5>
        </div>
      </div>
      {list}
    </div>
  );
};

const ResultList = (
  searchTerm: string,
  searchResult: DictDataResponse,
  showSubMenu: boolean,
  isSearched: boolean,
  tooManySearchWords: boolean,
): React.ReactElement[] => {
  const items = Object.keys(searchResult);
  if (items.length) {
    return items.map(
      (keyword: string): React.ReactElement => {
        const item = searchResult[keyword];
        if (item.type === DictContentType.translation) {
          return TranslationList(keyword, item, showSubMenu);
        }

        return (
          <React.Fragment key={`dict-ResultList-${keyword}`}>
            {StrongsXList(keyword, item, showSubMenu)}
          </React.Fragment>
        );
      },
    );
  }

  if (isSearched && searchTerm) {
    return [ContentNoDate()];
  }

  if (tooManySearchWords) {
    return [ContentTooManySearchWords()];
  }

  return [ContentHelp()];
};

const ContentList = (): React.ReactElement => {
  // Context can be another approach.
  const {
    searchTerm,
    isSearching,
    searchResult,
    showSubMenu,
    isSearched,
    tooManySearchWords,
  } = useSelector((state: AppState) => {
    return state.dictionary;
  });

  return (
    <div className="dict-content" key="dict-component-ContentList-content">
      {isSearching && <Spinner />}
      {ResultList(searchTerm, searchResult, showSubMenu, isSearched, tooManySearchWords)}
    </div>
  );
};

export default React.memo(ContentList);
