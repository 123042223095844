export const FACEBOOK = 'FACEBOOK';
export const GOOGLE = 'GOOGLE';
export const REDIRECT_TO_PROJECT = 'REDIRECT_TO_PROJECT';
export const REDIRECT_DONE = 'REDIRECT_DONE';
export const SIGN_IN_FAIL = 'SIGN_IN_FAIL';
export const EMAIL_ADDR_UPDATE = 'EMAIL_ADDR_UPDATE';
export const PASSWORD_UPDATE = 'PASS_UPDATE';
export const USER_IS_NOT_SIGNED = 'USER_IS_NOT_SIGNED';

export interface User {
  displayName?: string;
  email: string;
  emailVerified: boolean;
  photoURL: string;
  isAnonymous: boolean;
  uid: string;
  providerData: string[];
  profile?: any;
}
export const CLEAR_ERROR_MSG = 'CLEAR_ERROR_MSG';

// Type for that portion of the app state that
// relates to user sign in.
//
export interface SignInState {
  //
  // True if the sign in machinery is displaying a
  // modal dialog.
  display: boolean;

  // The signed in user as identified by Firebase, or null
  // if no user is signed in.
  user: User | null;

  // True to request redirection from the sign in dialog to
  // the project page.  Used after a successful sign in.
  // When true, the SignIn component will render a redirection
  // to the project page and will also dispatch an action
  // to reset redirect to false.
  redirect: boolean;

  // An error message associated with a recent attempt to sign
  // in, or null if there is no such error message.
  error?: null;

  // The email address to be used for signing in.
  emailAddr: string;

  // The password to be used for signing in.
  password: string;
}

export interface SignInProps {
  display: boolean;
  authenticated: boolean;
  redirectToProject: boolean;
  user: User | null;
  emailAddr: string;
  password: string;
  emailAddrUpdate?: any;
  passwordUpdate?: any;
  signinWithFacebook?: any;
  signinWithGoogle?: any;
  signinWithEmailAddr?: any;
  dispalyLightboxFunc?: any;
  closeLightboxFunc?: any;
  redirectDoneFunc?: any;
  error?: any;
}

interface RedirectAction {
  type: typeof REDIRECT_TO_PROJECT;
}

interface RedirectDoneAction {
  type: typeof REDIRECT_DONE;
}

interface SignFailAction {
  type: typeof SIGN_IN_FAIL;
  error: any;
}

interface SignEmailAddressAction {
  type: typeof EMAIL_ADDR_UPDATE;
  emailAddr: string;
}

interface SignPasswordAction {
  type: typeof PASSWORD_UPDATE;
  password: string;
}

interface ClearErrorAction {
  type: typeof CLEAR_ERROR_MSG;
}

export type SignInActionTypes =
  | RedirectAction
  | RedirectDoneAction
  | SignFailAction
  | SignEmailAddressAction
  | SignPasswordAction
  | ClearErrorAction;
