/* eslint-disable  no-param-reassign */
import * as types from '../types';
import noop from '../lib/noop';

export const INIT_STAT: types.NavigatorState = {
  loading: false,
  display: false,
  activeSlide: 0,
  defaultBookId: types.DEFAULT_BOOK,
  directionSlide: 'next',
  defaultChapter: types.DEFAULT_CHAPTER,
  keyword: '',
  editingBookId: '',
  editBookNameFunc: noop,
  saveBookNameFunc: noop,
  cancelBookNameFunc: noop,
  changeBookNameFunc: noop,
  fetchBookNamesFunc: noop,
  clearErrorFunc: noop,
  editingBookNameMap: {},
  sending: false,
  translatedBookNameMap: {},
};

const bookNameGeneration = (
  state: any,
  projectId: string,
  bookId: string,
  bookName: string | undefined,
): types.EditingBookNameMap => {
  const { editingBookNameMap } = state;
  let bookData: types.BookNameData = {};

  if (projectId in editingBookNameMap) {
    bookData = editingBookNameMap[projectId];
  }

  if (bookName) {
    bookData[bookId] = bookName;
  } else {
    delete bookData[bookId];
  }

  editingBookNameMap[projectId] = bookData;

  return editingBookNameMap;
};

export default (state = INIT_STAT, action: types.NavigatorActionTypes): types.NavigatorState => {
  switch (action.type) {
    case types.DISPLAY_NAVIGATOR:
      return { ...state, display: true };

    case types.CLOSE_NAVIGATOR:
      return { ...state, display: false };

    case types.CHANGE_BOOK:
      return { ...state, selectedBookId: action.bookId, selectedChapter: 1, display: false };

    case types.CHANGE_CHAPTER:
      return { ...state, selectedChapter: action.chapter };

    case types.CHANGE_SLIDE:
      return { ...state, activeSlide: action.index };

    case types.SEARCH_BOOK:
      return { ...state, keyword: action.keyword };

    case types.EDIT_BOOK_NAME:
      return {
        ...state,
        projectId: action.projectId,
        editingBookId: action.editingBookId,
        userAction: undefined,
      };

    case types.CHANGE_BOOK_NAME:
      // eslint-disable-next-line no-case-declarations
      const result = bookNameGeneration(
        state,
        action.projectId,
        action.editingBookId,
        action.translatedBookName,
      );

      return {
        ...state,
        editingBookNameMap: result,
        currentTranslatedBookName: action.translatedBookName,
        userAction: types.CHANGE_BOOK_NAME,
      };

    case types.CANCEL_BOOK_NAME:
      // eslint-disable-next-line no-case-declarations
      const bookNameMap = bookNameGeneration(
        state,
        action.projectId,
        action.editingBookId,
        undefined,
      );

      return {
        ...state,
        editingBookNameMap: bookNameMap,
        editingBookId: '',
        userAction: undefined,
      };

    case types.SAVE_BOOK_NAME_REQUEST:
      return { ...state, sending: true };

    case types.SAVE_BOOK_NAME_FAILURE:
      return { ...state, sending: false, editingBookId: '', error: action.error };

    case types.SAVE_BOOK_NAME_SUCCESS:
      return { ...state, sending: false, editingBookId: '' };

    case types.FETCH_BOOK_NAMES_REQUEST:
      return { ...state, loading: true };

    case types.FETCH_BOOK_NAMES_FAILURE:
      return { ...state, loading: false, error: action.error };

    case types.FETCH_BOOK_NAMES_SUCCESS:
      return { ...state, loading: false, translatedBookNameMap: action.translatedBookNameMap };

    case types.CLEAR_ERROR_MSG:
      return { ...state, loading: false, error: undefined };

    default:
      return { ...state, display: state.display };
  }
};
