/* eslint-disable import/prefer-default-export */
import React from 'react';
import Joi from 'joi-browser';

import { ProjectState, ProjectProps } from '../../types';

export default class ProjectBase extends React.Component<ProjectProps, ProjectState> {
  protected projectValidation(projectData: object): any {
    const schema = {
      projectName: Joi.string().label('Project name').min(3).max(128).required(),
      description: Joi.string().label('Description').min(3).max(3000).required(),
      isRTL: Joi.boolean().required(),
    };
    return Joi.validate(projectData, schema);
  }
}
