/* istanbul ignore file */
export const confirmUnsavedVerse = (message: string, verseModifiedMap: any): boolean => {
  if (verseModifiedMap && verseModifiedMap.size) {
    try {
      // eslint-disable-next-line no-alert
      return window.confirm(message);
    } catch (err) {
      // eslint-disable-next-line no-alert
      window.alert(err.message());
    }
  }

  return true;
};

export interface ConcordanceConfig {
  minClientWidth: number;
  minScreenWidth: number;
  clientWidth4listSize: number;
  smallListWidth: number;
  normalListWidth: number;
}

export const concordanceConfig: ConcordanceConfig = {
  minClientWidth: 500,
  minScreenWidth: 1024,
  clientWidth4listSize: 868,
  smallListWidth: 200,
  normalListWidth: 300,
};

export const determineConcordanceDropdownWidth = (): number => {
  const translationArea = document.getElementById('translation-list');
  const { clientWidth4listSize, smallListWidth, normalListWidth } = concordanceConfig;

  if (translationArea) {
    const { clientWidth } = translationArea;
    if (clientWidth >= clientWidth4listSize) {
      return normalListWidth;
    }
  }

  return smallListWidth;
};
