import {
  ContactState,
  ContactActionTypes,
  SEND_EMAIL_REQUEST,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_FAILURE,
  UPDATE_CONTACT_FORM,
  RESET_CONTACT_FORM,
} from '../types';

const INIT_STAT: ContactState = {
  returnAddress: '',
  subject: '',
  message: '',
  isSending: false,
  isSuccess: false,
  isFailure: false,
};

const Contact = (state = INIT_STAT, action: ContactActionTypes): any => {
  switch (action.type) {
    case SEND_EMAIL_REQUEST:
      return { ...state, isSending: true, isSuccess: false, isFailure: false };

    case SEND_EMAIL_SUCCESS:
      return {
        ...state,
        isSending: false,
        returnAddress: '',
        subject: '',
        message: '',
        isSuccess: true,
      };

    case SEND_EMAIL_FAILURE:
      return {
        ...state,
        isSending: false,
        isSuccess: false,
        isFailure: true,
      };

    case UPDATE_CONTACT_FORM:
      return {
        ...state,
        [action.data.field]: action.data.value,
      };

    case RESET_CONTACT_FORM:
      return {
        ...INIT_STAT,
      };

    default:
      return state;
  }
};

export default Contact;
