import { UndefinedAction } from './lightbox';
import { CLEAR_ERROR_MSG } from './signin';

export const DISPLAY_NAVIGATOR = 'DISPLAY_NAVIGATOR';
export const CLOSE_NAVIGATOR = 'CLOSE_NAVIGATOR';
export const CHANGE_BOOK = 'CHANGE_BOOK';
export const CHANGE_CHAPTER = 'CHANGE_CHAPTER';
export const CHANGE_SLIDE = 'CHANGE_SLIDE';
export const SEARCH_BOOK = 'SEARCH_BOOK';

export const VIEW_OF_BOOK = 'VIEW_OF_BOOK';
export const VIEW_OF_CHAPTER = 'VIEW_OF_CHAPTER';
export type CurrentView = typeof VIEW_OF_BOOK | typeof VIEW_OF_CHAPTER;

export const EDIT_BOOK_NAME = 'EDIT_BOOK_NAME';
export const CHANGE_BOOK_NAME = 'CHANGE_BOOK_NAME';
export const CANCEL_BOOK_NAME = 'CANCEL_BOOK_NAME';
export const SAVE_BOOK_NAME = 'SAVE_BOOK_NAME';
export const SAVE_BOOK_NAME_REQUEST = 'SAVE_BOOK_NAME_REQUEST';
export const SAVE_BOOK_NAME_FAILURE = 'SAVE_BOOK_NAME_FAILURE';
export const SAVE_BOOK_NAME_SUCCESS = 'SAVE_BOOK_NAME_SUCCESS';
export const FETCH_BOOK_NAMES = 'FETCH_BOOK_NAMES';
export const FETCH_BOOK_NAMES_REQUEST = 'FETCH_BOOK_NAMES_REQUEST';
export const FETCH_BOOK_NAMES_FAILURE = 'FETCH_BOOK_NAMES_FAILURE';
export const FETCH_BOOK_NAMES_SUCCESS = 'FETCH_BOOK_NAMES_SUCCESS';

export type BookNameData = {
  [bookId: string]: string;
};

export type EditingBookNameMap = {
  [projectId: string]: BookNameData;
};

interface DisplayNavigatorAction {
  type: typeof DISPLAY_NAVIGATOR;
}

interface CloseNavigatorAction {
  type: typeof CLOSE_NAVIGATOR;
}

interface ChangeBookAction {
  type: typeof CHANGE_BOOK;
  bookId: string;
}

interface ChangeChapterAction {
  type: typeof CHANGE_CHAPTER;
  chapter: number;
}

interface ChangeSlideAction {
  type: typeof CHANGE_SLIDE;
  index: number;
}

interface SearchBookAction {
  type: typeof SEARCH_BOOK;
  keyword: string;
}

// Actions for book name - begin ******************

interface EditBookNameAction {
  type: typeof EDIT_BOOK_NAME;
  projectId: string;
  editingBookId: string;
}

interface ChangeBookNameAction {
  type: typeof CHANGE_BOOK_NAME;
  projectId: string;
  editingBookId: string;
  translatedBookName: string;
}

interface CancelBookNameAction {
  type: typeof CANCEL_BOOK_NAME;
  projectId: string;
  editingBookId: string;
}

interface SaveBookNameAction {
  type: typeof SAVE_BOOK_NAME;
  projectId: string;
  editingBookId: string;
  translatedBookName: string;
}

interface SaveBookNameRequestAction {
  type: typeof SAVE_BOOK_NAME_REQUEST;
}

interface SaveBookNameFailureAction {
  type: typeof SAVE_BOOK_NAME_FAILURE;
  error: any;
}

interface SaveBookNameSuccessAction {
  type: typeof SAVE_BOOK_NAME_SUCCESS;
}

interface FetchBookNamesAction {
  type: typeof FETCH_BOOK_NAMES;
  projectId: string;
}

interface FetchBookNamesRequestAction {
  type: typeof FETCH_BOOK_NAMES_REQUEST;
}

interface FetchBookNamesFailureAction {
  type: typeof FETCH_BOOK_NAMES_FAILURE;
  error: any;
}

interface FetchBookNamesSuccessAction {
  type: typeof FETCH_BOOK_NAMES_SUCCESS;
  translatedBookNameMap: BookNameData;
}

// Actions for book name - end ******************

interface ClearErrorAction {
  type: typeof CLEAR_ERROR_MSG;
}

export type NavigatorActionTypes =
  | DisplayNavigatorAction
  | CloseNavigatorAction
  | ChangeBookAction
  | ChangeChapterAction
  | ChangeSlideAction
  | UndefinedAction
  | SearchBookAction
  | EditBookNameAction
  | ChangeBookNameAction
  | SaveBookNameAction
  | CancelBookNameAction
  | SaveBookNameAction
  | SaveBookNameRequestAction
  | SaveBookNameFailureAction
  | SaveBookNameSuccessAction
  | FetchBookNamesAction
  | FetchBookNamesRequestAction
  | FetchBookNamesFailureAction
  | FetchBookNamesSuccessAction
  | ClearErrorAction;

export interface NavigatorState {
  loading: boolean;
  display: boolean;
  selectedBookId?: string;
  defaultBookId?: string;
  activeSlide?: number;
  directionSlide?: string;
  selectedChapter?: number;
  defaultChapter?: number;
  defaultView?: CurrentView;
  keyword?: string;
  projectId?: string;
  editingBookId?: string;
  editingBookNameMap: EditingBookNameMap;
  translatedBookNameMap: BookNameData;
  editBookNameFunc: Function;
  saveBookNameFunc: Function;
  cancelBookNameFunc: Function;
  changeBookNameFunc: Function;
  fetchBookNamesFunc: Function;
  clearErrorFunc: Function;
  currentTranslatedBookName?: string;
  sending: boolean;
  error?: string;
  userAction?: string;
  changingBookOrChapter?: boolean;
  projectName?: string;
}

export interface NavigatorProps {
  loading: boolean;
  display: boolean;
  parentSelectedBookId?: string;
  selectedBookId?: string;
  defaultBookId?: string;
  displayNavigatorFunc?: any;
  closeNavigatorFunc?: any;
  changeBookFunc?: any;
  activeSlide?: number;
  directionSlide?: string;
  changeSlideFunc?: any;
  parentSelectedChapter?: number;
  selectedChapter?: number;
  defaultChapter?: number;
  defaultView?: CurrentView;
  changeChapterFunc?: any;
  keyword?: string;
  searchBookFunc?: any;
  verseModifiedMap?: Map<string, boolean>;
  projectId?: string;
  editingBookId?: string;
  editingBookNameMap: EditingBookNameMap;
  translatedBookNameMap: BookNameData;
  editBookNameFunc: Function;
  saveBookNameFunc: Function;
  cancelBookNameFunc: Function;
  changeBookNameFunc: Function;
  fetchBookNamesFunc: Function;
  clearErrorFunc: Function;
  currentTranslatedBookName?: string; // to fire componentDidUpdate
  sending: boolean;
  error?: string;
  userAction?: string;
  changingBookOrChapter?: boolean;
  projectName?: string;
}
