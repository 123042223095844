import React, { ReactElement } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Dropdown, Modal } from 'react-bootstrap';
import { config } from '../../config';

import {
  ProjectState,
  ProjectProps,
  ProjectUsers as typeProjectUsers,
  exportTypeI18ns,
  bookGroup,
  SelectedBooks,
} from '../../types';

import {
  projectLoaded,
  ShowProjectCreation,
  fetchProjects,
  clearError,
  showLightboxRemovingProject,
  closeLightboxRemovingProject,
  deleteProject,
  showLightboxEditingProject,
  closeLightboxEditingProject,
  showLightboxManageProjectUsers,
  closeLightboxManageProjectUsers,
  openLightboxExportDataAction,
  closeLightboxExportDataAction,
  dataExportsAction,
  changeExportTypeAction,
  changeBookGroupAction,
  fetchBookNamesAction,
  changeBookAction,
  selectBookInChapterView,
  setChapterRangeFrom,
  setChapterRangeTo,
  selectBookInVerseView,
  selectChapter,
  setVerseRangeMin,
  setVerseRangeMax,
  changeStep,
  dataExportValidationError,
} from '../../actions';
import { AppState } from '../../reducers';
import { Lightbox } from '../lightbox';
import Spinner from '../spinner';
import { ProjectEditing } from './projectEditing';
import { ProjectUsers } from './projectUsers';
import * as userFuncs from '../../lib/user';
import noop from '../../lib/noop';
import {
  BibleBooks,
  BibleBookNames,
  BibleChapters,
  getVerseNumberByBookAndChapter,
  ExportTypes,
} from '../../shared/verseIdParser';

export class ProjectListApp extends React.Component<ProjectProps, ProjectState> {
  public static defaultProps: ProjectProps = {
    // beginningDate: '',
    description: '',
    isFetching: false,
    loading: false,
    projectName: '',
    // targetCompletionDate: '',
    userEmail: '',

    cancelAddingProjectUserFunc: noop,
    changeViewFunc: noop,
    clearErrorAndMessageFunc: noop,
    closeLightboxEditingProjectFunc: noop,
    closeLightboxManageProjectUsersFunc: noop,
    closeLightboxRemovingProjectFunc: noop,
    createProjectFunc: noop,
    deleteProjectFunc: noop,
    deleteProjectUserFunc: noop,
    editProjectFunc: noop,
    fetchProjectFunc: noop,
    fetchProjectsFunc: noop,
    handleUserEmailChangeFunc: noop,
    handleUserPermissionChangeFunc: noop,
    saveProjectUserFunc: noop,
    searchProjectUserEmailFunc: noop,
    showLightboxEditingProjectFunc: noop,
    showLightboxManageProjectUsersFunc: noop,
    showLightboxRemovingProjectFunc: noop,
    ShowProjectCreationFunc: noop,
    showViewOfAddingProjectUserFunc: noop,
    isRTL: false,
    isReadOnly: false,

    displayLightboxOfExportData: false,
    isCreatingData: false,
    projectIdOfExportData: undefined,
    projectNameOfExportData: undefined,
    openLightboxOfExportDataFunc: noop,
    closeLightboxOfExportDataFunc: noop,
    dataExportsFunc: noop,
    exportType: ExportTypes.Books,
    selectedBooks: {},
    selectedBookGroup: undefined,
    currentStep: 1,
    dataExportErrorFunc: noop,
  };

  public constructor(props: ProjectProps) {
    super(props);

    this.handleSelectBook4Chapter = this.handleSelectBook4Chapter.bind(this);
    this.handleSelectBook4Verse = this.handleSelectBook4Verse.bind(this);
    this.handleChangeChapterRangeFrom = this.handleChangeChapterRangeFrom.bind(this);
    this.handleChangeChapterRangeTo = this.handleChangeChapterRangeTo.bind(this);
    this.handleSelectChapter = this.handleSelectChapter.bind(this);
    this.handleChangeVerseRangeMin = this.handleChangeVerseRangeMin.bind(this);
    this.handleChangeVerseRangeMax = this.handleChangeVerseRangeMax.bind(this);
  }

  public componentDidMount(): void {
    const { fetchProjectsFunc } = this.props;

    fetchProjectsFunc();
  }

  // delete project
  // eslint-disable-next-line react/sort-comp
  private deletingProject(projectID: string): void {
    const { showLightboxRemovingProjectFunc } = this.props;
    showLightboxRemovingProjectFunc(projectID);
  }

  private deleteProject(projectID: string): void {
    const { deleteProjectFunc } = this.props;

    deleteProjectFunc(projectID);
  }

  private lightboxRemovingProject(): ReactElement {
    const {
      showLightboxRemovingProjectVar,
      closeLightboxRemovingProjectFunc,
      removeProjectId,
      deletingProject,
    } = this.props;

    if (removeProjectId) {
      return (
        <Lightbox display={showLightboxRemovingProjectVar} key="lightbox-delete-project">
          <div className="modal" role="dialog">
            <div className="modal-dialog ">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">
                    <FormattedMessage id="project.list.deleteProject" />
                  </h5>
                  <button
                    type="button"
                    className="close"
                    onClick={(): void => {
                      closeLightboxRemovingProjectFunc();
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body" style={{ minHeight: '120px' }}>
                  <p>
                    <FormattedMessage id="project.list.areYouSure" />
                  </p>
                  <p>
                    <FormattedMessage id="project.list.workWillBeDeleted" />
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-danger"
                    style={{ minWidth: '100px' }}
                    onClick={(): void => {
                      this.deleteProject(removeProjectId);
                    }}
                  >
                    <FormattedMessage id="delete" />
                  </button>

                  <button
                    type="button"
                    className="btn btn-secondary"
                    style={{ minWidth: '70px' }}
                    onClick={(): void => {
                      closeLightboxRemovingProjectFunc();
                    }}
                  >
                    <FormattedMessage id="cancel" />
                  </button>
                </div>

                {deletingProject && <Spinner />}
              </div>
            </div>
          </div>
        </Lightbox>
      );
    }

    return <></>;
  }

  // edit project - begin
  private editingProject(projectID: string): void {
    const { showLightboxEditingProjectFunc } = this.props;
    showLightboxEditingProjectFunc(projectID);
  }

  private lightboxEditingProject(): ReactElement {
    const { showLightboxEditingProjectVar, editProjectId } = this.props;

    return (
      <Lightbox display={showLightboxEditingProjectVar} key="lightbox-edit-project">
        {editProjectId && <ProjectEditing />}
      </Lightbox>
    );
  }

  // manage users - begin
  private manageProjectUsers(projectId: string): void {
    const { showLightboxManageProjectUsersFunc } = this.props;
    showLightboxManageProjectUsersFunc(projectId);
  }

  private lightboxManageUsers(): ReactElement {
    const { showLightboxManageUsersVar, projectIdManageUsers } = this.props;

    return (
      <Lightbox display={showLightboxManageUsersVar} key="lightbox-manage-users">
        {projectIdManageUsers && <ProjectUsers />}
      </Lightbox>
    );
  }

  private listProjectUsers(users: typeProjectUsers): ReactElement[] {
    const arr: ReactElement[] = [];

    let n = 0;

    Object.keys(users).forEach((key: string): void => {
      n += 1;
      const name = users[key].displayName;
      const commaEle: ReactElement = <span key={`comma-${n}`}>, </span>;

      if (users[key].permission === 'owner') {
        arr.unshift(commaEle);
        arr.unshift(
          <span key={`username-${name}`}>
            <b>{name}</b>
          </span>,
        );
      } else {
        arr.push(<span key={`username-${name}`}>{name}</span>);
        arr.push(commaEle);
      }
    });

    arr.pop();
    return arr;
  }

  private deleteProjectButton(docId: string): ReactElement {
    if (false) {
      return (
        <button
          className="btn btn-link btn-remove text-danger"
          type="button"
          onClick={(): void => {
            this.deletingProject(docId);
          }}
        >
          <i className="fas fa-times" />
          <FormattedMessage id="delete" />
        </button>
      );
    }
    return <></>;
  }

  private openLightboxExportData(projectId: string, projectName: string): void {
    const { openLightboxOfExportDataFunc } = this.props;

    openLightboxOfExportDataFunc(projectId, projectName);

    if (projectId) {
      this.fetchTranslatedBookNames(projectId);
    }
  }

  private closeLightboxExportData(): void {
    const { closeLightboxOfExportDataFunc, fileUrls } = this.props;

    const oldFiles: string[] = [];
    if (fileUrls) {
      Object.values(fileUrls).forEach((fileUrl): void => {
        oldFiles.push(fileUrl);
      });
    }
    closeLightboxOfExportDataFunc(oldFiles);
  }

  protected showStatistic(): ReactElement {
    const { statistic, translatedBookNameMap, fileUrls } = this.props;
    const stack: ReactElement[] = [];

    try {
      if (statistic && fileUrls) {
        const data = JSON.parse(statistic);
        let row = 0;
        let totalVersesInDb = 0;
        let totalVersesGenerated = 0;
        Object.keys(data).forEach((key: string) => {
          row += 1;
          const bookIndex = Number(key);
          const obj = data[key];
          totalVersesInDb += obj.versesInDB;
          totalVersesGenerated += obj.versesGenerated;

          let { bookName } = obj;

          if (translatedBookNameMap && translatedBookNameMap[obj.id]) {
            bookName = translatedBookNameMap[obj.id];
          }

          const { url, filename } = fileUrls.urls[bookIndex];
          const saveAs = filename;

          stack.push(
            <tr key={`tr-statistic-${obj.bookName}`}>
              <th scope="row">{row}</th>
              <td>{this.showBookName(obj.id, bookName)}</td>
              <td>{obj.versesInDB}</td>
              <td>{obj.versesGenerated}</td>
              <td>
                <a href={url} download={saveAs} rel="noopener noreferrer" className="text-ytb">
                  {filename}
                </a>
              </td>
            </tr>,
          );
        });

        stack.push(
          <tr key="tr-statistic-total-row">
            <td colSpan={2} />
            <td>{totalVersesInDb}</td>
            <td colSpan={2}>{totalVersesGenerated}</td>
          </tr>,
        );

        return (
          <div className="row">
            <div className="col-sm">
              <div className="row">
                <div className="col-sm m-4 text-center">
                  <a
                    href={fileUrls.zipUrl?.url}
                    download={`${fileUrls.zipUrl?.filename}.zip`}
                    rel="noopener noreferrer"
                    className="btn btn-ytb"
                  >
                    <FormattedMessage id="download.all" />
                    <i className="fas fa-cloud-download-alt ml-2" />
                  </a>
                </div>
              </div>
              <table className="table table-hover table-sm table-statistic" key="table-statistic">
                <thead key="row-statistic-header">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">
                      <FormattedMessage id="export.book" />
                    </th>
                    <th scope="col">
                      <FormattedMessage id="export.translatedVerses" />
                    </th>
                    <th scope="col">
                      <FormattedMessage id="export.exportedVerses" />
                    </th>
                    <th scope="col">
                      <FormattedMessage id="download" />
                    </th>
                  </tr>
                </thead>
                <tbody>{stack}</tbody>
              </table>
            </div>
          </div>
        );
      }
    } catch (err) {
      return <></>;
    }

    return <></>;
  }

  protected handleChangeExportType(exportType: number): void {
    const { changeExportTypeFunc } = this.props;

    if (typeof changeExportTypeFunc === 'function') {
      changeExportTypeFunc(exportType);
    }
  }

  private handleSelectBooks(e: React.ChangeEvent<HTMLInputElement>): void {
    const { selectedBooks, selectBookFunc } = this.props;
    const { checked, value } = e.target;

    if (selectBookFunc && selectedBooks && value) {
      const bookKey = Number(value);

      if (checked) {
        selectedBooks[bookKey] = BibleBooks[bookKey];
      } else {
        delete selectedBooks[bookKey];
      }

      selectBookFunc(selectedBooks);
    }
  }

  protected handleSelectBookGroup(bookGroupKey: string): void {
    const { changeBookGroupFunc, selectBookFunc } = this.props;

    if (typeof changeBookGroupFunc === 'function') {
      changeBookGroupFunc(bookGroupKey);
    }

    if (selectBookFunc) {
      const selectedBooks: SelectedBooks = {};
      if (bookGroupKey === 'ot') {
        for (let bookKey = 1; bookKey <= 39; bookKey += 1) {
          selectedBooks[bookKey] = BibleBooks[bookKey];
        }
      } else if (bookGroupKey === 'nt') {
        for (let bookKey = 40; bookKey <= 66; bookKey += 1) {
          selectedBooks[bookKey] = BibleBooks[bookKey];
        }
      } else if (bookGroupKey === 'bible') {
        for (let bookKey = 1; bookKey <= 66; bookKey += 1) {
          selectedBooks[bookKey] = BibleBooks[bookKey];
        }
      }
      selectBookFunc(selectedBooks);
    }
  }

  private navOfExportData(): ReactElement {
    const { exportType } = this.props;

    return (
      <>
        <p>
          <FormattedMessage id="export.howDoUWant2Export" />
        </p>

        <div>
          {((): ReactElement[] => {
            const stack: ReactElement[] = [];
            // eslint-disable-next-line no-restricted-syntax
            for (const exportTypeName in ExportTypes) {
              // eslint-disable-next-line no-restricted-globals
              if (isNaN(Number(exportTypeName))) {
                const exportTypeValue = Number(ExportTypes[exportTypeName]);

                const checked = exportTypeValue === exportType;

                stack.push(
                  <div
                    className="form-check"
                    key={`form-check-change-export-type-${exportTypeValue}`}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      name="export-option"
                      id={`exportBy${exportTypeName}`}
                      value={exportTypeValue}
                      onChange={(): void => {
                        this.handleChangeExportType(exportTypeValue);
                      }}
                      checked={checked}
                    />
                    <label className="form-check-label" htmlFor={`exportBy${exportTypeName}`}>
                      <FormattedMessage id={exportTypeI18ns[exportTypeName]}>
                        {(promptMessage: string): string => {
                          return promptMessage;
                        }}
                      </FormattedMessage>
                    </label>
                  </div>,
                );
              }
            }

            return stack;
          })()}
        </div>
      </>
    );
  }

  /**
   * Get translated book name by bookId
   * @param bookId string
   * @returns string of changed book name | undefined
   */
  protected getTranslatedBookName(bookId: string): string | undefined {
    const { translatedBookNameMap } = this.props;

    if (translatedBookNameMap && bookId in translatedBookNameMap) {
      return translatedBookNameMap[bookId];
    }

    return undefined;
  }

  protected showBookName(bookId: string, bookName: string): ReactElement | string {
    const translatedBookName = this.getTranslatedBookName(bookId);

    if (translatedBookName) {
      return translatedBookName;
    }

    return <FormattedMessage id={bookName} />;
  }

  /**
   * Get translated book names
   * @param bookId string
   * @returns string of changed book name | undefined
   */
  protected fetchTranslatedBookNames(projectId: string): void {
    const { fetchBookNamesFunc } = this.props;

    if (typeof fetchBookNamesFunc === 'function') {
      fetchBookNamesFunc(projectId);
    }
  }

  protected bookRows(isOldTestament: boolean, items: string[]): ReactElement[] {
    const { selectedBooks } = this.props;

    return items.map(
      (bookId: string): ReactElement => {
        const bookName = BibleBookNames[bookId as keyof typeof BibleBookNames];
        const bookKey = BibleBooks[bookId as keyof typeof BibleBookNames];
        const checked = selectedBooks && bookKey in selectedBooks;

        return (
          <li className="list-group-item book-name-list" key={`bookRow-${bookId}`}>
            <input
              type="checkbox"
              className="form-check-input"
              value={bookKey}
              id={`book-${bookKey}`}
              key={`book-${bookKey}`}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                this.handleSelectBooks(e);
              }}
              checked={checked}
            />
            <label htmlFor={`book-${bookKey}`} className="form-check-label">
              {this.showBookName(bookId, bookName)}
            </label>
          </li>
        );
      },
    );
  }

  protected bookColumn(isOldTestament: boolean): any {
    const bookRange = { fm: 1, to: 39 };
    if (!isOldTestament) {
      bookRange.fm = 40;
      bookRange.to = 66;
    }

    const books: string[] = [];
    let count = 0;
    Object.entries(BibleBooks).forEach(([key, val]): void => {
      if (typeof val === 'number') {
        count += 1;
        if (count >= bookRange.fm && count <= bookRange.to) {
          books.push(key);
        }
      }
    });

    const mid: number = Math.ceil(books.length / 2);

    return (
      <div className="row book-list-body" key="book-list-body">
        <div className="col-6 px-0 " key="book-list-body-left">
          <ul className="list-group" key="book-list-group-left">
            {this.bookRows(isOldTestament, books.slice(0, mid))}
          </ul>
        </div>
        <div className="col-6 px-0 " key="book-list-body-right">
          <ul className="list-group " key="book-list-group-right">
            {this.bookRows(isOldTestament, books.slice(mid, books.length))}
          </ul>
        </div>
      </div>
    );
  }

  private listBooks(): ReactElement {
    return (
      <div className="list-books">
        <div className="row">
          <div className="col-6 text-center">
            <h2>
              <FormattedMessage id="navigator.oldTestament" />
            </h2>

            {this.bookColumn(true)}
          </div>

          <div className="col-6 text-center">
            <h2>
              <FormattedMessage id="navigator.newTestament" />
            </h2>
            {this.bookColumn(false)}
          </div>
        </div>
      </div>
    );
  }

  protected bookDropdownListOTorNt(isOt: boolean): ReactElement {
    const label = isOt ? 'OT' : 'NT';

    const stack: ReactElement[] = [];
    let index = 0;

    Object.keys(BibleBookNames).forEach((bookId: string) => {
      index += 1;

      let flag = false;
      if (isOt && index <= 39) {
        flag = true;
      } else if (!isOt && index > 39) {
        flag = true;
      }

      if (flag) {
        const translatedBookName = this.getTranslatedBookName(bookId);
        if (translatedBookName) {
          stack.push(
            <option value={bookId} key={`book-option-${bookId}`}>
              {translatedBookName}
            </option>,
          );
        } else {
          const bookName = BibleBookNames[bookId as keyof typeof BibleBookNames];
          stack.push(
            <FormattedMessage id={bookName} key={`book-option-message-${bookId}`}>
              {(message: string): ReactElement => {
                return (
                  <option value={bookId} key={`book-option-${bookId}`}>
                    {message}
                  </option>
                );
              }}
            </FormattedMessage>,
          );
        }
      }
    });

    return <optgroup label={label}>{stack}</optgroup>;
  }

  private handleSelectBook4Chapter(e: React.ChangeEvent<HTMLSelectElement>): void {
    const { exportByChapterSelectsBookFunc } = this.props;

    const bookId = e.target.value;
    if (exportByChapterSelectsBookFunc) {
      exportByChapterSelectsBookFunc(bookId);
    }
  }

  private handleSelectBook4Verse(e: React.ChangeEvent<HTMLSelectElement>): void {
    const { exportByVerseSelectsBookFunc } = this.props;

    const bookId = e.target.value;
    if (exportByVerseSelectsBookFunc) {
      exportByVerseSelectsBookFunc(bookId);
    }
  }

  private handleSelectChapter(e: React.ChangeEvent<HTMLSelectElement>): void {
    const { selectChapterFunc } = this.props;

    const chapter = e.target.value;
    if (selectChapterFunc && chapter) {
      selectChapterFunc(chapter);
    }
  }

  private getTotalChaptersInABook(bookId: string): number {
    return BibleChapters[bookId as keyof typeof BibleChapters];
  }

  protected chapterDropdownList(): ReactElement {
    const { selectedBook4Verse, selectedChapterInVersesExport } = this.props;

    let totalChapters = 1;
    let disabled = true;
    if (selectedBook4Verse) {
      totalChapters = this.getTotalChaptersInABook(selectedBook4Verse);
      disabled = false;
    }

    return (
      <select
        className="form-control"
        id="selectedChapter"
        onChange={this.handleSelectChapter}
        disabled={disabled}
        value={selectedChapterInVersesExport}
      >
        {((): ReactElement[] => {
          const stack: ReactElement[] = [];
          for (let index = 1; index <= totalChapters; index += 1) {
            stack.push(
              <option value={index} key={`chapter-${index}`}>
                {index}
              </option>,
            );
          }

          return stack;
        })()}
      </select>
    );
  }

  protected bookDropdownListForChapter(): ReactElement {
    const { selectedBook4Chapter } = this.props;

    const selectedBook = selectedBook4Chapter || '';

    return (
      <select
        className="form-control"
        id="selectedBook"
        onChange={this.handleSelectBook4Chapter}
        value={selectedBook}
      >
        <option value="">click to select</option>
        {this.bookDropdownListOTorNt(true)}
        {this.bookDropdownListOTorNt(false)}
      </select>
    );
  }

  protected bookDropdownListForVerse(): ReactElement {
    const { selectedBook4Verse } = this.props;

    const selectedBook = selectedBook4Verse || '';

    return (
      <select
        className="form-control"
        id="selectedBook"
        onChange={this.handleSelectBook4Verse}
        value={selectedBook}
      >
        <option value="">click to select</option>
        {this.bookDropdownListOTorNt(true)}
        {this.bookDropdownListOTorNt(false)}
      </select>
    );
  }

  private handleChangeChapterRangeFrom(e: React.ChangeEvent<HTMLInputElement>): void {
    const { exportByChapterSetChapterRangeFromFunc } = this.props;

    if (exportByChapterSetChapterRangeFromFunc) {
      exportByChapterSetChapterRangeFromFunc(e.target.value);
    }
  }

  private handleChangeChapterRangeTo(e: React.ChangeEvent<HTMLInputElement>): void {
    const { exportByChapterSetChapterRangeToFunc } = this.props;

    if (exportByChapterSetChapterRangeToFunc) {
      exportByChapterSetChapterRangeToFunc(e.target.value);
    }
  }

  protected chapterRange(): ReactElement {
    const { chapterFrom, chapterTo, selectedBook4Chapter } = this.props;

    const disabled = !selectedBook4Chapter;

    let chapterMaxChapter = 1;
    if (selectedBook4Chapter) {
      chapterMaxChapter = this.getTotalChaptersInABook(selectedBook4Chapter);
    }

    const chapterRangeFrom = chapterFrom || 1;
    const chapterRangeTo =
      chapterTo && chapterTo <= chapterMaxChapter ? chapterTo : chapterMaxChapter;

    return (
      <>
        <div className="row text-left">
          <div className="col-4">
            <label htmlFor="colFormLabelSm" className="col-form-label">
              From:
            </label>
          </div>
          <div className="col-8">
            <input
              type="number"
              className="form-control"
              id="chapterRangeFrom"
              min="1"
              max={chapterMaxChapter}
              value={chapterRangeFrom}
              onChange={this.handleChangeChapterRangeFrom}
              disabled={disabled}
            />
          </div>
        </div>

        <div className="row text-left">
          <div className="col-4">
            <label htmlFor="colFormLabelSm" className="col-form-label">
              To:
            </label>
          </div>
          <div className="col-8">
            <input
              type="number"
              className="form-control"
              id="chapterRangeFrom"
              min="1"
              max={chapterMaxChapter}
              value={chapterRangeTo}
              onChange={this.handleChangeChapterRangeTo}
              disabled={disabled}
            />
          </div>
        </div>
      </>
    );
  }

  public getVerseNumberByBookNChapter(bookId: string, chapter: number): number {
    return getVerseNumberByBookAndChapter(bookId, chapter);
  }

  private handleChangeVerseRangeMin(e: React.ChangeEvent<HTMLInputElement>): void {
    const { changeVerseRangeMinFunc } = this.props;

    if (changeVerseRangeMinFunc) {
      changeVerseRangeMinFunc(e.target.value);
    }
  }

  private handleChangeVerseRangeMax(e: React.ChangeEvent<HTMLInputElement>): void {
    const { changeVerseRangeMaxFunc } = this.props;

    if (changeVerseRangeMaxFunc) {
      changeVerseRangeMaxFunc(e.target.value);
    }
  }

  protected verseRange(): ReactElement {
    const {
      selectedBook4Verse,
      selectedChapterInVersesExport,
      verseRangeMin,
      verseRangeMax,
    } = this.props;

    const min = 1;
    let max = 1;
    let disabled = true;
    if (selectedBook4Verse && selectedChapterInVersesExport) {
      disabled = false;
      max = this.getVerseNumberByBookNChapter(selectedBook4Verse, selectedChapterInVersesExport);
    }

    const rangeFrom = verseRangeMin || min;
    const rangeTo = verseRangeMax || max;

    return (
      <>
        <div className="row text-left">
          <div className="col-4">
            <label htmlFor="colFormLabelSm" className="col-form-label">
              From:
            </label>
          </div>
          <div className="col-8">
            <input
              type="number"
              className="form-control"
              id="chapterRangeFrom"
              min={min}
              max={max}
              onChange={this.handleChangeVerseRangeMin}
              disabled={disabled}
              value={rangeFrom}
            />
          </div>
        </div>

        <div className="row text-left">
          <div className="col-4">
            <label htmlFor="colFormLabelSm" className="col-form-label">
              To:
            </label>
          </div>
          <div className="col-8">
            <input
              type="number"
              className="form-control"
              id="chapterRangeFrom"
              min={min}
              max={max}
              onChange={this.handleChangeVerseRangeMax}
              disabled={disabled}
              value={rangeTo}
            />
          </div>
        </div>
      </>
    );
  }

  private viewExportByChapters(): ReactElement {
    return (
      <>
        {((): ReactElement => {
          return (
            <>
              <div className="row mt-1 list-chapters">
                <div className="col-6">
                  <div className="form-group w-50 m-auto pt-3 text-center">
                    <label htmlFor="selectedBook" className="pb-2">
                      <FormattedMessage id="navigator.selectBook" />
                    </label>
                    {this.bookDropdownListForChapter()}
                  </div>
                </div>

                <div className="col-6 border-left">
                  <div className="form-group w-50 m-auto pt-3 text-center">
                    <label htmlFor="selectedBook" className="pb-2">
                      <FormattedMessage id="export.selectRangeOfChapters" />
                    </label>
                    {this.chapterRange()}
                  </div>
                </div>
              </div>
            </>
          );
        })()}
      </>
    );
  }

  private viewExportByVerses(): ReactElement {
    return (
      <>
        {((): ReactElement => {
          return (
            <>
              <div className="row mt-1 list-chapters">
                <div className="col-6">
                  <div className="form-group w-50 m-auto pt-3">
                    <label htmlFor="selectedBook">
                      <FormattedMessage id="navigator.selectBook" />
                    </label>
                    {this.bookDropdownListForVerse()}
                  </div>

                  <div className="form-group w-50 m-auto pt-4">
                    <label htmlFor="selectedBook">
                      <FormattedMessage id="export.selectChapter" />
                    </label>
                    {this.chapterDropdownList()}
                  </div>
                </div>

                <div className="col-6 border-left">
                  <div className="form-group w-50 m-auto pt-3 text-center">
                    <label htmlFor="selectedBook" className="pb-2">
                      <FormattedMessage id="export.selectRangeOfVerses" />
                    </label>
                    {this.verseRange()}
                  </div>
                </div>
              </div>
            </>
          );
        })()}
      </>
    );
  }

  private viewExportByBooks(): ReactElement {
    const { selectedBookGroup, selectedBooks } = this.props;

    return (
      <>
        <div className="row">
          <div className="col">
            <p className="mb-0">
              <FormattedMessage id="export.whichBooks" />
            </p>

            <div className="btn-group " role="group">
              {((): ReactElement[] => {
                return Object.keys(bookGroup).map(
                  (key: string): ReactElement => {
                    let cssClass =
                      selectedBookGroup === key
                        ? 'btn btn-outline-secondary short-animate selected'
                        : 'btn btn-outline-secondary short-animate';

                    if (
                      selectedBookGroup === 'none' &&
                      selectedBooks &&
                      Object.keys(selectedBooks).length
                    ) {
                      cssClass = 'btn btn-outline-secondary short-animate';
                    }

                    return (
                      <button
                        key={`btn-book-group-${key}`}
                        type="button"
                        className={cssClass}
                        onClick={(): void => {
                          this.handleSelectBookGroup(key);
                        }}
                      >
                        <FormattedMessage id={bookGroup[key]} />
                      </button>
                    );
                  },
                );
              })()}
            </div>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col">{this.listBooks()}</div>
        </div>
      </>
    );
  }

  /**
   * Start data exports and switch to the exporting view.
   */
  private startDataExports(dataType: string): void {
    const {
      dataExportsFunc,
      projectIdOfExportData,
      exportType,
      selectedBooks,
      selectedBook4Chapter,
      chapterFrom,
      chapterTo,
      selectedBook4Verse,
      selectedChapterInVersesExport,
      verseRangeMin,
      verseRangeMax,
      dataExportErrorFunc,
      projectNameOfExportData,
    } = this.props;

    if (exportType === ExportTypes.Books && selectedBooks) {
      const selectedBookIds = Object.keys(selectedBooks);
      const args = { exportType, selectedBookIds };

      if (selectedBookIds && selectedBookIds.length) {
        dataExportsFunc(projectIdOfExportData, args, dataType, projectNameOfExportData);
      } else {
        dataExportErrorFunc('export.haveNotYetSelectedBooks');
      }
    } else if (exportType === ExportTypes.Chapters) {
      const args = {
        exportType,
        bookId: BibleBooks[selectedBook4Chapter as keyof typeof BibleBooks],
        chapterFrom: Number(chapterFrom),
        chapterTo: Number(chapterTo),
      };
      dataExportsFunc(projectIdOfExportData, args, dataType, projectNameOfExportData);
    } else if (exportType === ExportTypes.Verses) {
      const args = {
        exportType,
        bookId: BibleBooks[selectedBook4Verse as keyof typeof BibleBooks],
        chapter4verse: Number(selectedChapterInVersesExport),
        verseRangeMin: Number(verseRangeMin),
        verseRangeMax: Number(verseRangeMax),
      };
      dataExportsFunc(projectIdOfExportData, args, dataType, projectNameOfExportData);
    } else {
      dataExportErrorFunc('export.exportTypeIsRequired');
    }
  }

  private backToMain(): void {
    const { changeStepFunc } = this.props;

    if (changeStepFunc) {
      changeStepFunc(1);
    }
  }

  private mainOfExportData(): ReactElement {
    const { exportType } = this.props;

    return (
      <>
        {((): ReactElement => {
          if (exportType === ExportTypes.Books) {
            return this.viewExportByBooks();
          }
          if (exportType === ExportTypes.Chapters) {
            return this.viewExportByChapters();
          }

          return this.viewExportByVerses();
        })()}
      </>
    );
  }

  private viewOfExporting(): ReactElement {
    const { isCreatingData, rtfError } = this.props;

    return (
      <>
        <div className="card-body">
          {((): ReactElement => {
            if (rtfError) {
              return (
                <>
                  <div className="row mt-2">
                    <div className="col-sm">
                      <h5>
                        <FormattedMessage id="error" />
                      </h5>
                      <p>
                        <FormattedMessage id="error.unexpectedError" />
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm text-center text-danger">
                      <FormattedMessage id={rtfError} />
                    </div>
                  </div>
                </>
              );
            }

            if (isCreatingData) {
              return (
                <>
                  <div className="row">
                    <div className="col-sm m-4">
                      <h5>
                        <FormattedMessage id="export.titlePreparingData" />
                      </h5>
                      <p>
                        <FormattedMessage id="export.doNotClose" />
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm m-4 text-center">
                      <div className="spinner">
                        <span />
                        <span />
                        <span />
                      </div>
                    </div>
                  </div>
                </>
              );
            }

            return (
              <>
                <div className="row">
                  <div className="col-sm mx-4">
                    <h5>
                      <FormattedMessage id="export.titleOfReady2Download" />
                    </h5>
                    <p>
                      <FormattedMessage id="export.textOfReady2Download" />
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm m-4 ">{this.showStatistic()}</div>
                </div>
              </>
            );
          })()}
        </div>

        <div className="card-footer text-left">
          <button
            className="btn btn-secondary px-4"
            type="button"
            onClick={(): void => {
              this.backToMain();
            }}
            disabled={isCreatingData}
          >
            <i className="fas fa-arrow-left mr-3" />
            <FormattedMessage id="startover" />
          </button>
        </div>
      </>
    );
  }

  /**
   * Validate the exporting arguments.
   */
  public validateExportArguments(): boolean {
    const {
      projectIdOfExportData,
      exportType,
      selectedBooks,
      selectedBook4Chapter,
      chapterFrom,
      chapterTo,
      selectedBook4Verse,
      selectedChapterInVersesExport,
      verseRangeMin,
      verseRangeMax,
    } = this.props;

    if (projectIdOfExportData) {
      if (exportType === ExportTypes.Books && selectedBooks) {
        const selectedBookIds = Object.keys(selectedBooks);
        if (selectedBookIds && selectedBookIds.length) {
          return true;
        }
      } else if (exportType === ExportTypes.Chapters) {
        if (selectedBook4Chapter && chapterFrom && chapterTo) {
          return true;
        }
      } else if (exportType === ExportTypes.Verses) {
        if (selectedBook4Verse && selectedChapterInVersesExport && verseRangeMin && verseRangeMax) {
          return true;
        }
      }
    }

    return false;
  }

  private viewOfExportSteps(): ReactElement {
    const { currentStep, dataExportError } = this.props;

    if (currentStep && currentStep === 2) {
      return this.viewOfExporting();
    }

    return (
      <>
        <div className="card-body">
          <div className="row">
            <div className="col-3">{this.navOfExportData()}</div>
            <div className="col-9 border-left">{this.mainOfExportData()}</div>
          </div>
        </div>
        <div className="card-footer">
          <div className="row">
            <div className="col-7">
              {dataExportError && <FormattedMessage id={dataExportError} />}
            </div>
            <div className="col-5 text-right">
              <button
                className="btn btn-primary mr-4"
                type="button"
                onClick={(): void => {
                  this.startDataExports('usfm');
                }}
                disabled={!this.validateExportArguments()}
              >
                <FormattedMessage id="export.to.usfm" />
              </button>

              <button
                className="btn btn-primary"
                type="button"
                onClick={(): void => {
                  this.startDataExports('rtf');
                }}
                disabled={!this.validateExportArguments()}
              >
                <FormattedMessage id="export.to.rtf" />
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }

  private viewOfExportData(projectName: string | undefined): ReactElement {
    return (
      <div className="card card-data-exports h-100">
        <div className="card-header">
          <div className="row ">
            <div className="col-1">
              <i className="fas fa-bible fa-3x text-ytb " />
            </div>
            <div className="col-11">
              <small>
                <FormattedMessage id="header.project" />
              </small>
              <p>{projectName}</p>
            </div>
          </div>
        </div>

        {this.viewOfExportSteps()}
      </div>
    );
  }

  private lightboxOfExportData(): ReactElement {
    const { displayLightboxOfExportData, projectNameOfExportData } = this.props;

    return (
      <Modal
        show={displayLightboxOfExportData}
        onHide={(): void => {
          this.closeLightboxExportData();
        }}
        key="lightbox-export-data"
        dialogClassName="lightbox-export-data"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="export.data" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.viewOfExportData(projectNameOfExportData)}</Modal.Body>
      </Modal>
    );
  }

  private moreMenu(projectId: string, projectName: string): ReactElement {
    return (
      <FormattedMessage id="more.options">
        {(title: string): ReactElement => (
          <span className="project-menu">
            <Dropdown drop="left" key={`dropdown - project - more - menu - ${projectId}`}>
              <Dropdown.Toggle id={`dropdown - project - more - menu - ${projectId}`} title={title}>
                <i className="fas fa-bars" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  as="button"
                  onClick={(): void => {
                    this.openLightboxExportData(projectId, projectName);
                  }}
                >
                  <FormattedMessage id="export.data" />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </span>
        )}
      </FormattedMessage>
    );
  }

  // list projects
  private listProjects(projectList: any[] | undefined): ReactElement | any[] {
    const { deletingError, currentUid } = this.props;

    if (projectList && projectList.length) {
      const stack = [];

      if (deletingError) {
        stack.push(<div className="alert alert-danger mb-4">{deletingError}</div>);
      }

      const list = projectList.map(
        (doc: firebase.firestore.DocumentData): ReactElement => {
          return (
            <div
              className={
                userFuncs.userIsOwner(currentUid, doc.data.users)
                  ? 'card project-list-card'
                  : 'card project-list-card shared-project '
              }
              key={doc.id}
            >
              {((): ReactElement => {
                if (userFuncs.userIsOwner(currentUid, doc.data.users)) {
                  return this.deleteProjectButton(doc.id);
                }

                return (
                  <div className="project-indication">
                    <i className="fas fa-share-alt" />
                    <FormattedMessage id="project.shared" />
                  </div>
                );
                // end if
              })()}

              {this.moreMenu(doc.id, doc.data.projectName)}
              <h2 className="card-title text-center">{doc.data.projectName}</h2>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm">
                    {/* <div className="row">
                      <div className="col-6 font-weight-bold px-0 py-1 text-right">
                        <FormattedMessage id="project.list.completion" />
                      </div>
                      <div className="col py-1 ">-</div>
                    </div> */}

                    <div className="row">
                      <div className="col-6 font-weight-bold px-0 py-1 text-right">
                        <FormattedMessage id="project.list.createdDate" />
                      </div>
                      <div className="col py-1 ">
                        {moment(doc.data.createdAt.seconds * 1000).format(config.dateFormatUs)}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm border-left">
                    {/* <div className="row">
                      <div className="col-6 font-weight-bold px-0 py-1 text-right">
                        <FormattedMessage id="project.list.beginningDate" />
                      </div>
                      <div className="col py-1 ">
                        {moment(doc.data.beginningDate).format(config.dateFormatUs)}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6 font-weight-bold px-0 py-1 text-right">
                        <FormattedMessage id="project.list.targetCompletionDate" />
                      </div>
                      <div className="col py-1 ">
                        {moment(doc.data.targetCompletionDate).format(config.dateFormatUs)}
                      </div>
                    </div> */}

                    <div className="row">
                      <div className="col-3 font-weight-bold px-0 py-1 text-right">
                        <FormattedMessage id="project.list.description" />
                      </div>
                      <div className="col-9 py-1 ">
                        <pre>{doc.data.description}</pre>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col my-5 text-center">
                    {userFuncs.userIsOwner(currentUid, doc.data.users) && (
                      <button
                        type="button"
                        className="btn btn-primary mr-2"
                        onClick={(): void => {
                          this.editingProject(doc.id);
                        }}
                      >
                        <FormattedMessage id="project.edit" />
                      </button>
                    )}

                    <Link className="btn btn-ytb mx-2" to={`/project/${doc.id}`}>
                      <FormattedMessage id="project.list.translateBible" />
                    </Link>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-2 text-right px-0 mt-3">
                    <span className="user-icon">
                      <i className="fas fa-users" />
                    </span>
                  </div>
                  <div className="col px-3 mt-4 user-list">
                    {this.listProjectUsers(doc.data.users)}
                  </div>
                  <div className="col-2 px-0 mt-3">
                    {userFuncs.userIsOwner(currentUid, doc.data.users) && (
                      <button
                        type="button"
                        className="btn btn-light btn-manage-users "
                        onClick={(): void => {
                          this.manageProjectUsers(doc.id);
                        }}
                      >
                        <FormattedMessage id="project.manage-users" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        },
      );

      stack.push(list);

      stack.push(this.lightboxOfExportData());

      return stack;
    }

    return (
      <div className="alert alert-info mb-4 py-4">
        <FormattedMessage id="project.list.empty" />
      </div>
    );
  }

  public render(): ReactElement {
    const { ShowProjectCreationFunc, isFetchingList, error, projectList } = this.props;

    return (
      <div className="project-list">
        <div className="row">
          <div className="col-8">
            <h1>
              <FormattedMessage id="project.list.myProjects" />
            </h1>
          </div>
          <div className="col-4 text-right mb-1">
            <button
              className="btn btn-ytb"
              type="button"
              onClick={ShowProjectCreationFunc.bind(this)}
            >
              <i className="fas fa-plus mr-1" />
              <FormattedMessage id="project.list.newProject" />
            </button>
          </div>
        </div>
        <hr />
        {((): ReactElement | ReactElement[] => {
          if (error) {
            return <div className="alert alert-danger mb-4">{error}</div>;
          }

          if (isFetchingList) {
            return <div className="loader" />;
          }

          return this.listProjects(projectList);
        })()}

        {this.lightboxRemovingProject()}
        {this.lightboxEditingProject()}
        {this.lightboxManageUsers()}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState): ProjectProps => {
  const props = {
    ...state.project,
  };

  return props;
};

const mapDispatchToProps = (dispatch: Dispatch): object => ({
  loaded: (): void => {
    dispatch(projectLoaded());
  },

  ShowProjectCreationFunc: (): void => {
    dispatch(ShowProjectCreation());
  },

  fetchProjectsFunc: (): void => {
    dispatch(fetchProjects());
  },

  clearErrorAndMessageFunc: (): void => {
    dispatch(clearError());
  },

  showLightboxRemovingProjectFunc: (projectId: string): void => {
    dispatch(showLightboxRemovingProject(projectId));
  },

  closeLightboxRemovingProjectFunc: (): void => {
    dispatch(closeLightboxRemovingProject());
  },

  deleteProjectFunc: (projectId: string): void => {
    dispatch(deleteProject(projectId));
  },

  showLightboxEditingProjectFunc: (projectId: string): void => {
    dispatch(showLightboxEditingProject(projectId));
  },

  closeLightboxEditingProjectFunc: (): void => {
    dispatch(closeLightboxEditingProject());
  },

  // manage project users
  showLightboxManageProjectUsersFunc: (projectId: string): void => {
    dispatch(showLightboxManageProjectUsers(projectId));
  },

  closeLightboxManageProjectUsersFunc: (): void => {
    dispatch(closeLightboxManageProjectUsers());
  },

  openLightboxOfExportDataFunc: (projectId: string, projectName: string): void => {
    dispatch(openLightboxExportDataAction(projectId, projectName));
  },

  closeLightboxOfExportDataFunc: (oldFiles: string[]): void => {
    dispatch(closeLightboxExportDataAction(oldFiles));
  },

  dataExportsFunc: (
    projectId: string,
    params: object,
    dataType: string,
    projectName: string,
  ): void => {
    dispatch(dataExportsAction(projectId, params, dataType, projectName));
  },

  changeExportTypeFunc: (exportType: number): void => {
    dispatch(changeExportTypeAction(exportType));
  },

  changeBookGroupFunc: (bookGroupKey: string): void => {
    dispatch(changeBookGroupAction(bookGroupKey));
  },

  fetchBookNamesFunc: (projectId: string): void => {
    dispatch(fetchBookNamesAction(projectId));
  },

  selectBookFunc: (selectedBooks: SelectedBooks): void => {
    dispatch(changeBookAction(selectedBooks));
  },

  exportByChapterSelectsBookFunc: (bookId: string): void => {
    dispatch(selectBookInChapterView(bookId));
  },

  exportByChapterSetChapterRangeFromFunc: (rangeValue: number): void => {
    dispatch(setChapterRangeFrom(rangeValue));
  },

  exportByChapterSetChapterRangeToFunc: (rangeValue: number): void => {
    dispatch(setChapterRangeTo(rangeValue));
  },

  exportByVerseSelectsBookFunc: (bookId: string): void => {
    dispatch(selectBookInVerseView(bookId));
  },

  selectChapterFunc: (chapter: number): void => {
    dispatch(selectChapter(chapter));
  },

  changeVerseRangeMinFunc: (rangeValue: number): void => {
    dispatch(setVerseRangeMin(rangeValue));
  },

  changeVerseRangeMaxFunc: (rangeValue: number): void => {
    dispatch(setVerseRangeMax(rangeValue));
  },

  changeStepFunc: (step: number): void => {
    dispatch(changeStep(step));
  },

  dataExportErrorFunc: (errorMsg: string): void => {
    dispatch(dataExportValidationError(errorMsg));
  },
});

export const ProjectList = connect(mapStateToProps, mapDispatchToProps)(ProjectListApp);
