import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink, Link } from 'react-router-dom';

import FeatureToggle from '../lib/feature/featureToggle';
import SingIn from './signin';

const matchProject = (match: any, location: any | void): boolean => {
  if (match) {
    return true;
  }

  if (location && Object.prototype.hasOwnProperty.call(location, 'pathname')) {
    const { pathname } = location;

    return pathname.toLowerCase().indexOf('/project') === 0;
  }

  return false;
};

const navLinks = (): ReactElement | null => {
  if (FeatureToggle.isNotActivated('isMarketingOnly')) {
    return (
      <ul className="navbar-nav ml-auto" key="nave-menus">
        <li className="nav-item" key="nav-link-home">
          <NavLink exact to="/" className="nav-link" activeClassName="active">
            <FormattedMessage id="header.home" />
          </NavLink>
        </li>
        <li className="nav-item" key="nav-link-project">
          <NavLink
            exact
            to="/project"
            className="nav-link"
            activeClassName="active"
            key="project-link"
            isActive={matchProject}
          >
            <FormattedMessage id="header.project" />
          </NavLink>
        </li>
        {/* <li className="nav-item" key="nav-link-help">
          <NavLink exact to="/help/" className="nav-link" activeClassName="active">
            <FormattedMessage id="header.help" />
          </NavLink>
          </li> */}
        <li className="nav-item" key="nav-link-contact">
          <NavLink exact to="/contact/" className="nav-link" activeClassName="active">
            <FormattedMessage id="header.contact" />
          </NavLink>
        </li>

        <li className="nav-item dropdown" key="nav-link-sign-in">
          <SingIn key="component-sign-in" />
        </li>
      </ul>
    );
  }
  return null;
};

const Header = (): ReactElement => {
  return (
    <React.Fragment key="react-fragment-nav">
      <nav className="navbar navbar-expand navbar-dark bg-dark fixed-top main-nav" key="headerNav">
        <div className="navbar-collapse" key="navbar-collapse">
          <Link to="/" className="sidebar-toggle d-flex mr-2" key="nav-link-logo">
            <p style={{ fontSize: '20px', padding: '0px', color: '#fff', margin: '0' }}>
              YouTranslate.Bible
            </p>
          </Link>

          {navLinks()}
        </div>
      </nav>
    </React.Fragment>
  );
};

export default Header;
