import { ManuscriptData, Gloss } from '../shared/structs';

export interface ManuscriptViewState {
  isOldTestament: boolean;
  manuscriptDataByVerse: Record<string, ManuscriptData[]>;
}

export interface ManuscriptViewProps {
  isOldTestament: boolean;
  manuscriptDataByVerse: Record<string, ManuscriptData[]>;
  lexiconData: Record<string, Record<string, string>>;
  glosses: Gloss[];
}

/* eslint import/prefer-default-export: "off" */
export const DefaultManuscriptViewProps = {
  isOldTestament: false,
  manuscriptDataByVerse: {},
  lexiconData: {},
  glosses: [],
};
