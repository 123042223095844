import { Translation } from '../shared/structs';
import * as types from '../types';
import firebase from '../lib/firebase';
import errorMessageExtractor from '../lib/errorMessageExtractor';

const FirebaseDb = firebase.createService('db');
const FirebaseAuth = firebase.createService('auth');

export const translationViewIsLoading = (): types.TranslationActionTypes => {
  return {
    type: types.IS_LOADING,
  };
};

export const translationViewIsLoaded = (): types.TranslationActionTypes => {
  return {
    type: types.IS_LOADED,
  };
};

export const changeManuscriptAccordionAction = (
  activeCard: number,
): types.TranslationActionTypes => {
  return {
    type: types.CHANGE_MANUSCRIPT_ACCORDION,
    activeCard,
  };
};

export const fetchDataRequestAction = (): types.TranslationActionTypes => {
  return {
    type: types.FETCH_DATA_REQUEST,
  };
};

export const fetchResourceDataRequestAction = (): types.TranslationActionTypes => {
  return {
    type: types.FETCH_RESOURCE_DATA_REQUEST,
  };
};

export const fetchResourceListRequestAction = (): types.TranslationActionTypes => {
  return {
    type: types.FETCH_RESOURCE_LIST_REQUEST,
  };
};

export const fetchReferenceListRequestAction = (): types.TranslationActionTypes => {
  return {
    type: types.FETCH_REFERENCE_LIST_REQUEST,
  };
};

export const fetchDataFailureAction = (error: string): types.TranslationActionTypes => {
  return {
    type: types.FETCH_DATA_FAILURE,
    error,
  };
};

export const fetchResourceDataFailureAction = (error: string): types.TranslationActionTypes => {
  return {
    type: types.FETCH_RESOURCE_DATA_FAILURE,
    error,
  };
};

export const fetchResourceListFailureAction = (error: string): types.TranslationActionTypes => {
  return {
    type: types.FETCH_RESOURCE_LIST_FAILURE,
    error,
  };
};

export const fetchReferenceListFailureAction = (error: string): types.TranslationActionTypes => {
  return {
    type: types.FETCH_REFERENCE_LIST_FAILURE,
    error,
  };
};

export const fetchReferenceDataRequestAction = (
  referenceId: string,
): types.TranslationActionTypes => {
  return {
    type: types.FETCH_REFERENCE_DATA_REQUEST,
    referenceId,
  };
};

export const fetchReferenceDataFailureAction = (error: string): types.TranslationActionTypes => {
  return {
    type: types.FETCH_REFERENCE_DATA_FAILURE,
    error,
  };
};

export const fetchReferenceDataSuccessAction = (
  referenceId: string,
  data: any,
): types.TranslationActionTypes => {
  return {
    type: types.FETCH_REFERENCE_DATA_SUCCESS,
    referenceId,
    data,
  };
};

export const fetchDataSuccessAction = (
  activeCard: number,
  data: types.CardData,
): types.TranslationActionTypes => {
  return {
    type: types.FETCH_DATA_SUCCESS,
    activeCard,
    data,
  };
};

export const fetchResourceDataSuccessAction = (
  resourceName: string,
  data: any,
): types.TranslationActionTypes => {
  return {
    type: types.FETCH_RESOURCE_DATA_SUCCESS,
    resourceName,
    data,
  };
};

export const fetchResourceListSuccessAction = (data: any): types.TranslationActionTypes => {
  return {
    type: types.FETCH_RESOURCE_LIST_SUCCESS,
    data,
  };
};

export const fetchReferenceListSuccessAction = (data: any): types.TranslationActionTypes => {
  return {
    type: types.FETCH_REFERENCE_LIST_SUCCESS,
    data,
  };
};

export const fetchDataAction = (activeCard: number, bookId: string, chapter: number): any => {
  return async (dispatch: any): Promise<any> => {
    try {
      dispatch(fetchDataRequestAction());
      const currentUser = await (await FirebaseAuth.init()).getCurrentSignedInUser();

      if (currentUser && Object.prototype.hasOwnProperty.call(currentUser, 'uid')) {
        const result = await (await FirebaseDb.init()).getManuscript(bookId, chapter);
        dispatch(fetchDataSuccessAction(activeCard, result));
      } else {
        throw new Error(types.USER_IS_NOT_SIGNED);
      }
    } catch (error) {
      const errorMessage = errorMessageExtractor(error);
      if (errorMessage === types.USER_IS_NOT_SIGNED) {
        dispatch(fetchDataFailureAction(`You must be signed in to access the Bible manuscript.`));
      } else {
        dispatch(fetchDataFailureAction(errorMessage));
      }
    }
  };
};

export const fetchResourceDataAction = (
  resource: string,
  bookId: string,
  chapterId: number,
): any => {
  return async (dispatch: any): Promise<any> => {
    try {
      dispatch(fetchResourceDataRequestAction());
      const currentUser = await (await FirebaseAuth.init()).getCurrentSignedInUser();

      if (currentUser && Object.prototype.hasOwnProperty.call(currentUser, 'uid')) {
        const result = await (await FirebaseDb.init()).getResourceByChapter(
          resource,
          bookId,
          chapterId,
        );
        dispatch(fetchResourceDataSuccessAction(resource, result));
      } else {
        throw new Error(types.USER_IS_NOT_SIGNED);
      }
    } catch (error) {
      const errorMessage = errorMessageExtractor(error);
      if (errorMessage === types.USER_IS_NOT_SIGNED) {
        dispatch(
          fetchResourceDataFailureAction(`You must be signed in to access the Bible manuscript.`),
        );
      } else {
        dispatch(fetchResourceDataFailureAction(errorMessage));
      }
    }
  };
};

export const fetchResourceListAction = (): any => {
  return async (dispatch: any): Promise<any> => {
    try {
      dispatch(fetchResourceListRequestAction());
      const currentUser = await (await FirebaseAuth.init()).getCurrentSignedInUser();

      if (currentUser && Object.prototype.hasOwnProperty.call(currentUser, 'uid')) {
        const result = await (await FirebaseDb.init()).getResourceList();
        dispatch(fetchResourceListSuccessAction(result));
      } else {
        throw new Error(types.USER_IS_NOT_SIGNED);
      }
    } catch (error) {
      const errorMessage = errorMessageExtractor(error);
      if (errorMessage === types.USER_IS_NOT_SIGNED) {
        dispatch(
          fetchResourceListFailureAction(`You must be signed in to access the Bible manuscript.`),
        );
      } else {
        dispatch(fetchResourceListFailureAction(errorMessage));
      }
    }
  };
};

export const fetchReferenceListAction = (): any => {
  return async (dispatch: any): Promise<any> => {
    try {
      dispatch(fetchReferenceListRequestAction());
      const currentUser = await (await FirebaseAuth.init()).getCurrentSignedInUser();

      if (currentUser && Object.prototype.hasOwnProperty.call(currentUser, 'uid')) {
        const result = await (await FirebaseDb.init()).getReferenceList();
        dispatch(fetchReferenceListSuccessAction(result));
      } else {
        throw new Error(types.USER_IS_NOT_SIGNED);
      }
    } catch (error) {
      const errorMessage = errorMessageExtractor(error);
      if (errorMessage === types.USER_IS_NOT_SIGNED) {
        dispatch(
          fetchReferenceListFailureAction(`You must be signed in to access the Bible manuscript.`),
        );
      } else {
        dispatch(fetchReferenceListFailureAction(errorMessage));
      }
    }
  };
};

export const fetchReferenceDataAction = (
  reference: string,
  bookId: string,
  chapterId: number,
): any => {
  return async (dispatch: any): Promise<any> => {
    try {
      dispatch(fetchReferenceDataRequestAction(reference));
      const currentUser = await (await FirebaseAuth.init()).getCurrentSignedInUser();

      if (currentUser && Object.prototype.hasOwnProperty.call(currentUser, 'uid')) {
        const result = await (await FirebaseDb.init()).getReferenceByChapter(
          reference,
          bookId,
          chapterId,
        );
        dispatch(fetchReferenceDataSuccessAction(reference, result));
      } else {
        throw new Error(types.USER_IS_NOT_SIGNED);
      }
    } catch (error) {
      const errorMessage = errorMessageExtractor(error);
      if (errorMessage === types.USER_IS_NOT_SIGNED) {
        dispatch(
          fetchReferenceDataFailureAction(`You must be signed in to access the Bible manuscript.`),
        );
      } else {
        dispatch(fetchReferenceDataFailureAction(errorMessage));
      }
    }
  };
};

export const addResourceAction = (resource: string): types.TranslationActionTypes => {
  return {
    type: types.ADD_RESOURCE,
    resource,
  };
};

export const closeResourceAction = (resource: string): types.TranslationActionTypes => {
  return {
    type: types.CLOSE_RESOURCE,
    resource,
  };
};

export const addReferenceAction = (reference: Translation): types.TranslationActionTypes => {
  return {
    type: types.ADD_REFERENCE,
    reference,
  };
};

export const closeReferenceAction = (reference: Translation): types.TranslationActionTypes => {
  return {
    type: types.CLOSE_REFERENCE,
    reference,
  };
};

export const rememberLastThingRequestAction = (): types.TranslationActionTypes => {
  return {
    type: types.REMEMBER_LAST_THING_REQUEST,
  };
};

export const rememberLastThingFailureAction = (error: string): types.TranslationActionTypes => {
  return {
    type: types.REMEMBER_LAST_THING_FAILURE,
    error,
  };
};

export const rememberLastThingSuccessAction = (): types.TranslationActionTypes => {
  return {
    type: types.REMEMBER_LAST_THING_SUCCESS,
  };
};

export const rememberLastThingAction = (
  projectId: string,
  fieldName: string,
  fieldValue: any,
): any => {
  return async (dispatch: any): Promise<any> => {
    try {
      dispatch(rememberLastThingRequestAction());
      const currentUser = await (await FirebaseAuth.init()).getCurrentSignedInUser();

      if (currentUser && Object.prototype.hasOwnProperty.call(currentUser, 'uid')) {
        const result = await (await FirebaseDb.init()).updateUserWorkedLastThing(
          currentUser.uid,
          projectId,
          fieldName,
          fieldValue,
        );

        if (result) {
          dispatch(rememberLastThingSuccessAction());
        } else {
          dispatch(rememberLastThingFailureAction(`Fail to update last thing worked on.`));
        }
      } else {
        throw new Error(types.USER_IS_NOT_SIGNED);
      }
    } catch (error) {
      if (Object.prototype.hasOwnProperty.call(error, 'message')) {
        if (error.message === types.USER_IS_NOT_SIGNED) {
          dispatch(rememberLastThingFailureAction(`You must be signed in to continue.`));
        } else {
          dispatch(rememberLastThingFailureAction(error.message));
        }
      } else if (typeof error === 'string') {
        dispatch(rememberLastThingFailureAction(error));
      } else {
        dispatch(rememberLastThingFailureAction(String(error)));
      }
    }
  };
};

/** **************** Verse translation data - begin **************** */
export const fetchVerseTranslationsRequestAction = (): types.TranslationActionTypes => {
  return {
    type: types.FETCH_VERSE_TRANSLATIONS_REQUEST,
  };
};
export const fetchVerseTranslationsFailureAction = (
  error: string,
): types.TranslationActionTypes => {
  return {
    type: types.FETCH_VERSE_TRANSLATIONS_FAILURE,
    error,
  };
};
export const fetchVerseTranslationsSuccessAction = (data: any[]): types.TranslationActionTypes => {
  return {
    type: types.FETCH_VERSE_TRANSLATIONS_SUCCESS,
    data,
  };
};
export const fetchVerseTranslationsAction = (
  projectId: string,
  bookId: string,
  chapter: number,
): any => {
  return async (dispatch: any): Promise<any> => {
    try {
      dispatch(fetchVerseTranslationsRequestAction());
      const currentUser = await (await FirebaseAuth.init()).getCurrentSignedInUser();
      if (currentUser && Object.prototype.hasOwnProperty.call(currentUser, 'uid')) {
        const result = await (await FirebaseDb.init()).getTranslationVerseList(
          currentUser.uid,
          projectId,
          bookId,
          chapter,
        );

        if (result) {
          dispatch(fetchVerseTranslationsSuccessAction(result));
        } else {
          dispatch(fetchVerseTranslationsFailureAction(`Fail to fetch translation list.`));
        }
      } else {
        throw new Error(types.USER_IS_NOT_SIGNED);
      }
    } catch (error) {
      if (Object.prototype.hasOwnProperty.call(error, 'message')) {
        if (error.message === types.USER_IS_NOT_SIGNED) {
          dispatch(
            fetchVerseTranslationsFailureAction(`You must be signed in to fetch translation list.`),
          );
        } else {
          dispatch(fetchVerseTranslationsFailureAction(error.message));
        }
      } else if (typeof error === 'string') {
        dispatch(fetchVerseTranslationsFailureAction(error));
      } else {
        dispatch(fetchVerseTranslationsFailureAction(String(error)));
      }
    }
  };
};

/** **************** Verse translation data - end **************** */

export const unmountAction = (): types.TranslationActionTypes => {
  return {
    type: types.UNMOUNT,
  };
};
