/* istanbul ignore file */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-danger */
import React, { ReactElement, RefObject } from 'react';
import ContentLoader from 'react-content-loader';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Modal, Button } from 'react-bootstrap';
import { Prompt } from 'react-router-dom';

import getCaretCoordinates from 'textarea-caret';
import SelectInterlinear from './selectInterlinear';
import SelectVerseDirection from './selectVerseDirection';
import * as types from '../../types';
import * as actions from '../../actions';
import { AppState } from '../../reducers';
import { INIT_STAT_EDITOR } from '../../reducers/verseEditor';
import { VerseIdParser } from '../../shared/verseIdParser';
import Spinner from '../spinner';
import getCurrentProjectId from '../../lib/getCurrentProjectId';
import SourceText from './sourceText';
import { ManuscriptSuggestions, MemoryVerseReference } from '../../shared/structs';
import { RevisionHistory } from './revisionHistory';
import { concordanceConfig } from '../../shared/frontend';

const VerseIdParserInstance = new VerseIdParser();

export class VerseEditorContainerComp extends React.Component<
  types.VerseEditorProps,
  types.VerseEditorState
> {
  private currentEditing = null;

  private wordReplaced = false;

  private autoSuggestionStyle: object = {};

  private autoSuggestionSublistStyle: object = {};

  private textAreaRefs: Map<string, RefObject<HTMLTextAreaElement>>;

  public static defaultProps = INIT_STAT_EDITOR;

  public constructor(props: types.VerseEditorProps) {
    super(props);

    this.state = INIT_STAT_EDITOR;

    this.handleVerseClicked = this.handleVerseClicked.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleComplete = this.handleComplete.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.clearError = this.clearError.bind(this);

    this.textAreaRefs = new Map<string, RefObject<HTMLTextAreaElement>>();
  }

  public componentDidMount(): void {
    this.determineScreenSize();

    window.onresize = (): void => {
      this.determineScreenSize();
      this.recalculateAutoSuggestionStyles();
    };
  }

  public shouldComponentUpdate(
    nextProps: types.VerseEditorProps,
    nextState: types.VerseEditorState,
  ): any {
    if (this.wordReplaced) {
      this.currentEditing = null;
      this.wordReplaced = false;
    } else {
      this.currentEditing = this.getCurrentEditingWord(nextState);
    }

    return true;
  }

  public componentDidUpdate(): void {
    this.recalculateAutoSuggestionStyles();

    window.onresize = (): void => {
      this.determineScreenSize();
    };
  }

  public getAutoSuggestion(): void {
    const { fetchSuggestion, fetchSuggestionFailure, textId } = this.props;
    const projectDocId = getCurrentProjectId();

    try {
      fetchSuggestion(projectDocId, textId, 'S1');
    } catch (error) {
      fetchSuggestionFailure(error);
    }
  }

  private getCurrentEditingWord(nextState: any): any {
    const { translation } = this.state;

    let currentItems: string[] = [];
    let nextItems: string[] = [];

    if (translation) {
      currentItems = translation.split(/\s/);
    }

    if ('translation' in nextState) {
      nextItems = nextState.translation.split(/\s/);
    }

    if (currentItems.length && nextItems.length) {
      for (let index = 0; index < nextItems.length; index += 1) {
        if (nextItems[index] !== currentItems[index]) {
          return nextItems[index];
        }
      }
    }

    if (nextItems.length) {
      return nextItems.pop();
    }

    return null;
  }

  private getFocusedButtonIndex(buttons: HTMLCollectionOf<any>): number {
    if (buttons && buttons.length) {
      for (let index = 0; index < buttons.length; index += 1) {
        if (buttons[index].classList.contains('focus')) {
          return index;
        }
      }
    }

    return 0;
  }

  private getSuggestionButtons(): HTMLCollectionOf<any> {
    return document.getElementsByClassName('suggestion-btn');
  }

  private getAutoSuggestionsForTextId(textId: string): ManuscriptSuggestions | undefined {
    const { autoSuggestionMap } = this.props;
    let autoSuggestions;
    if (autoSuggestionMap.has(textId)) {
      autoSuggestions = autoSuggestionMap.get(textId);
    }
    return autoSuggestions;
  }

  private getLinksVerifiedStatus(): boolean {
    const { textId, verses } = this.props;

    if (verses) {
      const currentVerse = verses.find((verse) => verse.textId === textId);
      if (currentVerse) {
        return Boolean(currentVerse.linksVerified);
      }
    }
    return false;
  }

  private pressSelectedKey(code: number): boolean {
    return [9, 13].includes(code);
  }

  private handleKeyDown(e: any): void {
    const code = e.which;
    if (
      this.isSuggestionListVisible() &&
      (this.pressSelectedKey(code) || (code >= 37 && code <= 40))
    ) {
      e.preventDefault();
    }
  }

  private handleKeyUp(e: any): void {
    const code = e.which;
    const buttons: HTMLCollectionOf<any> = this.getSuggestionButtons();

    if (buttons && buttons.length) {
      const maxIndex = buttons.length - 1;

      let index = this.getFocusedButtonIndex(buttons);

      if (this.pressSelectedKey(code)) {
        buttons[index].click();
      } else {
        if (code === 40) {
          index += 1;
        } else if (code === 38) {
          index -= 1;
        }

        if (index < 0) {
          index = maxIndex;
        }

        if (index > maxIndex) {
          index = 0;
        }

        this.changeFocusedButton(buttons, index);
      }
    }
  }

  private changeFocusedButton(buttons: HTMLCollectionOf<any>, key: number): void {
    if (buttons && buttons.length) {
      for (let index = 0; index < buttons.length; index += 1) {
        if (index !== key) {
          buttons[index].classList.remove('focus');
        }
      }
      buttons[key].classList.add('focus');
    }
  }

  private handleVerseClicked(): void {
    const {
      openEditor,
      editingGbiVerseCode,
      verseTextMap,
      verseCompleteMap,
      verseModifiedMap,
      projectId,
      textId,
      updateVerse,
    } = this.props;
    if (
      editingGbiVerseCode &&
      verseTextMap.has(editingGbiVerseCode) &&
      verseModifiedMap.has(editingGbiVerseCode)
    ) {
      const text = verseTextMap.get(editingGbiVerseCode);
      const completeValue = verseCompleteMap.get(editingGbiVerseCode);
      const completeStatus = !!completeValue;

      updateVerse(projectId, editingGbiVerseCode, text, completeStatus, completeStatus);
    }

    openEditor(textId);

    this.getAutoSuggestion();
  }

  private handleClose(): void {
    const {
      closeEditor,
      updateVerse,
      projectId,
      verseTextMap,
      verseModifiedMap,
      textId,
    } = this.props;

    if (textId && verseModifiedMap.has(textId)) {
      const text = verseTextMap.get(textId);
      updateVerse(projectId, textId, text, false, false);
    }

    closeEditor();
  }

  private changeTranslation(text: string): void {
    const { changeVerseTranslation, textId } = this.props;

    changeVerseTranslation(textId, text);
    this.setState({ error: '', translation: text });
  }

  private handleChange(e: any): void {
    this.changeTranslation(e.target.value);
  }

  private handleClickWord(word: string): void {
    const { textId } = this.props;

    const el: any | null = document.getElementById(`textarea-${textId}`);
    if (el) {
      const start = el.selectionStart;
      const end = el.selectionEnd;

      let char = '-';
      let left = start;
      while (left > 0 && char !== ' ' && char !== '\n') {
        left -= 1;
        const right = left + 1;
        char = el.value.substring(left, right);
      }
      left = left > 0 ? left + 1 : 0;

      const result = `${el.value.substring(0, left)}${word}${el.value.substring(end)}`;

      el.value = result;
      el.focus();
      el.selectionEnd = left + word.length + 1;

      this.wordReplaced = true;
      this.changeTranslation(result);
    }
  }

  private handleCloseModal(): void {
    this.setState({ error: '' });
  }

  private matchSuggestions(
    targetSuggestions: Record<string, MemoryVerseReference[]>,
    word: string | null,
  ): object {
    if (word) {
      const map = new Map();

      Object.keys(targetSuggestions).forEach((key: string): any => {
        if (key.toLowerCase().startsWith(word.toLowerCase())) {
          map.set(key, targetSuggestions[key]);
        }
      });

      return Array.from(map).reduce((obj, [key, val]) => {
        return Object.assign(obj, { [key]: val });
      }, {});
    }

    return {};
  }

  private suggestionSubList(items: any, word: string, index: number): any {
    let n = -1;
    const list = items.map((item: any): any => {
      n += 1;
      const html = item.text.replace(new RegExp(word, 'gi'), `<b>${word}</b>`);
      const verseInfo = VerseIdParserInstance.getReadableReferenceForGbiId(item.textId);

      return (
        <li className="list-group-item" key={`autosuggestion-sub-list-${item.textId}-${n}`}>
          <FormattedMessage id={verseInfo.book}>
            {(book: any): any => <sub>{`${book} ${verseInfo.ref}`}</sub>}
          </FormattedMessage>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </li>
      );
    });

    return (
      <ul
        className="list-group border suggestion-sub-list"
        key={`suggestion-sub-list-${index}`}
        style={this.autoSuggestionSublistStyle}
      >
        {list}
      </ul>
    );
  }

  private suggestionList(words: any): ReactElement | null {
    if (words) {
      const stack: ReactElement[] = [];
      let index = -1;
      Object.keys(words)
        .sort()
        .forEach((key: any): void => {
          index += 1;

          stack.push(
            <li className="list-group-item" key={`autosuggestion-list-${index}`}>
              <button
                type="button"
                key={`autosuggestion-list-button-${index}`}
                className="btn btn-link suggestion-btn"
                onClick={(): void => {
                  this.handleClickWord(key);
                }}
              >
                {key}
              </button>

              {this.suggestionSubList(words[key], key, index)}
            </li>,
          );
        });

      return (
        <div
          key="suggestionList"
          className="suggestion-list border"
          style={this.autoSuggestionStyle}
        >
          <ul className="list-group suggestion-main-list">{stack}</ul>
        </div>
      );
    }

    return null;
  }

  private shouldShowSuggestionList(words: object): boolean {
    const { editingGbiVerseCode } = this.props;
    return (
      Boolean(editingGbiVerseCode) &&
      Boolean(words) &&
      Boolean(Object.keys(words).length) &&
      !this.verseIsComplete()
    );
  }

  private isSuggestionListVisible(): boolean {
    const { textId } = this.props;
    const autoSuggestions = this.getAutoSuggestionsForTextId(textId);
    if (autoSuggestions) {
      const words = this.matchSuggestions(autoSuggestions.targetSuggestions, this.currentEditing);
      return this.shouldShowSuggestionList(words);
    }
    return false;
  }

  private showSuggestionList(
    targetSuggestions: Record<string, MemoryVerseReference[]>,
  ): object | null {
    const words = this.matchSuggestions(targetSuggestions, this.currentEditing);

    if (this.shouldShowSuggestionList(words)) {
      return this.suggestionList(words);
    }
    return null;
  }

  private displayAutoSuggestionPlaceholder(): ReactElement {
    return (
      <ContentLoader
        viewBox="0 0 300 90"
        speed={3}
        backgroundColor="#d6d6d6"
        foregroundColor="#ecebeb"
        className="auto-suggestion-placeholder"
      >
        <rect x="0" y="0" rx="3" ry="3" width="260" height="4" />
        <rect x="0" y="10" rx="3" ry="3" width="290" height="4" />
        <rect x="0" y="20" rx="3" ry="3" width="190" height="4" />
        <rect x="0" y="30" rx="3" ry="3" width="300" height="40" />
        <rect x="0" y="75" rx="3" ry="3" width="60" height="4" />
      </ContentLoader>
    );
  }

  private handleComplete(e: any): void {
    const {
      updateVerse,
      projectId,
      textId,
      verseTextMap,
      uncheckCompleteBox,
      updateVerseStatus,
    } = this.props;

    if (textId) {
      const text = verseTextMap.get(textId);

      if (e.target.checked) {
        // checkbox - on
        if (text) {
          updateVerse(projectId, textId, text, true, true);
        } else {
          // translation text is empty
          uncheckCompleteBox(textId);

          this.setState({ error: 'error.verseTranslationEmpty' });
        }
      } else {
        // checkbox -off
        uncheckCompleteBox(textId);

        if (text) {
          try {
            updateVerseStatus(projectId, textId, false); // update the complete status to FireStore
          } catch (err) {
            if (err === 'error.mustBeSignedToMakeChanges') {
              this.setState({ error: 'error.mustBeSignedToMakeChanges' });
            }
          }
        }
      }
    } // end if
  }

  private recalculateAutoSuggestionStyles(): void {
    const { textId } = this.props;
    const verseCode = textId || '';
    const elRef = this.textAreaRefs.get(verseCode);
    const el = elRef ? elRef.current : null;
    if (el && 'selectionEnd' in el && 'clientHeight' in el) {
      const { selectionEnd, clientHeight, clientWidth } = el;
      const caret = getCaretCoordinates(el, selectionEnd);

      if ('top' in caret) {
        const listWidth = 200;
        let sublistWidth = listWidth;
        const topPos = caret.top + 20 > clientHeight ? clientHeight : caret.top + 20;
        const leftPos = caret.left + listWidth > clientWidth ? clientWidth - listWidth : caret.left;

        const leftSpace = leftPos;
        const rightSpace = clientWidth - leftPos - listWidth;
        const maxSpace = Math.max(leftSpace, rightSpace);

        if (maxSpace < listWidth) {
          sublistWidth = maxSpace;
        }

        this.autoSuggestionSublistStyle =
          leftSpace > rightSpace
            ? { right: '100%', width: `${sublistWidth}px` }
            : { left: '100%', width: `${sublistWidth}px` };

        this.autoSuggestionStyle = {
          top: `${topPos}px`,
          left: leftPos,
          width: `${listWidth}px`,
        };
      }
    }
  }

  private lightboxRevisionHistory(textId: string): ReactElement {
    return <RevisionHistory key={`revision-history-component-${textId}`} />;
  }

  private verseIsComplete(): boolean {
    const { textId, verseCompleteMap } = this.props;

    let complete = false;
    if (verseCompleteMap && textId && verseCompleteMap.has(textId)) {
      complete = verseCompleteMap.get(textId) || false;
    }
    return complete;
  }

  public determineScreenSize(): void {
    const { showError, translationArea } = this.props;
    const { minClientWidth, minScreenWidth } = concordanceConfig;

    if (showError && translationArea) {
      const { clientWidth } = translationArea;
      const screenWidth = window.screen.width;

      if (clientWidth < minClientWidth || screenWidth < minScreenWidth) {
        showError('warning.screenTooSmall');
      } else {
        showError(undefined);
      }
    }
  }

  public clearError(): void {
    const { showError } = this.props;
    if (showError) {
      showError(undefined);
    }
  }

  public browserOrScreenTooSmall(): ReactElement {
    const { error } = this.props;
    return (
      <Modal show={!!error} onHide={this.clearError} className="modal-message">
        <Modal.Header closeButton />
        <Modal.Body>{error && <FormattedMessage id={error.toString()} />}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.clearError}>
            <FormattedMessage id="OK" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  public render(): ReactElement {
    const { error } = this.state;
    const {
      verseTextMap,
      verseModifiedMap,
      verseId,
      textId,
      bookId,
      chapter,
      verseManuscriptData,
      syntaxGroupData,
      editingGbiVerseCode,
      updating,
      updatingGbiVerseCode,
      loading,
      license,
      licenseMorphology,
      licenseSyntax,
      glosses,
      isNTrtl,
      isOTrtl,
      isNTltr,
      isOTltr,
      isRTL,
      isProjectReadOnly,
      triggerAlignment,
    } = this.props;

    // css class variables to control the ltr/rtl positioning
    let textDirection = 'ltr';
    let classvar = 'btn btn-link verse-box';
    if (isRTL) {
      textDirection = 'rtl';
      classvar = 'btn btn-link verse-box rtl';
    }

    let text = '';
    if (verseTextMap && textId && verseTextMap.has(textId)) {
      text = verseTextMap.get(textId) || '';
    }

    const complete = this.verseIsComplete();

    if (textId && textId === editingGbiVerseCode) {
      const disableBtnReviewAlignment = isProjectReadOnly ? false : !complete;
      // editing mode
      const textareaClass = verseModifiedMap.has(textId)
        ? 'form-control form-control-changed'
        : 'form-control';
      const autoSuggestions = this.getAutoSuggestionsForTextId(textId);
      return (
        <div className="form-group verse-box" key={`verse-editor-${textId}`}>
          <FormattedMessage id="translation.prompt">
            {(promptMessage: any): ReactElement | null => {
              if (verseModifiedMap && verseModifiedMap.size > 0) {
                return <Prompt message={promptMessage} />;
              }

              return null;
            }}
          </FormattedMessage>

          {this.browserOrScreenTooSmall()}

          <span className="verse-number">
            <sup>{verseId}</sup>
          </span>

          {((updating && updatingGbiVerseCode === textId) || loading) && <Spinner />}

          {((): ReactElement => {
            if (autoSuggestions) {
              const textAreaRef = React.createRef<HTMLTextAreaElement>();
              this.textAreaRefs.set(textId, textAreaRef);
              return (
                <>
                  <button
                    type="button"
                    className="close"
                    title="Close"
                    onClick={this.handleClose}
                    dir={textDirection}
                  >
                    <span>&times;</span>
                  </button>

                  <SelectVerseDirection
                    isNTrtl={isNTrtl}
                    isOTrtl={isOTrtl}
                    isNTltr={isNTltr}
                    isOTltr={isOTltr}
                    isRTL={isRTL}
                    textId={editingGbiVerseCode}
                    uid=""
                  />

                  <SelectInterlinear
                    uid=""
                    bookId={bookId}
                    chapter={chapter}
                    glossVersions={[]}
                    glossOT=""
                    glossNT=""
                  />

                  <SourceText
                    textId={editingGbiVerseCode}
                    manuscriptSuggestions={autoSuggestions}
                    verseManuscriptData={verseManuscriptData || []}
                    syntaxGroupData={syntaxGroupData}
                    license={license}
                    licenseMorphology={licenseMorphology}
                    licenseSyntax={licenseSyntax}
                    glosses={glosses}
                    isRTL={isRTL}
                    isNTrtl={isNTrtl}
                    isOTrtl={isOTrtl}
                    isNTltr={isNTltr}
                    isOTltr={isOTltr}
                    uid=""
                  />

                  <div className="text-area">
                    <div className="textarea-box">
                      <FormattedMessage id="Enter your translation">
                        {(placeholder: string): ReactElement => (
                          <textarea
                            disabled={complete || isProjectReadOnly}
                            className={textareaClass}
                            placeholder={placeholder}
                            onChange={this.handleChange}
                            onKeyUp={this.handleKeyUp}
                            onKeyDown={this.handleKeyDown}
                            defaultValue={text}
                            id={`textarea-${textId}`}
                            ref={textAreaRef}
                            dir={textDirection}
                          />
                        )}
                      </FormattedMessage>

                      {this.showSuggestionList(autoSuggestions.targetSuggestions)}
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-sm mt-2">
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="checkbox-complete"
                          onChange={this.handleComplete}
                          checked={complete}
                          dir={textDirection}
                          disabled={isProjectReadOnly}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="checkbox-complete"
                          dir={textDirection}
                        >
                          <FormattedMessage id="alignment.saveAndEditAlignment" />
                        </label>
                      </div>

                      <Modal
                        show={!!error}
                        onHide={this.handleCloseModal}
                        className="modal-message"
                      >
                        <Modal.Header closeButton />
                        <Modal.Body>
                          {error && <FormattedMessage id={error.toString()} />}
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="primary"
                            onClick={this.handleCloseModal}
                            dir={textDirection}
                          >
                            <FormattedMessage id="OK" />
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </div>

                    <div className="col-sm text-right p-0">
                      <button
                        type="button"
                        className="btn btn-link btn-link-ytb"
                        onClick={(): void => {
                          triggerAlignment(textId);
                        }}
                        disabled={disableBtnReviewAlignment}
                      >
                        <i className="fas fa-external-link-alt fa-lg" />
                        <FormattedMessage id="alignment.reviewAlignment" />
                      </button>
                      {this.lightboxRevisionHistory(textId)}
                    </div>
                  </div>
                </>
              );
            }

            return this.displayAutoSuggestionPlaceholder();
          })()}
        </div>
      );
    }

    const linksVerified = this.getLinksVerifiedStatus();

    return (
      <button
        type="button"
        className={classvar}
        onClick={this.handleVerseClicked}
        dir={textDirection}
      >
        <sup>{verseId}</sup>
        <span>{text}</span>
        {linksVerified && <i className="fas fa-check complete-sign" />}
      </button>
    );
  }
}

export const mapStateToProps = (state: AppState): any => {
  const { projectId } = state.translation;

  const props = {
    ...state.verseEditor,
    projectId,
    verses: state.translation.verseTranslations,
    license: state.translation.cardData.get(0)?.license,
    licenseMorphology: state.translation.cardData.get(0)?.licenseMorphology,
    licenseSyntax: state.translation.cardData.get(0)?.licenseSyntax,
    glosses: state.gloss.glosses,
    isProjectReadOnly: state.project.isReadOnly,
    isRTL: state.project.isRTL,
    profile: state.profile,
    isNTrtl: state.profile.isNTrtl,
    isNTltr: state.profile.isNTltr,
    isOTrtl: state.profile.isOTrtl,
    isOTltr: state.profile.isOTltr,
  };

  return props;
};

export const mapDispatchToProps = (dispatch: Dispatch): any => ({
  changeVerseTranslation: (textId: string, verseTranslation: string): void => {
    dispatch(actions.changeVerseAction(textId, verseTranslation));
  },

  updateVerse: (
    projectId: string,
    textId: string,
    verseTranslation: string,
    complete: boolean,
    closeEditor: boolean,
  ): void => {
    dispatch(actions.updateVerseAction(projectId, textId, verseTranslation, complete, closeEditor));
  },

  openEditor: (textId: string): void => {
    dispatch(actions.openEditorAction(textId));
  },

  closeEditor: (): void => {
    dispatch(actions.closeEditorAction());
  },

  uncheckCompleteBox: (textId: string): void => {
    dispatch(actions.uncheckCompleteBoxAction(textId));
  },

  showError: (error: string): void => {
    dispatch(actions.showErrorAction(error));
  },

  updateVerseStatus: (projectId: string, textId: string, complete: boolean): void => {
    dispatch(actions.updateVerseStatusAction(projectId, textId, complete));
  },

  fetchSuggestion: (projectId: string, textId: string, versification: string): void => {
    dispatch(actions.fetchSuggestionAction(projectId, textId, versification));
  },

  fetchSuggestionFailure: (error: any): void => {
    dispatch(actions.fetchSuggestionFailureAction(error));
  },

  fetchGlosses: (bookId: string, chapter: number, version: string): void => {
    dispatch(actions.fetchGlossAction(bookId, chapter, version));
  },

  triggerAlignment: (textId: string): void => {
    dispatch(actions.triggerAlignment(textId));
  },

  searchConcordance: (projectId: string, term: string): void => {
    dispatch(actions.searchBoxSubmitsAction(projectId, term));
  },
});

const VerseEditorContainer = connect(mapStateToProps, mapDispatchToProps)(VerseEditorContainerComp);

export default VerseEditorContainer;
