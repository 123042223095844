import FirebaseSdkProvider from './firebase/firebaseSdkProvider';

export default abstract class FirebaseFunctionClient {
  protected async invokeFunction(functionName: string, request: any): Promise<any> {
    const sdk = await FirebaseSdkProvider.getSdk();
    await this.useFunctionsEmulatorIfLocal(sdk);

    return sdk.functions().httpsCallable(functionName)(request);
  }

  public async useFunctionsEmulatorIfLocal(sdk: any): Promise<void> {
    if (process.env.NODE_ENV === 'development') {
      sdk.functions().useFunctionsEmulator('http://localhost:5001');
    }
  }
}
