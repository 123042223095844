import * as types from '../types';
import noop from '../lib/noop';

export const INIT_STAT_REVISION_HISTORY: types.RevisionHistoryState = {
  isLoading: true,
  display: false,
  openRevisionHistoryFunc: noop,
  closeRevisionHistoryFunc: noop,
  fetchRevisionHistoryDataFunc: noop,
};

export default (
  state = INIT_STAT_REVISION_HISTORY,
  action: types.RevisionHistoryTypes,
): types.RevisionHistoryState => {
  switch (action.type) {
    case types.OPEN_REVISION_HISTORY:
      return { ...state, display: true, isLoading: true };

    case types.CLOSE_REVISION_HISTORY:
      return { ...state, display: false };

    case types.FETCH_REVISION_HISTORY_REQUEST:
      return { ...state, isLoading: true };

    case types.FETCH_REVISION_HISTORY_FAILURE:
      return { ...state, isLoading: false, error: action.error };

    case types.FETCH_REVISION_HISTORY_SUCCESS:
      return { ...state, isLoading: false, revisionHistoryArray: action.data };

    default:
      return state;
  }
};
