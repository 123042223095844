/* istanbul ignore file */
import { ProjectUsers } from '../types/project';

export const userPermission = (uid: string | undefined, users: ProjectUsers): string => {
  return uid && users && uid in users ? users[uid].permission : 'readOnly';
};

export const userIsOwner = (uid: string | undefined, users: ProjectUsers): boolean => {
  return userPermission(uid, users) === 'owner';
};

export const userIsMember = (uid: string | undefined, users: ProjectUsers): boolean => {
  return !!(uid && users && uid in users);
};

export const nameInitial = (nameString: string): string => {
  if (nameString) {
    const names = nameString.split(' ');
    if (names.length > 1) {
      const initials = names.map((name: string) => name[0]);
      return initials.join('.');
    }

    const initial = nameString.slice(0, 3);
    return initial;
  }

  return nameString;
};
