import * as types from '../types';
import { Translation, ProjectTranslationVerse, TranslationLink } from '../shared/structs';

export const INIT_STAT: types.TranslationState = {
  loading: true,
  loadingVerses: false,
  cardData: new Map<number, types.CardData>(),
  resourceData: new Map(),
  referenceData: new Map(),
  authenticated: false,
  authorizationFail: false,
  selectedBookId: types.DEFAULT_BOOK,
  selectedChapter: types.DEFAULT_CHAPTER,
  error: undefined,
  activeCard: 0,
  isFetching: false,
  resources: new Array<string>(),
  references: new Array<Translation>(),
  availableResources: { empty: true, data: [] },
  availableReferences: { empty: true, data: [] },
  projectNotExisted: false,
  verseId: undefined,
  userIsProjectMember: false,
  changingBookOrChapter: false,
};

const regenerateVersesWithVerified = (
  textId: string,
  existingVerses: ProjectTranslationVerse[] = [],
  links: TranslationLink,
): ProjectTranslationVerse[] => {
  const existingVerse = existingVerses.find((verse) => verse.textId === textId);
  if (existingVerse) {
    const result = existingVerses.map((verse: ProjectTranslationVerse) => {
      if (verse.textId === textId) {
        const newVerse = verse;
        newVerse.linksVerified = true;
        newVerse.links = JSON.parse(JSON.stringify(links));

        return newVerse;
      }
      return verse;
    });

    return result;
  }

  const result = existingVerses.concat({ textId, linksVerified: true, text: '', textSegments: [] });

  return result;
};

export default (
  state = INIT_STAT,
  action:
    | types.TranslationActionTypes
    | types.CreateProjectActionTypes
    | types.NavigatorActionTypes
    | types.VerseEditorTypes
    | types.AuthActionTypes,
): types.TranslationState => {
  switch (action.type) {
    case 'persist/REHYDRATE':
      return { ...state, authorizationFail: false, authenticated: false };

    case types.IS_LOADING:
      return { ...state, loading: true };

    case types.IS_LOADED:
      return { ...state, loading: false };

    case types.USER_NOT_AUTHENTICATED:
      return { ...state, authorizationFail: true };

    case types.USER_AUTHENTICATED:
      return { ...state, authorizationFail: false, authenticated: true };

    case types.FETCH_PROJECT_REQUEST:
      return { ...state, loading: true };

    case types.FETCH_PROJECT_FAILURE:
      return { ...state, projectNotExisted: true, loading: false, error: action.error };

    case types.FETCH_PROJECT_SUCCESS:
      if (Object.prototype.hasOwnProperty.call(action.projectData, 'lastThingWorkedOn')) {
        return {
          ...state,
          loading: false,
          authenticated: true,
          projectName: action.projectData.projectName,
          projectId: action.projectId,
          selectedBookId: action.projectData.lastThingWorkedOn.bookId,
          selectedChapter: action.projectData.lastThingWorkedOn.chapter,
          references: action.projectData.lastThingWorkedOn.references,
          resources: action.projectData.lastThingWorkedOn.resources,
          referenceData: new Map(),
          resourceData: new Map(),
          userIsProjectMember: action.userIsProjectMember,
          versesDataLoaded: false,
        };
      }

      return {
        ...state,
        loading: false,
        authenticated: true,
        projectName: action.projectData.projectName,
        projectId: action.projectId,
        userIsProjectMember: action.userIsProjectMember,
        versesDataLoaded: false,
      };

    case types.CHANGE_BOOK:
      return {
        ...state,
        selectedBookId: action.bookId,
        selectedChapter: 1,
        cardData: new Map(),
        resourceData: new Map(),
        referenceData: new Map(),
      };

    case types.CHANGE_CHAPTER:
      return {
        ...state,
        selectedChapter: action.chapter,
        cardData: new Map(),
        resourceData: new Map(),
        referenceData: new Map(),
        changingBookOrChapter: true,
      };

    case types.CHANGE_MANUSCRIPT_ACCORDION:
      return { ...state, activeCard: action.activeCard };

    case types.FETCH_DATA_REQUEST:
      return { ...state, isFetchingData: true };

    case types.FETCH_DATA_SUCCESS:
      if (action.data && Object.keys(action.data.data).length) {
        state.cardData.set(0, action.data);
        state.cardData.set(1, action.data);
      }

      return { ...state, isFetchingData: false, changingBookOrChapter: false };

    case types.FETCH_RESOURCE_DATA_REQUEST:
      return { ...state, isFetching: true };

    case types.FETCH_RESOURCE_DATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        resourceData: Object.assign(state.resourceData, { [action.resourceName]: action.data }),
      };

    case types.ADD_RESOURCE:
      return { ...state, resources: state.resources.concat(action.resource) };

    case types.FETCH_RESOURCE_LIST_REQUEST:
      return { ...state, isFetching: true };

    case types.FETCH_RESOURCE_LIST_SUCCESS:
      return { ...state, availableResources: action.data, isFetching: false };

    case types.FETCH_REFERENCE_LIST_REQUEST:
      return { ...state, isFetching: true };

    case types.FETCH_REFERENCE_LIST_SUCCESS:
      return { ...state, availableReferences: action.data, isFetching: false };

    case types.FETCH_REFERENCE_DATA_REQUEST:
      return {
        ...state,
        isFetching: true,
        referenceData: { ...state.referenceData, [action.referenceId]: 'fetching' },
      };

    case types.ADD_REFERENCE:
      return { ...state, references: state.references.concat(action.reference) };

    case types.FETCH_REFERENCE_DATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        referenceData: { ...state.referenceData, [action.referenceId]: action.data },
      };

    case types.CLOSE_RESOURCE:
      return {
        ...state,
        resources: state.resources.filter((resource): boolean => {
          return resource !== action.resource;
        }),
      };

    case types.CLOSE_REFERENCE:
      return {
        ...state,
        references: state.references.filter((reference): boolean => {
          return reference !== action.reference;
        }),
      };

    case types.FETCH_VERSE_TRANSLATIONS_REQUEST:
      return { ...state, loadingVerses: true };

    case types.FETCH_VERSE_TRANSLATIONS_FAILURE:
      return { ...state, loadingVerses: false, error: action.error };

    case types.FETCH_VERSE_TRANSLATIONS_SUCCESS:
      return {
        ...state,
        loadingVerses: false,
        verseTranslations: action.data,
        versesDataLoaded: true,
      };

    case types.VERIFY_ALIGNMENT:
      return {
        ...state,
        verseTranslations: regenerateVersesWithVerified(
          action.verseCode,
          state.verseTranslations,
          action.links,
        ),
      };

    case types.UNMOUNT:
      return {
        ...state,
        loading: true,
        isFetchingData: true,
        selectedBookId: undefined,
        selectedChapter: undefined,
        cardData: new Map(),
      };

    default:
      return state;
  }
};
