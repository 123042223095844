/* eslint-disable import/prefer-default-export */
import { createTransform } from 'redux-persist';

export const transformer = createTransform(
  // inboundState
  (inboundState: any, key: number | string): any => {
    if (key === 'project') {
      return {
        ...inboundState,
        loading: true,
        error: '',
        editingError: '',
        deletingError: '',
        errorProjectUser: '',
        isFetching: false,
        isFetchingProject: false,
        editingProject: false,
        message: '',
        messageProjectUser: '',
        projectList: [],
        deletingProject: false,
        projectNameEditing: '',
        beginningDateEditing: '',
        targetCompletionDateEditing: '',
        descriptionEditing: '',
        authenticated: false,
        authorizationFail: false,
        keyword: '',
        isCreatingData: false,
        rtfError: undefined,
        statistic: undefined,
        dataExportError: '',
        selectedBookGroup: undefined,
        selectedBooks: undefined,
        selectedBooksString: '',
        selectedBook4Chapter: '',
        chapterFrom: 0,
        chapterTo: 0,
        selectedBook4Verse: '',
        selectedChapterInVersesExport: 0,
        verseRangeMin: 0,
        verseRangeMax: 0,
        fileUrl: undefined,
        loadingTranslatedBookNames: false,
        currentStep: 1,
      };
    }

    if (key === 'signin') {
      return { ...inboundState, error: '' };
    }

    if (key === 'verseEditor') {
      return {
        ...inboundState,
        verseTextMap: Object.fromEntries(inboundState.verseTextMap),
        verseCompleteMap: Object.fromEntries(inboundState.verseCompleteMap),
        verseModifiedMap: Object.fromEntries(inboundState.verseModifiedMap),
        autoSuggestionMap: Object.fromEntries(inboundState.autoSuggestionMap),
        updating: false,
        displayRevisionHistory: false,
      };
    }

    if (key === 'revisionHistory') {
      return {
        ...inboundState,
        isLoading: true,
      };
    }

    if (key === 'translation') {
      return {
        ...inboundState,
        changingBookOrChapter: false,
        loading: true,
        cardData: new Map(),
      };
    }

    if (key === 'dictionary') {
      return {
        ...inboundState,
        isSearching: false,
      };
    }

    return inboundState;
  },

  // outboundState
  (outboundState: any, key: number | string): any => {
    if (key === 'verseEditor') {
      return {
        ...outboundState,
        verseTextMap: new Map(Object.entries(outboundState.verseTextMap)),
        verseCompleteMap: new Map(Object.entries(outboundState.verseCompleteMap)),
        verseModifiedMap: new Map(Object.entries(outboundState.verseModifiedMap)),
        autoSuggestionMap: new Map(Object.entries(outboundState.autoSuggestionMap)),
        displayRevisionHistory: false,
      };
    }

    if (key === 'revisionHistory') {
      return {
        ...outboundState,
        isLoading: true,
      };
    }

    if (key === 'translation') {
      return {
        ...outboundState,
        changingBookOrChapter: false,
        loading: true,
        cardData: new Map(),
      };
    }

    if (key === 'dictionary') {
      return {
        ...outboundState,
        isSearching: false,
      };
    }

    return outboundState;
  },
);
