import * as types from '../types';

const dictionary = (
  state = types.initDictionaryState,
  action: types.DictionaryActionTypes,
): types.DictionaryState => {
  switch (action.type) {
    case types.SEARCH_DICT_REQUEST:
      return { ...state, isSearching: true };

    case types.SEARCH_DICT_SUCCESS:
      return { ...state, searchResult: action.data, isSearching: false, isSearched: true };

    case types.SEARCH_DICT_FAILURE:
      return { ...state, error: action.error, isSearching: false, isSearched: true };

    case types.SEARCH_BOX_CLEAR:
      return {
        ...state,
        searchTerm: '',
        searchResult: types.emptyDictResult,
        tooManySearchWords: false,
      };
    case types.SEARCH_BOX_CHANGES:
      // eslint-disable-next-line no-case-declarations
      const searchWords = action.searchTerm.split(/\s/);

      return {
        ...state,
        searchTerm: searchWords.slice(0, types.MAXIMUM_SEARCH_WORDS).join(' '),
        isSearched: false,
        tooManySearchWords: searchWords.length > types.MAXIMUM_SEARCH_WORDS,
        searchResult: action.searchTerm === '' ? types.emptyDictResult : state.searchResult,
      };

    case types.TOGGLE_SUB_MENU:
      return { ...state, showSubMenu: !action.showSubMenu };

    default:
      return state;
  }
};

export default dictionary;
