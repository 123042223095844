import React, { ReactElement } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Collapse, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { AppState } from '../../reducers';
import Manuscript from './manuscript';
import Translation from './translation';
import ResourceContainer from './resourceContainer';
import ReferenceContainer from './referenceContainer';
import Alignment from '../alignment';
import { GridProps, DefaultGridProps, Panel } from '../../types';
import { toggleManuscriptAction, updateUIConfigAction } from '../../actions';
import noop from '../../lib/noop';

const icons: Record<Panel, string> = {
  Left: 'scroll',
  Right: 'book-open',
  Bottom: 'book-reader',
};

const collapseButton = (
  isOpen: boolean,
  panel: Panel,
  toggleManuscript: Function,
  updateUIConfig: Function,
): ReactElement | null => {
  if (!isOpen) {
    const icon = icons[panel];
    const chevron = panel === 'Right' ? 'down' : 'up';
    const placement = panel.toLowerCase();
    const barPlacement = panel === 'Bottom' ? 'left' : 'top';
    const iconRotation = panel === 'Bottom' ? '' : '270';
    return (
      <OverlayTrigger
        key={placement}
        trigger={['hover', 'focus']}
        placement="top"
        // prettier-ignore
        overlay={(
          <Tooltip id={`tooltip-${placement}`}>
            <FormattedMessage id={`${placement}Panel.name`} />
          </Tooltip>
        )}
      >
        <div
          role="button"
          className={`${panel.toLowerCase()}-panel-toolbar panel bar-${barPlacement}`}
          onKeyDown={noop}
          tabIndex={0}
          onClick={(): void => {
            toggleManuscript(panel);
            updateUIConfig(`is${panel}PanelOpen`, !isOpen);
          }}
        >
          <div className={`expand-${panel.toLowerCase()}-panel`}>
            <i className={`fas fa-chevron-${chevron}`} />
            <i className={`fas fa-${icon} fa-rotate-${iconRotation}`} />
            <i className={`fas fa-chevron-${chevron}`} />
          </div>
        </div>
      </OverlayTrigger>
    );
  }
  return null;
};
export const GridComp: React.SFC<GridProps> = (props): ReactElement => {
  const {
    isRTL,
    isNTrtl,
    isOTrtl,
    isNTltr,
    isOTltr,
    isNTrtlAlignSource,
    isOTrtlAlignSource,
    isNTltrAlignSource,
    isOTltrAlignSource,
    isNTrtlAlignTarget,
    isOTrtlAlignTarget,
    isNTltrAlignTarget,
    isOTltrAlignTarget,
    isLeftPanelOpen,
    isRightPanelOpen,
    isBottomPanelOpen,
    toggleManuscript,
    updateUIConfig,
  } = props;

  const bottomPanelClass = isBottomPanelOpen ? 'expanded' : 'collapsed';
  return (
    <div className="grid-cols">
      <div className="grid row-1">
        <Alignment
          isRTL={isRTL}
          isNTrtlAlignSource={isNTrtlAlignSource}
          isOTrtlAlignSource={isOTrtlAlignSource}
          isNTltrAlignSource={isNTltrAlignSource}
          isOTltrAlignSource={isOTltrAlignSource}
          isNTrtlAlignTarget={isNTrtlAlignTarget}
          isOTrtlAlignTarget={isOTrtlAlignTarget}
          isNTltrAlignTarget={isNTltrAlignTarget}
          isOTltrAlignTarget={isOTltrAlignTarget}
        />
        {collapseButton(isLeftPanelOpen, 'Left', toggleManuscript, updateUIConfig)}
        <Collapse dimension="height" key="collapse-left-panel" in={isLeftPanelOpen}>
          <div className="left-panel">
            <Manuscript />
          </div>
        </Collapse>

        <div className="middle-panel">
          <Translation isNTrtl={isNTrtl} isOTrtl={isOTrtl} isNTltr={isNTltr} isOTltr={isOTltr} />
        </div>

        {collapseButton(isRightPanelOpen, 'Right', toggleManuscript, updateUIConfig)}
        <Collapse mountOnEnter dimension="height" key="collapse-right-panel" in={isRightPanelOpen}>
          <div className="right-panel">
            <ReferenceContainer />
          </div>
        </Collapse>
      </div>
      <div className={`grid row-2 ${bottomPanelClass}`}>
        {collapseButton(isBottomPanelOpen, 'Bottom', toggleManuscript, updateUIConfig)}
        <Collapse
          mountOnEnter
          dimension="height"
          key="collapse-bottom-panel"
          in={isBottomPanelOpen}
        >
          <div className="bottom-panel">
            <ResourceContainer />
          </div>
        </Collapse>
      </div>
    </div>
  );
};

GridComp.defaultProps = DefaultGridProps;

GridComp.propTypes = {
  isRTL: PropTypes.bool.isRequired,
  isNTrtl: PropTypes.bool.isRequired,
  isOTrtl: PropTypes.bool.isRequired,
  isNTltr: PropTypes.bool.isRequired,
  isOTltr: PropTypes.bool.isRequired,
  isNTrtlAlignSource: PropTypes.bool.isRequired,
  isOTrtlAlignSource: PropTypes.bool.isRequired,
  isNTltrAlignSource: PropTypes.bool.isRequired,
  isOTltrAlignSource: PropTypes.bool.isRequired,
  isNTrtlAlignTarget: PropTypes.bool.isRequired,
  isOTrtlAlignTarget: PropTypes.bool.isRequired,
  isNTltrAlignTarget: PropTypes.bool.isRequired,
  isOTltrAlignTarget: PropTypes.bool.isRequired,
  isLeftPanelOpen: PropTypes.bool.isRequired,
  isRightPanelOpen: PropTypes.bool.isRequired,
  isBottomPanelOpen: PropTypes.bool.isRequired,
  toggleManuscript: PropTypes.func.isRequired,
  updateUIConfig: PropTypes.func.isRequired,
};

export const mapDispatchToProps = (dispatch: Dispatch): any => ({
  toggleManuscript: (panel: Panel): void => {
    dispatch(toggleManuscriptAction(panel));
  },
  updateUIConfig: (fieldName: string, fieldValue: boolean): void => {
    dispatch(updateUIConfigAction(fieldName, fieldValue));
  },
});

export const mapStateToProps = (state: AppState): any => {
  return {
    isRTL: state.project.isRTL,
    isNTrtl: state.profile.isNTrtl,
    isOTrtl: state.profile.isOTrtl,
    isNTltr: state.profile.isNTltr,
    isOTltr: state.profile.isOTltr,
    isNTrtlAlignSource: state.profile.isNTrtlAlign,
    isOTrtlAlignSource: state.profile.isOTrtlAlign,
    isNTltrAlignSource: state.profile.isNTltrAlign,
    isOTltrAlignSource: state.profile.isOTltrAlign,
    isNTrtlAlignTarget: state.profile.isNTrtlAlign,
    isOTrtlAlignTarget: state.profile.isOTrtlAlign,
    isNTltrAlignTarget: state.profile.isNTltrAlign,
    isOTltrAlignTarget: state.profile.isOTltrAlign,
    isLeftPanelOpen: state.profile.isLeftPanelOpen,
    isRightPanelOpen: state.profile.isRightPanelOpen,
    isBottomPanelOpen: state.profile.isBottomPanelOpen,
  };
};

const Grid = connect(mapStateToProps, mapDispatchToProps)(GridComp);

export default Grid;
