import axios from 'axios';
import * as firebaseSdk from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/remote-config';

import featureToggleDefaults from '../feature/featureToggleDefaults';

const isBrowser = typeof window !== 'undefined';
const isDev = process.env.NODE_ENV === 'development';
const isNotTest = isDev || process.env.NODE_ENV === 'production';
const useRemoteConfig = isBrowser && isNotTest;

// Defines the maximum age in milliseconds of an entry in the
// config cache before it is considered stale.
// Defaults to 43200000 (Twelve hours).
//
// Commonly used intervals for testing:
// 90000 (1.5 min)
// 3600000 (1 hr)
// 3600000 * 12 (12 hr, firebase default)
const minimumFetchIntervalMillis = 3600000 * 12;

// Defines the maximum amount of milliseconds to wait for a response
// when fetching configuration from the Remote Config server.
// Defaults to 60000 (One minute).
const fetchTimeoutMillis = 120000; // 2 minutes: 120000

export default class FirebaseSdkProvider {
  private static initialized = false;

  private static appCount = 0;

  private static remoteConfig: any = null;

  public static async initializeSdk(): Promise<any> {
    if (!firebaseSdk.apps.length && !this.initialized) {
      const response = await axios.get('/__/firebase/init.json');

      if (response && 'data' in response) {
        // In case Firebase throws error if multiple apps initialized at the same time.
        // Error: `Firebase: Firebase App named '[DEFAULT]' already exists (app/duplicate-app).`
        if (this.appCount < 1) {
          this.appCount += 1;
          return firebaseSdk.initializeApp(response.data); // default
        }

        this.appCount += 1;
        const appName = Math.random().toString(36).substring(8);
        return firebaseSdk.initializeApp(response.data, appName); // random name
      }
    }
    return null;
  }

  public static async initializeRemoteConfig(): Promise<void> {
    // Added error handling to deal with rejection of remote config.
    try {
      this.remoteConfig = firebaseSdk.remoteConfig(); // Gets the RemoteConfig instance.
      this.remoteConfig.settings = { minimumFetchIntervalMillis, fetchTimeoutMillis };
      this.remoteConfig.defaultConfig = featureToggleDefaults;

      await this.remoteConfig.fetchAndActivate();
    } catch (err) {
      // Print error in dev env.
      if (isDev) {
        console.log(err);
      }
    }
  }

  public static async getSdk(): Promise<any> {
    if (!this.initialized) {
      await this.initializeSdk();
      if (useRemoteConfig) {
        await this.initializeRemoteConfig();
      }
    }

    return firebaseSdk;
  }

  public static deinitializeSdk(): void {
    firebaseSdk.app().delete();
    this.initialized = false;
    this.appCount = 0;
  }

  public static getRemoteConfigValue(key: string): { source: string; _value: string } {
    try {
      return this.remoteConfig.getValue(key);
    } catch (err) {
      if (isDev) {
        console.log(err);
      }
    }

    return { source: '', _value: JSON.stringify(featureToggleDefaults) };
  }
}
