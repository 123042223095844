/* eslint-disable import/prefer-default-export */
import React, { ReactElement } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Modal, Dropdown, OverlayTrigger, Tooltip, FormControl, Button } from 'react-bootstrap';
import {
  BibleBooks,
  BibleBookNames,
  BibleChapters,
  BibleBookCategory,
} from '../../shared/verseIdParser';

import { NavigatorProps, NavigatorState, VIEW_OF_BOOK } from '../../types';
import * as action from '../../actions';
import { AppState } from '../../reducers';
import CustomDropdownToggle from './customDropdownToggle';
import CustomDropdownMenu from './customDropdownMenu';
import { confirmUnsavedVerse } from '../../shared/frontend';
import { I18nDropdown } from './i18nDropdown';
import { INIT_STAT } from '../../reducers/navigator';
import getCurrentProjectId from '../../lib/getCurrentProjectId';
import Spinner from '../spinner';
import * as types from '../../types';

class NavigatorApp extends React.Component<NavigatorProps, NavigatorState> {
  public static defaultProps: NavigatorProps = INIT_STAT;

  public constructor(props: any) {
    super(props);
    this.handleBookSearch = this.handleBookSearch.bind(this);
    this.handleCloseError = this.handleCloseError.bind(this);
  }

  public componentDidMount(): void {
    const projectId = getCurrentProjectId();
    this.fetchTranslatedBookNames(projectId);
  }

  /**
   * Get changed book name by bookId
   * @param bookId string
   * @returns string of changed book name | undefined
   */
  protected getChangedBookName(bookId: string): string | undefined {
    const { editingBookNameMap } = this.props;

    const projectId = getCurrentProjectId();
    const bookNameData = editingBookNameMap[projectId];

    if (bookNameData && bookId in bookNameData) {
      return bookNameData[bookId];
    }

    return undefined;
  }

  /**
   * Get translated book name by bookId
   * @param bookId string
   * @returns string of changed book name | undefined
   */
  protected getTranslatedBookName(bookId: string): string | undefined {
    const { translatedBookNameMap } = this.props;

    if (translatedBookNameMap && bookId in translatedBookNameMap) {
      return translatedBookNameMap[bookId];
    }

    return undefined;
  }

  /**
   * Get traslated book names
   * @param bookId string
   * @returns string of changed book name | undefined
   */
  protected fetchTranslatedBookNames(projectId: string): void {
    const { fetchBookNamesFunc } = this.props;

    fetchBookNamesFunc(projectId);
  }

  public bookCategory(bookId: string): string {
    return BibleBookCategory[bookId as keyof typeof BibleBookCategory];
  }

  protected bookRows(isOldTestament: boolean, items: any[]): any {
    const { keyword, selectedBookId, parentSelectedBookId } = this.props;

    const selectedBookIdLocal = parentSelectedBookId || selectedBookId;

    return items.map((bookId: string): any => {
      const bookCate = this.bookCategory(bookId);
      const bookName = BibleBookNames[bookId as keyof typeof BibleBookNames];
      const words = bookName.toString().toLowerCase().split(' ');

      words.push(bookName.toLowerCase());

      const showBook =
        keyword === undefined ||
        words.filter((str: string): boolean => {
          return str.startsWith(keyword.toLowerCase());
        }).length;

      if (showBook) {
        if (selectedBookIdLocal !== bookId) {
          return (
            <li className="list-group-item book-name-list" key={`bookRow-${bookId}`}>
              {this.showBookCell(bookId, bookCate, bookName)}
            </li>
          );
        }

        return (
          <li className="list-group-item book-name-list book-selected" key={`bookRow-${bookId}`}>
            {this.showBookCell(bookId, bookCate, bookName)}
          </li>
        );
      }

      return undefined;
    });
  }

  /**
   * Temporarily indicates which book has content in the book list for the Eggon project.
   * @param bookId
   */
  private highlightEggonBooks(bookId: string): boolean {
    const books: { [bookId: string]: boolean } = {
      Haggai: true,
      Malachi: true,
      Zechariah: true,
      Obadiah: true,
      Ruth: true,
      Jonah: true,
      Joel: true,
    };

    return books[bookId];
  }

  private highlightIbaasBooks(bookId: string): boolean {
    const books: { [bookId: string]: boolean } = {
      Genesis: true,
    };

    return books[bookId];
  }

  protected showBookCell(bookId: string, bookCate: string, bookName: string): ReactElement {
    const { changeBookFunc, verseModifiedMap, editingBookId, projectName } = this.props;
    const translatedBookName = this.getTranslatedBookName(bookId);
    const cssClass: string =
      editingBookId === bookId ? 'book-name-cell edit-mode' : 'book-name-cell';

    return (
      <div className={`${cssClass}`}>
        <div className="show-book-name">
          <FormattedMessage id="translation.prompt">
            {(promptMessage: string): ReactElement => {
              return (
                <button
                  key={`bookRowBtn-${bookId}`}
                  type="button"
                  className="btn btn-link"
                  onClick={(): void => {
                    if (confirmUnsavedVerse(promptMessage, verseModifiedMap)) {
                      changeBookFunc(bookId);
                    }
                  }}
                >
                  <i key={`bookRowIcon-${bookId}`} className={`fas fa-bible color-${bookCate}`} />

                  {((): string | ReactElement => {
                    if (translatedBookName) {
                      return translatedBookName;
                    }

                    return <FormattedMessage id={bookName} />;
                  })()}

                  {((): ReactElement | undefined => {
                    // Temporarily indicates which book has content in the book list for the Eggon project.
                    if (
                      (projectName?.toLowerCase().includes('eggon') &&
                        this.highlightEggonBooks(bookId)) ||
                      (projectName?.toLowerCase().includes('ibaas') &&
                        this.highlightIbaasBooks(bookId))
                    ) {
                      return (
                        <i
                          key={`content-icon-${bookId}`}
                          className="fas fa-folder-open color-ytb pl-2"
                        />
                      );
                    }

                    return undefined;
                  })()}
                </button>
              );
            }}
          </FormattedMessage>
          {this.btnTranslateBookName(bookId)}
        </div>

        {this.editBookName(bookId, bookName)}
      </div>
    );
  }

  protected btnTranslateBookName(bookId: string): ReactElement {
    return (
      <FormattedMessage id="translate">
        {(msg: string): ReactElement => {
          return (
            <button
              type="button"
              className="btn btn-link btn-book btn-edit-book-name"
              title={msg}
              onClick={(): void => {
                this.doEditBookName(bookId);
              }}
            >
              <i className="fas fa-edit" />
            </button>
          );
        }}
      </FormattedMessage>
    );
  }

  protected doEditBookName(bookId: string): void {
    const { editBookNameFunc } = this.props;
    const projectId = getCurrentProjectId();
    editBookNameFunc(projectId, bookId);
  }

  protected doSaveBookName(bookId: string): void {
    const { saveBookNameFunc } = this.props;
    const projectId = getCurrentProjectId();
    const newBookName = this.getChangedBookName(bookId);

    saveBookNameFunc(projectId, bookId, newBookName);
  }

  protected doCancelBookName(bookId: string): void {
    const { cancelBookNameFunc } = this.props;
    const projectId = getCurrentProjectId();

    cancelBookNameFunc(projectId, bookId);
  }

  protected handleBookNameChange(e: any, bookId: string): void {
    const { changeBookNameFunc } = this.props;
    const projectId = getCurrentProjectId();

    changeBookNameFunc(projectId, bookId, e.target.value);
  }

  private showLoadingSpinner(): ReactElement {
    return <Spinner cssName="text-ytb" />;
  }

  protected editBookName(bookId: string, bookName: string): ReactElement {
    const { userAction } = this.props;
    const changedBookName = this.getChangedBookName(bookId);
    const translatedBookName = this.getTranslatedBookName(bookId);

    return (
      <div className="edit-book-name ">
        <div className="row">
          <div className="col-9 pr-1">
            <FormattedMessage id={bookName}>
              {(promptMessage: string): ReactElement => {
                let value = promptMessage;

                if (changedBookName) {
                  value = changedBookName;
                } else if (userAction && userAction === types.CHANGE_BOOK_NAME) {
                  value = '';
                } else if (translatedBookName) {
                  value = translatedBookName;
                }

                return (
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Book name"
                    value={value}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                      this.handleBookNameChange(e, bookId);
                    }}
                  />
                );
              }}
            </FormattedMessage>
          </div>
          <div className="col-3 px-0 align-middle">
            <FormattedMessage id="save">
              {(msg: string): ReactElement => {
                return (
                  <button
                    type="button"
                    className="btn btn-link btn-book"
                    title={msg}
                    disabled={!changedBookName}
                    onClick={(): void => {
                      this.doSaveBookName(bookId);
                    }}
                  >
                    <i className="fas fa-check" />
                  </button>
                );
              }}
            </FormattedMessage>

            <FormattedMessage id="cancel">
              {(msg: string): ReactElement => {
                return (
                  <button
                    type="button"
                    className="btn btn-link btn-book"
                    title={msg}
                    onClick={(): void => {
                      this.doCancelBookName(bookId);
                    }}
                  >
                    <i className="fas fa-ban" />
                  </button>
                );
              }}
            </FormattedMessage>
          </div>
        </div>
      </div>
    );
  }

  protected bookColumn(isOldTestament: boolean): any {
    const bookRange = { fm: 1, to: 39 };
    if (!isOldTestament) {
      bookRange.fm = 40;
      bookRange.to = 66;
    }

    const books: string[] = [];
    let count = 0;
    Object.entries(BibleBooks).forEach(([key, val]): void => {
      if (typeof val === 'number') {
        count += 1;
        if (count >= bookRange.fm && count <= bookRange.to) {
          books.push(key);
        }
      }
    });

    const mid: number = Math.ceil(books.length / 2);

    return (
      <div className="row book-list-body" key="book-list-body">
        <div className="col-sm px-0 " key="book-list-body-left">
          <ul className="list-group" key="book-list-group-left">
            {this.bookRows(isOldTestament, books.slice(0, mid))}
          </ul>
        </div>
        <div className="col-sm px-0 " key="book-list-body-right">
          <ul className="list-group " key="book-list-group-right">
            {this.bookRows(isOldTestament, books.slice(mid, books.length))}
          </ul>
        </div>
      </div>
    );
  }

  protected handleBookSearch(e: any): void {
    const { searchBookFunc } = this.props;
    const keyword = e.target.value;
    searchBookFunc(keyword);
  }

  private handleCloseError(): void {
    const { clearErrorFunc } = this.props;
    clearErrorFunc();
  }

  protected showError(): ReactElement {
    const { error } = this.props;
    const display = !!error;

    return (
      <Modal show={display} onHide={this.handleCloseError}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="error" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{error && <FormattedMessage id={error} />}</Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleCloseError}>OK</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  protected bookList(): any {
    const { keyword, searchBookFunc, sending } = this.props;

    return (
      <React.Fragment key="react-fragment-booklist">
        {sending && this.showLoadingSpinner()}
        <h1>
          <FormattedMessage id="navigator.selectBook" />
        </h1>
        <div className="form-group position-relative">
          <FormattedMessage id="navigator.search">
            {(placeholder: any): ReactElement => (
              <FormControl
                autoFocus
                className="w-100"
                placeholder={placeholder}
                onChange={this.handleBookSearch}
                value={keyword}
              />
            )}
          </FormattedMessage>
          <button
            type="button"
            className={`btn btn-link btn-clear  ${keyword === '' ? 'd-none' : ''}`}
            onClick={(): void => {
              searchBookFunc('');
            }}
          >
            <i className="fas fa-times" />
          </button>
        </div>
        <div className="row">
          <div className="col-sm d-flex flex-column align-items-stretch">
            <h2 className="text-center">
              <FormattedMessage id="navigator.oldTestament" />
            </h2>
            {this.bookColumn(true)}
          </div>
          <div className="col-sm d-flex flex-column align-items-stretch">
            <h2 className="text-center">
              <FormattedMessage id="navigator.newTestament" />
            </h2>
            {this.bookColumn(false)}
          </div>
        </div>
      </React.Fragment>
    );
  }

  protected chapterColumns(bookId: string | undefined): ReactElement {
    const totalNo = BibleChapters[bookId as keyof typeof BibleChapters];

    const chapters = [];
    if (totalNo) {
      for (let index = 1; index <= totalNo; index += 1) {
        chapters.push(
          <div className="col-2" key={`chapter-list-row-${index}`}>
            {index}
          </div>,
        );
      }
    }

    return (
      <div className="row book-list-body" key="chapter-list-body">
        {chapters}
      </div>
    );
  }

  protected chapterList(): ReactElement {
    const { selectedBookId } = this.props;

    return (
      <React.Fragment key="react-fragment-chapter-list">
        <h1 className="border-bottom">Select a chapter</h1>
        <div className="row" key="chapter-list">
          <div className="col-sm">
            <h2 className="text-center">
              {BibleBookNames[selectedBookId as keyof typeof BibleBookNames]}
            </h2>
            {this.chapterColumns(selectedBookId)}
          </div>
        </div>
      </React.Fragment>
    );
  }

  protected lightBox(): ReactElement {
    const { display, closeNavigatorFunc } = this.props;

    return (
      <Modal
        size="lg"
        backdrop
        show={display}
        onHide={closeNavigatorFunc}
        dialogClassName="book-modal"
      >
        <Modal.Header closeButton />
        <Modal.Body>{this.bookList()}</Modal.Body>
      </Modal>
    );
  }

  protected activateNavigator(): void {
    const { displayNavigatorFunc } = this.props;

    displayNavigatorFunc();
  }

  public dropdownItems(chapters: number): Array<ReactElement> {
    const {
      changeChapterFunc,
      parentSelectedChapter,
      selectedChapter,
      verseModifiedMap,
    } = this.props;
    const items = [];

    const selectedChapterLocal = parentSelectedChapter || selectedChapter;

    for (let index = 1; index <= chapters; index += 1) {
      if (selectedChapterLocal === index) {
        items.push(
          <Dropdown.Item eventKey={index.toString()} key={`dropdown-item-chapter-${index}`}>
            {index}
          </Dropdown.Item>,
        );
      } else {
        items.push(
          <I18nDropdown
            index={index}
            verseModifiedMap={verseModifiedMap}
            changeChapterFunc={changeChapterFunc}
            eventKey={index.toString()}
            key={`chapter-dropdown-item-i18n-${index}`}
          />,
        );
      }
    }

    return items;
  }

  public render(): ReactElement {
    const {
      defaultView,
      parentSelectedBookId,
      selectedBookId,
      defaultBookId,
      parentSelectedChapter,
      selectedChapter,
      defaultChapter,
      changingBookOrChapter,
    } = this.props;

    let bookId = selectedBookId || defaultBookId;
    if (parentSelectedBookId) {
      bookId = parentSelectedBookId;
    }
    const bookName = BibleBookNames[bookId as keyof typeof BibleBookNames];
    let translatedBookName;
    if (bookId) {
      translatedBookName = this.getTranslatedBookName(bookId);
    }

    let chapter = selectedChapter || defaultChapter;
    if (parentSelectedChapter) {
      chapter = parentSelectedChapter;
    }
    const chapterTotal = BibleChapters[bookId as keyof typeof BibleChapters];

    if (defaultView === VIEW_OF_BOOK) {
      return (
        <>
          <OverlayTrigger
            placement="top"
            // prettier-ignore
            overlay={(
              <Tooltip id="tooltipChangeBook">
                <FormattedMessage id="navigator.changeBook" />
              </Tooltip>
            )}
          >
            <button
              type="button"
              className="btn btn-link division"
              onClick={this.activateNavigator.bind(this)}
              disabled={changingBookOrChapter}
            >
              <span className="caption">
                <FormattedMessage id="navigator.book" />
              </span>
              <span className="link">
                {translatedBookName || <FormattedMessage id={bookName} />}
              </span>
            </button>
          </OverlayTrigger>

          {this.lightBox()}
          {this.showError()}
        </>
      );
    }

    return (
      <OverlayTrigger
        key="overlay-trigger-chapter"
        placement="top"
        // prettier-ignore
        overlay={(
          <Tooltip id="tooltipChangeChapter">
            <FormattedMessage id="navigator.changeChapter" />
          </Tooltip>
        )}
      >
        {((): ReactElement => {
          if (changingBookOrChapter) {
            return (
              <button type="button" className="btn btn-link" disabled>
                <span className="caption">
                  <FormattedMessage id="navigator.chapter" />
                </span>
                <span className="link">{chapter}</span>
              </button>
            );
          }

          return (
            <Dropdown key="overlay-trigger-dropdown-chapter">
              <Dropdown.Toggle id="dropdown-custom-components" as={CustomDropdownToggle}>
                <span className="caption">
                  <FormattedMessage id="navigator.chapter" />
                </span>
                <span className="link">{chapter}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu as={CustomDropdownMenu}>
                {this.dropdownItems(chapterTotal)}
              </Dropdown.Menu>
            </Dropdown>
          );
        })()}
      </OverlayTrigger>
    );
  } // end func
}

const mapStateToProps = (state: AppState): any => {
  const props = {
    ...state.navigator,
    verseModifiedMap: state.verseEditor.verseModifiedMap,
  };

  return props;
};

const mapDispatchToProps = (dispatch: Dispatch): any => ({
  searchBookFunc: (keyword: string): void => {
    dispatch(action.searchBook(keyword));
  },

  displayNavigatorFunc: (): void => {
    dispatch(action.displayNavigator());
  },

  closeNavigatorFunc: (): void => {
    dispatch(action.closeNavigator());
    dispatch(action.searchBook(''));
  },

  changeBookFunc: (bookId: string): void => {
    dispatch(action.changeBook(bookId));
    dispatch(action.searchBook(''));
  },

  changeChapterFunc: (chapter: number): void => {
    dispatch(action.changeChapter(chapter));
  },

  editBookNameFunc: (projectId: string, editingBookId: string): void => {
    dispatch(action.editBookName(projectId, editingBookId));
  },

  cancelBookNameFunc: (projectId: string, editingBookId: string): void => {
    dispatch(action.cancelBookName(projectId, editingBookId));
  },

  saveBookNameFunc: (
    projectId: string,
    editingBookId: string,
    translatedBookName: string,
  ): void => {
    dispatch(action.saveBookNameAction(projectId, editingBookId, translatedBookName));
  },

  changeBookNameFunc: (
    projectId: string,
    editingBookId: string,
    translatedBookName: string,
  ): void => {
    dispatch(action.changeBookName(projectId, editingBookId, translatedBookName));
  },

  fetchBookNamesFunc: (projectId: string): void => {
    dispatch(action.fetchBookNamesAction(projectId));
  },

  clearErrorFunc: (): void => {
    dispatch(action.clearError());
  },
});

const Navigator = connect(mapStateToProps, mapDispatchToProps)(NavigatorApp);

export default Navigator;
