import * as dictDataType from '../components/dictionary/dataType';

export const MAXIMUM_SEARCH_WORDS = 8;
export const SEARCH_BOX_CLEAR = 'SEARCH_BOX_CLEAR';
export const SEARCH_BOX_CHANGES = 'SEARCH_BOX_CHANGES';
export const SEARCH_DICT_REQUEST = 'SEARCH_DICT_REQUEST';
export const SEARCH_DICT_SUCCESS = 'SEARCH_DICT_SUCCESS';
export const SEARCH_DICT_FAILURE = 'SEARCH_DICT_FAILURE';
export const TOGGLE_SUB_MENU = 'TOGGLE_SUB_MENU';

interface ToggleSubMenu {
  type: typeof TOGGLE_SUB_MENU;
  showSubMenu: boolean;
}

interface ClearSearchBox {
  type: typeof SEARCH_BOX_CLEAR;
}

interface ChangeSearchBox {
  type: typeof SEARCH_BOX_CHANGES;
  searchTerm: string;
}

interface SearchDictRequest {
  type: typeof SEARCH_DICT_REQUEST;
  projectId: string;
  searchTerm: string;
}

interface SearchDictSuccess {
  type: typeof SEARCH_DICT_SUCCESS;
  data: dictDataType.DictDataResponse;
}

interface SearchDictFailure {
  type: typeof SEARCH_DICT_FAILURE;
  error: any;
}

export type DictionaryActionTypes =
  | ClearSearchBox
  | ChangeSearchBox
  | SearchDictRequest
  | SearchDictSuccess
  | SearchDictFailure
  | ToggleSubMenu;

export interface DictionaryState {
  searchTerm: string;
  searchResult: dictDataType.DictDataResponse;
  isSearching: boolean;
  showSubMenu: boolean;
  isSearched: boolean;
  error: any;
  tooManySearchWords: boolean;
}

export interface DictionaryProps {
  searchTerm: string;
  searchResult: dictDataType.DictDataResponse;
  isSearching: boolean;
  showSubMenu: boolean;
  isSearched: boolean;
  error: any;
  tooManySearchWords: boolean;
}

export const emptyDictResult = {};

export const initDictionaryState: DictionaryState = {
  searchTerm: '',
  searchResult: emptyDictResult,
  isSearching: false,
  showSubMenu: true,
  isSearched: false,
  error: null,
  tooManySearchWords: false,
};
