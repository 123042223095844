import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';

// i18n
import { IntlProvider } from 'react-intl';
import languageObject from './translations/messages';

import FeatureToggle from './lib/feature/featureToggle';

// Routed Components
import Homepage from './containers/homepage';
import Header from './components/header';
import Contact from './components/contact';
import Project from './containers/project';
import Help from './containers/help';
import Signup from './components/signup';
import PasswordReset from './components/passwordReset';
import { Profile } from './components/profile';
import Translation from './components/translation';

import { AppState } from './reducers';

interface AppProps {
  locale: string;
}

const otherRoutes = (): ReactElement | null => {
  if (FeatureToggle.isNotActivated('isMarketingOnly')) {
    return (
      <>
        <Route path="/help" component={Help} />
        <Route path="/contact" component={Contact} />
        <Route path="/project" component={Project} exact />
        <Route path="/project/:projectId" component={Translation} exact />
        <Route path="/account" component={Homepage} />
        <Route path="/signupWithEmail" component={Signup} />
        <Route path="/passwordReset" component={PasswordReset} />
        <Route path="/profile" component={Profile} />
      </>
    );
  }

  return null;
};

export const AppComp: React.SFC<AppProps> = (props: AppProps): any => {
  const { locale } = props;
  return (
    <Router>
      <IntlProvider locale={locale} messages={languageObject[locale]}>
        <Header />
        <Route path="/" exact component={Homepage} />
        {otherRoutes()}
      </IntlProvider>
    </Router>
  );
};

AppComp.propTypes = {
  locale: PropTypes.string.isRequired,
};

export const mapStateToProps = (state: AppState): any => {
  return {
    locale: state.locale.locale,
  };
};

export default connect(mapStateToProps)(AppComp);
