import { BibleApiRequest, BibleApiResponse } from '../shared/structs';
import FirebaseFunctionClient from './firebaseFunctionClient';

export default class BibleApiClient extends FirebaseFunctionClient {
  public async getBiblesList(): Promise<BibleApiResponse> {
    const request: BibleApiRequest = {
      list: true,
      requestedId: '',
      translation: '',
      additionalParams: {},
    };

    const response = await this.invokeFunction('bibleApi', request);
    return response.data;
  }

  public async getBibleByChapter(id: string, reference: string): Promise<BibleApiResponse> {
    const request: BibleApiRequest = {
      list: false,
      requestedId: reference,
      translation: id,
      additionalParams: {},
    };
    const response = await this.invokeFunction('bibleApi', request);
    return response.data;
  }
}
