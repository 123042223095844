import React, { ReactElement, useEffect } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';

import Spinner from './spinner';
import { ContactProps } from '../types/contact';
import { sendEmailAction, updateContactFormAction, resetContactFormAction } from '../actions';
import { AppState } from '../reducers';

const progressIndicator = (props: ContactProps): ReactElement => {
  const { isSending } = props;
  if (isSending) {
    return <Spinner />;
  }
  return <></>;
};

const statusMessage = (props: ContactProps): ReactElement => {
  const { isSuccess, isFailure } = props;
  if (isSuccess) {
    return (
      <span className="contact-message">
        <FormattedMessage id="contact.success" />
      </span>
    );
  }

  if (isFailure) {
    return (
      <span className="contact-message">
        <FormattedMessage id="contact.failure" />
      </span>
    );
  }
  return <></>;
};

export const ContactComp: React.FC<ContactProps> = (props: ContactProps): ReactElement => {
  const {
    returnAddress,
    subject,
    message,
    updateContactFormFunc,
    sendEmailFunc,
    resetContactFormFunc,
  } = props;
  const formRef = React.createRef<HTMLFormElement>();

  /* eslint react-hooks/exhaustive-deps: "off" */
  // The above rule doesn't like the empty array here.
  // We want the empty array so that the returned cleanup
  // function only executed when the on unmount.
  useEffect(() => {
    return (): void => {
      resetContactFormFunc();
    };
  }, []);

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md={6}>
          <h1>
            <FormattedMessage id="contact.header" />
          </h1>

          <Form ref={formRef}>
            <Form.Group controlId="returnAddress">
              <Form.Label className="contact-label required-field">
                <FormattedMessage id="contact.returnAddress" />
              </Form.Label>
              <FormattedMessage id="contact.returnAddressPlaceholder">
                {(placeholder: string): ReactElement => (
                  <Form.Control
                    className="contact email"
                    type="email"
                    required
                    placeholder={placeholder}
                    value={returnAddress}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                      updateContactFormFunc(e.target.id, e.target.value);
                    }}
                  />
                )}
              </FormattedMessage>
            </Form.Group>
            <Form.Group controlId="subject">
              <Form.Label className="contact-label required-field">
                <FormattedMessage id="contact.subject" />
              </Form.Label>
              <FormattedMessage id="contact.subjectPlaceholder">
                {(placeholder: string): ReactElement => (
                  <Form.Control
                    type="text"
                    className="contact subject"
                    required
                    value={subject}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                      updateContactFormFunc(e.target.id, e.target.value);
                    }}
                    placeholder={placeholder}
                  />
                )}
              </FormattedMessage>
            </Form.Group>
            <Form.Group controlId="message">
              <Form.Label className="contact-label required-field">
                <FormattedMessage id="contact.message" />
              </Form.Label>
              <FormattedMessage id="contact.messagePlaceholder">
                {(placeholder: string): ReactElement => (
                  <Form.Control
                    as="textarea"
                    className="contact message"
                    required
                    placeholder={placeholder}
                    value={message}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>): void => {
                      updateContactFormFunc(e.target.id, e.target.value);
                    }}
                    rows={5}
                  />
                )}
              </FormattedMessage>
            </Form.Group>

            {progressIndicator(props)}

            <Row>
              <Col md={12} className="d-flex justify-content-end align-items-center">
                {statusMessage(props)}
                <Button
                  className="float-right"
                  variant="primary"
                  type="submit"
                  id="send-email"
                  onClick={(e: React.MouseEvent<HTMLButtonElement>): void => {
                    e.preventDefault();
                    if (formRef?.current?.reportValidity()) {
                      sendEmailFunc(returnAddress, subject, message);
                    }
                  }}
                >
                  <FormattedMessage id="contact.sendMessage" />
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export const mapDispatchToProps = (dispatch: Dispatch): any => ({
  updateContactFormFunc: (field: string, value: string): void => {
    dispatch(updateContactFormAction(field, value));
  },

  resetContactFormFunc: (): void => {
    dispatch(resetContactFormAction());
  },

  sendEmailFunc: (returnAddress: string, subject: string, message: string): void => {
    dispatch(sendEmailAction(returnAddress, subject, message));
  },
});

export const mapStateToProps = (state: AppState): any => {
  return {
    ...state.contact,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactComp);
