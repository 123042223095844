import { combineReducers, Reducer, AnyAction } from 'redux';

import {
  LOGOUT,
  UserState,
  UserNavState,
  SignInState,
  LightboxState,
  ProjectState,
  SignUpState,
  PasswordResetState,
  AuthState,
  LocaleState,
  ProfileState,
  TranslationState,
  NavigatorState,
  VerseEditorState,
  AlignmentState,
  RevisionHistoryState,
  ContactState,
  GlossState,
  DictionaryState,
} from '../types';

import users from './user';
import lightbox from './lightbox';
import signin from './signin';
import project from './project';
import signup from './signup';
import passwordReset from './passwordReset';
import usernav from './usernav';
import auth from './auth';
import locale from './locale';
import profile from './profile';
import translation from './translation';
import navigator from './navigator';
import verseEditor from './verseEditor';
import alignment from './alignment';
import revisionHistory from './revisionHistory';
import contact from './contact';
import gloss from './gloss';
import dictionary from './dictionary';

export interface WholeAppState {
  users: UserState;
  lightbox: LightboxState;
  signin: SignInState;
  project: ProjectState;
  signup: SignUpState;
  passwordReset: PasswordResetState;
  usernav: UserNavState;
  auth: AuthState;
  locale: LocaleState;
  profile: ProfileState;
  translation: TranslationState;
  navigator: NavigatorState;
  verseEditor: VerseEditorState;
  alignment: AlignmentState;
  revisionHistory: RevisionHistoryState;
  contact: ContactState;
  gloss: GlossState;
  dictionary: DictionaryState;
}
const appReducer = combineReducers({
  users,
  lightbox,
  signin,
  project,
  signup,
  passwordReset,
  usernav,
  auth,
  locale,
  profile,
  translation,
  navigator,
  verseEditor,
  alignment,
  revisionHistory,
  contact,
  gloss,
  dictionary,
});

const rootAppReducer: Reducer<any, AnyAction> = (
  state: WholeAppState | undefined,
  action: any,
): any => {
  if (action.type === LOGOUT) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootAppReducer;
export type AppState = ReturnType<typeof rootAppReducer>;
