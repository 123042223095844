import {
  ProfileState,
  ProfileActionTypes,
  UPDATE_LANGUAGE,
  UPDATE_PROFILE,
  TOGGLE_MANUSCRIPT,
} from '../types';

const INIT_STAT: ProfileState = {
  language: 'en',
  isNTrtl: false,
  isOTrtl: false,
  isNTltr: false,
  isOTltr: false,
  isNTltrAlignSource: false,
  isNTrtlAlignSource: false,
  isOTltrAlignSource: false,
  isOTrtlAlignSource: false,
  isNTltrAlignTarget: false,
  isNTrtlAlignTarget: false,
  isOTltrAlignTarget: false,
  isOTrtlAlignTarget: false,
  isLeftPanelOpen: false,
  isRightPanelOpen: false,
  isBottomPanelOpen: false,
  selectedInterlinearNT: 'niv84',
  selectedInterlinearOT: 'niv84',
};

const Profile = (state = INIT_STAT, action: ProfileActionTypes): any => {
  switch (action.type) {
    case UPDATE_LANGUAGE:
      return { ...state, language: action.language };

    case UPDATE_PROFILE:
      return { ...action.profile };

    case TOGGLE_MANUSCRIPT:
      return { ...state, [`is${action.panel}PanelOpen`]: !state[`is${action.panel}PanelOpen`] };

    default:
      return state;
  }
};

export default Profile;
