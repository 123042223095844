import firebase from '../lib/firebase';
import * as types from '../types';
import { DictDataResponse } from '../components/dictionary/dataType';

const FirebaseAuth = firebase.createService('auth');

export const searchBoxClearAction = (): types.DictionaryActionTypes => ({
  type: types.SEARCH_BOX_CLEAR,
});

export const searchRequestAction = (
  projectId: string,
  searchTerm: string,
): types.DictionaryActionTypes => ({
  type: types.SEARCH_DICT_REQUEST,
  projectId,
  searchTerm,
});

export const searchSuccessAction = (data: DictDataResponse): types.DictionaryActionTypes => ({
  type: types.SEARCH_DICT_SUCCESS,
  data,
});

export const searchFailureAction = (error: any): types.DictionaryActionTypes => ({
  type: types.SEARCH_DICT_FAILURE,
  error,
});

export const searchBoxChangesAction = (searchTerm: string): types.DictionaryActionTypes => ({
  type: types.SEARCH_BOX_CHANGES,
  searchTerm,
});

export const toggleSubMenuAction = (showSubMenu: boolean): types.DictionaryActionTypes => ({
  type: types.TOGGLE_SUB_MENU,
  showSubMenu,
});

export const searchBoxSubmitsAction = (projectId: string, searchTerm: string): any => {
  return async (dispatch: any): Promise<void> => {
    try {
      dispatch(searchRequestAction(projectId, searchTerm));
      const currentUser = await (await FirebaseAuth.init()).getCurrentSignedInUser();

      if (currentUser && Object.prototype.hasOwnProperty.call(currentUser, 'uid')) {
        const token = await currentUser.getIdToken();
        const baseUrl = (await FirebaseAuth.init()).getFirebaseFunctionBaseUrl();
        if (!baseUrl) {
          // invalid Firebase URL.
          throw new Error(types.INVALID_FIREBASE_URL);
        }

        const apiEndpoint = `${baseUrl}/projectDict`;
        const headers = new Headers({
          Authorization: `Bearer ${token}`,
        });

        const body = JSON.stringify({ pid: projectId, searchTerm });

        const res = await fetch(apiEndpoint, {
          method: 'post',
          mode: 'cors',
          cache: 'no-cache',
          headers,
          body,
        });

        if (res.ok) {
          const data = await res.json();
          dispatch(searchSuccessAction(data));
        } else {
          const data = await res.json();

          const { error } = data;
          if (error) {
            const { message } = error;
            if (message) {
              throw new Error(message);
            }
            throw new Error(error);
          }

          throw new Error(res.statusText);
        }
      } else {
        throw new Error(types.USER_IS_NOT_SIGNED);
      }
    } catch (error) {
      if (Object.prototype.hasOwnProperty.call(error, 'message')) {
        if (error.message === types.USER_IS_NOT_SIGNED) {
          dispatch(searchFailureAction(error.signInIsRequiredToAccessProject));
        } else {
          dispatch(searchFailureAction(error.message));
        }
      } else if (typeof error === 'string') {
        dispatch(searchFailureAction(error));
      } else {
        dispatch(searchFailureAction(String(error)));
      }
    }
  };
};
