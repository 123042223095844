import noop from '../lib/noop';

export const SEND_EMAIL_REQUEST = 'SEND_EMAIL_REQUEST';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAILURE = 'SEND_EMAIL_FAILURE';

export const UPDATE_CONTACT_FORM = 'UPDATE_CONTACT_FORM';
export const RESET_CONTACT_FORM = 'RESET_CONTACT_FORM';

export interface ContactState {
  returnAddress: string;
  subject: string;
  message: string;
  isSending: boolean;
  isSuccess: boolean;
  isFailure: boolean;
}

export interface ContactProps {
  returnAddress: string;
  subject: string;
  message: string;
  isSending: boolean;
  isSuccess: boolean;
  isFailure: boolean;
  sendEmailFunc: Function;
  updateContactFormFunc: Function;
  resetContactFormFunc: Function;
}

export const DefaultContactProps: ContactProps = {
  returnAddress: '',
  subject: '',
  message: '',
  isSending: false,
  isSuccess: false,
  isFailure: false,
  sendEmailFunc: noop,
  updateContactFormFunc: noop,
  resetContactFormFunc: noop,
};

export const DefaultContactState: ContactState = {
  returnAddress: '',
  subject: '',
  message: '',
  isSending: false,
  isSuccess: false,
  isFailure: false,
};

interface SendEmailRequestAction {
  type: typeof SEND_EMAIL_REQUEST;
}

interface SendEmailSuccessAction {
  type: typeof SEND_EMAIL_SUCCESS;
}

interface SendEmailFailureAction {
  type: typeof SEND_EMAIL_FAILURE;
}

interface UpdateContactFormAction {
  type: typeof UPDATE_CONTACT_FORM;
  data: { field: string; value: string };
}

interface ResetContactFormAction {
  type: typeof RESET_CONTACT_FORM;
}

interface UndefinedAction {
  type: typeof undefined;
}

export type ContactActionTypes =
  | SendEmailRequestAction
  | SendEmailSuccessAction
  | SendEmailFailureAction
  | ResetContactFormAction
  | UpdateContactFormAction
  | UndefinedAction;
