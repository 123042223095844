import React, { ReactElement } from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import * as _ from 'lodash';
import * as types from '../../types';
import Segment from './segment';
import { SyntaxGroupData } from '../../shared/structs';

export class SegmentGroupComp extends React.Component<
  types.SegmentGroupProps,
  types.SegmentGroupState
> {
  private direction = false;

  private nextChildrenForSegmentGroup(
    segmentGroup: SyntaxGroupData,
    childSyntaxGroupData: SyntaxGroupData[],
  ): SyntaxGroupData[] {
    return childSyntaxGroupData
      .filter((datum) => datum.level > segmentGroup.level)
      .filter((datum) => {
        return (
          datum.startIndex >= segmentGroup.startIndex && datum.endIndex <= segmentGroup.endIndex
        );
      });
  }

  private nextChildrenForCurrentSegmentGroup(segmentGroup: SyntaxGroupData): SyntaxGroupData[] {
    const { childSyntaxGroupData } = this.props;
    const children = this.nextChildrenForSegmentGroup(segmentGroup, childSyntaxGroupData);
    return children;
  }

  private currentLevel(): number {
    const { syntaxGroup } = this.props;
    return syntaxGroup.level;
  }

  private nextLevel(): number {
    return this.currentLevel() + 1;
  }

  private isFlat(): boolean {
    const { childSyntaxGroupData } = this.props;
    return childSyntaxGroupData.length === 0;
  }

  private createRange(start: number, end: number): number[] {
    return Array.from(Array(1 + end - start).keys()).map((index: number) => {
      return start + index;
    });
  }

  private renderNestedSegments(): ReactElement[] {
    const {
      manuscriptSuggestions,
      verseManuscriptData,
      allSyntaxGroupData,
      childSyntaxGroupData,
      textId,
      glosses,
      renderDirection,
    } = this.props;
    return childSyntaxGroupData
      .filter((datum) => datum.level === this.nextLevel())
      .map((childSyntaxGroupDatum: SyntaxGroupData) => {
        return (
          <SegmentGroup
            textId={textId}
            key={`segment-group-child-${childSyntaxGroupDatum.startSegmentIndex}-${childSyntaxGroupDatum.endSegmentIndex}`}
            manuscriptSuggestions={manuscriptSuggestions}
            verseManuscriptData={verseManuscriptData}
            allSyntaxGroupData={allSyntaxGroupData}
            syntaxGroup={childSyntaxGroupDatum}
            childSyntaxGroupData={this.nextChildrenForCurrentSegmentGroup(childSyntaxGroupDatum)}
            glosses={glosses}
            renderDirection={renderDirection}
          />
        );
      });
  }

  private renderFlatSegments(): (ReactElement | null)[] {
    const { verseManuscriptData, manuscriptSuggestions, syntaxGroup, textId, glosses } = this.props;

    let segmentIndexes: number[] = [];
    if (syntaxGroup.renderLocations) {
      if (syntaxGroup.renderLocations.length === 0) {
        segmentIndexes = this.createRange(
          syntaxGroup.startSegmentIndex,
          syntaxGroup.endSegmentIndex,
        );
      } else if (syntaxGroup.renderLocations.length === 1) {
        segmentIndexes = this.createRange(
          syntaxGroup.renderLocations[0],
          syntaxGroup.renderLocations[0],
        );
      } else {
        segmentIndexes = this.createRange(
          syntaxGroup.renderLocations[0],
          syntaxGroup.renderLocations[syntaxGroup.renderLocations.length - 1],
        );
      }
    } else {
      segmentIndexes = this.createRange(syntaxGroup.startSegmentIndex, syntaxGroup.endSegmentIndex);
    }

    // console.log('[' + segmentIndexes + ']');
    if (this.direction) {
      segmentIndexes = segmentIndexes.reverse();
    }

    return segmentIndexes.map((currIndex: number): ReactElement | null => {
      // find the right manuscript for this spot
      // get the computed location for this manuscript segment
      if (syntaxGroup.renderLocations?.length === 0) {
        // can't find this segment whioh should be a problem
        return null;
      }

      const segmentRange = this.createRange(currIndex, currIndex);
      if (syntaxGroup.renderLocations) {
        if (_.intersection(syntaxGroup.renderLocations, segmentRange).length === 0) {
          // this segment doesn't render this position
          return null;
        }
      }

      const verseManuscriptDataForSegment = verseManuscriptData[currIndex];
      const linkSuggestions =
        manuscriptSuggestions.linkSuggestions[verseManuscriptDataForSegment.strongsX];
      const memorySuggestions =
        manuscriptSuggestions.memorySuggestions[verseManuscriptDataForSegment.strongsX];

      return (
        <Segment
          textId={textId}
          position={currIndex + 1}
          manuscriptData={verseManuscriptDataForSegment}
          linkSuggestions={linkSuggestions}
          memorySuggestions={memorySuggestions}
          key={verseManuscriptDataForSegment.positionId}
          glosses={glosses}
        />
      );
    });
  }

  private renderSegments(): (ReactElement | null)[] {
    if (this.isFlat()) {
      return this.renderFlatSegments();
    }
    return this.renderNestedSegments();
  }

  private renderTitleBar(syntaxType: SyntaxGroupData): ReactElement {
    if (syntaxType.type !== 'norender') {
      return (
        <span
          key={`segment-group-label-${syntaxType.startIndex}-${syntaxType.endIndex}}`}
          className="syntax-group-label"
        >
          <FormattedMessage id={`syntax.${syntaxType.type}`} />
        </span>
      );
    }
    return <span />;
  }

  public render(): ReactElement {
    const { syntaxGroup, renderDirection } = this.props;
    const isFlatClass = this.isFlat() ? 'flat' : '';

    this.direction = renderDirection;

    let keyCSS = 'segment-group';
    if (this.direction) {
      keyCSS = 'segment-group-rtl';
    }

    return (
      <div className={`segment-group-container ${isFlatClass} key=${keyCSS}`}>
        <OverlayTrigger
          key={`trigger-group-${syntaxGroup.startIndex}-${syntaxGroup.endIndex}`}
          trigger={['hover', 'focus']}
          placement="top"
          popperConfig={{
            modifiers: { preventOverflow: {} },
          }}
          // prettier-ignore
          overlay={(
            <Tooltip id={`tooltip-${syntaxGroup.startIndex}-${syntaxGroup.endIndex}`}>
              <b>
                <FormattedMessage id={`syntax.${syntaxGroup.type}`} />
                :&nbsp;
              </b>
              <FormattedMessage id={`syntax.${syntaxGroup.type}.description`} />
            </Tooltip>
            )}
        >
          {this.renderTitleBar(syntaxGroup)}
        </OverlayTrigger>

        <div className={`${keyCSS}`}>{this.renderSegments()}</div>
      </div>
    );
  }
}

const SegmentGroup = SegmentGroupComp;
export default SegmentGroup;
