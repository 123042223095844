import {
  PasswordResetState,
  PasswordResetActionTypes,
  PASSWORD_RESET_COMPLETES,
  PASSWORD_RESET_DISMISS_MESSAGE,
} from '../types';

const INIT_STATE: PasswordResetState = {
  passwordResetErrorMessage: '',
  passwordResetSuccessIndication: false,
};

const PasswordReset = (state = INIT_STATE, action: PasswordResetActionTypes): any => {
  switch (action.type) {
    case PASSWORD_RESET_COMPLETES:
      return {
        ...state,
        passwordResetErrorMessage: action.passwordResetErrorMessage,
        passwordResetSuccessIndication: action.passwordResetErrorMessage === '',
      };
    case PASSWORD_RESET_DISMISS_MESSAGE:
      return {
        ...state,
        passwordResetErrorMessage: '',
        passwordResetSuccessIndication: false,
      };

    default:
      return state;
  }
};

export default PasswordReset;
