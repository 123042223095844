export enum Categories {
  Adjective = 'Adjective',
  Demonstrative = 'Demonstrative',
  Noun = 'Noun',
  GentilicNoun = 'Gentilic Noun',
  ProperNoun = 'Proper Noun',
  CardinalNumber = 'Cardinal Number',
  OrdinalNumber = 'Ordinal Number',
  Number = 'Number',
  PronounInterrogative = 'Interrogative Pronoun',
  PronounIndependent = 'Independent Pronoun',
  Paragraph = 'Paragraph',
  Suffix = 'Suffix',
  DefiniteArticle = 'Definite Article',
  Conjunction = 'Conjunction',
  Adverb = 'Adverb',
  Interrogative = 'Interrogative',
  Interjection = 'Interjection',
  Negative = 'Negative',
  ObjectMarker = 'Object Marker',
  Preposition = 'Preposition',
  Relative = 'Relative',
  Verb = 'Verb',
  Particle = 'Particle',
  IndefinitePronoun = 'Indefinite Pronoun',
  InterrogativePronoun = 'Interrogative Pronoun',
  RelativePronoun = 'Relative Pronoun',
  PersonalPronoun = 'Personal Pronoun',
  DemonstrativePronoun = 'Demonstrative Pronoun',
  Article = 'Article',
}

export enum Analyses {
  FirstPerson = 'first person',
  SecondPerson = 'second person',
  ThirdPerson = 'third person',
  Masculine = 'masculine',
  Feminine = 'feminine',
  Common = 'common',
  Both = 'both',
  Neuter = 'neuter',
  Singular = 'singular',
  Plural = 'plural',
  Dual = 'dual',
  Nominative = 'nominative',
  Genitive = 'genetive',
  Dative = 'dative',
  Accusative = 'accusative',
  Vocative = 'vocative',
  Comparative = 'comparative',
  Superlative = 'superlative',
  Active = 'active',
  Middle = 'middle',
  Passive = 'passive',
  Aorist = 'aorist',
  Present = 'present',
  Future = 'future',
  Perfect = 'perfect',
  Pluperfect = 'pluperfect',
  Imperfect = 'imperfect',
  Indicative = 'indicative',
  Subjunctive = 'subjunctive',
  Imperative = 'imperative',
  Optative = 'optative',
  Infinitive = 'infinitive',
  InfinitiveConstruct = 'infinitive construct',
  InfinitiveAbsolute = 'infinitive absolute',
  Participle = 'participle',
  PassiveParticiple = 'passive participle',
  Absolute = 'absolute',
  Construct = 'construct',
  Determined = 'determined',
  Apocopated = 'apocopated',
  EnergicNun = 'energic nun',
  ParagogicHe = 'paragogic he',
  ParagogicNun = 'paragogic nun',
  Directional = 'directional',
  JussiveFormMeaning = 'jussive in both form and meaning',
  JussiveForm = 'jussive in form only',
  JussiveMeaning = 'jussive in meaning only',
  CohortativeFormMeaning = 'cohortative in both form and meaning',
  CohortativeForm = 'cohortative in form only',
  CohortativeMeaning = 'cohortative in meaning only',
  ConsecutivePerfect = 'consecutive perfect',
}

export class MorphologyParser {
  public parse(morphologyCode: string): string {
    const languageCode = morphologyCode.substring(0, 1);
    const analysisCode = morphologyCode.substring(1);

    return languageCode === 'G'
      ? this.parseGreekMorphology(analysisCode)
      : this.parseHebrewMorphology(analysisCode, languageCode);
  }

  public parseGreekMorphology(analysisCode: string): string {
    let result = '';
    let analysis = '';

    const category = this.parseGreekCategory(analysisCode.substring(0, 2));

    analysis += ` ${this.parseGreekCase(analysisCode.charAt(4))}`;
    analysis += ` ${this.parsePerson(analysisCode.charAt(3))}`;
    analysis += ` ${this.parseNumber(analysisCode.charAt(5))}`;
    analysis += ` ${this.parseGender(analysisCode.charAt(6))}`;
    analysis += ` ${this.parseDegree(analysisCode.charAt(7))}`;

    if (analysisCode.length > 8) {
      analysis += ` ${this.parseGreekTense(analysisCode.charAt(8))}`;
      analysis += ` ${this.parseGreekVoice(analysisCode.charAt(9))}`;
      analysis += ` ${this.parseGreekMood(analysisCode.charAt(10))}`;
    }

    analysis = analysis.replace(/\s\s+/g, ' ').trim();
    if (analysis) {
      result = `${category} - ${analysis}`;
    } else {
      result = category;
    }

    return result ?? '';
  }

  public parseHebrewMorphology(analysisCode: string, languageCode: string): string {
    const parsed = analysisCode.replace('!', '');

    if (parsed === 'np' || parsed === 'pii' || parsed === 'x') {
      // no further analysis needed
      return this.parseHebrewCategory(parsed);
    }

    let category = parsed.substring(0, 2);
    let code = parsed.substring(2);
    let result = '';
    let analysis = '';
    switch (category) {
      case 'nc':
      case 'uo':
      case 'uc':
        category = this.parseHebrewCategory(category);
        analysis = this.parseGender(code);
        analysis += ` ${this.parseNumber(code)}`;
        analysis += ` ${this.parseHebrewState(code)}`;
        break;
      case 'pi':
        category = this.parseHebrewCategory(category);
        analysis = this.parsePerson(code);
        analysis += ` ${this.parseGender(code)}`;
        analysis += ` ${this.parseNumber(code)}`;
        break;
      case 'ps':
        category = this.parseHebrewCategory(category);

        code = parsed.substring(2); // category code can be psn or psv
        analysis = this.parsePerson(code);
        analysis += ` ${this.parseGender(code)}`;
        analysis += ` ${this.parseNumber(code)}`;
        if (code.includes('Xe')) {
          analysis += ` ${Analyses.EnergicNun}`;
        }
        break;
      case 'ng':
        category = this.parseHebrewCategory(category);
        analysis = this.parseNumber(code);
        if (code.includes('d')) {
          analysis += ` ${Analyses.Determined}`;
        }
        break;
      default:
        // Check first character category codes
        category = parsed.substring(0, 1);
        code = parsed.substring(1);
        switch (category) {
          case 'a':
          case 'd':
            category = this.parseHebrewCategory(category);
            analysis = this.parseGender(code);
            analysis += ` ${this.parseNumber(code)}`;
            analysis += ` ${this.parseHebrewState(code)}`;
            break;
          case 'v':
            category = this.parseHebrewCategory(category);

            if (languageCode === 'A') {
              analysis = this.parseAramaicVerbStem(code.substring(0, 1));
            } else {
              analysis = this.parseHebrewVerbStem(code.substring(0, 1));
            }

            analysis += ` ${this.parseHebrewVerbConjugation(code.substring(1, 2))}`;
            analysis += ` ${this.parsePerson(code.substring(2, 5))}`;
            analysis += ` ${this.parseGender(code.substring(2, 5))}`;
            analysis += ` ${this.parseNumber(code.substring(2, 5))}`;

            if (code.substring(5).includes('X')) {
              analysis += ` ${this.parseHebrewSuffix(code.substring(5))}`;
            }

            analysis += ` ${this.parseHebrewVerbOptionalTag(code.substring(5))}`;

            break;
          case 'P':
            // category is further defined by the code
            category = this.parseHebrewCategory(parsed.substring(0, 2));
            break;
          case 'X':
            category = this.parseHebrewCategory(category);
            analysis = ` ${this.parseHebrewSuffix(code)}`;
            break;
          default:
            break;
        }
        break;
    }

    analysis = analysis.replace(/\s\s+/g, ' ').trim();
    if (analysis) {
      result = `${category} - ${analysis}`;
    } else {
      result = category;
    }

    return result ?? '';
  }

  public parseGender(code: string): string {
    let result = '';

    if (code.includes('m')) {
      result = Analyses.Masculine;
    } else if (code.includes('f')) {
      result = Analyses.Feminine;
    } else if (code.includes('b')) {
      result = Analyses.Both;
    } else if (code.includes('c')) {
      result = Analyses.Common;
    } else if (code.includes('n')) {
      result = Analyses.Neuter;
    }

    return result;
  }

  public parseNumber(code: string): string {
    let result = '';

    if (code.includes('s')) {
      result = Analyses.Singular;
    } else if (code.includes('p')) {
      result = Analyses.Plural;
    } else if (code.includes('d')) {
      result = Analyses.Dual;
    }

    return result;
  }

  public parsePerson(code: string): string {
    let result = '';

    if (code.includes('1')) {
      result = Analyses.FirstPerson;
    } else if (code.includes('2')) {
      result = Analyses.SecondPerson;
    } else if (code.includes('3')) {
      result = Analyses.ThirdPerson;
    }

    return result;
  }

  public parseDegree(code: string): string {
    let result = '';

    switch (code) {
      case 'c':
        result = Analyses.Comparative;
        break;
      case 's':
        result = Analyses.Superlative;
        break;
      default:
        break;
    }

    return result;
  }

  public parseGreekCategory(code: string): string {
    let result = '';

    switch (code) {
      case 'n-':
        result = Categories.Noun;
        break;
      case 'nr':
        result = Categories.ProperNoun;
        break;
      case 'v-':
        result = Categories.Verb;
        break;
      case 'a-':
        result = Categories.Adjective;
        break;
      case 'd-':
        result = Categories.Adverb;
        break;
      case 'c-':
        result = Categories.Conjunction;
        break;
      case 'p-':
        result = Categories.Preposition;
        break;
      case 'x-':
        result = Categories.Particle;
        break;
      case 'i-':
        result = Categories.Interjection;
        break;
      case 'ra':
        result = Categories.Article;
        break;
      case 'rp':
        result = Categories.PersonalPronoun;
        break;
      case 'rd':
        result = Categories.DemonstrativePronoun;
        break;
      case 'rr':
        result = Categories.RelativePronoun;
        break;
      case 'ri':
        result = Categories.InterrogativePronoun;
        break;
      case 'rn':
        result = Categories.IndefinitePronoun;
        break;
      case 'an':
        result = Categories.Number;
        break;
      default:
        break;
    }

    return result;
  }

  public parseGreekCase(code: string): string {
    let result = '';

    switch (code) {
      case 'n':
        result = Analyses.Nominative;
        break;
      case 'g':
        result = Analyses.Genitive;
        break;
      case 'd':
        result = Analyses.Dative;
        break;
      case 'a':
        result = Analyses.Accusative;
        break;
      case 'v':
        result = Analyses.Vocative;
        break;
      default:
        break;
    }

    return result;
  }

  public parseGreekTense(code: string): string {
    let result = '';

    switch (code) {
      case 'a':
        result = Analyses.Aorist;
        break;
      case 'p':
        result = Analyses.Present;
        break;
      case 'f':
        result = Analyses.Future;
        break;
      case 'x':
        result = Analyses.Perfect;
        break;
      case 'y':
        result = Analyses.Pluperfect;
        break;
      case 'i':
        result = Analyses.Imperfect;
        break;
      default:
        break;
    }

    return result;
  }

  public parseGreekVoice(code: string): string {
    let result = '';

    switch (code) {
      case 'a':
        result = Analyses.Active;
        break;
      case 'm':
        result = Analyses.Middle;
        break;
      case 'p':
        result = Analyses.Passive;
        break;
      default:
        break;
    }

    return result;
  }

  public parseGreekMood(code: string): string {
    let result = '';

    switch (code) {
      case 'i':
        result = Analyses.Indicative;
        break;
      case 's':
        result = Analyses.Subjunctive;
        break;
      case 'd':
        result = Analyses.Imperative;
        break;
      case 'o':
        result = Analyses.Optative;
        break;
      case 'n':
        result = Analyses.Infinitive;
        break;
      case 'p':
        result = Analyses.Participle;
        break;
      default:
        break;
    }

    return result;
  }

  public parseHebrewCategory(code: string): string {
    let result = '';
    switch (code) {
      case 'a':
        result = Categories.Adjective;
        break;
      case 'd':
        result = Categories.Demonstrative;
        break;
      case 'nc':
        result = Categories.Noun;
        break;
      case 'ng':
        result = Categories.GentilicNoun;
        break;
      case 'np':
        result = Categories.ProperNoun;
        break;
      case 'pi':
        result = Categories.PronounIndependent;
        break;
      case 'pii':
        result = Categories.PronounInterrogative;
        break;
      case 'ps':
        result = Categories.Suffix;
        break;
      case 'uc':
        result = Categories.CardinalNumber;
        break;
      case 'uo':
        result = Categories.OrdinalNumber;
        break;
      case 'v':
        result = Categories.Verb;
        break;
      case 'x':
        result = Categories.Paragraph;
        break;
      case 'Pa':
        result = Categories.DefiniteArticle;
        break;
      case 'Pc':
        result = Categories.Conjunction;
        break;
      case 'Pd':
        result = Categories.Adverb;
        break;
      case 'Pg':
        result = Categories.Interrogative;
        break;
      case 'Pi':
        result = Categories.Interjection;
        break;
      case 'Pn':
        result = Categories.Negative;
        break;
      case 'Po':
        result = Categories.ObjectMarker;
        break;
      case 'Pp':
        result = Categories.Preposition;
        break;
      case 'Pr':
        result = Categories.Relative;
        break;
      case 'X':
        result = Categories.Suffix;
        break;
      default:
        break;
    }

    return result;
  }

  public parseHebrewState(code: string): string {
    let result = '';

    if (code.includes('a')) {
      result = Analyses.Absolute;
    } else if (code.includes('c')) {
      result = Analyses.Construct;
    }

    return result;
  }

  public parseHebrewSuffix(code: string): string {
    let result = '';

    if (code.includes('a')) {
      result = Analyses.Apocopated;
    } else if (code.includes('e')) {
      result = Analyses.EnergicNun;
    } else if (code.includes('h')) {
      result = Analyses.ParagogicHe;
    } else if (code.includes('n')) {
      result = Analyses.ParagogicNun;
    } else if (code.includes('d')) {
      result = Analyses.Directional;
    }

    return result;
  }

  public parseAramaicVerbStem(code: string): string {
    let result = '';

    switch (code) {
      case 'A':
        result = 'afel';
        break;
      case 'B':
        result = 'hafel';
        break;
      case 'D':
        result = 'hofal';
        break;
      case 'F':
        result = 'hitpeel';
        break;
      case 'G':
        result = 'hitpolel';
        break;
      case 'H':
        result = 'hishtafel';
        break;
      case 'I':
        result = 'ishtafel';
        break;
      case 'K':
        result = 'itpaal';
        break;
      case 'L':
        result = 'itpeel';
        break;
      case 'M':
        result = 'pael';
        break;
      case 'N':
        result = 'peal';
        break;
      case 'O':
        result = 'peil';
        break;
      case 'P':
        result = 'polel';
        break;
      case 'Q':
        result = 'safal';
        break;
      case 'R':
        result = 'shafel';
        break;
      case 'S':
        result = 'hitpaal';
        break;
      case 'V':
        result = 'itpoel';
        break;
      case 'a':
        result = 'palel';
        break;
      default:
        break;
    }

    return result;
  }

  public parseHebrewVerbStem(code: string): string {
    let result = '';

    switch (code) {
      case 'a':
        result = 'palel';
        break;
      case 'b':
        result = 'peelal';
        break;
      case 'c':
        result = 'pilel';
        break;
      case 'd':
        result = 'pilpel';
        break;
      case 'e':
        result = 'polel';
        break;
      case 'f':
        result = 'polal';
        break;
      case 'g':
        result = 'polpal';
        break;
      case 'h':
        result = 'hifil';
        break;
      case 'i':
        result = 'pulal';
        break;
      case 'k':
        result = 'poel';
        break;
      case 'l':
        result = 'poal';
        break;
      case 'm':
        result = 'tifil';
        break;
      case 'n':
        result = 'nifal';
        break;
      case 'p':
        result = 'piel';
        break;
      case 'q':
        result = 'qal';
        break;
      case 's':
        result = 'hishtafel';
        break;
      case 't':
        result = 'hitpael';
        break;
      case 'u':
        result = 'hotpaal';
        break;
      case 'v':
        result = 'hitpolel';
        break;
      case 'w':
        result = 'hitpalpel';
        break;
      case 'x':
        result = 'nitpael';
        break;
      case 'y':
        result = 'hitpoel';
        break;
      case 'H':
        result = 'hofal';
        break;
      case 'P':
        result = 'pual';
        break;
      case 'Q':
        result = `qal ${Analyses.Passive}`;
        break;
      default:
        break;
    }

    return result;
  }

  public parseHebrewVerbConjugation(code: string): string {
    let result = '';

    switch (code) {
      case 'p':
        result = Analyses.Perfect;
        break;
      case 'i':
        result = Analyses.Imperfect;
        break;
      case 'w':
        result = 'wayyitiqol';
        break;
      case 'v':
        result = Analyses.Imperative;
        break;
      case 'c':
        result = Analyses.InfinitiveConstruct;
        break;
      case 'a':
        result = Analyses.InfinitiveAbsolute;
        break;
      case 'P':
        result = Analyses.Participle;
        break;
      case 's':
        result = Analyses.PassiveParticiple;
        break;
      default:
        break;
    }

    return result;
  }

  public parseHebrewVerbOptionalTag(code: string): string {
    let result = '';

    if (code.includes('{1}Jt')) {
      result = Analyses.JussiveFormMeaning;
    } else if (code.includes('{1}Jf')) {
      result = Analyses.JussiveForm;
    } else if (code.includes('{1}Jm')) {
      result = Analyses.JussiveMeaning;
    } else if (code.includes('{1}Ct')) {
      result = Analyses.CohortativeFormMeaning;
    } else if (code.includes('{1}Cf')) {
      result = Analyses.CohortativeForm;
    } else if (code.includes('{1}Cm')) {
      result = Analyses.CohortativeMeaning;
    } else if (code.includes('{2}')) {
      result = Analyses.ConsecutivePerfect;
    }

    return result;
  }
}

export default MorphologyParser;
