import React, { ReactElement } from 'react';
import { Dispatch } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { AppState } from '../../reducers';
import CustomDropdownMenu from '../navigator/customDropdownMenu';
import CustomDropdownToggle from '../navigator/customDropdownToggle';
import { SelectInterlinearProps } from '../../types';
import {
  fetchGlossVersionsAction,
  fetchGlossAction,
  updateUserProfileGenericAction,
} from '../../actions';
import { VerseIdParser } from '../../shared/verseIdParser';
import * as bookFunc from '../../lib/book';

const handleGlossChange = (props: SelectInterlinearProps, version: string): void => {
  const { fetchGlosses, bookId, chapter } = props;
  if (fetchGlosses) {
    fetchGlosses(bookId, chapter, version);
  }
};

const getTestament = (props: SelectInterlinearProps): string => {
  const { bookId, chapter } = props;
  const textId = bookFunc.generateBookChapterCode(bookId || '', chapter || 1);
  const verseIdParser = new VerseIdParser();
  return verseIdParser.isNT(textId) ? 'nt' : 'ot';
};

const generateInterlinearOptions = (
  props: SelectInterlinearProps,
  dispatch: Dispatch,
): ReactElement[] => {
  const { glossVersions, glossOT, glossNT, uid } = props;
  const testament = getTestament(props);
  const currentGloss = testament === 'nt' ? glossNT : glossOT;
  return glossVersions
    ?.filter((glossVersion) => glossVersion.testaments.includes(testament))
    .map(
      (glossVersion): ReactElement => {
        const isSelected = glossVersion.shortName === currentGloss;
        const isSelectedClass = isSelected ? 'selected' : '';
        return (
          <React.Fragment key={`interlinear-${glossVersion.shortName}`}>
            <Dropdown.Item
              key={`interlinear-${glossVersion.shortName}`}
              className={isSelectedClass}
              eventKey={`${glossVersion.shortName} ${glossVersion.fullName}`}
              onClick={(): void => {
                handleGlossChange(props, glossVersion.shortName);
                dispatch(
                  updateUserProfileGenericAction(
                    uid,
                    `selectedInterlinear${testament.toUpperCase()}`,
                    glossVersion.shortName,
                  ),
                );
              }}
            >
              <span className="item-title">
                <b>{glossVersion.fullName}</b>
              </span>
              <br />
              <p className="item-description license">{glossVersion.license}</p>
            </Dropdown.Item>
            <Dropdown.Divider key={`interlinear-divider-${glossVersion.shortName}`} />
          </React.Fragment>
        );
      },
    );
};
export const SelectInterlinearComp = (props: SelectInterlinearProps): ReactElement => {
  const dispatch = useDispatch();
  const { glossVersions } = props;

  if (!glossVersions || glossVersions.length === 0) {
    dispatch(fetchGlossVersionsAction());
  }

  return (
    <Dropdown key="select-interlinear-menu" className="dropdown-select-interlinear">
      <Dropdown.Toggle id="interlinear-dropdown-custom-components" as={CustomDropdownToggle}>
        <OverlayTrigger
          key="select-interlinear"
          trigger={['hover', 'focus']}
          placement="left"
          // prettier-ignore
          overlay={(
            <Tooltip id="tooltip-select-interlinear">
              <FormattedMessage id="selectInterlinear" />
            </Tooltip>
        )}
        >
          <i className="fab fa-readme" />
        </OverlayTrigger>
      </Dropdown.Toggle>
      <Dropdown.Menu as={CustomDropdownMenu}>
        {generateInterlinearOptions(props, dispatch)}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export const mapStateToProps = (state: AppState): any => {
  return {
    glossVersions: state.gloss.glossVersions,
    glossOT: state.profile.selectedInterlinearOT,
    glossNT: state.profile.selectedInterlinearNT,
    uid: state.auth.user ? state.auth.user.uid : '',
  };
};

export const mapDispatchToProps = (dispatch: Dispatch): any => ({
  fetchGlosses: (bookId: string, chapter: number, version: string): void => {
    dispatch(fetchGlossAction(bookId, chapter, version));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectInterlinearComp);
