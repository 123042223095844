import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { AppState } from '../reducers';
import { emailAddrUpdater } from '../actions/signin';
import { passwordResetAction, messageResetAction } from '../actions/passwordReset';
import { PasswordResetProps } from '../types';

const emailAddrUpdateWrapper = (e?: any, props?: any): void => {
  const { emailAddrUpdate } = props;
  emailAddrUpdate(e.target.value);
};

const passwordResetWrapper = (e: any, props: any): void => {
  e.preventDefault();
  const { passwordReset, emailAddr } = props;
  passwordReset(emailAddr);
};

const messageResetWrapper = (props: any): void => {
  const { messageReset } = props;
  messageReset();
};

export const PasswordResetComp: React.SFC<PasswordResetProps> = (props): any => {
  //
  const { emailAddr, passwordResetErrorMessage, passwordResetSuccessIndication } = props;

  const makeNotifier = (content: any, alertType: string): any => {
    return (
      <div className={`alert mb-4 ${alertType}`} role="alert">
        {content}
        <button type="button" className="close" onClick={(): void => messageResetWrapper(props)}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  };

  let notifier = <> </>;
  if (passwordResetErrorMessage) {
    notifier = makeNotifier(
      <>
        <FormattedMessage id="passwordReset.error" />
        {'  '}
        {passwordResetErrorMessage}
      </>,
      'alert-danger',
    );
  } else if (passwordResetSuccessIndication) {
    notifier = makeNotifier(<FormattedMessage id="passwordReset.success" />, 'alert-success');
  }

  const sendEmailButtonDisabled =
    passwordResetErrorMessage !== '' || passwordResetSuccessIndication;

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col">
            <h3>
              <FormattedMessage id="passwordReset.title" />
            </h3>
            <p>
              <FormattedMessage id="passwordReset.prompt" />
            </p>
          </div>
          <div className="col">
            <form
              id="password-reset-form"
              className="form-password-reset"
              onSubmit={(e): void => {
                passwordResetWrapper(e, props);
              }}
            >
              <div className="form-group mb-2">
                <FormattedMessage id="passwordReset.emailPlaceholder">
                  {(placeholder: string): ReactElement => (
                    <input
                      type="email"
                      className="form-control input-sm"
                      id="email-password-reset"
                      placeholder={placeholder}
                      value={emailAddr}
                      onChange={(e): void => {
                        emailAddrUpdateWrapper(e, props);
                      }}
                    />
                  )}
                </FormattedMessage>
              </div>

              {notifier}

              <button
                id="password-reset-request-email"
                className="btn btn-lg btn-ytb btn-block"
                type="submit"
                disabled={sendEmailButtonDisabled}
              >
                <FormattedMessage id="passwordReset.requestButtonLabel" />
              </button>
            </form>
          </div>
        </div>
        <hr />
      </div>
    </>
  );
};

/* eslint react/prop-types: "off" */
PasswordResetComp.propTypes = {
  emailAddr: PropTypes.string.isRequired,
  passwordResetErrorMessage: PropTypes.string.isRequired,
  passwordResetSuccessIndication: PropTypes.bool.isRequired,
};

PasswordResetComp.defaultProps = {};

export const mapDispatchToProps = (dispatch: Dispatch): any => ({
  emailAddrUpdate: (e?: any): void => {
    dispatch(emailAddrUpdater(e));
  },

  passwordReset: (emailAddr: string): void => {
    dispatch(passwordResetAction(emailAddr));
  },

  messageReset: (): void => {
    dispatch(messageResetAction());
  },
});

export const mapStateToProps = (state: AppState): any => {
  return {
    emailAddr: state.signup.emailAddr,
    passwordResetErrorMessage: state.passwordReset.passwordResetErrorMessage,
    passwordResetSuccessIndication: state.passwordReset.passwordResetSuccessIndication,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetComp);
