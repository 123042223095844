import { Gloss, GlossConfig } from '../shared/structs';

export const FETCH_GLOSS_REQUEST = 'FETCH_GLOSS_REQUEST';
export const FETCH_GLOSS_SUCCESS = 'FETCH_GLOSS_SUCCESS';
export const FETCH_GLOSS_FAILURE = 'FETCH_GLOSS_FAILURE';

export const FETCH_GLOSS_VERSIONS_REQUEST = 'FETCH_GLOSS_VERSIONS_REQUEST';
export const FETCH_GLOSS_VERSIONS_SUCCESS = 'FETCH_GLOSS_VERSIONS_SUCCESS';
export const FETCH_GLOSS_VERSIONS_FAILURE = 'FETCH_GLOSS_VERSIONS_FAILURE';

interface FetchGlossRequest {
  type: typeof FETCH_GLOSS_REQUEST;
  textId: string;
}

interface FetchGlossSuccess {
  type: typeof FETCH_GLOSS_SUCCESS;
  data: Gloss[];
  textId: string;
  version: string;
}

interface FetchGlossFailure {
  type: typeof FETCH_GLOSS_FAILURE;
  error: any;
}

interface FetchGlossVersionsRequest {
  type: typeof FETCH_GLOSS_VERSIONS_REQUEST;
}

interface FetchGlossVersionsSuccess {
  type: typeof FETCH_GLOSS_VERSIONS_SUCCESS;
  data: GlossConfig[];
}

interface FetchGlossVersionsFailure {
  type: typeof FETCH_GLOSS_VERSIONS_FAILURE;
  error: any;
}

interface UndefinedAction {
  type: typeof undefined;
}

export type GlossTypes =
  | FetchGlossRequest
  | FetchGlossSuccess
  | FetchGlossFailure
  | FetchGlossVersionsRequest
  | FetchGlossVersionsSuccess
  | FetchGlossVersionsFailure
  | UndefinedAction;

export interface GlossState {
  loading: boolean;
  glossVersions: GlossConfig[];
  glosses: Gloss[];
}
