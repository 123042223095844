import React, { ReactElement } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Popover, OverlayTrigger, Accordion, Card } from 'react-bootstrap';

import { FormattedMessage } from 'react-intl';
import CustomAccordionToggle from './customAccordionToggle';
import Spinner from '../spinner';
import * as BookFuncs from '../../lib/book';

import { AppState } from '../../reducers';
import {
  TranslationProps,
  TranslationState,
  DefaultTranslationProps,
  AccordionCards,
  MANUSCRIPT_CARD,
  MORPHOLOGY_CARD,
  DICTIONARY_CARD,
} from '../../types';

import {
  changeManuscriptAccordionAction,
  fetchDataAction,
  fetchGlossAction,
  toggleManuscriptAction,
  updateUIConfigAction,
} from '../../actions';

import ManuscriptView from './manuscriptView';
import MorphologyView from './morphologyView';
import DictionaryView from '../dictionary';

export class ManuscriptComp extends React.Component<TranslationProps, TranslationState> {
  public static defaultProps: TranslationProps = DefaultTranslationProps;

  public constructor(props: any) {
    super(props);
    const {
      activeCard,
      selectedBookId,
      selectedChapter,
      fetchData,
      fetchGlosses,
      cardData,
    } = props;
    this.changeCard = this.changeCard.bind(this);

    if (!cardData.has(activeCard)) {
      fetchData(activeCard, selectedBookId, selectedChapter);
      fetchGlosses(selectedBookId, selectedChapter, this.getSelectedGloss());
    }
  }

  public componentDidMount(): void {
    const { selectedBookId, selectedChapter, fetchData, fetchGlosses, activeCard } = this.props;

    fetchData(activeCard, selectedBookId, selectedChapter);
    fetchGlosses(selectedBookId, selectedChapter, this.getSelectedGloss());
  }

  public componentDidUpdate(prevProps: any): void {
    const { selectedBookId, selectedChapter, fetchData, fetchGlosses, activeCard } = this.props;

    // book or chapter changed
    if (
      BookFuncs.bookOrChapterChanged(
        prevProps.selectedBookId,
        prevProps.selectedChapter,
        selectedBookId,
        selectedChapter,
      )
    ) {
      fetchData(activeCard, selectedBookId, selectedChapter);
      fetchGlosses(selectedBookId, selectedChapter, this.getSelectedGloss());
    }
  }

  public getSelectedGloss(): string {
    const { selectedBookId, selectedGlossOT, selectedGlossNT } = this.props;
    const testament = BookFuncs.isOldTestament(selectedBookId || '') ? 'ot' : 'nt';
    const selectedGlossForCurrentTestment = testament === 'ot' ? selectedGlossOT : selectedGlossNT;
    return selectedGlossForCurrentTestment;
  }

  public changeCard(eventKey: string): void {
    const {
      changeAccordionCard,
      selectedBookId,
      selectedChapter,
      fetchData,
      fetchGlosses,
      cardData,
    } = this.props;

    const activeCard = parseInt(eventKey, 10);

    if (activeCard !== DICTIONARY_CARD && cardData && !cardData.has(activeCard)) {
      fetchData(activeCard, selectedBookId, selectedChapter);
      fetchGlosses(selectedBookId, selectedChapter, this.getSelectedGloss());
    }

    changeAccordionCard(activeCard);
  }

  private cardContainer(activeCard: number): ReactElement {
    const { cardData, glosses } = this.props;

    if (cardData && cardData.has(activeCard)) {
      const result = cardData.get(activeCard);
      if (!result || result.empty) {
        return <div id="manuscript-no-data">No data found.</div>;
      }

      if (activeCard === MANUSCRIPT_CARD) {
        return (
          <Card.Body className="manuscript-card">
            <ManuscriptView
              lexiconData={result.data.lexiconData}
              manuscriptDataByVerse={result.data.manuscriptDataByVerse}
              isOldTestament={result.isOldTestament}
              glosses={glosses}
            />
          </Card.Body>
        );
      }

      if (activeCard === MORPHOLOGY_CARD) {
        return (
          <Card.Body className="morphology-card">
            <MorphologyView
              lexiconData={result.data.lexiconData}
              manuscriptDataByVerse={result.data.manuscriptDataByVerse}
              isOldTestament={result.isOldTestament}
              glosses={glosses}
            />
          </Card.Body>
        );
      }
    }

    if (activeCard === DICTIONARY_CARD) {
      return (
        <Card.Body className="dictionary-card">
          <DictionaryView />
        </Card.Body>
      );
    }

    return <Spinner />;
  }

  public render(): ReactElement {
    let index = -1;
    const { activeCard, cardData, toggleManuscript, isLeftPanelOpen, updateUIConfig } = this.props;

    const result = cardData.get(activeCard);
    const license = result?.license ?? 'blank';
    const licenseMorphology = result?.licenseMorphology ?? 'blank';

    return (
      <Accordion
        defaultActiveKey={activeCard.toString()}
        activeKey={activeCard.toString()}
        className="panel bar-top"
      >
        <button
          type="button"
          className="collapse-left-panel"
          onClick={(): void => {
            toggleManuscript();
            updateUIConfig('isLeftPanelOpen', !isLeftPanelOpen);
          }}
        >
          <i className="fas fa-sm fa-chevron-left" />
          <i className="fas fa-sm fa-chevron-left" />
          <i className="fas fa-sm fa-chevron-left" />
        </button>
        {AccordionCards.map(
          (card: any): ReactElement => {
            index += 1;
            return (
              <Card
                key={`accordion-card-${index}`}
                id={`left-panel-accordion-card-${index}`}
                className={activeCard === index ? 'active-card' : ''}
              >
                <CustomAccordionToggle
                  eventKey={index.toString()}
                  activeCard={activeCard}
                  changeCard={this.changeCard}
                >
                  <OverlayTrigger
                    rootClose
                    key={card.name}
                    trigger={['hover', 'focus']}
                    placement="right"
                    popperConfig={{
                      modifiers: { preventOverflow: {} },
                    }}
                    // prettier-ignore
                    overlay={(
                      <Popover id={card.name}>
                        <Popover.Title as="h3">
                          <FormattedMessage id="references.licensing" />
                        </Popover.Title>
                        <Popover.Content className="attribution-text">
                          <p>{license}</p>
                          <p>{licenseMorphology}</p>
                        </Popover.Content>
                      </Popover>
                    )}
                  >
                    <span>
                      <FormattedMessage id={card.name} />
                    </span>
                  </OverlayTrigger>
                  <i className={`fas fa-caret-down accordion-toggle-${index}`} />
                </CustomAccordionToggle>
                <Accordion.Collapse eventKey={index.toString()}>
                  {this.cardContainer(index)}
                </Accordion.Collapse>
              </Card>
            );
          },
        )}
      </Accordion>
    );
  }
}

export const mapStateToProps = (state: AppState): any => {
  const props = {
    ...state.translation,
    isLeftPanelOpen: state.profile.isLeftPanelOpen,
    glosses: state.gloss.glosses,
    selectedGlossOT: state.profile.selectedInterlinearOT,
    selectedGlossNT: state.profile.selectedInterlinearNT,
  };

  return props;
};

export const mapDispatchToProps = (dispatch: Dispatch): any => ({
  changeAccordionCard: (activeCard: number): void => {
    dispatch(changeManuscriptAccordionAction(activeCard));
  },

  fetchData: (activeCard: number, bookId: string, chapter: number): void => {
    dispatch(fetchDataAction(activeCard, bookId, chapter));
  },

  fetchGlosses: (bookId: string, chapter: number, selectedGloss: string): void => {
    dispatch(fetchGlossAction(bookId, chapter, selectedGloss));
  },

  toggleManuscript: (): void => {
    dispatch(toggleManuscriptAction('Left'));
  },

  updateUIConfig: (fieldName: string, fieldValue: boolean): void => {
    dispatch(updateUIConfigAction(fieldName, fieldValue));
  },
});

const Manuscript = connect(mapStateToProps, mapDispatchToProps)(ManuscriptComp);

export default Manuscript;
