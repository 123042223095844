import * as types from '../types';
import firebase from '../lib/firebase';
import { TranslationLink } from '../shared/structs';

const FirebaseDb = firebase.createService('db');
const FirebaseAuth = firebase.createService('auth');

export const showErrorAction = (error: string): types.VerseEditorTypes => {
  return {
    type: types.SHOW_ERROR,
    error,
  };
};

export const uncheckCompleteBoxAction = (textId: string): types.VerseEditorTypes => {
  return {
    type: types.UNCHECK_COMPLETE_BOX,
    textId,
  };
};

export const openEditorAction = (textId: string): types.VerseEditorTypes => {
  return {
    type: types.OPEN_EDITOR,
    textId,
  };
};

export const closeEditorAction = (): types.VerseEditorTypes => {
  return {
    type: types.CLOSE_EDITOR,
  };
};

export const changeVerseAction = (
  textId: string,
  verseTranslation: string,
): types.VerseEditorTypes => {
  return {
    type: types.CHANGE_VERSE_TRANSLATION,
    textId,
    verseTranslation,
  };
};

export const triggerAlignment = (verseCode: string): types.VerseEditorTypes => {
  return {
    type: types.TRIGGER_ALIGNMENT_FLAG,
    verseCode,
  };
};

export const dismissAlignment = (): types.VerseEditorTypes => {
  return {
    type: types.DISMISS_ALIGNMENT,
  };
};

export const verifyAlignment = (
  verseCode: string,
  links: TranslationLink,
): types.VerseEditorTypes => {
  return {
    type: types.VERIFY_ALIGNMENT,
    verseCode,
    links,
  };
};

export const verifyAlignmentAction = (
  projectId: string,
  textId: string,
  links: TranslationLink,
): any => {
  return async (dispatch: any): Promise<any> => {
    dispatch(dismissAlignment());
    const currentUser = await (await FirebaseAuth.init()).getCurrentSignedInUser();

    if (currentUser && Object.prototype.hasOwnProperty.call(currentUser, 'uid')) {
      await (await FirebaseDb.init()).verifyLinks(currentUser.uid, projectId, textId, true, links);
      dispatch(verifyAlignment(textId, links));
    }
  };
};

export const updateVerseRequestAction = (
  textId: string,
  complete: boolean,
): types.VerseEditorTypes => {
  return {
    type: types.UPDATE_VERSE_REQUEST,
    textId,
    complete,
  };
};

export const updateVerseFailureAction = (error: string): types.VerseEditorTypes => {
  return {
    type: types.UPDATE_VERSE_FAILURE,
    error,
  };
};

export const updateVerseSuccessAction = (textId: string): types.VerseEditorTypes => {
  return {
    type: types.UPDATE_VERSE_SUCCESS,
    textId,
  };
};

export const updateVerseAction = (
  projectId: string,
  textId: string,
  verseTranslation: string,
  complete: boolean,
  closeEditor = true,
): any => {
  return async (dispatch: any): Promise<any> => {
    try {
      dispatch(updateVerseRequestAction(textId, complete));
      const currentUser = await (await FirebaseAuth.init()).getCurrentSignedInUser();

      if (currentUser && Object.prototype.hasOwnProperty.call(currentUser, 'uid')) {
        await (await FirebaseDb.init()).saveProjectTranslation(
          currentUser.uid,
          projectId,
          textId,
          verseTranslation,
          complete,
          currentUser.displayName,
        );

        dispatch(updateVerseSuccessAction(textId));

        if (closeEditor) {
          dispatch(closeEditorAction());
          if (complete) {
            dispatch(triggerAlignment(textId));
          }
        }
      } else {
        throw new Error(types.USER_IS_NOT_SIGNED);
      }
    } catch (error) {
      if (Object.prototype.hasOwnProperty.call(error, 'message')) {
        if (error.message === types.USER_IS_NOT_SIGNED) {
          dispatch(updateVerseFailureAction(`error.mustBeSignedToTranslateBible`));
        } else {
          dispatch(updateVerseFailureAction(error.message));
        }
      } else if (typeof error === 'string') {
        dispatch(updateVerseFailureAction(error));
      } else {
        dispatch(updateVerseFailureAction(String(error)));
      }
    }
  };
};

// ***************** update verse status - begin *****************
export const updateVerseStatusRequestAction = (
  textId: string,
  complete: boolean,
): types.VerseEditorTypes => {
  return {
    type: types.UPDATE_VERSE_STATUS_REQUEST,
    textId,
    complete,
  };
};

export const updateVerseStatusSuccessAction = (textId: string): types.VerseEditorTypes => {
  return {
    type: types.UPDATE_VERSE_STATUS_SUCCESS,
    textId,
  };
};

export const updateVerseStatusFailureAction = (error: string): types.VerseEditorTypes => {
  return {
    type: types.UPDATE_VERSE_STATUS_FAILURE,
    error,
  };
};

export const updateVerseStatusAction = (
  projectId: string,
  textId: string,
  complete: boolean,
): any => {
  return async (dispatch: any): Promise<any> => {
    try {
      dispatch(updateVerseStatusRequestAction(textId, complete));
      const currentUser = await (await FirebaseAuth.init()).getCurrentSignedInUser();

      if (currentUser && Object.prototype.hasOwnProperty.call(currentUser, 'uid')) {
        await (await FirebaseDb.init()).updateVerseTranslationStatus(
          currentUser.uid,
          projectId,
          textId,
          complete,
        );

        dispatch(updateVerseStatusSuccessAction(textId));
      } else {
        throw new Error(types.USER_IS_NOT_SIGNED);
      }
    } catch (error) {
      if (Object.prototype.hasOwnProperty.call(error, 'message')) {
        if (error.message === types.USER_IS_NOT_SIGNED) {
          dispatch(updateVerseStatusFailureAction('error.mustBeSignedToMakeChanges'));
        } else {
          dispatch(updateVerseStatusFailureAction(error.message));
        }
      } else if (typeof error === 'string') {
        dispatch(updateVerseStatusFailureAction(error));
      } else {
        dispatch(updateVerseStatusFailureAction(String(error)));
      }
    }
  };
};
// ***************** update verse status - end *****************
export const dismissVerseChangesAction = (): types.VerseEditorTypes => {
  return {
    type: types.DISMISS_VERSE_CHANGES,
  };
};

// ********************* Fetch suggestion - begin *********************
export const fetchSuggestionRequestAction = (textId: string): types.VerseEditorTypes => {
  return {
    type: types.FETCH_SUGGESTION_REQUEST,
    textId,
  };
};

export const fetchSuggestionSuccessAction = (textId: string, data: any): types.VerseEditorTypes => {
  return {
    type: types.FETCH_SUGGESTION_SUCCESS,
    data,
    textId,
  };
};

export const fetchSuggestionFailureAction = (error: any): types.VerseEditorTypes => {
  return {
    type: types.FETCH_SUGGESTION_FAILURE,
    error,
  };
};

export const fetchSuggestionAction = (
  projectId: string,
  textId: string,
  versification: string,
): any => {
  return async (dispatch: any): Promise<any> => {
    try {
      dispatch(fetchSuggestionRequestAction(textId));
      const currentUser = await (await FirebaseAuth.init()).getCurrentSignedInUser();

      if (currentUser && Object.prototype.hasOwnProperty.call(currentUser, 'uid')) {
        const suggestions = await (await FirebaseDb.init()).getVerseSuggestions(
          currentUser.uid,
          projectId,
          textId,
          versification,
        );
        dispatch(fetchSuggestionSuccessAction(textId, suggestions));
      } else {
        throw new Error(types.USER_IS_NOT_SIGNED);
      }
    } catch (error) {
      if (Object.prototype.hasOwnProperty.call(error, 'message')) {
        if (error.message === types.USER_IS_NOT_SIGNED) {
          dispatch(fetchSuggestionFailureAction('error.mustBeSignedToMakeChanges'));
        } else {
          dispatch(fetchSuggestionFailureAction(error.message));
        }
      } else if (typeof error === 'string') {
        dispatch(fetchSuggestionFailureAction(error));
      } else {
        dispatch(fetchSuggestionFailureAction(String(error)));
      }
    }
  };
};
// ********************* Fetch suggestion - end *********************
