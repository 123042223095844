import { SendEmailRequest, SendEmailResponse } from '../shared/structs';
import FirebaseFunctionClient from './firebaseFunctionClient';

export default class SendEmailClient extends FirebaseFunctionClient {
  public async sendEmail(
    returnAddress: string,
    subject: string,
    message: string,
  ): Promise<SendEmailResponse> {
    const request: SendEmailRequest = {
      subject,
      from: returnAddress,
      content: message,
    };
    const response = await this.invokeFunction('sendEmail', request);
    return response.data;
  }
}
