import React, { ReactElement } from 'react';

import { OverlayTrigger, Popover } from 'react-bootstrap';
import { ManuscriptViewProps, ManuscriptViewState, DefaultManuscriptViewProps } from '../../types';
import { ManuscriptData } from '../../shared/structs';
import getDisplaySegments, { ManuscriptDisplay } from '../../lib/getDisplaySegments';
import findGlossForPosition from '../../lib/findGlossForPosition';
import { isHackedForDisplay } from '../../shared/hackedVerseIds';

export class ManuscriptViewComp extends React.Component<ManuscriptViewProps, ManuscriptViewState> {
  public static defaultProps: ManuscriptViewProps = DefaultManuscriptViewProps;

  public constructor(props: ManuscriptViewProps) {
    super(props);
  }

  private segmentTextForManuscript(
    verseManuscriptData: ManuscriptData[],
    textId: string,
  ): ReactElement[] {
    const displaySegments = getDisplaySegments(verseManuscriptData);
    const { glosses } = this.props;

    return displaySegments.map(
      (verseSegment: ManuscriptDisplay, index: number): ReactElement => {
        const isLast = index === verseManuscriptData.length - 1;
        const segmentText = isLast ? verseSegment.text : verseSegment.text.concat(' ');
        const verseData = verseManuscriptData.filter((datum: ManuscriptData) => {
          return datum.positionId.startsWith(verseSegment.positions[0].substring(0, 3));
        });

        const glossStack: string[] = [];
        verseData.forEach((verseDatum: ManuscriptData): void => {
          const gloss = findGlossForPosition(glosses, textId, verseDatum.positionId);
          glossStack.push(gloss);
        });
        const glossTitle = glossStack.join(' ');

        return (
          <OverlayTrigger
            rootClose
            key={segmentText + verseSegment.positions.toString()}
            trigger={['hover', 'focus']}
            placement="right"
            // popperConfig={{ modifiers: { preventOverflow: {} } }}
            // prettier-ignore
            overlay={(
              <Popover id={segmentText + index}>
                <Popover.Title as="h3">{glossTitle}</Popover.Title>
                <Popover.Content>
                  {verseData.map((verseDatum: ManuscriptData): ReactElement => {
                    const { cat, lemma, english, strongsX, positionId } = verseDatum;
                    const gloss = findGlossForPosition(glosses, textId, positionId);
                    return (
                      <p key={verseDatum.segment + verseDatum.positionId}>
                        <span>{lemma}</span>
                        &nbsp;
                        <span>
                          (
                          {cat}
                          ):
                        </span>
                        &nbsp;
                        <span>
                          {gloss || english}
                          &nbsp;
                        </span>
                        <span>{strongsX}</span>
                      </p>
                    )
                  })}
                </Popover.Content>
              </Popover>
            )}
          >
            <span>{segmentText}</span>
          </OverlayTrigger>
        );
      },
    );
  }

  public render(): ReactElement {
    const { manuscriptDataByVerse, isOldTestament } = this.props;

    return (
      <div key="manuscript-list" className={`manuscript-list ${isOldTestament ? 'rtl' : ''}`}>
        {((): ReactElement[] => {
          return Object.keys(manuscriptDataByVerse)
            .filter((verse) => isHackedForDisplay(verse))
            .sort()
            .map(
              (verse: string): ReactElement => {
                const manuscriptData: ManuscriptData[] = manuscriptDataByVerse[verse];
                const verseNo = verse.substring(5).replace(/^0{0,2}/, '');

                return (
                  <p
                    key={`verse-${verseNo}`}
                    id={`manuscript-verse-${verseNo}`}
                    data-id={`${verseNo}`}
                  >
                    <sup>{verseNo}</sup>
                    {this.segmentTextForManuscript(manuscriptData, verse)}
                  </p>
                );
              },
            );
        })()}
      </div>
    );
  }
}

export default ManuscriptViewComp;
