/* istanbul ignore file */
/* eslint-disable no-param-reassign */
import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Dropdown, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../reducers';

import * as types from '../../types';
import {
  searchBoxSubmitsAction,
  searchBoxChangesAction,
  changeManuscriptAccordionAction,
  toggleManuscriptAction,
} from '../../actions';

import { MemoryVerseReference, MemoryTargetData } from '../../shared/structs';
import { VerseIdParser } from '../../shared/verseIdParser';
import { determineConcordanceDropdownWidth } from '../../shared/frontend';
import { strEscape, highlightLinkedWords } from '../../shared/functions';
import findGlossForPosition from '../../lib/findGlossForPosition';
import getCurrentProjectId from '../../lib/getCurrentProjectId';

const VerseIdParserInstance = new VerseIdParser();

export class SegmentComp extends React.Component<types.SegmentProps, types.SegmentState> {
  private isAdvanced = false;

  private calcListPosition(el: HTMLElement): void {
    const parents = document.getElementsByClassName('verse-box');
    if (parents && parents.length && el) {
      const parentPos = parents[0].getBoundingClientRect();
      const elPos = el.getBoundingClientRect();
      const offsetLeft = elPos.right - parentPos.right;
      const childSpaceRight = parentPos.right - elPos.right;
      const childSpaceLeft = elPos.left - parentPos.left;

      if (offsetLeft > 0) {
        el.style.transform = `translate3d(${Math.ceil(offsetLeft) * -1}px, 0px, 0px)`;
      }

      const defaultListWidth = determineConcordanceDropdownWidth();
      const maxChildSpace = Math.max(childSpaceLeft, childSpaceRight);
      const listWidth = Math.min(defaultListWidth, maxChildSpace);
      const list = el.getElementsByTagName('ul');
      if (list && list.length) {
        for (let index = 0; index < list.length; index += 1) {
          if (childSpaceRight < childSpaceLeft) {
            list[index].style.left = '';
            list[index].style.right = '100%';
          } else {
            list[index].style.right = '';
            list[index].style.left = '100%';
          }

          list[index].style.width = `${listWidth}px`;
        }
      }
    }
  }

  private toggleDropdownList(key: string, e: any): void {
    const wordList: HTMLCollection = document.getElementsByClassName('word-list');
    if (e && wordList && wordList.length) {
      const cssKey = 'display';
      for (let index = 0; index < wordList.length; index += 1) {
        const el: any | undefined = wordList[index];
        if (el === e.target.parentElement) {
          if (el.classList.contains(cssKey)) {
            el.classList.remove(cssKey);
            el.style.transform = '';
          } else {
            el.classList.add(cssKey);

            const nextGen = el.getElementsByClassName('word-list-main-menu ');
            if (nextGen && nextGen.length) {
              window.onresize = (): void => {
                this.calcListPosition(nextGen[0]);
              };
              this.calcListPosition(nextGen[0]);
            }
          }
        } else {
          el.classList.remove(cssKey);
        }
      }
    }
  }

  private suggestionOptionAdditionalInfo(
    word: string,
    memoryTargetData: MemoryTargetData[],
  ): ReactElement | null {
    if (memoryTargetData) {
      let n = -1;
      let items: MemoryVerseReference[] = [];

      const suggestions = Object.values(memoryTargetData);
      for (let index = 0; index < suggestions.length; index += 1) {
        const suggestionData = suggestions[index];

        if (suggestionData && suggestionData.verses && suggestionData.targetText === word) {
          items = suggestionData.verses;
        }
      }

      const listRecord = items.reduce((acc: Record<string, MemoryVerseReference>, item) => {
        const unique = acc;

        if (!unique[item.textId]) {
          unique[item.textId] = item;
        }

        if ('locationKeys' in unique[item.textId] === false) {
          unique[item.textId].locationKeys = [];
        }

        unique[item.textId].locationKeys?.push(item.locationKey);
        return unique;
      }, {});

      const list = Object.keys(listRecord)
        .sort()
        .map((textId) => {
          n += 1;
          const { text, locationKeys } = listRecord[textId];
          let html = strEscape(text);

          if (locationKeys) {
            html = highlightLinkedWords(word, text, locationKeys);
          }

          const verseInfo = VerseIdParserInstance.getReadableReferenceForGbiId(textId);

          return (
            <li className="list-group-item" key={`word-list-dropdown-list-g0746-${textId}-${n}`}>
              <FormattedMessage id={verseInfo.book}>
                {(book): ReactElement => <sub>{`${book} ${verseInfo.ref}`}</sub>}
              </FormattedMessage>
              <div dangerouslySetInnerHTML={{ __html: html }} />
            </li>
          );
        });

      return (
        <ul className="list-group border sub-menu" style={{ left: '100%' }}>
          {list}
        </ul>
      );
    }
    return null;
  }

  private suggestionOption(targetTranslation: string, strongX: string): ReactElement {
    const { linkSuggestions, memorySuggestions } = this.props;

    if (linkSuggestions && linkSuggestions.length) {
      return (
        <div className="item item-target word-list dropdown ">
          <button
            type="button"
            className="btn dropdown-toggle"
            key={`word-list-dropdown-button-${strongX}`}
            onClick={this.toggleDropdownList.bind(this, strongX)}
          >
            {targetTranslation}
          </button>

          <ul
            className="dropdown-menu word-list-main-menu"
            key={`word-list-dropdown-list-${strongX}`}
            id={`word-list-dropdown-list-${strongX}`}
            data-key={strongX}
          >
            {((): ReactElement[] => {
              return linkSuggestions.map(
                (item: string, index: number): ReactElement => {
                  return (
                    <li className="dropdown-item" key={`dropdown-item-${index + 1}`}>
                      {item}

                      {((): ReactElement | undefined => {
                        if (
                          memorySuggestions &&
                          memorySuggestions.length >= index &&
                          'count' in memorySuggestions[index]
                        ) {
                          return (
                            <span className="occurrence">{memorySuggestions[index].count}</span>
                          );
                        }

                        return undefined;
                      })()}

                      {this.suggestionOptionAdditionalInfo(item, memorySuggestions)}
                    </li>
                  );
                },
              );
            })()}
          </ul>
        </div>
      );
    }
    return <span className="item item-target">{targetTranslation}</span>;
  }

  protected searchConcordance(term: string): void {
    const { searchConcordance, isLeftPanelOpen } = this.props;
    const projectId = getCurrentProjectId();
    if (searchConcordance) {
      searchConcordance(projectId, term, isLeftPanelOpen);
    }
  }

  protected dropdownSearchMenu(segment: string, strongsX: string): ReactElement {
    const { linkedTargetWords } = this.props;

    if (linkedTargetWords) {
      return (
        <Dropdown drop="up">
          <Dropdown.Toggle variant="link" id={`interlinear-dropdown-btn-${strongsX}-${segment}`}>
            {segment}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              onClick={(): void => {
                this.searchConcordance(segment);
              }}
            >
              <FormattedMessage id="dict.searchLemma">
                {(message: string): ReactElement => (
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip id="tooltip-disabled">{message}</Tooltip>}
                  >
                    <span className="d-inline-block">{segment}</span>
                  </OverlayTrigger>
                )}
              </FormattedMessage>
            </Dropdown.Item>

            {((): ReactElement => {
              if (linkedTargetWords) {
                return (
                  <Dropdown.Item
                    onClick={(): void => {
                      this.searchConcordance(linkedTargetWords);
                    }}
                  >
                    <FormattedMessage id="dict.searchTranslation">
                      {(message: string): ReactElement => (
                        <OverlayTrigger
                          placement="right"
                          overlay={<Tooltip id="tooltip-disabled">{message}</Tooltip>}
                        >
                          <span className="d-inline-block">{linkedTargetWords}</span>
                        </OverlayTrigger>
                      )}
                    </FormattedMessage>
                  </Dropdown.Item>
                );
              }

              return <></>;
            })()}
          </Dropdown.Menu>
        </Dropdown>
      );
    }

    return (
      <button
        id={`interlinear-dropdown-btn-${strongsX}-${segment}`}
        type="button"
        className="btn btn-link"
      >
        {segment}
      </button>
    );
  }

  // TODO: allow users to select simple/advanced view
  public render(): ReactElement {
    const { manuscriptData, position, textId, glosses } = this.props;

    const gloss = findGlossForPosition(glosses, textId, manuscriptData.positionId);
    return (
      <div
        className={`group group-${position}`}
        key={`suggestion-group-${manuscriptData.strongsX}-${position}`}
      >
        <span className="item item-number" hidden={!this.isAdvanced}>
          {position}
        </span>
        <span className="item item-strong-x" hidden={!this.isAdvanced}>
          {manuscriptData.strongsX}
        </span>
        <span className="item item-manuscript">
          {this.dropdownSearchMenu(manuscriptData.segment, manuscriptData.strongsX)}
        </span>
        {this.suggestionOption(gloss, manuscriptData.strongsX)}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState): any => {
  const props = {
    isLeftPanelOpen: state.profile.isLeftPanelOpen,
    verses: state.translation.verseTranslations,
  };

  return props;
};

const mapDispatchToProps = (dispatch: Dispatch): any => ({
  searchConcordance: (projectId: string, term: string, isLeftPanelOpen: boolean): void => {
    if (!isLeftPanelOpen) {
      dispatch(toggleManuscriptAction('Left'));
    }
    dispatch(changeManuscriptAccordionAction(2));
    dispatch(searchBoxChangesAction(term));
    dispatch(searchBoxSubmitsAction(projectId, term));
  },
});
const Segment = connect(mapStateToProps, mapDispatchToProps)(SegmentComp);
export default Segment;
