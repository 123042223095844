import { Dispatch } from 'redux';

import firebase from '../lib/firebase';
import { USER_AUTHENTICATED, USER_NOT_AUTHENTICATED, PUBLISH_DISPLAY_NAME, User } from '../types';

const FirebaseAuth = firebase.createService('auth');

export function userAuthenticated(user: User): any {
  return (dispatch: any): void => {
    dispatch({
      type: USER_AUTHENTICATED,
      user,
    });
  };
}

export function userNotAuthenticated(): any {
  return (dispatch: any): void => {
    dispatch({
      type: USER_NOT_AUTHENTICATED,
    });
  };
}

export function publishDisplayName(displayName: string): any {
  return (dispatch: any): void => {
    dispatch({
      type: PUBLISH_DISPLAY_NAME,
      displayName,
    });
  };
}

/* istanbul ignore next */
export async function createAuthListener(dispatch: Dispatch): Promise<any> {
  (await FirebaseAuth.init()).auth().onAuthStateChanged((user: User): any => {
    if (user) {
      dispatch(userAuthenticated(user));
      if (user.displayName) {
        dispatch(publishDisplayName(user.displayName));
      }
    } else {
      dispatch(userNotAuthenticated());
    }
  });
}
