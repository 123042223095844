import React, { ReactElement } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Accordion, Card, Popover, OverlayTrigger } from 'react-bootstrap';
import { useIntl, FormattedMessage } from 'react-intl';

import { AppState } from '../../reducers';
import Spinner from '../spinner';
import { TranslationProps, DefaultTranslationProps } from '../../types';
import {
  fetchReferenceDataAction,
  closeReferenceAction,
  rememberLastThingAction,
} from '../../actions';
import CustomAccordionToggle from './customAccordionToggle';
import getCurrentProjectId from '../../lib/getCurrentProjectId';
import { Translation, DataResult } from '../../shared/structs';

const ESV_ID = 'f421fe261da7624f-01';
const ESV_WARNING =
  'The English text of the ESV Bible may not be translated into any other language, may not be used as the basis of a derivative work, and may not be used as the sole base English text from which any translation of the Bible is made.';

const getCopyright = (
  referenceId: string,
  referenceData: DataResult,
  emptyMessage: string,
): ReactElement => {
  const dblCopyright = referenceData?.data?.responseData?.copyright ?? emptyMessage;

  if (referenceId === ESV_ID) {
    return (
      <>
        <p>{dblCopyright}</p>
        <p>{ESV_WARNING}</p>
      </>
    );
  }

  return <p>{dblCopyright}</p>;
};

const content = (props: TranslationProps): ReactElement => {
  const { referenceData, fetchData, selectedReference, selectedBookId, selectedChapter } = props;
  if (
    referenceData[selectedReference.id] &&
    referenceData[selectedReference.id] !== 'fetching' &&
    Object.keys(referenceData).length
  ) {
    const response = referenceData[selectedReference.id].data;
    const { responseData } = response;
    if (response.error) {
      return (
        <p className="reference-text text-center">
          <FormattedMessage id={response.errorMessage} />
        </p>
      );
    }
    if (!response.responseData) {
      return (
        <p className="reference-text text-center">
          <FormattedMessage id="error.referenceDoesNotExistForResource" />
        </p>
      );
    }
    return (
      <div className="scripture-styles">
        <p className="reference-text" dangerouslySetInnerHTML={{ __html: responseData.content }} />
      </div>
    );
  }
  if (!referenceData[selectedReference.id]) {
    fetchData(selectedReference.id, selectedBookId, selectedChapter);
  }
  return <Spinner />;
};

export const ReferenceComp: React.FC<TranslationProps> = (
  props: TranslationProps,
): ReactElement => {
  const {
    selectedReference,
    closeReferenceFunc,
    rememberLastThing,
    references,
    referenceData,
  } = props;

  const { formatMessage } = useIntl();

  const referenceCopyright = getCopyright(
    selectedReference.id,
    referenceData[selectedReference.id],
    formatMessage({ id: 'references.licensingEmpty' }),
  );

  return (
    <Card key={`accordion-card-reference-${selectedReference.id}`} className="active-card">
      <CustomAccordionToggle eventKey={selectedReference.id} activeCard>
        <OverlayTrigger
          rootClose
          key={selectedReference.fullName}
          trigger={['hover', 'focus']}
          placement="left"
          popperConfig={{
            modifiers: { preventOverflow: {} },
          }}
          // prettier-ignore
          overlay={(
            <Popover id={selectedReference.fullName}>
              <Popover.Title as="h3">
                <FormattedMessage id="references.licensing" />
              </Popover.Title>
              <Popover.Content className="attribution-text">{referenceCopyright}</Popover.Content>
            </Popover>
          )}
        >
          <span className="reference-name float-left">{selectedReference.fullName}</span>
        </OverlayTrigger>
        <span className="float-right">
          <span
            className="close-reference"
            role="presentation"
            onClick={(e: any): void => {
              e.stopPropagation();
              closeReferenceFunc(selectedReference);
              rememberLastThing(
                getCurrentProjectId(),
                'references',
                references.filter((reference: Translation): boolean => {
                  return reference.id !== selectedReference.id;
                }),
              );
            }}
          >
            <i className="fa sm fa-times" />
          </span>
        </span>
      </CustomAccordionToggle>
      <Accordion.Collapse eventKey={selectedReference.id} className="show">
        <Card.Body>{content(props)}</Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

ReferenceComp.defaultProps = DefaultTranslationProps;

export const mapStateToProps = (state: AppState): TranslationProps => {
  const props = {
    ...state.translation,
  };
  return props;
};

export const mapDispatchToProps = (dispatch: Dispatch): any => ({
  fetchData: (selectedReference: string, bookId: string, chapterId: number): void => {
    dispatch(fetchReferenceDataAction(selectedReference, bookId, chapterId));
  },
  closeReferenceFunc: (reference: Translation): void => {
    dispatch(closeReferenceAction(reference));
  },
  rememberLastThing: (projectId: string, fieldName: string, fieldValue: any): void => {
    dispatch(rememberLastThingAction(projectId, fieldName, fieldValue));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ReferenceComp);
