import * as types from '../types';

export const InitStateGloss: types.GlossState = {
  loading: false,
  glossVersions: [],
  glosses: [],
};

export default (state = InitStateGloss, action: types.GlossTypes): types.GlossState => {
  switch (action.type) {
    case types.FETCH_GLOSS_REQUEST:
      return { ...state, loading: true };
    case types.FETCH_GLOSS_VERSIONS_REQUEST:
      return { ...state, loading: true };
    case types.FETCH_GLOSS_SUCCESS:
      return {
        ...state,
        loading: false,
        glosses: action.data,
      };
    case types.FETCH_GLOSS_VERSIONS_SUCCESS:
      return { ...state, loading: false, glossVersions: action.data };

    default:
      return state;
  }
};
