import { Firestore } from '@google-cloud/firestore';
import { VerseIdParser } from '../verseIdParser';
import {
  DbNames,
  ManuscriptData,
  ManuscriptSuggestions,
  ManuscriptVerse,
  MemoryVerseReference,
  MemoryCollection,
  TranslationLink,
  TranslationMemory,
  TranslationMemoryItem,
  MemoryTargetData,
} from '../structs/projectTranslations';

interface TargetSuggestion {
  source: string;
  targets: TranslationMemory[];
}

function getNearSegments(
  mySegments: string[],
  memorySegments: string[],
  nearnessFactor = 2,
): string[] {
  // Search for positions within plus or minus nearFactor
  // later, this may be made more exact using GBI tree
  // Note: slice will include up to second arg index, but will not include it
  const memorySegmentsCount = memorySegments.length;
  const firstIndex = mySegments.indexOf(memorySegments[0].toLowerCase());
  const leftMostIndex = Math.max(0, firstIndex - memorySegmentsCount + 1 - nearnessFactor);
  const rightMostIndex = Math.min(
    firstIndex + memorySegmentsCount + nearnessFactor,
    mySegments.length,
  );
  return mySegments.slice(leftMostIndex, rightMostIndex);
}

function getUniqueSegments(sourceSegments: string[]): string[] {
  // make sure to only process non-empty unique segments
  return sourceSegments.filter(
    (segment, index, self) => segment && self.indexOf(segment) === index,
  );
}

function foundInNearSegments(mySegments: string[], memorySegments: string[]): boolean {
  if (memorySegments.length === 1) {
    return mySegments.includes(memorySegments[0].toLowerCase());
  }

  const myNearSegments = getNearSegments(mySegments, memorySegments);

  return memorySegments.every((memorySegment) => myNearSegments.includes(memorySegment));
}

function createAlignmentLink(mySegments: string[], memorySegments: string[]): number[] {
  const links: number[] = [];

  // We need to restrict searching for memory segments to those that are near the first matching segment
  const nearSegments = getNearSegments(mySegments, memorySegments);

  // Get position of the first segment found.
  // This will be used to find real position of segments in mySegments
  const firstSegment = memorySegments[0].toLowerCase();
  const indexAdjust = mySegments.indexOf(firstSegment) - nearSegments.indexOf(firstSegment);

  memorySegments.forEach((segment: string) => {
    const index = indexAdjust + nearSegments.indexOf(segment.toLowerCase());
    links.push(index);
  });
  return links;
}

function getMatchFromMemory(
  sourceSegment: string,
  sourceSegments: string[],
  targetSegments: string[],
  targetSuggestions: TargetSuggestion[],
): TranslationMemory | undefined {
  const possibleSuggestion = targetSuggestions.find(
    (suggestion) => suggestion.source === sourceSegment && suggestion.targets.length,
  );

  let bestMatch;
  if (possibleSuggestion) {
    bestMatch = possibleSuggestion.targets.find((target) => {
      // make sure all memory source memory segments found in source text are somewhat close to each other
      return (
        foundInNearSegments(sourceSegments, target.sourceSegments) &&
        foundInNearSegments(targetSegments, target.targetSegments)
      );
    });
  }

  return bestMatch;
}

function getLinkSuggestions(
  sourceSegments: string[],
  memory: string[][],
): Record<string, string[]> {
  return sourceSegments.reduce(
    (
      suggestions: Record<string, string[]>,
      segment: string,
      index: number,
    ): Record<string, string[]> => {
      const previousSuggestions = suggestions;
      const currentSuggestions = {
        [segment]: memory[index],
      };
      return { ...previousSuggestions, ...currentSuggestions };
    },
    {},
  );
}

function getTargetSuggestions(
  memorySuggestions: Record<string, MemoryTargetData[]>,
): Record<string, MemoryVerseReference[]> {
  return Object.values(memorySuggestions).reduce((suggestions, memoryTargetData) => {
    return Object.assign(
      suggestions,
      memoryTargetData.reduce(
        (obj, data) => Object.assign(obj, { [data.targetText]: data.verses }),
        {},
      ),
    );
  }, {});
}

function getTopTextSuggestion(sourceSegments: string[], memory: string[][]): string {
  return sourceSegments.reduce((suggestion: string, segment: string, index: number): string => {
    const [currentSuggestion] = memory[index];
    if (currentSuggestion) {
      return `${suggestion} ${currentSuggestion}`.trim();
    }
    return suggestion;
  }, '');
}

async function getMemory(
  db: Firestore,
  projectDocId: string,
  sourceText: string,
  memoryType = DbNames.LinkMemoryCollection,
  topOnly = false,
): Promise<MemoryTargetData[]> {
  // for greediness in auto-links and auto-suggestions,
  //   favor linkings with multiple target segments
  //   then most popular

  const query = db
    .collection(DbNames.ProjectTranslationsCollection)
    .doc(projectDocId)
    .collection(memoryType)
    .where('sourceText', '==', sourceText)
    .where('count', '>', 0)
    .orderBy('count', 'desc');

  if (topOnly) {
    query.limit(1);
  }

  const snapshot = await query.get();
  const targets: TranslationMemory[] = [];
  snapshot.forEach((doc) => {
    const docData = doc.data();
    const memory: TranslationMemory = {
      sourceText: docData.sourceText,
      sourceSegments: docData.sourceSegments,
      targetText: docData.targetText,
      targetSegments: docData.targetSegments,
      totalSegmentsCount: docData.totalSegmentsCount,
      verses: docData.verses,
      count: docData.count,
    };
    targets.push(memory);
  });

  // prefer larger segments
  return targets
    .sort((m1, m2) => m2.totalSegmentsCount - m1.totalSegmentsCount)
    .map((memory) => {
      const memoryTargetData: MemoryTargetData = {
        targetText: memory.targetText,
        verses: memory.verses,
        count: memory.count,
      };
      return memoryTargetData;
    });
}

async function getMemoryTargetTexts(
  db: Firestore,
  projectDocId: string,
  sourceText: string,
  memoryType = DbNames.LinkMemoryCollection,
  topOnly = false,
): Promise<string[]> {
  // for greediness in auto-links and auto-suggestions,
  //   favor linkings with multiple target segments
  //   then most popular

  const query = db
    .collection(DbNames.ProjectTranslationsCollection)
    .doc(projectDocId)
    .collection(memoryType)
    .where('sourceText', '==', sourceText)
    .where('count', '>', 0)
    .orderBy('count', 'desc');

  if (topOnly) {
    query.limit(1);
  }

  const snapshot = await query.get();
  const targets: TranslationMemory[] = [];
  snapshot.forEach((doc) => {
    const docData = doc.data();
    const memory: TranslationMemory = {
      sourceText: docData.sourceText,
      sourceSegments: docData.sourceSegments,
      targetText: docData.targetText,
      targetSegments: docData.targetSegments,
      totalSegmentsCount: docData.totalSegmentsCount,
    };
    targets.push(memory);
  });

  // prefer larger segments
  return targets
    .sort((m1, m2) => m2.totalSegmentsCount - m1.totalSegmentsCount)
    .map((memory) => memory.targetText);
}

async function getMemorySuggestions(
  db: Firestore,
  projectDocId: string,
  segments: string[],
  memoryCollection: MemoryCollection,
): Promise<Record<string, MemoryTargetData[]>> {
  const memoryPromises = segments.map(
    (segment): Promise<MemoryTargetData[]> => {
      return getMemory(db, projectDocId, segment, memoryCollection);
    },
  );

  const memory = await Promise.all(memoryPromises);

  const segmentSuggestions = await segments.reduce(
    async (suggestions: Promise<Record<string, MemoryTargetData[]>>, segment, index: number) => {
      let allSuggestions;
      const previous = await suggestions;
      const memoryTargets = memory[index];
      if (memoryTargets.length) {
        allSuggestions = Object.assign(previous, { [segment]: memoryTargets });
      } else {
        allSuggestions = Object.assign(previous);
      }

      return allSuggestions;
    },
    Promise.resolve({}),
  );

  return segmentSuggestions;
}

async function getMemoryTargetSegments(
  db: Firestore,
  projectDocId: string,
  source: string,
): Promise<TargetSuggestion> {
  // for greediness in auto-links and auto-suggestions,
  //   favor linkings with multiple target segments
  //   then most popular
  const query = db
    .collection(DbNames.ProjectTranslationsCollection)
    .doc(projectDocId)
    .collection(DbNames.LinkMemoryCollection)
    .where('sourceSegments', 'array-contains', source)
    .where('count', '>', 0)
    .orderBy('count', 'desc');

  const snapshot = await query.get();
  const targets: TranslationMemory[] = [];
  snapshot.forEach((doc) => {
    const docData = doc.data();
    const memory: TranslationMemory = {
      sourceText: docData.sourceText,
      sourceSegments: docData.sourceSegments,
      targetText: docData.targetText,
      targetSegments: docData.targetSegments,
      totalSegmentsCount: docData.totalSegmentsCount,
    };
    targets.push(memory);
  });

  // prefer larger segments
  return {
    source,
    targets: targets.sort((m1, m2) => m2.totalSegmentsCount - m1.totalSegmentsCount),
  };
}

export async function getProjectTranslationVersification(
  db: Firestore,
  projectDocId: string,
): Promise<string> {
  const query = db.collection(DbNames.ProjectTranslationsCollection).doc(projectDocId);

  const snapshot = await query.get();
  let id = DbNames.DefaultVersification; // default S1
  const data = snapshot.data();
  if (data && data.versification) {
    id = data.versification;
  }
  return id;
}

export async function getManuscriptVerseData(
  db: Firestore,
  verseId: string,
  versification: string,
): Promise<ManuscriptVerse> {
  const verseIdParser = new VerseIdParser();

  const query = db
    .collection(DbNames.ManuscriptsCollection)
    .doc(verseIdParser.manuscriptId(verseId))
    .collection(DbNames.VersesCollection)
    .where(verseIdParser.versificationFieldId(versification), 'array-contains', verseId);

  const snapshot = await query.get();
  const verses: ManuscriptVerse[] = [];
  snapshot.forEach((doc) => {
    const data = doc.data();
    const verse = new ManuscriptVerse(
      data.textId,
      data.text,
      data.textSegments,
      data.manuscriptData,
      data.chunkData,
    );
    verses.push(verse);
  });

  // TODO: Return lasts verse. This might need to be redesigned later to show multiple verse data
  return verses.sort((a, b) => a.textId.localeCompare(b.textId))[verses.length - 1];
}

export async function getTopLinkSuggestions(
  db: Firestore,
  projectDocId: string,
  sourceManuscriptData: ManuscriptData[],
  verseTargetSegments: string[],
): Promise<TranslationLink[]> {
  // use array of StrongsX for source segments (must be in lower case letters)
  let sourceSegments = sourceManuscriptData.map((manuscriptData: ManuscriptData) => {
    // ignore function segments, but keep them in the array to get correct positions
    return manuscriptData.catIsContent ? manuscriptData.strongsX : '';
  });

  // take out punctuations, and use lowercase for searching segments
  let targetSegments = verseTargetSegments.map((segment: string): string => {
    return TranslationMemoryItem.cleanseSegment(segment.toLowerCase());
  });

  // find link suggestions based on using exact segment match
  const targetSuggestionsPromises = getUniqueSegments(sourceSegments).map((segment) =>
    getMemoryTargetSegments(db, projectDocId, segment),
  );
  const targetSuggestions = await Promise.all(targetSuggestionsPromises);

  const suggestionsFromMemory = sourceSegments.reduce(
    (links: TranslationLink[], segment: string): TranslationLink[] => {
      if (segment) {
        const targetMatch = getMatchFromMemory(
          segment,
          sourceSegments,
          targetSegments,
          targetSuggestions,
        );
        if (targetMatch) {
          // Note: mySourceSegments and myTargetSegments are passed in by reference, to blank out matched segments
          const sources = createAlignmentLink(sourceSegments, targetMatch.sourceSegments);
          const targets = createAlignmentLink(targetSegments, targetMatch.targetSegments);
          links.push({ sources, targets });

          // remove segment from being used again, but keep position of segments in place
          sourceSegments = sourceSegments.map((val, i) => (sources.includes(i) ? '' : val));
          targetSegments = targetSegments.map((val, i) => (targets.includes(i) ? '' : val));
        }
      }

      return links;
    },
    [],
  );

  // remove possible empty suggestions
  return suggestionsFromMemory.filter((obj) => Object.keys(obj).length);
}

export async function getSuggestions(
  db: Firestore,
  projectDocId: string,
  textId: string,
  versification: string,
): Promise<ManuscriptSuggestions> {
  // manuscript data
  const source = await getManuscriptVerseData(db, textId, versification);
  if (source.chunkData) {
    const sourceChunks = source.chunkData.map((data) => data.chunk);
    const sourceContentSegments = source.manuscriptData
      .filter((data) => data.catIsContent)
      .map((data) => data.strongsX);

    const memoryTextTopPromises = source.textSegmentsStrongsX.map((segment) =>
      getMemoryTargetTexts(db, projectDocId, segment, DbNames.LinkMemoryCollection, true),
    );

    const memoryTextAllPromises = source.textSegmentsStrongsX.map((segment) =>
      getMemoryTargetTexts(db, projectDocId, segment, DbNames.LinkMemoryCollection, false),
    );

    const [
      memoryTop,
      memoryAll,
      linkMemorySuggestions,
      chunkMemorySuggestions,
    ] = await Promise.all([
      Promise.all(memoryTextTopPromises),
      Promise.all(memoryTextAllPromises),
      getMemorySuggestions(db, projectDocId, sourceContentSegments, DbNames.LinkMemoryCollection),
      getMemorySuggestions(db, projectDocId, sourceChunks, DbNames.ChunkMemoryCollection),
    ]);

    const textSuggestions = getTopTextSuggestion(source.textSegmentsStrongsX, memoryTop);
    const linkSuggestions = getLinkSuggestions(source.textSegmentsStrongsX, memoryAll);
    const memorySuggestions = Object.assign(linkMemorySuggestions, chunkMemorySuggestions);
    const targetSuggestions = getTargetSuggestions(memorySuggestions);

    return {
      textSuggestion: textSuggestions,
      linkSuggestions,
      memorySuggestions,
      targetSuggestions,
    };
  }

  return { textSuggestion: '', linkSuggestions: {}, memorySuggestions: {}, targetSuggestions: {} };
}
